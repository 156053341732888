import { Box, ScrollArea, Table } from "@mantine/core";
import cx from "clsx";
import { useEffect, useState } from "react";
import { repositoryPos } from "../../../../../../_base/_const/_constVar";
import { MessageResponse } from "../../../../../../model/MessageResponse";
import { TblDmCustomer } from "../../../../../../model/TblDmCustomer";
import { TblDmCustomerCategory } from "../../../../../../model/TblDmCustomerCategory";
import { tblPromotionDetailModel } from "../../../../../../model/TblPromotion";
import classes from "../../Styles/ScrollTable.module.css";

const Customers = ({
  dataPromotionDetail,
}: {
  dataPromotionDetail: tblPromotionDetailModel | undefined;
}) => {
  const [dataTable, setDataTable] = useState<any[]>([]);
  const [dataCustomerCat, setDataCustomerCat] = useState<
    TblDmCustomerCategory[]
  >([]);
  const [scrolled, setScrolled] = useState(false);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const fetchDataCustomerCat = async () => {
      const dataApi = await repositoryPos.get<MessageResponse<any>>(
        "/api/v1/TblDmCustomerGroup/get-all"
      );

      if (dataApi && dataApi.success) {
        setDataCustomerCat(dataApi.data);
      } else setDataCustomerCat([]);
    };

    fetchDataCustomerCat();
  }, []);

  useEffect(() => {
    if (
      dataPromotionDetail?.listCustGroup &&
      dataPromotionDetail?.listCustGroup?.length > 0
    ) {
      setDataTable(dataPromotionDetail?.listCustGroup);
    } else setDataTable([]);
  }, [dataPromotionDetail]);

  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight - 470);
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  return (
    <Box mt={10}>
      <ScrollArea
        h={height}
        onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
      >
        <Table
          striped
          highlightOnHover
          withColumnBorders
          withTableBorder
          style={{ overflowY: "auto", height: "30px" }}
        >
          <Table.Thead
            className={cx(classes.header, { [classes.scrolled]: scrolled })}
          >
            <Table.Tr>
              {/* <Table.Th>Mã nhóm</Table.Th> */}
              {/* <Table.Th>Nhóm khách hàng</Table.Th> */}
              <Table.Th>Mã khách</Table.Th>
              <Table.Th>Tên khách hàng</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {dataTable?.map((item, index) => (
              <Table.Tr
                key={index}
                style={{
                  backgroundColor: selectedRows.includes(item.id)
                    ? "var(--mantine-color-blue-light)"
                    : "",
                }}
              >
                {/* <Table.Td>{item.groupName}</Table.Td> */}
                <Table.Td>{item.cusCode}</Table.Td>
                <Table.Td>{item.cusName}</Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
          {dataTable.length < 1 && (
            <Table.Caption>Không có dữ liệu</Table.Caption>
          )}
        </Table>
      </ScrollArea>
    </Box>
  );
};

export default Customers;
