import {
  Button,
  ComboboxItem,
  Flex,
  Select,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { modals } from "@mantine/modals";
import { IconPlus, IconSearch } from "@tabler/icons-react";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_RowSelectionState,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useState } from "react";
import { paginationBase } from "../../../_base/model/_base/BaseTable";
import { formatDateTransfer } from "../../../common/FormatDate/FormatDate";
import {
  _timerDelayClick,
  repositoryPos,
} from "../../../_base/_const/_constVar";
import { getProvinceSelect } from "../../../service/getSelectApi";
import { _localization } from "../../../config/location";
import { TblDebtGroup } from "../../../model/TblDebtGroup";

const AddFormDebt = ({
  handleChooseDebt,
  navigate,
}: {
  handleChooseDebt: Function;
  navigate?: any;
}) => {
  const [data, setData] = useState<TblDebtGroup[]>([]);
  const [dataTemp, setDataTemp] = useState<any[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [customerInfo, setCustomerInfo] = useState<MRT_RowSelectionState>({});
  const [selectIds, setSelectIds] = useState<string[]>([]);
  const [deleteViewStatus, setDeleteViewStatus] = useState(false);
  const [timeLeft, setTimeLeft] = useState(60);
  const [isCounting, setIsCounting] = useState(false);
  const [keySearch, setKeySearch] = useState({
    keySearch: "",
    type: "",
    provinceId: "",
  });
  const [pagination, setPagination] = useState(paginationBase);
  const [dataProvinceSelect, setDataProvinceSelect] = useState<ComboboxItem[]>(
    []
  );

  const columns = React.useMemo<MRT_ColumnDef<TblDebtGroup>[]>(
    () => [
      {
        header: "STT",
        Cell: ({ row }) => (
          <Text size="12.5px">{row.index === -1 ? "" : row.index + 1}</Text>
        ),
        size: 30,
      },
      {
        accessorKey: "groupName", // Trường tương ứng với tên khách hàng
        header: "Tên khách hàng",
        enableColumnFilter: false,
        enableColumnActions: false,
        size: 50,
        Cell: ({ renderedCellValue, row }) => {
          return (
            <>
              <Text fw={600}>{renderedCellValue}</Text>
            </>
          );
        },
      },
      {
        accessorKey: "groupCode", // Trường tương ứng với tên khách hàng
        header: "Mã đối tượng công nợ",
        enableColumnFilter: false,
        enableColumnActions: false,
        size: 50,
        Cell: ({ renderedCellValue, row }) => {
          return (
            <>
              <Text fw={600}>{renderedCellValue}</Text>
            </>
          );
        },
      },
      {
        accessorKey: "taxCode", // Trường mã số thuế
        header: "Mã số thuế",
        enableColumnFilter: true,
        enableColumnActions: false,
        size: 50,
      },
      {
        accessorKey: "address", // Trường địa chỉ
        header: "Địa chỉ",
        enableColumnFilter: true,
        enableColumnActions: false,
        size: 200,
      },
      {
        accessorKey: "phone", // Trường số điện thoại
        header: "Số điện thoại",
        enableColumnFilter: true,
        enableColumnActions: false,
        size: 50,
      },
      {
        accessorKey: "contactPerson", // Trường người liên hệ
        header: "Người liên hệ",
        enableColumnFilter: true,
        enableColumnActions: false,
        size: 50,
      },
      {
        accessorKey: "debtTerm", // Trường thời hạn nợ
        header: "Thời hạn nợ",
        enableColumnFilter: true,
        enableColumnActions: false,
        size: 80,
      },
      {
        accessorKey: "debtLimit", // Trường giới hạn nợ
        header: "Giới hạn nợ",
        enableColumnFilter: true,
        enableColumnActions: false,
        size: 50,
      },
      {
        accessorKey: "createByName", // Trường tên người tạo
        header: "Người tạo",
        enableColumnFilter: true,
        enableColumnActions: false,
        size: 50,
      },
      {
        accessorKey: "createDate", // Trường ngày tạo
        header: "Ngày tạo",
        enableColumnFilter: true,
        enableColumnActions: false,
        size: 50,
      },
    ],
    []
  );

  const startCountdown = () => {
    if (!isCounting) {
      setTimeLeft(_timerDelayClick);
      setIsCounting(true);
    }
  };

  const handleChangeSearchValue = (value: string, key: string) => {
    if (value !== "Invalid date")
      setKeySearch((prevData) => ({ ...prevData, [key]: value }));
    else {
      setKeySearch((prevData) => ({ ...prevData, [key]: "" }));
    }
  };

  const renderTypeName = (value: number) => {
    switch (value) {
      case 1:
        return "Cá nhân";
      case 2:
        return "Doanh nghiệp";
      case 3:
        return "Bán buôn";
      case null:
        return "";
    }
  };

  const fetchData = async () => {
    // setIsLoading(true);
    // setIsRefetching(true);
    // setIsError(false);

    let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }`;

    if (keySearch.keySearch) {
      url += `&KeySearch=${keySearch.keySearch.trim()}`;
    }

    if (keySearch.type) {
      url += `&Type=${keySearch.type}`;
    }

    try {
      const response = await repositoryPos.get(
        `/api/v1/TblDebtGroup/get-list${url}`
      );
      if (response && response.success) {
        let result = response.data;
        setData(result);
        setDataTemp(result);
        setRowCount(response.length);
        setSelectIds([]);
        await Promise.all([getDataProvinceId()]);
        table.resetRowSelection();
      }
    } catch (error) {
      setData([]);
      setIsError(true);
      console.error(error);
      return;
    }
    // startCountdown();
  };

  const getDataProvinceId = async () => {
    const res = await getProvinceSelect();
    setDataProvinceSelect(
      res
        ?.filter((item: any) => item.value != null && item.text != null)
        ?.map((item: any) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  useEffect(() => {
    let timer: ReturnType<typeof setInterval>;
    if (isCounting && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsCounting(false);
    }
    return () => clearInterval(timer);
  }, [isCounting, timeLeft]);

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);

  useEffect(() => {
    fetchData();
  }, []);

  const table = useMantineReactTable({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    columnFilterDisplayMode: "popover",
    renderTopToolbarCustomActions: ({ table }) => (
      <Flex
        gap="md"
        justify="flex-start"
        align="center"
        direction="row"
        wrap="wrap"
      >
        <TextInput
          type="text"
          leftSection={<IconSearch color="#15aabf" />}
          placeholder="Từ khóa"
          value={keySearch.keySearch ?? ""}
          onChange={(e) =>
            handleChangeSearchValue(e.currentTarget.value, "keySearch")
          }
        />
        {/* <Select
          placeholder="Loại khách hàng"
          data={[
            { label: "Cá nhân", value: "1" },
            { label: "Doanh nghiệp", value: "2" },
          ]}
          clearable
          onChange={(e) => handleChangeSearchValue(e?.toString() ?? "", "type")}
        />
        <Select
          placeholder="Tỉnh/TP"
          data={dataProvinceSelect}
          searchable
          clearable
          onChange={(e) =>
            handleChangeSearchValue(e?.toString() ?? "", "provinceId")
          }
          nothingFoundMessage="Không tìm thấy dữ liệu !"
        /> */}
        <Button
          variant="outline"
          leftSection={<IconSearch size={14} />}
          onClick={async () => {
            await fetchData();
          }}
        >
          Tìm kiếm
        </Button>
      </Flex>
    ),
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    enableRowSelection: false,
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["code"],
        right: ["action"],
      },
      columnVisibility: { id: false },
      density: "xs",
      pagination: { pageSize: 20, pageIndex: 0 },
    },
    mantineTableContainerProps: {
      style: { maxHeight: "62.5vh", minHeight: "300px" },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    // manualFiltering: true,
    // manualPagination: true,
    // manualSorting: true,
    rowCount,
    // onColumnFiltersChange: setColumnFilters,
    // onGlobalFilterChange: setGlobalFilter,
    // onPaginationChange: setPagination,
    // onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        fontWeight: "normal", //conditional styling
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      // pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no data
      //sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["20", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    // mantineTableBodyRowProps: ({ row }) => ({
    //   onClick: row.getToggleSelectedHandler(),
    //   sx: { cursor: "pointer" },
    // }),
    mantineTableBodyRowProps: ({ row }) => ({
      onDoubleClick: () => {
        handleChooseDebt(row.original);
        modals.closeAll();
      },
      sx: {
        cursor: "pointer", //you might want to change the cursor too when adding an onClick
      },
    }),
  });

  return (
    <div style={{ marginTop: "10px" }}>
      <MantineReactTable table={table} />
    </div>
  );
};

export default AddFormDebt;
