import * as React from "react";
import "../../Styles/tab.component.css";
import { useEffect, useState } from "react";
import { MessageResponse } from "../../model/MessageResponse";
import {
  IconHandStop,
  IconFilter,
  IconSearch,
  IconDownload,
  IconCalendar,
  IconHistory,
  IconCopy,
  IconTrash,
  IconEdit,
  IconNews,
} from "@tabler/icons-react";
import {
  MRT_ColumnFiltersState,
  MRT_SortingState,
  MRT_PaginationState,
  MRT_RowSelectionState,
  MRT_ColumnDef,
  useMantineReactTable,
  MantineReactTable,
} from "mantine-react-table";
import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Flex,
  Input,
  Menu,
  rem,
  Select,
  Text,
  Title,
  Tooltip,
} from "@mantine/core";
import ProcessInstancesModel from "../../model/ProcessInstancesModel";
import { DatePickerInput } from "@mantine/dates";
import { SelectListItem } from "../../model/SelectListItem";
import { getProcessDefSelect, getUsersSelect } from "../../const/getSelectApi";
import { _localization } from "../../config/location";
import { DocumentModel } from "../../model/DocumentModel";
import { IconPlus } from "@tabler/icons-react";

const TypeContractTable = () => {
  //#region  table

  //data and fetching state
  const [data, setData] = useState<DocumentModel[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  //table state
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  //
  const dataStatus = [
    { value: "app_submit", label: "Duyệt" },
    { value: "image_signature", label: "Ký ảnh và duyệt" },
    { value: "digital_signature", label: "Ký số và duyệt" },
  ];
  const [dateUsers, setUsers] = useState<SelectListItem[]>([]);

  const [dateFrom, setDateFrom] = useState<Date | null>(null);
  const [dateTo, setDateTo] = useState<Date | null>(null);
  useEffect(() => {
    getUser();
  }, []);

  async function getUser() {
    const data = await getUsersSelect();
    if (data && data.length > 0) setUsers(data);
  }

  useEffect(() => {
    fetchData();
  }, [pagination.pageIndex, pagination.pageSize, sorting]);

  const [keySearch, setKeySearch] = useState<string>("");
  const [createdById, setcreatedById] = useState<number>(0);
  const [status, setStatus] = useState<string>("");
  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setOpenedMenu(false);
    const url = new URL("/api/v1/system/document", "https://localhost:7118");
    url.searchParams.set("page", `${pagination.pageIndex}`);
    url.searchParams.set("pageSize", `${pagination.pageSize}`);
    if (keySearch && keySearch.length > 0)
      url.searchParams.set("key", `${keySearch}`);
    let _softing = "";
    if (sorting && sorting.length > 0) {
      let _q = sorting[0];
      if (_q) _softing = _q.id + "|" + _q.desc;
    }
    url.searchParams.set("soft", `${_softing}`);
    try {
      const response = await fetch(url.href);
      const json = (await response.json()) as MessageResponse<any>;
      setData(json.data.data);
      setRowCount(json.data.count);
    } catch (error) {
      setIsError(true);
      console.error(error);
      return;
    }
    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
  };

  function progessTemplate(props: any): any {
    return (
      <>
        <Tooltip label="Chỉnh sửa">
          <ActionIcon variant="light" aria-label="Settings" color="cyan">
            <IconEdit size={14} stroke={1.5} />
          </ActionIcon>
        </Tooltip>
        <Tooltip label="Xóa">
          <ActionIcon variant="light" aria-label="Settings" color="red">
            <IconTrash size={14} stroke={1.5} />
          </ActionIcon>
        </Tooltip>
      </>
    );
  }

  const columns = React.useMemo<MRT_ColumnDef<DocumentModel>[]>(
    () => [
      {
        accessorKey: "id",
        header: "Id",
      },
      {
        accessorKey: "code",
        header: "Mã loại",
        size: 30,
      },
      {
        accessorKey: "name",
        header: "Tên tài liệu",
        size: 50,
      },
      {
        accessorKey: "description",
        header: "Mô tả",
        size: 500,
      },
      {
        accessorKey: "date",
        header: "Ngày cập nhật",
      },
      {
        accessorKey: "action",
        header: "Thao tác",
        size: 50,
        Cell: ({ renderedCellValue, row }) => (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "16px",
            }}
          >
            {progessTemplate(renderedCellValue)}
          </Box>
        ),
      },
    ],
    []
  );
  const [openedMenu, setOpenedMenu] = useState(true);

  const table = useMantineReactTable({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    //add custom action buttons to top-left of top toolbar
    renderTopToolbarCustomActions: ({ table }) => (
      <>
        <Flex
          gap="md"
          justify="flex-start"
          align="center"
          direction="row"
          wrap="wrap"
        >
          <Input
            placeholder="Nhập từ khóa ..."
            onChange={(e) => {
              setKeySearch(e.target.value ?? "");
            }}
          />

          <Button
            leftSection={<IconSearch size={14} />}
            color="blue"
            variant="outline"
            onClick={async () => {
              await fetchData();
            }}
          >
            Tìm kiếm
          </Button>
        </Flex>
      </>
    ),
    renderToolbarInternalActions: ({ table }) => (
      <>
        <Flex
          gap="md"
          justify={{ sm: "flex-start", lg: "flex-end" }}
          align="center"
          direction="row"
          wrap="wrap"
        >
          <Button
            leftSection={<IconPlus size={14} />}
            color="violet"
            variant="outline"
          >
            Tạo mới
          </Button>
        </Flex>
      </>
    ),

    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    enableRowSelection: true,
    getRowId: (row) => row.id?.toString(),
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select", "code"],
        right: ["action"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: { style: { maxHeight: "60vh" } },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    rowCount,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        fontWeight: "bold", //conditional styling
        fontSize: "11.5px",
        padding: "4px 12px",
      },
    }),
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no data
      sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["20", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
  });

  //#endregion
  return (
    <>
      <div className="mt-5">
        <MantineReactTable table={table} />
      </div>
    </>
  );
};
export default TypeContractTable;
