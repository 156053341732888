/* eslint-disable react/jsx-pascal-case */
import {
  ActionIcon,
  Box,
  Button,
  Grid,
  LoadingOverlay,
  Menu,
  Table,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconSearch } from "@tabler/icons-react";
import {
  MRT_ColumnDef,
  MRT_GlobalFilterTextInput,
  MRT_RowSelectionState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import * as React from "react";
import { useEffect, useState } from "react";
import {
  _timerDelayClick,
  repositoryPos,
} from "../../../../../../_base/_const/_constVar";
import { paginationBase } from "../../../../../../_base/model/_base/BaseTable";
import { _localization } from "../../../../../../config/location";

const TableItems = ({
  handleCheckboxChange,
  dataItem,
  isGetByCat,
}: TableItemsProps) => {
  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "itemCode",
        header: "Mã",
        enableColumnFilter: false,
        enableColumnActions: false,
        size: 30,
      },
      {
        accessorKey: "itemName",
        header: "Tên",
        enableColumnFilter: false,
        enableColumnActions: false,
      },
    ],
    []
  );

  const [data, setData] = useState<any[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [pagination, setPagination] = useState(paginationBase);

  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

  const [keySearch, setKeySearch] = useState<string>("");
  const [selectIds, setSelectIds] = useState<string[]>([]);
  const [checkDuplicateCode, setCheckDuplicateCode] = useState<string[]>([]);
  const [dataItemSearch, setDataItemSearch] = useState<
    { itemCode: string; itemName: string }[]
  >([]);

  const [timeLeft, setTimeLeft] = useState(60);
  const [isCounting, setIsCounting] = useState(false);

  const [visible, { toggle, close, open }] = useDisclosure(false);

  const startCountdown = () => {
    if (!isCounting) {
      setTimeLeft(_timerDelayClick);
      setIsCounting(true);
    }
  };

  const TableSelect = () => (
    <Table.ScrollContainer minWidth={500} maw={550} h={200} type="native">
      <Table striped highlightOnHover stickyHeader>
        <Table.Thead>
          <Table.Tr>
            <Table.Th />
            <Table.Th>Mã sản phẩm</Table.Th>
            <Table.Th>Tên sản phẩm</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {dataItemSearch?.map((item) => {
            return (
              <Table.Tr
                key={item.itemCode}
                bg={
                  checkDuplicateCode.some((select) => select === item.itemCode)
                    ? "var(--mantine-color-blue-light)"
                    : undefined
                }
              >
                <Table.Td>
                  <Button
                    size="xs"
                    variant="filled"
                    onClick={() => {
                      handleAddItem(item);
                    }}
                    disabled={checkDuplicateCode.includes(item.itemCode)}
                  >
                    {checkDuplicateCode.includes(item.itemCode ?? "")
                      ? "Đã chọn"
                      : "Chọn"}
                  </Button>
                </Table.Td>
                <Table.Td>{item.itemCode}</Table.Td>
                <Table.Td>
                  <Tooltip label={item.itemName}>
                    <Text size="sm" w={250} lineClamp={2} truncate="end">
                      {item.itemName}
                    </Text>
                  </Tooltip>
                </Table.Td>
              </Table.Tr>
            );
          })}
        </Table.Tbody>
        {dataItemSearch.length < 1 && (
          <Table.Caption>Không tìm thấy sản phẩm!</Table.Caption>
        )}
      </Table>
    </Table.ScrollContainer>
  );

  const deepCompare = (obj1: any, obj2: any) => {
    if (typeof obj1 !== "object" || typeof obj2 !== "object") {
      return obj1 === obj2;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) return false;

    for (let key of keys1) {
      if (!deepCompare(obj1[key], obj2[key])) {
        return false;
      }
    }

    return true;
  };

  const fetchDataItem = async () => {
    if (keySearch) {
      try {
        const response = await repositoryPos.get(
          `/api/v1/TblItem/get-list-by-cat?Skip=0&Take=30&KeySearch=${keySearch}`
        );

        setDataItemSearch(response?.data);
      } catch (error) {
        setDataItemSearch([]);
      }
    }
  };

  const handleAddItem = (dataAdd: any) => {
    setData((prevData) => [...prevData, { ...dataAdd }]);
    setCheckDuplicateCode((prev) => [...prev, dataAdd.itemCode]);
  };

  const fetchData = async () => {
    open();
    setIsLoading(true);
    setIsRefetching(true);
    setIsError(false);
    try {
      if (dataItem && dataItem.length > 0) {
        let result = dataItem;
        setData((prev) => [...prev, ...result]);
        setRowCount([...data, ...result].length);
        setSelectIds([]);
        // table.resetRowSelection();
      }
    } catch (error) {
      setData([]);
      setIsError(true);
      console.error(error);
      return;
    } finally {
      close();
      setIsLoading(false);
      setIsRefetching(false);
    }
    startCountdown();
  };

  useEffect(() => {
    let timer: ReturnType<typeof setInterval>;
    if (isCounting && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsCounting(false);
    }
    return () => clearInterval(timer);
  }, [isCounting, timeLeft]);

  useEffect(() => {
    const selectedRows = table.getSelectedRowModel().rows;
    const selectedOriginalData = selectedRows.map((row) => row.original);

    handleCheckboxChange(selectedOriginalData);
  }, [rowSelection]);

  useEffect(() => {
    const updatedRowSelection = data?.reduce((acc, _, index) => {
      acc[index] = true;
      return acc;
    }, {});

    table.setRowSelection(updatedRowSelection);
  }, [data, dataItem]);

  useEffect(() => {
    fetchData();
  }, [dataItem]);

  useEffect(() => {
    if (keySearch && keySearch.length > 2) {
      fetchDataItem();
    } else {
      setDataItemSearch([]);
    }
  }, [keySearch]);

  const table = useMantineReactTable({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    columnFilterDisplayMode: "popover",
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: {
      ..._localization,
      selectedCountOfRowCountRowsSelected: "",
      clearSelection: "",
    },
    manualPagination: true,
    enableRowSelection: true,
    // enableTopToolbar: false,
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: true,
      showGlobalFilter: true,
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: 380, minHeight: 380 },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    onPaginationChange: setPagination,
    rowCount,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontWeight: "500",
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      isLoading,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
      pagination,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: false,
      withEdges: true,
      rowsPerPageOptions: ["10", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    enableTopToolbar: false,
    mantineTableProps: {
      striped: false,
    },
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });

  return (
    <>
      <Box mt={10} w={"95%"}>
        <LoadingOverlay
          visible={visible}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />
        <Grid align="center">
          <Grid.Col span={{ base: 12, md: 4, lg: 4 }}>
            <MRT_GlobalFilterTextInput
              disabled={data.length === 0}
              table={table}
              placeholder="Nhập tên sản phẩm"
              rightSection={
                <ActionIcon variant="light">
                  <IconSearch size={14} />
                </ActionIcon>
              }
              leftSection={<></>}
              styles={{
                input: {
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  paddingLeft: "10px",
                },
              }}
              size="xs"
              w={"100%"}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 4, lg: 4 }}>
            <Menu
              trapFocus={false}
              trigger="hover"
              shadow="md"
              width={"800"}
              position="bottom-start"
            >
              <Menu.Target>
                <TextInput
                  placeholder="Tìm kiếm để thêm sản phẩm"
                  size="xs"
                  onChange={async (e) => {
                    const _key = e.target.value ?? "";
                    setKeySearch(_key);
                  }}
                />
              </Menu.Target>
              <Menu.Dropdown maw={550}>
                <TableSelect />
              </Menu.Dropdown>
            </Menu>
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 4, lg: 4 }}></Grid.Col>
        </Grid>

        <Box p={"10px 0px"}>
          <MantineReactTable table={table} />
        </Box>
      </Box>
    </>
  );
};

type TableItemsProps = {
  handleCheckboxChange: any;
  dataItem: any[];
  isGetByCat?: boolean;
};

export default TableItems;
