import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Flex,
  Input,
  Title,
  Tooltip,
} from "@mantine/core";
import { modals } from "@mantine/modals";
import {
  IconActivity,
  IconEdit,
  IconFunction,
  IconHandStop,
  IconManualGearbox,
  IconPlus,
  IconSearch,
  IconShoppingBag,
  IconTrash,
  IconUser,
  IconZoomReset,
} from "@tabler/icons-react";
import {
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_ExpandedState,
  MRT_PaginationState,
  MRT_RowSelectionState,
  MRT_SortingState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { NotificationExtension } from "../../_base/extension/NotificationExtension";
import Repository from "../../_base/helper/HttpHelper";
import { _localization } from "../../config/location";
import { getProcessDefSelect, getUsersSelect } from "../../const/getSelectApi";
import { MessageResponse } from "../../model/MessageResponse";
import { SelectListItem } from "../../model/SelectListItem";
import "../../Styles/tab.component.css";
import {
  _timerDelayClick,
  _timerDelaySearch,
  repositoryMaster,
} from "../../_base/_const/_constVar";
import UserCreate from "./MenuGroupCreate";
import { UserMenuGroup } from "../../model/UserMenuGroup";
import UserEdit from "./MenuGroupEdit";
import _breadcrumb from "../../_base/component/_layout/_breadcrumb";
import MenuGroupCreate from "./MenuGroupCreate";
import { formatDateTransfer } from "../../common/FormatDate/FormatDate";
import MenuGroupEdit from "./MenuGroupEdit";
import AddRouterToMenuGroup from "./AddRouterToMenuGroup";
import MenuGroupEmployAdd from "./MenuGroupEmployAdd ";
import { useDebouncedState } from "@mantine/hooks";

const UserListView = () => {
  //#region  table
  const headerRef = React.useRef<HTMLDivElement>(null);
  //data and fetching state
  const [data, setData] = useState<UserMenuGroup[]>([]);
  const [dataTemp, setDataTemp] = useState<UserMenuGroup[]>([]);

  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  //table state
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 50,
  });
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  //

  const [keySearch, setKeySearch] = useDebouncedState('', 200);
  const [selectIds, setSelectIds] = useState<string[]>([]);

  const [deleteViewStatus, setDeleteViewStatus] = useState(0);
  //count
  const [timeLeft, setTimeLeft] = useState(_timerDelaySearch);
  const [isCounting, setIsCounting] = useState(false);

  useEffect(() => {
    let timer: ReturnType<typeof setInterval>;
    if (isCounting && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsCounting(false);
    }
    return () => clearInterval(timer);
  }, [isCounting, timeLeft]);

  const startCountdown = () => {
    if (!isCounting) {
      setTimeLeft(_timerDelaySearch);
      setIsCounting(true);
    }
  };

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);

  useEffect(() => {
    fetchData();
  }, [deleteViewStatus, sorting, pagination.pageIndex, pagination.pageSize]);

  const fetchData = async () => {
    if (!isCounting) {
      startCountdown();
      setIsLoading(true);
      setIsRefetching(true);
      setOpenedMenu(false);
      try {
        let urlGet =
          "/api/v1/menugroup/get-list?skip=" +
          pagination.pageIndex * pagination.pageSize +
          "&take=" +
          pagination.pageSize;

        if (keySearch && keySearch.length > 0)
          urlGet = urlGet + "&keySearch=" + keySearch;
        let _softing = "";
        let isOrder = false;
        if (sorting && sorting.length > 0) {
          let _q = sorting[0];
          if (_q) {
            _softing = _q.id;
            isOrder = _q.desc;
          }
        }
        urlGet = urlGet + "&sort=" + _softing + "&isOrder=" + isOrder;

        const response = await repositoryMaster.get<
          MessageResponse<UserMenuGroup[]>
        >(urlGet);
        if (response && response.success) {
          setData(response.data ?? []);
          setRowCount(response.totalCount);
          setSelectIds([]);
          table.resetRowSelection();
        }
      } catch (error) {
        setIsError(true);
        console.error(error);
        return;
      }
      setIsError(false);
      setIsLoading(false);
      setIsRefetching(false);
    }
  };

  const activeData = async (status: boolean) => {
    if (selectIds.length < 1)
      NotificationExtension.Success("Bạn chưa chọn dữ liệu !");
    else
      try {
        const res = await repositoryMaster.post<MessageResponse<boolean>>(
          "/api/v1/menugroup/actives",
          {
            ids: selectIds,
            status: status,
          }
        );
        if (res && res.success) {
          NotificationExtension.Success("Cập nhật thành công !");
          await fetchData();
        } else NotificationExtension.Fails("Cập nhật thất bại !");
      } catch (error) {
        console.error(error);
        return;
      }
  };
  const activesData = async (status: boolean, id: string) => {
    if (!id || id.length < 1)
      NotificationExtension.Success("Bạn chưa chọn dữ liệu !");
    else
      try {
        const res = await repositoryMaster.post<MessageResponse<boolean>>(
          "/api/v1/menugroup/actives",
          {
            ids: [id],
            status: status,
          }
        );
        if (res && res.success) {
          await fetchData();
          NotificationExtension.Success("Cập nhật thành công !");
        } else NotificationExtension.Fails("Cập nhật thất bại !");
      } catch (error) {
        console.error(error);
        return;
      }
  };
  const deletesData = async (ids: string[]) => {
    if (ids.length < 1)
      NotificationExtension.Success("Bạn chưa chọn dữ liệu !");
    else
      try {
        modals.openConfirmModal({
          title: (
            <>
              <Title order={5}>Xóa !</Title>
            </>
          ),

          size: "550px",
          children: <>Bạn có thực sự muốn xóa ?</>,
          labels: { confirm: "Lưu lại", cancel: "Hủy bỏ" },
          onConfirm: async () => {
            const res = await repositoryMaster.post<MessageResponse<boolean>>(
              "/api/v1/menugroup/delete",
              ids
            );
            if (res)
              if (res.success) {
                await fetchData();
                NotificationExtension.Success("Xóa thành công !");
              } else NotificationExtension.Fails("Xóa thất bại !");
          },
        });
      } catch (error) {
        console.error(error);
        return;
      }
  };
  function createItem() {
    setDeleteViewStatus(0);
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Thêm mới !</Title>
        </>
      ),

      size: "350px",
      children: <MenuGroupCreate onLoad={fetchData} />,
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  }

  function editItem(id: number) {
    setDeleteViewStatus(0);
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Chỉnh sửa !</Title>
        </>
      ),

      size: "550px",
      children: <MenuGroupEdit id={id} onLoad={fetchData} />,
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  }

  const groupAddRoleModal = (idAuthozire: string) => {
    modals.closeAll();

    modals.openConfirmModal({
      title: "Danh sách nhóm",

      size: "100%",
      fullScreen: false,
      children: <AddRouterToMenuGroup idGroup={idAuthozire} />,
      labels: { confirm: "Confirm", cancel: "Hủy bỏ" },
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };
  const groupAddEmpModal = (idAuthozire: string) => {
    modals.closeAll();

    modals.openConfirmModal({
      title: "Danh sách nhân viên",
      size: "100%",
      fullScreen: false,
      children: <MenuGroupEmployAdd  idGroup={idAuthozire} />,
      labels: { confirm: "Confirm", cancel: "Hủy bỏ" },
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };
  function getColorStatus(text: boolean) {
    switch (text) {
      case true:
        return "teal";
      default:
        return "red";
    }
  }

  const columns = React.useMemo<MRT_ColumnDef<UserMenuGroup>[]>(
    () => [
      {
        accessorKey: "id",
        header: "Id",
      },
      {
        accessorKey: "name",
        header: "Tên nhóm",
      },
      // {
      //   accessorKey: "listrouter",
      //   header: "Tên đầy đủ",
      // },
      {
        accessorKey: "active",
        header: "Trạng thái",
        Cell: ({ renderedCellValue, row }) => (
          <>
            <Badge color={getColorStatus(row.original.active ?? false)}>
              {row.original.active ? "Đang hoạt động" : "Dừng hoạt động"}
            </Badge>
          </>
        ),
      },
      {
        accessorKey: "createby",
        header: "Người tạo",
      },
      {
        accessorKey: "createdate",
        header: "Ngày tạo (UTC)",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
      },

      {
        accessorKey: "action",
        header: "Phân quyền và thao tác",
        Cell: ({ renderedCellValue, row, table }) => (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "16px",
            }}
          >
            <Tooltip label="Nhóm">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="cyan"
                onClick={() => {
                  groupAddRoleModal(row.original.id.toString());
                }}
              >
                <IconFunction size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
            <Tooltip label="Nhân viên">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="cyan"
                onClick={() => {
                  groupAddEmpModal(row.original.id.toString());
                }}
              >
                <IconUser size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>

            <Tooltip label="Chỉnh sửa">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="orange"
                onClick={async () => {
                  editItem(row.original.id);
                }}
              >
                <IconEdit size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>

            <Tooltip
              label={
                row.original.active === true ? "Dừng hoạt động" : "Hoạt động"
              }
            >
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color={row.original.active === true ? "violet" : "green"}
                onClick={async () => {
                  await activesData(
                    !row.original.active,
                    row.original.id.toString()
                  );
                }}
              >
                {row.original.active === true ? (
                  <IconHandStop size={20} />
                ) : (
                  <IconActivity size={20} />
                )}
              </ActionIcon>
            </Tooltip>

            <Tooltip label="Xóa">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="red"
                onClick={async () => {
                  await deletesData([row.original.id.toString()]);
                }}
              >
                <IconTrash size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
          </Box>
        ),
      },
    ],
    []
  );
  const [openedMenu, setOpenedMenu] = useState(false);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 160 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (200 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);
  const table = useMantineReactTable({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    //add custom action buttons to top-left of top toolbar
    renderTopToolbarCustomActions: ({ table }) => (
      <div ref={headerRef}>
        <_breadcrumb></_breadcrumb>
        <Flex
          gap="md"
          justify="flex-start"
          align="center"
          direction="row"
          wrap="wrap"
        >
          <Input
            placeholder="Nhập từ khóa ..."
            onChange={(e) => {
              setKeySearch(e.target.value ?? "");
            }}
          />

          <Button
            leftSection={<IconSearch size={14} />}
            color="blue"
            variant="outline"
            disabled={isCounting}
            onClick={async () => {
              await fetchData();
            }}
          >
            {timeLeft > 0 && timeLeft} Tìm kiếm
          </Button>
        </Flex>
      </div>
    ),
    renderToolbarInternalActions: ({ table }) => (
      <>
        <Flex
          gap="md"
          justify={{ sm: "flex-start", lg: "flex-end" }}
          align="center"
          direction="row"
          wrap="wrap"
        >
          <Button
            leftSection={<IconPlus size={14} />}
            color="blue"
            variant="outline"
            onClick={async () => {
              createItem();
            }}
          >
            Thêm mới
          </Button>

          <Button
            leftSection={<IconActivity size={14} />}
            color="green"
            variant="outline"
            onClick={async () => {
              await activeData(true);
            }}
            disabled={selectIds.length < 1}
          >
            Hoạt động
          </Button>
          <Button
            leftSection={<IconHandStop size={14} />}
            color="red"
            variant="outline"
            onClick={async () => {
              await activeData(false);
            }}
            disabled={selectIds.length < 1}
          >
            Dừng hoạt động
          </Button>
          <Button
            leftSection={<IconTrash size={14} />}
            color="red"
            variant="outline"
            onClick={async () => {
              await deletesData(selectIds);
            }}
            disabled={selectIds.length < 1}
          >
            Xóa nhóm (đã chọn)
          </Button>
        </Flex>
      </>
    ),

    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    enableRowNumbers: true,
    localization: _localization,
    enableRowSelection: true,
    getRowId: (row) => row?.id?.toString(),
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select", "userName"],
        right: ["action"],
      },
      columnVisibility: { id: false },
      density: "xs",
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    rowCount,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        fontWeight: "bold", //conditional styling
        fontSize: "12.5px",
        padding: "5px 15px",
        //  backgroundColor: row.original.userName.length>0 ? "white" : "gray",
      },
    }),
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no data
      sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["50", "100", "150"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
  });
  //#endregion
  return (
    <>
      <div className="mt-5">
        <MantineReactTable table={table} />
      </div>
    </>
  );
};
export default UserListView;
