import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Fieldset,
  Flex,
  Group,
  Menu,
  Table,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import {
  IconArrowLeft,
  IconDeviceFloppy,
  IconPlus,
  IconTrash,
} from "@tabler/icons-react";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_RowSelectionState,
  useMantineReactTable,
} from "mantine-react-table";
import * as React from "react";
import { useEffect, useState } from "react";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { useLocation, useNavigate } from "react-router-dom";
import { repositoryMdm, repositoryPos } from "../../../_base/_const/_constVar";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import { _localization } from "../../../config/location";
import ProductionOrderDetail from "./ProductionOrderDetail/ProductionOrderDetail";
import "./tab.component.css";
import { ResponseDataTblItemTransaction } from "../../../model/TblInventorySerialOnHand";

const EditWip = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const idProductionOrder = location.state && location.state.id;
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState<any>([]);
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [keySearch, setKeySearch] = useState("");
  const [isTableVisible, setIsTableVisible] = useState(false);
  const [dataTableSelect, setDataTableSelect] = useState<any>([]);
  const form = useForm({
    mode: "uncontrolled",
    initialValues: {
      email: "",
      termsOfService: false,
    },
  });

  //table khi hover vào input tìm kiếm serial
  const TableSelect = () => (
    <Table.ScrollContainer minWidth={500} h={300} type="native">
      <Table striped highlightOnHover stickyHeader>
        <Table.Thead>
          <Table.Tr>
            <Table.Th />
            <Table.Th>Mã sản phẩm</Table.Th>
            <Table.Th>Tên sản phẩm</Table.Th>
            <Table.Th>Số serial</Table.Th>
            <Table.Th>Tuổi tồn</Table.Th>
            <Table.Th>Kho</Table.Th>
            <Table.Th>Đơn vị tính</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {dataTableSelect.map((item: any, index: number) => (
            <Table.Tr key={index}>
              <Table.Td>
                <Button size="xs" variant="filled">
                  Chọn
                </Button>
              </Table.Td>
              <Table.Td>{item.itemCode}</Table.Td>
              <Table.Td>{item.itemName}</Table.Td>
              <Table.Td>{item.serial}</Table.Td>
              <Table.Td>{item.age}</Table.Td>
              <Table.Td>{item.invName}</Table.Td>
              <Table.Td>{item.primaryUomName}</Table.Td>
            </Table.Tr>
          ))}
        </Table.Tbody>

        <Table.Caption>Không tìm thấy sản phẩm!</Table.Caption>
      </Table>
    </Table.ScrollContainer>
  );
  const MenuDataOutWart = () => (
    <Menu
      trapFocus={false}
      trigger="hover"
      shadow="md"
      width={"800"}
      position="bottom-start"
    >
      <Menu.Target>
        <TextInput
          placeholder="Hoặc tìm kiếm mã sản phẩm"
          size="xs"
          onChange={async (e) => {
            const _key = e.target.value ?? "";
            setKeySearch(_key);
            if (_key.length > 0) {
              setIsTableVisible(true);
            } else {
              setIsTableVisible(false);
            }
          }}
          style={{ width: "200px" }}
        />
      </Menu.Target>
      {isTableVisible && <Menu.Dropdown>{<TableSelect />}</Menu.Dropdown>}
    </Menu>
  );
  // Get Data Production Order Detail
  const fechDataProductionOrderDetail = async () => {
    setIsLoading(true);
    setIsError(false);
    try {
      const response = await repositoryMdm.get(
        `/api/v1/TblProductionOrder/detail?id=${idProductionOrder}`
      );
      if (response && response.success) {
        let result = response?.data;
        setData(result || []);
      } else {
        setIsError(true);
      }
    } catch (error) {
      setIsError(true);
      setData([]);
    } finally {
      setIsLoading(false);
    }
  };
  // lấy data ở tìm kiếm serial
  const fetchDataItem = async () => {
    if (keySearch) {
      try {
        // if (!idDetail) {
        //   console.log("idDetail is not defined or has no value");
        //   return;
        // }

        // console.log("idDetail before API call:", idDetail);

        const response =
          await repositoryPos.get<ResponseDataTblItemTransaction>(
            `/api/v1/TblItem/get-list?KeySearch=${keySearch}&Skip=0&Take=100`
          );
        if (response) {
          const result = response.data;
          setDataTableSelect(result);
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  useEffect(() => {
    fechDataProductionOrderDetail();
  }, [idProductionOrder]);
  useEffect(() => {
    if (keySearch && keySearch.length > 2) {
      fetchDataItem();
    }
  }, [keySearch]);
  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 160 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (310 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "index",
        header: "STT",
        Cell: ({ row }) => row.index + 1,
        size: 30,
      },
      {
        accessorKey: "invName",
        header: "Kho",
        size: 100,
      },
      {
        accessorKey: "itemCode",
        header: "Mã hàng",
        size: 100,
      },
      {
        accessorKey: "serial",
        header: "Serial",
        size: 100,
      },
      {
        accessorKey: "itemName",
        header: "Tên SP",
        Cell: ({ row }) => (
          <Tooltip label={row.original.itemName}>
            <Text size="sm" w={300}>
              {row.original.itemName}
            </Text>
          </Tooltip>
        ),
      },

      {
        accessorKey: "primaryUomName",
        header: "ĐVT1",
        size: 30,
      },
      {
        accessorKey: "primaryQuantity",
        header: "SL1",
        size: 30,
      },

      {
        accessorKey: "subQuantity",
        header: "SL2",
        size: 30,
      },
      {
        accessorKey: "subUomName",
        header: "ĐVT2",
        size: 30,
      },
      {
        header: "Giá theo ĐVT2",
        size: 30,
      },
      {
        header: "Thành tiền",
        size: 30,
      },
      {
        accessorKey: "status",
        header: "Tình trạng",
        size: 100,
      },
      {
        header: "Ghi chú",
        size: 150,
      },
      {
        accessorKey: "warning",
        header: "Cảnh báo",
        Cell: ({ row }) =>
          !row.original.isMapping ? (
            <Tooltip label={row.original.warning}>
              <Text size="sm" w={300} c={"red"}>
                {row.original.warning}
              </Text>
            </Tooltip>
          ) : null,
      },

      {
        accessorKey: "action",
        header: "Thao tác",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
        size: 30,
        Cell: ({ row }) => (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Tooltip label="Xóa">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="red"
                onClick={async () => {}}
              >
                <IconTrash size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
          </Box>
        ),
      },
    ],
    []
  );

  const columnsOrderDetail = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "id",
        header: "Mã linh kiện",
        Cell: ({ renderedCellValue }) => (
          <Badge
            radius="sm"
            variant="dot"
            size="lg"
            color={renderedCellValue === null ? "red" : "#004d00"}
          >
            {renderedCellValue === null ? null : renderedCellValue}
          </Badge>
        ),
        size: 200,
      },
      {
        accessorKey: "tenVt",
        header: "Tên linh kiện",
        Cell: ({ renderedCellValue }) => (
          <Text size="12.5px" fw={500} truncate="end" h={15}>
            {renderedCellValue}
          </Text>
        ),
        maxSize: 100,
      },
      {
        accessorKey: "soLuong",
        header: "Số lượng",
        Cell: ({ renderedCellValue }: any) => <>{renderedCellValue}</>,
        size: 200,
      },
      {
        accessorKey: "dvt",
        header: "Đơn vị",
        Cell: ({ renderedCellValue }: any) => <>{renderedCellValue}</>,
        size: 200,
      },
    ],
    []
  );

  const table = useMantineReactTable({
    columns,
    data: [],
    positionToolbarAlertBanner: "bottom",
    enableColumnActions: false,
    //add custom action buttons to top-left of top toolbar
    renderTopToolbarCustomActions: () => (
      <form
        onSubmit={form.onSubmit((values: any) => console.log(values))}
        style={{ width: "100%" }}
      >
        <Flex direction="row" w={"100%"} gap="lg" align="center">
          <TextInput
            withAsterisk
            placeholder="Serial"
            key={form.key("email")}
            {...form.getInputProps("email")}
            size="xs"
            style={{ width: "200px" }}
          />
          <Group justify="flex-end">
            <Button
              leftSection={<IconPlus size={14} />}
              color="blue"
              variant="outline"
              type="submit"
              size="xs"
            >
              Thêm
            </Button>
          </Group>
          {/* <TextInput
            placeholder="Hoặc tìm kiếm mã sản phẩm"
            size="xs"
            onChange={async (e) => {
              setKeySearch(e.target.value);
            }}
            style={{ width: "200px" }}
          /> */}
          {MenuDataOutWart()}
        </Flex>
      </form>
    ),
    enableToolbarInternalActions: false,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    // localization: _localization,
    // // enableRowSelection: true,
    // getRowId: (row) => row.id,
    // initialState: {
    //   showColumnFilters: false,
    //   columnPinning: {
    //     left: ["mrt-row-select", "index"],
    //     right: ["action"],
    //   },
    //   columnVisibility: { id: false, phone: false, fax: false },
    //   density: "xs",
    // },
    mantineTableContainerProps: {
      style: { height: 180, minHeight: 100 },
    },
    enableStickyHeader: true,
    enablePagination: false,
    enableBottomToolbar: false,
    onRowSelectionChange: setRowSelection,
    // manualFiltering: true,
    // manualPagination: true,

    // onColumnFiltersChange: setColumnFilters,
    // onGlobalFilterChange: setGlobalFilter,
    // onPaginationChange: setPagination,
    // onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        fontWeight: "normal", //conditional styling
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      //  pagination,
      showAlertBanner: isError,

      showSkeletons: isLoading, //loading for the first time with no data
      //sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["10", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    // columnFilterDisplayMode: "popover",
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });
  const handleReturnPage = () => {
    navigate(-1);
  };
  const tableOrderDetail = useMantineReactTable({
    columns: columnsOrderDetail,
    data: data?.tblChitietVatTuModels || [],
    positionToolbarAlertBanner: "bottom",
    //add custom action buttons to top-left of top toolbar
    enableTopToolbar: false,
    enableToolbarInternalActions: false,
    enableColumnActions: false,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    // enableRowSelection: true,
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select"],
        right: ["action"],
      },
      //   columnVisibility: getColumnVisibility(),
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { height: 180, minHeight: 100 },
    },
    enableStickyHeader: true,
    enablePagination: false,
    enableBottomToolbar: false,
    onRowSelectionChange: setRowSelection,
    // manualFiltering: true,
    // manualPagination: true,
    // manualSorting: true,

    // onColumnFiltersChange: setColumnFilters,
    // onGlobalFilterChange: setGlobalFilter,
    // onPaginationChange: setPagination,
    // onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        fontWeight: "normal", //conditional styling
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      //  pagination,
      showAlertBanner: isError,
      showSkeletons: isLoading, //loading for the first time with no data
      //sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    // mantinePaginationProps: {
    //   showRowsPerPage: true,
    //   withEdges: true,
    //   rowsPerPageOptions: ["10", "50", "100"],
    // },
    // paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    // columnFilterDisplayMode: "popover",
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });
  const titleSell = () => {
    return (
      <>
        <Group mt={-10} align="center" justify="space-between">
          <Flex
            gap="md"
            justify="flex-start"
            align="center"
            direction="row"
            wrap="wrap"
          >
            <Group gap={20}>
              <Text fw={700}>Loại phiếu:</Text>
              <Badge variant="light" size="xs" color="blue" radius="md">
                Xuất linh kiện sản xuất
              </Badge>
            </Group>
          </Flex>
          <Flex
            gap="md"
            justify={{ sm: "flex-start", lg: "flex-end" }}
            wrap="wrap"
            align="center"
            direction="row"
          >
            <Button
              leftSection={<IconDeviceFloppy size={14} />}
              color="blue"
              size="xs"
              onClick={() => handleReturnPage()}
            >
              Cập nhật
            </Button>
            <Button
              leftSection={<IconArrowLeft size={14} />}
              color="red"
              size="xs"
              onClick={() => handleReturnPage()}
            >
              Quay lại
            </Button>
          </Flex>
        </Group>
      </>
    );
  };

  return (
    <Box>
      <div ref={headerRef}>
        <_breadcrumb></_breadcrumb>
        <Box my={"10px"}>{titleSell()}</Box>
      </div>
      <Box
        style={{
          maxHeight: `${height}px`,
          minHeight: `${height}px`,
        }}
      >
        <PanelGroup direction="horizontal" id="group">
          <Panel defaultSize={35} minSize={28} maxSize={40}>
            <Fieldset legend={"Thông tin mã lệnh"} p={5}>
              <ProductionOrderDetail data={data} />
            </Fieldset>
          </Panel>
          <PanelResizeHandle id="resize-handle" />
          <Panel defaultSize={65} minSize={35} maxSize={85}>
            <Box>
              <Fieldset p={10} pb={0} legend={"Serial linh kiện sản xuất"}>
                <MantineReactTable table={table} />
              </Fieldset>
              <Fieldset p={10} pb={0} mt={10} legend={"Linh kiện sản xuất"}>
                <MantineReactTable table={tableOrderDetail} />
              </Fieldset>
              <Fieldset p={10} pb={5} mt={10}>
                <form
                  onSubmit={form.onSubmit((values: any) => console.log(values))}
                  style={{ width: "100%" }}
                >
                  <Flex direction="row" w={"100%"} gap="lg" align="center">
                    <Group justify="flex-end">
                      <Button color="blue" variant="outline" type="submit">
                        Mã Serial thành phẩm
                      </Button>
                    </Group>
                    <TextInput
                      withAsterisk
                      placeholder="Nhập mã serial thành phẩm"
                      key={form.key("email")}
                      {...form.getInputProps("email")}
                      w={"85%"}
                    />
                  </Flex>
                </form>
              </Fieldset>
            </Box>
          </Panel>
        </PanelGroup>
      </Box>
    </Box>
  );
};
export default EditWip;
