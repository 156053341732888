import {
  Badge,
  Box,
  Button,
  Fieldset,
  Flex,
  Grid,
  LoadingOverlay,
  ScrollArea,
  Text,
  Textarea,
  TextInput,
} from "@mantine/core";
import { IconArrowBack, IconPrinter } from "@tabler/icons-react";
import React, { useEffect, useState } from "react";

import { useDisclosure } from "@mantine/hooks";
import {
  MantineReactTable,
  MRT_ColumnDef,
  useMantineReactTable,
} from "mantine-react-table";
import { useLocation, useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { repositoryMdm } from "../../../../_base/_const/_constVar";
import { NotificationExtension } from "../../../../_base/extension/NotificationExtension";
import { styleCellTable } from "../../../../_setup/navdata/baseStyleCellTable";
import { formatDateTransfer } from "../../../../common/FormatDate/FormatDate";
import { _localization } from "../../../../config/location";
import PrintImportBorrowedGoods from "../DMImportBorrowedGoods/PrintImportBorrowedGoods";
import PrintExportBorrowedGoods from "./PrintExportBorrowedGoods";
const formatCurrency = (value: any) => {
  if (value == null) {
    return "";
  }
  // Định dạng số theo chuẩn Việt Nam
  return value.toLocaleString("vi-VN", {
    minimumFractionDigits: 0, // Không hiển thị phần thập phân
    maximumFractionDigits: 0, // Đảm bảo không có chữ số sau dấu phẩy
  });
};
const DetailExportBorredGood = () => {
  // #region handleData Detail
  const location = useLocation();
  const headerRef = React.useRef<HTMLDivElement>(null);
  const contentRef = React.useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [height, setHeight] = useState(0);
  const [visible, { open, close }] = useDisclosure(false);
  //   data
  const [dataDetail, setDataDetail] = useState<any>();
  // const entity = {
  //   // id: number | string,
  //   empId: null,
  //   requestId: null,
  //   errorNumber: null,
  //   dateReceive: new Date().toString(),
  //   inventoryCode: null,
  //   note: null,
  //   idItemPosition: null,
  //   tblWarrantyReceiveTicketDetailCommands: null,
  // };

  const dataAll = [
    ...(dataDetail?.tblWarrantyExItemBorrowTicketAddDetailModels || []),
    ...(dataDetail?.tblWarrantyExportItemBorrowTicketDetailModels || []),
  ];
  // const form = useForm<TblDmWarrantyReceiveTicket>({
  //   mode: "uncontrolled",
  //   validateInputOnChange: true,
  //   initialValues: {
  //     ...entity,
  //   },

  //   validate: {
  //     inventoryCode: (value: string | null) => {
  //       if (!value) {
  //         return "Vui lòng nhập tên trung tâm !";
  //       }
  //     },
  //     empId: (value: number | null) => {
  //       if (!value) {
  //         return "Vui lòng nhập tên người tiếp nhận !";
  //       }
  //     },
  //   },
  // });
  const fetchDataDetail = async () => {
    open();
    try {
      if (location?.state.id) {
        const response = await repositoryMdm.get(
          `/api/v1/TblWarrantyExportItemBorrowTicket/detail?id=${location?.state.id}`
        );
        if (response && response.httpStatusCode === 200) {
          const result = response.data;
          setDataDetail(result);
        } else {
          NotificationExtension.Fails("Không tìm thấy dữ liệu");
        }
      }
    } catch (error) {
      NotificationExtension.Fails("Đã xảy ra lỗi");
      console.log("error", error);
    } finally {
      close();
    }
  };
  const columns = React.useMemo<MRT_ColumnDef<[]>[]>(
    () => [
      {
        accessorKey: "type",
        header: "Loại",
        enableEditing: false,
        size: 100,
        Cell: ({ renderedCellValue }: any) => (
          <>
            {renderedCellValue === "Sản phẩm bảo hành" ? (
              <Badge color="blue" radius="md">
                Bảo hành
              </Badge>
            ) : (
              <Badge color="green" radius="md">
                Cho mượn
              </Badge>
            )}
          </>
        ),
      },
      {
        accessorKey: "itemCode",
        header: "Mã sản phẩm",
        size: 100,
      },

      {
        accessorKey: "itemName",
        header: "Tên sản phẩm",
        enableEditing: false,
        size: 100,
      },
      // {
      //   accessorKey: "serial",
      //   header: "Serial",
      //   enableEditing: false,
      //   size: 100,
      // },
      {
        accessorKey: "uomName",
        header: "Đơn vị tính",
        enableEditing: false,
        size: 100,
      },

      {
        accessorKey: "amount",
        header: "Số lượng",
        size: 100,
      },
      {
        accessorKey: "acessory",
        header: "Phụ kiện theo kèm",
        enableEditing: false,
        size: 100,
      },
      {
        accessorKey: "condition",
        header: "Tình trạng",
        enableEditing: false,
        size: 100,
      },
      {
        accessorKey: "priceOld",
        header: "Giá",
        size: 100,
        Cell: ({ renderedCellValue, row }: any) => (
          <>{Number(renderedCellValue)?.toLocaleString("vi-VN")} </>
        ),
      },
      {
        accessorKey: "priceNew",
        header: "Giá hiện hành",
        size: 100,
        Cell: ({ renderedCellValue, row }: any) => (
          <>{renderedCellValue?.toLocaleString("vi-VN")} </>
        ),
      },
      {
        accessorKey: "note",
        header: "Ghi chú",
        enableEditing: false,
        size: 100,
      },
    ],
    []
  );
  const table = useMantineReactTable<[]>({
    columns,
    data: dataAll || [],
    positionToolbarAlertBanner: "bottom",
    createDisplayMode: "row",
    editDisplayMode: "cell",
    enableEditing: false,
    enableRowActions: false,
    enableColumnActions: false,
    enableColumnOrdering: false,
    enableBatchRowSelection: true,
    // onCreatingRowCancel: () => setValidationErrors({}),
    // onEditingRowCancel: () => setValidationErrors({}),
    // positionActionsColumn: "last",

    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    enableTopToolbar: false,
    localization: _localization,
    // getRowId: (row) => row.itemId?.toString(),
    mantineTableContainerProps: {
      style: { maxHeight: height - 100, minHeight: height - 100 },
    },
    initialState: {
      columnPinning: {
        left: ["type"],
        // right: ["mrt-row-actions"],
      },
      columnVisibility: { id: true },
      density: "xs",
    },

    enableStickyHeader: true,
    // onRowSelectionChange: setRowSelection,
    manualSorting: false,
    // rowCount,
    mantineTableBodyCellProps: ({ row }) => ({
      style: styleCellTable(row),
    }),
    enablePagination: false,
    enableBottomToolbar: false,
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
  });
  // #region handle Print
  const [idPrint, setIdPrint] = useState<string | number>();
  const [dataPrint, setDataPrint] = useState<any>();
  const componentRef = React.useRef(null);
  const handlePrint = useReactToPrint({
    contentRef: componentRef,
    // onBeforePrint: () => console.log("Before printing..."),
    // onAfterPrint: () => console.log("After printing..."),
    pageStyle: `
    @page {
      size:auto;
      margin: 5mm 0;
  }`,
    // documentTitle: " BIÊN BẢN BÀN GIAO SẢN PHẨM CHO MƯỢN ",
  });
  const onhandlePrint = () => {
    fetchDataPrint();
    setIdPrint(location?.state.id);
  };
  const fetchDataPrint = async () => {
    try {
      if (location?.state.id) {
        const response = await repositoryMdm.get(
          `/api/v1/TblWarrantyExportItemBorrowTicket/print?id=${location?.state.id}`
        );

        if (response && response.httpStatusCode === 200) {
          const result = response.data;
          setDataPrint(result);
        } else {
          NotificationExtension.Fails("Dữ liệu không tồn tại");
        }
      }
    } catch (error) {
      NotificationExtension.Fails("Dữ liệu không tồn tại");
      console.log("error", error);
    }
  };
  useEffect(() => {
    if (dataPrint) {
      handlePrint();
    }
  }, [dataPrint]);
  useEffect(() => {
    fetchDataDetail();
  }, [location?.state.id]);
  const handleReturnPage = () => {
    navigate(-1);
  };
  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const contentHeight = contentRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 160 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (190 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);
  return (
    <>
      <Box
        component="form"
        mx="auto"
        p={"0px 10px"}
        // onSubmit={form.onSubmit((e: any) => {
        //   console.log("e", e);
        // })}
        style={{ position: "relative" }}
      >
        <LoadingOverlay
          zIndex={1000}
          visible={visible}
          overlayProps={{ radius: "sm", blur: 2 }}
        />
        <Flex
          align={"center"}
          justify={"end"}
          style={{ marginBottom: "10px" }}
          gap="md"
        >
          <Button
            type="button"
            color="red"
            onClick={() => handleReturnPage()}
            leftSection={<IconArrowBack size={18} />}
          >
            Thoát
          </Button>
          {(location?.state.status === 3 || location?.state.status === 4) && (
            <Button
              leftSection={<IconPrinter size={14} />}
              color="blue"
              onClick={() => onhandlePrint()}
            >
              In Phiếu
            </Button>
          )}
        </Flex>
        <Grid>
          <Grid.Col span={5}>
            <ScrollArea h={height + 60} scrollbars="y">
              <Grid>
                <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                  <Fieldset legend="Thông tin khách hàng">
                    <Grid>
                      <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                        <TextInput
                          label="Mã khách hàng"
                          placeholder="Mã khách hàng"
                          value={
                            dataDetail !== undefined
                              ? dataDetail?.custCode
                              : null
                          }
                          readOnly
                          variant="filled"
                        />
                      </Grid.Col>
                      <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                        <TextInput
                          label="Số điện thoại"
                          placeholder="Số điện thoại"
                          value={
                            dataDetail !== undefined ? dataDetail?.phone : null
                          }
                          readOnly
                          variant="filled"
                        />
                      </Grid.Col>
                      <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                        <TextInput
                          label="Tên khách hàng"
                          placeholder="Tên khách hàng"
                          value={
                            dataDetail !== undefined
                              ? dataDetail?.custName
                              : null
                          }
                          readOnly
                          variant="filled"
                        />
                      </Grid.Col>{" "}
                      <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                        <TextInput
                          label="Địa chỉ"
                          placeholder="Địa chỉ"
                          value={
                            dataDetail !== undefined
                              ? dataDetail?.address
                              : null
                          }
                          readOnly
                          variant="filled"
                        />
                      </Grid.Col>
                    </Grid>
                  </Fieldset>
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                  <Fieldset legend="Thông tin phiếu yêu cầu xuất nhập hàng mượn">
                    <Grid>
                      <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                        <TextInput
                          label="Số phiếu yêu cầu hàng mượn"
                          placeholder="Số phiếu yêu cầu hàng mượn"
                          value={
                            dataDetail !== undefined
                              ? dataDetail?.attribute2
                              : null
                          }
                          readOnly
                          variant="filled"
                        />
                      </Grid.Col>
                      <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                        <TextInput
                          label="Số phiếu tiếp nhận hàng lỗi"
                          value={
                            dataDetail !== undefined
                              ? dataDetail?.requestCode
                              : null
                          }
                          readOnly
                          variant="filled"
                        />
                      </Grid.Col>
                      <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                        <TextInput
                          label="Bộ phận / Phòng ban"
                          placeholder=""
                          value={
                            dataDetail !== undefined
                              ? dataDetail?.depeartment
                              : null
                          }
                          readOnly
                          variant="filled"
                        />
                      </Grid.Col>
                      <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                        <TextInput
                          label="Người lập"
                          placeholder=""
                          value={
                            dataDetail !== undefined
                              ? dataDetail?.empName
                              : null
                          }
                          readOnly
                          variant="filled"
                        />
                      </Grid.Col>
                      <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                        <TextInput
                          label="Ngày lập"
                          placeholder=""
                          value={formatDateTransfer(
                            dataDetail !== undefined
                              ? dataDetail?.createDate
                              : null
                          )}
                          readOnly
                          variant="filled"
                        />
                      </Grid.Col>
                      <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                        <TextInput
                          label="Kho"
                          placeholder=""
                          value={
                            dataDetail !== undefined
                              ? dataDetail?.invCode
                              : null
                          }
                          readOnly
                          variant="filled"
                        />
                      </Grid.Col>
                      <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                        <TextInput
                          label="Chi nhánh"
                          placeholder=""
                          value={
                            dataDetail !== undefined ? dataDetail?.branch : null
                          }
                          readOnly
                          variant="filled"
                        />
                      </Grid.Col>
                      <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                        <TextInput
                          label="Chức danh"
                          placeholder=""
                          value={
                            dataDetail !== undefined ? dataDetail?.role : null
                          }
                          readOnly
                          variant="filled"
                        />
                      </Grid.Col>
                      <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                        <Textarea
                          label="Ghi chú"
                          value={
                            dataDetail !== undefined ? dataDetail?.note : null
                          }
                          readOnly
                        />
                      </Grid.Col>
                    </Grid>
                  </Fieldset>
                </Grid.Col>
              </Grid>
            </ScrollArea>
          </Grid.Col>
          <Grid.Col span={7}>
            <Fieldset legend="Thông tin sản phẩm">
              <MantineReactTable table={table} />
            </Fieldset>
            <Flex justify={"start"} gap={"xs"} h={100} mt={5}>
              <Flex
                direction={"row"}
                align={"center"}
                justify={"center"}
                bd={"1px solid #DEE2E6"}
                style={{ borderRadius: "10px" }}
                gap={"md"}
                w={300}
              >
                <Text size="14px" fw={"bold"}>
                  Tổng số tiền cọc :
                </Text>
                <Text size="13px" fw={"bold"} c={"red"}>
                  {formatCurrency(dataDetail?.totalDeposit)} VND
                </Text>
              </Flex>
            </Flex>
          </Grid.Col>
        </Grid>
      </Box>

      {idPrint !== undefined && dataPrint && (
        <div style={{ display: "none" }}>
          {location?.state?.status === 3 ? (
            <PrintExportBorrowedGoods
              dataPrint={dataPrint}
              ref={componentRef}
            />
          ) : location?.state?.status === 4 ? (
            <PrintImportBorrowedGoods
              // dataPrint={dataPrint}
              id={idPrint}
              dataPrint={dataPrint}
              ref={componentRef}
            />
          ) : null}
        </div>
      )}
    </>
  );
};

export default DetailExportBorredGood;
