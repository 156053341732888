import React, { useEffect, useState } from "react";
import BreadcrumbLayout1 from "../../../_base/component/_layout/_breadcrumb";
import {
  Box,
  Button,
  Card,
  Center,
  Flex,
  Group,
  HoverCard,
  Modal,
  rem,
  ScrollArea,
  Table,
  Text,
  Title,
  Tooltip,
} from "@mantine/core";
import {
  IconArrowBackUp,
  IconDeviceFloppy,
  IconPlus,
  IconReceipt,
} from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { useDisclosure, useMove } from "@mantine/hooks";
import ListReceipts from "../ListReceipts/ListReceipts";
import InfoBillReceipt from "./InfoBillReceipt";
import { repositoryPos } from "../../../_base/_const/_constVar";
import { MessageResponse } from "../../../model/MessageResponse";
import { OrderReceipt } from "./TypeBill/OrderReceipt/OrderReceipt";
import { OtherVotes } from "./TypeBill/OtherVotes/OtherVotes";

interface ModelInfoBill {
  receiptNumber: string | null | undefined;
  typeReceipt: number | null | undefined;
  type: number | null | undefined;
}

interface DataList {
  tblPaymentReceiptOld: TblPaymentReceipt;
  count: number;
}

interface ExampleInterface {
  att1: string | null;
  att2: string | null;
  att3: number | null;
  att4: number | null;
  att5: string[] | null;
  disabled: boolean;
  group: string | null;
  selected: boolean;
  text: string | null;
  value: string | null;
}

interface TblPaymentReceipt {
  accountfund: string | number | null | undefined;
  codefund: string | null;
  createby: number;
  createdDate: string;
  creatorbranch: number | null | undefined;
  creatordepartment: number | null | undefined;
  creatorfullname?: string | null;
  creatorsalesbranch: string | null;
  creatorworkplace: string | null;
  fundbranch: string | null;
  funddepartment: string | null;
  fundsalesbranch: string | null;
  fundworkplace: string | null;
  funfullname: string | null;
  id: number;
  payerdescription: string | null;
  payerfullname: string | null;
  payernote: string | null;
  payertype: string | null;
  receiptNumber: string;
  anotherName: string | null;
  phoneNumber: string | null;
  codeCustomer: string | null;
  totalAmount: number;
  type: number;
  typeReceipt: number;
  completiontime?: string;
  status?: string | null;
  attribute2?: string | null;
  attribute3?: string | null;
  attribute4?: string | null;
  attribute5?: string | null;
  attribute6?: string | null;
  attribute7?: string | null;
  bankname?: string | null;
  createrolename?: string | null;
  accountnumber?: string | null;
}

interface User {
  id: number;
  code: string;
  fullname: string;
  dateOfBirth: string;
  gender: number;
  departmentId: number | null;
  departmentName: string | null;
  roleId: number;
  roleName: string;
  managerName: string | null;
  address: string | null;
  phoneNumber: string;
  active: boolean;
  email: string;
  description: string | null;
  accountingCenterId: number | null;
  managerId: number | null;
  isCrm: boolean | null;
  currentCardId: number;
  totalAmount: string | null;
  partCode: string | null;
  createDate: string;
  listBranch: string | null;
  listInventory: string | null;
  listGroup: string;
  branchId: number;
  branchName: string;
  workAddress: string | null;
}

const AddAdjustmentSlip = () => {
  const navigate = useNavigate();
  const [opened, { open, close }] = useDisclosure(false);
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);
  const [dataInfoModel, setDataInfoModel] = useState<ModelInfoBill>();
  const [datafetch, setDataFetch] = useState<TblPaymentReceipt>();
  const [value, setValue] = useState({ x: 0.2, y: 0.6 });
  const { ref, active } = useMove(setValue);
  const [scrolled, setScrolled] = useState(false);
  const [listTblDmEmployee, setListTblDmEmployee] = useState<User>();
  const [dataGetDepartments, setDataGetDepartments] = useState<
    ExampleInterface[]
  >([]);
  const [listEmployee, setListEmployee] = useState<any[]>([]);

  const ths = (
    <Table.Tr>
      <Table.Th w={110}></Table.Th>
      <Table.Th>
        <Text size="sm" fw={700} ta="center">
          Số liệu cũ
        </Text>
      </Table.Th>
      <Table.Th>
        <Text size="sm" fw={700} ta="center">
          Số liệu mới
        </Text>
      </Table.Th>
    </Table.Tr>
  );

  const rows = (
    <>
      <Table.Tr>
        <Table.Td>
          <Text size="sm" fw={600}>
            Mã phiếu thu
          </Text>
        </Table.Td>
        <Table.Td>
          <Text lineClamp={1}>{datafetch?.receiptNumber}</Text>
        </Table.Td>
        <Table.Td></Table.Td>
      </Table.Tr>
      <Table.Tr>
        <Table.Td>
          <Text size="sm" fw={600}>
            Thời gian tạo
          </Text>
        </Table.Td>
        <Table.Td>
          <Text lineClamp={1}>{datafetch?.createdDate}</Text>
        </Table.Td>
        <Table.Td></Table.Td>
      </Table.Tr>
      <Table.Tr>
        <Table.Td>
          <Text size="sm" fw={600}>
            Thời gian HT
          </Text>
        </Table.Td>
        <Table.Td>
          <Text lineClamp={1}> {datafetch?.completiontime}</Text>
        </Table.Td>
        <Table.Td></Table.Td>
      </Table.Tr>
      <Table.Tr>
        <Table.Td>
          <Text size="sm" fw={600}>
            Người nộp tiền
          </Text>
        </Table.Td>
        <Table.Td>
          <Text lineClamp={1}>{datafetch?.creatorfullname}</Text>
        </Table.Td>
        <Table.Td></Table.Td>
      </Table.Tr>
      <Table.Tr>
        <Table.Td>
          <Text size="sm" fw={600}>
            Phòng ban
          </Text>
        </Table.Td>
        <Table.Td>
          <Text lineClamp={1}>
            {dataGetDepartments?.find(
              (item: any) =>
                String(item.value) === String(datafetch?.funddepartment)
            )?.text ?? ""}
          </Text>
        </Table.Td>
        <Table.Td></Table.Td>
      </Table.Tr>
      <Table.Tr>
        <Table.Td>
          <Text size="sm" fw={600}>
            ĐĐ làm việc
          </Text>
        </Table.Td>
        <Table.Td>
          <Text lineClamp={1}>{datafetch?.fundworkplace ?? ""}</Text>
        </Table.Td>
        <Table.Td></Table.Td>
      </Table.Tr>
      <Table.Tr>
        <Table.Td>
          <Text size="sm" fw={600}>
            Họ và tên
          </Text>
        </Table.Td>
        <Table.Td>
          <Text lineClamp={1}>
            {listEmployee?.find(
              (item: any) => item.value == datafetch?.funfullname
            )?.text ?? ""}
          </Text>
        </Table.Td>
        <Table.Td></Table.Td>
      </Table.Tr>
      <Table.Tr>
        <Table.Td>
          <Text size="sm" fw={600}>
            Chức danh
          </Text>
        </Table.Td>
        <Table.Td>
          <Text lineClamp={1}>
            {String(listTblDmEmployee?.id) === datafetch?.funfullname
              ? listTblDmEmployee?.roleName
              : ""}
          </Text>
        </Table.Td>
        <Table.Td></Table.Td>
      </Table.Tr>
      {/* <Table.Tr>
        <Table.Td>
          <Text size="sm" fw={600}>
            Mã KH/NCC
          </Text>
        </Table.Td>
        <Table.Td>
          <Tooltip label={codeCustomer}>
            <Text lineClamp={1}>{codeCustomer}</Text>
          </Tooltip>
        </Table.Td>
        <Table.Td></Table.Td>
      </Table.Tr>
      <Table.Tr>
        <Table.Td>
          <Text size="sm" fw={600}>
            Tên
          </Text>
        </Table.Td>
        <Table.Td>
          <Tooltip label={anotherName}>
            <Text lineClamp={1}>{anotherName}</Text>
          </Tooltip>
        </Table.Td>
        <Table.Td></Table.Td>
      </Table.Tr>
      <Table.Tr>
        <Table.Td>
          <Text size="sm" fw={600}>
            Số điện thoại
          </Text>
        </Table.Td>
        <Table.Td>
          <Tooltip label={phoneNumber}>
            <Text lineClamp={1}>{phoneNumber}</Text>
          </Tooltip>
        </Table.Td>
        <Table.Td></Table.Td>
      </Table.Tr>
      <Table.Tr>
        <Table.Td>
          <Text size="sm" fw={600}>
            Tên ngân hàng
          </Text>
        </Table.Td>
        <Table.Td>
          <Tooltip label={bankName}>
            <Text lineClamp={1}>{bankName}</Text>
          </Tooltip>
        </Table.Td>
        <Table.Td></Table.Td>
      </Table.Tr>
      <Table.Tr>
        <Table.Td>
          <Text size="sm" fw={600}>
            STK
          </Text>
        </Table.Td>
        <Table.Td>
          <Tooltip label={accountNumber}>
            <Text lineClamp={1}>{accountNumber}</Text>
          </Tooltip>
        </Table.Td>
        <Table.Td></Table.Td>
      </Table.Tr>
      <Table.Tr>
        <Table.Td>
          <Text size="sm" fw={600}>
            Đơn vị tính
          </Text>
        </Table.Td>
        <Table.Td>
          <Tooltip label={unit}>
            <Text lineClamp={1}>{unit}</Text>
          </Tooltip>
        </Table.Td>
        <Table.Td></Table.Td>
      </Table.Tr>
      <Table.Tr>
        <Table.Td>
          <Text size="sm" fw={600}>
            Mã phí
          </Text>
        </Table.Td>
        <Table.Td>
          <Tooltip label={feeCode}>
            <Text lineClamp={1}>{feeCode}</Text>
          </Tooltip>
        </Table.Td>
        <Table.Td></Table.Td>
      </Table.Tr>
      <Table.Tr>
        <Table.Td>
          <Text size="sm" fw={600}>
            Nội dung
          </Text>
        </Table.Td>
        <Table.Td>
          <Tooltip label={payerDescription}>
            <Text lineClamp={1}>{payerDescription}</Text>
          </Tooltip>
        </Table.Td>
        <Table.Td></Table.Td>
      </Table.Tr>
      <Table.Tr>
        <Table.Td>
          <Text size="sm" fw={600}>
            Tổng tiền
          </Text>
        </Table.Td>
        <Table.Td>
          <Tooltip label={totalAmount}>
            <Text lineClamp={1} ta="right">
              {totalAmount}
            </Text>
          </Tooltip>
        </Table.Td>
        <Table.Td></Table.Td>
      </Table.Tr> */}
    </>
  );

  const formAddBill = () => (
    <Modal
      opened={opened}
      size="100%"
      onClose={close}
      title={<Title order={5}>Vui lòng chọn phiếu</Title>}
    >
      <InfoBillReceipt
        onSetDataInfoModel={setDataInfoModel}
        onCloseModal={close}
      />
    </Modal>
  );

  const fetchDataSelect = async () => {
    const dataApi = await repositoryPos.get<MessageResponse<DataList>>(
      `/api/v1/CreateSellItem/create-receipt-parent-adjust?receiptNumber=${dataInfoModel?.receiptNumber}`
    );

    if (dataApi && dataApi.success) {
      const dataList = dataApi?.data;
      setDataFetch(dataList.tblPaymentReceiptOld);
    }
  };

  const fetchDataDepartments = async () => {
    const response = await repositoryPos.get<
      MessageResponse<ExampleInterface[]>
    >("/api/v1/TblDepartment/get-select");

    if (response && response.success) {
      let result = response.data;

      const newDepartment = {
        att1: null,
        att2: null,
        att3: 0,
        att4: 0,
        att5: null,
        disabled: false,
        group: null,
        selected: false,
        text: "Khác",
        value: "-1",
      };

      const updatedDepartments = [...result, newDepartment];
      setDataGetDepartments(updatedDepartments);
    }
  };

  const fetchDataEmployee = async () => {
    const response = await repositoryPos.get<MessageResponse<any>>(
      "/api/v1/TblDmEmployee/get-select"
    );

    if (response && response.success) {
      let result = response.data;
      setListEmployee(result);
    }
  };

  const fetchDataTblDmEmployee = async () => {
    const response = await repositoryPos.get<MessageResponse<any>>(
      `/api/v1/TblDmEmployee/detail-seft?empId=${datafetch?.funfullname}`
    );

    if (response && response.success) {
      let result = response.data;
      setListTblDmEmployee(result);
      // setListEmployee(result);
    }
  };

  useEffect(() => {
    open();
    fetchDataDepartments();
    fetchDataEmployee();
    fetchDataTblDmEmployee();
  }, [open]);

  useEffect(() => {
    if (dataInfoModel?.receiptNumber) {
      fetchDataSelect();
    }
  }, [dataInfoModel?.receiptNumber]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      setHeight(window.innerHeight - (145 + headerHeight));
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [height]);

  return (
    <>
      {formAddBill()}
      <Flex
        style={{ border: "1px solid #dee2e6", padding: "5px 15px" }}
        ref={headerRef}
        justify="space-between"
      >
        <BreadcrumbLayout1></BreadcrumbLayout1>
        <Flex align="center" gap={40}>
          <Flex gap={20}>
            <Flex gap={15}>
              <Tooltip label="Xem">
                <HoverCard width={700} shadow="md">
                  <HoverCard.Target>
                    <Box
                      style={{
                        borderRadius: "3px",
                        border: "0.5px solid #000",
                        cursor: "pointer",
                      }}
                      w={20}
                      h={20}
                      bg="black"
                    ></Box>
                  </HoverCard.Target>
                  <HoverCard.Dropdown>
                    <ScrollArea
                      h={height}
                      onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
                    >
                      <Table
                        layout="fixed"
                        striped
                        withTableBorder
                        withColumnBorders
                      >
                        <Table.Thead>{ths}</Table.Thead>
                        <Table.Tbody>{rows}</Table.Tbody>
                      </Table>
                    </ScrollArea>
                  </HoverCard.Dropdown>
                </HoverCard>
              </Tooltip>
              <Text fs="italic" fw={600}>
                Số liệu cũ
              </Text>
            </Flex>
            <Flex gap={15}>
              <Tooltip label="Xem">
                <Box
                  style={{
                    borderRadius: "3px",
                    border: "0.5px solid #000",
                    cursor: "pointer",
                  }}
                  w={20}
                  h={20}
                  bg="#ffec99"
                ></Box>
              </Tooltip>

              <Text fs="italic" fw={600}>
                Số liệu mới
              </Text>
            </Flex>
          </Flex>
          <Button
            onClick={open}
            color="blue"
            leftSection={<IconReceipt size={18} />}
            variant="filled"
          >
            Chọn phiếu
          </Button>
        </Flex>
      </Flex>
      {dataInfoModel?.typeReceipt === 1 &&
      dataInfoModel?.type === 1 &&
      dataInfoModel?.receiptNumber ? (
        <>{datafetch ? <OrderReceipt datafetch={datafetch} /> : null}</>
      ) : dataInfoModel?.receiptNumber ? (
        <>
          {datafetch ? (
            <OtherVotes value={value} datafetch={datafetch} />
          ) : null}
        </>
      ) : null}
    </>
  );
};

export default AddAdjustmentSlip;
