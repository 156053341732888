import {
  Checkbox,
  Fieldset,
  Flex,
  NumberInput,
  TextInput,
  Grid,
  Textarea,
  Box,
} from "@mantine/core";
import { DateInput, DateTimePicker } from "@mantine/dates";
import { UseFormReturnType } from "@mantine/form";
import { useState, useEffect } from "react";
import {
  tblPromotion,
  tblPromotionDetailModel,
} from "../../../../../../model/TblPromotion";
import { IconCalendarMonth } from "@tabler/icons-react";
import moment from "moment";
import dayjs from "dayjs";

const GeneralInformation = ({
  selectedPolicyType,

  dataDetailPromotion,
  setDataDetailPromotion,
}: {
  selectedPolicyType: string;
  // form: UseFormReturnType<tblPromotion, (values: tblPromotion) => tblPromotion>;
  dataDetailPromotion: tblPromotionDetailModel;
  setDataDetailPromotion: React.Dispatch<
    React.SetStateAction<tblPromotionDetailModel>
  >;
}) => {
  const [effDate, setEffDate] = useState<string>("");
  const [expDate, setExpDate] = useState<string>("");

  const getType = (value: string) => {
    switch (value) {
      case "0":
        return "Chính sách thông thường (khi bán hàng chỉ được chọn 1 chính sách)";
      case "1":
        return "Chính sách mặc định (luôn được áp dụng kèm theo chính sách thường)";
      case "2":
        return "Chính sách chiết khấu, giảm giá (khai báo mã chiết khấu, giảm giá)";
      default:
        return "Chính sách thông thường (khi bán hàng chỉ được chọn 1 chính sách)";
    }
  };

  const handleEditPromotionDetail = (value: string | number, key: string) => {
    setDataDetailPromotion((prevData) => ({ ...prevData, [key]: value }));
  };

  return (
    <Box h={"max-content"}>
      <Fieldset
        legend="Thông tin chung"
        display={"flex"}
        style={{ flexDirection: "column", gap: "10px" }}
      >
        <Grid>
          <Grid.Col span={{ base: 6, md: 1.5, lg: 1.5 }}>
            <TextInput
              label="Số chính sách"
              placeholder="Nhập số chính sách"
              disabled
              size="xs"
              // {...form.getInputProps("tblPromotionCommand.code")}
              value={dataDetailPromotion.code || ""}
              onChange={(e) =>
                handleEditPromotionDetail(e.currentTarget.value, "code")
              }
            />
          </Grid.Col>
          {/* <Grid.Col span={{ base: 6, md: 1.5, lg: 1.5 }}>
            <NumberInput
              label="Thứ tự ưu tiên"
              min={0}
              size="xs"
              // {...form.getInputProps("tblPromotionCommand.priority")}
              value={dataDetailPromotion.priority || ""}
              onChange={(value) => handleEditPromotionDetail(value, "priority")}
            />
          </Grid.Col> */}

          <Grid.Col span={{ base: 6, md: 1.5, lg: 1.5 }}>
            <TextInput
              label="Trạng thái"
              placeholder="Trạng thái"
              disabled
              w={"100%"}
              size="xs"
              // {...form.getInputProps("tblPromotionCommand.approveStatus")}
              value={
                dataDetailPromotion?.approveStatus === "2"
                  ? "Đã duyệt"
                  : dataDetailPromotion?.approveStatus === "3"
                  ? "Tạm dừng"
                  : dataDetailPromotion?.approveStatus === "4"
                  ? "Từ chối"
                  : "Chưa duyệt"
              }
              onChange={(e) =>
                handleEditPromotionDetail(
                  e.currentTarget.value,
                  "approveStatus"
                )
              }
            />
          </Grid.Col>
          <Grid.Col span={{ base: 6, md: 1.5, lg: 1.5 }}>
            <TextInput
              label="Người lập"
              // value={localStorage.getItem("userName") ?? ""}
              disabled
              w={"100%"}
              size="xs"
              value={dataDetailPromotion.createBy || ""}
              onChange={(e) =>
                handleEditPromotionDetail(e.currentTarget.value, "createBy")
              }
            />
          </Grid.Col>
          <Grid.Col span={{ base: 6, md: 1.5, lg: 1.5 }}>
            <TextInput
              label="Chính sách khuyến mại"
              size="xs"
              disabled
              // {...form.getInputProps("tblPromotionCommand.type")}
              value={getType(selectedPolicyType)}
            />
          </Grid.Col>

          <Grid.Col span={{ base: 6, md: 1.5, lg: 1.5 }}>
            <DateTimePicker
              label="Hiệu lực từ ngày"
              placeholder="Hiệu lực từ ngày"
              minDate={new Date()}
              rightSection={<IconCalendarMonth size={14} />}
              size="xs"
              // w={180}
              // {...form.getInputProps("tblPromotionCommand.effDate")}
              value={
                dataDetailPromotion.effDate
                  ? new Date(dataDetailPromotion.effDate)
                  : null
              }
              onChange={(value) => {
                handleEditPromotionDetail(
                  moment(value).format("YYYY-MM-DD[T]HH:mm:ss") || "",
                  "effDate"
                );
                // form
                //   .getInputProps("tblPromotionCommand.effDate")
                //   .onChange(
                //     moment(value).format("YYYY-MM-DD[T]HH:mm:ss") || ""
                //   );
              }}
            />
          </Grid.Col>

          <Grid.Col span={{ base: 6, md: 1.5, lg: 1.5 }}>
            <DateTimePicker
              label="Đến ngày"
              placeholder="Đến ngày"
              minDate={
                dataDetailPromotion.effDate
                  ? new Date(
                      dayjs(dataDetailPromotion.effDate).add(1, "day").toDate()
                    )
                  : new Date()
              }
              rightSection={<IconCalendarMonth size={14} />}
              size="xs"
              // w={180}
              // {...form.getInputProps("tblPromotionCommand.expDate")}
              value={
                dataDetailPromotion.expDate
                  ? new Date(dataDetailPromotion.expDate)
                  : null
              }
              onChange={(value) => {
                handleEditPromotionDetail(
                  moment(value).format("YYYY-MM-DD[T]HH:mm:ss") || "",
                  "expDate"
                );
                // form
                //   .getInputProps("tblPromotionCommand.expDate")
                //   .onChange(
                //     moment(value).format("YYYY-MM-DD[T]HH:mm:ss") || ""
                //   );
              }}
            />
          </Grid.Col>

          <Grid.Col span={{ base: 6, md: 1.5, lg: 1.5 }}>
            {/* <Flex gap={15} direction={"column"}>
              <Checkbox label="Chỉ áp dụng online" size="xs"></Checkbox>
              <Checkbox
                label="Có kết hợp với chính sách mặc định"
                size="xs"
              ></Checkbox>
            </Flex> */}
          </Grid.Col>

          <Grid.Col span={{ base: 12, md: 4.5, lg: 4.5 }}>
            <Textarea
              label="Ghi chú"
              placeholder="Ghi chú"
              size="xs"
              // {...form.getInputProps("tblPromotionCommand.note")}
              value={dataDetailPromotion.note || ""}
              onChange={(e) =>
                handleEditPromotionDetail(e.currentTarget.value, "note")
              }
            />
          </Grid.Col>
        </Grid>
      </Fieldset>
    </Box>
  );
};

export default GeneralInformation;
