import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Flex,
  Grid,
  GridCol,
  Group,
  Input,
  Select,
  SelectProps,
  Tooltip,
  rem,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import {
  IconBrandMastercard,
  IconCheck,
  IconEdit,
  IconEye,
  IconHomeShare,
  IconMoneybag,
  IconPackageImport,
  IconPlus,
  IconSearch,
  IconStatusChange,
  IconTimeDuration15,
} from "@tabler/icons-react";
import {
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MRT_SortingState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { repositoryMdm } from "../../../../_base/_const/_constVar";
import BreadcrumbLayout1 from "../../../../_base/component/_layout/_breadcrumb";
import { NotificationExtension } from "../../../../_base/extension/NotificationExtension";
import { paginationBase } from "../../../../_base/model/_base/BaseTable";
import { formatDateTransfer } from "../../../../common/FormatDate/FormatDate";
import { _localization } from "../../../../config/location";
import { green } from "../../../../const/variables";
import { BorrowTicket } from "../../../../model/TblWarrantyItemBorrowTicket";

function getColorStatus(text: any) {
  switch (text) {
    case "Yêu cầu xuất mượn":
      return "blue";
    case "Đã thu tiền":
      return "purple";
    case "Đã xuất kho":
      return "yellow";
    case "Đã nhập hàng":
      return "green";
    case "Đã thu chi":
      return "orange";
    default:
      return "gray";
  }
}

const dataStatusOption = [
  { value: "1", label: "Yêu cầu xuất mượn" },
  { value: "2", label: "Đã thu tiền" },
  { value: "3", label: "Đã xuất kho" },
  { value: "4", label: "Đã nhập hàng" },
  { value: "5", label: "Đã thu chi" },
];
const renderSelectOptionStatus: SelectProps["renderOption"] = ({
  option,
  checked,
}) => (
  <Group flex="1" gap="xs">
    <Badge color={getColorStatus(option.label)} radius="sm" variant="filled">
      {option.label}
    </Badge>
    {checked && (
      <IconCheck size={"14px"} style={{ marginInlineStart: "auto" }} />
    )}
  </Group>
);
// hàm này để cho chọn ngày đúng , chứ không khi chọn sẽ bị lùi 1 ngày so với ngày chọn
const addOneDay = (isoDate: string | null) => {
  if (!isoDate) return "";
  // Tạo đối tượng Date từ chuỗi ISO
  const date = new Date(isoDate);
  // Thêm 1 ngày vào đối tượng Date
  date.setDate(date.getDate() + 1);
  // Trả về chuỗi ngày giờ theo chuẩn ISO
  return date.toISOString().slice(0, -3);
};
const DMBorrwedGoods = () => {
  const navigate = useNavigate();
  //data and fetching state
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<BorrowTicket[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);
  //table state
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [pagination, setPagination] = useState(paginationBase);
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [status, setStatus] = useState<number | null>(null); // Lưu trữ status
  const [id, setId] = useState<number | null>(null); // Lưu trữ id
  const [idPNHL, setIdPNHL] = useState<string[]>([]); // Lưu trữ id
  const onhandleStatus = async (id: number, status: number) => {
    const handleStatus = status + 1;
    setId(id); // Cập nhật id

    if (status === 1) {
      const response = await repositoryMdm.post(
        "/api/v1/TblWarrantyExportItemBorrowTicket/status",
        {
          ids: [id],
          status: handleStatus,
          borrow_Code: null,
          attribute3: null,
        }
      );
      if (response && response.httpStatusCode === 200) {
        if (response && response.httpStatusCode === 200) {
          NotificationExtension.Success("Cập nhật trạng thái thành công");
        }
      }

      setStatus(handleStatus);
      setId(id);
    } else if (status === 2) {
      const response =
        await repositoryMdm.get(`/api/v1/TblWarrantyExportItemBorrowTicket/create-pxm
      `);
      if (response) {
        // setBorrowCodeKEY(response.data.borrowCode);
        await repositoryMdm.post(
          "/api/v1/TblWarrantyExportItemBorrowTicket/status",
          {
            ids: [id],
            status: handleStatus,
            borrow_Code: response.data.borrowCode,
            attribute3: null,
          }
        );
        if (response && response.httpStatusCode === 200) {
          NotificationExtension.Success("Cập nhật trạng thái thành công");
        }
      }
      setStatus(handleStatus);
      setId(id);
    } else if (status === 3) {
      const result =
        await repositoryMdm.get(`/api/v1/TblWarrantyExportItemBorrowTicket/create-pnm
      `);
      const borrowCode = data.find((item) => item.id === id)?.borrowCode;

      if (borrowCode) {
        const response = await repositoryMdm.post(
          "/api/v1/TblWarrantyExportItemBorrowTicket/status",
          {
            ids: [id],
            status: handleStatus,
            borrow_Code: borrowCode,
            attribute3: result.data.attribute3,
          }
        );
        setData((prev) => {
          return prev.map((item) => {
            if (item.id === id) {
              return {
                ...item,
                attribute3: result.data.attribute3,
                status: handleStatus,
              };
            }
            return item;
          });
        });

        if (response && response.httpStatusCode === 200) {
          NotificationExtension.Success("Cập nhật trạng thái thành công");
        }
        setStatus(handleStatus);
        setId(id);
      }
    } else if (status === 4) {
      const attribute3 = data.find((item) => item.id === id)?.attribute3;
      const borrowCode = data.find((item) => item.id === id)?.borrowCode;
      const response = await repositoryMdm.post(
        "/api/v1/TblWarrantyExportItemBorrowTicket/status",
        {
          ids: [id],
          status: handleStatus,
          borrow_Code: borrowCode,
          attribute3: attribute3,
        }
      );
      setData((prev) => {
        return prev.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              status: handleStatus,
            };
          }
          return item;
        });
      });
      if (response && response.httpStatusCode === 200) {
        NotificationExtension.Success("Cập nhật trạng thái thành công");
      }
      setStatus(handleStatus);
      setId(id);
    } else {
      NotificationExtension.Fails("Cập nhật trạng thái thất bại");
    }
  };

  const columns = React.useMemo<MRT_ColumnDef<BorrowTicket>[]>(
    () => [
      {
        header: "STT",
        size: 30,
        Cell: ({ row }) => (
          <>
            <span
              style={{
                fontWeight: "bold",
              }}
            >
              {row.index + 1}
            </span>
          </>
        ),
      },
      {
        accessorKey: "attribute2",
        header: "Số phiếu yêu cầu hàng mượn",
        Cell: ({ renderedCellValue, row }: any) => (
          <>
            <Tooltip label="xem chi tiết">
              <Badge
                radius="sm"
                variant="dot"
                size="lg"
                style={{ cursor: "pointer" }}
                color={renderedCellValue === null ? "" : green.base}
                onDoubleClick={() =>
                  detailItem(row.original.id!, row.original.status)
                }
              >
                {renderedCellValue}
              </Badge>
            </Tooltip>
          </>
        ),
      },

      {
        accessorKey: "borrowCode",
        header: "Số phiếu xuất hàng mượn",
        Cell: ({ renderedCellValue, row }: any) => (
          <>
            <Tooltip label="xem chi tiết">
              <Badge
                radius="sm"
                variant="dot"
                size="lg"
                style={{ cursor: "pointer" }}
                color={renderedCellValue === null ? "" : green.base}
                onDoubleClick={() =>
                  detailItem(row.original.id!, row.original.status)
                }
              >
                {renderedCellValue}
              </Badge>
            </Tooltip>
          </>
        ),
      },
      {
        accessorKey: "attribute3",
        header: "Số phiếu nhập hàng mượn",
        Cell: ({ renderedCellValue, row }: any) => (
          <>
            <Tooltip label="xem chi tiết">
              <Badge
                radius="sm"
                variant="dot"
                size="lg"
                style={{ cursor: "pointer" }}
                color={renderedCellValue === null ? "" : green.base}
                onDoubleClick={() =>
                  detailItem(row.original.id!, row.original.status)
                }
              >
                {renderedCellValue}
              </Badge>
            </Tooltip>
          </>
        ),
      },

      {
        accessorKey: "status",
        header: "Trạng thái",
        size: 30,
        Cell: ({ renderedCellValue }: any) => (
          <>
            {dataStatusOption.map(
              (item: any, index: number) =>
                item.value == renderedCellValue && (
                  <Badge
                    key={index}
                    color={getColorStatus(item.label)}
                    radius="sm"
                    variant="filled"
                  >
                    {item.label}
                  </Badge>
                )
            )}
          </>
        ),
      },

      {
        accessorKey: "totalDeposit",
        header: "Tiền cọc",
        size: 30,
        Cell: ({ renderedCellValue, row }: any) => (
          <>
            {renderedCellValue === 0 ? (
              <></>
            ) : (
              renderedCellValue?.toLocaleString("vi-VN")
            )}{" "}
          </>
        ),
      },
      {
        accessorKey: "createDate",
        header: "Ngày lập",
        Cell: ({ renderedCellValue, row }: any) => (
          <>{formatDateTransfer(renderedCellValue)}</>
        ),
        size: 30,
      },
      {
        accessorKey: "custName",
        header: "Tên khách hàng",
        size: 30,
      },
      {
        accessorKey: "phone",
        header: "Số điện thoại",
        size: 30,
      },
      {
        accessorKey: "address",
        header: "Địa chỉ",
        size: 30,
      },
      {
        accessorKey: "invCode",
        header: "Kho",
        size: 30,
      },
      {
        accessorKey: "empName",
        header: "Người lập",
        size: 30,
        Cell: ({ renderedCellValue, row }: any) => <>{renderedCellValue}</>,
      },

      {
        accessorKey: "note",
        header: "Ghi chú",
        size: 30,
      },
      {
        accessorKey: "action",
        header: "Thao tác",
        size: 50,
        Cell: ({ renderedCellValue, row, table }) => (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Tooltip label="Chi tiết">
              <ActionIcon
                variant="light"
                color="cyan"
                onClick={async () => {
                  detailItem(row.original.id!, row.original.status);
                }}
              >
                <IconEye size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
            {row.original.status === 1 || row.original.status === 4 ? (
              <>
                <Tooltip label="Sửa phiếu">
                  <ActionIcon
                    variant="light"
                    color="yellow"
                    onClick={async () => {
                      editItem(row.original.id!, row.original.status);
                    }}
                  >
                    <IconEdit size={20} stroke={1.5} />
                  </ActionIcon>
                </Tooltip>
              </>
            ) : (
              ""
            )}
            {row.original.status === 1 && (
              <Tooltip label="Thu tiền">
                <ActionIcon
                  variant="light"
                  color="purple"
                  onClick={async () =>
                    await onhandleStatus(row.original.id!, row.original.status)
                  }
                >
                  <IconMoneybag size={20} stroke={1.5} />
                </ActionIcon>
              </Tooltip>
            )}
            {row.original.status === 2 && (
              <Tooltip label="Xuất kho">
                <ActionIcon
                  variant="light"
                  color="orange"
                  onClick={async () => {
                    await onhandleStatus(row.original.id!, row.original.status);
                  }}
                >
                  <IconHomeShare size={20} stroke={1.5} />
                </ActionIcon>
              </Tooltip>
            )}
            {row.original.status === 3 && (
              <Tooltip label="Nhập hàng">
                <ActionIcon
                  variant="light"
                  color="violet"
                  onClick={async () => {
                    await onhandleStatus(row.original.id!, row.original.status);
                  }}
                >
                  <IconPackageImport size={20} stroke={1.5} />
                </ActionIcon>
              </Tooltip>
            )}
            {row.original.status === 4 && (
              <Tooltip label="Thu chi">
                <ActionIcon
                  variant="light"
                  color="orange"
                  onClick={async () =>
                    await onhandleStatus(row.original.id!, row.original.status)
                  }
                >
                  <IconBrandMastercard size={20} stroke={1.5} />
                </ActionIcon>
              </Tooltip>
            )}
          </Box>
        ),
      },
    ],
    [data]
  );

  // form search
  const formSeachInput = useForm({
    mode: "uncontrolled",
    initialValues: {
      keySearch: "",
      startDate: "",
      endDate: "",
      status: "",
    },
  });
  // navigate to detail
  const detailItem = (id: string | number, status: number) => {
    navigate("/warranty/detail-borrowed-goods", {
      state: { id, status },
    });
  };
  const editItem = (id: string | number, status: number) => {
    navigate("/warranty/edit-borrowed-goods", {
      state: { id, status },
    });
  };
  const onhandleCreate = () => {
    navigate("/warranty/create-borrowed-goods", {
      state: {
        idPNHL,
      },
    });
  };
  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setIsError(false);

    let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }`;

    if (formSeachInput.getValues().keySearch) {
      url += `&KeySearch=${formSeachInput.getValues().keySearch}`;
    }
    if (formSeachInput.getValues().startDate) {
      url += `&StartDate=${addOneDay(formSeachInput.getValues().startDate)}`;
    }

    if (formSeachInput.getValues().endDate) {
      url += `&EndDate=${addOneDay(formSeachInput.getValues().endDate)}`;
    }
    if (formSeachInput.getValues().status) {
      url += `&Status=${formSeachInput.getValues().status}`;
    }
    try {
      const response = await repositoryMdm.get(
        `/api/v1/TblWarrantyExportItemBorrowTicket/get-list${url}`
      );
      if (response && response.httpStatusCode === 200) {
        let result = response.data;
        setIsLoading(false);
        setRowCount(result.length);
        setData(result);
        setIdPNHL(result.map((item: any) => item.requestCode));
        table.resetRowSelection();
      }
    } catch (error) {
      setData([]);
      setIsError(true);
      setIsLoading(false);
      console.error(error);
      return;
    } finally {
      setIsRefetching(false);
      setIsLoading(false);
    }
  };
  const table = useMantineReactTable({
    columns,
    data: data ? data : [],
    positionToolbarAlertBanner: "bottom",
    enableTopToolbar: false,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    getRowId: (row) => row.id?.toString(),

    initialState: {
      showColumnFilters: false,
      columnPinning: {
        // left: [""],
        right: ["action"],
      },
      columnVisibility: { id: true },
      density: "xs",
    },
    enableColumnActions: false,
    enableSorting: false,
    enableRowSelection: false,
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    manualFiltering: false,
    manualPagination: true,
    manualSorting: true,
    onSortingChange: setSorting,
    rowCount,
    onPaginationChange: setPagination,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontSize: "11.5px",
        padding: "4px 12px",
      },
    }),
    state: {
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
      sorting,
    },
    mantineToolbarAlertBannerProps: false
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["20", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
  });
  useEffect(() => {
    fetchData();
  }, [pagination?.pageIndex, pagination?.pageSize, status, id]);
  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 190 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (235 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);
  return (
    <>
      <div
        style={{
          border: "1px solid #dee2e6",
          padding: "0px 10px",
          overflowX: "hidden",
        }}
      >
        <BreadcrumbLayout1></BreadcrumbLayout1>
      </div>
      <Flex
        justify="space-between"
        align={"center"}
        style={{ border: "1px solid #dee2e6", padding: "5px" }}
        ref={headerRef}
      >
        <form
          onSubmit={formSeachInput.onSubmit(async () => await fetchData())}
          style={{ flex: 1 }}
        >
          <Grid mt={10}>
            <GridCol span={{ base: 6, sm: 4, md: 2.5 }}>
              <Input
                w={"100%"}
                placeholder="Từ khoá"
                type="text"
                leftSection={<IconSearch color="#15aabf" />}
                key={formSeachInput.key("keySearch")}
                {...formSeachInput.getInputProps("keySearch")}
              />
            </GridCol>

            <GridCol span={{ base: 6, sm: 4, md: 2.5, lg: 2.5 }}>
              <Select
                placeholder={"Trạng thái"}
                searchable
                clearable
                data={dataStatusOption}
                leftSection={<IconStatusChange color="#15aabf" />}
                comboboxProps={{
                  transitionProps: { transition: "pop", duration: 200 },
                }}
                renderOption={renderSelectOptionStatus}
                nothingFoundMessage={"Không có dữ liệu"}
                key={formSeachInput.key("status")}
                {...formSeachInput.getInputProps("status")}
              />
            </GridCol>
            <GridCol span={{ base: 8, sm: 6, md: 3.35, lg: 2.5 }}>
              <DatePickerInput
                type="range"
                size="sm"
                allowSingleDateInRange
                placeholder="Chọn khoảng ngày"
                leftSection={<IconTimeDuration15 color="#15aabf" />}
                locale="vi"
                valueFormat="DD/MM/YYYY"
                onChange={(e) => {
                  formSeachInput.setFieldValue(
                    "startDate",
                    e[0] ? e[0].toISOString() : ""
                  );
                  formSeachInput.setFieldValue(
                    "endDate",
                    e[1] ? e[1].toISOString() : ""
                  );
                }}
                clearable
              />
            </GridCol>
            <GridCol span={{ base: 4, sm: 2, md: 1 }}>
              <Button
                leftSection={<IconSearch size={14} />}
                color="blue"
                variant="outline"
                type="submit"
              >
                Tìm kiếm
              </Button>
            </GridCol>
          </Grid>
        </form>
        <div>
          <Button
            leftSection={
              <IconPlus style={{ width: rem(14), height: rem(14) }} />
            }
            onClick={() => onhandleCreate()}
          >
            Thêm mới
          </Button>
        </div>
      </Flex>

      <div className="mt-5" style={{ marginTop: "10px" }}>
        <MantineReactTable table={table} />
      </div>
    </>
  );
};

export default DMBorrwedGoods;
