import {
  Badge,
  Box,
  Button,
  ComboboxItem,
  Fieldset,
  Flex,
  Grid,
  Group,
  LoadingOverlay,
  Select,
  Text,
  TextInput,
  Textarea,
  Tooltip,
} from "@mantine/core";
import { DatePickerInput, DateTimePicker } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { useEffect, useRef, useState } from "react";
import {
  repositoryDelivery,
  repositoryMdm,
  repositoryPos,
} from "../../../_base/_const/_constVar";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { MessageResponse } from "../../../model/MessageResponse";
import { TblDelivery } from "../../../model/TblDelivery";
import { TblDmCustomer } from "../../../model/TblDmCustomer";
import { TblDMDeliveryDepartment } from "../../../model/TblDMDeliveryDepartment";
import { IconArrowLeft } from "@tabler/icons-react";
import DeliveryDetailByDeliveryId from "./DeliveryDetailByDeliveryId";
import DeliveryDetailByRequestTransfer from "./DeliveryDetailByRequestTransfer";
import DeliveryDetailBySellItem from "./DeliveryDetailBySellItem";
import { getTblDmEmployeeSelect } from "../../../service/getSelectApi";
import { getValueById } from "../../../_base/helper/FunctionHelper";
import React from "react";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_RowSelectionState,
  useMantineReactTable,
} from "mantine-react-table";
import { paginationBase } from "../../../_base/model/_base/BaseTable";
import { TblDeliveryDetails } from "../../../model/TblDeliveryCreate";

const DetailDataView = ({ id, handleDetail }: DetailDataViewProps) => {
  const entity = {
    id: "0",
    assignmentDate: null,
    finishDate: null,
    orderHeaderId: null,
    statusId: null,
    sourceCode: null,
    sourceType: null,
    description: null,
    isDraft: null,
    printedNumber: null,
    confirmationDate: new Date().toString(),
    fromDeliveryDate: new Date().toString(),
    toDeliveryDate: new Date().toString(),
    method: null,
    branchId: null,
    type: null,
    depId: null,
    returnReason: null,
    customerAddress: null,
    customerProvinceId: null,
    customerDistrictId: null,
    customerCommuneId: null,
    fromAddress: null,
    toAddress: null,
    lastUpdateDate: null,
    createBy: null,
    createDate: new Date().toString(),
    lastUpdateBy: null,
    parentId: null,
    priorityId: null,
    customerId: null,
    telephoneNumber: null,
    transactionNumber: null,
    transactionContent: null,
    deliveryAppointmentTime: new Date().toString(),
    attribute1: null,
    attribute2: null,
    attribute3: null,
    attribute4: null,
    attribute5: null,
    tblDeliveryDetails: null,
  };

  const [visible, { toggle, close, open }] = useDisclosure(false);
  const [dataTblDeliveryDepartment, setDataTblDeliveryDepartment] =
    useState<TblDMDeliveryDepartment>();
  const [dataEmployeeSelect, setDataEmployeeSelect] = useState<ComboboxItem[]>(
    []
  );
  const [dataDeliveryDetailByDeliveryId, setDataDeliveryDetailByDeliveryId] =
    useState<TblDeliveryDetails[]>([]);
  console.log(dataDeliveryDetailByDeliveryId);
  const [dataTblCustomer, setDataTblCustomer] = useState<TblDmCustomer>();

  const [dataBranch, setDataBranch] = useState<any[]>([]);
  const [selectedFromAddr, setSelectedFromAddr] = useState<any | null>(null);
  const [sourceType, setSourceType] = useState(0);
  const [sourceCode, setSourceCode] = useState("");

  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [pagination, setPagination] = useState(paginationBase);

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "attribute2",
        header: "Mã sản phẩm",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "attribute5",
        header: "Serial",
        Cell: ({ row }) => (
          <Text fw={500} size="12.5px">
            {row.original.attribute4 === "HANG_HACOM"
              ? row.original.attribute5
              : null}
          </Text>
        ),
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "productName",
        header: "Tên sản phẩm",
        Cell: ({ row }) => (
          <Box w={350}>
            <Tooltip label={row.original.productName}>
              <Text size="12.5px" fw={500} truncate="end" h={15}>
                {row.original.productName}
              </Text>
            </Tooltip>
          </Box>
        ),
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "attribute3",
        header: "Đơn vị tính",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
    ],
    []
  );

  const form = useForm<TblDelivery>({
    mode: "uncontrolled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },
  });

  const callApiGetData = async () => {
    open();
    try {
      const callapi = await repositoryDelivery.get<
        MessageResponse<TblDelivery>
      >(`/api/v1/TblDelivery/detail?id=${id}`);

      if (callapi) {
        const dataApi = callapi?.data;
        setSourceType(Number(dataApi.sourceType));
        setSourceCode(dataApi.sourceCode ?? "");

        if (dataApi != null) {
          form.setValues(dataApi);
          form.resetDirty(dataApi);
          Promise.all([fetchDataEmployeeSelect(), getDataBranch()]);

          const [deliveryDepartment, customer] = await Promise.all([
            dataDeliveryDepartmentDetail(),
            dataCustomerDetail(),
          ]);
          setDataTblDeliveryDepartment(deliveryDepartment);
          setDataTblCustomer(customer);
        }
        close();
      } else {
        NotificationExtension.Fails("Dữ liệu không tồn tại");
        modals.closeAll();
      }
    } catch (error) {
      console.error(error);
      NotificationExtension.Fails("An error occurred while fetching data");
      close();
    }
  };

  const getDataDeliveryDetailByDeliveryId = async () => {
    try {
      const callApi = await repositoryDelivery.get<
        MessageResponse<TblDeliveryDetails[]>
      >(
        `/api/v1/TblDelivery/get-delivery-detail-by-delivery-id?deliveryId=${id}`
      );
      if (callApi) {
        const dataApi = callApi.data;

        if (dataApi != null) {
          setDataDeliveryDetailByDeliveryId(dataApi);
        } else {
          NotificationExtension.Fails("Dữ liệu không tồn tại");
        }
      }
    } catch (error) {
      console.error(error);
      NotificationExtension.Fails("An error occurred while fetching data");
    }
  };

  async function getDeliveryDepartmentDetail(id: string | number) {
    const req = `/api/v1/TblDmDeliveryDepartment/detail?id=${id}`;
    const callapi = await repositoryMdm.get<MessageResponse<any>>(req);
    if (callapi?.success) return callapi?.data;
    return [];
  }

  const dataDeliveryDepartmentDetail = async () => {
    if (form.getValues().depId) {
      const getData = await getDeliveryDepartmentDetail(
        form.getValues().depId ?? ""
      );
      return getData;
    }
  };

  async function getCustomerDetailMdm(id: string | number) {
    const req = `/api/v1/TblDmCustomer/get-customer-by-list-id?ids=${id}`;
    const callapi = await repositoryPos.get<MessageResponse<any>>(req);
    if (callapi?.success) return callapi?.data[0];
    return [];
  }

  const dataCustomerDetail = async () => {
    if (form.getValues().customerId) {
      const getData = await getCustomerDetailMdm(
        form.getValues().customerId ?? ""
      );
      return getData;
    }
  };

  const fetchDataEmployeeSelect = async () => {
    const getData = await getTblDmEmployeeSelect();
    setDataEmployeeSelect(
      getData
        ?.filter((item: any) => item.value != null && item.text != null)
        ?.map((item: any) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const getDataBranch = async () => {
    const res = await repositoryDelivery.get("/api/v1/TblDmMasterOrg/get-all");

    if (res && res.success) {
      const filteredData = res.data.filter(
        (item: any) => item.code !== null || item.name !== null
      );
      const mappedData = filteredData.map((item: any) => ({
        value: item.id.toString(),
        label: item.name,
      }));
      const mappedDataAddress = filteredData.map((addr: any) => ({
        id: addr.id,
        road: addr.road,
        province: addr.province,
        country: addr.country,
      }));
      setDataBranch(mappedData);
      setSelectedFromAddr(mappedDataAddress);
    }
  };

  const handleRenderSourceType = (sourceType: number) => {
    switch (sourceType) {
      case 3:
        return <DeliveryDetailByDeliveryId id={id} />;
        break;
      case 1:
        return <DeliveryDetailBySellItem sourceCode={sourceCode} id={id} />;
        break;
      case 2:
        return <DeliveryDetailByRequestTransfer sourceCode={sourceCode} />;
        break;
    }
  };

  useEffect(() => {
    if (id) {
      callApiGetData();
      getDataDeliveryDetailByDeliveryId();
    }
  }, [id]);

  const table = useMantineReactTable({
    columns,
    data: dataDeliveryDetailByDeliveryId,
    positionToolbarAlertBanner: "bottom",
    enableTopToolbar: false,
    renderTopToolbarCustomActions: ({ table }) => <></>,
    renderToolbarInternalActions: ({ table }) => <></>,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    enableRowSelection: false,
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select", "transactionNumber"],
        right: ["action"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: 450, minHeight: 450 },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    manualFiltering: false,
    manualPagination: true,
    manualSorting: false,
    rowCount,
    onPaginationChange: setPagination,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontWeight: "500",
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: false
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["20", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    columnFilterDisplayMode: "popover",
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });

  return (
    <>
      <Box component="form">
        <LoadingOverlay
          visible={visible}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />
        <Group
          justify="space-between"
          mt="xs"
          style={{
            position: "sticky",
            bottom: 0,
            backgroundColor: "white",
          }}
        >
          <Badge>Chi tiết đơn giao hàng</Badge>
          <Button
            type="button"
            color="red"
            loading={visible}
            onClick={() => handleDetail()}
            leftSection={!visible ? <IconArrowLeft size={18} /> : undefined}
          >
            Quay lại
          </Button>
        </Group>
        <Flex gap={"md"}>
          <Fieldset
            legend="Thông tin đơn giao hàng"
            w={"50%"}
            p={"5px 15px 15px 15px"}
          >
            <Grid>
              <Grid.Col span={12}>
                <Fieldset legend="Thông tin đơn hàng" p={"5px 15px 15px 15px"}>
                  <Grid>
                    <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
                      <TextInput
                        label="Số giao dịch"
                        size="xs"
                        placeholder={"Nhập số"}
                        type="text"
                        readOnly
                        variant="filled"
                        {...form.getInputProps("transactionNumber")}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
                      <DatePickerInput
                        label="Ngày lập"
                        size="xs"
                        readOnly
                        variant="filled"
                        valueFormat="DD/MM/YYYY"
                        value={
                          form.getValues().createDate
                            ? new Date(form.getValues().createDate ?? "")
                            : null
                        }
                        onChange={() => {}}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
                      <Select
                        label="Người tạo"
                        data={dataEmployeeSelect}
                        value={form.getValues().createBy?.toString() ?? ""}
                        rightSection={" "}
                        size="xs"
                        readOnly
                        variant="filled"
                      />
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
                      <DatePickerInput
                        label="Ngày giao"
                        size="xs"
                        readOnly
                        variant="filled"
                        valueFormat="DD/MM/YYYY"
                        value={
                          form.getValues().fromDeliveryDate
                            ? new Date(form.getValues().fromDeliveryDate ?? "")
                            : null
                        }
                      />
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
                      <TextInput
                        label="Bộ phận giao hàng"
                        variant="filled"
                        size="xs"
                        readOnly
                        value={dataTblDeliveryDepartment?.ddName ?? ""}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
                      <TextInput
                        label="Ghi chú"
                        variant="filled"
                        size="xs"
                        readOnly
                        {...form.getInputProps("description")}
                      />
                    </Grid.Col>
                  </Grid>
                </Fieldset>
              </Grid.Col>
              <Grid.Col span={12}>
                <Fieldset legend="Thông tin giao hàng" p={"5px 15px 15px 15px"}>
                  <Grid>
                    <Grid.Col span={{ base: 12, lg: 4 }}>
                      <DatePickerInput
                        label="Ngày hẹn giao"
                        size="xs"
                        readOnly
                        variant="filled"
                        valueFormat="DD/MM/YYYY"
                        value={
                          form.getValues().deliveryAppointmentTime
                            ? new Date(
                                form.getValues().deliveryAppointmentTime ?? ""
                              )
                            : null
                        }
                        onChange={() => {}}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, lg: 4 }}>
                      <TextInput
                        label="SĐT"
                        size="xs"
                        variant="filled"
                        readOnly
                        type="text"
                        value={form.getValues().telephoneNumber ?? ""}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, lg: 4 }}>
                      <TextInput
                        label="Khách hàng"
                        size="xs"
                        readOnly
                        variant="filled"
                        type="text"
                        value={dataTblCustomer?.name ?? ""}
                      />
                    </Grid.Col>
                  </Grid>
                  <Grid>
                    <Grid.Col span={12}>
                      <TextInput
                        label="Nội dung giao"
                        size="xs"
                        readOnly
                        variant="filled"
                        type="text"
                        {...form.getInputProps("transactionContent")}
                      />
                    </Grid.Col>
                  </Grid>
                </Fieldset>
              </Grid.Col>
              <Grid.Col span={12}>
                <Grid>
                  <Grid.Col span={12}>
                    <Fieldset
                      legend="Địa chỉ giao hàng"
                      p={"5px 15px 15px 15px"}
                    >
                      <Grid>
                        <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
                          <Select
                            label="Địa điểm"
                            size="xs"
                            placeholder={"Chọn địa điểm"}
                            data={dataBranch}
                            value={form.getValues().branchId?.toString()}
                            variant="filled"
                            readOnly
                          />
                        </Grid.Col>
                        <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
                          <TextInput
                            label="Tỉnh/TP"
                            size="xs"
                            readOnly
                            variant="filled"
                            value={getValueById(
                              form.getValues().branchId ?? 0,
                              selectedFromAddr,
                              "province"
                            )}
                          />
                        </Grid.Col>
                        <Grid.Col span={{ base: 12, md: 12, lg: 7 }}>
                          <TextInput
                            label="Địa chỉ"
                            size="xs"
                            readOnly
                            variant="filled"
                            value={getValueById(
                              form.getValues().branchId ?? 0,
                              selectedFromAddr,
                              "road"
                            )}
                          />
                        </Grid.Col>
                      </Grid>
                    </Fieldset>
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <Fieldset
                      legend="Địa chỉ nhận hàng"
                      p={"5px 15px 15px 15px"}
                    >
                      <TextInput
                        label="Địa chỉ"
                        variant="filled"
                        size="xs"
                        readOnly
                        value={
                          form.getValues().attribute3
                            ? `${form.getValues().attribute3} - ${
                                form.getValues().toAddress
                              }`
                            : `${form.getValues().toAddress}`
                        }
                      />
                    </Fieldset>
                  </Grid.Col>
                </Grid>
              </Grid.Col>
            </Grid>
          </Fieldset>
          <Fieldset
            legend={
              sourceType === 3 ? "Thông tin sản phẩm" : "Chi tiết đơn hàng"
            }
            w={"50%"}
          >
            {sourceType === 3 ? (
              <Box pt={15}>
                <MantineReactTable table={table} />
              </Box>
            ) : (
              handleRenderSourceType(sourceType)
            )}
          </Fieldset>
        </Flex>
      </Box>
    </>
  );
};

export default DetailDataView;

type DetailDataViewProps = {
  id: any;
  handleDetail: any;
};
