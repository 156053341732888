import { Badge, Box, Tooltip, Text } from "@mantine/core";
import {
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import * as React from "react";
import { useEffect, useState } from "react";
import {
  _timerDelayClick,
  repositoryDelivery,
  repositoryMdm,
} from "../../../_base/_const/_constVar";
import { _localization } from "../../../config/location";
import "../../../Styles/tab.component.css";
import { green } from "../../../const/variables";
import { MessageResponse } from "../../../model/MessageResponse";

const DetailProduct = ({
  id,
  sourceType,
  sourceCode,
  isDetail,
  setDataOrder,
}: {
  id: any;
  sourceType: any;
  sourceCode: any;
  isDetail: any;
  setDataOrder?: any;
}) => {
  //data and fetching state
  const [data, setData] = useState<any[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  //table state
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [selectIds, setSelectIds] = useState<string[]>([]);
  //count
  const [timeLeft, setTimeLeft] = useState(60);
  const [isCounting, setIsCounting] = useState(false);

  const columnType3 = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "attribute2",
        header: "Mã sản phẩm",
        Cell: ({ renderedCellValue }) => (
          <Badge
            radius="sm"
            variant="dot"
            size="lg"
            color={renderedCellValue === null ? "red" : green.base}
          >
            {renderedCellValue === null ? "Không xác định" : renderedCellValue}
          </Badge>
        ),
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: "attribute1",
        header: "Mã serial",
        Cell: ({ renderedCellValue }) => (
          <Badge
            radius="sm"
            variant="dot"
            size="lg"
            color={renderedCellValue === null ? "red" : green.base}
          >
            {renderedCellValue === null ? "Không xác định" : renderedCellValue}
          </Badge>
        ),
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: "productName",
        header: "Tên sản phẩm",
        Cell: ({ row }) => (
          <Box w={350}>
            <Tooltip label={row.original.productName}>
              <Text size="12.5px" fw={500} truncate="end" h={15}>
                {row.original.productName}
              </Text>
            </Tooltip>
          </Box>
        ),
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: "attribute3",
        header: "Đơn vị tính",
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: "note",
        header: "Ghi chú",
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
    ],
    []
  );

  const columnsType1 = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "itemCode",
        header: "Mã sản phẩm",
        Cell: ({ renderedCellValue }) => (
          <Badge
            radius="sm"
            variant="dot"
            size="lg"
            color={renderedCellValue === null ? "red" : green.base}
          >
            {renderedCellValue === null ? "Không xác định" : renderedCellValue}
          </Badge>
        ),
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: "serial",
        header: "Mã serial",
        Cell: ({ renderedCellValue }) => (
          <Badge
            radius="sm"
            variant="dot"
            size="lg"
            color={renderedCellValue === null ? "red" : green.base}
          >
            {renderedCellValue === null ? "Không xác định" : renderedCellValue}
          </Badge>
        ),
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: "itemName",
        header: "Tên sản phẩm",
        Cell: ({ row }) => (
          <Box w={350}>
            <Tooltip label={row.original.itemName}>
              <Text size="12.5px" fw={500} truncate="end" h={15}>
                {row.original.itemName}
              </Text>
            </Tooltip>
          </Box>
        ),
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: "primaryUomCode",
        header: "Đơn vị tính",
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: "soPrice",
        header: "Giá",
        Cell: ({ row }) => (
          <Text size="12.5px" fw={500}>
            {row.original.soPrice?.toLocaleString()} đ
          </Text>
        ),
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: "serialStatus",
        header: "Trạng thái",
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: "note",
        header: "Ghi chú",
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
    ],
    []
  );

  const columnsType2 = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "itemCode",
        header: "Mã sản phẩm",
        Cell: ({ renderedCellValue }) => (
          <Badge
            radius="sm"
            variant="dot"
            size="lg"
            color={renderedCellValue === null ? "red" : green.base}
          >
            {renderedCellValue === null ? "Không xác định" : renderedCellValue}
          </Badge>
        ),
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: "serialNumber",
        header: "Mã serial",
        Cell: ({ renderedCellValue }) => (
          <Badge
            radius="sm"
            variant="dot"
            size="lg"
            color={renderedCellValue === null ? "red" : green.base}
          >
            {renderedCellValue === null ? "Không xác định" : renderedCellValue}
          </Badge>
        ),
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: "itemName",
        header: "Tên sản phẩm",
        Cell: ({ row }) => (
          <Box w={350}>
            <Tooltip label={row.original.itemName}>
              <Text size="12.5px" fw={500} truncate="end" h={15}>
                {row.original.itemName}
              </Text>
            </Tooltip>
          </Box>
        ),
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: "dvt",
        header: "Đơn vị tính",
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: "donGia",
        header: "Đơn giá",
        Cell: ({ renderedCellValue }) => (
          <Text fw={500} size="12.5px">
            {renderedCellValue?.toLocaleString()} đ
          </Text>
        ),
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: "serialStatus",
        header: "Trạng thái serial",
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: "note",
        header: "Ghi chú",
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
    ],
    []
  );

  const getDataListProduct = async () => {
    if (sourceType && sourceType !== null) {
      let url = "";

      if (sourceType === 3) {
        url += `/api/v1/TblDelivery/get-delivery-detail-by-delivery-id?deliveryId=${id}`;

        const res = await repositoryDelivery.get<MessageResponse<any>>(url);

        if (res && res?.success) {
          const result = res.data;
          setData(result);
        }
      } else if (sourceType === 1) {
        url += `/api/v1/TblDelivery/detail-delivery?id=${id}`;

        const res = await repositoryDelivery.get<MessageResponse<any>>(url);
        if (res && res?.success) {
          const result = res.data;
          setData(result?.listItemDeliveries);
          if (isDetail) {
            setDataOrder(result);
          }
        }
      } else if (sourceType === 2) {
        url += `/api/v1/TblItemTransaction/get-detail-by-request-transfer-code?RequestTransferCode=${sourceCode}`;

        const res = await repositoryDelivery.get<MessageResponse<any>>(url);
        if (res && res?.success) {
          const result = res.data;
          setData(result);
        }
      }
    } else {
      return;
    }
  };

  useEffect(() => {
    let timer: ReturnType<typeof setInterval>;
    if (isCounting && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsCounting(false);
    }
    return () => clearInterval(timer);
  }, [isCounting, timeLeft]);

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);

  useEffect(() => {
    if (id || sourceCode) {
      getDataListProduct();
    }
  }, [id, sourceCode]);

  const table = useMantineReactTable({
    columns:
      sourceType === 3
        ? columnType3
        : sourceType === 1
        ? columnsType1
        : columnsType2,
    data,
    positionToolbarAlertBanner: "bottom",
    columnFilterDisplayMode: "popover",
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    enableRowSelection: false,
    enablePagination: false,
    enableBottomToolbar: false,
    enableTopToolbar: false,
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: false,
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: {
        maxHeight: !isDetail ? 500 : "320px",
        minHeight: !isDetail ? 100 : "320px",
      },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    rowCount,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        fontWeight: "500", //conditional styling
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    mantineTableProps: () => ({
      striped: true,
    }),
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      //  pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no data
      //sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["10", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });

  return (
    <>
      <MantineReactTable table={table} />
    </>
  );
};

export default DetailProduct;
