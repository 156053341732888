import {
  Button,
  Checkbox,
  Fieldset,
  Flex,
  ScrollArea,
  Select,
  Table,
} from "@mantine/core";
import { useState, useEffect } from "react";
import classes from "../Styles/ScrollTable.module.css";
import cx from "clsx";
import { modals } from "@mantine/modals";
import { IconCheck, IconWindow } from "@tabler/icons-react";
import { TblDmCustomerCategory } from "../../../../../model/TblDmCustomerCategory";
import { repositoryPos } from "../../../../../_base/_const/_constVar";
import { MessageResponse } from "../../../../../model/MessageResponse";
import { TblDmCustomer } from "../../../../../model/TblDmCustomer";
import { NotificationExtension } from "../../../../../_base/extension/NotificationExtension";
import { sky_blue } from "../../../../../const/variables";

const ModalCustomers = ({
  data,
  dataTable,
  handleAddCustomer,
}: {
  data: TblDmCustomerCategory[];
  dataTable: TblDmCustomer[];
  handleAddCustomer: (selectedCus: TblDmCustomer[]) => void;
}) => {
  const [selectedCus, setSelectedCus] = useState<TblDmCustomer[]>([]);
  const [scrolled, setScrolled] = useState(false);
  const [selectedCustomerCat, setSelectedCustomerCat] = useState("");
  const [selectedData, setSelectedData] = useState<any[]>([]);
  const [dataOption, setDataOption] = useState<any[]>([]);
  const [dataCustomer, setDataCustomer] = useState<TblDmCustomer[]>([]);

  const handleCheckboxChange = (cus: TblDmCustomer, checked: boolean) => {
    const updatedSelectedRows = checked
      ? [...selectedCus, cus]
      : selectedCus.filter((item) => item.id !== cus.id);

    setSelectedCus(updatedSelectedRows);
  };

  const handleConfirm = () => {
    if (dataCustomer.length > 0) {
      handleAddCustomer(selectedCus.length < 1 ? dataCustomer : selectedCus);
    } else {
      NotificationExtension.Fails("Không có nhóm khách hàng nào!");
    }
  };

  // useEffect(() => {
  //   setSelectedData(existingDataTable);
  //   const selectedCodes = existingDataTable
  //     .flatMap((customers) => customers.detailGroupCustomers)
  //     .map((detail) => detail.code);
  //   setSelectedRow(selectedCodes);
  // }, [existingDataTable]);

  useEffect(() => {
    setDataOption(
      data.map((cat) => ({
        value: cat.id.toString() || "",
        label: cat.name || "",
      }))
    );

    setSelectedCus(dataTable);
  }, []);

  useEffect(() => {
    const fetchDataCustomer = async () => {
      if (selectedCustomerCat) {
        const url = `/api/v1/TblDmCustomer/get-customer-by-group?groupId=${selectedCustomerCat}`;

        const dataApi = await repositoryPos.get<
          MessageResponse<TblDmCustomer[]>
        >(url);

        if (dataApi && dataApi.success) {
          setDataCustomer(dataApi.data);
        } else setDataCustomer([]);
      } else setDataCustomer([]);
    };

    fetchDataCustomer();
  }, [selectedCustomerCat]);

  return (
    <>
      <Fieldset legend="Danh sách nhóm khách hàng" m={"10px 0px"}>
        <Select
          label="Nhóm khách hàng"
          checkIconPosition="left"
          data={dataOption}
          defaultValue={"Tất cả"}
          onChange={(value) => setSelectedCustomerCat(value || "")}
        />
        <ScrollArea
          h={175}
          m={"10px 0px"}
          onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
        >
          <Table
            striped
            highlightOnHover
            withColumnBorders
            withTableBorder
            style={{ overflowY: "auto", height: "100px" }}
          >
            <Table.Thead
              className={cx(classes.header, { [classes.scrolled]: scrolled })}
            >
              <Table.Tr>
                <Table.Th w={70}>Áp dụng</Table.Th>
                <Table.Th w={80}>Mã</Table.Th>
                <Table.Th>Nhóm khách hàng</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {dataCustomer.map((item: any) => (
                <Table.Tr
                  key={item.id}
                  bg={
                    selectedCus.some((cus) => cus.id === item.id)
                      ? "var(--mantine-color-blue-light)"
                      : undefined
                  }
                >
                  <Table.Td>
                    <Checkbox
                      checked={selectedCus.some((cus) => cus.id === item.id)}
                      onChange={(event) =>
                        handleCheckboxChange(item, event.currentTarget.checked)
                      }
                    />
                  </Table.Td>
                  <Table.Td>{item.code}</Table.Td>
                  <Table.Td>{item.name}</Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
            {dataCustomer.length < 1 && (
              <Table.Caption>Không có dữ liệu</Table.Caption>
            )}
          </Table>
        </ScrollArea>
      </Fieldset>
      <Flex align={"center"} justify={"center"} gap={10}>
        <Button
          color="gray"
          onClick={() => {
            modals.closeAll();
          }}
          leftSection={<IconWindow size={18} />}
        >
          Đóng
        </Button>
        <Button
          color={sky_blue.base}
          leftSection={<IconCheck size={18} />}
          disabled={!selectedCustomerCat}
          onClick={() => {
            handleConfirm();
          }}
        >
          Xác nhận
        </Button>
      </Flex>
    </>
  );
};

export default ModalCustomers;
