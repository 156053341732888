import { forwardRef } from "react";
import styles from "./PrintStyle.module.css";
import { Flex, Image } from "@mantine/core";
import { IconCheck } from "@tabler/icons-react";
interface PrintedInvoiceProps {
  dataPrint: any;
}

const PrintExportBorrowedGoods = forwardRef<
  HTMLDivElement,
  PrintedInvoiceProps
>(({ dataPrint }, ref) => {
  return (
    <div ref={ref}>
      <div className={styles.containerPrint}>
        <div className={styles.contentMain}>
          <table className={styles.reportContainer}>
            <thead className={styles.reportHeader}>
              <tr>
                <th className={styles.reportHeaderCell}>
                  <div className={`${styles.header} ${styles.pageHeader}`}>
                    <Flex direction="row" justify="space-between" align="start">
                      <div className={styles.logo}>
                        <Image
                          radius="md"
                          src="https://hanoicomputercdn.com/media/lib/17-10-2024/logo-hacom-since-2001_new.png"
                          className={styles.logo}
                          style={{ width: "150px" }}
                        />
                      </div>
                      <div className={styles.headerRight}>
                        <h4
                          className={styles.text}
                          style={{
                            fontSize: "14.5px",
                            fontWeight: 900,
                          }}
                        >
                          CÔNG TY CỔ PHẦN ĐẦU TƯ CÔNG NGHỆ HACOM
                        </h4>
                        <Flex direction="column" gap="0">
                          <Flex direction="row">
                            <span
                              style={{
                                color: "rgb(230, 37, 43)",
                                fontWeight: 800,
                              }}
                            >
                              Trụ sở:
                            </span>
                            <span>
                              129+131 Lê Thanh nghị, Q.Hai Bà Trưng, TP.Hà Nội
                            </span>
                          </Flex>
                          <Flex direction="row" gap="lg">
                            <Flex direction="row">
                              <span
                                style={{
                                  color: "rgb(230, 37, 43)",
                                  fontWeight: 800,
                                }}
                              >
                                Tel:
                              </span>
                              <span>19001903</span>
                            </Flex>
                            <Flex direction="row">
                              <span
                                style={{
                                  color: "rgb(230, 37, 43)",
                                  fontWeight: 800,
                                }}
                              >
                                Email:
                              </span>
                              <span>info@hacom.vn</span>
                            </Flex>
                          </Flex>
                          <Flex direction="row">
                            <span
                              style={{
                                color: "rgb(230, 37, 43)",
                                fontWeight: 800,
                              }}
                            >
                              Website:
                            </span>{" "}
                            <span>www.hacom.vn</span>
                          </Flex>
                        </Flex>
                      </div>
                    </Flex>
                  </div>
                </th>
              </tr>
            </thead>
            {/* <tfoot className={styles.reportFooter}>
              <tr>
                <td className={styles.reportFooterCell}>
                  <Flex
                    direction="row"
                    style={{ width: "100%" }}
                    className={styles.footerInfo}
                    justify="space-between"
                    align={"center"}
                  >
                    <div>
                      <span style={{ fontSize: "10px" }}>
                        BM.HCKT.HACOM_0129{" "}
                      </span>
                    </div>
                    <div style={{ fontSize: "10px" }}>Trang</div>
                  </Flex>
                </td>
              </tr>
            </tfoot> */}
            <tbody className={styles.reportContent}>
              <tr>
                <td className={styles.reportContentCell}>
                  <div className={styles.contentMain}>
                    <div>
                      <h3
                        className={styles.text}
                        style={{
                          textAlign: "center",
                          fontWeight: 600,
                          fontSize: "18px",
                        }}
                      >
                        BIÊN BẢN BÀN GIAO SẢN PHẨM CHO MƯỢN
                      </h3>
                    </div>
                    <Flex
                      direction="column"
                      style={{ padding: "0 30px", margin: "0 auto" }}
                    >
                      <span
                        style={{
                          fontStyle: "italic",
                          fontSize: "14.5px",
                          lineHeight: "1.6",
                          color: "#000",
                          fontWeight: "normal",
                        }}
                      >
                        Căn cứ Phiếu tiếp nhận – Bàn giao sản phẩm bảo hành số:
                        ………………. ngày …… tháng …… năm 20……;
                      </span>
                      <span
                        style={{
                          fontStyle: "italic",
                          fontSize: "14.5px",
                          lineHeight: "1.6",
                          fontWeight: "normal",
                          color: "#000",
                        }}
                      >
                        Căn cứ nhu cầu và năng lực các Bên.
                      </span>
                      <span
                        style={{
                          fontSize: "14.5px",
                          lineHeight: "1.6",
                          fontWeight: "normal",
                          color: "#000",
                        }}
                      >
                        Hôm nay, ngày ….. tháng ….. năm 20….., tại ………………….,
                        chúng tôi gồm:
                      </span>
                    </Flex>
                    {/* ----- */}
                    <div>
                      <div>
                        <Flex direction="row">
                          <span style={{ fontWeight: 600 }}>
                            A. BÊN A (Bên mượn): Ông/Bà{" "}
                          </span>
                          <span className={styles.dottedLine}></span>
                        </Flex>
                      </div>
                      <div className={styles.infoTop}>
                        <Flex direction="row" align="center">
                          <Flex
                            direction="row"
                            style={{ width: "75%" }}
                            align="center"
                          >
                            <IconCheck
                              stroke={1}
                              style={{
                                marginRight: "5px",
                                marginLeft: "-2px",
                                width: "15px",
                                height: "25px",
                              }}
                            />
                            <span>Địa chỉ</span>
                            <span
                              className={styles.dottedLine}
                              style={{ flexGrow: 1 }}
                            ></span>
                          </Flex>
                          <Flex direction="row" style={{ width: "25%" }}>
                            <span>Điện thoại</span>
                            <span
                              className={styles.dottedLine}
                              style={{ flexGrow: 1 }}
                            ></span>
                          </Flex>
                        </Flex>
                        <Flex direction="row" align="center">
                          <Flex
                            direction="row"
                            align="center"
                            style={{ width: "50%" }}
                          >
                            <IconCheck
                              stroke={1}
                              style={{
                                marginRight: "5px",
                                marginLeft: "-2px",
                                width: "15px",
                                height: "25px",
                              }}
                            />
                            <span>Số CCCD:</span>
                            <span
                              className={styles.dottedLine}
                              style={{ flexGrow: 1 }}
                            ></span>
                          </Flex>
                          <Flex direction="row" style={{ width: "25%" }}>
                            <span>Ngày cấp:</span>
                            <span
                              className={styles.dottedLine}
                              style={{ flexGrow: 1 }}
                            ></span>
                          </Flex>
                          <Flex direction="row" style={{ width: "25%" }}>
                            <span>Nơi cấp</span>
                            <span
                              className={styles.dottedLine}
                              style={{ flexGrow: 1 }}
                            ></span>
                          </Flex>
                        </Flex>
                      </div>
                    </div>
                    {/* ---- */}
                    <div>
                      <div>
                        <Flex direction="row">
                          <span style={{ fontWeight: 700 }}>
                            B. BÊN B (Bên cho mượn): CÔNG TY CỔ PHẦN ĐẦU TƯ CÔNG
                            NGHỆ HACOM
                          </span>
                          {/* <span className={styles.dottedLine}></span> */}
                        </Flex>
                      </div>
                      <div className={styles.infoTop}>
                        <Flex direction="row" align="center">
                          <Flex
                            direction="row"
                            style={{ width: "75%" }}
                            align="center"
                          >
                            <IconCheck
                              stroke={1}
                              style={{
                                marginRight: "5px",
                                marginLeft: "-2px",
                                width: "15px",
                                height: "25px",
                              }}
                            />
                            <span>Địa chỉ</span>
                            <span
                              className={styles.dottedLine}
                              style={{ flexGrow: 1 }}
                            ></span>
                          </Flex>
                          <Flex direction="row" style={{ width: "25%" }}>
                            <span>Điện thoại</span>
                            <span
                              className={styles.dottedLine}
                              style={{ flexGrow: 1 }}
                            ></span>
                          </Flex>
                        </Flex>
                        <Flex direction="row" align="center">
                          <Flex
                            direction="row"
                            align="center"
                            style={{ width: "50%" }}
                          >
                            <IconCheck
                              stroke={1}
                              style={{
                                marginRight: "5px",
                                marginLeft: "-2px",
                                width: "15px",
                                height: "25px",
                              }}
                            />
                            <span>Đại diện:</span>
                            <span
                              className={styles.dottedLine}
                              style={{ flexGrow: 1 }}
                            ></span>
                          </Flex>
                          <Flex direction="row" style={{ width: "50%" }}>
                            <span>Chức vụ:</span>
                            <span
                              className={styles.dottedLine}
                              style={{ flexGrow: 1 }}
                            ></span>
                          </Flex>
                        </Flex>
                      </div>
                    </div>
                    {/* ------ */}
                    <div>
                      <Flex direction="column" className={styles.term}>
                        <span style={{ paddingLeft: "20px" }}>
                          Hai Bên thống nhất lập Biên bản bàn giao sản phẩm cho
                          mượn (sau đây gọi tắt là “Biên bản”) với các nội dung
                          sau:
                        </span>
                        <span>
                          <strong>1. Mục đích bàn giao:</strong> Bên A mượn sản
                          phẩm (máy móc, thiết bị, vật tư) để sử dụng tạm thời
                          trong quá trình Bên B bảo hành theo nội dung Phiếu
                          tiếp nhận – bàn giao sản phẩm số: ………… ngày …… tháng
                          …… năm 20….
                        </span>
                        <span>
                          <strong>2. Sản phẩm đã bàn giao: </strong> Bên B đã
                          bàn giao đầy đủ cho Bên A các sản phẩm mà Bên A có nhu
                          cầu sử dụng tạm thời, bảo đảm đúng chủng loại, hoạt
                          động tốt, tình trạng sản phẩm rõ ràng, minh bạch như
                          sau:
                        </span>
                      </Flex>
                      <div className={styles.tableProduct}>
                        <table>
                          <thead>
                            <tr>
                              <th style={{ width: "5%" }}>STT</th>
                              <th style={{ width: "60%" }}>Tên sản phẩm</th>
                              <th style={{ width: "5%" }}>SL</th>
                              <th style={{ width: "15%" }}>Tình trạng</th>
                              <th style={{ width: "15%" }}>Ghi chú</th>
                            </tr>
                          </thead>
                          <tbody>
                            {dataPrint?.tblWarrantyExItemBorrowTicketAddDetailModels.map(
                              (item: any, index: number) => {
                                return (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.itemName}</td>
                                    <td>{item.amount}</td>
                                    <td>{item.condition}</td>
                                    <td>{item.note}</td>
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {/* ------ */}
                    <div className={styles.infoCenter}>
                      <Flex direction="column">
                        <span>
                          <strong>
                            3. Giá trị sản phẩm mượn và số tiền đặt cọc{" "}
                          </strong>
                        </span>
                        <span>
                          - Tổng giá trị sản phẩm Bên B cho Bên A mượn ước tính:
                          ...................................VNĐ
                        </span>
                        <Flex direction="row" style={{ width: "100%" }}>
                          <span>Bằng chữ:</span>
                          <span className={styles.dottedLine}></span>
                        </Flex>
                        <span>
                          Số tiền Bên A đặt cọc cho Bên B là:
                          ................................... VNĐ.
                        </span>
                        <Flex direction="row" style={{ width: "100%" }}>
                          <span>Bằng chữ:</span>
                          <span className={styles.dottedLine}></span>
                        </Flex>
                        <Flex direction="row" style={{ width: "100%" }}>
                          <span>- Hình thức đặt cọc:</span>
                          <span className={styles.dottedLine}></span>
                        </Flex>
                        <span>
                          Trong trường hợp Bên A làm mất sản phẩm hoặc làm hư
                          hỏng sản phẩm đã mượn của Bên B, Hai Bên đồng ý khoản
                          đặt cọc này sẽ được sử dụng để Bên A khắc phục, bồi
                          thường một phần/toàn bộ thiệt hại cho Bên B. Nội dung
                          này sẽ được Hai Bên thỏa thuận, xác nhận chi tiết bằng
                          văn bản.
                        </span>
                        <span>
                          Trong trường hợp hết thời hạn mượn sản phẩm theo nội
                          dung Biên bản này, nếu Bên A không thực hiện việc hoàn
                          trả sản phẩm đã mượn cho Bên B, Bên A sẽ không được
                          Bên B hoàn lại toàn bộ số tiền đã đặt cọc nêu trên,
                          trừ trường hợp Bên A có lý do chính đáng được Bên B
                          chấp thuận.
                        </span>
                        <span>
                          <strong>4. Thời hạn mượn sản phẩm: </strong>
                        </span>
                      </Flex>
                    </div>

                    {/* ----- */}
                    <div className={styles.infoBottom}>
                      <Flex direction="column">
                        <span>
                          Bên B cho Bên A mượn sản phẩm theo nội dung Biên bản
                          này từ ngày……tháng…..năm 20…. đến hết ngày
                          …….tháng…….năm 20….., tương ứng với thời gian sửa
                          chữa/bảo hành theo Phiếu tiếp nhận bảo hành
                          số:……………….ngày……tháng……năm 20……
                        </span>
                        <span>
                          Trường hợp vì lý do sản phẩm của Bên A cần thời gian
                          bảo hành/sửa chữa kéo dài hơn dự kiến so với nội dung
                          đã lập trong Phiếu tiếp nhận bảo hành, Bên B có trách
                          nhiệm thông báo cho Bên A về thời gian gia hạn bảo
                          hành thông qua một trong các phương tiện liên lạc bằng
                          điện thoại hoặc nền tảng ứng dụng trực tuyến/sms
                          (trường hợp Bên A không tiếp nhận cuộc gọi) thì việc
                          hoàn trả sản phẩm cho mượn sẽ không bị tính là quá hạn
                          cho đến khi việc bảo hành sản phẩm cho Bên A hoàn tất.
                        </span>
                        <span>
                          <strong>5. Trách nhiệm của Hai Bên: </strong>
                        </span>
                        <span>
                          <Flex direction="row" align="center">
                            <IconCheck
                              stroke={1}
                              style={{
                                marginRight: "5px",
                                marginLeft: "-2px",
                                width: "15px",
                                height: "25px",
                              }}
                            />{" "}
                            <strong> Trách nhiệm của Bên A: </strong>
                          </Flex>
                        </span>
                        <span>
                          - Hoàn trả lại sản phẩm đã mượn của Bên B đúng thời
                          hạn, đúng hiện trạng theo nội dung Biên bản này.{" "}
                          <br />
                          - Nếu Bên A làm mất sản phẩm, Bên A phải thanh toán
                          toàn bộ giá trị sản phẩm cho Bên B theo giá trị ước
                          tính đã xác nhận trong Biên bản này. <br />- Nếu Bên A
                          mượn sản phẩm để xảy ra hư hỏng; tình trạng sản phẩm
                          không còn được như mô tả tại Biên bản này thì Bên A
                          phải thanh toán, khắc phục, bồi thường tài sản, sản
                          phẩm đã mượn cho Bên B ngay sau khi Bên B thông báo
                          chi phí sửa chữa, khắc phục cho Bên A. <br />
                          - Với các sản phẩm Bên B cho Bên A mượn là các sản
                          phẩm, vật tư tiêu hao như: máy in Laser, máy in màu,
                          giấy in,… thì Bên A phải chi trả cho Bên B tiền/vật tư
                          bổ sung lại cho vật tư tiêu hao đó. Số tiền/vật tư Bên
                          A trả cho Bên B dựa theo đúng giá trị, đúng vật tư của
                          thị trường tại thời điểm hoàn trả sản phẩm. <br />
                          - Trong thời gian mượn sản phẩm, Bên A tự chịu trách
                          nhiệm về các thông tin dữ liệu, bản quyền phần mềm Bên
                          A đã cài đặt; tự bảo vệ, lưu trữ dữ liệu cá nhân tại
                          sản phẩm đã mượn; chủ động sao lưu (backup) tất cả dữ
                          liệu cá nhân ra khỏi sản phẩm đã mượn trước khi hoàn
                          trả sản phẩm cho Bên B. <br />- Nếu bên A sử dụng sản
                          phẩm mượn của bên B sai mục đích hoặc vi phạm pháp
                          luật, bên A sẽ chịu hoàn toàn trách nhiệm trước Pháp
                          luật.
                        </span>
                        <span>
                          <Flex direction="row" align="center">
                            <IconCheck
                              stroke={1}
                              style={{
                                marginRight: "5px",
                                marginLeft: "-2px",
                                width: "15px",
                                height: "25px",
                              }}
                            />{" "}
                            <strong> Trách nhiệm của Bên B: </strong>
                          </Flex>
                        </span>
                        <span>
                          - Tư vấn, bàn giao sản phẩm cho Bên A theo đúng nhu
                          cầu của Bên A. <br />
                          - Đảm bảo sản phẩm cho mượn đúng hiện trạng theo nội
                          dung Biên bản này. <br />- Hoàn trả lại số tiền Bên A
                          đã đặt cọc sau khi Bên A thanh toán, khắc phục, bồi
                          thường tài sản,… do Bên A làm mất hoặc làm hư hỏng sản
                          phẩm đã mượn của Bên B (nếu có).
                        </span>
                        <span>
                          <strong>6. Điều khoản chung: </strong>
                        </span>
                        <span>
                          - Hai Bên cam kết và bảo đảm rằng khi một Bên đã thực
                          hiện đầy đủ trách nhiệm theo Biên bản này, Bên còn lại
                          sẽ không có bất kì khiếu nại hoặc tiến hành bất cứ
                          hành động pháp lý nào khác nhằm chống lại hoặc gây ảnh
                          hưởng/gây thiệt hại đến uy tín, danh dự, hình ảnh, tài
                          sản… của Bên kia. <br />- Biên bản có hiệu lực ngay
                          sau khi bàn giao sản phẩm và đủ chữ ký của cả Hai Bên.
                          Biên bản này được lập thành 02 bản, Bên A giữ 01 bản,
                          Bên B giữ 01 bản có giá trị như nhau làm căn cứ thực
                          hiện.
                        </span>
                      </Flex>
                      <Flex
                        direction="row"
                        justify="space-around"
                        style={{ marginTop: "10px" }}
                      >
                        <Flex direction="column">
                          <span style={{ fontWeight: 700 }}>
                            ĐẠI DIỆN BÊN A
                          </span>
                          <span>(Ký, ghi rõ họ tên)</span>
                        </Flex>
                        <Flex direction="column">
                          <span style={{ fontWeight: 700 }}>
                            ĐẠI DIỆN BÊN B
                          </span>
                          <span>(Ký, ghi rõ họ tên)</span>
                        </Flex>
                      </Flex>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
});

export default PrintExportBorrowedGoods;
