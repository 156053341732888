import React from "react";

interface TblPaymentReceipt {
  accountfund: string | number | null | undefined;
  codefund: string | null;
  createby: number;
  createdDate: string;
  creatorbranch: number | null | undefined;
  creatordepartment: number | null | undefined;
  creatorfullname?: string | null;
  creatorsalesbranch: string | null;
  creatorworkplace: string | null;
  fundbranch: string | null;
  funddepartment: string | null;
  fundsalesbranch: string | null;
  fundworkplace: string | null;
  funfullname: string | null;
  id: number;
  payerdescription: string | null;
  payerfullname: string | null;
  payernote: string | null;
  payertype: string | null;
  receiptNumber: string;
  anotherName: string | null;
  phoneNumber: string | null;
  codeCustomer: string | null;
  totalAmount: number;
  type: number;
  typeReceipt: number;
  completiontime?: string;
  status?: string | null;
  attribute2?: string | null;
  attribute3?: string | null;
  attribute4?: string | null;
  attribute5?: string | null;
  attribute6?: string | null;
  attribute7?: string | null;
  bankname?: string | null;
  createrolename?: string | null;
  accountnumber?: string | null;
}

type OrderReceiptProps = {
  datafetch: TblPaymentReceipt;
};

export const OrderReceipt: React.FC<OrderReceiptProps> = ({ datafetch }) => {
  return <div>11111</div>;
};
