import {
  Box,
  Button,
  Grid,
  Group,
  NumberInput,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { modals } from "@mantine/modals";
import { IconCheck, IconWindow } from "@tabler/icons-react";
import { tblPromotionItemAddOnCommand } from "../../../../../model/TblPromotion";
import { sky_blue } from "../../../../../const/variables";

const ModalItemAddOnEditDiscount = ({
  dataEdit,
  editItemDiscount,
}: {
  dataEdit: tblPromotionItemAddOnCommand;

  editItemDiscount: (dataSubmit: tblPromotionItemAddOnCommand) => void;
}) => {
  const entity = dataEdit;

  const form = useForm({
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },
    validate: {
      quantityMax: (value, values) => {
        if (value && values.quantityMin && value < values.quantityMin) {
          return "Số lượng tối đa không được nhỏ hơn số lượng tối thiểu";
        }
      },
      quantityMin: (value, values) => {
        if (value && values.quantityMax && value > values.quantityMax) {
          return "Số lượng tối đa không được nhỏ hơn số lượng tối thiểu";
        }
      },
    },
  });

  return (
    <div>
      <Box
        component="form"
        onSubmit={form.onSubmit((e) => {
          editItemDiscount(e);
        })}
      >
        <Grid mt="md">
          <Grid.Col span={6}>
            <TextInput
              label={"% chiết khấu"}
              placeholder={"Nhập %"}
              type="number"
              disabled={!!form.values.discountAmount}
              {...form.getInputProps("discountPercent")}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            {/* <TextInput
              label={"Số tiền chiết khẩu"}
              placeholder={"Nhập số tiền"}
              type="number"
              disabled={!!form.values.discountPercent}
              {...form.getInputProps("discountAmount")}
            /> */}
            <NumberInput
              label={"Số tiền chiết khấu"}
              placeholder={"Nhập số tiền"}
              hideControls
              thousandSeparator="."
              decimalSeparator=","
              disabled={!!form.values.discountPercent}
              {...form.getInputProps("discountAmount")}
              onChange={(e) => {
                form.getInputProps("discountAmount").onChange(e);
              }}
            />
          </Grid.Col>
        </Grid>
        <Grid mt="md">
          <Grid.Col span={6}>
            <NumberInput
              label={"Số Lượng tối thiểu"}
              placeholder={"Nhập số lượng"}
              hideControls
              {...form.getInputProps("quantityMin")}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <NumberInput
              label={"Số Lượng tối đa"}
              placeholder={"Nhập số lượng"}
              hideControls
              {...form.getInputProps("quantityMax")}
            />
          </Grid.Col>
        </Grid>

        <Grid mt="md">
          <Grid.Col span={6}>
            <TextInput
              label={"Số Lượng áp dụng"}
              placeholder={"Nhập số lượng"}
              type="number"
              {...form.getInputProps("quantityApply")}
            />
          </Grid.Col>
          <Grid.Col span={6}></Grid.Col>
        </Grid>

        <Group justify="end" mt="md">
          <Button
            type="button"
            color="gray"
            onClick={() => {
              modals.closeAll();
            }}
            leftSection={<IconWindow size={18} />}
          >
            Đóng
          </Button>
          <Button
            type="submit"
            color={sky_blue.base}
            leftSection={<IconCheck size={18} />}
          >
            Lưu
          </Button>
          <></>
        </Group>
      </Box>
    </div>
  );
};

export default ModalItemAddOnEditDiscount;
