import {
  Box,
  Button,
  Flex,
  ScrollArea,
  Table,
  Title,
  Checkbox,
} from "@mantine/core";
import { IconPlus, IconTrash, IconX } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import classes from "../../Styles/ScrollTable.module.css";
import cx from "clsx";
import { modals } from "@mantine/modals";
import ModalCustomers from "../../Modals/Customers";
import { UseFormReturnType } from "@mantine/form";
import { tblPromotion } from "../../../../../../model/TblPromotion";
import { repositoryPos } from "../../../../../../_base/_const/_constVar";
import { MessageResponse } from "../../../../../../model/MessageResponse";
import { TblDmCustomerCategory } from "../../../../../../model/TblDmCustomerCategory";
import { TblDmCustomer } from "../../../../../../model/TblDmCustomer";
import { red, teal } from "../../../../../../const/variables";

const Customers = ({
  form,
}: {
  form: UseFormReturnType<tblPromotion, (values: tblPromotion) => tblPromotion>;
}) => {
  const [dataTable, setDataTable] = useState<TblDmCustomer[]>([]);
  const [dataCustomerCat, setDataCustomerCat] = useState<
    TblDmCustomerCategory[]
  >([]);
  const [scrolled, setScrolled] = useState(false);
  const [modalOpened, setModalOpened] = useState(false);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [height, setHeight] = useState(0);

  const openModalCustomers = () => {
    setModalOpened(true);
    modals.openConfirmModal({
      centered: true,
      size: "600px",
      title: <Title order={5}>Danh sách nhóm khách hàng</Title>,
      children: (
        <ModalCustomers
          data={dataCustomerCat}
          handleAddCustomer={handleAddCustomer}
          dataTable={dataTable}
        />
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setSelectAll(checked);
    setSelectedRows(checked ? dataTable.map((item) => item.id) : []);
  };

  const handleCheckboxChange = (
    id: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const checked = event.target.checked;
    const updatedSelectedRows = checked
      ? [...selectedRows, id]
      : selectedRows.filter((item) => item !== id);

    setSelectedRows(updatedSelectedRows);
    setSelectAll(updatedSelectedRows.length === dataTable.length);
  };

  const handleAddCustomer = (selectedCus: TblDmCustomer[]) => {
    // Create a Map to keep track of unique customers by their id
    const uniqueCustomersMap = new Map<number, TblDmCustomer>();

    // Add existing customers from dataTable to the map
    dataTable.forEach((customer) =>
      uniqueCustomersMap.set(customer.id, customer)
    );

    // Add new customers from selectedCus to the map, replacing duplicates if they exist
    selectedCus.forEach((customer) =>
      uniqueCustomersMap.set(customer.id, customer)
    );

    // Convert the map back to an array
    const uniqueCustomersArray = Array.from(uniqueCustomersMap.values());

    // Update the state with the unique array of customers
    setDataTable(uniqueCustomersArray);

    // Extract the ids for form field value
    const uniqueIdsArray = uniqueCustomersArray.map((customer) => customer.id);
    form.setFieldValue("tblPromotionCommand.listCustGroup", uniqueIdsArray);
    modals.closeAll();
  };

  const handleDelete = (index?: number) => {
    if (index !== undefined && index !== null) {
      const updatedData = dataTable.filter((_, i) => i !== index);
      setDataTable(updatedData);
      form.setFieldValue(
        "tblPromotionCommand.listCustGroup",
        updatedData.map((cus) => cus.id)
      );
      setSelectedRows([]);
      setSelectAll(false);
    } else {
      const updatedData = dataTable.filter(
        (item) => !selectedRows.includes(item.id)
      );
      setDataTable(updatedData);
      form.setFieldValue(
        "tblPromotionCommand.listCustGroup",
        updatedData.map((cus) => cus.id)
      );
      setSelectedRows([]);
      setSelectAll(false);
    }
  };

  useEffect(() => {
    const fetchDataCustomerCat = async () => {
      const dataApi = await repositoryPos.get<MessageResponse<any>>(
        "/api/v1/TblDmCustomerGroup/get-all"
      );

      if (dataApi && dataApi.success) {
        setDataCustomerCat(dataApi.data);
      } else setDataCustomerCat([]);
    };

    fetchDataCustomerCat();

    // if (form.values.tblPromotionCommand.listCustGroup.length > 0) {
    //   setDataTable(form.values.tblPromotionCommand.listCustGroup);
    // } else setDataTable([]);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight - 510);
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  return (
    <Box mt={10}>
      <ScrollArea
        h={height}
        onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
      >
        <Table
          striped
          highlightOnHover
          withColumnBorders
          withTableBorder
          style={{ overflowY: "auto", height: "30px" }}
        >
          <Table.Thead
            className={cx(classes.header, { [classes.scrolled]: scrolled })}
          >
            <Table.Tr>
              <Table.Th w={30}>
                <Checkbox checked={selectAll} onChange={handleSelectAll} />
              </Table.Th>
              {/* <Table.Th>Mã nhóm</Table.Th> */}
              <Table.Th>Nhóm khách hàng</Table.Th>
              <Table.Th>Mã khách</Table.Th>
              <Table.Th>Tên khách hàng</Table.Th>
              <Table.Th w={70}>Thao tác</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {dataTable?.map((item, index) => (
              <Table.Tr
                key={index}
                style={{
                  backgroundColor: selectedRows.includes(item.id)
                    ? "var(--mantine-color-blue-light)"
                    : "",
                }}
              >
                <Table.Td w={30}>
                  <Checkbox
                    checked={selectedRows.includes(item.id)}
                    onChange={(e) => handleCheckboxChange(item.id, e)}
                  />
                </Table.Td>

                <Table.Td>{item.groupName}</Table.Td>
                <Table.Td>{item.code}</Table.Td>
                <Table.Td>{item.name}</Table.Td>
                <Table.Td>
                  <IconTrash
                    cursor={"pointer"}
                    color="red"
                    onClick={() => handleDelete(index)}
                  />
                </Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
          {dataTable.length < 1 && (
            <Table.Caption>Không có dữ liệu</Table.Caption>
          )}
        </Table>
      </ScrollArea>
      <Flex align={"center"} justify={"space-between"} m={"10px 0px"}>
        <Flex align="center" gap={5}>
          <Button
            size="xs"
            variant="outline"
            leftSection={<IconPlus size={14} />}
            color={teal.base}
            onClick={openModalCustomers}
          >
            Thêm
          </Button>
          <Button
            size="xs"
            variant="outline"
            leftSection={<IconX size={14} />}
            color={red.base}
            onClick={() => handleDelete()}
          >
            Xóa
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
};

export default Customers;
