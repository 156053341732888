import React, { useEffect, useState } from "react";
import {
  Flex,
  Text,
  ActionIcon,
  Popover,
  RadioGroup,
  Radio,
  TextInput,
  Tooltip,
} from "@mantine/core";
import { IconEdit } from "@tabler/icons-react";
import { cobalt_blue } from "../../const/variables";

interface EditableTextWithOptionsProps {
  label: string;
  value: string;
  color?: string;
  options?: string[];
  onChange: (value: string) => void;
  width?: string;
  onClick?: () => void;
  isCod?: boolean;
  required?: boolean;
  readOnly?: boolean;
}

const EditableTextWithOptions: React.FC<EditableTextWithOptionsProps> = ({
  label,
  value,
  options = [],
  onChange,
  color,
  width = "auto",
  onClick,
  isCod = false,
  required = false,
  readOnly = false,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [selectedOption, setSelectedOption] = useState(value);
  const [searchTerm, setSearchTerm] = useState(""); // State để lưu giá trị tìm kiếm

  const handleEditClick = () => {
    setIsEditing((prev) => !prev);
    setSearchTerm(""); // Reset giá trị tìm kiếm khi mở popover
  };

  const handleOptionChange = (value: string) => {
    setSelectedOption(value);
    onChange(value);
    setIsEditing(false);
  };

  const filteredOptions = options.filter((option) =>
    option.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    setSelectedOption(value);
  }, [value]);

  return (
    <Flex align="center" gap="xs" w={"350px"}>
      {label && (
        <Text style={{ flex: isCod ? "0 0 10px" : "0 0 100px" }}>
          {label} {required && <span style={{ color: "red" }}>*</span>}
        </Text>
      )}
      <Tooltip
        label={`${selectedOption}`}
        arrowOffset={10}
        arrowSize={4}
        withArrow
        color="blue"
      >
        <Text
          fw={500}
          c={color}
          style={{ whiteSpace: "nowrap", pointerEvents: "auto" }}
          onClick={onClick}
          truncate
        >
          {selectedOption}
        </Text>
      </Tooltip>
      {!readOnly && (
        <Popover
          opened={isEditing}
          onClose={() => setIsEditing(false)}
          width={200}
          position="bottom"
          withArrow
          withinPortal
        >
          <Popover.Target>
            <ActionIcon size={18} variant="white" onClick={handleEditClick}>
              <IconEdit color={cobalt_blue.base} size={18} />
            </ActionIcon>
          </Popover.Target>
          <Popover.Dropdown style={{ minWidth: width }}>
            <TextInput
              placeholder="Tìm kiếm..."
              value={searchTerm}
              onChange={(event) => setSearchTerm(event.currentTarget.value)}
              mb={8}
            />
            <div style={{ maxHeight: "200px", overflowY: "auto" }}>
              <RadioGroup
                value={selectedOption}
                onChange={handleOptionChange}
                label={`Chọn ${label.toLowerCase()}`}
                required
              >
                {filteredOptions.map((option) => (
                  <Radio mt={5} key={option} value={option} label={option} />
                ))}
              </RadioGroup>
            </div>
          </Popover.Dropdown>
        </Popover>
      )}
    </Flex>
  );
};

export default EditableTextWithOptions;
