import { Badge, Button, Flex } from "@mantine/core";
import {
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import * as React from "react";
import { useEffect, useState } from "react";
import { repositoryMdm } from "../../../../_base/_const/_constVar";
import { paginationBase } from "../../../../_base/model/_base/BaseTable";
import { _localization } from "../../../../config/location";
import { green } from "../../../../const/variables";

const BorrowedGoodList = ({
  setIdFormDataSelected,
  IdFormDataSelected,
  KeySearch,
  idPNHL,
}: {
  setIdFormDataSelected: any;
  KeySearch: string;
  IdFormDataSelected: any;
  idPNHL: string[];
}) => {
  //data and fetching state
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [data, setData] = useState<any[]>([]);
  //table state
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [pagination, setPagination] = useState(paginationBase);
  const [IdItemClicked, setIdItemClicked] = useState<any>(null);
  const handleClick = (id: any) => {
    setIdFormDataSelected(id);
    setIdItemClicked(id);
  };
  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "errorNumber",
        header: "Số phiếu tiếp nhận hàng lỗi",
        Cell: ({ renderedCellValue, cell }) => (
          <Badge
            radius="sm"
            variant="dot"
            size="lg"
            color={renderedCellValue === null ? "red" : green.base}
          >
            {renderedCellValue === null ? null : renderedCellValue}
          </Badge>
        ),
      },
      {
        accessorKey: "createByName",
        header: "Người tiếp nhận",
      },
      {
        accessorKey: "custMeeting",
        header: "Khách hàng hẹn gặp",
      },
      {
        accessorKey: "custPhone",
        header: "Số điện thoại",
      },
      {
        accessorKey: "meetingAddress",
        header: "Địa chỉ hẹn gặp",
      },
      {
        accessorKey: "action",
        header: "Chọn phiếu",
        Cell: ({ row }) => (
          <Flex justify={"center"}>
            <Button
              onClick={() => handleClick(row.original.id)}
              variant="filled"
              color="teal"
              disabled={
                IdFormDataSelected === row.original.id ||
                idPNHL.filter((item) => item === row.original.errorNumber)
                  .length > 0
              }
            >
              {IdFormDataSelected === row.original.id ||
              idPNHL.filter((item) => item === row.original.errorNumber)
                .length > 0
                ? "Đã chọn"
                : "Chọn"}
            </Button>
          </Flex>
        ),
        enableColumnActions: false,
        enableSorting: false,
        size: 50,
      },
    ],
    [IdFormDataSelected]
  );
  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setIsError(false);
    let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }`;
    if (KeySearch) {
      url += `&KeySearch=${KeySearch.trim()}`;
    }
    try {
      const response = await repositoryMdm.get(
        `/api/v1/TblWarrantyReceiveTicket/get-list${url}`
      );
      if (response && response.success) {
        let result = response.data;
        setData(result);
      }
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
  };
  const valiKeySearch = React.useMemo(() => {
    return KeySearch;
  }, [KeySearch]);
  useEffect(() => {
    if (valiKeySearch) {
      fetchData();
    }
  }, [valiKeySearch]);
  const table = useMantineReactTable({
    columns,
    data: data || [],
    positionToolbarAlertBanner: "bottom",
    //add custom action buttons to top-left of top toolbar
    enableColumnActions: false,
    enableSorting: false,
    enableTopToolbar: false,
    renderBottomToolbar: false,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    enableRowSelection: false,
    getRowId: (row) => row.itemId?.toString(),
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["action"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: 250, minHeight: 100 },
    },
    enableStickyHeader: true,
    enableMultiRowSelection: false,
    onRowSelectionChange: setRowSelection,
    rowCount,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontWeight: "500", //conditional styling
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      isLoading,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["10", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
  });

  //#endregion
  return (
    <>
      <MantineReactTable table={table} />
    </>
  );
};

export default BorrowedGoodList;
