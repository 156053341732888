import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Flex,
  Grid,
  GridCol,
  Group,
  Menu,
  Select,
  SelectProps,
  Text,
  TextInput,
  Title,
  Tooltip,
  rem,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { modals } from "@mantine/modals";
import {
  IconCaretDown,
  IconCheck,
  IconEdit,
  IconEye,
  IconFileExport,
  IconFileLike,
  IconFileUpload,
  IconPlus,
  IconSearch,
  IconTrash,
} from "@tabler/icons-react";
import {
  MRT_ColumnDef,
  MRT_Row,
  MRT_RowSelectionState,
  MRT_SortingState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as xlsx from "xlsx";
import { repositoryPos } from "../../../_base/_const/_constVar";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { paginationBase } from "../../../_base/model/_base/BaseTable";
import {
  formatDateNotTimeZone,
  formatDateTransfer,
} from "../../../common/FormatDate/FormatDate";
import { _localization } from "../../../config/location";
import { green } from "../../../const/variables";
import { MessageResponse } from "../../../model/MessageResponse";
import { TblDmEmployee } from "../../../model/TblDmEmployee";
import { tblPromotionGetList } from "../../../model/TblPromotion";
import DeleteView from "./DeleteView";
import ModalApprovePromotion from "./ModalApprovePromotion";

function getColorStatus(text: any) {
  switch (text) {
    case "2":
      return "teal";
    case "3":
      return "yellow";
    case "4":
      return "red";
    default:
      return "blue";
  }
}

function getStatus(text: any) {
  switch (text) {
    case "2":
      return "Đã duyệt";
    case "3":
      return "Tạm dừng";
    case "4":
      return "Từ chối";
    default:
      return "Chưa duyệt";
  }
}

const PromotionPolicy = () => {
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);
  const navigate = useNavigate();
  const [selectIds, setSelectIds] = useState<string[]>([]);
  const [rowCount, setRowCount] = useState(0);
  const [pagination, setPagination] = useState(paginationBase);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState<tblPromotionGetList[]>([]);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [search, setSearch] = useState({
    keySearch: "",
    effDate: "",
    expDate: "",
    fromApproveDate: "",
    toApproveDate: "",
    approveStatus: "",
  });
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [dataEmployee, setDataEmployee] = useState<TblDmEmployee[]>([]);

  const dataStatus = React.useMemo<any[]>(
    () => [
      {
        value: "1",
        label: "Chưa duyệt",
      },
      {
        value: "2",
        label: "Đã duyệt",
      },
      {
        value: "3",
        label: "Tạm dừng",
      },
      {
        value: "4",
        label: "Từ chối",
      },
    ],
    []
  );

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "code",
        header: "Mã chính sách",
        size: 100,
        Cell: ({ renderedCellValue, row }) => (
          <Tooltip label="Xem chi tiết">
            <Badge
              radius="sm"
              variant="dot"
              size="lg"
              style={{ cursor: "pointer" }}
              color={renderedCellValue === null ? "red" : green.base}
              onDoubleClick={() => handleDetailPromotion(row.original.id)}
            >
              {renderedCellValue === null ? null : renderedCellValue}
            </Badge>
          </Tooltip>
        ),
      },
      {
        accessorKey: "approveStatus",
        header: "Trạng thái duyệt",
        size: 100,
        Cell: ({ renderedCellValue, row, cell }) => (
          <>
            <Badge color={getColorStatus(row.original.approveStatus)}>
              {getStatus(row.original.approveStatus)}
            </Badge>
          </>
          // <Text>
          //   {renderedCellValue === "2"
          //     ? "Đã duyệt"
          //     : renderedCellValue === "3"
          //     ? "Tạm dừng"
          //     : renderedCellValue === "4"
          //     ? "Từ chối"
          //     : "Chưa duyệt"}
          // </Text>
        ),
      },
      // {
      //   accessorKey: "type",
      //   header: "Chính sách khuyến mãi",
      // },
      {
        accessorKey: "createDate",
        header: "Ngày tạo",
        Cell: ({ renderedCellValue }: any) => {
          if (
            typeof renderedCellValue === "string" ||
            renderedCellValue instanceof Date
          ) {
            return <>{formatDateTransfer(renderedCellValue)}</>;
          }
          return <>{formatDateTransfer(undefined)}</>;
        },
      },
      {
        accessorKey: "createBy",
        header: "Người tạo",
        size: 100,
        enableSorting: false,
      },
      {
        accessorKey: "effDate",
        header: "Thời gian hiệu lực",
        Cell: ({ renderedCellValue }: any) => {
          if (
            typeof renderedCellValue === "string" ||
            renderedCellValue instanceof Date
          ) {
            return <>{formatDateTransfer(renderedCellValue)}</>;
          }
          return <>{formatDateTransfer(undefined)}</>;
        },
      },
      {
        accessorKey: "expDate",
        header: "Thời gian hết hạn",
        Cell: ({ renderedCellValue }: any) => {
          if (
            typeof renderedCellValue === "string" ||
            renderedCellValue instanceof Date
          ) {
            return <>{formatDateTransfer(renderedCellValue)}</>;
          }
          return <>{formatDateTransfer(undefined)}</>;
        },
      },

      {
        accessorKey: "priority",
        header: "Độ ưu tiên",
        size: 100,
      },

      {
        accessorKey: "note",
        header: "Nội dung",
        size: 100,
        Cell: ({ renderedCellValue, cell }) => (
          <Tooltip label={renderedCellValue as string}>
            <Text truncate="end" maw={300}>
              {renderedCellValue}
            </Text>
          </Tooltip>
        ),
      },
      {
        accessorKey: "approveBy",
        header: "Người duyệt",
        size: 100,
        enableSorting: false,
        Cell: ({ renderedCellValue, cell }) =>
          getEmployeeName(renderedCellValue?.toString() || ""),
      },
      {
        accessorKey: "approveDate",
        header: "Ngày duyệt",
        Cell: ({ renderedCellValue }: any) => {
          if (
            typeof renderedCellValue === "string" ||
            renderedCellValue instanceof Date
          ) {
            return <>{formatDateTransfer(renderedCellValue)}</>;
          }
          return <>{formatDateTransfer(undefined)}</>;
        },
      },
      {
        accessorKey: "approveNote",
        header: "Ghi chú duyệt",
        size: 100,
      },

      // {
      //   accessorKey: "listCustGroup",
      //   header: "Danh sách khách hàng",
      // },

      // {
      //   accessorKey: "listOtherCondition",
      //   header: "Danh sách tình trạng khác",
      // },

      // {
      //   accessorKey: "checkNomalPromote",
      //   header: "Check chính sách thường",
      // },

      // {
      //   accessorKey: "checkRewardPoint",
      //   header: "Check Điểm thưởng",
      // },

      // {
      //   accessorKey: "checkUploadWebsite",
      //   header: "Check upload web",
      // },

      {
        accessorKey: "action",
        header: "Thao tác",
        enableColumnActions: false,
        enableSorting: false,
        size: 50,
        Cell: ({ renderedCellValue, row }) => (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "4px",
            }}
          >
            {processTaskActionMenu(row)}
          </Box>
        ),
      },
    ],
    [dataEmployee]
  );

  function processTaskActionMenu(row: MRT_Row<any>): any {
    return (
      <>
        <Tooltip label="Duyệt chính sách">
          <ActionIcon
            variant="light"
            aria-label="Settings"
            color="teal"
            disabled={
              row.original.approveStatus === "2" ||
              row.original.approveStatus === "4"
            }
            onClick={async () => {
              handleApprovePromotion([row.id]);
            }}
          >
            <IconFileLike size={20} stroke={1.5} />
          </ActionIcon>
        </Tooltip>
        <Tooltip label="Sửa chính sách">
          <ActionIcon
            variant="light"
            aria-label="Settings"
            color="yellow"
            disabled={row.original.approveStatus !== "1"}
          >
            <IconEdit
              size={14}
              stroke={1.5}
              onClick={() => handleEditPromotion(row.id)}
            />
          </ActionIcon>
        </Tooltip>
        <Tooltip label="Xem chi tiết">
          <ActionIcon
            variant="light"
            aria-label="Settings"
            color="cyan"
            onClick={async () => {
              handleDetailPromotion(row.id);
            }}
          >
            <IconEye size={20} stroke={1.5} />
          </ActionIcon>
        </Tooltip>
        <Tooltip label="Xóa chính sách">
          <ActionIcon
            variant="light"
            aria-label="Settings"
            color="red"
            disabled={row.original.approveStatus === "2"}
            onClick={async () => {
              await deletesData([row.id]);
            }}
          >
            <IconTrash size={14} stroke={1.5} />
          </ActionIcon>
        </Tooltip>
      </>
    );
  }

  const getEmployeeName = (id: string) => {
    const employee = dataEmployee.find(
      (employee) => employee.id.toString() === id
    );

    return employee?.fullname;
  };

  const handleChangeSearchValue = (value: string, key: string) => {
    if (value !== "Invalid date")
      setSearch((prevData) => ({ ...prevData, [key]: value }));
    else {
      setSearch((prevData) => ({ ...prevData, [key]: "" }));
    }
  };

  const handleExport = () => {
    try {
      const worksheet = xlsx.utils.json_to_sheet(data);
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, worksheet, "Data");
      xlsx.writeFile(workbook, "chinh-sach-khuyen-mai-POS.xlsx");
      toast.success("Export excel thành công", { autoClose: 1500 });
    } catch (error) {
      toast.error("Export excel thất bại", { autoClose: 1500 });
    }
  };

  const deletesData = async (ids: string[]) => {
    if (ids.length < 1) NotificationExtension.Warn("Bạn chưa chọn dữ liệu !");
    else
      try {
        modals.openConfirmModal({
          title: (
            <>
              <Title order={5}>Xóa chính sách khuyến mãi!</Title>
            </>
          ),
          centered: true,
          size: "550px",
          children: <DeleteView idItems={ids} fetchData={fetchData} />,
          confirmProps: { display: "none" },
          cancelProps: { display: "none" },
        });
      } catch (error) {
        console.error(error);
        return;
      }
  };

  const fetchData = async () => {
    setIsLoading(true);
    setIsError(false);
    try {
      let url = `/api/v1/tblPromotion/get-list?Skip=${
        pagination.pageIndex * pagination.pageSize
      }&Take=${pagination.pageSize}`;

      if (search.keySearch) {
        url += `&KeySearch=${search.keySearch}`;
      }

      if (search.effDate) {
        url += `&EffDate=${search.effDate}`;
      }
      if (search.expDate) {
        url += `&ExpDate=${search.expDate}`;
      }

      if (search.fromApproveDate) {
        url += `&FromApproveDate=${search.fromApproveDate}`;
      }
      if (search.toApproveDate) {
        url += `&ToApproveDate=${search.toApproveDate}`;
      }

      if (search.approveStatus) {
        url += `&ApproveStatus=${search.approveStatus}`;
      }

      if (sorting && sorting.length > 0) {
        let _q = sorting[0];
        if (_q) {
          url = url + "&sort=" + _q.id + "&isOrder=" + _q.desc;
        }
      }

      const response = await repositoryPos.get<
        MessageResponse<tblPromotionGetList[]>
      >(url);

      if (response && response.success) {
        setData(response.data);
        setRowCount(response.totalCount);
        setSelectIds([]);
        table.resetRowSelection();
      }
    } catch (error) {
      setIsError(true);
      console.error(error);
      return;
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreatePP = () => {
    navigate("/config-system/create-promotion-policy");
    localStorage.removeItem("policyType");
  };

  const handleApprovePromotion = (ids: string[]) => {
    modals.openConfirmModal({
      size: "auto",
      title: (
        <>
          <Title order={5}>Duyệt chính sách khuyến mại</Title>
        </>
      ),
      children: <ModalApprovePromotion ids={ids} fetchData={fetchData} />,
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };

  const handleEditPromotion = (id: string) => {
    navigate("/config-system/edit-promotion", { state: { id: id } });
    localStorage.removeItem("policyType");
  };

  const handleDetailPromotion = (id: string) => {
    navigate("/config-system/detail-promotion", { state: { id: id } });
    localStorage.removeItem("policyType");
  };

  const optionColorsStatus: Record<
    "Chưa duyệt" | "Đã duyệt" | "Tạm dừng" | "Từ chối",
    string
  > = {
    "Chưa duyệt": "blue",
    "Đã duyệt": "teal",
    "Tạm dừng": "yellow",
    "Từ chối": "red",
  };

  const renderSelectOptionStatus: SelectProps["renderOption"] = ({
    option,
    checked,
  }) => (
    <Group flex="1" gap="xs">
      <Badge
        color={
          optionColorsStatus[
            option.label as "Chưa duyệt" | "Đã duyệt" | "Tạm dừng" | "Từ chối"
          ]
        }
        radius="sm"
        variant="filled"
      >
        {option.label}
      </Badge>
      {checked && (
        <IconCheck size={"14px"} style={{ marginInlineStart: "auto" }} />
      )}
    </Group>
  );

  useEffect(() => {
    const fetchDataEmployee = async () => {
      try {
        let url = `/api/v1/tblDmEmployee/get-all`;

        const response = await repositoryPos.get<
          MessageResponse<TblDmEmployee[]>
        >(url);

        if (response && response.success) {
          setDataEmployee(response.data);
        }
      } catch (error) {
        return;
      }
    };

    fetchDataEmployee();

    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      setHeight(window.innerHeight - (190 + headerHeight));
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);

  useEffect(() => {
    fetchData();
  }, [pagination, sorting]);

  const table = useMantineReactTable({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    renderTopToolbarCustomActions: ({ table }) => (
      <div ref={headerRef} style={{ width: "70%" }}>
        <_breadcrumb></_breadcrumb>
        <Grid>
          <GridCol span={{ base: 6, sm: 4, md: 2 }}>
            <TextInput
              flex={1}
              placeholder="Từ khoá"
              onChange={(e) => {
                handleChangeSearchValue(
                  e.currentTarget.value ?? "",
                  "keySearch"
                );
              }}
            />
          </GridCol>

          <GridCol span={{ base: 6, sm: 4, md: 2 }}>
            <Select
              flex={1}
              placeholder="Trạng thái duyệt"
              clearable
              data={dataStatus}
              comboboxProps={{
                transitionProps: { transition: "pop", duration: 200 },
              }}
              renderOption={renderSelectOptionStatus}
              onChange={(e) => {
                handleChangeSearchValue(e ?? "", "approveStatus");
              }}
            />
          </GridCol>

          <GridCol span={{ base: 12, sm: 8, md: 3 }}>
            <DatePickerInput
              // type="multiple"
              type="range"
              size="sm"
              placeholder="Thời gian hiệu lực từ ngày - đến ngày"
              locale="vi"
              // value={value}
              valueFormat="DD/MM/YYYY"
              onChange={(e) => {
                handleChangeSearchValue(
                  formatDateNotTimeZone(e[0]) ?? "",
                  "effDate"
                );
                handleChangeSearchValue(
                  formatDateNotTimeZone(e[1]) ?? "",
                  "expDate"
                );
              }}
              clearable
            />
          </GridCol>

          <GridCol span={{ base: 12, sm: 8, md: 3 }}>
            <DatePickerInput
              // type="multiple"
              type="range"
              size="sm"
              placeholder="Duyệt từ ngày - đến ngày"
              locale="vi"
              // value={value}
              valueFormat="DD/MM/YYYY"
              onChange={(e) => {
                handleChangeSearchValue(
                  formatDateNotTimeZone(e[0]) ?? "",
                  "fromApproveDate"
                );
                handleChangeSearchValue(
                  formatDateNotTimeZone(e[1]) ?? "",
                  "toApproveDate"
                );
              }}
              clearable
            />
          </GridCol>

          <GridCol span={{ base: 6, sm: 4, md: 2 }}>
            <Button
              leftSection={<IconSearch size={14} />}
              color="blue"
              variant="outline"
              onClick={async () => {
                await fetchData();
              }}
            >
              Tìm kiếm
            </Button>
          </GridCol>
        </Grid>
      </div>
    ),
    renderToolbarInternalActions: ({ table }) => (
      <>
        <Flex
          gap="md"
          justify={"flex-end"}
          align="center"
          direction="row"
          wrap="wrap"
        >
          <Button
            leftSection={<IconPlus size={14} />}
            color="blue"
            variant="outline"
            onClick={handleCreatePP}
          >
            Thêm mới (F11)
          </Button>

          <Button
            leftSection={<IconTrash size={14} />}
            color="red"
            variant="outline"
            onClick={async () => {
              await deletesData(selectIds);
            }}
            disabled={selectIds.length < 1}
          >
            Xóa (đã chọn)
          </Button>

          <Menu shadow="md" width={200}>
            <Menu.Target>
              <Button
                rightSection={
                  <IconCaretDown style={{ width: rem(14), height: rem(14) }} />
                }
              >
                Chức năng
              </Button>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Label>Application</Menu.Label>
              <Menu.Item
                leftSection={
                  <IconFileExport style={{ width: rem(14), height: rem(14) }} />
                }
                onClick={handleExport}
              >
                Export Excel
              </Menu.Item>
              <Tooltip label={"Chức năng đang update"}>
                <Menu.Item
                  leftSection={
                    <IconFileUpload
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                >
                  Import Excel
                </Menu.Item>
              </Tooltip>
            </Menu.Dropdown>
          </Menu>
        </Flex>
      </>
    ),
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    enableRowSelection: true,
    getRowId: (row) => row?.id?.toString(),
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select", "code", "approveStatus"],
        right: ["applicableObject", "action"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    rowCount,
    // onColumnFiltersChange: setColumnFilters,
    // onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        // fontWeight: "bold",
        fontSize: "11.5px",
        padding: "4px 12px",
      },
    }),
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      // showProgressBars: isRefetching,
      // showSkeletons: isLoading, //loading for the first time with no data
      sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: false
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["10", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });

  return (
    <>
      <div className="mt-5">
        <MantineReactTable table={table} />
      </div>
    </>
  );
};

export default PromotionPolicy;
