import {
  Box,
  Button,
  Checkbox,
  Grid,
  Group,
  LoadingOverlay,
  Select,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { repositoryPos } from "../../_base/_const/_constVar";
import { NotificationExtension } from "../../_base/extension/NotificationExtension";
import { MessageResponse } from "../../model/MessageResponse";
import { useEffect, useState } from "react";

type Department = {
  departmentId: number;
  name: string | null;
  description: string | null;
  active: boolean | null;
  deparmentCode: string | null;
  creationDate: Date | null;
  createdBy: string | null;
  lastUpdateDate: Date | null;
  lastUpdatedBy: string | null;
  address: string | null;
  branchId: string | null;
};
type DataItem = {
  att1: string | null;
  att2: string | null;
  att3: number;
  att4: number;
  att5: string | null;
  disabled: boolean;
  group: string | null;
  selected: boolean;
  text: string;
  value: string;
};
type CreateDataViewProps = {
  onClose: any;
};

const CreateDepartmen = ({ onClose }: CreateDataViewProps) => {
  const entity: Department = {
    departmentId: 0,
    name: null,
    description: null,
    active: null,
    deparmentCode: null,
    creationDate: null,
    createdBy: null,
    lastUpdateDate: null,
    lastUpdatedBy: null,
    address: null,
    branchId: null,
  };

  const [visible, { toggle, close, open }] = useDisclosure(false);
  const [dataDepartmen, setdataDepartmen] = useState<
    { value: string; label: string }[]
  >([]);
  const form = useForm<Department>({
    mode: "uncontrolled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },
    validate: {
      deparmentCode: (value) => (value ? null : "Mã bộ phận là bắt buộc."),
      name: (value) => (value ? null : "Tên bộ phận là bắt buộc."),
      address: (value) => (value ? null : "Địa chỉ là bắt buộc."),
      description: (value) => (value ? null : "Mô tả là bắt buộc."),
      branchId: (value) =>
        value && !isNaN(Number(value))
          ? null
          : "Branch ID là bắt buộc và phải là số.",
    },
  });

  const handleCreateTblDMSaleChannel = async (dataSubmit: Department) => {
    open();
    const dataApi = await repositoryPos.post<MessageResponse<Department>>(
      "/api/v1/TblDepartment/create",
      dataSubmit
    );
    if (dataApi?.success) {
      onClose((prev: any) => !prev);
      modals.closeAll();
      NotificationExtension.Success("Thêm thành công !");
    }
    close();
  };

  const DataDepartmen = async () => {
    try {
      const response = await repositoryPos.get(
        `api/v1/TblDmInventory/get-select-branch`
      );
      if (response && response.success) {
        const data = response.data;
        console.log(data);
        const formattedData = data.map((item: DataItem) => ({
          value: item.value,
          label: item.text,
        }));
        setdataDepartmen(formattedData);
      }
    } catch (error) {
      console.error(error);
      return;
    }
  };
  useEffect(() => {
    DataDepartmen();
  }, []);
  return (
    <Box
      component="form"
      onSubmit={form.onSubmit((e: Department) => {
        handleCreateTblDMSaleChannel(e);
      })}
      style={{ position: "relative", width: "350px", height: "auto" }}
    >
      <LoadingOverlay
        visible={visible}
        zIndex={1000}
        overlayProps={{ radius: "sm", blur: 2 }}
      />

      <Grid mt={10}>
        <Grid.Col span={12}>
          <TextInput
            label={"Mã bộ phận"}
            placeholder={"Mã bộ phận"}
            type="text"
            withAsterisk
            {...form.getInputProps("deparmentCode")}
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <TextInput
            label="Tên bộ phận"
            placeholder="Tên bộ phận"
            withAsterisk
            {...form.getInputProps("name")}
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <TextInput
            label={"Địa chỉ"}
            placeholder={"Địa chỉ"}
            type="text"
            withAsterisk
            {...form.getInputProps("address")}
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <TextInput
            label={"Mô tả"}
            placeholder={"Mô tả"}
            type="text"
            withAsterisk
            {...form.getInputProps("description")}
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <Select
            label={"branch Id"}
            placeholder={"branch Id"}
            withAsterisk
            data={dataDepartmen}
            {...form.getInputProps("branchId")}
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <Checkbox
            label="Hoạt động"
            {...form.getInputProps("active", { type: "checkbox" })}
          />
        </Grid.Col>
      </Grid>

      <Group
        justify="end"
        mt="xs"
        style={{
          position: "sticky",
          bottom: 0,
          backgroundColor: "white",
        }}
      >
        <Button
          type="button"
          color="gray"
          loading={visible}
          onClick={() => {
            modals.closeAll();
          }}
        >
          Đóng
        </Button>
        <Button type="submit" loading={visible}>
          Lưu
        </Button>
      </Group>
    </Box>
  );
};

export default CreateDepartmen;
