import {
  Box,
  Button,
  ComboboxItem,
  Grid,
  Group,
  LoadingOverlay,
  Select,
  Textarea,
  TextInput,
} from "@mantine/core";
import { DateTimePicker } from "@mantine/dates";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { IconArrowLeft, IconCheck } from "@tabler/icons-react";
import { sky_blue } from "../../../const/variables";
import { hasLength, useForm } from "@mantine/form";
import { AssignmentWarrantyCompany } from "../../../model/AssignWarrantyCompany";
import { getTblDMBrandSelect } from "../../../service/getSelectApi";
import { useEffect, useState } from "react";
import { repositoryMdm, repositoryPos } from "../../../_base/_const/_constVar";
import { MessageResponse } from "../../../model/MessageResponse";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { handleKeyDown } from "../../../_base/helper/FunctionHelper";

const AssignWarrantyCompany = ({
  id,
  detailId,
  onClose,
  branchIdProp,
  orderCode,
}: {
  id: any;
  detailId: any;
  onClose: any;
  branchIdProp: any;
  orderCode: any;
}) => {
  const entity = {
    id: id,
    requestFormId: detailId,
    empCallId: null,
    brandId: null,
    empBrand: null,
    phoneBrand: null,
    addressBrand: null,
    noteBrand: null,
    dateCall: null,
    scheduleTime: null,
    deadline: null,
    status: false,
    po: orderCode,
  };

  console.log(detailId);
  console.log(id);

  const form = useForm<AssignmentWarrantyCompany>({
    mode: "uncontrolled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },

    transformValues: (values) => ({
      ...values,
      po: Number(values.po),
      brandId: Number(values.brandId),
    }),

    validate: {
      deadline: (value, values) =>
        !value
          ? "Vui lòng nhập hạn xử lý !"
          : new Date(value) < new Date(values.dateCall ?? "") ||
            new Date(value) < new Date(values.scheduleTime ?? "")
          ? "Hạn xử lý không được trước thời gian liên hệ và ngày hẹn !"
          : null,
      po: (value: number | null) => {
        if (!value) {
          return "Vui lòng nhập số chứng từ !";
        }
      },
      empCallId: (value: number | null) => {
        if (!value) {
          return "Vui lòng nhập nhân viên liên hệ !";
        }
      },
      dateCall: (value, values) =>
        !value
          ? "Vui lòng nhập thời gian liên hệ"
          : new Date(value) > new Date(values.deadline ?? "") ||
            new Date(value) > new Date(values.scheduleTime ?? "")
          ? "Thời gian liên hệ không được sau sau ngày hẹn và hạn xử lý !"
          : null,
      scheduleTime: (value, values) =>
        !value
          ? "Vui lòng chọn ngày hẹn gặp !"
          : new Date(value) > new Date(values.deadline ?? "") ||
            new Date(value) < new Date(values.dateCall ?? "")
          ? "Ngày hẹn không được sau hạn xử lý và trước thời gian liên hệ !"
          : null,
      brandId: (value: number | null) => {
        if (!value) {
          return "Vui lòng nhập tên hãng !";
        }
      },
      empBrand: (value: string | null) => {
        if (!value) {
          return "Vui lòng nhập tên nhân viên hãng !";
        }
      },
      phoneBrand: (value: string | null) => {
        if (value) {
          return hasLength(
            { min: 8, max: 10 },
            "Số điện thoại chưa đúng định dạng !"
          )(value as string);
        }
      },
      addressBrand: (value: string | null) => {
        if (!value) {
          return "Vui lòng nhập địa chỉ hãng !";
        }
      },
      noteBrand: (value: string | null) => {
        if (value) {
          return hasLength(
            { max: 250 },
            "Ghi chú không được vượt quá 250 kí tự !"
          )(value as string);
        }
      },
    },
  });

  const [dataEmployeeSelect, setDataEmployeeSelect] = useState<ComboboxItem[]>(
    []
  );
  const [dataBrandSelect, setDataBrandSelect] = useState<ComboboxItem[]>([]);

  const [visible, { close, open }] = useDisclosure(false);

  // const getUpdateWarrantyBrand = async () => {
  //   open();
  //   const res = await repositoryMdm.get<MessageResponse<any>>(
  //     `/api/v1/TblWarrantyServiceRequestForm/get-list-by-detail-ids?id=${id}`
  //   );

  //   if (res) {
  //     const dataApi = res?.data;
  //     if (dataApi != null) {
  //       form.setValues((prev) => ({
  //         ...prev,
  //         empCallId: Number(dataApi[0].empCallId),
  //         brandId: Number(dataApi[0].brandId),
  //         empBrand: dataApi[0].empBrand?.toString(),
  //         phoneBrand: dataApi[0].phoneBrand?.toString(),
  //         addressBrand: dataApi[0].addressBrand?.toString(),
  //         noteBrand: dataApi[0].noteBrand?.toString(),
  //         dateCall: dataApi[0].dateCall?.toString(),
  //         scheduleTime: dataApi[0].scheduleTime?.toString(),
  //         deadline: dataApi[0].deadline?.toString(),
  //         status: false,
  //         po: Number(dataApi[0].poNumber),
  //       }));
  //     }
  //     close();
  //   } else {
  //     modals.closeAll();
  //   }
  // };

  const handleUpdateWarrantyBrand = async (
    dataSubmit: AssignmentWarrantyCompany
  ) => {
    open();
    const dataApi = await repositoryMdm.post<
      MessageResponse<AssignmentWarrantyCompany>
    >(
      "/api/v1/TblWarrantyServiceRequestForm/update-employee-brand",
      dataSubmit
    );
    if (dataApi?.success) {
      modals.closeAll();
      NotificationExtension.Success("Phân công thành công !");
      onClose((prev: any) => !prev);
    }
    close();
  };

  const getDataEmployeeForDep = async () => {
    const res = await repositoryPos.get<MessageResponse<any>>(
      `/api/v1/TblDmEmployee/get-list?BranchId=${branchIdProp}&Take=200`
    );

    if (res && res?.success) {
      setDataEmployeeSelect(
        res.data
          ?.filter(
            (item: any) =>
              item.id != null &&
              item.fullname != null &&
              item.roleName.toLowerCase().includes("bảo hành")
          )
          ?.map((item: any) => ({
            value: item.id?.toString(),
            label: item.fullname,
          }))
      );
    }
  };

  const fetchDataBrand = async () => {
    const get = await getTblDMBrandSelect();
    setDataBrandSelect(
      get
        .filter((item) => item.value != null && item.text != null)
        .map((item) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  useEffect(() => {
    getDataEmployeeForDep();
    fetchDataBrand();
  }, []);

  // useEffect(() => {
  //   if (id) {
  //     getUpdateWarrantyBrand();
  //   }
  // }, [id]);

  return (
    <Box
      component="form"
      mx="auto"
      style={{ position: "relative" }}
      maw={750}
      w={"50vw"}
      onSubmit={form.onSubmit((e: AssignmentWarrantyCompany) => {
        handleUpdateWarrantyBrand(e);
      })}
    >
      <LoadingOverlay
        visible={visible}
        zIndex={1000}
        overlayProps={{ radius: "sm", blur: 2 }}
      />
      <Grid mt={10}>
        <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
          <DateTimePicker
            label="Hạn xử lý"
            placeholder="Chọn hạn xử lý"
            value={
              form.getValues().deadline
                ? new Date(form.getValues().deadline ?? "")
                : undefined
            }
            valueFormat="DD/MM/YYYY HH:mm A"
            locale="vi"
            clearable
            {...form.getInputProps("deadline")}
            onChange={(e) =>
              form.setValues((prev) => ({
                ...prev,
                deadline: e ? new Date(e ?? "").toLocaleString() : null,
              }))
            }
            withAsterisk
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
          <TextInput
            label="Số chứng từ"
            placeholder="Nhập số chứng từ"
            variant="filled"
            readOnly
            withAsterisk
            {...form.getInputProps("po")}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
          <Select
            label="Nhân viên liên hệ"
            placeholder="Nhập tên nhân viên liên hệ"
            data={dataEmployeeSelect}
            comboboxProps={{ width: 200 }}
            searchable
            nothingFoundMessage="Không tìm thấy dữ liệu !"
            withAsterisk
            {...form.getInputProps("empCallId")}
            value={form.getValues().empCallId?.toString() ?? ""}
            onChange={(e) =>
              form.setValues((prev) => ({ ...prev, empCallId: Number(e) }))
            }
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
          <DateTimePicker
            label="Thời gian liên hệ"
            placeholder="Thời gian liên hệ"
            valueFormat="DD/MM/YYYY HH:mm A"
            value={
              form.getValues().dateCall
                ? new Date(form.getValues().dateCall ?? "")
                : null
            }
            locale="vi"
            clearable
            withAsterisk
            {...form.getInputProps("dateCall")}
            onChange={(e) =>
              form.setValues((prev) => ({
                ...prev,
                dateCall: e ? new Date(e ?? "").toLocaleString() : null,
              }))
            }
          />
        </Grid.Col>
      </Grid>
      <Grid>
        <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
          <DateTimePicker
            label="Ngày hẹn"
            placeholder="Chọn ngày hẹn"
            valueFormat="DD/MM/YYYY HH:mm A"
            locale="vi"
            minDate={new Date()}
            value={
              form.getValues().scheduleTime
                ? new Date(form.getValues().scheduleTime ?? "")
                : undefined
            }
            clearable
            withAsterisk
            {...form.getInputProps("scheduleTime")}
            onChange={(e) =>
              form.setValues((prev) => ({
                ...prev,
                scheduleTime: e ? new Date(e ?? "").toLocaleString() : null,
              }))
            }
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
          <Select
            label="Hãng"
            placeholder="Nhập tên hãng"
            data={dataBrandSelect}
            value={form.getValues().brandId?.toString() ?? ""}
            searchable
            nothingFoundMessage="Không tìm thấy dữ liệu !"
            withAsterisk
            onChange={(e) =>
              form.setValues((prev) => ({ ...prev, brandId: Number(e) }))
            }
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
          <TextInput
            label="Nhân viên hãng nhận"
            placeholder="Nhập tên nhân viên hãng"
            withAsterisk
            {...form.getInputProps("empBrand")}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
          <TextInput
            label="SĐT hãng"
            placeholder="Nhập SĐT hãng"
            onKeyDown={handleKeyDown}
            type="number"
            {...form.getInputProps("phoneBrand")}
          />
        </Grid.Col>
      </Grid>
      <Grid mt={8}>
        <Grid.Col span={12}>
          <TextInput
            label="Địa chỉ hãng"
            placeholder="Nhập địa chỉ hãng"
            {...form.getInputProps("addressBrand")}
            withAsterisk
          />
        </Grid.Col>
      </Grid>
      <Grid mt={8}>
        <Grid.Col span={12}>
          <Textarea
            label="Ghi chú"
            placeholder="Nhập ghi chú"
            {...form.getInputProps("noteBrand")}
          />
        </Grid.Col>
      </Grid>
      <Group
        justify="end"
        mt="xs"
        style={{
          position: "sticky",
          bottom: 0,
          backgroundColor: "white",
        }}
      >
        <Button
          type="button"
          color="gray"
          loading={visible}
          onClick={() => {
            modals.closeAll();
          }}
          leftSection={!visible ? <IconArrowLeft size={18} /> : undefined}
        >
          Quay lại
        </Button>
        <Button
          type="submit"
          color={sky_blue.base}
          loading={visible}
          leftSection={!visible ? <IconCheck size={18} /> : undefined}
        >
          Lưu
        </Button>
      </Group>
    </Box>
  );
};

export default AssignWarrantyCompany;
