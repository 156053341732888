import {
  Box,
  Checkbox,
  Grid,
  LoadingOverlay,
  Text,
  Textarea,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { useEffect } from "react";
import { MessageResponse } from "../../../../model/MessageResponse";
import { TblDMInventoryGroup } from "../../../../model/TblDMInventoryGroup";
import { repositoryMdm } from "../../../../_base/_const/_constVar";

const DetailDataView = ({ id }: DetailDataViewProps) => {
  const entity: TblDMInventoryGroup = {
    id: 0,
    maKho: null,
    chucNangBan: false,
    createBy: 0,
    createDate: null,
    dienGiai: null,
    dieuChuyenLienchinhanh: false,
    dieuChuyenNoibo: false,
    lastUpdateBy: null,
    lastUpdateDate: null,
    loaiKho: null,
    nguoiDieuChuyenLienchinhanh: null,
    nguoiDieuChuyenNoibo: null,
    nguoiQuanLy: null,
  };

  const [visible, { close, open }] = useDisclosure(false);

  const form = useForm<TblDMInventoryGroup>({
    mode: "uncontrolled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },
  });

  const callApiGetData = async () => {
    open();
    const callapi = await repositoryMdm.get<
      MessageResponse<TblDMInventoryGroup>
    >(`/api/v1/TblDmInventoryGroup/get-detail?id=${id}`);
    if (callapi) {
      const dataApi = callapi?.data;
      form.setValues({ ...dataApi });
      form.resetDirty(dataApi);
      close();
    } else {
      modals.closeAll();
    }
  };

  useEffect(() => {
    if (id) {
      callApiGetData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);


  return (
    <>
      <Box
        component="form"
        mx="auto"
        w={{ md: "400px", lg: "480px" }}
        style={{ position: "relative" }}
      >
        <LoadingOverlay
          visible={visible}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />

        <Grid mt={10}>
          <Grid.Col span={4}>
            <TextInput
              readOnly
              label={"Mã kho"}
              placeholder={"Nhập mã kho"}
              type="text"
              withAsterisk
              {...form.getInputProps("maKho")}
            />
          </Grid.Col>
          <Grid.Col span={8}>
            <TextInput
              readOnly
              label={"Loại kho"}
              placeholder={"Nhập loại kho"}
              type="text"
              withAsterisk
              w={"100%"}
              {...form.getInputProps("loaiKho")}
            />
          </Grid.Col>
        </Grid>

        <Grid>
          <Grid.Col>
            <Textarea
              readOnly
              label={"Diễn giải"}
              placeholder={"Nhập diễn giải"}
              autosize
              withAsterisk
              {...form.getInputProps("dienGiai")}
            />
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col>
            <Checkbox
              readOnly
              checked={form.getValues().chucNangBan ? true : false}
              label={"Chức năng bán"}
              onChange={() => {}}
              mt={16}
            />
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col>
            <TextInput
              readOnly
              label={"Người quản lý"}
              placeholder={"Nhập người quản lý"}
              type="text"
              withAsterisk
              {...form.getInputProps("nguoiQuanLy")}
            />
          </Grid.Col>
        </Grid>
        <Grid mt={8}>
          <Grid.Col>
            <Text size="md" fw="bold" ta="center">
              Điều chuyển liên chi nhánh
            </Text>
          </Grid.Col>
          <Grid.Col>
            <Checkbox
              readOnly
              checked={form.getValues().dieuChuyenLienchinhanh ? true : false}
              label={"Điều chuyển"}
              onChange={() => {}}
            />
          </Grid.Col>
          <Grid.Col>
            <TextInput
              readOnly
              label={"Người điều chuyển"}
              placeholder={"Nhập người điều chuyển"}
              type="text"
              {...form.getInputProps("nguoiDieuChuyenLienchinhanh")}
            />
          </Grid.Col>
        </Grid>
        <Grid mt={8}>
          <Grid.Col>
            <Text size="md" fw="bold" ta="center">
              Điều chuyển nội bộ
            </Text>
          </Grid.Col>
          <Grid.Col>
            <Checkbox
              readOnly
              checked={form.getValues().dieuChuyenNoibo ? true : false}
              label={"Điều chuyển"}
              onChange={() => {}}
            />
          </Grid.Col>
          <Grid.Col>
            <TextInput
              readOnly
              label={"Người điều chuyển"}
              placeholder={"Nhập người điều chuyển"}
              type="text"
              {...form.getInputProps("nguoiDieuChuyenNoibo")}
            />
          </Grid.Col>
        </Grid>
      </Box>
    </>
  );
};

export default DetailDataView;

type DetailDataViewProps = {
  id: string | number;
};
