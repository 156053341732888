import {
  Box,
  Checkbox,
  Group,
  LoadingOverlay,
  TextInput,
  Textarea,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { useEffect } from "react";
import { repositoryPos } from "../../../../_base/_const/_constVar";
import { NotificationExtension } from "../../../../_base/extension/NotificationExtension";
import { MessageResponse } from "../../../../model/MessageResponse";
import { TblDMInventory } from "../../../../model/TblDMInventory";

const DetailDataView = ({ id }: DetailDataViewProps) => {
  const entity = {
    id: "0",
    idBranch: 0,
    code: "",
    name: "",
    addr: "",
    type: null,
    phone: null,
    fax: null,
    note: null,
    active: false,
    closeBookDate: null,
    isPrintInvoice: null,
    isPrintReceve: null,
    synDate: null,
    oldInventoryCode: null,
    codeoracle: null,
    positionId: null,
    createBy: null,
    createDate: null,
    lastUpdateBy: null,
    lastUpdateDate: null,
    openBookDate: null,
  };

  const [visible, { toggle, close, open }] = useDisclosure(false);

  const form = useForm<TblDMInventory>({
    mode: "controlled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },
  });

  const callApiGetData = async () => {
    open();
    const callapi = await repositoryPos.post<MessageResponse<TblDMInventory>>(
      `/api/v1/TblDMInventory/detail?id=${id}`
    );
    if (callapi) {
      const dataApi = callapi?.data;
      if (dataApi != null) {
        form.setValues(dataApi);
        form.resetDirty(dataApi);
      }
      close();
    } else {
      modals.closeAll();
    }
  };

  useEffect(() => {
    if (id) {
      callApiGetData();
    }
  }, [id]);

  return (
    <>
      <Box
        component="form"
        mx="auto"
        w={{ base: "250px", md: "300px", lg: "400px" }}
        style={{ position: "relative" }}
      >
        <LoadingOverlay
          visible={visible}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />

        <Group wrap="nowrap" mt={10} gap={"lg"}>
          <TextInput
            readOnly
            label={"Mã kho"}
            variant="filled"
            {...form.getInputProps("code")}
          />
          <TextInput
            readOnly
            label={"Tên kho"}
            variant="filled"
            w={"100%"}
            {...form.getInputProps("name")}
          />
        </Group>

        <Group grow wrap="nowrap" mt={10} gap={"lg"}>
          <TextInput
            readOnly
            label={"Địa chỉ"}
            variant="filled"
            {...form.getInputProps("addr")}
          />
        </Group>

        <Group grow wrap="nowrap" mt={10} gap={"lg"}>
          <TextInput
            readOnly
            label={"Điện thoại"}
            type="number"
            variant="filled"
            {...form.getInputProps("phone")}
          />
          <TextInput
            readOnly
            label={"Fax"}
            type="number"
            variant="filled"
            {...form.getInputProps("fax")}
          />
        </Group>

        <Group grow wrap="nowrap" mt={10} gap={"lg"}>
          <TextInput
            readOnly
            label={"Mã vùng"}
            variant="filled"
            {...form.getInputProps("positionId")}
          />
          <TextInput
            readOnly
            label={"Trung tâm khác"}
            variant="filled"
            {...form.getInputProps("oldInventoryCode")}
          />
        </Group>

        <Group grow wrap="nowrap" mt={10} gap={"lg"} align="center">
          <TextInput
            readOnly
            label={"Mã kho ORC"}
            variant="filled"
            {...form.getInputProps("codeoracle")}
          />
          <TextInput
            readOnly
            label={"Tên loại kho"}
            type="number"
            variant="filled"
            {...form.getInputProps("type")}
          />
        </Group>
        <Group grow wrap="nowrap" mt={10} gap={"lg"} align="center">
          <Textarea
            readOnly
            label={"Ghi chú"}
            w={"250px"}
            variant="filled"
            {...form.getInputProps("note")}
          />
        </Group>
        <Group grow wrap="nowrap" gap={"lg"} align="center" mt={"xs"}>
          <Checkbox
            label={"Sử dụng"}
            readOnly
            checked={form.values.active}
            {...form.getInputProps("active")}
            onChange={() => {}}
          />
        </Group>
      </Box>
    </>
  );
};

export default DetailDataView;

type DetailDataViewProps = {
  id: string | number;
};
