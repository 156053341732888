import { createBrowserRouter } from "react-router-dom";
import { PageNotFound } from "../../_base/component/_layout/_404";
import { Layout1 } from "../../_base/component/_layout/_layout1";
import { LayoutAuth } from "../../_base/component/_layout/_layoutAuth";
import LayoutLogin from "../../_base/component/_login/_LayoutLogin";
import { SelectListItem } from "../../model/SelectListItem";
import AppView from "../../views/Auth/AppView";
import AuthListRoleView from "../../views/Auth/AuthListRoleView";
import GroupsRoleView from "../../views/Auth/GroupsRoleView";
import LoggingView from "../../views/Auth/LoggingView";
import TopicErrorView from "../../views/Auth/TopicErrorView";
import UserListView from "../../views/Auth/UserListView";
import Commodities from "../../views/Category/Commodities/Commodities";
import GeneralData from "../../views/Category/GeneralData/GeneralData";
import Payment from "../../views/Category/Payment/Payment";
import Transport from "../../views/Category/Transport/Transport";
import ListDetailsPrice from "../../views/ConfigSystem/PriceList/ListDetailsPrice";
import ListPrice from "../../views/ConfigSystem/PriceList/ListPrice";
import CreatePromotionPolicy from "../../views/ConfigSystem/PromotionPolicy/CreatePromotionPolicy/CreatePromotionPolicy";
import EditPromotion from "../../views/ConfigSystem/PromotionPolicy/EditPromotion/EditPromotion";
import PromotionPolicy from "../../views/ConfigSystem/PromotionPolicy/PromotionPolicy";
import ViewDetailPromotion from "../../views/ConfigSystem/PromotionPolicy/ViewPromotion/ViewPromotion";
import Delivery from "../../views/DeliveryConfig/Delivery";
import DeliveryAssignment from "../../views/DeliveryConfig/DeliveryAssignment";
import DeliveryDispatch from "../../views/DeliveryConfig/DeliveryDispatch";
import HomeView from "../../views/home/HomeView";
import DepartmentView from "../../views/Organization/DepartmentView";
import EmployeeView from "../../views/Organization/EmployeeView";
import RoleView from "../../views/Organization/RoleView";
import CreateRequestTransfer from "../../views/RequestTransfer/CreateRequestTransfer";
import EditRequestTransfer from "../../views/RequestTransfer/EditRequestTransfer";
import RequestTransfer from "../../views/RequestTransfer/WareHouseTransfer/RequestTransfer";
import DmCustomer from "../../views/Sell/Customer/TblDMCustomer";
import { PaymentReceipt } from "../../views/Sell/PaymentReceipt/PaymentReceipt";
import RetailOrder from "../../views/Sell/RetailOrder";
import SalesOrderView from "../../views/Sell/SalesOrderList";
import PaymentForm from "../../views/Sell/ScreenPayment";
import TypeContractTable from "../../views/System/TypeContractTable";
import RequestInventoryTransaction from "../../views/Warehouse/RequestInventoryTransaction/RequestInventoryTransaction";
import EditWarehouseInWard from "../../views/Warehouse/WarehouseInWard/EditWarehouseInWard";
import WarehouseInWard from "../../views/Warehouse/WarehouseInWard/WarehouseInWard";
import InventoryCycleCount from "../../views/Warehouse/WarehouseManagement/InventoryCycleCount/InventoryCycleCount";
import WarehouseManagement from "../../views/Warehouse/WarehouseManagement/WarehouseManagement";
import CreateWarehouseOutWard from "../../views/Warehouse/WarehouseOutWard/CreateWarehouseOutWard";
import EditWarehouseOutWard from "../../views/Warehouse/WarehouseOutWard/EditWarehouseOutWard";
import WarehouseOutWard from "../../views/Warehouse/WarehouseOutWard/WarehouseOutWard";
import ReceptionList from "../../views/Warranty/ReceiveWarranty/ReceptionList";
import SupplierWarrantyExport from "../../views/Warranty/SupplierWarrantyExport/SupplierWarrantyExport";
// import SaleReviseOrder from "../../views/Sell/SaleReviseOrder/SaleReviseOrder";
import DetailRequestTransfer from "../../views/RequestTransfer/DetailRequestTransfer";

import CustomerList from "../../views/CustomerManagement/CustomerList/CustomerList";
import { ExchangeRate } from "../../views/Sell/exchangeRate/exchangeRate";
import TestingTechnique from "../../views/TestingTechnique";
import WarehouseTransaction from "../../views/Warehouse/WarehouseTransaction/WarehouseTransaction";
import ProcessingAssignment from "../../views/Warranty/RequestProcessing/ProcessingAssignment";
import WarrantyCompany from "../../views/Warranty/WarrantyCompany/WarrantyCompany";
// import ReducedApproval from "../../views/ReducedApproval/ReducedApproval";
import GroupUserView from "../../views/Auth/GroupUserView";
import { HistoryAndApprove } from "../../views/Auth/HistoryAndApprove/HistoryAndApprove";
import ListOnlineView from "../../views/Auth/ListOnlineView";
import UserPageView from "../../views/Auth/UserPageView";
import CreateMfgBom from "../../views/ConfigSystem/MfgBom/CreateMfgBom";
import DetailMfgBom from "../../views/ConfigSystem/MfgBom/DetailMfgBom";
import EditMfgBom from "../../views/ConfigSystem/MfgBom/EditMfgBom";
import MfgBomView from "../../views/ConfigSystem/MfgBom/MfgBom";
import DebtGroupView from "../../views/DebtGroup/DebtGroupView";
import DeliveryPay from "../../views/DeliveryConfig/DeliveryPay/DeliveryPay";
import DepartmentsList from "../../views/Departments/DepartmentsList";
import Invoice from "../../views/Invoice/DMInvoice";
import MenuView from "../../views/Menu/MenuView";
import MenuGroupListView from "../../views/MenuGroup/MenuGroupListView";
import { ApproveProposalView } from "../../views/Proposal/ApproveProposal/ApproveProposalView";
import { CreateProposalView } from "../../views/Proposal/CreateProposal/CreateProposalView";
import { DetailProposalView } from "../../views/Proposal/DetailProposal/DetailProposalView";
import { EditProposalView } from "../../views/Proposal/EditProposal/EditProposalView";
import { ProposalView } from "../../views/Proposal/ProposalView";
import ReducedApproval from "../../views/ReducedApproval/ReducedApproval";
import { AddOrUpdateFileReportView } from "../../views/Report/AddOrUpdateFileReportView";
import { GridExample } from "../../views/Report/ExampleView";
import { ReportInventorySerialOnhandView } from "../../views/Report/ReportInventorySerialOnhandView";
import { ReportPaymentReceiptView } from "../../views/Report/ReportPaymentReceiptView";
import { ReportSalesListView } from "../../views/Report/ReportSalesListView";
import AddAdjustmentSlip from "../../views/Sell/AddAdjustmentSlip/AddAdjustmentSlip";
import AdjustmentSlip from "../../views/Sell/AdjustmentSlip/AdjustmentSlip";
import CreditAdvice from "../../views/Sell/CreditAdvice/CreditAdvice";
import DebtNote from "../../views/Sell/DebtNote/DebtNote";
import DetailListReceipts from "../../views/Sell/ListReceipts/DetailListReceipts";
import ListPaymentSlip from "../../views/Sell/ListReceipts/ListPaymentSlip";
import ListReceipts from "../../views/Sell/ListReceipts/ListReceipts";
import InternalReceipt from "../../views/Sell/PaymentReceipt/InternalReceipt/InternalReceipt";
import PaymentSlip from "../../views/Sell/PaymentSlip/PaymentSlip";
import ReturnOrder from "../../views/Sell/ReturnOrder/ReturnOrder";
import ReceptionTestingTechnique from "../../views/TestingTechnique/ReceptionTestingTechnique";
import BarcodeHistory from "../../views/Warehouse/BarcodeHistory/BarcodeHistory";
import { BarcodeStatus } from "../../views/Warehouse/BarcodeStatus/BarcodeStatus";
import CategoryCombination from "../../views/Warehouse/CategoryCombination/CategoryCombination";
import ExportAdjustment from "../../views/Warehouse/ExportAdjustment/ExportAdjustment";
import FinishedGoodReceipt from "../../views/Warehouse/FinishedGoodsReceipt/FinishedGoodReceipt";
import ImportPurchases from "../../views/Warehouse/ImportPurchases/ImportPurchases";
import ImportPurchasesDetail from "../../views/Warehouse/ImportPurchases/importPurchasesDetail";
import ImportPurchasesView from "../../views/Warehouse/ImportPurchases/ImportPurchasesView";
import InOutWarehouseOutWard from "../../views/Warehouse/InOutWarehouseManagement/WarehouseOutWard";
import InventoryReportList from "../../views/Warehouse/InventoryReport/InventoryReportList";
import InventoryTransactionsReport from "../../views/Warehouse/InventoryTransactionsReport/InventoryTransactionsReport";
import ListSerial from "../../views/Warehouse/ListSerial/ListSerial";
import DetailWipView from "../../views/Warehouse/MfgWip/DetailView";
import EditWip from "../../views/Warehouse/MfgWip/EditWip";
import MfgWip from "../../views/Warehouse/MfgWip/MfgWip";
import { SupplierSlip } from "../../views/Warehouse/SupplierSlip/SupplierSlip";
import CreateWarehousePurchaseInWard from "../../views/Warehouse/WarehouseInWard/CreateWarehousePurchaseInWard";
import WarehouseInWardPlus from "../../views/Warehouse/WarehouseInWardPlus/WarehouseInWardPlus";
import WarehouseLocationList from "../../views/Warehouse/WarehouseLocationList/WarehouseLocationList";
import DetailWarehouseOutWard from "../../views/Warehouse/WarehouseOutWard/DetailWarehouseOutWard";
import DefectiveGoodsList from "../../views/Warranty/DefectiveGoodsList/DefectiveGoodsList";
import PerformHomeWarranty from "../../views/Warranty/PerformHomeWarranty/PerformHomeWarranty";
import WarrantyReceiveItem from "../../views/Warranty/ReceiptWarranty/WarrantyReceiveItem";
import ReceiveTicketList from "../../views/Warranty/ReceiveTicket/ReceiveTicketList";
import ReturnCustomers from "../../views/Warranty/ReturnCustomers/ReturnCustomers";
import WarrantyInformation from "../../views/Warranty/WarrantyInformation/WarrantyInformation";
import CreateExportBorrwedGood from "../../views/Warranty/WarrantyLoanRequestForm/DMExportBorrowedGoods/CreateExportBorrwedGood";
import DetailExportBorredGood from "../../views/Warranty/WarrantyLoanRequestForm/DMExportBorrowedGoods/DetailExportBorredGood";
import DMBorrwedGoods from "../../views/Warranty/WarrantyLoanRequestForm/DMExportBorrowedGoods/DMExportBorrwedGoods";
import EditExportBorred from "../../views/Warranty/WarrantyLoanRequestForm/DMExportBorrowedGoods/EditExportBorred";
import WarrantyRenewalList from "../../views/Warranty/WarrantyRenewal/WarrantyRenewalList";
import InventoryReportByBarcode from "../../views/Warehouse/InventoryReportByBarcode/InventoryReportByBarcode";
import DebtGroupList from "../../views/DebtGroup/DebtGroupList";
import DebtGroupListCus from "../../views/DebtGroup/DebtGroupListCus";
import ModalDetailTestingTechnique from "../../views/TestingTechnique/ModalDetailTestingTechnique";
import ModalAssignTestingTechnique from "../../views/TestingTechnique/ModalAssignTestingTechnique";
import ModalUpdateTestingTechnique from "../../views/TestingTechnique/ModalUpdateTestingTechnique";
import PaymentSlipBranchs from "../../views/Sell/PaymentSlipBranchs/PaymentSlipBranchs";
import DebtNoteBranchs from "../../views/Sell/DebtNoteBranchs/DebtNoteBranchs";
import InwardRequest from "../../views/Warehouse/InwardRequest/InwardRequest";

const router = createBrowserRouter([
  {
    path: "/",
    id: "root",
    element: <Layout1 />,
    errorElement: <PageNotFound />,
    action: async ({ request }) => {
      // if (auth)
      //  return protectedLoader;
    },
    children: [
      {
        path: "/",
        element: <HomeView></HomeView>,
      },
      {
        path: "/department",
        handle: {
          crumb: () => {
            let model: SelectListItem = {
              disabled: false,
              group: null,
              selected: false,
              text: "",
              value: "",
            };
            model.text = "Danh mục dữ liệu";
            model.value = "/department";
            return model;
          },
        },
        children: [
          {
            path: "/department/department-list",
            element: <DepartmentsList></DepartmentsList>,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Danh mục bộ phận";
                model.value = "/department/department-list";
                return model;
              },
            },
          },
        ],
      },
      {
        path: "/category-data",
        handle: {
          crumb: () => {
            let model: SelectListItem = {
              disabled: false,
              group: null,
              selected: false,
              text: "",
              value: "",
            };
            model.text = "Danh mục dữ liệu";
            model.value = "/category-data";
            return model;
          },
        },
        children: [
          {
            path: "/category-data/general-data",
            element: <GeneralData></GeneralData>,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Dữ liệu chung";
                model.value = "system/category-data";
                return model;
              },
            },
          },
          {
            path: "/category-data/payment",
            element: <Payment></Payment>,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Thanh toán";
                model.value = "system/category-data";
                return model;
              },
            },
          },
          {
            path: "/category-data/transport",
            element: <Transport></Transport>,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Giao vận";
                model.value = "system/category-data";
                return model;
              },
            },
          },
          {
            path: "/category-data/commodities",
            element: <Commodities></Commodities>,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Hàng hóa";
                model.value = "system/category-data";
                return model;
              },
            },
          },
        ],
      },
      {
        path: "/invoice",
        //   element: <SystemView />,
        handle: {
          crumb: () => {
            let model: SelectListItem = {
              disabled: false,
              group: null,
              selected: false,
              text: "",
              value: "",
            };
            model.text = "Danh mục hóa đơn";
            model.value = "/invoice";
            return model;
          },
        },
        children: [
          {
            path: "/invoice/list",
            element: <Invoice />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Hóa đơn";
                model.value = "/list";
                return model;
              },
            },
          },
        ],
      },

      {
        path: "/config-system",
        handle: {
          crumb: () => {
            let model: SelectListItem = {
              disabled: false,
              group: null,
              selected: false,
              text: "",
              value: "",
            };
            model.text = "Thiết lập hệ thống";
            model.value = "/config-system";
            return model;
          },
        },
        children: [
          {
            path: "/config-system/config-price",
            element: <ListPrice></ListPrice>,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Thiết lập bảng giá bán";
                model.value = "system/config-system";
                return model;
              },
            },
          },
          {
            path: "/config-system/list-details-price",
            element: <ListDetailsPrice></ListDetailsPrice>,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Thiết lập bảng giá bán";
                model.value = "/config-system/list-details-price";
                return model;
              },
            },
          },
          {
            path: "/config-system/config-promotion-policy",
            element: <PromotionPolicy></PromotionPolicy>,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Thiết lập chính sách khuyến mãi";
                model.value = "/config-system/config-promotion-policy";
                return model;
              },
            },
          },

          {
            path: "/config-system/create-promotion-policy",
            element: <CreatePromotionPolicy></CreatePromotionPolicy>,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Chính sách khuyến mãi";
                model.value = "/config-system/config-promotion-policy";
                return model;
              },
            },
          },
          {
            path: "/config-system/detail-promotion",
            element: <ViewDetailPromotion></ViewDetailPromotion>,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Chính sách khuyến mãi";
                model.value = "/config-system/config-promotion-policy";
                return model;
              },
            },
          },

          {
            path: "/config-system/edit-promotion",
            element: <EditPromotion></EditPromotion>,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                return model;
              },
            },
          },

          {
            path: "/config-system/general-settings",
            element: <ListPrice></ListPrice>,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                return model;
              },
            },
          },

          {
            path: "/config-system/config-bom",
            element: <MfgBomView></MfgBomView>,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Thiết lập định mức nguyên vật liệu";
                model.value = "/config-system/config-bom";
                return model;
              },
            },
          },
          {
            path: "/config-system/create-config-bom",
            element: <CreateMfgBom></CreateMfgBom>,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Thiết lập định mức nguyên vật liệu";
                model.value = "/config-system/create-config-bom";
                return model;
              },
            },
          },
          {
            path: "/config-system/edit-config-bom",
            element: <EditMfgBom></EditMfgBom>,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Thiết lập định mức nguyên vật liệu";
                model.value = "/config-system/edit-config-bom";
                return model;
              },
            },
          },

          {
            path: "/config-system/detail-config-bom",
            element: <DetailMfgBom></DetailMfgBom>,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Thiết lập định mức nguyên vật liệu";
                model.value = "/config-system/detail-config-bom";
                return model;
              },
            },
          },
        ],
      },
      {
        path: "/warehouse-config",
        handle: {
          crumb: () => {
            let model: SelectListItem = {
              disabled: false,
              group: null,
              selected: false,
              text: "",
              value: "",
            };
            model.text = "Cấu hình kho hàng";
            model.value = "/warehouse-config";
            return model;
          },
        },
        children: [
          {
            path: "/warehouse-config/inoutwarehouse-management",
            element: <InOutWarehouseOutWard />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Theo dõi nhập/xuất";
                model.value = "/warehouse-config/inoutwarehouse-management";
                return model;
              },
            },
          },
          {
            path: "/warehouse-config/barcode-status",
            element: <BarcodeStatus />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Trạng thái mã vạch";
                model.value = "/warehouse-config/barcode-status";
                return model;
              },
            },
          },
          {
            path: "/warehouse-config/import-purchases",
            element: <ImportPurchases />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Đơn mua hàng";
                model.value = "/warehouse-config/warehouse-config";
                return model;
              },
            },
          },
          {
            path: "/warehouse-config/purchase-order",
            element: <ImportPurchasesView />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Danh sách đơn mua hàng";
                model.value = "/warehouse-config/warehouse-config";
                return model;
              },
            },
          },
          {
            path: "/warehouse-config/purchase-order-detail",
            element: <ImportPurchasesDetail />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Danh sách chi tiết đơn mua hàng";
                model.value = "/warehouse-config/warehouse-config";
                return model;
              },
            },
          },
          {
            path: "/warehouse-config/supplier-slip",
            element: <SupplierSlip />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Danh sách phiếu nhập từ nhà cung cấp";
                model.value = "/warehouse-config/warehouse-config";
                return model;
              },
            },
          },
          {
            path: "/warehouse-config/list-serial",
            element: <ListSerial />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Danh sách kho serial";
                model.value = "/warehouse-config/warehouse-config";
                return model;
              },
            },
          },
          {
            path: "/warehouse-config/warehouse-management",
            element: <WarehouseManagement />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Kiểm kê kho";
                model.value = "/warehouse-config/warehouse-config";
                return model;
              },
            },
          },
          {
            path: "/warehouse-config/inventory-cycle-count",
            element: <InventoryCycleCount />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                return model;
              },
            },
          },

          {
            path: "/warehouse-config/request-transfer",
            element: <RequestTransfer />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Lập yêu cầu điều chuyển kho";
                model.value = "/warehouse-config/request-transfer";
                return model;
              },
            },
          },
          {
            path: "/warehouse-config/create-request-transfer",
            element: <CreateRequestTransfer />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Yêu cầu điều chuyển kho";
                model.value = "/warehouse-config/create-request-transfer";
                return model;
              },
            },
          },
          {
            path: "/warehouse-config/edit-request-transfer",
            element: <EditRequestTransfer />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Yêu cầu điều chuyển kho";
                model.value = "/warehouse-config/edit-request-transfer";
                return model;
              },
            },
          },
          {
            path: "/warehouse-config/detail-request-transfer",
            element: <DetailRequestTransfer />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Yêu cầu điều chuyển kho";
                model.value = "/warehouse-config/detail-request-transfer";
                return model;
              },
            },
          },

          {
            path: "/warehouse-config/request-inventory-transaction",
            element: <RequestInventoryTransaction />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Phiếu cần xuất kho";
                model.value = "/warehouse-config/request-inventory-transaction";
                return model;
              },
            },
          },
          {
            path: "/warehouse-config/request-inward-transaction",
            element: <InwardRequest />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Phiếu cần nhập kho";
                model.value = "/warehouse-config/request-inward-transaction";
                return model;
              },
            },
          },
          {
            path: "/warehouse-config/warehouse-transaction",
            element: <WarehouseTransaction />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Quản lý sổ kho";
                model.value = "/warehouse-config/warehouse-transaction";
                return model;
              },
            },
          },

          {
            path: "/warehouse-config/warehouse-out-ward",
            element: <WarehouseOutWard />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Phiếu xuất kho";
                model.value = "/warehouse-config/warehouse-out-ward";
                return model;
              },
            },
          },
          {
            path: "/warehouse-config/create-warehouse-out-ward",
            element: <CreateWarehouseOutWard />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Phiếu xuất kho";
                model.value = "/warehouse-config/create-warehouse-out-ward";
                return model;
              },
            },
          },
          {
            path: "/warehouse-config/supplier-request-form",
            element: <WarehouseInWardPlus />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Phiếu nhập hàng nhà cung cấp";
                model.value = "/warehouse-config/supplier-request-form";
                return model;
              },
            },
          },
          {
            path: "/warehouse-config/edit-warehouse-out-ward",
            element: <EditWarehouseOutWard />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Phiếu xuất kho";
                model.value = "/warehouse-config/edit-warehouse-out-ward";
                return model;
              },
            },
          },
          {
            path: "/warehouse-config/detail-warehouse-out-ward",
            element: <DetailWarehouseOutWard />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Phiếu xuất kho";
                model.value = "/warehouse-config/detail-warehouse-out-ward";
                return model;
              },
            },
          },

          {
            path: "/warehouse-config/warehouse-in-ward",
            element: <WarehouseInWard />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Phiếu nhập kho";
                model.value = "/warehouse-config/warehouse-in-ward";
                return model;
              },
            },
          },
          {
            path: "/warehouse-config/create-warehouse-in-ward",
            element: <WarehouseInWardPlus />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Phiếu nhập kho";
                model.value = "/warehouse-config/create-warehouse-in-ward";
                return model;
              },
            },
          },
          {
            path: "/warehouse-config/create-finished-product-receipt",
            element: <WarehouseInWardPlus />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Phiếu nhập kho";
                model.value =
                  "/warehouse-config/create-finished-product-receipt";
                return model;
              },
            },
          },
          {
            path: "/warehouse-config/edit-warehouse-in-ward",
            element: <EditWarehouseInWard />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Phiếu nhập kho";
                model.value = "/warehouse-config/edit-warehouse-in-ward";
                return model;
              },
            },
          },
          {
            path: "/warehouse-config/edit-warehouse-in-ward-plus",
            element: <WarehouseInWardPlus />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Phiếu nhập kho";
                model.value = "/warehouse-config/edit-warehouse-in-ward-plus";
                return model;
              },
            },
          },
          {
            path: "/warehouse-config/create-warehouse-purchase-in-ward",
            element: <CreateWarehousePurchaseInWard />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Phiếu nhập kho mua hàng";
                model.value =
                  "/warehouse-config/create-warehouse-purchase-in-ward";
                return model;
              },
            },
          },
          // {
          //   path: "/warehouse-config/edit-warehouse-purchase-in-ward",
          //   element: <EditWarehousePurchaseInWard />,
          //   handle: {
          //     crumb: () => {
          //       let model: SelectListItem = {
          //         disabled: false,
          //         group: null,
          //         selected: false,
          //         text: "",
          //         value: "",
          //       };
          //       model.text = "Phiếu nhập kho mua hàng";
          //       model.value =
          //         "/warehouse-config/edit-warehouse-purchase-in-ward";
          //       return model;
          //     },
          //   },
          // },

          //MfgWip
          {
            path: "/warehouse-config/wip",
            element: <MfgWip />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Lệnh sản xuất";
                model.value = "/warehouse-config/wip";
                return model;
              },
            },
          },

          // {
          //   path: "/warehouse-config/create-wip",
          //   element: <CreateMfgWip />,
          //   handle: {
          //     crumb: () => {
          //       let model: SelectListItem = {
          //         disabled: false,
          //         group: null,
          //         selected: false,
          //         text: "",
          //         value: "",
          //       };
          //       model.text = "Lệnh sản xuất";
          //       model.value = "/warehouse-config/create-wip";
          //       return model;
          //     },
          //   },
          // },
          {
            path: "/warehouse-config/edit-wip",
            element: <EditWip />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Lệnh sản xuất";
                model.value = "/warehouse-config/edit-wip";
                return model;
              },
            },
          },
          {
            path: "/warehouse-config/detail-wip",
            element: <DetailWipView />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Chi tiết lệnh sản xuất";
                model.value = "/warehouse-config/detail-wip";
                return model;
              },
            },
          },
          {
            path: "/warehouse-config/export-adjustment",
            element: <ExportAdjustment />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Xuất kho hủy";
                model.value = "/warehouse-config/export-adjustment";
                return model;
              },
            },
          },
          {
            path: "/warehouse-config/inventoryReport",
            element: <InventoryReportList />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Báo cáo tồn kho";
                model.value = "/warehouse-config/inventoryReport";
                return model;
              },
            },
          },
          {
            path: "/warehouse-config/InventoryReportByBarcode",
            element: <InventoryReportByBarcode />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Báo cáo tồn kho theo mã vạch";
                model.value = "/warehouse-config/InventoryReportByBarcode";
                return model;
              },
            },
          },
          {
            path: "/warehouse-config/warehouse-location-list",
            element: <WarehouseLocationList />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Danh mục vị trí kho";
                model.value = "/warehouse-config/warehouse-location-list";
                return model;
              },
            },
          },
          {
            path: "/warehouse-config/finished-goods-receipt-list",
            element: <FinishedGoodReceipt />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Danh sách phiếu nhập thành phẩm";
                model.value = "/warehouse-config/finished-goods-receipt-list";
                return model;
              },
            },
          },
          {
            path: "/warehouse-config/inventoryTransfer",
            element: <InventoryTransactionsReport />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Bảng kê phiếu nhập/phiếu xuất";
                model.value = "/warehouse-config/inventoryTransfer";
                return model;
              },
            },
          },
          {
            path: "/warehouse-config/barcode-history",
            element: <BarcodeHistory />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Báo cáo lịch sử mã vạch";
                model.value = "/warehouse-config/barcode-history";
                return model;
              },
            },
          },
          {
            path: "/warehouse-config/category-combination",
            element: <CategoryCombination />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Danh sách sản phẩm";
                model.value = "/warehouse-config/category-combination";
                return model;
              },
            },
          },
        ],
      },
      {
        path: "/proposal",
        element: <ProposalView />,
        handle: {
          crumb: () => {
            let model: SelectListItem = {
              disabled: false,
              group: null,
              selected: false,
              text: "",
              value: "",
            };
            model.text = "Đề xuất";
            model.value = "/proposal";
            return model;
          },
        },
        children: [
          {
            path: "/proposal/create-proposal",
            element: <CreateProposalView />,
            index: true,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Tạo đề xuất";
                model.value = "/proposal/create-proposal";
                return model;
              },
            },
          },
          {
            path: "/proposal/edit-proposal",
            element: <EditProposalView />,
            index: true,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Sửa đề xuất";
                model.value = "/proposal/edit-proposal";
                return model;
              },
            },
          },
          {
            path: "/proposal/proposal-detail",
            element: <DetailProposalView />,
            index: true,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Chi tiết đề xuất";
                model.value = "/proposal/proposal-detail";
                return model;
              },
            },
          },
          {
            path: "/proposal/approve-proposal",
            element: <ApproveProposalView />,
            index: true,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Quản lý đề xuất";
                model.value = "/proposal/approve-proposal";
                return model;
              },
            },
          },
        ],
      },
      {
        path: "/delivery-config",
        handle: {
          crumb: () => {
            let model: SelectListItem = {
              disabled: false,
              group: null,
              selected: false,
              text: "",
              value: "",
            };
            model.text = "Cấu hình giao vận";
            model.value = "system/config-system";
            return model;
          },
        },
        children: [
          {
            path: "/delivery-config/delivery",
            element: <Delivery></Delivery>,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Đơn giao hàng";
                model.value = "system/config-system";
                return model;
              },
            },
          },
          {
            path: "/delivery-config/delivery-assignment",
            element: <DeliveryAssignment></DeliveryAssignment>,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Phân đơn hàng giao vận";
                model.value = "system/config-system";
                return model;
              },
            },
          },
          // {
          //   path: "/delivery-config/delivery-create",
          //   element: <CreateDataViewDelivery />,
          //   handle: {
          //     crumb: () => {
          //       let model: SelectListItem = {
          //         disabled: false,
          //         group: null,
          //         selected: false,
          //         text: "",
          //         value: "",
          //       };
          //       return model;
          //     },
          //   },
          // },
          {
            path: "/delivery-config/delivery-dispatch",
            element: <DeliveryDispatch></DeliveryDispatch>,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Thực hiện vận đơn";
                model.value = "system/config-system";
                return model;
              },
            },
          },
          {
            path: "/delivery-config/delivery-pay",
            element: <DeliveryPay />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Báo cáo tính lương giao vận";
                model.value = "/delivery-config/delivery-pay";
                return model;
              },
            },
          },
        ],
      },
      {
        path: "/system",
        //   element: <SystemView />,
        handle: {
          crumb: () => {
            let model: SelectListItem = {
              disabled: false,
              group: null,
              selected: false,
              text: "",
              value: "",
            };
            model.text = "Hệ thống";
            model.value = "/system";
            return model;
          },
        },
        children: [
          {
            path: "/system/document-type",
            element: <TypeContractTable />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Loại tài liệu";
                model.value = "system/document-type";
                return model;
              },
            },
          },
        ],
      },

      {
        path: "/sell",
        //   element: <SystemView />,
        handle: {
          crumb: () => {
            let model: SelectListItem = {
              disabled: false,
              group: null,
              selected: false,
              text: "",
              value: "",
            };
            model.text = "Bán hàng";
            model.value = "/sell";
            return model;
          },
        },
        children: [
          {
            path: "/sell/retail-order",
            element: <RetailOrder />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Đơn bán lẻ";
                model.value = "system/retail-order";
                return model;
              },
            },
          },
          {
            path: "/sell/sales-order-list",
            element: <SalesOrderView />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Danh sách đơn";
                model.value = "system/sales-order";
                return model;
              },
            },
          },

          {
            path: "/sell/sales-order-list-return",
            element: <SalesOrderView />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Danh sách đơn đổi trả";
                model.value = "system/sales-order";
                return model;
              },
            },
          },

          {
            path: "/sell/approved-discount-order",
            element: <RetailOrder />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Duyệt xin giảm giá";
                model.value = "system/sales-order";
                return model;
              },
            },
          },
          {
            path: "/sell/exchange-order",
            element: <RetailOrder />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Đổi sản phẩm";
                model.value = "system/sales-order";
                return model;
              },
            },
          },
          {
            path: "/sell/revise-order",
            element: <RetailOrder />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Sửa đơn hàng bán";
                model.value = "system/sales-order";
                return model;
              },
            },
          },
          {
            path: "/sell/view-order",
            element: <RetailOrder />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Xem đơn hàng bán";
                model.value = "system/sales-order";
                return model;
              },
            },
          },
          {
            path: "/sell/payment-order",
            element: <RetailOrder />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Thanh toán đơn hàng";
                model.value = "system/sales-order";
                return model;
              },
            },
          },
          {
            path: "/sell/return-order",
            element: <ReturnOrder />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Tạo đơn trả hàng";
                model.value = "system/sales-order";
                return model;
              },
            },
          },

          {
            path: "/sell/view-return-order",
            element: <ReturnOrder />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Xem đơn trả hàng";
                model.value = "system/sales-order";
                return model;
              },
            },
          },

          {
            path: "/sell/payment-return-order",
            element: <ReturnOrder />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Thanh toán đơn trả hàng";
                model.value = "system/sales-order";
                return model;
              },
            },
          },

          {
            path: "/sell/customer",
            element: <DmCustomer />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Khách hàng";
                model.value = "system/sales-order";
                return model;
              },
            },
          },
          {
            path: "/sell/screen-payment",
            element: <PaymentForm />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Thông tin thanh toán";
                model.value = "/sell/screen-payment";
                return model;
              },
            },
          },
        ],
      },

      {
        path: "/bill",
        handle: {
          crumb: () => {
            let model: SelectListItem = {
              disabled: false,
              group: null,
              selected: false,
              text: "",
              value: "",
            };
            model.text = "Phiếu thu";
            model.value = "/bill";
            return model;
          },
        },
        children: [
          {
            path: "/bill/list-receipts",
            element: <ListReceipts />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Danh sách phiếu thu / chi";
                model.value = "system/bill";
                return model;
              },
            },
          },
          {
            path: "/bill/adjustment-slip",
            element: <AdjustmentSlip />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Phiếu điều chỉnh";
                model.value = "system/bill";
                return model;
              },
            },
          },
          {
            path: "/bill/add-adjustment-slip",
            element: <AddAdjustmentSlip />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Thêm phiếu điều chỉnh";
                model.value = "system/bill";
                return model;
              },
            },
          },
          {
            path: "/bill/list-payment-slip",
            element: <ListPaymentSlip />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Danh sách phiếu chi";
                model.value = "system/bill";
                return model;
              },
            },
          },
          {
            path: "/bill/detail-payment-receipt",
            element: <DetailListReceipts />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Xem chi tiết phiếu";
                model.value = "system/bill";
                return model;
              },
            },
          },
          {
            path: "/bill/detail-payment-receipt",
            element: <DetailListReceipts />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Xem chi tiết phiếu";
                model.value = "system/bill";
                return model;
              },
            },
          },
          {
            path: "/bill/payment-receipt",
            element: <PaymentReceipt />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Phiếu thu đơn hàng";
                model.value = "system/bill";
                return model;
              },
            },
          },
          {
            path: "/bill/internal-receipt",
            element: <InternalReceipt />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Phiếu thu khác";
                model.value = "system/bill";
                return model;
              },
            },
          },
          {
            path: "/bill/credit-advice",
            element: <CreditAdvice />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Lập phiếu báo có";
                model.value = "system/bill";
                return model;
              },
            },
          },
          {
            path: "/bill/payment-slip",
            element: <PaymentSlip />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Lập phiếu chi";
                model.value = "system/bill";
                return model;
              },
            },
          },
          {
            path: "/bill/payment-slip-branches",
            element: <PaymentSlipBranchs />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Lập phiếu chi (cho nhiều chi nhánh)";
                model.value = "system/bill";
                return model;
              },
            },
          },
          {
            path: "/bill/debt-note",
            element: <DebtNote />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Lập phiếu báo nợ ";
                model.value = "system/bill";
                return model;
              },
            },
          },
          {
            path: "/bill/debt-note-branches",
            element: <DebtNoteBranchs />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Lập phiếu báo nợ (Cho nhiều chi nhánh)";
                model.value = "system/bill";
                return model;
              },
            },
          },
          {
            path: "/bill/exchange-rate",
            element: <ExchangeRate />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Tỉ giá vàng";
                model.value = "system/bill";
                return model;
              },
            },
          },
        ],
      },
      {
        path: "/reduced-approval",
        handle: {
          crumb: () => {
            let model: SelectListItem = {
              disabled: false,
              group: null,
              selected: false,
              text: "",
              value: "",
            };
            model.text = "Duyệt giảm";
            model.value = "/reduced-approval";
            return model;
          },
        },
        children: [
          {
            path: "/reduced-approval/discount-approval",
            element: <ReducedApproval />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Duyệt giảm";
                model.value = "/reduced-approval/discount-approval";
                return model;
              },
            },
          },
        ],
      },
      {
        path: "/warranty",
        //   element: <SystemView />,
        handle: {
          crumb: () => {
            let model: SelectListItem = {
              disabled: false,
              group: null,
              selected: false,
              text: "",
              value: "",
            };
            model.text = "Bảo hành";
            model.value = "/warranty";
            return model;
          },
        },
        children: [
          {
            path: "/warranty/receive-warranty",
            element: <ReceptionList />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Lập phiếu yêu cầu";
                model.value = "/warranty/receive-warranty";
                return model;
              },
            },
          },
          {
            path: "/warranty/warranty-list",
            element: <WarrantyRenewalList />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Danh sách gia hạn bảo hành";
                model.value = "/warranty/warranty-list";
                return model;
              },
            },
          },

          {
            path: "/warranty/supplier-warranty-export",
            element: <SupplierWarrantyExport />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Xuất bảo hành NCC";
                model.value = "/warranty/receive-warranty";
                return model;
              },
            },
          },
          {
            path: "/warranty/request-processing",
            element: <ProcessingAssignment />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Xử lý yêu cầu";
                model.value = "/warranty/receive-warranty";
                return model;
              },
            },
          },
          {
            path: "/warranty/warranty-company",
            element: <WarrantyCompany />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Theo dõi hãng xử lý tại nhà";
                model.value = "/warranty/warranty-company";
                return model;
              },
            },
          },
          {
            path: "/warranty/warranty-company",
            element: <WarrantyCompany />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Theo dõi hãng xử lý tại nhà";
                model.value = "/warranty/warranty-company";
                return model;
              },
            },
          },
          {
            path: "/warranty/perform-home-warranty",
            element: <PerformHomeWarranty />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Thực hiện xử lý tại nhà";
                model.value = "/warranty/perform-home-warranty";
                return model;
              },
            },
          },
          {
            path: "/warranty/receive-ticket-list",
            element: <ReceiveTicketList />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Phiếu tiếp nhận hàng lỗi";
                model.value = "/warranty/receive-ticket-list";
                return model;
              },
            },
          },
          {
            path: "/warranty/defective-goods-list",
            element: <DefectiveGoodsList />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Bảng kê hàng lỗi";
                model.value = "/warranty/defective-goods-list";
                return model;
              },
            },
          },
          {
            path: "/warranty/return-customers",
            element: <ReturnCustomers />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Xuất trả khách";
                model.value = "/warranty/defective-goods-list";
                return model;
              },
            },
          },
          {
            path: "/warranty/warranty-information",
            element: <WarrantyInformation />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Thông tin phân công";
                model.value = "/warranty/warranty-information";
                return model;
              },
            },
          },
          {
            path: "/warranty/warranty-receive-item",
            element: <WarrantyReceiveItem />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Phiếu nhập kho hàng lỗi";
                model.value = "/warranty/warranty-receive-item";
                return model;
              },
            },
          },
          {
            path: "/warranty/list-borrowed-goods",
            element: <DMBorrwedGoods />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Phiếu xuất nhập hàng mượn";
                model.value = "/warranty/list-borrowed-goods";
                return model;
              },
            },
          },
          {
            path: "/warranty/create-borrowed-goods",
            element: <CreateExportBorrwedGood />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Thêm mới phiếu xuất nhập hàng mượn";
                model.value = "/warranty/create-borrowed-goods";
                return model;
              },
            },
          },
          {
            path: "/warranty/detail-borrowed-goods",
            element: <DetailExportBorredGood />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Chi tiết phiếu xuất nhập hàng mượn";
                model.value = "/warranty/detail-borrowed-goods";
                return model;
              },
            },
          },
          {
            path: "/warranty/edit-borrowed-goods",
            element: <EditExportBorred />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Chỉnh sửa phiếu xuất nhập hàng mượn";
                model.value = "/warranty/edit-export-borrowed-goods";
                return model;
              },
            },
          },
        ],
      },
      {
        path: "/testing",
        //   element: <SystemView />,
        handle: {
          crumb: () => {
            let model: SelectListItem = {
              disabled: false,
              group: null,
              selected: false,
              text: "",
              value: "",
            };
            model.text = "Kiểm tra";
            model.value = "/testing";
            return model;
          },
        },
        children: [
          {
            path: "/testing/testing-technique",
            element: <TestingTechnique />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Kỹ thuật kiểm tra";
                model.value = "/testing/testing-technique";
                return model;
              },
            },
          },
          {
            path: "/testing/detail-testing-technique",
            element: <ModalDetailTestingTechnique />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Chi tiết kỹ thuật kiểm tra";
                model.value = "/testing/detail-testing-technique";
                return model;
              },
            },
          },
          {
            path: "/testing/assign-testing-technique",
            element: <ModalAssignTestingTechnique />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Chi tiết kỹ thuật kiểm tra";
                model.value = "/testing/assign-testing-technique";
                return model;
              },
            },
          },
          {
            path: "/testing/update-testing-technique",
            element: <ModalUpdateTestingTechnique />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Cập nhật kỹ thuật kiểm tra";
                model.value = "/testing/update-testing-technique";
                return model;
              },
            },
          },
          // {
          //   path: "/testing/reception-testing-technique",
          //   element: <ReceptionTestingTechnique />,
          //   handle: {
          //     crumb: () => {
          //       let model: SelectListItem = {
          //         disabled: false,
          //         group: null,
          //         selected: false,
          //         text: "",
          //         value: "",
          //       };
          //       model.text = "Tiếp nhận kiểm tra";
          //       model.value = "/testing/reception-testing-technique";
          //       return model;
          //     },
          //   },
          // },
        ],
      },

      {
        path: "/customer-management",
        //   element: <SystemView />,
        handle: {
          crumb: () => {
            let model: SelectListItem = {
              disabled: false,
              group: null,
              selected: false,
              text: "",
              value: "",
            };
            model.text = "Danh mục dữ liệu";
            model.value = "/customer-management";
            return model;
          },
        },
        children: [
          {
            path: "/customer-management/customer-list",
            element: <CustomerList />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Danh sách khách hàng";
                model.value = "/customer-management/customer-list";
                return model;
              },
            },
          },
        ],
      },
      {
        path: "/debtgroup",
        //   element: <SystemView />,
        handle: {
          crumb: () => {
            let model: SelectListItem = {
              disabled: false,
              group: null,
              selected: false,
              text: "",
              value: "",
            };
            model.text = "Danh mục dữ liệu";
            model.value = "/debtgroup";
            return model;
          },
        },
        children: [
          {
            path: "/debtgroup/customer-debtgroup-list",
            element: <DebtGroupListCus type={1} />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Danh sách nhóm công nợ khách hàng";
                model.value = "/debtgroup/customer-debtgroup-list";
                return model;
              },
            },
          },
          {
            path: "/debtgroup/supplier-debtgroup-list",
            element: <DebtGroupList type={2} />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Danh sách nhóm công nợ nhà cung cấp";
                model.value = "/debtgroup/supplier-debtgroup-list";
                return model;
              },
            },
          },
        ],
      },
      {
        path: "/menuview",
        //   element: <SystemView />,
        handle: {
          crumb: () => {
            let model: SelectListItem = {
              disabled: false,
              group: null,
              selected: false,
              text: "",
              value: "",
            };
            model.text = "Menu";
            model.value = "/menuview";
            return model;
          },
        },
        children: [
          {
            path: "/menuview/list",
            element: <MenuView />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Danh sách nhóm công nợ";
                model.value = "/menuview/list";
                return model;
              },
            },
          },
        ],
      },

      {
        path: "/authozire",
        //   element: <SystemView />,
        handle: {
          crumb: () => {
            let model: SelectListItem = {
              disabled: false,
              group: null,
              selected: false,
              text: "",
              value: "",
            };
            model.text = "Phân quyền";
            model.value = "/authozire";
            return model;
          },
        },
        children: [
          {
            path: "/authozire/menu-group",
            element: <MenuGroupListView />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Nhóm menu";
                model.value = "authozire/menu-group";
                return model;
              },
            },
          },
          {
            path: "/authozire/roles",
            element: <AuthListRoleView />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Danh mục chức năng";
                model.value = "authozire/roles";
                return model;
              },
            },
          },
          {
            path: "/authozire/groups",
            element: <GroupsRoleView />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Nhóm người dùng";
                model.value = "authozire/groups";
                return model;
              },
            },
          },
          {
            path: "/authozire/groups-brand-inventory",
            element: <GroupUserView />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Nhóm phòng ban và kho";
                model.value = "authozire/groups-brand-inventory";
                return model;
              },
            },
          },
          {
            path: "/authozire/users",
            element: <UserListView />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Danh sách người dùng";
                model.value = "authozire/users";
                return model;
              },
            },
          },
          {
            path: "/authozire/app",
            element: <AppView />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Danh sách App";
                model.value = "authozire/app";
                return model;
              },
            },
          },
          {
            path: "/authozire/history-and-approve",
            element: <HistoryAndApprove />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Lịch sử và phê duyệt";
                model.value = "authozire/history-and-approve";
                return model;
              },
            },
          },
          {
            path: "/authozire/logging",
            element: <LoggingView />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Nhật ký hệ thống";
                model.value = "authozire/logging";
                return model;
              },
            },
          },
          {
            path: "/authozire/online",
            element: <ListOnlineView />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Danh sách online";
                model.value = "authozire/online";
                return model;
              },
            },
          },
          {
            path: "/authozire/topic-error",
            element: <TopicErrorView />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Topic error";
                model.value = "authozire/logging";
                return model;
              },
            },
          },
          {
            path: "/authozire/user-page",
            element: <UserPageView />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Trang cá nhân";
                model.value = "authozire/logging";
                return model;
              },
            },
          },
        ],
      },

      {
        path: "/organization",
        //   element: <SystemView />,
        handle: {
          crumb: () => {
            let model: SelectListItem = {
              disabled: false,
              group: null,
              selected: false,
              text: "",
              value: "",
            };
            model.text = "Dữ liệu chung";
            model.value = "/organization";
            return model;
          },
        },
        children: [
          {
            path: "/organization/department",
            element: <DepartmentView />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Phòng ban";
                model.value = "organization/department";
                return model;
              },
            },
          },
          {
            path: "/organization/position",
            element: <RoleView />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Chức vụ";
                model.value = "organization/position";
                return model;
              },
            },
          },
          {
            path: "/organization/staff",
            element: <EmployeeView />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Nhân viên";
                model.value = "organization/staff";
                return model;
              },
            },
          },
        ],
      },

      {
        path: "/report",
        //   element: <SystemView />,
        handle: {
          crumb: () => {
            let model: SelectListItem = {
              disabled: false,
              group: null,
              selected: false,
              text: "",
              value: "",
            };
            model.text = "Báo cáo";
            model.value = "/report";
            return model;
          },
        },
        children: [
          {
            path: "/report/example",
            element: <GridExample />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Mẫu báo cáo";
                model.value = "report/example";
                return model;
              },
            },
          },
          {
            path: "/report/add-or-update-file",
            element: <AddOrUpdateFileReportView />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Thêm template export excel";
                model.value = "report/add-or-update-file";
                return model;
              },
            },
          },
          {
            path: "/report/inventory-serial-onhand",
            element: <ReportInventorySerialOnhandView />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Tồn kho theo serial";
                model.value = "report/inventory-serial-onhand";
                return model;
              },
            },
          },
          {
            path: "/report/payment-receipt",
            element: <ReportPaymentReceiptView />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Báo cáo sổ quỹ";
                model.value = "report/payment-receipt";
                return model;
              },
            },
          },
          {
            path: "/report/sales-list",
            element: <ReportSalesListView />,
            handle: {
              crumb: () => {
                let model: SelectListItem = {
                  disabled: false,
                  group: null,
                  selected: false,
                  text: "",
                  value: "",
                };
                model.text = "Bảng kê báng hàng";
                model.value = "report/sales-list";
                return model;
              },
            },
          },
        ],
      },
    ],
  },
  {
    path: "auth",
    element: <LayoutAuth />, // Define your Auth layout component here
    children: [
      {
        path: "login",
        element: <LayoutLogin />, // Create a Login page component
      },
      {
        path: "register",
        // element: <AuthenticationRegister />, // Create a Login page component
      },

      // Add more authentication-related routes here
    ],
  },
]);

export default router;
