import { useEffect, useMemo, useRef, useState } from "react";
import { TblDMApproveProposal } from "../../../model/TblDMApproveProposal";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_RowSelectionState,
  useMantineReactTable,
} from "mantine-react-table";
import { toast } from "react-toastify";
import * as xlsx from "xlsx";
import {
  ActionIcon,
  Badge,
  Button,
  Flex,
  Input,
  Menu,
  rem,
  Text,
  Title,
  Tooltip,
} from "@mantine/core";
import {
  IconCaretDown,
  IconCheck,
  IconEdit,
  IconEye,
  IconFileExport,
  IconHandStop,
  IconSearch,
  IconTrash,
} from "@tabler/icons-react";
import { _localization } from "../../../config/location";
import { paginationBase } from "../../../_base/model/_base/BaseTable";
import { IconPlus } from "@tabler/icons-react";
import { modals } from "@mantine/modals";
import { UpdateStatusView } from "./UpdateStatusView";
import { repositoryMdm } from "../../../_base/_const/_constVar";
import { MessageResponse } from "../../../model/MessageResponse";
import { useNavigate } from "react-router-dom";
import { DeleteProposalView } from "./DeleteProposalView";
import { ApproveWithResponseView } from "./ApproveWithResponseView";
import { CreateProposalView } from "../CreateProposal/CreateProposalView";
import { EditProposalView } from "../EditProposal/EditProposalView";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
export const ApproveProposalView = () => {
  //columns
  const checkEditEnabled = (item: TblDMApproveProposal) => {
    if (item.active === null) {
      return item.idEmployee !== (Number(localStorage.getItem("empId")) ?? 0);
    }
    return true;
  };
  const columns = useMemo<MRT_ColumnDef<TblDMApproveProposal>[]>(
    () => [
      {
        accessorKey: "id",
        header: "Id",
      },
      {
        accessorKey: "createDate",
        header: "Thời gian",
        Cell: ({ row }) => (
          <Text fw={700} size="sm">
            {formatDate(row.original?.createDate)}
          </Text>
        ),
      },
      {
        accessorKey: "code",
        header: "Mã đề xuất",
        Cell: ({ renderedCellValue, row }: any) => (
          <Badge
            variant="dot"
            color={getColorStatus(row.original.active)}
            radius={4}
            size="lg"
            onDoubleClick={() => {
              navigate("/proposal/proposal-detail", {
                state: { proposalId: row.original.id },
              });
            }}
            style={{
              cursor: "pointer",
            }}
          >
            <Text fw={700} size="sm">
              {renderedCellValue}
            </Text>
          </Badge>
        ),
        size: 100,
      },
      {
        accessorKey: "nameDepeartment",
        header: "Phòng ban",
      },
      {
        accessorKey: "idWorkAddress",
        header: "Địa điểm làm việc",
      },
      {
        accessorKey: "nmaePart",
        header: "Bộ phận",
      },
      {
        accessorKey: "nameRole",
        header: "Chức danh",
      },
      {
        accessorKey: "nameEmployee",
        header: "Họ & Tên",
      },
      {
        accessorKey: "note",
        header: "Nội dung đề xuất",
      },
      {
        accessorKey: "urlFile",
        header: "File đính kèm",
        Cell: ({ renderedCellValue, row }) => (
          <>
            <Badge
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                if (row.original.urlFile !== "Không có file") {
                  openFile(row.original.urlFile ?? "", row.original.code);
                }
              }}
              w={80}
              color={getColorStatus(
                row.original.urlFile === "Không có file" ? false : true
              )}
            >
              {row.original.urlFile === "Không có file" ? "Chưa có" : "Đã có"}
            </Badge>
          </>
        ),
      },
      {
        accessorKey: "priorityLevel",
        header: "Múc độ ưu tiên",
        Cell: ({ row }) => (
          <Badge w={80} color={getColorPriority(row.original.priorityLevel)}>
            {getTextPriority(row.original.priorityLevel)}
          </Badge>
        ),
      },
      {
        header: "Người nhận",
        accessorKey: "nameEmployeeReceive",
      },
      {
        accessorKey: "lastUpdateDate",
        header: "Ngày duyệt",
        Cell: ({ row }) =>
          row.original.active &&
          row.original.lastUpdateDate && (
            <Text fw={700} size="sm">
              {formatDate(row.original?.lastUpdateDate)}
            </Text>
          ),
      },
      {
        accessorKey: "active",
        header: "Trạng thái",
        Cell: ({ row }) => (
          <Badge w={120} color={getColorStatus(row.original.active)}>
            {row.original.active === null
              ? "Chưa duyệt"
              : row.original.active
              ? "Đã duyệt"
              : "Từ chối"}
          </Badge>
        ),
      },
      {
        header: "Phản hồi",
        accessorKey: "respond",
      },
      {
        accessorKey: "action",
        header: "Thao tác",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
        Cell: ({ row }) => (
          <Flex gap={8}>
            {row.original.active === null ? (
              <>
                <Tooltip label="Phê duyệt">
                  <ActionIcon
                    variant="light"
                    aria-label="Settings"
                    color="green"
                    disabled={
                      row.original.idEmployeeReceive !==
                      (Number(localStorage.getItem("empId")) ?? 0)
                    }
                    onClick={() => {
                      approveWithStatus(row.original);
                    }}
                  >
                    <IconCheck size={20} />
                  </ActionIcon>
                </Tooltip>
                <Tooltip label="Từ chối phê duyệt">
                  <ActionIcon
                    variant="light"
                    aria-label="Settings"
                    color="red"
                    disabled={
                      row.original.idEmployeeReceive !==
                      (Number(localStorage.getItem("empId")) ?? 0)
                    }
                    onClick={() => {
                      updateStatusItem([row.original.id], "reject");
                    }}
                  >
                    <IconHandStop size={20} />
                  </ActionIcon>
                </Tooltip>
              </>
            ) : (
              <>
                <Tooltip label="Phê duyệt">
                  <ActionIcon
                    variant="light"
                    aria-label="Settings"
                    color="green"
                    disabled={true}
                  >
                    <IconCheck size={20} />
                  </ActionIcon>
                </Tooltip>
                <Tooltip label="Từ chối phê duyệt">
                  <ActionIcon
                    variant="light"
                    aria-label="Settings"
                    color="red"
                    disabled={true}
                  >
                    <IconHandStop size={20} />
                  </ActionIcon>
                </Tooltip>
              </>
            )}
            <Tooltip label="Sửa">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="orange"
                disabled={checkEditEnabled(row.original)}
                onClick={() => {
                  navigate("/proposal/edit-proposal", {
                    state: {
                      id: row.original.id,
                    },
                  });
                }}
              >
                <IconEdit size={20} />
              </ActionIcon>
            </Tooltip>
            <Tooltip label="Xem chi tiết">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="blue"
                onClick={() => {
                  navigate("/proposal/proposal-detail", {
                    state: { proposalId: row.original.id },
                  });
                }}
              >
                <IconEye size={20} />
              </ActionIcon>
            </Tooltip>

            <Tooltip label="Xóa">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="red"
                onClick={() => {
                  deletesData([row.original.id]);
                }}
                disabled={checkEditEnabled(row.original)}
              >
                <IconTrash size={20} />
              </ActionIcon>
            </Tooltip>
          </Flex>
        ),
      },
    ],
    []
  );
  //data and fetching state
  const navigate = useNavigate();
  const headerRef = useRef<HTMLDivElement>(null);
  const [data, setData] = useState<TblDMApproveProposal[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);
  const [pagination, setPagination] = useState(paginationBase);

  //table state
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

  const [keySearch, setKeySearch] = useState<string>("");
  const [selectIds, setSelectIds] = useState<string[]>([]);
  const [closeViewStatus, setCloseViewStatus] = useState(false);

  const openFile = (url: string, name: string) => {
    modals.openConfirmModal({
      size: "auto",
      title: <Title order={5}>{name}</Title>,
      fullScreen: true,
      children: (
        <DocViewer
          style={{
            width: "fit-content",
            height: "fit-content",
            maxHeight: "80vh",
            minWidth: "60vh",
          }}
          documents={[`https://files.hacom.vn/hacom/${url}`].map((file) => ({
            uri: file,
          }))}
          config={{
            header: {
              disableHeader: true,
            },
            pdfZoom: {
              zoomJump: 0.1,
              defaultZoom: 2,
            },
          }}
          pluginRenderers={DocViewerRenderers}
        />
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };

  function getColorStatus(text: boolean | null): string {
    switch (text) {
      case null:
        return "yellow";
      case true:
        return "teal";
      default:
        return "red";
    }
  }

  function getColorPriority(level: number) {
    switch (level) {
      case 0:
        return "teal";
      default:
        return "red";
    }
  }

  function getTextPriority(level: number) {
    switch (level) {
      case 0:
        return "Thấp";
      default:
        return "Cao";
    }
  }

  const handleExport = () => {
    try {
      const worksheet = xlsx.utils.json_to_sheet(data);
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, worksheet, "Data");
      xlsx.writeFile(workbook, "danh-sach-phieu-de-xuat.xlsx");
      toast.success("Export excel thành công", { autoClose: 1500 });
    } catch (error) {
      toast.error("Export excel thất bại", { autoClose: 1500 });
    }
  };

  function formatDate(isoDateString: string | Date, add?: boolean): string {
    const date: Date = new Date(isoDateString);

    // Cộng thêm 7 tiếng
    if (add) date.setHours(date.getHours() + 7);
    // Lấy các thành phần ngày giờ sau khi cộng thêm số giờ
    const hours: string = date.getHours().toString().padStart(2, "0");
    const minutes: string = date.getMinutes().toString().padStart(2, "0");
    const seconds: string = date.getSeconds().toString().padStart(2, "0");
    const day: string = date.getDate().toString().padStart(2, "0");
    const month: string = (date.getMonth() + 1).toString().padStart(2, "0"); // Tháng bắt đầu từ 0
    const year: number = date.getFullYear();

    // Định dạng thành chuỗi "giờ:phút:giây ngày-tháng-năm"
    return `${hours}:${minutes}:${seconds} ${day}-${month}-${year}`;
  }
  //fetch
  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setIsError(false);
    try {
      let url = `?IdEmployees=${localStorage.getItem("empId") ?? 0}&Skip=${
        pagination.pageSize * pagination.pageIndex
      }&Take=${pagination.pageSize}`;
      if (keySearch) url += `&KeySearch=${keySearch}`;
      const response = await repositoryMdm.get<
        MessageResponse<TblDMApproveProposal[]>
      >("/api/v1/TblApproposal/get-list" + url);

      if (response && response.success) {
        const result = response.data;
        setData(result);
        setRowCount(response.totalCount);
        setSelectIds([]);
        table.resetRowSelection();
      }
    } catch (error) {
      setData([]);
      setIsError(true);
      console.error(error);
      return;
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
  };

  // useEffect

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);

  useEffect(() => {
    const fetchAll = async () => {
      await Promise.all([fetchData()]);
    };
    fetchAll();
  }, [pagination.pageIndex, pagination.pageSize, closeViewStatus]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      setHeight(window.innerHeight - (230 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);
  // modal
  const deletesData = (ids: string[]) => {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Xác nhận xóa!</Title>
        </>
      ),
      children: (
        <DeleteProposalView onClose={setCloseViewStatus} idItems={ids} />
      ),
      size: "550px",
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };

  const updateStatusItem = (ids: string[], mode: "approve" | "reject") => {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Cập nhật trạng thái đề xuất!</Title>
        </>
      ),
      size: "auto",
      children: (
        <UpdateStatusView
          mode={mode}
          onClose={setCloseViewStatus}
          idItems={ids}
        />
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };

  const approveWithStatus = (row: TblDMApproveProposal) => {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Duyệt phiết đề xuất!</Title>
        </>
      ),
      size: "auto",
      children: (
        <ApproveWithResponseView row={row} onClose={setCloseViewStatus} />
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };

  //table

  const table = useMantineReactTable({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    //add custom action buttons to top-left of top toolbar
    renderTopToolbarCustomActions: ({ table }) => (
      <div ref={headerRef}>
        <Flex
          gap="md"
          justify="flex-start"
          align="center"
          direction="row"
          wrap="wrap"
        >
          <Input
            placeholder="Nhập từ khóa ..."
            value={keySearch}
            onChange={(e) => setKeySearch(e.target.value)}
          />
          <Button
            rightSection={<IconSearch size={18}></IconSearch>}
            variant="filled"
            color="blue"
            onClick={async () => {
              await fetchData();
            }}
          >
            Tìm
          </Button>
        </Flex>
      </div>
    ),
    renderToolbarInternalActions: ({ table }) => (
      <>
        <Flex
          gap="md"
          justify={{ sm: "flex-start", lg: "flex-end" }}
          align="center"
          direction="row"
          wrap="wrap"
        >
          <Button
            leftSection={<IconPlus size={14} />}
            color="blue"
            variant="outline"
            onClick={() => {
              navigate("/proposal/create-proposal");
            }}
          >
            Thêm mới
          </Button>
          {/* <Button
            leftSection={<IconTrash size={14} />}
            color="red"
            variant="outline"
            onClick={() => {
              const validIds = selectIds.filter((id) => {
                const item = data.find((i) => Number(i.id) === Number(id));
                if (!item) return false;
                return !checkEditEnabled(item);
              });
              if (validIds.length === 0) {
                NotificationExtension.Warn(
                  "Đề xuất đã duyệt hoặc không có quyền xoá!"
                );
                table.resetRowSelection();
                return;
              }
              deletesData(validIds);
            }}
            disabled={selectIds.length < 1}
          >
            Xóa (đã chọn)
          </Button>

          <Button
            leftSection={<IconCheck size={14} />}
            color="green"
            variant="outline"
            onClick={() => {
              const validIds = selectIds.filter((id) => {
                const item = data.find((i) => Number(i.id) === Number(id));
                if (item?.active) return false;
                return (
                  item?.idEmployeeReceive ===
                  Number(localStorage.getItem("empId") ?? 0)
                );
              });
              if (validIds.length === 0) {
                NotificationExtension.Warn(
                  "Đề xuất đã duyệt hoặc không có quyền duyệt!"
                );
                table.resetRowSelection();
                return;
              }
              updateStatusItem(validIds, "approve");
            }}
            disabled={selectIds.length < 1}
          >
            Phê duyệt (đã chọn)
          </Button>
          <Button
            leftSection={<IconHandStop size={14} />}
            color="red"
            variant="outline"
            onClick={() => {
              const validIds = selectIds.filter((id) => {
                const item = data.find((i) => Number(i.id) === Number(id));
                if (item?.active) return false;
                return (
                  item?.idEmployeeReceive ===
                  Number(localStorage.getItem("empId") ?? 0)
                );
              });
              if (validIds.length === 0) {
                NotificationExtension.Warn(
                  "Đề xuất đã duyệt hoặc không có quyền từ chối!"
                );
                table.resetRowSelection();
                return;
              }
              updateStatusItem(validIds, "reject");
            }}
            disabled={selectIds.length < 1}
          >
            Từ chối (đã chọn)
          </Button> */}

          <Menu shadow="md" width={200}>
            <Menu.Target>
              <Button
                // size="xs"
                rightSection={
                  <IconCaretDown style={{ width: rem(14), height: rem(14) }} />
                }
              >
                Chức năng
              </Button>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Label>Application</Menu.Label>
              <Menu.Item
                leftSection={
                  <IconFileExport style={{ width: rem(14), height: rem(14) }} />
                }
                onClick={handleExport}
              >
                Export Excel
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Flex>
      </>
    ),

    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    // enableRowSelection: true,
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: false,
      pagination,
      columnPinning: {
        left: ["mrt-row-select", "code"],
        right: ["active", "action"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },

    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,

    onRowSelectionChange: setRowSelection,
    // manualFiltering: true,
    manualPagination: true,
    // manualSorting: true,
    rowCount,
    // onColumnFiltersChange: setColumnFilters,
    // onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    // onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        fontWeight: "700", //conditional styling
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no data
      //sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["10", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: true,
    },
    columnFilterDisplayMode: "popover",
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });
  return (
    <>
      <MantineReactTable table={table} />
    </>
  );
};
