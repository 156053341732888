import {
  Box,
  Group,
  LoadingOverlay,
  NumberFormatter,
  Paper,
  Text,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { modals } from "@mantine/modals";
import { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { repositoryPayment } from "../../../../_base/_const/_constVar";
import "../../../../Styles/tab.component.css";

const bankOptions = [
  { value: "", label: "Không chọn" },
  // { value: "QRONLY", label: "Thanh toan QRONLY" },
  // { value: "MBAPP", label: "Ứng dụng MobileBanking" },
  // { value: "VNPAYQR", label: "VNPAYQR" },
  // { value: "VNBANK", label: "LOCAL BANK" },
  // { value: "IB", label: "INTERNET BANKING" },
  // { value: "ATM", label: "ATM CARD" },
  // { value: "INTCARD", label: "INTERNATIONAL CARD" },
  // { value: "VISA", label: "VISA" },
  // { value: "MASTERCARD", label: "MASTERCARD" },
  // { value: "JCB", label: "JCB" },
  // { value: "UPI", label: "UPI" },
  // { value: "VIB", label: "VIB" },
  // { value: "VIETCAPITALBANK", label: "VIETCAPITALBANK" },
  // { value: "SCB", label: "Ngân hàng SCB" },
  { value: "NCB", label: "Ngân hàng NCB" },
  { value: "SACOMBANK", label: "Ngân hàng SacomBank" },
  { value: "EXIMBANK", label: "Ngân hàng EximBank" },
  { value: "MSBANK", label: "Ngân hàng MSBANK" },
  { value: "NAMABANK", label: "Ngân hàng NamABank" },
  { value: "VNMART", label: "Ví điện tử VnMart" },
  { value: "VIETINBANK", label: "Ngân hàng Vietinbank" },
  { value: "VIETCOMBANK", label: "Ngân hàng VCB" },
  { value: "HDBANK", label: "Ngân hàng HDBank" },
  { value: "DONGABANK", label: "Ngân hàng Dong A" },
  { value: "TPBANK", label: "Ngân hàng TPBank" },
  { value: "OJB", label: "Ngân hàng OceanBank" },
  { value: "BIDV", label: "Ngân hàng BIDV" },
  { value: "TECHCOMBANK", label: "Ngân hàng Techcombank" },
  { value: "VPBANK", label: "Ngân hàng VPBank" },
  { value: "AGRIBANK", label: "Ngân hàng Agribank" },
  { value: "MBBANK", label: "Ngân hàng MBBank" },
  { value: "ACB", label: "Ngân hàng ACB" },
  { value: "OCB", label: "Ngân hàng OCB" },
  { value: "IVB", label: "Ngân hàng IVB" },
  { value: "SHB", label: "Ngân hàng SHB" },
  { value: "APPLEPAY", label: "Apple Pay" },
  { value: "GOOGLEPAY", label: "Google Pay" },
];

const submitMBPayment = async (setIsError: Function) => {
  const dataMB = {
    terminalID: "MB_HACOM10",
    qrcodeType: 4,
    parterType: 2,
    initMethod: 14,
    transactionAmount: 1000001,
    billNumber: "001",
    referenceLabelTime: "",
    ordercode: "000002",
    transactionPurpose: "string",
    additionAddress: "string",
    additionMobile: "string",
    additionEmail: "string",
    qrcodevalue: "string",
  };

  try {
    const response = await repositoryPayment.post(
      `api/TblMBQRcode/gen-qr-mb`,
      dataMB
    );
    if (response && response.success) {
      return response.data.qrcodevalue; // Assuming the QR code data is in response.data.qrcodevalue
    }
  } catch (error) {
    setIsError(true);
    console.error(error);
  }
};

export const PaymentMethodMb = ({
  price,
  productCode,
  setPaymentDone,
  paymentDone,
  orderNumber,
}: {
  setPaymentDone: any;
  paymentDone: any;
  price: number;
  productCode: string;
  orderNumber: string;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [qrCode, setQrCode] = useState<string | null>(null);
  const [redirectLink, setRedirectLink] = useState(null);
  const [isLoadingCricle, setIsLoadingCricle] = useState(true);
  const form = useForm({
    initialValues: {
      amount: price,
      orderDescription: "",
      bankCode: "",
      language: "VN",
    },
    validate: {
      amount: (value) => (value ? null : "Vui lòng nhập số tiền"),
      bankCode: (value) => (value ? null : "Vui lòng chọn ngân hàng"),
    },
  });

  const handleSubmitMB = async () => {
    if (qrCode) {
      modals.closeAll();
      setPaymentDone([...paymentDone, 72]);
    } else {
      setIsLoading(true);
      const dataQrCode = await submitMBPayment(setIsError);
      if (dataQrCode) {
        setQrCode(dataQrCode);
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (redirectLink) {
      const timer = setTimeout(() => {
        setIsLoadingCricle(false);
        window.open(redirectLink, "_blank");
      }, 3000);

      // Cleanup the timer on component unmount
      return () => clearTimeout(timer);
    }
  }, [redirectLink]);

  useEffect(() => {
    handleSubmitMB();
  }, []);
  return (
    <Box component="form" mx="auto" style={{ position: "relative" }}>
      <LoadingOverlay
        visible={isLoading}
        zIndex={1000}
        overlayProps={{ radius: "sm", blur: 2 }}
      />
      {qrCode && (
        <Group
          align="center"
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
          }}
        >
          <Paper
            withBorder
            shadow="md"
            p="lg"
            radius="md"
            style={{
              backgroundColor: "white",
              maxWidth: "400px", // Set a max-width for better responsiveness
              width: "100%",
              textAlign: "center",
            }}
          >
            <Text mb={5}>Quý khách vui lòng quét mã để thanh toán</Text>
            <Text fw={"500"}>
              {`Số tiền thanh toán: `}
              <NumberFormatter
                style={{ color: "red" }}
                value={price}
                thousandSeparator=","
                // suffix=" ₫"
              />
            </Text>

            <Text fw={"500"}>
              {`Nội dung: ${orderNumber}(Thanh toan truoc)`}
            </Text>

            <Text fw={"500"}>Số tài khoản: xxxxxxxxxxx</Text>
            <Text fw={"500"} mb={15}>
              Tên ngân hàng: MB Bank
            </Text>

            <QRCode size={200} value={qrCode} />
          </Paper>
        </Group>
      )}

      <Group
        justify="end"
        mt="md"
        style={{
          position: "sticky",
          bottom: 0,
          backgroundColor: "white",
        }}
      ></Group>
    </Box>
  );
};
