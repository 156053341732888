import { Box, Fieldset, Grid, TextInput } from "@mantine/core";
import { formatDateTransfer } from "../../../../common/FormatDate/FormatDate";

type Props = {
  data: any;
};

const ProductionOrderDetail = ({ data }: Props) => {
  return (
    <div>
      <Box>
        <Grid>
          <Grid.Col span={12}>
            <Fieldset legend="Thông tin về mã lệnh">
              <Grid>
                <Grid.Col span={{ base: 12, md: 4, lg: 4 }}>
                  <TextInput
                    label="Mã lệnh"
                    size="xs"
                    defaultValue={data?.soCt}
                    variant="filled"
                    readOnly
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 4, lg: 4 }}>
                  <TextInput
                    label="Mã công ty"
                    size="xs"
                    defaultValue={data?.maCty || ""}
                    readOnly
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 4, lg: 4 }}>
                  <TextInput
                    label="Mã chứng từ"
                    size="xs"
                    defaultValue={data?.maCt || ""}
                    readOnly
                  />
                </Grid.Col>
                {/* <Grid.Col span={{ base: 12, md: 4, lg: 4 }}>
                  <TextInput
                    label="Số chứng từ"
                    size="xs"
                    defaultValue={data?.soCt || ""}
                    readOnly
                  />
                </Grid.Col>{" "} */}
                <Grid.Col span={{ base: 12, md: 4, lg: 4 }}>
                  <TextInput
                    label="Ngày lập chứng từ"
                    size="xs"
                    defaultValue={formatDateTransfer(data?.ngayCt || "")}
                    readOnly
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 4, lg: 4 }}>
                  <TextInput
                    label="Ngày dự kiến sản xuất"
                    size="xs"
                    defaultValue={formatDateTransfer(data?.ngayDksx || "")}
                    readOnly
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 4, lg: 4 }}>
                  <TextInput
                    label="Trạng thái"
                    size="xs"
                    defaultValue={data?.tenTrangThai || ""}
                    readOnly
                  />
                </Grid.Col>{" "}
                <Grid.Col span={{ base: 12, md: 4, lg: 4 }}>
                  <TextInput
                    label="Số lượng"
                    size="xs"
                    defaultValue={data?.tSoLuong || ""}
                    readOnly
                  />
                </Grid.Col>{" "}
                <Grid.Col span={{ base: 12 }}>
                  <TextInput
                    label="Diễn giải"
                    size="xs"
                    defaultValue={data?.dienGiai || ""}
                    readOnly
                  />
                </Grid.Col>{" "}
              </Grid>
            </Fieldset>
            <Fieldset legend="Thông tin khách hàng">
              <Grid>
                <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                  <TextInput
                    label="Tên khách hàng"
                    size="xs"
                    defaultValue={data?.tenKh || ""}
                    readOnly
                  />
                </Grid.Col>{" "}
                <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                  <TextInput
                    label="Người giao dịch"
                    size="xs"
                    defaultValue={data?.nguoiGd || ""}
                    readOnly
                  />
                </Grid.Col>
              </Grid>
            </Fieldset>
            <Fieldset legend="Thời gian và người phụ trách">
              <Grid>
                <Grid.Col span={{ base: 12, md: 3, lg: 3 }}>
                  <TextInput
                    label="Ngày tạo "
                    size="xs"
                    defaultValue={formatDateTransfer(data?.cdate || "")}
                    readOnly
                  />
                </Grid.Col>{" "}
                <Grid.Col span={{ base: 12, md: 3, lg: 3 }}>
                  <TextInput
                    label="Người tạo"
                    size="xs"
                    defaultValue={data?.cuser ?? ""}
                    readOnly
                  />
                </Grid.Col>{" "}
                <Grid.Col span={{ base: 12, md: 3, lg: 3 }}>
                  <TextInput
                    label="Ngày cập nhật "
                    size="xs"
                    defaultValue={formatDateTransfer(data?.ldate || "")}
                    readOnly
                  />
                </Grid.Col>{" "}
                <Grid.Col span={{ base: 12, md: 3, lg: 3 }}>
                  <TextInput
                    label="Người cập nhật"
                    size="xs"
                    defaultValue={data?.luser || ""}
                    readOnly
                  />
                </Grid.Col>
              </Grid>
            </Fieldset>
          </Grid.Col>
        </Grid>
      </Box>
    </div>
  );
};

export default ProductionOrderDetail;
