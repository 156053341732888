import Repository from "../_base/helper/HttpHelper";
import { MessageResponse } from "../model/MessageResponse";
import { SelectListItem } from "../model/SelectListItem";
import { TreeChartModel } from "../model/TreeChartModel";

export async function getUsersSelect() {
  const repository = new Repository(process.env.REACT_APP_Demo_APP_API_URL);
  let url = "/select/users";
  let callapi = await repository.get<MessageResponse<SelectListItem[]>>(url);
  if (callapi?.success) return callapi?.data;
  return [];
}

export async function getProcessDefSelect() {
  const repository = new Repository(process.env.REACT_APP_Demo_APP_API_URL);
  let url = "/select/process_def";
  let callapi = await repository.get<MessageResponse<SelectListItem[]>>(url);
  if (callapi?.success) return callapi?.data;
  return [];
}

export async function getOrganizationsSelect() {
  const repository = new Repository(process.env.REACT_APP_Demo_APP_API_URL);
  let url = "/select/organizations";
  let callapi = await repository.get<MessageResponse<SelectListItem[]>>(url);
  if (callapi?.success) return callapi?.data;
  return [];
}
export async function getOrganizationsChartTree() {
  const repository = new Repository(process.env.REACT_APP_Demo_APP_API_URL);
  let url = "/select/organizations-tree";
  let callapi = await repository.get<MessageResponse<TreeChartModel[]>>(url);
  if (callapi?.success) return callapi?.data;
  return [];
}
export async function getEcmClassSelect() {
  const repository = new Repository(process.env.REACT_APP_Demo_APP_API_URL);
  let url = "/select/ecm-class";
  let callapi = await repository.get<MessageResponse<SelectListItem[]>>(url);
  if (callapi?.success) return callapi?.data;
  return [];
}
export async function getPartnersSelect() {
  const repository = new Repository(process.env.REACT_APP_Demo_APP_API_URL);
  let url = "/select/partners";
  let callapi = await repository.get<MessageResponse<SelectListItem[]>>(url);
  if (callapi?.success) return callapi?.data;
  return [];
}
