import {
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import * as React from "react";
import { useEffect, useState } from "react";
import {
  _timerDelayClick,
  repositoryDelivery,
} from "../../../../_base/_const/_constVar";
import { _localization } from "../../../../config/location";
import { MessageResponse } from "../../../../model/MessageResponse";
import "../../../../Styles/tab.component.css";
import _breadcrumb from "../../../../_base/component/_layout/_breadcrumb";
import { TblDMSupplies } from "../../../../model/TblDMSupplies";
import { paginationBase } from "../../../../_base/model/_base/BaseTable";
import {
  Badge,
  Box,
  Tooltip,
  Text,
  Grid,
  TextInput,
  Button,
} from "@mantine/core";
import { IconSearch } from "@tabler/icons-react";
import { green } from "../../../../const/variables";

const DMSupplies = () => {
  //data and fetching state
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<TblDMSupplies[]>([]);
  const [, setDataTemp] = useState<TblDMSupplies[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);
  const [pagination] = useState(paginationBase);
  const [keySearch, setKeySearch] = useState("");
  //table state
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [selectIds, setSelectIds] = useState<string[]>([]);
  const [deleteViewStatus, setDeleteViewStatus] = useState(false);
  //count
  const [timeLeft, setTimeLeft] = useState(60);
  const [isCounting, setIsCounting] = useState(false);

  const columns = React.useMemo<MRT_ColumnDef<TblDMSupplies>[]>(
    () => [
      {
        accessorKey: "maVt",
        header: "Mã vật tư",
        Cell: ({ renderedCellValue, cell }) => (
          <Badge
            radius="sm"
            variant="dot"
            size="lg"
            color={renderedCellValue === null ? "red" : green.base}
          >
            {renderedCellValue === null ? "Không xác định" : renderedCellValue}
          </Badge>
        ),
      },
      {
        accessorKey: "tenVt",
        header: "Tên vật tư",
        Cell: ({ row }) => (
          <Box w={350}>
            <Tooltip label={row.original.tenVt}>
              <Text size="12.5px" fw={500} truncate="end" h={15}>
                {row.original.tenVt}
              </Text>
            </Tooltip>
          </Box>
        ),
      },
      {
        accessorKey: "maCty",
        header: "Mã công ty",
      },
      {
        accessorKey: "loai",
        header: "Loại",
      },
      {
        accessorKey: "partNo",
        header: "Part Number",
      },
      {
        accessorKey: "dvt",
        header: "Đơn vị tính",
      },
      {
        accessorKey: "dvtBan",
        header: "Đơn vị tính bán",
      },
      {
        accessorKey: "hsDvtban",
        header: "Hệ số đơn vị tính bán",
        size: 250,
      },
      {
        accessorKey: "dvtMua",
        header: "Đơn vị tính mua",
      },
      {
        accessorKey: "hsDvtmua",
        header: "Hệ số đơn vị tính mua",
        size: 250,
      },
      {
        accessorKey: "tonKho",
        header: "Tồn kho",
      },
      {
        accessorKey: "giaTon",
        header: "Giá tồn kho",
      },
      {
        accessorKey: "tkVt",
        header: "TK vật tư",
      },
      {
        accessorKey: "tkDt",
        header: "TK doanh thu",
      },
      {
        accessorKey: "tkDtnb",
        header: "TK doanh thu nội bộ",
      },
      {
        accessorKey: "tkGv",
        header: "TK giá vốn",
      },
      {
        accessorKey: "tkTl",
        header: "TK trả lại",
      },
      {
        accessorKey: "tkCk",
        header: "TK chiết khấu",
      },
      {
        accessorKey: "tkKm",
        header: "TK khuyến mại",
      },
      {
        accessorKey: "tkDd",
        header: "TK dở dang",
      },
      {
        accessorKey: "tkCpnvl",
        header: "TK chi phí NVL",
      },
      {
        accessorKey: "maThue",
        header: "Mã thuế",
      },
      {
        accessorKey: "tinhGt",
        header: "Tính giá thành",
      },
      {
        accessorKey: "maNhvt",
        header: "Mã nhóm vật tư",
      },
      {
        accessorKey: "tenNhvt",
        header: "Tên nhóm vật tư",
      },
      {
        accessorKey: "maPlvt1",
        header: "Mã PL vật tư 1",
      },
      {
        accessorKey: "tenPlvt1",
        header: "Tên PL vậy tư 1",
      },
      {
        accessorKey: "maPlvt2",
        header: "Mã PL vật tư 2",
      },
      {
        accessorKey: "tenPlvt2",
        header: "Tên PL vật tư 2",
      },
      {
        accessorKey: "maPlvt3",
        header: "Mã PL vật tư 3",
      },
      {
        accessorKey: "tenPlvt3",
        header: "Tên PL vật tư 3",
      },
      {
        accessorKey: "maNhvat",
        header: "Mã nhóm VAT",
      },
      {
        accessorKey: "slMin",
        header: "Số lượng tồn tối thiểu",
        size: 225,
      },
      {
        accessorKey: "slMax",
        header: "Số lượng tồn tối đa",
      },
      {
        accessorKey: "tsNk",
        header: "Thuế xuất nhập khẩu",
      },
      {
        accessorKey: "tsGtgt",
        header: "Thuế xuất GTGT",
      },
      {
        accessorKey: "tsXk",
        header: "Thuế suất xuất khẩu",
      },
      {
        accessorKey: "tsTtdb",
        header: "Thuế xuất tiêu thụ đặc biệt",
        size: 250,
      },
      {
        accessorKey: "maKho",
        header: "Mã kho",
      },
      {
        accessorKey: "tenKho",
        header: "Tên kho",
      },
      {
        accessorKey: "maVitri",
        header: "Mã vị trí",
      },
      {
        accessorKey: "tenVitri",
        header: "Tên vị trí",
      },
      {
        accessorKey: "ghiChu",
        header: "Ghi chú",
      },
      {
        accessorKey: "nhaSx",
        header: "Nhà sản xuất",
      },
      {
        accessorKey: "nhaPp",
        header: "Nhà phân phối",
      },
      {
        accessorKey: "nuocSx",
        header: "Nước sản xuất",
      },
      {
        accessorKey: "tgbhNcc",
        header: "Thời hạn bảo hành NCC",
        size: 250,
      },
      {
        accessorKey: "tgbhHnc",
        header: "Thời hạn bảo hành của HNC",
        size: 250,
      },
      {
        accessorKey: "bhTaihang",
        header: "Bảo hành tại hãng",
      },
      {
        accessorKey: "xuatAm",
        header: "Xuất âm",
      },
      {
        accessorKey: "khongNl",
        header: "Không nhập lại",
      },
      {
        accessorKey: "trungMv",
        header: "Trùng mã vạch",
      },
      {
        accessorKey: "noSeri",
        header: "No Seri",
      },
      {
        accessorKey: "chieuDai",
        header: "Chiều dài",
      },
      {
        accessorKey: "chieuRong",
        header: "Chiều rộng",
      },
      {
        accessorKey: "chieuCao",
        header: "Chiều cao",
      },
      {
        accessorKey: "trongLuong",
        header: "Trọng lượng",
      },
      {
        accessorKey: "theTich",
        header: "Thể tích",
      },
      {
        accessorKey: "bom",
        header: "BOM của vật tư",
      },
      {
        accessorKey: "ksd",
        header: "Không sử dụng",
      },
      {
        accessorKey: "cdate",
        header: "Ngày tạo",
      },
      {
        accessorKey: "cuser",
        header: "Người tạo",
      },
      {
        accessorKey: "ldate",
        header: "Ngày sửa cuối",
      },
      {
        accessorKey: "luser",
        header: "Người sửa cuối",
      },
    ],
    []
  );

  const startCountdown = () => {
    if (!isCounting) {
      setTimeLeft(_timerDelayClick);
      setIsCounting(true);
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);

    let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }`;

    if (keySearch) {
      url += `&KeySearch=${keySearch}`;
    }

    try {
      const response = await repositoryDelivery.get<
        MessageResponse<TblDMSupplies[]>
      >(`/api/v1/SyncDataFromAsia/get-list-danh-muc-vat-tu${url}`);
      if (response && response.success) {
        let result = response.data;
        setData(result);
        setDataTemp(response.data);
        setRowCount(result.length);
        setSelectIds([]);
        table.resetRowSelection();
      }
    } catch (error) {
      setData([]);
      setIsError(true);
      console.error(error);
      return;
    }
    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
    startCountdown();
  };

  useEffect(() => {
    let timer: ReturnType<typeof setInterval>;
    if (isCounting && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsCounting(false);
    }
    return () => clearInterval(timer);
  }, [isCounting, timeLeft]);

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);

  useEffect(() => {
    fetchData();
  }, [deleteViewStatus]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      setHeight(window.innerHeight - (265 + headerHeight));
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const table = useMantineReactTable({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    columnFilterDisplayMode: "popover",
    renderTopToolbarCustomActions: ({ table }) => (
      <div ref={headerRef} style={{ width: "100%" }}>
        <Grid>
          <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
            <TextInput
              placeholder="Nhập từ khóa ..."
              leftSection={<IconSearch color="#15aabf" />}
              value={keySearch}
              onChange={(e) => setKeySearch(e.currentTarget.value)}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
            <Button
              leftSection={<IconSearch size={14} />}
              color="blue"
              variant="outline"
              onClick={async () => {
                await fetchData();
              }}
            >
              Tìm kiếm
            </Button>
          </Grid.Col>
        </Grid>
      </div>
    ),
    renderToolbarInternalActions: ({ table }) => <></>,

    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    enableRowSelection: false,
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["maVt"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    rowCount,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontWeight: "500",
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      isLoading,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["10", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });

  return (
    <>
      <MantineReactTable table={table} />
    </>
  );
};

export default DMSupplies;
