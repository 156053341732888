import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Flex,
  Input,
  Title,
  Tooltip,
} from "@mantine/core";
import { modals } from "@mantine/modals";
import {
  IconActivity,
  IconEdit,
  IconHandStop,
  IconPlus,
  IconTrash,
  IconZoomReset,
} from "@tabler/icons-react";
import {
  MRT_ColumnDef,
  MRT_PaginationState,
  MRT_RowSelectionState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { NotificationExtension } from "../../_base/extension/NotificationExtension";
import Repository from "../../_base/helper/HttpHelper";
import { _localization } from "../../config/location";

import { MessageResponse } from "../../model/MessageResponse";
import "../../Styles/tab.component.css";
import {
  _timerDelayClick,
  repositoryMaster,
  repositoryPos,
} from "../../_base/_const/_constVar";

import { useDebouncedState } from "@mantine/hooks";
import EmployeeEdit from "./EmployeeEdit";
import { DepartmentModel } from "../../model/DepartmentModel";
import _breadcrumb from "../../_base/component/_layout/_breadcrumb";

const DepartmentView = () => {
  //#region  table
  const headerRef = React.useRef<HTMLDivElement>(null);
  //data and fetching state
  const [data, setData] = useState<DepartmentModel[]>([]);
  const [dataTemp, setDataTemp] = useState<DepartmentModel[]>([]);

  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  //
  const dataStatus = [
    { value: "1", label: "Đang duyệt" },
    { value: "2", label: "Đã duyệt" },
    { value: "3", label: "Từ chối" },
    { value: "4", label: "Hủy duyệt" },
  ];

  const [isFrist, SetIsFrist] = useState<boolean>(false);

  const [keySearch, setKeySearch] = useState<string>("");

  const [selectIds, setSelectIds] = useState<string[]>([]);

  const [deleteViewStatus, setDeleteViewStatus] = useState(0);
  //count
  const [timeLeft, setTimeLeft] = useState(60);
  const [isCounting, setIsCounting] = useState(false);

  useEffect(() => {
    let timer: ReturnType<typeof setInterval>;
    if (isCounting && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsCounting(false);
    }
    return () => clearInterval(timer);
  }, [isCounting, timeLeft]);

  const startCountdown = () => {
    if (!isCounting) {
      setTimeLeft(_timerDelayClick);
      setIsCounting(true);
    }
  };

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);

  useEffect(() => {
    fetchData();
  }, [deleteViewStatus]);

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setOpenedMenu(false);
    try {
      const response = await repositoryPos.get<
        MessageResponse<DepartmentModel[]>
      >("/api/v1/TblDepartment/get-all");
      if (response && response.success) {
        const treeData = response.data;
        setData(treeData);
        setRowCount(response.totalCount);
        setDataTemp(treeData);
        setSelectIds([]);
        table.resetRowSelection();
      }
    } catch (error) {
      setIsError(true);
      console.error(error);
      return;
    }
    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
    startCountdown();
  };

  const activeData = async (status: boolean) => {
    if (selectIds.length < 1)
      NotificationExtension.Success("Bạn chưa chọn dữ liệu !");
    else
      try {
        const res = await repositoryPos.post<MessageResponse<boolean>>(
          "/api/v1/TblDepartment/actives",
          {
            ids: selectIds,
            status: status,
          }
        );
        console.log(res);
        if (res && res.success) {
          NotificationExtension.Success("Cập nhật thành công !");
          await fetchData();
        } else NotificationExtension.Fails("Cập nhật thất bại !");
      } catch (error) {
        console.error(error);
        return;
      }
  };
  const activesData = async (status: boolean, id: number) => {
    if (id < 1) NotificationExtension.Success("Bạn chưa chọn dữ liệu !");
    else
      try {
        const res = await repositoryPos.post<MessageResponse<boolean>>(
          "/api/v1/TblDepartment/actives",
          {
            ids: [id],
            status: status,
          }
        );
        if (res && res.success) {
          NotificationExtension.Success("Cập nhật thành công !");
          await fetchData();
        } else NotificationExtension.Fails("Cập nhật thất bại !");
      } catch (error) {
        console.error(error);
        return;
      }
  };
  const deletesData = async (ids: string[]) => {
    if (ids.length < 1)
      NotificationExtension.Success("Bạn chưa chọn dữ liệu !");
    else
      try {
        modals.openConfirmModal({
          title: (
            <>
              <Title order={5}>Xóa !</Title>
            </>
          ),

          size: "550px",
          children: <>Bạn có thực sự muốn xóa ?</>,
          labels: { confirm: "Lưu lại", cancel: "Hủy bỏ" },
          onConfirm: async () => {
            const res = await repositoryPos.post<MessageResponse<boolean>>(
              "/api/v1/TblDepartment/delete",
              ids
            );
            if (res)
              if (res.success) {
                NotificationExtension.Success("Xóa thành công !");
                await fetchData();
              } else NotificationExtension.Fails("Xóa thất bại !");
          },
        });
      } catch (error) {
        console.error(error);
        return;
      }
  };

  function getColorStatus(text: boolean) {
    switch (text) {
      case true:
        return "teal";
      default:
        return "red";
    }
  }

  const columns = React.useMemo<MRT_ColumnDef<DepartmentModel>[]>(
    () => [
      {
        accessorKey: "id",
        header: "Id",
      },
      {
        accessorKey: "departmentId",
        header: "Mã phòng ban",
        // Cell: ({ renderedCellValue, row }) => (
        //   <>
        //     {row && row.original?.parentId && row.original?.parentId?.length > 0
        //       ? "----- " + renderedCellValue
        //       : renderedCellValue}
        //   </>
        // ),
      },
      {
        accessorKey: "name",
        header: "Tên phòng ban",
        // Cell: ({ renderedCellValue, row }) => (
        //   <>
        //     {row && row.original?.parentId && row.original?.parentId?.length > 0
        //       ? "----- " + renderedCellValue
        //       : renderedCellValue}
        //   </>
        // ),
      },

      {
        accessorKey: "active",
        header: "Trạng thái",
        Cell: ({ renderedCellValue, row }) => (
          <>
            <Badge color={getColorStatus(row.original.active ?? false)}>
              {row.original.active ? "Đang hoạt động" : "Dừng hoạt động"}
            </Badge>
          </>
        ),
      },

      {
        accessorKey: "description",
        header: "Ghi chú",
        //  enableGrouping: true,
        //   size: 50,
      },
      {
        accessorKey: "action",
        header: "Thao tác",
        Cell: ({ renderedCellValue, row, table }) => (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "16px",
            }}
          >
            <Tooltip
              label={
                row.original.active === true ? "Dừng hoạt động" : "Hoạt động"
              }
            >
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color={row.original.active === true ? "violet" : "green"}
                onClick={async () => {
                  await activesData(
                    !row.original.active,
                    row.original.departmentId
                  );
                }}
              >
                {row.original.active === true ? (
                  <IconHandStop size={20} />
                ) : (
                  <IconActivity size={20} />
                )}
              </ActionIcon>
            </Tooltip>

            <Tooltip label="Xóa">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="red"
                onClick={async () => {
                  await deletesData([row.original.departmentId.toString()]);
                }}
              >
                <IconTrash size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
          </Box>
        ),
      },
    ],
    []
  );

  const [openedMenu, setOpenedMenu] = useState(false);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 160 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (200 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  const [valueDebounced, setValueDebounced] = useDebouncedState("", 500);
  useEffect(() => {
    if (valueDebounced && valueDebounced.length > 0) {
      const keySearch1 = valueDebounced.toLowerCase(); // Chuyển đổi từ khóa tìm kiếm thành chữ thường

      const searchResult = dataTemp.filter(
        (product) => product.name?.toLowerCase().includes(keySearch1)
        //   product.description?.toLowerCase().includes(keySearch1) ||
        //   product.email?.toLowerCase().includes(keySearch1) ||
        //   product.phoneNumber?.toLowerCase().includes(keySearch1) ||
        //   product.code?.toLowerCase().includes(keySearch1) ||
        //   product.departmentName?.toLowerCase().includes(keySearch1) ||
        //   product.roleName?.toLowerCase().includes(keySearch1) ||
        //   product.id?.toString().toLowerCase().includes(keySearch1)
      );
      setData(searchResult);
    } else setData(dataTemp);
  }, [valueDebounced]);

  const table = useMantineReactTable({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    //add custom action buttons to top-left of top toolbar
    // renderTopToolbarCustomActions: ({ table }) => (
    //   <div ref={headerRef}>
    //     <_breadcrumb></_breadcrumb>
    //     <Flex
    //       gap="md"
    //       justify="flex-start"
    //       align="center"
    //       direction="row"
    //       wrap="wrap"
    //     >
    //       <Input
    //         placeholder="Nhập từ khóa ..."
    //         onChange={async (e) => {
    //           const _key = e.target.value ?? "";
    //           // if (_key.length >= 3)
    //           setValueDebounced(_key);
    //         }}
    //       />
    //     </Flex>
    //   </div>
    // ),

    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    enableRowSelection: true,
    getRowId: (row) => row.departmentId?.toString(),
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select"],
        right: ["action"],
      },
      columnVisibility: { id: false },
      density: "xs",
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    // manualFiltering: true,
    // manualPagination: true,
    // manualSorting: true,
    rowCount,
    // onColumnFiltersChange: setColumnFilters,
    // onGlobalFilterChange: setGlobalFilter,
    // onPaginationChange: setPagination,
    // onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        fontWeight: "bold", //conditional styling
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      //  pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no data
      //sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["50", "100", "150"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: false,
    mantineTableProps: {
      striped: false,
    },
  });

  return (
    <>
      <div
        style={{ border: "1px solid #dee2e6", padding: "5px 10px" }}
        ref={headerRef}
      >
        <Flex
          gap="md"
          justify={"space-between"}
          align="center"
          direction="row"
          wrap="wrap"
        >
          <Flex>
            <_breadcrumb></_breadcrumb>
          </Flex>
          <Flex gap={20}>
            <Button
              leftSection={<IconActivity size={14} />}
              color="green"
              variant="outline"
              onClick={async () => {
                await activeData(true);
              }}
              disabled={selectIds.length < 1}
            >
              Hoạt động
            </Button>
            <Button
              leftSection={<IconHandStop size={14} />}
              color="red"
              variant="outline"
              onClick={async () => {
                await activeData(false);
              }}
              disabled={selectIds.length < 1}
            >
              Dừng hoạt động
            </Button>
            <Button
              leftSection={<IconTrash size={14} />}
              color="red"
              variant="outline"
              onClick={async () => {
                await deletesData(selectIds);
              }}
              disabled={selectIds.length < 1}
            >
              Xóa (đã chọn)
            </Button>
            <Button
              leftSection={<IconZoomReset size={14} />}
              color="violet"
              variant="outline"
              onClick={async () => {
                await fetchData();
              }}
              disabled={isCounting}
            >
              {timeLeft > 0 && timeLeft} Load data
            </Button>
          </Flex>
        </Flex>
        <Flex>
          <Input
            placeholder="Nhập từ khóa ..."
            onChange={async (e) => {
              const _key = e.target.value ?? "";
              // if (_key.length >= 3)
              setValueDebounced(_key);
            }}
          />
        </Flex>
      </div>
      <div className="mt-5">
        <MantineReactTable table={table} />
      </div>
    </>
  );
};

export default DepartmentView;
