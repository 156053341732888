import {
  Badge,
  Box,
  NumberFormatter,
  Select,
  Text,
  TextInput,
} from "@mantine/core";
import {
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import * as React from "react";
import { useEffect, useState } from "react";
import { _timerDelayClick } from "../../_base/_const/_constVar";
import { _localization } from "../../config/location";
import { paginationBase } from "../../_base/model/_base/BaseTable";
import { green } from "../../const/variables";
import { UseFormReturnType } from "@mantine/form";
import { UpdateStatusDetails } from "./ModalUpdateTestingTechnique";

const ListProductDetail = ({
  dataDetail,
  isDetail,
  form,
  height,
}: {
  dataDetail: any;
  isDetail: boolean;
  form?: UseFormReturnType<
    UpdateStatusDetails,
    (values: UpdateStatusDetails) => UpdateStatusDetails
  >;
  height?: number;
}) => {
  const testingStatuss = React.useMemo(
    () => [
      {
        label: "Test ok",
        value: "TEST_OK",
      },
      {
        label: "Test lỗi",
        value: "TEST_LOI",
      },
    ],
    []
  );

  const testDifficultys = React.useMemo(
    () => [
      {
        label: "Dễ",
        value: "DE",
      },
      {
        label: "Đơn giản",
        value: "DON_GIAN",
      },
      {
        label: "Bình thường",
        value: "BINH_THUONG",
      },
      {
        label: "Khó",
        value: "KHO",
      },
      {
        label: "Phức tạp",
        value: "PHUC_TAP",
      },
    ],
    []
  );

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "itemCode",
        header: "Mã sản phẩm",
        Cell: ({ renderedCellValue }) => (
          <Badge
            radius="sm"
            variant="dot"
            size="lg"
            color={renderedCellValue === null ? "red" : green.base}
          >
            {renderedCellValue === null ? "Không xác định" : renderedCellValue}
          </Badge>
        ),
      },
      {
        accessorKey: "serial",
        header: "Số serial",
      },
      {
        accessorKey: "primaryUomCode",
        header: "Đơn vị tính",
      },
      {
        accessorKey: "quality",
        header: "Số lượng",
      },
      {
        accessorKey: "discountRate",
        header: "Chiết khấu ( % )",
      },
      {
        accessorKey: "discountAmount",
        header: "Tiền chiết khấu",
      },
      {
        accessorKey: "discountAdded",
        header: "Giảm thêm đơn",
      },
      {
        accessorKey: "priceFinal",
        header: "Tiền sau CK",
        Cell: ({ renderedCellValue, cell }) => (
          <NumberFormatter
            value={(renderedCellValue as string | number) || 0}
            suffix="₫"
            thousandSeparator="."
            decimalSeparator=","
          />
        ),
      },
      {
        accessorKey: "vatRate",
        header: "VAT (%)",
        // enableEditing: false,
      },
      {
        accessorKey: "vatAmount",
        header: "Tiền VAT",
        // enableEditing: false,
        Cell: ({ renderedCellValue, cell }) => (
          <NumberFormatter
            value={(renderedCellValue as string | number) || 0}
            suffix="₫"
            thousandSeparator="."
            decimalSeparator=","
          />
        ),
      },
      {
        accessorKey: "totalLineAmount",
        header: "Thành tiền",
        Cell: ({ renderedCellValue, cell }) => (
          <NumberFormatter
            value={(renderedCellValue as string | number) || 0}
            suffix="₫"
            thousandSeparator="."
            decimalSeparator=","
          />
        ),
      },
      {
        accessorKey: "lineTyle",
        header: "Loại mặt hàng",
      },
      {
        accessorKey: "testingStatus",
        header: "Trạng thái",
        Cell: ({ renderedCellValue, row }) =>
          form ? (
            <Select
              clearable
              searchable
              placeholder="Chọn trạng thái"
              data={testingStatuss}
              value={form
                ?.getValues()
                ?.updateStatusTblTestingTechniqueHeaderDetailCommandModels?.find(
                  (d) => d.id === row.original?.id
                )
                ?.testingStatus?.toString()}
              onChange={(value) =>
                form.setValues((prev) => ({
                  ...prev,
                  updateStatusTblTestingTechniqueHeaderDetailCommandModels:
                    prev?.updateStatusTblTestingTechniqueHeaderDetailCommandModels?.map(
                      (item) =>
                        item.id === row.original?.id
                          ? { ...item, testingStatus: value }
                          : item
                    ),
                }))
              }
            />
          ) : (
            <Text>
              {
                testingStatuss?.find(
                  (item) =>
                    item.value.toString() === row?.original?.testingStatus
                )?.label
              }
            </Text>
          ),
      },
      {
        accessorKey: "testDifficulty",
        header: "Mức độ test",
        Cell: ({ renderedCellValue, row }) =>
          form ? (
            <Select
              clearable
              searchable
              placeholder="Chọn mức độ"
              data={testDifficultys}
              value={form
                ?.getValues()
                ?.updateStatusTblTestingTechniqueHeaderDetailCommandModels?.find(
                  (d) => d.id === row.original?.id
                )
                ?.testDifficulty?.toString()}
              onChange={(value) =>
                form.setValues((prev) => ({
                  ...prev,
                  updateStatusTblTestingTechniqueHeaderDetailCommandModels:
                    prev?.updateStatusTblTestingTechniqueHeaderDetailCommandModels?.map(
                      (item) =>
                        item.id === row.original?.id
                          ? { ...item, testDifficulty: value }
                          : item
                    ),
                }))
              }
            />
          ) : (
            <Text>
              {
                testDifficultys?.find(
                  (item) =>
                    item.value.toString() === row?.original?.testDifficulty
                )?.label
              }
            </Text>
          ),
      },
      {
        accessorKey: "noteByEmployee",
        header: "Ghi chú",
        Cell: ({ renderedCellValue, row }) =>
          form ? (
            <TextInput
              placeholder="Nhập ghi chú"
              value={form
                ?.getValues()
                ?.updateStatusTblTestingTechniqueHeaderDetailCommandModels?.find(
                  (d) => d.id === row.original?.id
                )
                ?.noteByEmployee?.toString()}
              onChange={(e) =>
                form.setValues((prev) => ({
                  ...prev,
                  updateStatusTblTestingTechniqueHeaderDetailCommandModels:
                    prev?.updateStatusTblTestingTechniqueHeaderDetailCommandModels?.map(
                      (item) =>
                        item.id === row.original?.id
                          ? { ...item, noteByEmployee: e.target?.value }
                          : item
                    ),
                }))
              }
            />
          ) : (
            <Text>{renderedCellValue}</Text>
          ),
      },
    ],
    []
  );

  //data and fetching state
  const [data] = useState<any[]>([dataDetail]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [pagination, setPagination] = useState(paginationBase);
  //table state
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [selectIds, setSelectIds] = useState<string[]>([]);
  //count
  const [timeLeft, setTimeLeft] = useState(60);
  const [isCounting, setIsCounting] = useState(false);

  useEffect(() => {
    let timer: ReturnType<typeof setInterval>;
    if (isCounting && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsCounting(false);
    }
    return () => clearInterval(timer);
  }, [isCounting, timeLeft]);

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);

  const table = useMantineReactTable({
    columns,
    data: dataDetail ? dataDetail : [],
    positionToolbarAlertBanner: "bottom",
    columnFilterDisplayMode: "popover",
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    enableRowSelection: false,
    enablePagination: true,
    enableBottomToolbar: isDetail,
    enableTopToolbar: false,
    enableFilters: false,
    enableColumnActions: false,
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["itemCode"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: {
        maxHeight: !isDetail ? 255 : height,
        minHeight: !isDetail ? 100 : height,
      },
    },
    enableStickyHeader: true,
    manualPagination: true,
    onRowSelectionChange: setRowSelection,
    onPaginationChange: setPagination,
    rowCount: data?.length,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        fontWeight: "500", //conditional styling
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    mantineTableProps: () => ({
      striped: true,
    }),
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no data
      //sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["10", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });

  return (
    <Box mt={"20px"}>
      <MantineReactTable table={table} />
    </Box>
  );
};

export default ListProductDetail;
