import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Card,
  Checkbox,
  CheckIcon,
  Divider,
  Flex,
  Grid,
  GridCol,
  Group,
  Input,
  Menu,
  Modal,
  Notification,
  NumberFormatter,
  NumberInput,
  rem,
  ScrollArea,
  Select,
  Table,
  Text,
  Textarea,
  TextInput,
  Title,
  Tooltip,
} from "@mantine/core";
import { DateInput, DatePickerInput, DateTimePicker } from "@mantine/dates";
import {
  IconAlertCircle,
  IconBellQuestion,
  IconBellRinging,
  IconBellX,
  IconCheck,
  IconExclamationCircle,
  IconInfoCircle,
  IconMinus,
  IconPlus,
  IconSearch,
  IconTicket,
  IconTrash,
  IconX,
} from "@tabler/icons-react";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_RowSelectionState,
  MRT_SortingState,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { _localization } from "../../../config/location";
import { styleCellTable } from "../../../_setup/navdata/baseStyleCellTable";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import EditableTextWithOptions from "../../../common/selectValue";
import { MessageResponse } from "../../../model/MessageResponse";
import {
  dataExchangeRate,
  repositoryDelivery,
  repositoryMdm,
  repositoryPos,
} from "../../../_base/_const/_constVar";
import { EmployeeModel } from "../../../model/Employee";
import {
  formatDateNotTimeZone,
  formatDateTransfer,
  formatDateTransferLastYear,
} from "../../../common/FormatDate/FormatDate";
import { useForm } from "@mantine/form";
import { modals } from "@mantine/modals";
import {
  useDebouncedValue,
  useDisclosure,
  useMediaQuery,
} from "@mantine/hooks";
import { TblDMPaymentMethod } from "../../../model/TblDMPaymentMethod";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { notifications } from "@mantine/notifications";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import { green, orange } from "../../../const/variables";
import { IconBellCheck } from "@tabler/icons-react";
import { SelectListItemBase } from "../../../_base/model/_base/SelectListItemBase";
import { DetailsOrderHeader } from "../../../model/DetailsOrderHeaderModel";

interface PaymentReceiptDetail {
  accountfund: string | number | null | undefined;
  codefund: string | null;
  createby: number;
  createdDate: string;
  creatorbranch: string | null;
  creatordepartment: string | null;
  creatorfullname: string | null;
  creatorsalesbranch: string | null;
  creatorworkplace: string | null;
  fundbranch: string | null;
  funddepartment: string | null;
  completiontime: string | null;
  fundsalesbranch: string | null;
  fundworkplace: string | null;
  funfullname: string | null;
  id: number;
  payerdescription: string | null;
  payerfullname: string | null;
  payernote: string | null;
  payertype: string | null;
  receiptNumber: string;
  attribute1: string | null;
  attribute2: string | null;
  attribute3: string | null;
  attribute4: string | null;
  attribute5: string | null;
  attribute6: string | null;
  attribute7: string | null;
  attribute8: string | null;
  attribute9: string | null;
  bankname: string | null;
  totalAmount: number;
  codecustomer: string | null;
  accountnumber: String | null;
  anothername: string | null;
  phonenumber: string | null;
  createrolename: string | null;
  type: number;
  typeReceipt: string;
}

interface InvoiceReceipt {
  allocatedAmount: number;
  amountPaid: number;
  amountRe: number;
  notes: string | null;
  orderDate: string;
  orderNumber: string;
  totalAmount: number;
}

interface InvoiceReceiptsPaymentModels {
  paymentReceiptDetail: PaymentReceiptDetail;
  invoiceReceiptsPaymentModels: InvoiceReceipt[];
}

interface Account {
  id: number;
  code: string;
  description: string;
}

interface Receipt {
  id: number;
  orderNumber: string;
  orderTypeId: number;
  orderDate: string; // Có thể dùng Date nếu bạn muốn chuyển đổi
  orderStatus: string;
  note?: string | null;
  orgId: number;
  invOrgId: number;
  deptId?: number | null;
  divisionId?: number | null;
  saleChanelId?: number | null;
  sourceOrderNumber?: string | null;
  sourceDocument?: string | null;
  sourceInvoices?: string | null;
  custId: number;
  custGroupId?: number | null;
  contactPersonId?: number | null;
  billToLocationId?: number | null;
  shipToLocationId?: number | null;
  invoiceSerial?: string | null;
  invoiceNumber?: string | null;
  invoiceDate?: string | null; // Có thể dùng Date nếu bạn muốn chuyển đổi
  taxCode?: string | null;
  taxAddr?: string | null;
  amountTotal: number;
  amountDiscount: number;
  amountVoucher?: number | null;
  amountVat: number;
  currentcyCode: string;
  currentcyRate?: number | null;
  amountPaid: number;
  amountRemaining: number;
  paymentType?: string | null;
  paymentStatus?: string | null;
  paymentMethod?: string | null;
  paymentDue: number;
  saleId: number;
  managerId?: number | null;
  approveId?: number | null;
  approveNote?: string | null;
  rejectNote?: string | null;
  shipngType?: string | null;
  shipingVendor?: string | null;
  shippingCode?: string | null;
  shippingStatus?: string | null;
  shippingPriority?: string | null;
  shippingDate?: string | null; // Có thể dùng Date nếu bạn muốn chuyển đổi
  shippingNote?: string | null;
  technicalChecked: number;
  technicalNote?: string | null;
  attribute1?: string | null;
  attribute2?: string | null;
  attribute3?: string | null;
  attribute4?: string | null;
  attribute5?: string | null;
  attribute6?: string | null;
  attribute7?: string | null;
  attribute8?: string | null;
  attribute9?: string | null;
  attribute10?: string | null;
  attribute11?: string | null;
  attribute12?: string | null;
  attribute13?: string | null;
  attribute14?: string | null;
  attribute15?: string | null;
  clientId?: number | null;
  createBy: number;
  createDate: string; // Có thể dùng Date nếu bạn muốn chuyển đổi
  lastUpdateBy?: number | null;
  lastUpdateDate?: string | null; // Có thể dùng Date nếu bạn muốn chuyển đổi
  orderStatusId: number;
  salesShift?: string | null;
  printInvoiceCheck?: string | null;
  value?: any;
  allocatedAmount?: number;
}

interface FundDetails {
  accountfund: number | null | undefined;
  codefund: number | null | string;
  createby: number | null;
  createdDate: string | null;
  creatorbranch: string | null | undefined;
  creatordepartment: string | null;
  creatorfullname: string | null;
  creatorsalesbranch: string | null;
  creatorworkplace: string | null;
  fundbranch: string | null;
  funddepartment: string | null;
  fundsalesbranch: string | null;
  fundworkplace: string | null;
  funfullname: string | null;
  id: number | null;
  payerdescription: string | null;
  payerfullname: string | null;
  payernote: string | null;
  payertype: string | null;
  receiptNumber: string | null;
  anotherName: string | null;
  phoneNumber: string | null;
  codeCustomer: string | null;
  totalAmount: number | null;
  type: number | null;
  typeReceipt: any;
  completiontime?: string | null;
  status?: string | null;
  bankname?: string | null;
  createrolename?: string | null;
  accountnumber?: string | null;
  isAdjust: any;
  numberAdjust: any;
}

interface Permission {
  id: number;
  code: string;
  name: string;
  createBy: string | null;
  createDate: string;
  lastUpdateDate: string | null;
  lastUpdateBy: string | null;
}

interface DepartmentUser {
  departmentId: number;
  name: string;
  description: string | null;
  active: boolean;
  departmentCode: string;
  creationDate: string;
  createdBy: string;
  lastUpdateDate: string | null;
  lastUpdatedBy: string | null;
  address: string;
  branchId: number;
}

interface Program {
  id: number;
  code: string;
  name: string;
  createBy: string | null;
  createDate: string;
  lastUpdateDate: string | null;
  lastUpdateBy: string | null;
}
interface Department {
  id: number;
  name: string;
}

interface DetaiReceiptsProps {
  receiptId: number;
  deltail: number;
}

interface DepartmentCustomerOrder {
  id: number;
  name: string;
  details: {
    fullName: string;
    phoneNumber: string;
  };
}

interface AddressInfo {
  active: boolean;
  address: string;
  birthOfDate: string;
  code: string;
  commune: number;
  companyName: string | null;
  contact: string | null;
  country: string | null;
  debtLimit: number | null;
  description: string | null;
  district: number;
  email: string;
  fax: string | null;
  groupId: number;
  groupName: string;
  id: number;
  name: string;
  province: number;
  sex: number;
  taxCode: string | null;
  tblDmCustomerGroupModel: any | null;
  telephoneNumber: string;
  type: number;
}

interface Customer {
  addr: string;
  createdBy: number;
  creationDate: string;
  creditLimit: number;
  email: string;
  employeeId: number;
  enabledFlag: boolean | null;
  endDateActive: string;
  id: number;
  orgId: number;
  phone: string;
  startDateActive: string;
  vatRegistrationNum: string;
  vendorCode: string;
  vendorName: string;
  vendorTypeLookupCode: string;
}

interface CreateReceiptParentRequest {
  //   receiptCommand: Command[];
  paymentReceiptDetailCommand: FundDetails;
  save: boolean | null | undefined;
}

interface Location {
  id: number;
  code: string;
  name: string;
  address: string;
  phone: string | null;
  fax: string | null;
  email: string | null;
  note: string | null;
  location: string | null;
  road: string;
  province: string;
  country: string | null;
  channelcode: string | null;
  isonline: boolean | null;
  masterInventoryOrg: string | null;
  guarantee: string | null;
  orgId: string | null;
  townName: string | null;
  createBy: string | null;
  createDate: Date | null;
  lastUpdateBy: string | null;
  lastUpdateDate: Date | null;
}

interface DebtData {
  id?: number;
  incurDebt: number | null;
  codeForFees?: string | null;
  occurringCredit?: number | null;
  debtObjectCode?: string | null;
  department?: string | null;
  debtObjectName?: string | null;
  description?: string | null;
  descriptionFund?: string | null;
  departmentFund?: string | null;
  fee?: number | null;
  contract?: string | null;
}

const banks = [
  { text: "VCB", value: 1 },
  { text: "VietinBank", value: 2 },
  { text: "BIDV", value: 3 },
  { text: "Techcombank", value: 4 },
  { text: "MB", value: 5 },
  { text: "VPBank", value: 6 },
  { text: "Sacombank", value: 7 },
  { text: "ACB", value: 8 },
  { text: "TPBank", value: 9 },
];

const accounts: Account[] = [
  { code: "1111", description: "Tiền Việt nam", id: 1 },
  { code: "1112.1", description: "Ngoại tệ USD", id: 2 },
  { code: "1113", description: "Vàng bạc, kim khí quí, đá quí", id: 3 },
];

interface UserData {
  code: string | null;
  depName: string | null;
  departmentId: number | null;
  fullName: string | null;
  id: number;
  managerId: number | null;
  managerName: string | null | null;
  roleId: number;
  roleName: string | null;
}

const DetailListReceipts = () => {
  const navigate = useNavigate();

  const [invoiceReceiptsPaymentModels, setInvoiceReceiptsPaymentModels] =
    useState<InvoiceReceiptsPaymentModels | null>(null);

  const [height, setHeight] = useState(0);
  const [rowCount] = useState(0);
  const [globalFilter, setGlobalFilter] = useState("");
  const [heightContent, setHeightContent] = useState(0);
  const [listBranch, setListBranch] = useState<any[]>([]);
  const [listEmployee, setListEmployee] = useState<SelectListItemBase[]>([]);
  const [dataGetDepartments, setDataGetDepartments] = useState<any[]>([]);
  const [dataCodeFund, setDataCodeFunch] = useState<SelectListItemBase[]>([]);
  const [dataRole, setDataRole] = useState<any[]>([]);
  const [changeValueTotal, setChangeValueTatal] = useState<any>();
  const [selectedValue, setSelectedValue] = useState<any>(1);
  const headerRef = React.useRef<HTMLDivElement>(null);
  const contentRef = React.useRef<HTMLDivElement>(null);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  const [debtData, setDebtData] = useState<DebtData[]>([]);

  const [customerDetail, setCustomerDetail] = useState<Customer[]>([]);
  const [addressInfoDetail, setAddressInfo] = useState<AddressInfo[]>([]);
  const [selectedCalculation, setSelectedCalculation] = useState<string>("VND");
  const [branchAll, setBranchAll] = useState<DepartmentUser[]>([]);

  const [userData, setUserData] = useState<UserData | null>(null);
  const [locationBranch, setLocationBranch] = useState<Location | null>(null);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const [badgeContent, setBadgeContent] = useState<string>("Không xác định");
  const [badgeColor, setBadgeColor] = useState<string>("gray");
  const [badIcon, setBadIcon] = useState<React.ReactNode>(null);
  const today = new Date();
  const [isError] = useState(false);
  const [isLoading] = useState(false);
  const [isRefetching] = useState(false);
  const [sorting] = useState<MRT_SortingState>([]);
  const userName = localStorage.getItem("userName") || "";
  const [dataBranchHandle, setDataBranchHandle] = useState<
    SelectListItemBase[]
  >([]);
  const location = useLocation();
  const { id, type, receptype } = location.state || {};
  const [listEmployeeDetail, setListEmployeeDetail] = useState<EmployeeModel[]>(
    []
  );
  const [dataGetReceiptFee, setDataReceiptFee] = useState<Permission[]>([]);
  const [dataGetReceiptContract, setDataGetReceiptContract] = useState<
    Program[]
  >([]);

  const [validationErrors, setValidationErrors] = useState<
    Record<string, string | undefined>
  >({});

  const [dataRep, setDataRep] = useState<Array<DetailsOrderHeader>>([]);

  const columns = React.useMemo<MRT_ColumnDef<DetailsOrderHeader>[]>(
    () => [
      {
        accessorKey: "STT",
        header: "STT",
        enableColumnActions: false,
        enableEditing: false,
        enableSorting: false,
        size: 50,
        Cell: ({ renderedCellValue, row }) => (
          <Text fw={600}>{row.index + 1}</Text>
        ),
      },
      {
        accessorKey: "orderNumber",
        header: "Số đơn hàng",
        enableColumnActions: false,
        enableSorting: false,
        Cell: ({ renderedCellValue, cell }) => {
          return (
            <Text size="sm" fw={600}>
              {renderedCellValue}
            </Text>
          );
        },
      },
      {
        accessorKey: "attribute2",
        header: "Mã đối tượng công nợ",
        size: 150,
        enableColumnActions: false,
        enableEditing: false,
        enableSorting: false,
        Cell: ({ renderedCellValue, cell }) => {
          return (
            <Text size="sm" fw={600}>
              {renderedCellValue === "null" ? "" : renderedCellValue}
            </Text>
          );
        },
      },
      {
        accessorKey: "attribute7",
        header: "Tên đối tượng công nợ",
        size: 150,
        enableEditing: false,
        enableColumnActions: false,
        enableSorting: false,
        Cell: ({ renderedCellValue, cell }) => {
          return (
            <Text size="sm" fw={600}>
              {renderedCellValue === "null" ? "" : renderedCellValue}
            </Text>
          );
        },
      },
      {
        accessorKey: "amountTotal",
        header: "Tổng tiền hóa đơn",
        enableSorting: false,
        enableColumnActions: false,

        Cell: ({ renderedCellValue, cell }) => (
          <Text size="sm" fw={600}>
            <NumberFormatter
              value={(renderedCellValue as string | number) || 0}
              suffix=" ₫"
              thousandSeparator=","
            />
          </Text>
        ),
      },
      {
        accessorKey: "amountPaid",
        header: "Đã thanh toán",
        enableSorting: false,
        enableColumnActions: false,
        Cell: ({ renderedCellValue, cell }) => (
          <Text size="sm" fw={600}>
            <NumberFormatter
              value={(renderedCellValue as string | number) || 0}
              suffix=" ₫"
              thousandSeparator=","
            />
          </Text>
        ),
      },
      {
        accessorKey: "amountRemaining",
        header: "Số còn phải TT",
        enableSorting: false,
        enableColumnActions: false,

        Cell: ({ renderedCellValue, cell }) => (
          <Text size="sm" fw={600}>
            <NumberFormatter
              value={(renderedCellValue as string | number) || 0}
              suffix=" ₫"
              thousandSeparator=","
            />
          </Text>
        ),
      },
      {
        accessorKey: "allocatedAmount",
        header: "Số tiền phân bổ",
        enableColumnActions: false,

        enableSorting: false,
        Cell: ({ renderedCellValue, cell }) => (
          <Text size="sm" fw={600}>
            <NumberFormatter
              value={(renderedCellValue as string | number) || 0}
              suffix=" ₫"
              thousandSeparator=","
            />
          </Text>
        ),
      },

      {
        accessorKey: "note",
        header: "Diễn giải",
        enableSorting: false,
        enableColumnActions: false,
        enableEditing: true,
      },
    ],
    [validationErrors]
  );

  const columnDetail = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "index",
        header: "STT",
        Cell: ({ row }) => row.index + 1,
        size: 30,
        enableSorting: false,
        enableEditing: false,
      },
      {
        accessorKey: "codeForFees",
        header: "Mã Phí",
        enableEditing: true,
        size: 200,
        enableSorting: false,
      },
      ...(type === 2 || type === 4
        ? [
            {
              accessorKey: "occurringCredit",
              header: "Phát sinh có",
              size: 30,
              enableSorting: false,

              Cell: ({ row, renderedCellValue }: any) => (
                <NumberFormatter
                  value={Number(renderedCellValue)}
                  thousandSeparator=","
                />
              ),
            },
          ]
        : [
            {
              accessorKey: "occurringCredit",
              header: "Phát sinh nợ",
              size: 30,
              enableSorting: false,
              Cell: ({ row, renderedCellValue }: any) => (
                <NumberFormatter
                  value={Number(renderedCellValue)}
                  thousandSeparator=","
                />
              ),
            },
          ]),
      {
        accessorKey: "debtObjectCode",
        header: "Mã Đối Tượng Công Nợ",
        enableSorting: false,
        size: 200,
        Cell: ({ renderedCellValue, cell }) => {
          return (
            <Text size="sm" fw={600}>
              {renderedCellValue === "null" ? "" : renderedCellValue}
            </Text>
          );
        },
      },
      {
        accessorKey: "debtObjectName",
        header: "Tên Đối Tượng Công Nợ",
        enableSorting: false,
        Cell: ({ renderedCellValue, cell }) => {
          return (
            <Text size="sm" fw={600}>
              {renderedCellValue === "null" ? "" : renderedCellValue}
            </Text>
          );
        },
      },
      {
        accessorKey: "description",
        header: "Nội Dung",
        enableSorting: false,
      },
      {
        accessorKey: "departmentFund",
        header: "Bộ Phận",
        enableSorting: false,
        Cell: ({ renderedCellValue, cell, row, table, column }) => {
          return (
            <Text>
              {branchAll.find(
                (ele) => String(ele.departmentId) === String(renderedCellValue)
              )?.name ?? ""}
            </Text>
          );
        },
        size: 100,
      },
      {
        accessorKey: "fee",
        header: "CTKM",
        enableSorting: false,
        size: 100,
        Cell: ({ renderedCellValue, cell, row, table, column }) => {
          return (
            <Text>
              {dataGetReceiptFee.find(
                (ele) => String(ele.id) === String(renderedCellValue)
              )?.name ?? ""}
            </Text>
          );
        },
      },
      {
        accessorKey: "contract",
        header: "Hợp Đồng",
        enableSorting: false,
        size: 100,
        Cell: ({ renderedCellValue, cell, row, table, column }) => {
          return (
            <Text>
              {dataGetReceiptContract.find(
                (ele) => String(ele.id) === String(renderedCellValue)
              )?.name ?? ""}
            </Text>
          );
        },
      },
    ],
    [type, branchAll, dataGetReceiptFee, dataGetReceiptContract]
  );

  const table = useMantineReactTable<DetailsOrderHeader>({
    columns,
    data: dataRep,
    positionToolbarAlertBanner: "bottom",
    createDisplayMode: "row",
    editDisplayMode: "cell",
    enableEditing: false,
    enableBatchRowSelection: true,
    enableBottomToolbar: true,
    renderTopToolbarCustomActions: ({ table }) => (
      <>
        <Flex
          gap="md"
          justify="flex-start"
          align="center"
          direction="row"
          wrap="wrap"
          mb={"xs"}
          style={{ opacity: 0 }}
        ></Flex>
      </>
    ),
    mantineTopToolbarProps: {
      style: {
        display: "none",
      },
    },

    mantineBottomToolbarProps: {
      style: {
        display: "none",
      },
    },

    localization: _localization,
    enableRowSelection: false,
    getRowId: (row) => row.orderNumber ?? "",
    mantineTableContainerProps: {
      style: {
        maxHeight: height - 20,
        minHeight: height - 20,
        // borderTop: `5px solid #ec1c24`,
      },
    },
    mantineTableProps: {
      striped: true,
    },
    enablePinning: true,
    initialState: {
      columnPinning: {
        left: ["mrt-row-select", "mrt-row-actions"],
        right: ["amountRemaining", "allocatedAmount"],
      },
      showColumnFilters: false,
      columnVisibility: { id: false },
      density: "xs",
    },
    positionActionsColumn: "last",
    onRowSelectionChange: setRowSelection,
    manualFiltering: true,
    manualPagination: false,
    manualSorting: true,
    rowCount,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    // onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: styleCellTable(row),
    }),
    onCreatingRowCancel: () => setValidationErrors({}),
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["20", "50", "100"],
    },
    enableColumnPinning: true,
  });

  const debtAccountTable = useMantineReactTable({
    columns: columnDetail,
    data: debtData,
    displayColumnDefOptions: {
      "mrt-row-actions": {
        header: "Thao tác",
      },
    },
    positionToolbarAlertBanner: "bottom",
    enableColumnActions: false,
    enableEditing: true,
    createDisplayMode: "row",
    editDisplayMode: "modal",
    enableToolbarInternalActions: false,
    enableRowActions: true,
    enableBatchRowSelection: true,
    positionActionsColumn: "last",
    enableExpandAll: false,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    renderRowActions: ({ row }) => (
      <Flex gap={10}>
        <Tooltip label="Xóa">
          <ActionIcon disabled variant="light" color="rgba(255, 0, 0, 1)">
            <IconTrash size={14} />
          </ActionIcon>
        </Tooltip>
      </Flex>
    ),
    renderTopToolbarCustomActions: ({ table }) => MenuData(),
    mantineBottomToolbarProps: {
      style: {
        paddingBottom: "20px",
      },
    },
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select", "index"],
        right: ["mrt-row-actions", "deviated"],
      },
      columnVisibility: { id: false, phone: false, fax: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height * 0.4, minHeight: height * 0.4 },
    },
    mantineTableProps: {
      striped: true,
    },
    enableStickyHeader: true,
    enablePagination: false,
    enableBottomToolbar: false,

    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        width: "100%",
        fontWeight: "normal",
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),

    enableColumnPinning: true,

    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });
  const fetchDataEmployee = async () => {
    const response = await repositoryPos.get<MessageResponse<any>>(
      "/api/v1/TblDmEmployee/get-select"
    );

    if (response && response.success) {
      let result = response.data;
      setListEmployee(result);
    }
  };

  const fetchDataBranch = async () => {
    const response = await repositoryDelivery.get<
      MessageResponse<TblDMPaymentMethod[]>
    >("/api/v1/TblDmMasterOrg/get-all");

    if (response && response.success) {
      let result = response.data;
      setListBranch(result);
    }
  };

  const fetchDataBranchHandle = async () => {
    const response = await repositoryPos.get<
      MessageResponse<SelectListItemBase[]>
    >("/api/v1/TblDmInventory/get-select-branch?auth=false");

    if (response && response.success) {
      let result = response.data;
      setDataBranchHandle(result);
    }
  };

  const fetchDataTblVendor = async () => {
    const response = await repositoryMdm.get<MessageResponse<Customer[]>>(
      "/api/v1/TblVendor/get-all"
    );

    if (response && response.success) {
      let result = response.data;
      setCustomerDetail(result);
    }
  };

  const fetchDataTblDmCustomer = async () => {
    const response = await repositoryPos.get<MessageResponse<AddressInfo[]>>(
      "/api/v1/TblDmCustomer/get-list?Skip=0&Take=50"
    );

    if (response && response.success) {
      let result = response.data;
      setAddressInfo(result);
    }
  };

  const fetchDataDepartments = async () => {
    const response = await repositoryPos.get<
      MessageResponse<TblDMPaymentMethod[]>
    >("/api/v1/TblDepartment/get-select");

    if (response && response.success) {
      let result = response.data;
      setDataGetDepartments(result);
    }
  };

  const fetchDataDetailBranch = async () => {
    const response = await repositoryPos.get<MessageResponse<Location>>(
      `/api/v1/TblDmInventory/get-detail-branch?id=${Number(
        invoiceReceiptsPaymentModels?.paymentReceiptDetail?.creatorbranch
      )}`
    );

    if (response && response.success) {
      const result = response.data;
      setLocationBranch(result);
    }
  };

  const fetchDataEmployeeDetail = async () => {
    const response = await repositoryPos.get<MessageResponse<EmployeeModel[]>>(
      `/api/v1/TblDmEmployee/get-list?BranchId=${Number(
        invoiceReceiptsPaymentModels?.paymentReceiptDetail?.payerfullname
      )}&Skip=0&Take=9999`
    );

    if (response && response.success) {
      let result = response.data;
      setListEmployeeDetail(result);
    }
  };

  useEffect(() => {
    if (
      invoiceReceiptsPaymentModels &&
      invoiceReceiptsPaymentModels?.paymentReceiptDetail?.creatorbranch
    ) {
      fetchDataDetailBranch();
    }
  }, [invoiceReceiptsPaymentModels?.paymentReceiptDetail?.creatorbranch]);

  const getTblDmExpense = async () => {
    const response = await repositoryMdm.get<
      MessageResponse<SelectListItemBase[]>
    >("/api/v1/TblDmExpense/get-select");

    if (response && response.success) {
      let result = response.data;
      setDataCodeFunch(result);
    }
  };

  const MenuData = () => (
    <Menu position="bottom-start" trigger="hover" shadow="md" width={"700"}>
      <Menu.Target>
        <TextInput
          disabled
          autoFocus
          placeholder="Hoặc tìm kiếm theo mã phí"
          size="xs"
          w={400}
          rightSection={<IconSearch size={14} />}
        />
      </Menu.Target>
      <Menu.Dropdown>
        {/* <TableSelectCenterTransfer filteredData={filteredData} /> */}
      </Menu.Dropdown>
    </Menu>
  );

  const getTblRole = async () => {
    const response = await repositoryPos.get<MessageResponse<any[]>>(
      "/api/v1/TblRole/get-all"
    );

    if (response && response.success) {
      let result = response.data;
      setDataRole(result);
    }
  };

  const fetchDataReceiptFee = async () => {
    const response = await repositoryPos.get<MessageResponse<Permission[]>>(
      "/api/v1/TblDmFee/get-list?Skip=0&Take=9999"
    );

    if (response && response.success) {
      let result = response.data;
      setDataReceiptFee(result);
    }
  };

  const fetchDataGetAll = async () => {
    try {
      const response = await repositoryPos.get<
        MessageResponse<DepartmentUser[]>
      >(`/api/v1/TblDepartment/get-all`);

      if (response && response.success) {
        const result = response.data;
        setBranchAll(result);
      } else {
        console.error("Failed to fetch departmentFund details");
      }
    } catch (error) {
      console.error("Error fetching departmentFund details", error);
    }
  };

  const fetchDataReceiptContract = async () => {
    const response = await repositoryPos.get<MessageResponse<Program[]>>(
      "/api/v1/TblDmContract/get-list?Skip=0&Take=99999"
    );

    if (response && response.success) {
      let result = response.data;
      setDataGetReceiptContract(result);
    }
  };

  useEffect(() => {
    fetchDataEmployee();
    fetchDataReceiptFee();
    fetchDataGetAll();
    fetchDataReceiptContract();
    fetchDataBranch();
    fetchDataDepartments();
    getTblDmExpense();
    fetchDataTblVendor();
    fetchDataTblDmCustomer();
    getTblRole();
    fetchDataBranchHandle();
  }, []);

  useEffect(() => {
    if (invoiceReceiptsPaymentModels) {
      fetchDataEmployeeDetail();
    }
  }, [invoiceReceiptsPaymentModels?.paymentReceiptDetail?.payerfullname]);

  const managerOrder = () => (
    <>
      <ScrollArea pr={16} scrollbars="y" h={height + 60} offsetScrollbars>
        <Grid>
          <GridCol pb={0} span={{ base: 6, xs: 6, sm: 6, md: 6, lg: 6, lx: 6 }}>
            <Flex mt={10} align="center" gap="xs">
              <Text style={{ flex: "0 0 100px", whiteSpace: "nowrap" }}>
                Mã phiếu thu
              </Text>
              <Text fw={500}>
                {
                  invoiceReceiptsPaymentModels?.paymentReceiptDetail
                    ?.receiptNumber
                }
              </Text>
            </Flex>
          </GridCol>
          <GridCol pb={0} span={{ base: 6, xs: 6, sm: 6, md: 6, lg: 6, lx: 6 }}>
            <Flex mt={10} align="center" gap="xs">
              <Text style={{ flex: "0 0 100px", whiteSpace: "nowrap" }}>
                Thời gian tạo
              </Text>
              <Text fw={500} style={{ whiteSpace: "nowrap" }}>
                {formatDateTransferLastYear(
                  invoiceReceiptsPaymentModels?.paymentReceiptDetail
                    ?.createdDate
                )}
              </Text>
            </Flex>
          </GridCol>
          <GridCol
            pb={0}
            offset={{ base: 0, xs: 0, sm: 0, md: 0, lg: 6, lx: 6 }}
            span={{ base: 6, xs: 6, sm: 6, md: 6, lg: 6, lx: 6 }}
          >
            <Flex align="center" gap="xs">
              <Text style={{ flex: "0 0 150px", whiteSpace: "nowrap" }}>
                Thời gian hoàn thành
              </Text>
              <Text fw={500} style={{ whiteSpace: "nowrap" }}>
                {formatDateTransferLastYear(
                  invoiceReceiptsPaymentModels?.paymentReceiptDetail
                    ?.completiontime
                )}
              </Text>
            </Flex>
          </GridCol>
          <Grid.Col pb={0} span={12}>
            <Divider
              my="xs"
              label={
                <Text size="lg" fw={600} c="#000">
                  Người lập phiếu thu
                </Text>
              }
              labelPosition="left"
            />
          </Grid.Col>
          <GridCol pt={0} span={{ base: 6, xs: 6, sm: 6, md: 6, lg: 6, lx: 6 }}>
            <Flex>
              <Text style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}>
                Phòng ban
              </Text>
              <Text fw="600">
                {listBranch?.find(
                  (item: any) =>
                    item.id ==
                    invoiceReceiptsPaymentModels?.paymentReceiptDetail
                      ?.creatorbranch
                )?.name || ""}
              </Text>
            </Flex>
          </GridCol>

          <GridCol pt={0} span={{ base: 6, xs: 6, sm: 6, md: 6, lg: 6, lx: 6 }}>
            <Flex>
              <Text style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}>
                Họ và tên
              </Text>
              <Text fw={600} style={{ whiteSpace: "nowrap" }}>
                {(() => {
                  const data = listEmployee?.find(
                    (item: any) =>
                      item.value ===
                      invoiceReceiptsPaymentModels?.paymentReceiptDetail
                        ?.creatorfullname
                  );

                  return data ? `${data.text} - ${data.att1}` : null;
                })()}
              </Text>
            </Flex>
          </GridCol>

          <GridCol pt={0} span={{ base: 6, xs: 6, sm: 6, md: 6, lg: 6, lx: 6 }}>
            <Flex>
              <Text style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}>
                ĐĐ làm việc
              </Text>
              <Text fw="600">
                {" "}
                {
                  invoiceReceiptsPaymentModels?.paymentReceiptDetail
                    ?.creatorworkplace
                }
              </Text>
            </Flex>
          </GridCol>
          <GridCol
            pt={0}
            span={{ base: 12, xs: 6, sm: 6, md: 6, lg: 6, lx: 6 }}
          >
            <Flex>
              <Text style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}>
                Chức danh
              </Text>
              <Text fw="600">
                {
                  dataRole?.find(
                    (item: any) =>
                      String(item.id) ==
                      String(
                        invoiceReceiptsPaymentModels?.paymentReceiptDetail
                          ?.createrolename
                      )
                  )?.name
                }
              </Text>
              {/* <TextInput
                    size="sm"
                    disabled
                    flex={1}
                    error={formErrors.creatorbranch}
                    value={formData.creatorbranch}
                    onChange={(e) =>
                      handleChange("creatorbranch", e.currentTarget.value)
                    }
                    placeholder="Nhập..."
                    rightSection={<IconMinus size={"12px"} />}
                  /> */}
            </Flex>
          </GridCol>
          <GridCol
            pb={0}
            pt={0}
            span={{ base: 6, xs: 6, sm: 6, md: 6, lg: 6, lx: 6 }}
          >
            <Flex>
              <Text style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}>
                Bộ phận
              </Text>
              <Text fw="600">
                {
                  dataGetDepartments?.find(
                    (item: any) =>
                      item.value ==
                      invoiceReceiptsPaymentModels?.paymentReceiptDetail
                        ?.creatordepartment
                  )?.text
                }
              </Text>
            </Flex>
          </GridCol>
          <Grid.Col pb={0} pt={0} span={12}>
            <Divider
              my="xs"
              label={
                <Text size="lg" fw={600} c="#000">
                  Người nộp tiền
                </Text>
              }
              labelPosition="left"
            />
          </Grid.Col>
          <GridCol
            pt={0}
            span={{ base: 12, xs: 12, sm: 6, md: 6, lg: 6, lx: 6 }}
          >
            <Group style={{ flexWrap: "nowrap" }}>
              <Text style={{ flex: "0 0 90px", whiteSpace: "nowrap" }}>
                Họ và tên
              </Text>
              <Text fw={600}>
                {(() => {
                  const data = listEmployee?.find(
                    (item) =>
                      String(item.value) ===
                      String(
                        invoiceReceiptsPaymentModels?.paymentReceiptDetail
                          ?.payerfullname
                      )
                  );
                  return data
                    ? `${data.text} - ${data.att1}`
                    : invoiceReceiptsPaymentModels?.paymentReceiptDetail
                        .anothername;
                })()}
              </Text>
            </Group>
          </GridCol>
          <GridCol
            pt={0}
            span={{ base: 12, xs: 12, sm: 6, md: 6, lg: 6, lx: 6 }}
          >
            <Group style={{ flexWrap: "nowrap" }}>
              <Text style={{ flex: "0 0 90px", whiteSpace: "nowrap" }}>
                Loại
              </Text>
              <Text fw={600}>
                {invoiceReceiptsPaymentModels?.paymentReceiptDetail?.payertype}
              </Text>
            </Group>
          </GridCol>
          <GridCol pt={0} span={{ base: 12, md: 6, lg: 6 }}>
            <Group style={{ flexWrap: "nowrap" }}>
              <Text style={{ flex: "0 0 90px", whiteSpace: "nowrap" }}>
                CN bán
              </Text>
              <Text fw={600}>
                {listBranch?.find(
                  (item: any) =>
                    item.id ==
                    invoiceReceiptsPaymentModels?.paymentReceiptDetail
                      ?.creatorbranch
                )?.name || ""}
              </Text>
            </Group>
          </GridCol>
          <GridCol pt={0} span={{ base: 12, md: 12, lx: 12, lg: 12 }}>
            <Group>
              <Text style={{ flex: "0 0 90px", whiteSpace: "nowrap" }}>
                Nội dung
              </Text>
              <Text>
                {
                  invoiceReceiptsPaymentModels?.paymentReceiptDetail
                    ?.payerdescription
                }
              </Text>
            </Group>
          </GridCol>
          <GridCol>
            <Group>
              <Text
                style={{ flex: "0 0 90px", whiteSpace: "nowrap" }}
                fw={600}
                c="red"
              >
                Tổng tiền
              </Text>
              <Text c="red" fw={600}>
                <NumberFormatter
                  thousandSeparator=","
                  suffix=" VND"
                  placeholder="Nhập số tiền"
                  value={
                    invoiceReceiptsPaymentModels?.paymentReceiptDetail
                      ?.totalAmount
                  }
                />
              </Text>
            </Group>
          </GridCol>
        </Grid>
      </ScrollArea>
    </>
  );

  //   useEffect(() => {
  //     const fetchDataListBranch = async () => {
  //       const response = await repositoryDelivery.get<MessageResponse<Receipt[]>>(
  //         "/api/v1/TblDmMasterOrg/get-select"
  //       );

  //       if (response && response.success) {
  //         let result = response.data;
  //         setDataListBranch(result);
  //       }
  //     };

  //     const fetchDataPaymentMethod = async () => {
  //       const response = await repositoryMdm.get<
  //         MessageResponse<TblDMPaymentMethod[]>
  //       >("/api/v1/TblDmPaymentMethod/get-all");

  //       if (response && response.success) {
  //         let result = response.data;
  //         setPaymentMethod(result);
  //       }
  //     };

  //     const fetchDataPaymentType = async () => {
  //       const response = await repositoryMdm.get<
  //         MessageResponse<TblDMPaymentMethod[]>
  //       >("/api/v1/TblDmPaymentType/get-select");

  //       if (response && response.success) {
  //         let result = response.data;
  //         setPaymentTypes(result);
  //       }
  //     };

  //     const fetchDataBranch = async () => {
  //       const response = await repositoryDelivery.get<
  //         MessageResponse<TblDMPaymentMethod[]>
  //       >("/api/v1/TblDmMasterOrg/get-all");

  //       if (response && response.success) {
  //         let result = response.data;
  //         setListBranch(result);
  //       }
  //     };

  //     Promise.all([
  //       fetchDataPaymentMethod(),
  //       fetchDataPaymentType(),
  //       fetchDataListBranch(),
  //       fetchDataBranch(),
  //     ]);
  //   }, []);
  const detailReceipts = async () => {
    // Example id
    const response = await repositoryPos.get<
      MessageResponse<InvoiceReceiptsPaymentModels>
    >(`/api/v1/CreateSellItem/details-receipt?id=${id}`);

    if (response && response.success) {
      setInvoiceReceiptsPaymentModels(response.data);
      if (response.data.paymentReceiptDetail?.attribute5) {
        const _data = JSON.parse(
          response.data.paymentReceiptDetail?.attribute5
        ) as Array<DetailsOrderHeader>;
        if (_data) setDataRep(_data);
      }
    }
  };

  useEffect(() => {
    detailReceipts();
  }, [id]);

  const getDataFromLocalStorage = (key: string): UserData | null => {
    const data = localStorage.getItem(key);
    if (data) {
      return JSON.parse(data);
    }
    return null;
  };

  useEffect(() => {
    const data = getDataFromLocalStorage("userLogin");
    if (data) {
      setUserData(data);
    }
  }, []);

  useEffect(() => {
    const dataString: string | null | undefined =
      invoiceReceiptsPaymentModels?.paymentReceiptDetail?.attribute5;
    let dataArray;

    if (dataString) {
      dataArray = JSON.parse(dataString);
      const mappedData = dataArray.map((item: DebtData) => ({
        id: item.id,
        codeForFees: item.codeForFees,
        debtObjectCode: item.debtObjectCode,
        debtObjectName: item.debtObjectName,
        description: item.description,
        incurDebt: item.incurDebt,
        fee: item.fee,
        contract: item.contract,
        department: item.department,
        departmentFund: item.departmentFund,
        occurringCredit: item.occurringCredit,
      }));
      setDebtData((prev) => [...prev, ...mappedData]);
    } else {
      console.warn("Hông có data:", dataString);
    }
  }, [invoiceReceiptsPaymentModels?.paymentReceiptDetail?.attribute5]);

  useEffect(() => {
    setChangeValueTatal(
      invoiceReceiptsPaymentModels?.paymentReceiptDetail?.totalAmount
    );
  }, [invoiceReceiptsPaymentModels?.paymentReceiptDetail?.totalAmount]);

  useEffect(() => {
    const defaultItem = dataExchangeRate.find(
      (item) =>
        String(item.currencyType) ===
        String(
          invoiceReceiptsPaymentModels?.paymentReceiptDetail?.accountfund ??
            "VND"
        )
    );
    setSelectedValue(defaultItem?.value ?? null);
  }, [invoiceReceiptsPaymentModels?.paymentReceiptDetail?.accountfund]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const contentHeight = contentRef.current?.offsetHeight || 0;
    const handleResize = () => {
      setHeight(window.innerHeight - (135 + headerHeight));
      setHeightContent(window.innerHeight - (105 + contentHeight));
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [heightContent, height]);

  console.log(
    addressInfoDetail?.find(
      (item) =>
        String(item.id) ===
        String(invoiceReceiptsPaymentModels?.paymentReceiptDetail?.codecustomer)
    )?.code,
    customerDetail,
    "addressInfoDetail"
  );
  return (
    <>
      {receptype === 1 && type === 1 ? (
        <>
          <Grid pb={15} ref={headerRef}>
            <GridCol span={{ base: 12, md: 12, lg: 12 }}>
              <Flex direction="column" gap={5}>
                <_breadcrumb></_breadcrumb>
                <>
                  <Grid>
                    <Grid.Col
                      style={{
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                      }}
                      span={12}
                    >
                      <Badge size="xl" color="#C3FF36" c="#000">
                        Đơn hàng
                      </Badge>
                    </Grid.Col>
                  </Grid>
                </>
              </Flex>
            </GridCol>
          </Grid>
          <Grid gutter="xs">
            <GridCol span={{ base: 12, md: 6, lg: 6, xl: 4.5 }}>
              <Card
                shadow="sm"
                radius="md"
                withBorder
                pr={0}
                pt={0}
                p="md"
                style={{
                  maxHeight: height + 60,
                  minHeight: height + 60,
                }}
              >
                {managerOrder()}
              </Card>
            </GridCol>
            <GridCol span={{ base: 12, md: 6, lg: 6, xl: 7.5 }}>
              <MantineReactTable table={table} />
              <Card
                shadow="sm"
                radius="md"
                withBorder
                p="md"
                mt={5}
                style={{
                  backgroundColor: "#f9f9f9",
                  borderRadius: "10px",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Group mr={15} gap={15} align="end" justify="end">
                  {invoiceReceiptsPaymentModels?.paymentReceiptDetail
                    ?.completiontime ? (
                    <>
                      <Button color={"blue"} size="sm" disabled>
                        Đã khóa
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        disabled
                        variant="filled"
                        leftSection={<IconCheck size={14} />}
                        color="#5a47b0"
                        // onClick={() => fetchCreateReceiptParent(true)}
                      >
                        Đã lưu
                      </Button>
                    </>
                  )}
                </Group>
              </Card>
            </GridCol>
          </Grid>
        </>
      ) : (
        <>
          <Box pl={20} pr={0} bg="#fff" ref={headerRef}>
            <Grid>
              <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
                <Flex align="center" gap="xs">
                  <_breadcrumb></_breadcrumb>
                </Flex>
              </Grid.Col>
              <Grid.Col
                style={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
                span={{ base: 12, md: 6, lg: 4 }}
              >
                {receptype === 1 ? (
                  <>
                    {type === 2 ? (
                      <Badge size="xl" color="#FF8800">
                        Phiếu khác
                      </Badge>
                    ) : null}
                  </>
                ) : type === 1 ? (
                  <Badge size="xl" color="blue">
                    Phiếu chi
                  </Badge>
                ) : type === 3 ? (
                  <Badge size="xl" color="gray">
                    Phiếu báo nợ
                  </Badge>
                ) : type === 4 ? (
                  <Badge size="xl" color="#9D3BC7">
                    Phiếu báo có
                  </Badge>
                ) : null}
              </Grid.Col>
              <Grid.Col span={{ base: 0, md: 0, lg: 4 }}></Grid.Col>
            </Grid>
          </Box>
          <Divider my="md" mb={0} />
          <Box p={15} bg={"#f8f8f8"}>
            <Grid gutter="xs">
              <Grid.Col span={{ base: 12, md: 6, lg: 5 }}>
                <Card
                  shadow="sm"
                  radius="md"
                  withBorder
                  p="lg"
                  pr={0}
                  pt={15}
                  style={{
                    maxHeight: height + 20,
                    minHeight: height + 20,
                  }}
                >
                  <ScrollArea w="100%" h={height + 600} offsetScrollbars>
                    <Grid>
                      <Grid.Col pb={0} span={{ base: 12, md: 12, lg: 6 }}>
                        <Flex>
                          <Text
                            style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}
                          >
                            {receptype === 1 && type === 2
                              ? "Mã phiếu thu"
                              : type === 3
                              ? "Mã báo nợ"
                              : type === 4
                              ? "Mã báo có"
                              : "Mã phiếu chi"}
                          </Text>
                          <Text fw={600} style={{ whiteSpace: "nowrap" }}>
                            {
                              invoiceReceiptsPaymentModels?.paymentReceiptDetail
                                ?.receiptNumber
                            }
                          </Text>
                        </Flex>
                      </Grid.Col>
                      <Grid.Col pb={0} span={{ base: 12, md: 12, lg: 6 }}>
                        <Flex>
                          <Text
                            style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}
                          >
                            Thời gian tạo
                          </Text>
                          <Text fw={500} style={{ whiteSpace: "nowrap" }}>
                            {formatDateTransferLastYear(
                              invoiceReceiptsPaymentModels?.paymentReceiptDetail
                                ?.createdDate
                            )}
                          </Text>
                        </Flex>
                      </Grid.Col>
                      <Grid.Col span={{ base: 12, md: 12, lg: 6 }} pb={0}>
                        {(receptype === 1 && type === 3) || type === 4 ? (
                          <Flex align="center">
                            <Text
                              style={{
                                flex: "0 0 130px",
                                whiteSpace: "nowrap",
                              }}
                            >
                              Mã GD ngân hàng
                            </Text>
                            <Text fw={600} style={{ whiteSpace: "nowrap" }}>
                              {invoiceReceiptsPaymentModels
                                ?.paymentReceiptDetail?.attribute8 ?? ""}
                            </Text>
                          </Flex>
                        ) : null}
                      </Grid.Col>
                      <Grid.Col span={{ base: 12, md: 12, lg: 6 }} pb={0}>
                        <Flex>
                          <Text
                            style={{ flex: "0 0 150px", whiteSpace: "nowrap" }}
                          >
                            Thời gian hoàn thành
                          </Text>
                          <Text fw={500} style={{ whiteSpace: "nowrap" }}>
                            {formatDateTransferLastYear(
                              invoiceReceiptsPaymentModels?.paymentReceiptDetail
                                ?.completiontime
                            )}
                          </Text>
                        </Flex>
                      </Grid.Col>
                      <Grid.Col pt={0} pb={0} span={12}>
                        <Divider
                          my="xs"
                          label={
                            <Text size="lg" fw={600} c="#000">
                              {receptype === 1 && type === 2
                                ? "Người lập phiếu thu"
                                : "Người lập phiếu chi"}
                            </Text>
                          }
                          labelPosition="left"
                        />
                      </Grid.Col>
                      <Grid.Col pb={0} span={{ base: 12, md: 12, lg: 6 }}>
                        <Flex>
                          <Text
                            style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}
                          >
                            Chi nhánh
                          </Text>
                          <Text fw={600} style={{ whiteSpace: "nowrap" }}>
                            {locationBranch?.name ?? ""}
                          </Text>
                        </Flex>
                      </Grid.Col>
                      <Grid.Col
                        pt={0}
                        pb={0}
                        span={{ base: 12, md: 12, lg: 6 }}
                      >
                        <Flex>
                          <Text
                            style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}
                          >
                            Họ và tên
                          </Text>
                          <Text fw={600} style={{ whiteSpace: "nowrap" }}>
                            {(() => {
                              const data = listEmployee?.find(
                                (item: any) =>
                                  item.value ===
                                  invoiceReceiptsPaymentModels
                                    ?.paymentReceiptDetail?.creatorfullname
                              );

                              return data
                                ? `${data.text} - ${data.att1}`
                                : null;
                            })()}
                          </Text>
                        </Flex>
                      </Grid.Col>

                      <Grid.Col pb={0} span={{ base: 12, md: 12, lg: 6 }}>
                        <Flex>
                          <Text
                            style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}
                          >
                            ĐĐ làm việc
                          </Text>
                          <Text fw="600">
                            {" "}
                            {
                              invoiceReceiptsPaymentModels?.paymentReceiptDetail
                                ?.creatorworkplace
                            }
                          </Text>
                        </Flex>
                      </Grid.Col>
                      <Grid.Col pb={0} span={{ base: 12, md: 12, lg: 6 }}>
                        <Flex>
                          <Text
                            style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}
                          >
                            Chức danh
                          </Text>
                          <Text fw="600">
                            {
                              dataRole?.find(
                                (item: any) =>
                                  String(item.id) ==
                                  String(
                                    invoiceReceiptsPaymentModels
                                      ?.paymentReceiptDetail?.createrolename
                                  )
                              )?.name
                            }
                          </Text>
                        </Flex>
                      </Grid.Col>
                      <Grid.Col pb={0} span={{ base: 12, md: 12, lg: 6 }}>
                        <Flex>
                          <Text
                            style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}
                          >
                            Phòng ban
                          </Text>
                          <Text fw={600} style={{ whiteSpace: "nowrap" }}>
                            {
                              dataGetDepartments?.find(
                                (item: any) =>
                                  item.value ==
                                  invoiceReceiptsPaymentModels
                                    ?.paymentReceiptDetail?.creatordepartment
                              )?.text
                            }
                          </Text>
                        </Flex>
                      </Grid.Col>
                      <Grid.Col pt={0} pb={0} span={12}>
                        <Divider
                          my="xs"
                          label={
                            <Text size="lg" fw={600} c="#000">
                              {receptype === 1 && type === 2
                                ? "Người nộp tiền"
                                : "Người nhận tiền"}
                            </Text>
                          }
                          labelPosition="left"
                        />
                      </Grid.Col>
                      <Grid.Col pt={0} pb={0} span={12}>
                        <Group style={{ flexWrap: "nowrap" }}>
                          <Text
                            style={{ flex: "0 0 90px", whiteSpace: "nowrap" }}
                          >
                            Loại
                          </Text>
                          <Text fw={600}>
                            {invoiceReceiptsPaymentModels?.paymentReceiptDetail
                              ?.payertype ?? ""}
                          </Text>
                        </Group>
                      </Grid.Col>
                      <Grid.Col pb={0} pt={0} span={12}>
                        <Divider
                          my="xs"
                          label={
                            <Text size="lg" fw={600} c="#000">
                              Nội bộ
                            </Text>
                          }
                          labelPosition="left"
                        />
                      </Grid.Col>
                      <Grid.Col
                        pt={0}
                        span={{ xs: 12, sm: 6, md: 6, lg: 6, lx: 6 }}
                      >
                        <Flex align="center">
                          <Text
                            style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}
                          >
                            Chi nhánh
                          </Text>
                          <Text fw="600">
                            {dataBranchHandle?.find(
                              (item: SelectListItemBase) =>
                                item.value ===
                                invoiceReceiptsPaymentModels
                                  ?.paymentReceiptDetail?.fundbranch
                            )?.text || null}
                            {}
                          </Text>
                        </Flex>
                      </Grid.Col>
                      <Grid.Col
                        pt={0}
                        span={{ xs: 12, sm: 6, md: 6, lg: 6, lx: 6 }}
                      >
                        <Flex align="center">
                          <Text
                            style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}
                          >
                            Họ và tên
                          </Text>
                          <Text fw={600}>
                            {(() => {
                              const data = listEmployeeDetail?.find(
                                (item: any) =>
                                  String(item.id) ===
                                  String(
                                    invoiceReceiptsPaymentModels
                                      ?.paymentReceiptDetail?.funfullname
                                  )
                              );
                              return data
                                ? `${data.fullname} - ${data.code}`
                                : null;
                            })()}
                          </Text>
                        </Flex>
                      </Grid.Col>
                      <Grid.Col
                        pt={0}
                        pb={0}
                        span={{ xs: 12, sm: 6, md: 6, lg: 6, lx: 6 }}
                      >
                        <Flex align="center">
                          <Text
                            style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}
                          >
                            ĐĐ Làm việc
                          </Text>
                          <Text fw="600">
                            {
                              invoiceReceiptsPaymentModels?.paymentReceiptDetail
                                ?.fundworkplace
                            }
                          </Text>
                        </Flex>
                      </Grid.Col>
                      <Grid.Col
                        pt={0}
                        pb={0}
                        span={{ xs: 12, sm: 6, md: 6, lg: 6, lx: 6 }}
                      >
                        <Flex align="center">
                          <Text
                            style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}
                          >
                            Chức danh
                          </Text>
                          <Text fw="600">
                            {invoiceReceiptsPaymentModels?.paymentReceiptDetail
                              ?.attribute3 ?? ""}
                          </Text>
                        </Flex>
                      </Grid.Col>
                      <Grid.Col
                        pt={10}
                        span={{ xs: 12, sm: 6, md: 6, lg: 6, lx: 6 }}
                      >
                        <Flex align="center">
                          <Text
                            style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}
                          >
                            Phòng ban
                          </Text>
                          <Text fw="600">
                            {dataGetDepartments?.find(
                              (item: any) =>
                                item.value ===
                                invoiceReceiptsPaymentModels
                                  ?.paymentReceiptDetail?.funddepartment
                            )?.text ?? ""}
                          </Text>
                        </Flex>
                      </Grid.Col>
                      <Grid.Col pt={0} pb={0} span={12}>
                        <Divider
                          my="xs"
                          label={
                            <Text size="lg" fw={600} c="#000">
                              Khác
                            </Text>
                          }
                          labelPosition="left"
                        />
                      </Grid.Col>
                      <Grid.Col
                        pt={0}
                        span={{ base: 12, xs: 12, sm: 6, md: 6, lg: 6, lx: 6 }}
                      >
                        <Flex align="center">
                          <Text
                            style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}
                          >
                            Mã KH/NCC
                          </Text>
                          <Text fw="600">
                            {addressInfoDetail?.find(
                              (item) =>
                                String(item.id) ===
                                String(
                                  invoiceReceiptsPaymentModels
                                    ?.paymentReceiptDetail?.codecustomer
                                )
                            )?.code ||
                              customerDetail?.find(
                                (item) =>
                                  String(item.id) ===
                                  String(
                                    invoiceReceiptsPaymentModels
                                      ?.paymentReceiptDetail?.codecustomer
                                  )
                              )?.vendorCode}
                          </Text>
                        </Flex>
                      </Grid.Col>
                      <Grid.Col
                        pt={0}
                        span={{ base: 12, xs: 12, sm: 6, md: 6, lg: 6, lx: 6 }}
                      >
                        <Flex align="center">
                          <Text
                            style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}
                          >
                            Tên KH/NCC
                          </Text>
                          <Text fw="600">
                            {
                              invoiceReceiptsPaymentModels?.paymentReceiptDetail
                                ?.anothername
                            }
                          </Text>
                        </Flex>
                      </Grid.Col>

                      <Grid.Col
                        offset={{ base: 0, xs: 0, sm: 6, md: 6, lg: 6, lx: 6 }}
                        span={{ base: 12, xs: 12, sm: 6, md: 6, lg: 6, lx: 6 }}
                        pt={0}
                      >
                        <Flex align="center">
                          <Text
                            style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}
                          >
                            Số điện thoại
                          </Text>
                          <Text fw={600}>
                            {invoiceReceiptsPaymentModels?.paymentReceiptDetail
                              ?.phonenumber ?? ""}
                          </Text>
                        </Flex>
                      </Grid.Col>
                      <Grid.Col pt={0} pb={0} span={12}>
                        <Divider
                          my="xs"
                          label={
                            <Text size="lg" fw={600} c="#000">
                              Đối tượng công nợ
                            </Text>
                          }
                          labelPosition="left"
                        />
                      </Grid.Col>
                      <Grid.Col
                        pt={0}
                        span={{ base: 12, xs: 12, sm: 6, md: 6, lg: 6, lx: 6 }}
                      >
                        <Flex align="center">
                          <Text
                            style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}
                          >
                            Mã ĐTCN
                          </Text>
                          <Text fw="600">
                            {invoiceReceiptsPaymentModels?.paymentReceiptDetail
                              ? [
                                  invoiceReceiptsPaymentModels
                                    .paymentReceiptDetail.attribute2 !== "null"
                                    ? invoiceReceiptsPaymentModels
                                        .paymentReceiptDetail.attribute2
                                    : "",
                                  invoiceReceiptsPaymentModels
                                    .paymentReceiptDetail.attribute7 !== "null"
                                    ? invoiceReceiptsPaymentModels
                                        .paymentReceiptDetail.attribute7
                                    : "",
                                ]
                                  .filter(Boolean)
                                  .join(" - ") || ""
                              : ""}
                          </Text>
                        </Flex>
                      </Grid.Col>
                    </Grid>
                  </ScrollArea>
                </Card>
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6, lg: 7 }}>
                <Card
                  shadow="sm"
                  radius="md"
                  withBorder
                  p="md"
                  style={{
                    backgroundColor: "#fff",
                    borderRadius: "10px",
                    padding: "20px",
                    boxShadow: "0 1px 10px rgba(0, 0, 0, 0.1)",
                    maxHeight: height + 20,
                    minHeight: height + 20,
                  }}
                >
                  <Flex align="center">
                    <Text
                      style={{ flex: "0 0 100px", whiteSpace: "nowrap" }}
                      mb={15}
                      fw={600}
                    >
                      Tỷ giá <span style={{ color: "red" }}>*</span>
                    </Text>
                    <Table layout="fixed" withTableBorder withColumnBorders>
                      <Table.Tbody>
                        <Table.Tr>
                          <Table.Td align="left">
                            <Text fw={600}>Đơn vị tính</Text>
                          </Table.Td>
                          <Table.Td align="center">
                            <Text fw={600}>Tỉ giá</Text>
                          </Table.Td>
                          <Table.Td align="center">
                            <Text fw={600}>Thành tiền</Text>
                          </Table.Td>
                        </Table.Tr>
                        <Table.Tr>
                          <Table.Td>
                            <Text fw={600}>
                              {dataExchangeRate.find(
                                (ele) =>
                                  ele.currencyType ===
                                  invoiceReceiptsPaymentModels
                                    ?.paymentReceiptDetail?.accountfund
                              )?.calculation ?? ""}
                            </Text>
                          </Table.Td>
                          <Table.Td align="right">
                            <NumberFormatter
                              style={{ fontWeight: "bold" }}
                              value={
                                dataExchangeRate.find(
                                  (ele) =>
                                    ele.currencyType ===
                                    invoiceReceiptsPaymentModels
                                      ?.paymentReceiptDetail?.accountfund
                                )?.value ?? ""
                              }
                              thousandSeparator
                            ></NumberFormatter>
                          </Table.Td>
                          <Table.Td align="right">
                            <NumberFormatter
                              value={
                                invoiceReceiptsPaymentModels
                                  ?.paymentReceiptDetail?.totalAmount
                              }
                              style={{
                                fontSize: "15px",
                                fontWeight: 700,
                                color: "red",
                              }}
                              thousandSeparator
                            />
                          </Table.Td>
                        </Table.Tr>
                      </Table.Tbody>
                    </Table>
                  </Flex>
                  <Flex mt={20} align="center">
                    <Text
                      style={{ flex: "0 0 100px", whiteSpace: "nowrap" }}
                      fw={600}
                    >
                      {type === 2 || type === 4
                        ? "Tài khoản nợ"
                        : "Tài khoản có"}
                      <span style={{ color: "red" }}>*</span>
                    </Text>
                    <Table layout="fixed" withTableBorder withColumnBorders>
                      <Table.Tbody>
                        <Table.Tr>
                          <Table.Td align="right">
                            <Text fw={600}>
                              {
                                invoiceReceiptsPaymentModels
                                  ?.paymentReceiptDetail?.codefund
                              }
                            </Text>
                          </Table.Td>
                          <Table.Td align="left">
                            <Text fw={600}>
                              {invoiceReceiptsPaymentModels
                                ?.paymentReceiptDetail?.attribute6 ??
                                (dataExchangeRate.find(
                                  (ele) =>
                                    String(ele.id) ===
                                    String(
                                      invoiceReceiptsPaymentModels
                                        ?.paymentReceiptDetail?.codefund
                                    )
                                )?.calculation ||
                                  "")}
                            </Text>
                          </Table.Td>
                        </Table.Tr>
                      </Table.Tbody>
                    </Table>
                  </Flex>
                  <Group mt={20}>
                    <MantineReactTable table={debtAccountTable} />
                  </Group>
                  <Group mt={15}>
                    <Flex w="60%" align="center" gap={20}>
                      <Text
                        style={{ flex: "0 0 90px", whiteSpace: "nowrap" }}
                        fw={700}
                        c="red"
                      >
                        Tổng tiền
                      </Text>
                      <Text fw={700} c="red">
                        <NumberFormatter
                          thousandSeparator=","
                          suffix=" VND"
                          value={
                            invoiceReceiptsPaymentModels?.paymentReceiptDetail
                              ?.totalAmount
                          }
                        />
                      </Text>
                    </Flex>
                  </Group>
                  <Group mr={15} gap={15} align="end" justify="end">
                    {invoiceReceiptsPaymentModels?.paymentReceiptDetail
                      ?.completiontime ? (
                      <>
                        <Button color={"blue"} size="sm" disabled>
                          Đã khóa
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          variant="filled"
                          disabled
                          leftSection={<IconCheck size={14} />}
                          color="#5a47b0"
                          // onClick={() => fetchCreateReceiptParent(true)}
                        >
                          Đã lưu
                        </Button>
                      </>
                    )}
                  </Group>
                </Card>
              </Grid.Col>
            </Grid>
          </Box>
        </>
      )}
    </>
  );
};

export default DetailListReceipts;

const orderInfoStyle = {
  display: "flex",
  flexDirection: "column" as "column",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center" as "center",
  padding: "10px",
  border: "1px solid #e0e0e0",
  borderRadius: "8px",
  margin: "5px",
};

const infoLabelStyle = {
  fontSize: "14px",
  color: "#555",
  marginBottom: "5px",
};

const infoValueStyle = {
  fontSize: "14px",
  fontWeight: "bold" as "bold",
  color: "#000",
};
