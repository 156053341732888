//#region start
import {
  Badge,
  Box,
  Button,
  Fieldset,
  Flex,
  Grid,
  LoadingOverlay,
  Menu,
  NumberInput,
  ScrollArea,
  Select,
  Table,
  Text,
  Textarea,
  TextInput,
  Tooltip,
} from "@mantine/core";
import { useDebouncedState } from "@mantine/hooks";
import {
  IconArrowBack,
  IconCheck,
  IconEdit,
  IconTrash,
} from "@tabler/icons-react";
import React, { useEffect, useState } from "react";

import { useClickOutside } from "@mantine/hooks";
import { IconPlus } from "@tabler/icons-react";
import {
  MantineReactTable,
  MRT_ColumnDef,
  useMantineReactTable,
} from "mantine-react-table";
import { useLocation, useNavigate } from "react-router-dom";
import { repositoryMdm } from "../../../../_base/_const/_constVar";
import { NotificationExtension } from "../../../../_base/extension/NotificationExtension";
import { styleCellTable } from "../../../../_setup/navdata/baseStyleCellTable";
import { _localization } from "../../../../config/location";
import { BorrowTicket } from "../../../../model/TblWarrantyItemBorrowTicket";

type ProductColumn = {
  type: string; // loại sản phẩm, ví dụ "Sản phẩm bảo hành" hoặc "cho mượn"
  itemCode: string; // mã sản phẩm
  itemName: string; // tên sản phẩm
  uomName: string; // đơn vị tính
  amount: number; // số lượng
  acessory?: string; // phụ kiện đi kèm (có thể là chuỗi hoặc null)
  condition?: string; // tình trạng sản phẩm
  priceOld: number; // giá cũ của sản phẩm
  priceNew?: number; // giá hiện hành của sản phẩm
  note?: string; // ghi chú sản phẩm
};
const formatCurrency = (value: any) => {
  if (value == null) {
    return "";
  }
  // Định dạng số theo chuẩn Việt Nam
  return value.toLocaleString("vi-VN", {
    minimumFractionDigits: 0, // Không hiển thị phần thập phân
    maximumFractionDigits: 0, // Đảm bảo không có chữ số sau dấu phẩy
  });
};
const EditExportBorred = () => {
  const location = useLocation();
  const headerRef = React.useRef<HTMLDivElement>(null);
  const contentRef = React.useRef<HTMLDivElement>(null);
  const [totaldeposit, setTotalDeposit] = useState<null | number>(null);
  const navigate = useNavigate();
  const [height, setHeight] = useState(0);
  const [textNote, setTextNote] = useDebouncedState("", 200);
  const [dataWarrantyProduct, setDataWarrantyProducts] = useState<
    ProductColumn[]
  >([]);
  const [itemBorrowID, setItemBorrowID] = useState<number>(0);
  // const token = localStorage.getItem("token");

  //#region fetch data update
  //fetch data update
  const [dataUpdate, setDataUpdate] = useState<BorrowTicket>();
  const fetchDataUpdate = async () => {
    try {
      const response = await repositoryMdm.get(
        `/api/v1/TblWarrantyExportItemBorrowTicket/update?id=${location.state.id}`
      );
      if (response && response.httpStatusCode === 200) {
        setDataUpdate(response.data);
        setTotalDeposit(response.data.totalDeposit);
        setDataSelectLoanProduct(
          response.data.tblWarrantyExItemBorrowTicketAddDetailModels
        );
        const itemBorrowID =
          response.data.tblWarrantyExItemBorrowTicketAddDetailModels[0]
            ?.itemBorrowId || 0;
        setItemBorrowID(itemBorrowID);
        setDataWarrantyProducts(
          response.data.tblWarrantyExportItemBorrowTicketDetailModels
        );
      }
    } catch (error) {
      NotificationExtension.Fails("Lỗi khi lấy dữ liệu");
    }
  };
  useEffect(() => {
    fetchDataUpdate();
  }, [location.state.id]);
  //#endregion fetch data update
  // #region table
  const [isEditing, setIsEditing] = useState(false); //lưu trạng thái editting
  const [searchItem, setSearchItem] = useDebouncedState("", 300);
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string | undefined>
  >({});
  // dữ liệu list Sản phẩm cho mượn
  const [dataSearchLoanRequest, setDataSearchLoanRequest] = useState<[]>([]); //danh sách Sản phẩm cho mượn
  const [selectedItemCodes, setSelectedItemCodes] = useState<string[]>([]); //thêm mã sản phẩm vào mảng
  const [dataSelectLoanProduct, setDataSelectLoanProduct] = useState<
    ProductColumn[]
  >([]); //sản phẩm đã chọn

  // lấy dữ liệu Sản phẩm cho mượn
  const fetchDataLoanRequest = async () => {
    try {
      let url = `?Skip=0&Take=100`;
      if (searchItem) {
        url += `&KeySearch=${searchItem}`;
      }
      const response = await repositoryMdm.get(
        `/api/v1/TblWarrantyExportItemBorrowTicket/get-list-item${url}`
      );
      if (response && response.success) {
        let result = response.data;
        if (dataSelectLoanProduct && dataSelectLoanProduct.length > 0) {
          result = result.filter(
            (item: any) =>
              !selectedItemCodes.includes(item.itemCode) &&
              !dataSelectLoanProduct.some(
                (x: any) => x.itemCode === item.itemCode
              )
          );
        }
        setDataSearchLoanRequest(result);
      }
    } catch (error) {
      NotificationExtension.Fails("Lỗi khi lấy dữ liệu Sản phẩm cho mượn");
    }
  };
  const TableSelect = ({ data }: any) => (
    <Table.ScrollContainer minWidth={500} mah={250} type="native">
      <Table striped highlightOnHover stickyHeader>
        <Table.Thead>
          <Table.Tr>
            <Table.Th />
            <Table.Th>Mã sản phẩm</Table.Th>
            <Table.Th>Tên sản phẩm</Table.Th>
            <Table.Th>Giá</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {data.map((item: any) => {
            return (
              <Table.Tr key={item.itemCode}>
                <Table.Td>
                  <Flex justify={"center"}>
                    <Button
                      onClick={() => {
                        handSelectItem(item);
                        table.setCreatingRow(null);
                      }}
                      variant="filled"
                      color="teal"
                      disabled={selectedItemCodes.includes(item.itemCode)}
                    >
                      {selectedItemCodes.includes(item.itemCode)
                        ? "Đã chọn"
                        : "Chọn"}
                    </Button>
                  </Flex>
                </Table.Td>
                <Table.Td>{item.itemCode}</Table.Td>
                <Table.Td>{item.itemName}</Table.Td>
                <Table.Td>{item.listPrice}</Table.Td>
              </Table.Tr>
            );
          })}
        </Table.Tbody>
        {data.length < 1 && (
          <Table.Caption>Không tìm thấy sản phẩm!</Table.Caption>
        )}
      </Table>
    </Table.ScrollContainer>
  );
  const handSelectItem = (item: any) => {
    // Thêm mã sản phẩm đã chọn vào mảng
    setSelectedItemCodes((prev) => [...prev, item.itemCode]);

    setDataSelectLoanProduct((prev) => {
      // Gộp Sản phẩm cho mượn cũ và Sản phẩm cho mượn mới
      const loanProduct = {
        type: "Sản phẩm cho mượn",
        id: item.id,
        itemId: item.itemId,
        itemCode: item.itemCode,
        itemBorrowId: itemBorrowID,
        itemName: item.itemName,
        priceOld: item.listPrice,
        uomName: item.subUomCode,
        amount: item.amount == undefined ? 1 : item.amount,
      };
      return [loanProduct, ...prev];
    });
    setSearchItem("");
  };
  //#region dataAll
  let dataAll = [...dataSelectLoanProduct, ...dataWarrantyProduct];

  const columns = React.useMemo<MRT_ColumnDef<ProductColumn>[]>(
    () => [
      {
        accessorKey: "type",
        header: "Loại",
        enableEditing: false,
        size: 100,
        Cell: ({ row, renderedCellValue }: any) => {
          return (
            <>
              {renderedCellValue === "Sản phẩm bảo hành" ? (
                <Badge color="blue" radius="md">
                  Bảo hành
                </Badge>
              ) : (
                <Badge color="green" radius="md">
                  Cho mượn
                </Badge>
              )}
            </>
          );
        },
      },
      {
        accessorKey: "itemCode",
        header: "Mã sản phẩm",
        size: 100,
        enableEditing: (row) => {
          // Không cho edit nếu là "Sản phẩm bảo hành"
          if (row.original.type === "Sản phẩm bảo hành") {
            return false;
          }
          // Không cho edit nếu là "Sản phẩm cho mượn" và có trong selectedItemCodes
          if (
            row.original.type === "Sản phẩm cho mượn" &&
            selectedItemCodes.includes(row.original.itemCode)
          ) {
            return false;
          }
          // Cho phép edit nếu là "Sản phẩm cho mượn" và không có trong selectedItemCodes
          return true;
        },
        Edit: ({ cell, row, table, column }) => {
          const [keepRef, setKeepRef] = useState(false);
          const ref = useClickOutside(() => {
            if (!keepRef) {
              table.setEditingCell(null);
            }
          });

          return (
            <div ref={ref}>
              <Menu
                trapFocus={false}
                trigger="hover"
                shadow="md"
                width={"800"}
                position="bottom-start"
              >
                <Menu.Target>
                  <TextInput
                    defaultValue={row._valuesCache[column.id]}
                    onChange={async (e) => {
                      const _key = e.target.value ?? "";
                      setSearchItem(_key);
                    }}
                  />
                </Menu.Target>
                <Menu.Dropdown>
                  <TableSelect data={dataSearchLoanRequest} />
                </Menu.Dropdown>
              </Menu>
            </div>
          );
        },
      },
      {
        accessorKey: "itemName",
        header: "Tên sản phẩm",
        enableEditing: false,
      },
      {
        accessorKey: "uomName",
        header: "Đơn vị tính",
        enableEditing: false,
        size: 100,
      },
      {
        accessorKey: "amount",
        header: "Số lượng",
        size: 100,
        enableEditing: (row) => row.original.type !== "Sản phẩm bảo hành",
        Edit: ({ row, column, table }) => {
          return (
            <NumberInput
              placeholder="Nhập số lượng"
              hideControls
              value={row._valuesCache[column.id]}
              min={1}
              onChange={(e) => {
                setDataSelectLoanProduct((prevData: any) => {
                  return prevData.map((item: any) => {
                    if (item.itemCode === row.original.itemCode) {
                      return { ...item, amount: e };
                    }
                    return item;
                  });
                });
              }}
            />
          );
        },
      },
      {
        accessorKey: "acessory",
        header: "Phụ kiện theo kèm",
        enableEditing: (row) => row.original.type !== "Sản phẩm bảo hành",
        size: 100,
        Edit: ({ row, cell, column }) => {
          return (
            <>
              <TextInput
                type="text"
                defaultValue={row._valuesCache[column.id]}
                onChange={(e) => {
                  const newValue = e.target.value;
                  setDataSelectLoanProduct((prevData: any) => {
                    return prevData.map((item: any) => {
                      if (item.itemCode === row.original.itemCode) {
                        return { ...item, acessory: newValue };
                      }
                      return item;
                    });
                  });
                }}
                onBlur={() => {
                  table.setEditingCell(null);
                }}
              />
            </>
          );
        },
      },
      {
        accessorKey: "condition",
        header: "Tình trạng",
        size: 170,
        Edit: ({ row, cell, column }) => {
          const isWarrantyProduct = row.original.type === "Sản phẩm bảo hành";

          return (
            <>
              <Select
                data={["Mới 100%", "Đã qua sử dụng"]}
                defaultValue={row._valuesCache[column.id]}
                onChange={(newValue) => {
                  if (isWarrantyProduct) {
                    setDataWarrantyProducts((prevData: any) => {
                      return prevData.map((item: any) => {
                        if (item.itemCode === row.original.itemCode) {
                          return { ...item, condition: newValue };
                        }
                        return item;
                      });
                    });
                  } else {
                    setDataSelectLoanProduct((prevData: any) => {
                      return prevData.map((item: any) => {
                        if (item.itemCode === row.original.itemCode) {
                          return { ...item, condition: newValue };
                        }
                        return item;
                      });
                    });
                  }
                }}
                onBlur={() => {
                  table.setEditingCell(null);
                }}
              />
            </>
          );
        },
      },
      {
        accessorKey: "priceOld",
        header: "Giá",
        size: 100,
        enableEditing: false,
        Cell: ({ renderedCellValue, row }: any) => (
          <>{Number(renderedCellValue)?.toLocaleString("vi-VN")} </>
        ),
      },
      {
        accessorKey: "priceNew",
        header: "Giá hiện hành",
        size: 100,
        Edit: ({ row, column, table }) => {
          const isWarrantyProduct = row.original.type === "Sản phẩm bảo hành";
          return (
            <NumberInput
              hideControls
              value={row._valuesCache[column.id]}
              min={1}
              onChange={(e) => {
                if (isWarrantyProduct) {
                  setDataWarrantyProducts((prevData) => {
                    return prevData.map((item: any) => {
                      if (item.itemCode === row.original.itemCode) {
                        return { ...item, priceNew: e };
                      }
                      return item;
                    });
                  });
                } else {
                  setDataSelectLoanProduct((prevData: any) => {
                    return prevData.map((item: any) => {
                      if (item.itemCode === row.original.itemCode) {
                        return { ...item, priceNew: e };
                      }
                      return item;
                    });
                  });
                }
              }}
              onBlur={(e) => {
                table.setEditingCell(null);
                if (Number(e.target.value) < 1 || e.target.value === "") {
                  NotificationExtension.Fails(
                    `Giá hiện hành ${row.original.itemName} không được nhỏ hơn 1!`
                  );
                }
              }}
            />
          );
        },
        Cell: ({ renderedCellValue, row }: any) => (
          <>{renderedCellValue?.toLocaleString("vi-VN")} </>
        ),
      },
      {
        accessorKey: "note",
        header: "Ghi chú",
        size: 100,
        Edit: ({ row, cell, column }) => {
          const isWarrantyProduct = row.original.type === "Sản phẩm bảo hành";

          return (
            <>
              <TextInput
                type="text"
                defaultValue={row._valuesCache[column.id]}
                onChange={(e) => {
                  const newValue = e.target.value;
                  if (isWarrantyProduct) {
                    setDataWarrantyProducts((prevData) => {
                      return prevData.map((item: any) => {
                        if (item.itemCode === row.original.itemCode) {
                          return { ...item, note: newValue };
                        }
                        return item;
                      });
                    });
                  } else {
                    setDataSelectLoanProduct((prevData: any) => {
                      return prevData.map((item: any) => {
                        if (item.itemCode === row.original.itemCode) {
                          return { ...item, note: newValue };
                        }
                        return item;
                      });
                    });
                  }
                }}
                onBlur={() => {
                  table.setEditingCell(null);
                }}
              />
            </>
          );
        },
      },
    ],
    [dataSearchLoanRequest, selectedItemCodes, dataSelectLoanProduct]
  );
  const table = useMantineReactTable({
    columns,
    data: dataAll || [],
    positionToolbarAlertBanner: "bottom",
    createDisplayMode: "row",
    editDisplayMode: "cell",
    enableEditing: location.state.status === 4 ? false : true,
    enableRowActions: true,
    enableColumnActions: false,
    enableColumnOrdering: false,
    enableBatchRowSelection: true,
    onCreatingRowCancel: () => setValidationErrors({}),
    onEditingRowCancel: () => setValidationErrors({}),
    positionActionsColumn: "last",
    renderRowActions: ({ row, table }) => {
      return location.state.status === 4 ? (
        <></>
      ) : (
        <Flex gap="4">
          <Tooltip label="Chỉnh sửa">
            <IconEdit
              color="orange"
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                // Toggle trạng thái chỉnh sửa
                setIsEditing((prev) => !prev);
                if (!isEditing) {
                  table.setEditingRow(row); // Bắt đầu chỉnh sửa
                } else {
                  table.setEditingRow(null); // Hủy chỉnh sửa
                }
              }}
            ></IconEdit>
          </Tooltip>
          {row.original.type === "Sản phẩm cho mượn" && (
            <Tooltip label="Xóa">
              <IconTrash
                color="red"
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  setDataSelectLoanProduct((prevData) => {
                    return prevData.filter(
                      (item: any) => item.itemCode !== row.original.itemCode
                    );
                  });
                  setSelectedItemCodes((prev) =>
                    prev.filter((item) => item !== row.original.itemCode)
                  );
                }}
              />
            </Tooltip>
          )}
        </Flex>
      );
    },
    mantineTableBodyCellProps: ({ row, column }) => ({
      style: styleCellTable(row),
    }),
    renderToolbarInternalActions: ({ table }) => (
      <>
        <Flex
          gap="md"
          justify="flex-start"
          align="center"
          direction="row"
          wrap="wrap"
          mb={"xs"}
        >
          <Button
            leftSection={<IconPlus size={14} />}
            variant="outline"
            // color="blue"
            size="xs"
            onClick={() => {
              table.setCreatingRow(true);
            }}
            style={{
              pointerEvents: location.state.status === 4 ? "none" : "auto",
              color: location.state.status === 4 ? "gray" : "blue",
            }}
          >
            Thêm sản phẩm
          </Button>
        </Flex>
      </>
    ),

    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    mantineTableContainerProps: {
      style: { maxHeight: height - 148, minHeight: height - 148 },
    },
    initialState: {
      columnPinning: {
        left: ["mrt-row-select"],
        right: ["mrt-row-actions"],
      },
      columnVisibility: { id: true },
      density: "xs",
    },
    enableStickyHeader: true,
    manualSorting: false,
    enablePagination: false,
    enableBottomToolbar: false,
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
  });

  //#region data XNHM
  const mapProductData = (product: any, type: string) => {
    if (type === "loan") {
      //sp cho mượn
      return {
        id: product?.id,
        itemBorrowId: product?.itemBorrowId,
        itemId: product.itemId || 0,
        deposit: 1,
        condition: product.condition || "",
        priceNew: product.priceNew,
        note: product.note || "",
        type: product.type,
        amount: product.amount,
        priceOld: product.priceOld,
        acessory: product.acessory || "",
        attribute1: new Date().toISOString(),
        attribute2: "",
        attribute3: "",
        attribute4: 0,
        attribute5: 0,
        attribute6: 0,
      };
    } else if (type === "warranty") {
      //sp bảo hành
      return {
        id: product?.id,
        itemBorrowId: product?.itemBorrowId,
        deposit: 1,
        condition: product.condition || "",
        priceNew: product.priceNew || 0,
        note: product.note || "",
        type: product.type,
        priceOld: product.priceOld || 0,
        itemId: product.itemId || 0,
        attribute1: new Date().toISOString(),
        attribute2: "",
        attribute3: "",
        attribute4: 0,
        attribute5: 0,
        attribute6: 0,
      };
    }
  };

  const prepareInitValueCreate = () => {
    const loanProducts = dataSelectLoanProduct.map((product) =>
      mapProductData(product, "loan")
    );

    const warrantyProducts = dataWarrantyProduct.map((product) =>
      mapProductData(product, "warranty")
    );

    return {
      id: location.state.id,
      receiveId: dataUpdate?.receiveId,
      borrowCode: dataUpdate?.borrowCode,
      totalDeposit: totaldeposit,
      note: textNote,
      status: dataUpdate?.status,
      createDate: dataUpdate?.createDate,
      lastUpdateBy: 0,
      lastUpdateDate: "",
      createBy: dataUpdate?.createBy,
      attribute1: "string",
      attribute2: dataUpdate?.attribute2,
      attribute3: dataUpdate?.attribute3,
      attribute4: 0,
      attribute5: 0,
      attribute6: 0,
      tblWarrantyExportItemBorrowTicketDetailCommands: warrantyProducts,
      tblWarrantyExItemBorrowTicketAddDetailCommands: loanProducts,
    };
  };
  const onhandleCreate = async () => {
    const initValueCreate = prepareInitValueCreate();
    if (dataWarrantyProduct) {
      if (dataWarrantyProduct.length === 0) {
        NotificationExtension.Fails("Vui lòng chọn Sản phẩm bảo hành");
        return;
      } else {
        for (const item of dataWarrantyProduct) {
          if (!item.condition) {
            NotificationExtension.Fails(
              "Vui lòng nhập tình trạng Sản phẩm bảo hành"
            );
            return;
          }
          if (item.priceNew === undefined) {
            NotificationExtension.Fails(
              "Vui lòng nhập giá hiện hành Sản phẩm bảo hành"
            );
            return;
          }
        }
      }
    }
    if (dataSelectLoanProduct) {
      if (dataSelectLoanProduct.length === 0) {
        NotificationExtension.Fails("Vui lòng chọn Sản phẩm cho mượn");
        return;
      } else {
        for (const item of dataSelectLoanProduct) {
          if (!item.amount) {
            NotificationExtension.Fails(
              "Vui lòng nhập số lượng Sản phẩm cho mượn"
            );
            return;
          }
          if (!item.condition) {
            NotificationExtension.Fails(
              "Vui lòng nhập tình trạng Sản phẩm cho mượn"
            );
            return;
          }
          if (item.priceNew === undefined) {
            NotificationExtension.Fails(
              "Vui lòng nhập giá hiện hành Sản phẩm cho mượn"
            );
            return;
          }
        }
      }
    }

    if (initValueCreate !== null) {
      try {
        const response = await repositoryMdm.post(
          "/api/v1/TblWarrantyExportItemBorrowTicket/update",
          initValueCreate
        );
        if (response && response.httpStatusCode === 200) {
          NotificationExtension.Success("Cập nhật thành công");
        }
      } catch (error) {
        NotificationExtension.Fails("Lỗi khi tạo phiếu xuất nhập hàng mượn");
      }
    }
  };

  useEffect(() => {
    if (dataUpdate && dataAll) {
      const dateCreate = new Date(dataUpdate.createDate); // Ngày nhận bảo hành

      const result =
        dataUpdate.tblWarrantyExportItemBorrowTicketDetailModels.map(
          (item: any) => {
            if (item.buyingDate === null)
              return NotificationExtension.Fails("Ngày mua không hợp lệ");
            const dateBuy = new Date(item.buyingDate); // Ngày mua
            const latestPrice = item.priceOld; // Giá lúc mua
            // const priceNew = data.priceNew; // Giá hiện hành
            const priceNew = dataAll.find(
              (x: any) => x.itemCode === item.itemCode
            )?.priceNew;
            const warrantyTerm = item.warrantyTerm; // Thời gian bảo hành (tính bằng tháng)
            // // Tính thời gian đã sử dụng (số tháng)
            const useTime =
              (dateCreate.getFullYear() - dateBuy.getFullYear()) * 12 +
              (dateCreate.getMonth() - dateBuy.getMonth());
            // Lấy giá nhỏ hơn giữa giá mua và giá hiện hành, sau đó tính 90% +khấu hao
            if (priceNew !== undefined) {
              const depreciationValue = Math.min(latestPrice, priceNew) * 0.9;
              // Tính giá trị đã khấu hao (dựa trên thời gian đã sử dụng và thời gian bảo hành)
              const depreciatedAmount =
                (depreciationValue * useTime) / warrantyTerm;

              // Tính giá thực trả khách
              const actualAmountPaid =
                Math.min(latestPrice, priceNew) - depreciatedAmount;

              return {
                actualAmountPaid,
              };
            } else {
              console.log("Giá hiện hành không hợp lệ");
            }

            // // Kiểm tra nếu ngày hiện tại chưa qua ngày mua trong tháng
            // if (dateCreate.getDate() < dateBuy.getDate()) {
            //   useTime--;
            // }
          }
        );
      if (result !== undefined) {
        let total = 0;
        let totalLoanProduct = 0;
        let totaldeposit = 0;
        result?.map((item: any) => {
          // return (total += item.actualAmountPaid);
          if (item !== undefined) {
            return (total += item.actualAmountPaid);
          }
          return total;
        });
        if (total > 0) {
          dataAll.map((item) => {
            if (item.type === "Sản phẩm cho mượn") {
              if (item.priceNew !== undefined) {
                return (totalLoanProduct += item.priceNew);
              }
            }
          });
        }
        if (totalLoanProduct > total) {
          setTotalDeposit(totalLoanProduct - total);
        } else {
          setTotalDeposit((totaldeposit = 0));
        }
      }
    }
  }, [dataAll]);

  useEffect(() => {
    if (totaldeposit && dataUpdate) {
      const updatedData = { ...dataUpdate, totalDeposit: totaldeposit };
      setDataUpdate(updatedData);
    }
  }, [setTotalDeposit]);
  const handleReturnPage = () => {
    navigate("/warranty/list-borrowed-goods");
  };

  useEffect(() => {
    fetchDataLoanRequest(); //lấy dữ liệu Sản phẩm cho mượn
  }, [searchItem, selectedItemCodes]);
  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const contentHeight = contentRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 160 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (200 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);
  return (
    <>
      <Box
        component="form"
        mx="auto"
        p={"0px 10px"}
        style={{ position: "relative" }}
      >
        <LoadingOverlay
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />
        <Flex
          justify={"space-between"}
          align={"center"}
          style={{ marginBottom: "10px" }}
        >
          <Flex w={"100%"} justify={"flex-end"} gap={10}>
            <Button
              type="button"
              color="red"
              onClick={() => handleReturnPage()}
              leftSection={<IconArrowBack size={18} />}
            >
              Thoát
            </Button>
            <Button
              type="button"
              color={"teal"}
              leftSection={<IconCheck size={18} />}
              onClick={() => onhandleCreate()}
            >
              Lưu
            </Button>
          </Flex>
        </Flex>
        <Grid>
          <Grid.Col span={5}>
            <ScrollArea h={height + 80} scrollbars="y">
              <Grid>
                <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                  <Fieldset legend="Thông tin khách hàng">
                    <Grid>
                      <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                        <TextInput
                          label="Mã khách hàng"
                          placeholder="Mã khách hàng"
                          readOnly
                          value={dataUpdate?.custCode}
                          variant="filled"
                        />
                      </Grid.Col>
                      <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                        <TextInput
                          label="Tên khách hàng"
                          placeholder="Tên khách hàng"
                          readOnly
                          value={dataUpdate?.custName}
                          variant="filled"
                        />
                      </Grid.Col>
                      <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                        <TextInput
                          label="Số điện thoại"
                          placeholder="Số điện thoại"
                          readOnly
                          value={dataUpdate?.phone}
                          variant="filled"
                        />
                      </Grid.Col>
                      <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                        <TextInput
                          label="Địa chỉ"
                          placeholder="Địa chỉ"
                          readOnly
                          value={dataUpdate?.address}
                          variant="filled"
                        />
                      </Grid.Col>
                    </Grid>
                  </Fieldset>
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                  <Fieldset legend="Thông tin phiếu yêu cầu xuất mượn">
                    <Grid>
                      <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                        <TextInput
                          label="Số phiếu yêu cầu hàng mượn"
                          placeholder="Số phiếu yêu cầu hàng mượn"
                          readOnly
                          value={dataUpdate?.attribute2}
                          variant="filled"
                        />
                      </Grid.Col>
                      <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                        <TextInput
                          label="Số phiếu tiếp nhận hàng lỗi"
                          placeholder="Nhập số phiếu tiếp nhận hàng lỗi"
                          readOnly
                          variant="filled"
                          value={dataUpdate?.requestCode}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                        <TextInput
                          label="Bộ phận / Phòng ban"
                          placeholder=""
                          readOnly
                          variant="filled"
                          value={dataUpdate?.branch}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                        <TextInput
                          label="Người lập"
                          placeholder=""
                          readOnly
                          variant="filled"
                          value={dataUpdate?.empName}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                        <TextInput
                          label="Ngày tạo phiếu"
                          placeholder=""
                          readOnly
                          variant="filled"
                          value={dataUpdate?.createDate}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                        <TextInput
                          label="Kho"
                          placeholder=""
                          readOnly
                          variant="filled"
                          value={dataUpdate?.invCode}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                        <TextInput
                          label="Chi nhánh"
                          placeholder=""
                          readOnly
                          variant="filled"
                          value={dataUpdate?.branch}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                        <TextInput
                          label="Chức danh"
                          placeholder=""
                          readOnly
                          variant="filled"
                          value={dataUpdate?.role}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                        <div>
                          <Textarea
                            h={60}
                            label="Ghi chú"
                            onChange={(e) => {
                              setTextNote(e.target.value);
                            }}
                            defaultValue={dataUpdate?.note}
                          />
                        </div>{" "}
                      </Grid.Col>
                    </Grid>
                  </Fieldset>
                </Grid.Col>
              </Grid>
            </ScrollArea>
          </Grid.Col>
          <Grid.Col span={7}>
            <Fieldset legend="Thông tin sản phẩm">
              <MantineReactTable table={table} />
            </Fieldset>
            <Flex justify={"start"} gap={"xs"} h={100} mt={5}>
              <Flex
                direction={"row"}
                align={"center"}
                justify={"center"}
                bd={"1px solid #DEE2E6"}
                style={{ borderRadius: "10px" }}
                gap={"md"}
                w={300}
              >
                <Text size="14px" fw={"bold"}>
                  Tổng số tiền cọc :
                </Text>
                <Text size="13px" fw={"bold"} c={"red"}>
                  {totaldeposit !== null ? formatCurrency(totaldeposit) : 0} VND
                </Text>
              </Flex>
            </Flex>
          </Grid.Col>
        </Grid>
      </Box>
    </>
  );
};

export default EditExportBorred;
