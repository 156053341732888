import { useForm } from "@mantine/form";
import { useDebouncedState, useDisclosure } from "@mantine/hooks";
import { useEffect, useMemo, useRef, useState } from "react";
import { repositoryMdm, repositoryPos } from "../../../_base/_const/_constVar";
import { MessageResponse } from "../../../model/MessageResponse";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import {
  Badge,
  Box,
  Button,
  Card,
  Center,
  Divider,
  Fieldset,
  FileInput,
  Flex,
  Grid,
  InputLabel,
  LoadingOverlay,
  Menu,
  ScrollArea,
  Select,
  Space,
  Table,
  Text,
  Textarea,
  TextInput,
} from "@mantine/core";
import { modals } from "@mantine/modals";
import { ComboboxItem } from "../../../model/ComboboxItem";
import { IconArrowLeft, IconCheck, IconX } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { sky_blue } from "../../../const/variables";
import { SocketExtension } from "../../../_base/socket/socket";

type TblDMApproveProposal = {
  id: string;
  code: string;
  idDepeartment: number;
  idWorkAddress: string;
  idPart: number;
  idRole: number;
  idEmployee: number;
  note: string;
  urlFile: string | null;
  priorityLevel: number | null;
  active: boolean | null;
  respond: string;
  idDepeartmentReceive: number;
  idPartReceive: number;
  idRoleReceive: number;
  idEmployeeReceive: number;
  fileNameExtension: string;
};

type User = {
  id: number;
  code: number;
  fullname: string;
  departmentId: number | null;
  departmentName: string | null;
  roleId: number;
  roleName: string;
  branchId: number;
  brachName: string;
  workAddress: string | null;
};

export const CreateProposalView = () => {
  const [enable, setEnable] = useState(true);
  const navigate = useNavigate();
  const [height, setHeight] = useState(0);
  const [file, setFile] = useState<File | null>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const [receiveUserList, setReceiveUserList] = useState<User[]>([]);
  const [username, setUsername] = useState("");
  const [visible, { close, open }] = useDisclosure(false);
  const priorityItems = useMemo<ComboboxItem[]>(
    () => [
      { label: "Cao", value: "1" },
      { label: "Thấp", value: "0" },
    ],
    []
  );
  const entity = {
    id: "",
    code: "",
    idDepeartment: 0,
    idWorkAddress: "",
    idPart: 0,
    idRole: 0,
    idEmployee: 0,
    note: "",
    urlFile: "",
    priorityLevel: 0,
    active: null,
    respond: "",
    idDepeartmentReceive: 0,
    idPartReceive: 0,
    idRoleReceive: 0,
    idEmployeeReceive: 0,
    fileNameExtension: "",
  };
  const [user, setUser] = useState<User>({
    id: 0,
    code: 0,
    fullname: "",
    departmentId: null,
    departmentName: null,
    roleId: 0,
    roleName: "",
    branchId: 0,
    brachName: "",
    workAddress: null,
  });
  const [selectedReceiveUser, setSelectedReceiveUser] = useState<User>({
    id: 0,
    code: 0,
    fullname: "",
    departmentId: null,
    departmentName: null,
    roleId: 0,
    roleName: "",
    branchId: 0,
    brachName: "",
    workAddress: null,
  });

  const form = useForm<TblDMApproveProposal>({
    mode: "uncontrolled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },

    validate: {
      code: (value: string) => {
        if (!value) {
          return "Vui lòng nhập mã đề xuất!";
        }
      },
      priorityLevel: (value: number | null) => {
        if (value === null) {
          return "Vui lòng chọn mức độ ưu tiên!";
        }
      },
      idEmployeeReceive: (value: number | null) => {
        if (!value) {
          return "Vui lòng chọn nhân viên nhận đề xuất!";
        }
      },
    },
  });

  function formatDate(isoDateString: string | Date, add?: boolean): string {
    const date: Date = new Date(isoDateString);

    // Cộng thêm 7 tiếng
    if (add) date.setHours(date.getHours() + 7);
    // Lấy các thành phần ngày giờ sau khi cộng thêm số giờ
    const hours: string = date.getHours().toString().padStart(2, "0");
    const minutes: string = date.getMinutes().toString().padStart(2, "0");
    const seconds: string = date.getSeconds().toString().padStart(2, "0");
    const day: string = date.getDate().toString().padStart(2, "0");
    const month: string = (date.getMonth() + 1).toString().padStart(2, "0"); // Tháng bắt đầu từ 0
    const year: number = date.getFullYear();

    // Định dạng thành chuỗi "giờ:phút:giây ngày-tháng-năm"
    return `${hours}:${minutes}:${seconds} ${day}-${month}-${year}`;
  }

  const callApiGetData = async () => {
    open();
    const callApi = await repositoryMdm.get<
      MessageResponse<TblDMApproveProposal>
    >("/api/v1/TblApproposal/create?prefix=DX");
    if (callApi?.success) {
      const dataApi = callApi?.data;
      if (dataApi !== null) {
        form.setValues(dataApi);
        form.resetDirty(dataApi);
      }
      close();
    } else {
      NotificationExtension.Fails("Bạn không có quyền tạo!!!");
      navigate("/proposal/approve-proposal");
    }
  };

  const fetchDataTblDmEmployee = async () => {
    const response = await repositoryPos.get<MessageResponse<any>>(
      `/api/v1/TblDmEmployee/detail-seft?empId=${
        Number(localStorage.getItem("empId")) ?? 0
      }`
    );

    if (response && response.success) {
      let result = response.data;
      setUser(result);
    }
  };

  const fetchDataReceiveUsers = async () => {
    const response = await repositoryPos.get<MessageResponse<any>>(
      `/api/v1/TblDmEmployee/get-list?KeySearch=${username}&Skip=0&Take=10`
    );

    if (response && response.success) {
      let result = response.data;
      setReceiveUserList(result);
    }
  };

  // useEffect
  useEffect(() => {
    const fetchAllData = async () => {
      await Promise.all([callApiGetData(), fetchDataTblDmEmployee()]);
    };
    fetchAllData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (file && file.size >= 5 * 1024 * 1024) {
      form.setFieldError("file", "File quá lớn, vui lòng chọn file dưới 5mb!");
      setEnable(false);
    } else {
      form.clearFieldError("file");
      setEnable(true);
    }
  }, [file]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      setHeight(window.innerHeight - (230 + headerHeight));
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [height]);

  useEffect(() => {
    if (username.length > 3) {
      fetchDataReceiveUsers();
    }
  }, [username]);

  const handleSubmit = async (dataSubmit: TblDMApproveProposal) => {
    const data: TblDMApproveProposal = {
      ...dataSubmit,
      idEmployee: user.id ?? 0,
      idDepeartment: user.branchId ?? 0,
      idPart: user.departmentId ?? 0,
      idRole: user.roleId ?? 0,
      idWorkAddress: user.workAddress ?? "",
      idDepeartmentReceive: selectedReceiveUser.branchId ?? 0,
      idPartReceive: selectedReceiveUser.departmentId ?? 0,
      idRoleReceive: selectedReceiveUser.roleId ?? 0,
      idEmployeeReceive: selectedReceiveUser.id ?? 0,
      active: null,
      respond: "",
      urlFile: "",
    };
    const formData = new FormData();
    if (file) {
      formData.append("file", file);
      const fileNameExtension = file.name.split(".").pop();
      formData.append(
        "fileNameExtension",
        `${form.getValues().code}.${fileNameExtension}`
      );
    }

    (Object.keys(data) as (keyof TblDMApproveProposal)[]).forEach((key) => {
      const value = data[key];

      if (value !== null && value !== undefined) {
        formData.append(key, value.toString()); // Ensure the value is a string
      }
    });

    try {
      const response = await repositoryMdm.post(
        "/api/v1/TblApproposal/create",
        formData
      );
      if (response && response.success) {
        NotificationExtension.Success("Tạo đề xuất thành công!");
        SocketExtension.SendMessageToEmp(Number(selectedReceiveUser.id), {
          message: "Có đề xuất mới gửi tới bạn!",
        });
        navigate("/proposal/approve-proposal");
      }
    } catch (error) {
      NotificationExtension.Fails("Tạo đề xuất thất bại!");
    }
  };
  return (
    <Box
      component="form"
      onSubmit={form.onSubmit((e: TblDMApproveProposal) => {
        handleSubmit(e);
      })}
    >
      <LoadingOverlay
        visible={visible}
        zIndex={1000}
        overlayProps={{ radius: "sm", blur: 2 }}
      />
      <Flex justify={"end"} style={{ position: "sticky" }} mb={10} gap={8}>
        <Button
          variant="outline"
          color={sky_blue.light}
          leftSection={<IconArrowLeft size={16} />}
          onClick={() => {
            navigate("/proposal/approve-proposal");
          }}
        >
          Trở lại
        </Button>
        <Button
          variant="outline"
          leftSection={<IconCheck size={16} />}
          type="submit"
          disabled={!enable}
        >
          Lưu
        </Button>
      </Flex>

      <Box p={15} bg={"#f8f8f8"}>
        <Grid gutter={"xs"}>
          <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
            <Card
              shadow="sm"
              radius="md"
              withBorder
              p={0}
              style={{
                maxHeight: height,
                minHeight: height,
              }}
            >
              <Badge variant="gradient" size="lg" m={12}>
                Tạo phiếu đề xuất mới
              </Badge>
              <Table
                striped
                horizontalSpacing="sm"
                withColumnBorders
                withTableBorder
              >
                <Table.Thead>
                  <Table.Tr>
                    <Table.Th w={"25%"}></Table.Th>
                    <Table.Th w={"25%"}>
                      <Center>
                        <Text fw={700}>Người lập</Text>
                      </Center>
                    </Table.Th>
                    <Table.Th w={"25%"}>
                      <Center>
                        <Text fw={700}>Người nhận</Text>
                      </Center>
                    </Table.Th>
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                  <Table.Tr>
                    <Table.Td>
                      <Text fw={700}>Thời gian tạo</Text>
                    </Table.Td>
                    <Table.Td>
                      <Text>{formatDate(new Date())}</Text>
                    </Table.Td>
                    <Table.Td></Table.Td>
                  </Table.Tr>
                  <Table.Tr>
                    <Table.Td>
                      <Text fw={700}>Mã đề xuất</Text>
                    </Table.Td>
                    <Table.Td>
                      <Text>{form.getValues().code}</Text>
                    </Table.Td>
                    <Table.Td></Table.Td>
                  </Table.Tr>
                  <Table.Tr>
                    <Table.Td>
                      <Text fw={700}>Họ và tên</Text>
                    </Table.Td>
                    <Table.Td>
                      <Text>
                        {JSON.parse(localStorage.getItem("userLogin") ?? "")?.[
                          "fullName"
                        ] ?? ""}
                      </Text>
                    </Table.Td>
                    <Table.Td>
                      <Menu
                        shadow="md"
                        width={240}
                        trigger="hover"
                        openDelay={100}
                        closeDelay={400}
                      >
                        <Menu.Target>
                          <TextInput
                            placeholder="Nhập tên người nhận"
                            error={form.errors.idEmployeeReceive}
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                          />
                        </Menu.Target>
                        <Menu.Dropdown
                          mah={240}
                          style={{
                            overflowY: "auto",
                          }}
                        >
                          <Table.ScrollContainer
                            minWidth={180}
                            mah={200}
                            type="native"
                          >
                            <Table highlightOnHover>
                              <Table.Tbody>
                                {receiveUserList &&
                                receiveUserList.length > 0 &&
                                username.length > 2 ? (
                                  receiveUserList.map((item, index) => (
                                    <Table.Tr key={index}>
                                      <Table.Td
                                        onClick={() => {
                                          form.setValues((prev) => ({
                                            ...prev,
                                            idEmployeeReceive: Number(item.id),
                                          }));
                                          setSelectedReceiveUser(item);
                                          setUsername(
                                            item.fullname + " - " + item.id
                                          );
                                        }}
                                        style={{
                                          cursor: "pointer",
                                        }}
                                      >
                                        <Text size="xs">
                                          {item.fullname + " - " + item.id}
                                        </Text>
                                      </Table.Td>
                                    </Table.Tr>
                                  ))
                                ) : (
                                  <Table.Tr>
                                    <Table.Td ta={"center"}>
                                      <Text size="xs" c={"#B5B5CC"}>
                                        {username === null ||
                                        username?.length < 3
                                          ? "Nhập tối thiểu 3 kí tự"
                                          : "Không tìm thấy dữ liệu !"}
                                      </Text>
                                    </Table.Td>
                                  </Table.Tr>
                                )}
                              </Table.Tbody>
                            </Table>
                          </Table.ScrollContainer>
                        </Menu.Dropdown>
                      </Menu>
                    </Table.Td>
                  </Table.Tr>
                  <Table.Tr>
                    <Table.Td>
                      <Text fw={700}>Chức danh</Text>
                    </Table.Td>
                    <Table.Td>
                      <Text>{user.roleName ?? ""}</Text>
                    </Table.Td>
                    <Table.Td>
                      <Text>{selectedReceiveUser.roleName ?? ""}</Text>
                    </Table.Td>
                  </Table.Tr>
                  <Table.Tr>
                    <Table.Td>
                      <Text fw={700}>Bộ phận</Text>
                    </Table.Td>
                    <Table.Td>
                      <Text>{user?.departmentName ?? ""}</Text>
                    </Table.Td>
                    <Table.Td>
                      <Text>{selectedReceiveUser?.departmentName ?? ""}</Text>
                    </Table.Td>
                  </Table.Tr>
                  <Table.Tr>
                    <Table.Td>
                      <Text fw={700}>Phòng ban</Text>
                    </Table.Td>
                    <Table.Td>
                      <Text>{user?.brachName ?? ""}</Text>
                    </Table.Td>
                    <Table.Td></Table.Td>
                  </Table.Tr>
                  <Table.Tr>
                    <Table.Td>
                      <Text fw={700}>Địa điểm làm việc</Text>
                    </Table.Td>
                    <Table.Td>
                      <Text>{user?.workAddress ?? ""}</Text>
                    </Table.Td>
                    <Table.Td></Table.Td>
                  </Table.Tr>
                  <Table.Tr>
                    <Table.Td>
                      <Text fw={700}>Mức độ ưu tiên</Text>
                    </Table.Td>
                    <Table.Td>
                      <Select
                        placeholder="Nhập mức độ ưu tiên"
                        data={priorityItems}
                        error={form.errors.priorityLevel}
                        onChange={(value) => {
                          if (value)
                            form.setFieldValue("priorityLevel", Number(value));
                          else form.setFieldValue("priorityLevel", null);
                        }}
                        searchable
                        clearable
                        nothingFoundMessage="Không tìm thấy dữ liệu !"
                      />
                    </Table.Td>
                    <Table.Td></Table.Td>
                  </Table.Tr>
                </Table.Tbody>
              </Table>
            </Card>
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
            <Card
              shadow="sm"
              radius="md"
              withBorder
              p="lg"
              style={{
                maxHeight: height,
                minHeight: height,
              }}
            >
              <Center>
                <Text fw={700} size={"xl"}>
                  Nội dung phiếu đề xuất
                </Text>
              </Center>

              <Grid>
                <Grid.Col span={{ base: 12 }}>
                  <Textarea
                    placeholder="Nhập nội dung đề xuất"
                    size="lg"
                    minRows={6}
                    maxRows={6}
                    autosize
                    {...form.getInputProps("note")}
                  />
                </Grid.Col>

                <Grid.Col pb={0} span={{ base: 12 }}>
                  <Divider
                    my="xs"
                    label={
                      <Text size="lg" fw={600} c="#000">
                        File đính kèm
                      </Text>
                    }
                    labelPosition="left"
                  />
                </Grid.Col>
                <Grid.Col>
                  <FileInput
                    accept="application/vnd.rar,application/zip,image/jpeg,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                    onChange={setFile}
                    placeholder="Chọn file đính kèm"
                    value={file}
                    error={form.errors.file}
                    clearable
                  />
                </Grid.Col>
              </Grid>
            </Card>
          </Grid.Col>
        </Grid>
      </Box>
    </Box>
  );
};
