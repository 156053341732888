import {
  Button,
  Checkbox,
  Fieldset,
  Flex,
  Grid,
  Input,
  LoadingOverlay,
  Overlay,
  Pagination,
  ScrollArea,
  Table,
  TextInput,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { IconCheck, IconSearch, IconWindow } from "@tabler/icons-react";
import cx from "clsx";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_RowSelectionState,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useState } from "react";
import { repositoryPos } from "../../../../../_base/_const/_constVar";
import { NotificationExtension } from "../../../../../_base/extension/NotificationExtension";
import { SelectListItemBase } from "../../../../../_base/model/_base/SelectListItemBase";
import { _localization } from "../../../../../config/location";
import { sky_blue } from "../../../../../const/variables";
import { MessageResponse } from "../../../../../model/MessageResponse";
import { tblItemByCat } from "../../../../../model/TblItem";
import { tblPromotionItemGiftCommand } from "../../../../../model/TblPromotion";
import TableSegment from "../Components/TableItemPromotion/TableSegment";
import classes from "../Styles/ScrollTable.module.css";
import TableItems from "../Components/TableItemPromotion/TableItems";

const ModalSearchItems = ({
  handleAddItem,
  handleAddSearchItem,
  dataDelete,
}: {
  handleAddItem: (
    selectedCatSegment: { code: string; type: number }[],
    selectedManuFacTurer: string[],
    selectCat: string | null
  ) => void;
  handleAddSearchItem: (selectItem: tblItemByCat[]) => void;
  dataDelete?: tblPromotionItemGiftCommand[];
}) => {
  const [visibleCat, { close: closeCat, open: openCat }] = useDisclosure(false);
  const [visibleItem, { close: closeItem, open: openItem }] =
    useDisclosure(false);
  const [selectCat, setSelectedCat] = useState<string | null>("1a");
  const [scrolledv1, setScrolledv1] = useState(false);
  const [selectedManuFacTurer, setSelectedManuFacTurer] = useState<string[]>(
    []
  );
  const [dataManuFacTurer, setDataManuFacTurer] = useState<any[]>([]);
  const [dataManuFacTurerTemp, setDataManuFacTurerTemp] = useState<any[]>([]);
  const [keySearch, setKeySearch] = useState<string>("");
  const [keySearchItem, setKeySearchItem] = useState<string>("");
  const [selectedCatSegment, setSelectedCatSegment] = useState<
    { code: string; type: number; checked: boolean }[]
  >([]);
  const [dataItem, setDataItem] = useState<tblItemByCat[]>([]);
  const [dataItemTemp, setDataItemTemp] = useState<tblItemByCat[]>([]);
  const [selectItem, setSelectItem] = useState<tblItemByCat[]>([]);
  const [activePage, setPage] = useState(1);
  const [activePageGroup, setPageGroup] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [totalCountGroup, setTotalCountGroup] = useState(0);
  const [isGetByCat, setIsGetByCat] = useState(false);
  const [search, setSearch] = useState({
    type: "",
    keySearch: "",
  });

  // Table delete
  const [data, setData] = useState<any[]>(dataDelete ?? []);
  const [dataTemp, setDataTemp] = useState<any[]>(dataDelete ?? []);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [rowCount, setRowCount] = useState(0);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [selectIds, setSelectIds] = useState<string[]>([]);

  const entity: tblItemByCat = {
    brandId: null, // ID thương hiệu (có thể null)
    isOnhand: null, // Trạng thái có sẵn hàng
    itemCode: null, // Mã mặt hàng
    itemId: 0, // ID mặt hàng
    itemName: null, // Tên mặt hàng
    itemShortName: null, // Tên ngắn của mặt hàng (có thể null)
    segmentId: null, // ID phân khúc
    segmentName: null, //
  };

  const columnsDelete = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "itemPrimaryCode",
        header: "Mã",
      },

      {
        accessorKey: "itemPrimaryName",
        header: "Tên",
      },
    ],
    [data]
  );

  const handleCheckboxChange = (
    code: string | null,
    type: number,
    checked: boolean
  ) => {
    setSelectedCatSegment((prevSelectedCatSegment) => {
      let updatedSelectedRows;

      if (checked && code) {
        updatedSelectedRows = [
          ...prevSelectedCatSegment.filter((row) => row.code !== code),
          { code, type, checked },
        ];
      } else {
        updatedSelectedRows = prevSelectedCatSegment.filter(
          (row) => row.code !== code
        );
      }

      return updatedSelectedRows;
    });
  };

  const handleItemCheckboxChange = (item: tblItemByCat[]) => {
    setSelectItem(item);
  };

  const handleConfirmAddItem = () => {
    if (dataDelete && dataDelete.length > 0) {
      if (tableDelete.getSelectedRowModel().rows.length > 0) {
        const rowSelected = tableDelete
          .getSelectedRowModel()
          .rows?.map((row) => ({
            itemId: Number(row.original?.idItemPrimary),
            itemCode: row.original?.itemPrimaryCode?.toString() ?? "",
            itemName: row.original?.itemPrimaryName?.toString() ?? "",
            brandId: row.original?.brandId ?? 0,
            isOnhand: row.original?.isOnhand ?? false,
            itemShortName: row.original?.itemShortName?.toString() ?? "",
            segmentId: row.original?.segmentId ?? 0,
            segmentName: row.original?.segmentName?.toString() ?? "",
          }));
        handleAddSearchItem(rowSelected);
      }
    }
    // if (selectedCatSegment?.length > 0) {
    //   handleAddItem(selectedCatSegment, selectedManuFacTurer, selectCat);
    // } else {
    handleAddSearchItem(selectItem);
    // }
  };

  // const handleKeyDown = (e: any) => {
  //   if (e.key === "Enter") {
  //     activePage === 1 ? fetchDataSearchItem() : setPage(1);
  //   }
  // };

  const handleOnChange = (e: any) => {
    const value = e.target.value.toLowerCase() ?? "";
    setKeySearch(value);
    if (value) {
      const searchResult = dataTemp.filter(
        (product) =>
          product.itemPrimaryCode?.toLowerCase().includes(value) ||
          product.itemPrimaryName?.toLowerCase().includes(value)
      );
      setRowCount(searchResult.length);
      setSelectIds([]);
      tableDelete.resetRowSelection();
      setData(searchResult);
    } else {
      setRowCount(dataTemp.length);
      setSelectIds([]);
      tableDelete.resetRowSelection();
      setData(dataTemp);
    }
  };

  // const fetchDataSearchItem = async () => {
  //   openItem();
  //   let url = `/api/v1/TblItem/get-list-by-cat?Skip=${
  //     50 * (activePage - 1)
  //   }&Take=50`;

  //   if (keySearchItem) {
  //     url += `&KeySearch=${keySearchItem}`;
  //   }
  //   const dataApi = await repositoryPos.get<MessageResponse<tblItemByCat[]>>(
  //     url
  //   );

  //   if (dataApi?.success && dataApi.data) {
  //     setDataItem(dataApi.data);
  //     setDataItemTemp(dataApi.data);
  //     setTotalCount(Math.ceil(dataApi.totalCount / 50));
  //   } else {
  //     setDataItem([]);
  //     setDataItemTemp([]);
  //     setSelectItem([]);
  //     setTotalCount(0);
  //   }
  //   closeItem();
  // };

  const mapSegments = (array: { code: string; type: number }[]) => {
    const segments: { [key: string]: string[] } = {
      segment1: [],
      segment2: [],
      segment3: [],
      segment4: [],
      segment5: [],
      segment6: [],
    };

    array?.forEach((item) => {
      const segmentKey = `segment${item?.type}`;

      if (segments[segmentKey]) {
        segments[segmentKey].push(item?.code);
      }
    });

    return segments;
  };

  const handleFetchItemFormCat = async () => {
    let url = `/api/v1/TblPromotion/get-list-item`;

    const dataSegment = selectedCatSegment.filter(
      (item) => item !== null && item !== undefined
    );

    const dataSubmit = {
      keySearch: "",
      sort: "",
      isOrder: true,
      active: true,
      skip: 50 * (activePage - 1),
      take: 50,
      ...mapSegments(dataSegment),
    };

    const dataApi = await repositoryPos.post<MessageResponse<any[]>>(
      url,
      dataSubmit
    );
    if (dataApi?.success && dataApi.data) {
      if (dataApi?.data?.length < 1) {
        NotificationExtension.Warn("Không có sản phẩm tồn kho !");
        return;
      }
      const newData: tblItemByCat[] = dataApi?.data.map((item) => ({
        ...entity,
        itemId: item.itemId,
        itemCode: item.itemCode,
        itemName: item.itemName,
      }));
      setIsGetByCat(true);
      setDataItem(newData);
      setDataItemTemp(newData);
      setTotalCount(Math.ceil(dataApi.totalCount / 50));
      NotificationExtension.Success("Thêm sản phẩm thành công !");
    } else {
      setDataItem([]);
      setDataItemTemp([]);
      setSelectItem([]);
      setTotalCount(0);
      setIsGetByCat(false);
    }
  };

  const tableDelete = useMantineReactTable({
    columns: columnsDelete,
    data,
    positionToolbarAlertBanner: "bottom",
    //add custom action buttons to top-left of top toolbar
    renderTopToolbarCustomActions: ({ table }) => (
      <div>
        <Flex
          gap="md"
          justify="flex-start"
          align="center"
          direction="row"
          wrap="wrap"
        >
          <Input placeholder="Nhập từ khóa ..." onChange={handleOnChange} />
        </Flex>
      </div>
    ),

    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    enableRowSelection: true,
    getRowId: (row: any) => row?.original?.idItemPrimary?.toString(),
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select", "code"],
        right: ["action"],
      },
      columnVisibility: { id: true, phone: false, fax: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: 400, minHeight: 400 },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    // manualFiltering: true,
    // manualPagination: true,
    // manualSorting: true,
    rowCount,
    // onColumnFiltersChange: setColumnFilters,
    // onGlobalFilterChange: setGlobalFilter,
    // onPaginationChange: setPagination,
    // onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        fontWeight: "normal", //conditional styling
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      //  pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no data
      //sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["10", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    columnFilterDisplayMode: "popover",
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });

  // useEffect(() => {
  //   if (!dataDelete && !isGetByCat) {
  //     fetchDataSearchItem();
  //   }
  // }, [activePage]);

  return (
    <>
      {dataDelete && dataDelete?.length > 0 ? (
        <MantineReactTable table={tableDelete} />
      ) : (
        <Grid>
          <Grid.Col span={{ base: 12, md: 5, xs: 5 }}>
            <Fieldset
              legend="Tìm nhóm hàng"
              mt={10}
              h={"98%"}
              style={{ position: "relative" }}
            >
              {/* {selectItem.length > 0 && (
                <Overlay color="#000" backgroundOpacity={0.55} />
              )} */}
              <TableSegment
                handleCheckboxChange={handleCheckboxChange}
                handleFetchItemFormCat={handleFetchItemFormCat}
              ></TableSegment>
            </Fieldset>
          </Grid.Col>

          <Grid.Col span={{ base: 12, md: 7, xs: 7 }}>
            <Fieldset
              legend="Tìm mã hàng"
              mt={10}
              style={{ position: "relative" }}
            >
              {/* {dataItem?.length === 0 && (
                <Overlay color="#000" backgroundOpacity={0.5} />
              )} */}
              <TableItems
                dataItem={dataItem}
                handleCheckboxChange={handleItemCheckboxChange}
                isGetByCat={isGetByCat}
              ></TableItems>
            </Fieldset>
          </Grid.Col>
        </Grid>
      )}

      <Flex align={"center"} justify={"end"} gap={10} mt={10}>
        <Button
          color="gray"
          onClick={() => {
            modals.closeAll();
          }}
          leftSection={<IconWindow size={18} />}
        >
          Đóng
        </Button>
        <Button
          color={sky_blue.base}
          leftSection={<IconCheck size={18} />}
          onClick={() => {
            handleConfirmAddItem();
          }}
        >
          Xác nhận
        </Button>
      </Flex>
    </>
  );
};

export default ModalSearchItems;
