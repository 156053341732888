import { FormValidateInput } from "@mantine/form/lib/types";
import { hasLength, isNotEmpty } from "@mantine/form";
import {
  UserMasterCreateModel,
  UserMasterModel,
} from "../model/UserMasterModel";
import { UserMenuGroup } from "../model/UserMenuGroup";
import { ChangPassWordModel } from "../model/ChangPassWordModel";

const containsUpperCase = (value: string) =>
  /[A-Z]/.test(value) ? null : "Mật khẩu phải chứa ít nhất một chữ cái in hoa!";
const containsLowerCase = (value: string) =>
  /[a-z]/.test(value) ? null : "Mật khẩu phải chứa ít nhất một chữ cái thường!";
const containsSpecialChar = (value: string) =>
  /[!@#$%^&*(),.?":{}|<>]/.test(value)
    ? null
    : "Mật khẩu phải chứa ít nhất một kí tự đặc biệt!";
const minLength = (value: string) =>
  value.length >= 6 ? null : "Mật khẩu phải nhiều hơn 6 kí tự!";
const maxLength = (value: string) =>
  value.length <= 20 ? null : "Mật khẩu phải ít hơn 20 kí tự!";
const noSpaces = (value: string) =>
  /\s/.test(value) ? "Không được chứa dấu cách!" : null;

export const _validateUserMasterModelValidator: FormValidateInput<UserMasterCreateModel> =
  {
    userName: (value: string | null) => {
      if (!value || value.length < 5) {
        return "Vui lòng nhập tên đăng nhập !";
      }
      const lengthError = hasLength(
        { min: 5, max: 15 },
        "Tên đăng nhập phải từ 5-15 kí tự!"
      )(value as string);
      if (lengthError) return lengthError;
      return noSpaces(value as string);
    },
    fullName: (value: string | undefined) => {
      if (!value || value.length < 5) {
        return "Vui lòng nhập tên đầy đủ !";
      }
      return hasLength(
        { min: 5, max: 25 },
        "Tên đầy đủ phải từ 5-25 kí tự!"
      )(value as string);
    },
    password: (value: string | null) => {
      if (!value) {
        return "Xin vui lòng nhập mật khẩu!";
      }
      const validators = [
        minLength,
        maxLength,
        containsUpperCase,
        containsLowerCase,
        containsSpecialChar,
        noSpaces,
      ];
      for (let validate of validators) {
        const error = validate(value);
        if (error) {
          return error;
        }
      }
      return null;
    },
    confirmPassword: (
      value: string | undefined,
      values: UserMasterCreateModel
    ) => {
      if (!value) {
        return "Xin vui lòng nhập mật khẩu xác nhận!";
      }
      if (value !== values.password) {
        return "Hai mật khẩu chưa khớp nhau!";
      }
      const validators = [
        minLength,
        maxLength,
        containsUpperCase,
        containsLowerCase,
        containsSpecialChar,
        noSpaces,
      ];
      for (let validate of validators) {
        const error = validate(value);
        if (error) {
          return error;
        }
      }
      return null;
    },
  };

export const _validateUserMasterModelValidatorNoPassword: FormValidateInput<UserMasterCreateModel> =
  {
    userName: (value: string | null) => {
      if (!value || value.length < 5) {
        return "Vui lòng nhập tên đăng nhập !";
      }
      const lengthError = hasLength(
        { min: 5, max: 15 },
        "Tên đăng nhập phải từ 5-15 kí tự!"
      )(value as string);
      if (lengthError) return lengthError;
      return noSpaces(value as string);
    },
    fullName: (value: string | undefined) => {
      if (!value || value.length < 5) {
        return "Vui lòng nhập tên đầy đủ !";
      }
      return hasLength(
        { min: 5, max: 25 },
        "Tên đầy đủ phải từ 5-25 kí tự!"
      )(value as string);
    },
    empid: (value: number | undefined) => {
      if (!value || value < 1) {
        return "Vui lòng chọn nhân viên !";
      }
    },
    // Password validation removed
    confirmPassword: (
      value: string | undefined,
      values: UserMasterCreateModel
    ) => {
      if (values.password) {
        // Only validate if password is provided
        if (!value) {
          return "Xin vui lòng nhập mật khẩu xác nhận!";
        }
        if (value !== values.password) {
          return "Hai mật khẩu chưa khớp nhau!";
        }
        const validators = [
          minLength,
          maxLength,
          containsUpperCase,
          containsLowerCase,
          containsSpecialChar,
          noSpaces,
        ];
        for (let validate of validators) {
          const error = validate(value);
          if (error) {
            return error;
          }
        }
      }
      return null;
    },
  };

export const _validateMenuValidator: FormValidateInput<UserMenuGroup> = {
  name: (value: string | null) => {
    if (!value || value.length < 5) {
      return "Vui lòng nhập tên !";
    }
    const lengthError = hasLength(
      { min: 5, max: 50 },
      "Tên phải từ 5-50 kí tự!"
    )(value as string);
    if (lengthError) return lengthError;
    return null;
  },
};

const minLengthPass = (min: number) => (value: string) =>
  value.length >= min ? null : `Phải ít nhất ${min} kí tự`;
const maxLengthPass = (max: number) => (value: string) =>
  value.length <= max ? null : `Không được quá ${max} kí tự`;

export const validateChangPassWordModel: FormValidateInput<ChangPassWordModel> = {
  Code: (value: string) => {
    if (!value) {
      return "Xin vui lòng nhập mã code xác nhận!";
    }
    return null;
  },
  PassOld: (value: string) => {
    if (!value) {
      return "Xin vui lòng nhập mật khẩu!";
    }
    const lengthError = minLengthPass(5)(value) || maxLengthPass(20)(value);
    if (lengthError) return lengthError;
    return null;
  },
  PassNew: (value: string) => {
    if (!value) {
      return "Xin vui lòng nhập mật khẩu mới!";
    }
    const lengthError = minLengthPass(5)(value) || maxLengthPass(20)(value);
    if (lengthError) return lengthError;
    return null;
  },
  RePassNew: (value: string, values: ChangPassWordModel) => {
    if (!value) {
      return "Xin vui lòng nhập mật khẩu mới xác nhận!";
    }
    if (value !== values.PassNew) {
      return "Hai mật khẩu chưa khớp nhau!";
    }
    const lengthError = minLengthPass(5)(value) || maxLengthPass(20)(value);
    if (lengthError) return lengthError;
    return null;
  },
};
