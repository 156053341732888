import {
  Box,
  Checkbox,
  Fieldset,
  Flex,
  Grid,
  NumberInput,
  Textarea,
  TextInput,
} from "@mantine/core";
import { tblPromotionDetailModel } from "../../../../../../model/TblPromotion";
import { IconCalendarMonth } from "@tabler/icons-react";
import { DateTimePicker } from "@mantine/dates";

const GeneralInformation = ({
  dataPromotionDetail,
}: {
  dataPromotionDetail: tblPromotionDetailModel | undefined;
}) => {
  const getType = (value: string) => {
    switch (value) {
      case "0":
        return "Chính sách thông thường (khi bán hàng chỉ được chọn 1 chính sách)";
      case "1":
        return "Chính sách mặc định (luôn được áp dụng kèm theo chính sách thường)";
      case "2":
        return "Chính sách chiết khấu, giảm giá (khai báo mã chiết khấu, giảm giá)";
      default:
        return "";
    }
  };

  return (
    <Box h={"max-content"}>
      <Fieldset
        legend="Thông tin chung"
        display={"flex"}
        style={{ flexDirection: "column", gap: "10px" }}
      >
        <Grid>
          <Grid.Col span={{ base: 6, md: 1.5, lg: 1.5 }}>
            <TextInput
              label="Số chính sách"
              placeholder="Nhập số chính sách"
              size="xs"
              readOnly
              value={dataPromotionDetail?.code || ""}
            />
          </Grid.Col>
          {/* <Grid.Col span={{ base: 6, md: 1.5, lg: 1.5 }}>
            <NumberInput
              label="Thứ tự ưu tiên"
              min={0}
              size="xs"
              readOnly
              value={dataPromotionDetail?.priority || ""}
            />
          </Grid.Col> */}

          <Grid.Col span={{ base: 6, md: 1.5, lg: 1.5 }}>
            <TextInput
              label="Trạng thái"
              placeholder="Trạng thái"
              w={"100%"}
              size="xs"
              readOnly
              value={
                dataPromotionDetail?.approveStatus === "2"
                  ? "Đã duyệt"
                  : dataPromotionDetail?.approveStatus === "3"
                  ? "Tạm dừng"
                  : dataPromotionDetail?.approveStatus === "4"
                  ? "Từ chối"
                  : "Chưa duyệt"
              }
            />
          </Grid.Col>
          <Grid.Col span={{ base: 6, md: 1.5, lg: 1.5 }}>
            <TextInput
              label="Người lập"
              readOnly
              value={dataPromotionDetail?.createBy || ""}
              w={"100%"}
              size="xs"
            />
          </Grid.Col>
          <Grid.Col span={{ base: 6, md: 1.5, lg: 1.5 }}>
            <TextInput
              label="Chính sách khuyến mại"
              size="xs"
              readOnly
              value={getType(dataPromotionDetail?.type || "")}
            />
          </Grid.Col>

          <Grid.Col span={{ base: 6, md: 1.5, lg: 1.5 }}>
            <DateTimePicker
              readOnly
              label="Hiệu lực từ ngày"
              placeholder="Hiệu lực từ ngày"
              minDate={new Date()}
              rightSection={<IconCalendarMonth size={14} />}
              size="xs"
              value={
                dataPromotionDetail?.effDate
                  ? new Date(dataPromotionDetail?.effDate)
                  : null
              }
            />
          </Grid.Col>

          <Grid.Col span={{ base: 6, md: 1.5, lg: 1.5 }}>
            <DateTimePicker
              readOnly
              label="Đến ngày"
              placeholder="Đến ngày"
              rightSection={<IconCalendarMonth size={14} />}
              size="xs"
              value={
                dataPromotionDetail?.expDate
                  ? new Date(dataPromotionDetail?.expDate)
                  : null
              }
            />
          </Grid.Col>

          <Grid.Col span={{ base: 6, md: 1.5, lg: 1.5 }}>
            {/* <Flex gap={15} direction={"column"}>
              <Checkbox label="Chỉ áp dụng online" size="xs"></Checkbox>
              <Checkbox
                label="Có kết hợp với chính sách mặc định"
                size="xs"
              ></Checkbox>
            </Flex> */}
          </Grid.Col>

          <Grid.Col span={{ base: 12, md: 4.5, lg: 4.5 }}>
            <Textarea
              label="Ghi chú"
              placeholder="Ghi chú"
              size="xs"
              readOnly
              value={dataPromotionDetail?.note || ""}
            />
          </Grid.Col>
        </Grid>
      </Fieldset>
    </Box>
  );
};

export default GeneralInformation;
