import { ComboboxItem, Divider, Grid, Select, TextInput } from "@mantine/core";
import { useEffect, useRef, useState } from "react";
import { CreateSellItemForDelivery } from "../../../model/TblCreateSellItem";
import {
  repositoryDelivery,
  repositoryPos,
} from "../../../_base/_const/_constVar";
import { MessageResponse } from "../../../model/MessageResponse";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { DatePickerInput } from "@mantine/dates";
import { getOrderTypeSelect } from "../../../service/getSelectApi";
import DetailProduct from "./DetailProduct";

const DeliveryDetailBySellItem = ({
  sourceCode,
  id,
}: {
  sourceCode: any;
  id: any;
}) => {
  const [dataDeliveryDetailBySellItem, setDataDeliveryDetailBySellItem] =
    useState<CreateSellItemForDelivery[]>([]);
  const [dataOrderTypeSelect, setDataOrderTypeSelect] = useState<
    ComboboxItem[]
  >([]);
  const [dataOrder, setDataOrder] = useState<any>();

  const heightRes = useRef<HTMLDivElement | null>(null);

  const fetchDataDeliveryDetailBySellItem = async () => {
    try {
      const callApi = await repositoryPos.get<
        MessageResponse<CreateSellItemForDelivery[]>
      >(
        `api/v1/CreateSellItem/detail-for-delivery?OrderCode=${sourceCode}&Take=${50}`
      );
      if (callApi) {
        const dataApi = callApi.data;

        if (Array.isArray(dataApi)) {
          setDataDeliveryDetailBySellItem(dataApi);
        } else {
          setDataDeliveryDetailBySellItem([dataApi]);
        }
        Promise.all([fetchDataOrderTypeSelect()]);
      } else {
        NotificationExtension.Fails("Dữ liệu không tồn tại");
      }
    } catch (error) {
      console.error(error);
      NotificationExtension.Fails("An error occurred while fetching data");
    }
  };

  // const getDataProduct = async () => {
  //   try {
  //     const callApi = await repositoryDelivery.get<MessageResponse<any>>(
  //       `api/v1/CreateSellItem/detail-delivery?id=${id}`
  //     );
  //     if (callApi) {
  //       const dataApi = callApi.data;
  //       setDataProduct(dataApi?.listItemDeliveries);
  //     } else {
  //       NotificationExtension.Fails("Dữ liệu không tồn tại");
  //     }
  //   } catch (error) {
  //     console.error(error);
  //     NotificationExtension.Fails("An error occurred while fetching data");
  //   }
  // };

  const fetchDataOrderTypeSelect = async () => {
    const getData = await getOrderTypeSelect();
    setDataOrderTypeSelect(
      getData
        ?.filter((item: any) => item.value != null && item.text != null)
        ?.map((item: any) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  useEffect(() => {
    if (sourceCode) {
      fetchDataDeliveryDetailBySellItem();
    }
  }, [sourceCode]);

  useEffect(() => {
    const updateHeight = () => {
      if (heightRes.current) {
        const height = window.innerHeight - 610;
        heightRes.current.style.height = `${height}px`;
      }
    };
    const timeoutId = setTimeout(updateHeight, 100);
    window.addEventListener("resize", updateHeight);
    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  // useEffect(() => {
  //   if (id) {
  //     getDataProduct();
  //   } else {
  //     setDataProduct([]);
  //   }
  // }, [id]);

  return (
    <>
      {dataDeliveryDetailBySellItem?.map((item, index) => (
        <>
          <Grid key={index}>
            <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
              <TextInput
                label="Số đơn hàng"
                value={item.orderNumber ?? ""}
                variant="filled"
                readOnly
                size="xs"
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
              <DatePickerInput
                label="Ngày đặt hàng"
                valueFormat="DD/MM/YYYY"
                value={item.orderDate ? new Date(item.orderDate) : null}
                variant="filled"
                readOnly
                size="xs"
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
              <Select
                label="Loại đơn hàng"
                data={dataOrderTypeSelect}
                value={item.orderTypeId?.toString() ?? ""}
                rightSection={" "}
                variant="filled"
                readOnly
                size="xs"
              />
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
              <TextInput
                label="Tổng giá trị"
                value={`${item.amountTotal?.toLocaleString() ?? ""} đ`}
                variant="filled"
                readOnly
                size="xs"
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
              <TextInput
                label="Đã trả"
                value={`${dataOrder?.amountPaid.toLocaleString() ?? ""} đ`}
                variant="filled"
                readOnly
                size="xs"
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
              <TextInput
                label="Còn lại"
                value={`${dataOrder?.amountRemaining.toLocaleString() ?? ""} đ`}
                variant="filled"
                readOnly
                size="xs"
              />
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
              <TextInput
                label="Trạng thái đơn hàng"
                value={item.orderStatus?.toString() ?? ""}
                variant="filled"
                readOnly
                size="xs"
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
              <TextInput
                label="Tình trạng thanh toán"
                value={item.paymentStatus ?? ""}
                variant="filled"
                readOnly
                size="xs"
              />
            </Grid.Col>
          </Grid>
          <Divider my="xs" label="Danh sách sản phẩm" labelPosition="center" />
          <DetailProduct
            id={id}
            sourceCode={null}
            sourceType={1}
            isDetail={true}
            setDataOrder={setDataOrder}
          />
        </>
      ))}
    </>
  );
};

export default DeliveryDetailBySellItem;
