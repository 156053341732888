import { useDisclosure } from "@mantine/hooks";
import { TblDMInventoryGroup } from "../../../../model/TblDMInventoryGroup";
import { hasLength, useForm } from "@mantine/form";
import { NotificationExtension } from "../../../../_base/extension/NotificationExtension";
import { modals } from "@mantine/modals";
import { repositoryMdm } from "../../../../_base/_const/_constVar";
import { MessageResponse } from "../../../../model/MessageResponse";
import { useEffect } from "react";
import {
  Box,
  Button,
  Checkbox,
  Grid,
  Group,
  LoadingOverlay,
  Text,
  Textarea,
  TextInput,
} from "@mantine/core";
import { handleKeyDown } from "../../../../_base/helper/FunctionHelper";
import { sky_blue } from "../../../../const/variables";
import { IconCheck, IconWindow } from "@tabler/icons-react";

export const CreateDataView = ({ onClose }: CreateDateViewProps) => {
  const entity = {
    id: 0,
    maKho: null,
    loaiKho: null,
    dienGiai: null,
    chucNangBan: null,
    nguoiQuanLy: null,
    dieuChuyenLienchinhanh: null,
    nguoiDieuChuyenLienchinhanh: null,
    dieuChuyenNoibo: null,
    nguoiDieuChuyenNoibo: null,
    createBy: null,
    createDate: null,
    lastUpdateBy: null,
    lastUpdateDate: null,
  };

  const [visible, { close, open }] = useDisclosure(false);

  const form = useForm<TblDMInventoryGroup>({
    mode: "uncontrolled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },

    validate: {
      maKho: (value: string | null) => {
        const invalidChars =
          /[âăáàảãạêếềệễểíìịĩỉóòỏõọôốồộổỗơớờợỡởúùủũụưứừựữửýỳỷỹỵđ]/i;

        if (!value) {
          return "Vui lòng nhập mã kho !";
        }
        if (invalidChars.test(value ?? "")) {
          return "Mã kho chứa những kí tự không hợp lệ !";
        }
        return hasLength(
          { max: 20 },
          "Mã kho không được dài hơn 20 ký tự !"
        )(value as string);
      },
      loaiKho: (value: string | null) => {
        if (!value) {
          return "Vui lòng nhập loại kho!";
        }
        return hasLength(
          { max: 200 },
          "Loại kho phải nhỏ hơn 10 kí tự!"
        )(value as string);
      },
      dienGiai: (value: string | null) => {
        if (!value) {
          return "Vui lòng nhập diễn giải!";
        }
        return hasLength(
          { max: 1000 },
          "Diễn giải không quá 1000 kí tự!"
        )(value as string);
      },
      nguoiQuanLy: (value: string | null) => {
        if (!value) {
          return "Vui lòng nhập người quản lý!";
        }
        return hasLength(
          { max: 200 },
          "Người quản lý không quá 200 kí tự!"
        )(value as string);
      },
      nguoiDieuChuyenLienchinhanh: (value: string | null) => {
        return hasLength(
          { max: 200 },
          "Người điều chuyển liên chi nhánh không quá 200 kí tự!"
        )(value as string);
      },
      nguoiDieuChuyenNoibo: (value: string | null) => {
        return hasLength(
          { max: 200 },
          "Người điều chuyển nội bộ không quá 200 kí tự!"
        )(value as string);
      },
    },
  });

  const callApiGetData = async () => {
    open();
    const callApi = await repositoryMdm.get<
      MessageResponse<TblDMInventoryGroup>
    >("/api/v1/TblDmInventoryGroup/create");
    if (callApi?.success) {
      const dataApi = callApi?.data;
      if (dataApi !== null) {
        form.setValues(dataApi);
        form.resetDirty(dataApi);
      }
      close();
    } else {
      NotificationExtension.Fails("Bạn không có quyền tạo!!!");
      modals.closeAll();
    }
  };

  const handleCreateTblDMInventoryGroup = async (
    dataSubmit: TblDMInventoryGroup
  ) => {
    open();
    const dataApi = await repositoryMdm.post<
      MessageResponse<TblDMInventoryGroup>
    >("/api/v1/TblDmInventoryGroup/create", dataSubmit);
    if (dataApi?.success) {
      onClose((prev: any) => !prev);
      modals.closeAll();
      NotificationExtension.Success("Thêm thành công !");
    }
    close();
  };

  useEffect(() => {
    callApiGetData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box
        component="form"
        mx="auto"
        w={{ base: "300px", md: "400px", lg: "480px" }}
        onSubmit={form.onSubmit((e: TblDMInventoryGroup) => {
          handleCreateTblDMInventoryGroup(e);
        })}
        style={{ position: "relative" }}
      >
        <LoadingOverlay
          visible={visible}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />

        <Grid mt={10}>
          <Grid.Col span={4}>
            <TextInput
              label={"Mã kho"}
              placeholder={"Nhập mã kho"}
              type="text"
              withAsterisk
              onKeyDown={handleKeyDown}
              {...form.getInputProps("maKho")}
            />
          </Grid.Col>
          <Grid.Col span={8}>
            <TextInput
              label={"Loại kho"}
              placeholder={"Nhập loại kho"}
              type="text"
              withAsterisk
              onKeyDown={handleKeyDown}
              w={"100%"}
              {...form.getInputProps("loaiKho")}
            />
          </Grid.Col>
        </Grid>

        <Textarea
          label={"Diễn giải"}
          placeholder={"Nhập diễn giải"}
          autosize
          withAsterisk
          onKeyDown={handleKeyDown}
          {...form.getInputProps("dienGiai")}
        />

        <Grid>
          <Grid.Col>
            <Checkbox
              label={"Chức năng bán"}
              onKeyDown={handleKeyDown}
              {...form.getInputProps("chucNangBan")}
              mt={16}
            />
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col>
            <TextInput
              label={"Người quản lý"}
              placeholder={"Nhập người quản lý"}
              type="text"
              onKeyDown={handleKeyDown}
              withAsterisk
              {...form.getInputProps("nguoiQuanLy")}
            />
          </Grid.Col>
        </Grid>
        <Grid mt={8}>
          <Grid.Col>
            <Text size="md" fw="bold" ta="center">
              Điều chuyển liên chi nhánh
            </Text>
          </Grid.Col>
          <Grid.Col>
            <Checkbox
              label={"Điều chuyển"}
              onKeyDown={handleKeyDown}
              {...form.getInputProps("dieuChuyenLienchinhanh")}
            />
          </Grid.Col>
          <Grid.Col>
            <TextInput
              label={"Người điều chuyển"}
              placeholder={"Nhập người điều chuyển"}
              type="text"
              onKeyDown={handleKeyDown}
              
              {...form.getInputProps("nguoiDieuChuyenLienchinhanh")}
            />
          </Grid.Col>
        </Grid>
        <Grid mt={8}>
          <Grid.Col>
            <Text size="md" fw="bold" ta="center">
              Điều chuyển nội bộ
            </Text>
          </Grid.Col>
          <Grid.Col>
            <Checkbox
              label={"Điều chuyển"}
              onKeyDown={handleKeyDown}
              {...form.getInputProps("dieuChuyenNoibo")}
            />
          </Grid.Col>
          <Grid.Col>
            <TextInput
              label={"Người điều chuyển"}
              placeholder={"Nhập người điều chuyển"}
              type="text"
              onKeyDown={handleKeyDown}
              
              {...form.getInputProps("nguoiDieuChuyenNoibo")}
            />
          </Grid.Col>
        </Grid>

        <Group
          justify="end"
          mt="xs"
          style={{
            position: "sticky",
            bottom: 0,
            backgroundColor: "white",
          }}
        >
          <Button
            type="button"
            color="gray"
            loading={visible}
            onClick={() => {
              modals.closeAll();
            }}
            leftSection={!visible ? <IconWindow size={18} /> : undefined}
          >
            Đóng
          </Button>
          <Button
            type="submit"
            color={sky_blue.base}
            loading={visible}
            leftSection={!visible ? <IconCheck size={18} /> : undefined}
          >
            Lưu
          </Button>
          <></>
        </Group>
      </Box>
    </>
  );
};

type CreateDateViewProps = {
  onClose: any; // Function run on close the modal
};
