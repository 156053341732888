import {
  Box,
  Button,
  Flex,
  ScrollArea,
  Table,
  Title,
  Checkbox,
} from "@mantine/core";
import { IconPlus, IconTrash, IconX } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import classes from "../../Styles/ScrollTable.module.css";
import cx from "clsx";
import { modals } from "@mantine/modals";
import ModalCustomers from "../../Modals/Customers";
import { UseFormReturnType } from "@mantine/form";
import {
  listCustGroup,
  tblPromotion,
  tblPromotionDetailModel,
} from "../../../../../../model/TblPromotion";
import { repositoryPos } from "../../../../../../_base/_const/_constVar";
import { MessageResponse } from "../../../../../../model/MessageResponse";
import { TblDmCustomerCategory } from "../../../../../../model/TblDmCustomerCategory";
import { TblDmCustomer } from "../../../../../../model/TblDmCustomer";
import { red, teal } from "../../../../../../const/variables";

const Customers = ({
  // form,
  dataListCustGroup,
  setDataListCustGroup,
  setDataDetailPromotion,
}: {
  // form: UseFormReturnType<tblPromotion, (values: tblPromotion) => tblPromotion>;
  dataListCustGroup: listCustGroup[];
  setDataListCustGroup: React.Dispatch<React.SetStateAction<listCustGroup[]>>;
  setDataDetailPromotion: React.Dispatch<
    React.SetStateAction<tblPromotionDetailModel>
  >;
}) => {
  // const [dataListCustGroup, setDataListCustGroup] = useState<TblDmCustomer[]>([]);
  const [dataCustomerCat, setDataCustomerCat] = useState<
    TblDmCustomerCategory[]
  >([]);
  const [scrolled, setScrolled] = useState(false);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [height, setHeight] = useState(0);

  const openModalCustomers = () => {
    modals.openConfirmModal({
      centered: true,
      size: "600px",
      title: <Title order={5}>Danh sách nhóm khách hàng</Title>,
      children: (
        <ModalCustomers
          data={dataCustomerCat}
          handleAddCustomer={handleAddCustomer}
          dataListCustGroup={dataListCustGroup}
        />
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setSelectAll(checked);
    setSelectedRows(checked ? dataListCustGroup.map((item) => item.cusId) : []);
  };

  const handleCheckboxChange = (
    id: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const checked = event.target.checked;
    const updatedSelectedRows = checked
      ? [...selectedRows, id]
      : selectedRows.filter((item) => item !== id);

    setSelectedRows(updatedSelectedRows);
    setSelectAll(updatedSelectedRows.length === dataListCustGroup.length);
  };

  const handleAddCustomer = (selectedCus: TblDmCustomer[]) => {
    // Create a Map to keep track of unique customers by their id
    const uniqueCustomersMap = new Map<number, listCustGroup>();

    // Add existing customers from dataListCustGroup to the map
    dataListCustGroup.forEach((customer) =>
      uniqueCustomersMap.set(customer.cusId, customer)
    );

    // Add new customers from selectedCus to the map, replacing duplicates if they exist
    selectedCus.forEach((customer) =>
      uniqueCustomersMap.set(customer.id, {
        cusId: customer.id,
        cusCode: customer.code,
        cusName: customer.name,
      })
    );

    // Convert the map back to an array
    const uniqueCustomersArray = Array.from(uniqueCustomersMap.values());

    // Update the state with the unique array of customers
    setDataListCustGroup(uniqueCustomersArray);
    // setDataListCustGroup(uniqueCustomersArray)

    // Extract the ids for form field value
    const uniqueIdsArray = uniqueCustomersArray.map(
      (customer) => customer.cusId
    );
    setDataDetailPromotion((prevData) => ({
      ...prevData,
      listCustGroup: uniqueIdsArray,
    }));
    modals.closeAll();
  };

  const handleDelete = (index?: number) => {
    if (index !== undefined && index !== null) {
      const updatedData = dataListCustGroup.filter((_, i) => i !== index);
      setDataListCustGroup(updatedData);
      setDataDetailPromotion((prevData) => ({
        ...prevData,
        listCustGroup: updatedData.map((cus) => cus.cusId),
      }));
      setSelectedRows([]);
      setSelectAll(false);
    } else {
      const updatedData = dataListCustGroup.filter(
        (item) => !selectedRows.includes(item.cusId)
      );
      setDataListCustGroup(updatedData);
      setDataDetailPromotion((prevData) => ({
        ...prevData,
        listCustGroup: updatedData.map((cus) => cus.cusId),
      }));
      setSelectedRows([]);
      setSelectAll(false);
    }
  };

  useEffect(() => {
    const fetchDataCustomerCat = async () => {
      const dataApi = await repositoryPos.get<MessageResponse<any>>(
        "/api/v1/TblDmCustomerGroup/get-all"
      );

      if (dataApi && dataApi.success) {
        setDataCustomerCat(dataApi.data);
      } else setDataCustomerCat([]);
    };

    fetchDataCustomerCat();

    // if (form.values.tblPromotionCommand.listCustGroup.length > 0) {
    //   setDataListCustGroup(form.values.tblPromotionCommand.listCustGroup);
    // } else setDataListCustGroup([]);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight - 510);
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  return (
    <Box mt={10}>
      <ScrollArea
        h={height}
        onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
      >
        <Table
          striped
          highlightOnHover
          withColumnBorders
          withTableBorder
          style={{ overflowY: "auto", height: "30px" }}
        >
          <Table.Thead
            className={cx(classes.header, { [classes.scrolled]: scrolled })}
          >
            <Table.Tr>
              <Table.Th w={30}>
                <Checkbox checked={selectAll} onChange={handleSelectAll} />
              </Table.Th>
              {/* <Table.Th>Mã nhóm</Table.Th> */}
              {/* <Table.Th>Nhóm khách hàng</Table.Th> */}
              <Table.Th>Mã khách</Table.Th>
              <Table.Th>Tên khách hàng</Table.Th>
              <Table.Th w={70}>Thao tác</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {dataListCustGroup?.map((item, index) => (
              <Table.Tr
                key={index}
                style={{
                  backgroundColor: selectedRows.includes(item.cusId)
                    ? "var(--mantine-color-blue-light)"
                    : "",
                }}
              >
                <Table.Td w={30}>
                  <Checkbox
                    checked={selectedRows.includes(item.cusId)}
                    onChange={(e) => handleCheckboxChange(item.cusId, e)}
                  />
                </Table.Td>

                {/* <Table.Td>{item.groupName}</Table.Td> */}
                <Table.Td>{item.cusCode}</Table.Td>
                <Table.Td>{item.cusName}</Table.Td>
                <Table.Td>
                  <IconTrash
                    cursor={"pointer"}
                    color="red"
                    onClick={() => handleDelete(index)}
                  />
                </Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
          {dataListCustGroup.length < 1 && (
            <Table.Caption>Không có dữ liệu</Table.Caption>
          )}
        </Table>
      </ScrollArea>
      <Flex align={"center"} justify={"space-between"} m={"10px 0px"}>
        <Flex align="center" gap={5}>
          <Button
            size="xs"
            variant="outline"
            leftSection={<IconPlus size={14} />}
            color={teal.base}
            onClick={openModalCustomers}
          >
            Thêm
          </Button>
          <Button
            size="xs"
            variant="outline"
            leftSection={<IconX size={14} />}
            color={red.base}
            onClick={() => handleDelete()}
          >
            Xóa
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
};

export default Customers;
