import {
  Badge,
  Box,
  Button,
  Divider,
  Fieldset,
  Grid,
  Group,
  Text,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDebouncedState, useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { IconArrowBack, IconCheck } from "@tabler/icons-react";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  repositoryDelivery,
  repositoryPos,
} from "../../_base/_const/_constVar";
import { NotificationExtension } from "../../_base/extension/NotificationExtension";
import { getValueById } from "../../_base/helper/FunctionHelper";
import { SocketExtension } from "../../_base/socket/socket";
import { formatDateTransfer } from "../../common/FormatDate/FormatDate";
import { sky_blue } from "../../const/variables";
import { ComboboxItem } from "../../model/ComboboxItem";
import { MessageResponse } from "../../model/MessageResponse";
import { TblAssignTestingTechniqueModel } from "../../model/TblTestingTechniqueHeader";
import { getTechnicalTestingDifficultySelect } from "../../service/getSelectApi";
import ListProductDetail from "./ListProductDetail";

export interface UpdateStatusDetails {
  headerId: number;
  updateStatusTblTestingTechniqueHeaderDetailCommandModels: UpdateStatusTblTestingTechniqueHeaderDetailCommandModel[];
}

interface UpdateStatusTblTestingTechniqueHeaderDetailCommandModel {
  id: number;
  testDifficulty: string | null;
  testingStatus: string | null;
  noteByEmployee: string | null;
}

const ModalUpdateTestingTechnique = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const id = location?.state && location.state.id;
  const dataStatusOption = location?.state && location.state.dataStatusOption;
  const dataSourceStatus = location?.state && location.state.dataSourceStatus;
  const entity = {
    testingTechniqueHeaderId: Number(id),
    processingDate: null,
    priorityId: null,
    difficultyLevelId: null,
    note: null,
    responsibleEmployeeId: null,
    assignToTblTestingTechniqueHeaderCommandModels: null,
  };

  const entitySubmit = {
    headerId: 0,
    updateStatusTblTestingTechniqueHeaderDetailCommandModels: [],
  };
  const form = useForm<any>({
    mode: "uncontrolled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },

    transformValues: (values) => ({
      ...values,
      priorityId: Number(values.priorityId),
      difficultyLevelId: Number(values.difficultyLevelId),
    }),
  });

  const submitFrom = useForm<UpdateStatusDetails>({
    mode: "uncontrolled",
    initialValues: {
      ...entitySubmit,
    },
  });

  const [visible, { toggle, close, open }] = useDisclosure(false);

  const [searchEmployee, setSearchEmployee] = useDebouncedState<string>(
    "",
    300
  );
  const [dataEmployeeOption, setDataEmployeeOption] = useState<any[]>([]);

  const [dataDetail, setDataDetail] = useState<any>({});
  const [
    dataTechnicalTestingDifficultySelect,
    setDataTechnicalTestingDifficultySelect,
  ] = useState<ComboboxItem[]>([]);
  const initialScreenHeightRef = useRef<number>(window.innerHeight);
  const boxTopRef = useRef<number>(0);
  const [bottomBoxHeight, setBottomBoxHeight] = useState(0);

  const getDetailProduct = async () => {
    const res = await repositoryDelivery.get<MessageResponse<any>>(
      `/api/v1/TblTestingTechniqueHeader/get-detail?id=${id}`
    );

    if (res && res?.success) {
      const dataApi = res.data;
      setDataDetail(dataApi);
      form.setValues(dataApi);
      submitFrom.setValues((prev) => ({
        headerId: dataApi?.id,
        updateStatusTblTestingTechniqueHeaderDetailCommandModels:
          dataApi?.tblTestingTechniqueDetailModels?.map((item: any) => ({
            id: item?.id,
            testDifficulty: item?.testDifficulty,
            testingStatus: item?.testingStatus,
            noteByEmployee: item?.noteByEmployee,
          })),
      }));
    }
  };

  const handleAssignEmployee = async (dataSubmit: any) => {
    const url = `/api/v1/TblTestingTechniqueHeader/update-status-detail`;
    open();

    try {
      const response = await repositoryDelivery.post<
        MessageResponse<UpdateStatusDetails>
      >(url, dataSubmit);
      if (response?.success) {
        navigate("/testing/testing-technique");
        NotificationExtension.Success("Cập nhật kiểm tra thành công");
        modals.closeAll();
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    close();
  };

  const getDataTechnicalTestingDifficultySelect = async () => {
    const res = await getTechnicalTestingDifficultySelect();
    setDataTechnicalTestingDifficultySelect(
      res
        .filter(
          (item) =>
            item.value != null && item.text != null && item.value !== "-1"
        )
        .map((item) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const getDataEmployee = async () => {
    const res = await repositoryPos.get<MessageResponse<any>>(
      `/api/v1/TblDmEmployee/get-list?Take=100&KeySearch=${searchEmployee}`
    );

    if (res && res?.success) {
      const result = res.data;
      setDataEmployeeOption(
        result?.filter((item: any) =>
          item.roleName.toLowerCase().includes("kỹ thuật")
        )
      );
    } else {
      setDataEmployeeOption([]);
    }
  };

  useEffect(() => {
    Promise.all([
      // getDataDeliveryPriotitySelect(),
      getDataTechnicalTestingDifficultySelect(),
    ]);
  }, []);

  useEffect(() => {
    if (searchEmployee && searchEmployee.length > 2) {
      getDataEmployee();
    } else {
      setDataEmployeeOption([]);
    }
  }, [searchEmployee]);

  useEffect(() => {
    if (id) getDetailProduct();
  }, [id]);

  useEffect(() => {
    const updateHeight = () => {
      if (boxTopRef.current) {
        const boxTopHeight = boxTopRef.current;
        const totalHeight = window.innerHeight;
        const calculatedBottomHeight = totalHeight - boxTopHeight;
        setBottomBoxHeight(calculatedBottomHeight);
      }
    };

    updateHeight();

    window.addEventListener("resize", updateHeight);

    return () => window.removeEventListener("resize", updateHeight);
  }, []);

  return (
    <Box
      component="form"
      mx="auto"
      onSubmit={submitFrom.onSubmit((e) => {
        handleAssignEmployee(e);
      })}
    >
      <Box
        ref={(el) => {
          if (el) boxTopRef.current = el.offsetHeight;
        }}
      >
        <Group
          justify="end"
          mt="md"
          style={{
            position: "sticky",
            bottom: 0,
            backgroundColor: "white",
          }}
        >
          <Button
            type="button"
            color="red"
            loading={visible}
            onClick={() => {
              navigate("/testing/testing-technique");
            }}
            leftSection={!visible ? <IconArrowBack size={18} /> : undefined}
          >
            Quay lại
          </Button>
          <Button
            type="submit"
            color={sky_blue.base}
            loading={visible}
            leftSection={!visible ? <IconCheck size={18} /> : undefined}
          >
            Cập nhật
          </Button>
        </Group>

        <Fieldset legend={<Badge>Thông tin đơn hàng</Badge>}>
          <Grid mt={"10px"}>
            <Grid.Col span={{ base: 12, md: 12, lg: 4 }} mb={0}>
              <TextInput
                readOnly
                label="Số phân công"
                value={form.getValues()?.code}
              ></TextInput>
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 12, lg: 4 }}>
              <TextInput
                readOnly
                label="Ngày phân công"
                value={formatDateTransfer(
                  form.getValues()?.assignmentDate ?? new Date()
                )}
              ></TextInput>
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 12, lg: 4 }}>
              <TextInput
                readOnly
                label="Người phân công"
                value={form.getValues()?.assignerName}
              ></TextInput>
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 12, lg: 4 }}>
              <TextInput
                readOnly
                label="Người nhận phân công"
                type="text"
                value={
                  form.getValues()?.responsibleEmployeeName?.toString() ?? null
                }
                {...form.getInputProps("responsibleEmployeeName")}
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 12, lg: 4 }}>
              <TextInput
                readOnly
                label="Người hỗ trợ"
                type="text"
                value={
                  form
                    ?.getValues()
                    ?.tblTestingTechniqueAssignmentModels?.find(
                      (e: any) =>
                        e?.emloyeeId?.toString() !==
                        form?.getValues()?.responsibleEmployeeId?.toString()
                    )
                    ?.emloyeeName?.toString() ?? null
                }
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 12, lg: 4 }}>
              <TextInput readOnly label="Nội dung phân công"></TextInput>
            </Grid.Col>
          </Grid>
        </Fieldset>
        <Divider
          my={10}
          label={
            <Badge color="green" size="md">
              Thông tin chi tiết đơn hàng
            </Badge>
          }
          labelPosition="center"
        />
        <Grid>
          <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
            <TextInput
              label="Mã kiểm tra"
              size="xs"
              readOnly
              value={dataDetail?.code || ""}
            />
          </Grid.Col>

          <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
            <TextInput
              label="Tên khách hàng"
              size="xs"
              readOnly
              value={dataDetail?.customerName || ""}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
            <TextInput
              label="Số điện thoại"
              size="xs"
              readOnly
              value={dataDetail?.customerTelephone || ""}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
            <TextInput
              label="Trạng thái đơn hàng"
              size="xs"
              readOnly
              value={getValueById(
                dataDetail?.sourceStatus?.toString(),
                dataSourceStatus,
                "label"
              )}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
            <TextInput
              label="Trạng thái kiểm tra"
              size="xs"
              readOnly
              value={getValueById(
                dataDetail?.testingStatus?.toString(),
                dataStatusOption,
                "label"
              )}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
            <TextInput
              label="Ghi chú"
              size="xs"
              readOnly
              value={dataDetail?.description || ""}
            />
          </Grid.Col>
        </Grid>
      </Box>
      <ListProductDetail
        form={submitFrom}
        dataDetail={dataDetail?.tblTestingTechniqueDetailModels}
        isDetail={true}
        height={bottomBoxHeight - 190}
      />
    </Box>
  );
};

export default ModalUpdateTestingTechnique;
