import {
  Box,
  Button,
  ComboboxItem,
  Fieldset,
  Grid,
  Group,
  LoadingOverlay,
  NumberInput,
  Select,
  TextInput,
  Textarea,
  Tooltip,
} from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { IconWindow } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { repositoryPos } from "../../../_base/_const/_constVar";
import { MessageResponse } from "../../../model/MessageResponse";
import {
  getCommuneSelect,
  getDistrictSelect,
  getProvinceSelect,
  getTblDmCustomerGroupSelect,
} from "../../../service/getSelectApi";
import { getItemFromData } from "../../../_base/helper/FunctionHelper";

const DetailDataView = ({ id }: DetailDataViewProps) => {
  const entity = {
    id: 0,
    code: null,
    name: null,
    email: null,
    active: false,
    address: null,
    birthOfDate: null,
    contact: null,
    description: null,
    fax: null,
    parentId: null,
    sex: null,
    taxCode: null,
    telephoneNumber: null,
    type: null,
    groupId: null,
    groupName: null,
    attribute1: null,
    attribute2: null,
    attribute3: null,
    attribute4: null,
    attribute5: null,
  };

  const [visible, { toggle, close, open }] = useDisclosure(false);
  const [dataTblCustomerGroup, setDataTblCustomerGroup] = useState<
    { value: string; label: string; att1: string }[]
  >([]);
  const [dataTblProvince, setDataTblProvince] = useState<ComboboxItem[]>([]);
  const [dataTblDistrict, setDataTblDistrict] = useState<ComboboxItem[]>([]);
  const [dataTblCommune, setDataTblCommune] = useState<ComboboxItem[]>([]);
  const [dataTblDebtGroup, setDataTblDebtGroup] = useState<ComboboxItem[]>([]);
  const [searchDebt, setSearchDebt] = useState("");

  const form = useForm<any>({
    mode: "uncontrolled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },
  });

  const callApiGetData = async () => {
    open();
    const callApi = await repositoryPos.get<MessageResponse<any>>(
      `/api/v1/TblDmCustomer/get-detail?Id=${id}`
    );
    if (callApi?.success) {
      const dataApi = callApi?.data;
      await Promise.all([dataCustomerGrouplSelect(), dataProvinceSelect()]);
      if (dataApi !== null) {
        form.setValues(dataApi);
        form.resetDirty(dataApi);
      }
      close();
    }
  };

  const dataCustomerGrouplSelect = async () => {
    try {
      const getData = await getTblDmCustomerGroupSelect();
      const filteredData = getData?.filter(
        (item) => item.value != null && item.text != null
      );
      const mappedData = filteredData?.map((item) => ({
        value: item.value,
        label: item.text,
        att1: item.att1,
      }));
      setDataTblCustomerGroup(mappedData);
    } catch (error) {
      console.error("Error in dataTblLocation: ", error);
    }
  };

  const dataProvinceSelect = async () => {
    try {
      const getData = await getProvinceSelect();
      const filteredData = getData?.filter(
        (item) => item.value != null && item.text != null
      );
      const mappedData = filteredData?.map((item) => ({
        value: item.value,
        label: item.text,
      }));
      setDataTblProvince(mappedData);
    } catch (error) {
      console.error("Error in dataTblLocation: ", error);
    }
  };

  const dataDistrictlSelect = async (idProvince: string) => {
    try {
      const getData = await getDistrictSelect(idProvince);
      const filteredData = getData?.filter(
        (item) => item.value != null && item.text != null
      );
      const mappedData = filteredData?.map((item) => ({
        value: item.value,
        label: item.text,
      }));
      setDataTblDistrict(mappedData);
    } catch (error) {
      console.error("Error in dataTblLocation: ", error);
    }
  };

  const dataCommuneSelect = async (idDistrict: string) => {
    try {
      const getData = await getCommuneSelect(idDistrict);
      const filteredData = getData?.filter(
        (item) => item.value != null && item.text != null
      );
      const mappedData = filteredData?.map((item) => ({
        value: item.value,
        label: item.text,
      }));
      setDataTblCommune(mappedData);
    } catch (error) {
      console.error("Error in dataTblLocation: ", error);
    }
  };

  const fetchDataDebt = async () => {
    const search =
      form?.getValues()?.attribute1?.toString() ?? searchDebt.toLowerCase();
    let url = `/api/v1/TblDebtGroup/get-list?Ship=0&Take=30`;
    if (search) {
      url += `&KeySearch=${search}`;
    }
    try {
      const response = await repositoryPos.get<MessageResponse<any[]>>(url);
      if (response?.success && response?.data) {
        const data = response.data;
        const mappedData = Array.from(
          new Map(
            data.map((value) => [
              value?.groupCode,
              {
                value: value?.groupCode?.toString(),
                label: `${value?.groupCode?.toString()} - ${value?.groupName?.toString()}`,
              },
            ])
          ).values()
        );
        setDataTblDebtGroup(mappedData);
      } else {
        setDataTblDebtGroup([]);
      }
    } catch (error) {
      setDataTblDebtGroup([]);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    async function callApi() {
      Promise.all([callApiGetData(), fetchDataDebt()]);
    }
    if (id) callApi();
  }, [id]);

  useEffect(() => {
    if (searchDebt && searchDebt.length > 4) {
      fetchDataDebt();
    } else setDataTblDebtGroup([]);
  }, [searchDebt]);

  useEffect(() => {
    if (form.getValues().province && dataTblDistrict.length === 0) {
      dataDistrictlSelect(form.getValues().province);
    } else {
      setDataTblDistrict([]);
    }
  }, [form.getValues().province]);

  useEffect(() => {
    if (form.getValues().district && dataTblCommune.length === 0) {
      dataCommuneSelect(form.getValues().district);
    } else {
      setDataTblCommune([]);
    }
  }, [form.getValues().district]);

  return (
    <Box
      component="form"
      mx="auto"
      w={{
        base: "300px",
        xs: "400px",
        sm: "500px",
        md: "650px",
        lg: "700px",
      }}
      style={{ position: "relative" }}
    >
      <LoadingOverlay
        visible={visible}
        zIndex={1000}
        overlayProps={{ radius: "sm", blur: 2 }}
      />

      <Grid mt={10}>
        <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
          <Select
            rightSection={<></>}
            label={"Loại KH"}
            data={[
              {
                value: "1",
                label: "Cá nhân",
              },
              {
                value: "2",
                label: "Doanh nghiệp",
              },
              {
                value: "3",
                label: "Doanh nghiệp được cấp hạn mức",
              },
            ]}
            readOnly
            variant="filled"
            allowDeselect={false}
            value={form.getValues()?.type?.toString() ?? null}
            {...form.getInputProps("type")}
            onChange={(value) => {
              form.setValues((prev) => ({
                ...prev,
                type: value?.toString(),
                groupId: value?.toString() === "2" ? "142" : null,
              }));
            }}
          />
        </Grid.Col>

        <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
          <TextInput
            label={"MSKH"}
            type="text"
            readOnly
            variant="filled"
            {...form.getInputProps("code")}
          />
        </Grid.Col>

        <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
          {form.getValues().type?.toString() === "3" ? (
            <TextInput
              label="Đối tượng công nợ"
              value={form.getValues()?.code}
              variant="filled"
              readOnly
            />
          ) : (
            <Tooltip
              label={
                getItemFromData(dataTblDebtGroup, form.getValues().attribute1)
                  ?.label
              }
            >
              <Select
                rightSection={<></>}
                label={"Đối tượng công nợ"}
                readOnly
                variant="filled"
                value={form.getValues().attribute1?.toString() ?? null}
                data={dataTblDebtGroup}
                {...form.getInputProps("attribute1")}
              />
            </Tooltip>
          )}
        </Grid.Col>

        <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
          <Select
            rightSection={<></>}
            label={"Nhóm khách hàng"}
            searchable
            readOnly
            variant="filled"
            value={form.getValues().groupId?.toString() ?? null}
            data={dataTblCustomerGroup?.filter(
              (value: any) =>
                value?.att1?.toString() === form?.getValues().type?.toString()
            )}
            {...form.getInputProps("groupId")}
          />
        </Grid.Col>

        <Grid.Col span={12} py={0}>
          <Grid>
            <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
              <Grid>
                <Grid.Col span={12}>
                  <TextInput
                    label={"Tên doanh nghiệp"}
                    type="text"
                    readOnly
                    variant="filled"
                    {...form.getInputProps("companyName")}
                  />
                </Grid.Col>
              </Grid>

              <Fieldset legend="Người mua hàng">
                <Grid>
                  <Grid.Col span={12}>
                    <TextInput
                      label={"Họ và tên"}
                      type="text"
                      readOnly
                      variant="filled"
                      {...form.getInputProps("name")}
                    />
                  </Grid.Col>

                  <Grid.Col span={12}>
                    <Select
                      rightSection={<></>}
                      readOnly
                      variant="filled"
                      label={"Giới tính"}
                      type="text"
                      data={[
                        {
                          value: "1",
                          label: "Nam",
                        },
                        {
                          value: "2",
                          label: "Nữ",
                        },
                        {
                          value: "3",
                          label: "Khác",
                        },
                      ]}
                      value={form.getValues().sex?.toString() ?? null}
                      {...form.getInputProps("sex")}
                    />
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <DateInput
                      label={"Ngày sinh nhật"}
                      locale="vi"
                      maxDate={new Date()}
                      valueFormat="DD-MM-YYYY"
                      readOnly
                      variant="filled"
                      value={
                        form.getValues().birthOfDate
                          ? new Date(form.getValues().birthOfDate?.toString())
                          : undefined
                      }
                      {...form.getInputProps("birthOfDate")}
                    />
                  </Grid.Col>

                  <Grid.Col span={12}>
                    <TextInput
                      label={"Số điện thoại"}
                      type="number"
                      readOnly
                      variant="filled"
                      {...form.getInputProps("telephoneNumber")}
                    />
                  </Grid.Col>
                </Grid>
              </Fieldset>
              <Grid>
                <Grid.Col span={12}>
                  <TextInput
                    label={"Mã số thuế"}
                    type="text"
                    readOnly
                    variant="filled"
                    {...form.getInputProps("taxCode")}
                  />
                </Grid.Col>

                <Grid.Col span={12}>
                  <TextInput
                    label={"Email"}
                    type="text"
                    readOnly
                    variant="filled"
                    {...form.getInputProps("email")}
                  />
                </Grid.Col>
              </Grid>
            </Grid.Col>

            <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
              <Fieldset legend="Công nợ">
                <Grid>
                  <Grid.Col span={{ base: 12 }}>
                    <NumberInput
                      readOnly
                      variant="filled"
                      label={"Thời hạn công nợ (ngày)"}
                      hideControls
                      value={Number(form.getValues()?.attribute2)}
                      key={"attribute2"}
                      {...form.getInputProps("attribute2")}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12 }}>
                    <NumberInput
                      readOnly
                      variant="filled"
                      label={"Hạn mức công nợ"}
                      thousandSeparator=","
                      hideControls
                      value={Number(form.getValues()?.debtLimit)}
                      key={"debtLimit"}
                      {...form.getInputProps("debtLimit")}
                    />
                  </Grid.Col>
                </Grid>
              </Fieldset>

              <Fieldset mt="7px" legend="Địa chỉ">
                <Grid>
                  <Grid.Col span={{ base: 12 }}>
                    <Select
                      rightSection={<></>}
                      readOnly
                      variant="filled"
                      label={"Tỉnh/Thành phố"}
                      searchable
                      data={dataTblProvince}
                      onClick={async () => {
                        if (dataTblProvince.length === 0) {
                          await dataProvinceSelect();
                        }
                      }}
                      value={form.getValues().province?.toString() ?? null}
                      {...form.getInputProps("province")}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12 }}>
                    <Select
                      rightSection={<></>}
                      label={"Quận/Huyện"}
                      searchable
                      readOnly
                      variant="filled"
                      data={dataTblDistrict}
                      value={form.getValues().district?.toString() ?? null}
                      {...form.getInputProps("district")}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12 }}>
                    <Select
                      rightSection={<></>}
                      label={"Phường/Xã"}
                      searchable
                      readOnly
                      variant="filled"
                      data={dataTblCommune}
                      value={form.getValues().commune?.toString() ?? null}
                      {...form.getInputProps("commune")}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12 }}>
                    <TextInput
                      label={"Địa chỉ cụ thể"}
                      h={"100%"}
                      readOnly
                      variant="filled"
                      {...form.getInputProps("address")}
                    />
                  </Grid.Col>
                </Grid>
              </Fieldset>
            </Grid.Col>
          </Grid>
        </Grid.Col>
      </Grid>

      <Group
        justify="end"
        mt="xs"
        style={{
          position: "sticky",
          bottom: 0,
          backgroundColor: "white",
        }}
      >
        <Button
          type="button"
          color="gray"
          loading={visible}
          onClick={() => {
            modals.closeAll();
          }}
          leftSection={!visible ? <IconWindow size={18} /> : undefined}
        >
          Đóng
        </Button>

        <></>
      </Group>
    </Box>
  );
};

export default DetailDataView;

type DetailDataViewProps = {
  id: string | number;
};
