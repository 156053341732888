import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Flex,
  Grid,
  Group,
  Input,
  Title,
  Tooltip,
} from "@mantine/core";
import { modals } from "@mantine/modals";
import {
  IconActivity,
  IconClearAll,
  IconEdit,
  IconFunction,
  IconHandStop,
  IconManualGearbox,
  IconPlus,
  IconSearch,
  IconShoppingBag,
  IconTrash,
  IconZoomReset,
} from "@tabler/icons-react";
import {
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_ExpandedState,
  MRT_PaginationState,
  MRT_RowSelectionState,
  MRT_SortingState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { NotificationExtension } from "../../_base/extension/NotificationExtension";
import Repository from "../../_base/helper/HttpHelper";
import { _localization } from "../../config/location";
import { getProcessDefSelect, getUsersSelect } from "../../const/getSelectApi";
import { MessageResponse } from "../../model/MessageResponse";
import { SelectListItem } from "../../model/SelectListItem";
import GroupAddRole from "./GroupAddRoleAuthozire";
import GroupRoleEdit from "./GroupRoleEdit";
import GroupsRoleAdd from "./GroupsRoleAdd";
import "../../Styles/tab.component.css";
import {
  _timerDelayClick,
  _timerDelaySearch,
  repositoryLog,
  repositoryMaster,
} from "../../_base/_const/_constVar";
import { Historyuseraction } from "../../model/HistoryuseractionModel";
import moment from "moment";
import { formatDateTransfer } from "../../common/FormatDate/FormatDate";
import _breadcrumb from "../../_base/component/_layout/_breadcrumb";

const LoggingView = () => {
  //#region  table
  const headerRef = React.useRef<HTMLDivElement>(null);
  //data and fetching state
  const [data, setData] = useState<Historyuseraction[]>([]);
  const [dataTemp, setDataTemp] = useState<Historyuseraction[]>([]);

  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  //table state
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 100,
  });
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  //

  const [keySearch, setKeySearch] = useState<string>("");
  const [selectIds, setSelectIds] = useState<string[]>([]);

  const [deleteViewStatus, setDeleteViewStatus] = useState(0);
  //count
  const [timeLeft, setTimeLeft] = useState(_timerDelaySearch);
  const [isCounting, setIsCounting] = useState(false);

  useEffect(() => {
    let timer: ReturnType<typeof setInterval>;
    if (isCounting && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsCounting(false);
    }
    return () => clearInterval(timer);
  }, [isCounting, timeLeft]);

  const startCountdown = () => {
    if (!isCounting) {
      setTimeLeft(_timerDelaySearch);
      setIsCounting(true);
    }
  };

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);

  useEffect(() => {
    fetchData();
  }, [deleteViewStatus, sorting, pagination.pageIndex, pagination.pageSize]);

  const fetchData = async () => {
    if (!isCounting) {
      startCountdown();
      setIsLoading(true);
      setIsRefetching(true);
      setOpenedMenu(false);
      try {
        let urlGet =
          "/api/v1/logging/get-list?skip=" +
          pagination.pageIndex * pagination.pageSize +
          "&take=" +
          pagination.pageSize;

        if (keySearch && keySearch.length > 0)
          urlGet = urlGet + "&keySearch=" + keySearch;
        let _softing = "";
        let isOrder = false;
        console.log(55, sorting);
        if (sorting && sorting.length > 0) {
          let _q = sorting[0];
          if (_q) {
            _softing = _q.id;
            isOrder = _q.desc;
          }
        }
        urlGet = urlGet + "&sort=" + _softing + "&isOrder=" + isOrder;

        const response = await repositoryLog.get<
          MessageResponse<Historyuseraction[]>
        >(urlGet);
        if (response && response.success) {
          // const treeData = buildTree(response.data);
          const treeData = response.data;
          setData(treeData ?? []);
          setRowCount(response.totalCount);
          setDataTemp(treeData);
          setSelectIds([]);
          table.resetRowSelection();
        }
      } catch (error) {
        setIsError(true);
        console.error(error);
        return;
      }
      setIsError(false);
      setIsLoading(false);
      setIsRefetching(false);
    }
  };

  const fetchDelete = async () => {
    try {
      let urlGet = "/api/v1/logging/delete-logs";
      const response = await repositoryLog.get<MessageResponse<any>>(urlGet);
      if (response && response.success && response.httpStatusCode === 200) {
        NotificationExtension.Success("Thao tác thành công !");
      } else NotificationExtension.Fails("Thao tác thất bại !");
    } catch (error) {
      setIsError(true);
      console.error(error);
      return;
    }
  };
  /**
   * Chuyển đổi chuỗi DateTime ISO 8601 thành định dạng "giờ:phút ngày-tháng-năm"
   * @param isoDateString - Chuỗi DateTime theo định dạng ISO 8601
   * @returns Định dạng "giờ:phút ngày-tháng-năm"
   */
  function formatDate(isoDateString: string, add?: boolean): string {
    const date: Date = new Date(isoDateString);

    // Cộng thêm 7 tiếng
    if (add) date.setHours(date.getHours() + 7);
    // Lấy các thành phần ngày giờ sau khi cộng thêm số giờ
    const hours: string = date.getHours().toString().padStart(2, "0");
    const minutes: string = date.getMinutes().toString().padStart(2, "0");
    const seconds: string = date.getSeconds().toString().padStart(2, "0");
    const day: string = date.getDate().toString().padStart(2, "0");
    const month: string = (date.getMonth() + 1).toString().padStart(2, "0"); // Tháng bắt đầu từ 0
    const year: number = date.getFullYear();

    // Định dạng thành chuỗi "giờ:phút:giây ngày-tháng-năm"
    return `${hours}:${minutes}:${seconds} ${day}-${month}-${year}`;
  }
  const columns = React.useMemo<MRT_ColumnDef<Historyuseraction>[]>(
    () => [
      {
        accessorKey: "id",
        header: "Id",
      },

      {
        accessorKey: "empid",
        header: "Mã nhân viên",
      },
      {
        accessorKey: "username",
        header: "Tài khoản",
      },
      {
        accessorKey: "nameemp",
        header: "Tên nhân viên",
      },
      {
        accessorKey: "ip",
        header: "Ip máy khách",
      },
      {
        accessorKey: "time",
        header: "Độ trễ thao tác (Milliseconds)",
      },
      {
        accessorKey: "nameaction",
        header: "Chức năng thao tác",
      },
      {
        accessorKey: "codeaction",
        header: "Mã chức năng",
      },
      {
        accessorKey: "createdate",
        header: "Giờ vào",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDate(renderedCellValue, true)}</>
        ),
      },
      {
        accessorKey: "exitdate",
        header: "Giờ ra",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDate(renderedCellValue, true)}</>
        ),
      },
      {
        accessorKey: "logdate",
        header: "Ngày ghi",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDate(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "env",
        header: "Môi trường",
      },
    ],
    []
  );
  const [openedMenu, setOpenedMenu] = useState(false);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 160 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (240 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);
  const table = useMantineReactTable({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    //add custom action buttons to top-left of top toolbar
    renderTopToolbarCustomActions: ({ table }) => (
      <Box>
        <_breadcrumb></_breadcrumb>
        <Group>
          <Input
            placeholder="Nhập từ khóa ..."
            onChange={(e) => {
              setKeySearch(e.target.value ?? "");
            }}
          />
          <Button
            ml={"xs"}
            leftSection={<IconSearch size={14} />}
            color="blue"
            variant="outline"
            disabled={isCounting}
            onClick={async () => {
              await fetchData();
            }}
          >
            Tìm kiếm
          </Button>

          <Button
            ml={"xs"}
            leftSection={<IconClearAll size={14} />}
            color="red"
            variant="outline"
            disabled={isCounting}
            onClick={async () => {
              await fetchDelete();
            }}
          >
            Dọn dẹp
          </Button>
        </Group>
      </Box>
    ),
    renderToolbarInternalActions: ({ table }) => <></>,

    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    // enableRowSelection: true,
    getRowId: (row) => row?.id?.toString(),
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select", "userName"],
        right: ["action"],
      },
      columnVisibility: { id: false },
      density: "xs",
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    rowCount,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        fontWeight: "bold", //conditional styling
        fontSize: "12.5px",
        padding: "5px 15px",
        //  backgroundColor: row.original.userName.length>0 ? "white" : "gray",
      },
    }),
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no data
      sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["100", "150", "200"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
  });
  //#endregion
  return (
    <>
      <div className="mt-5">
        <MantineReactTable table={table} />
      </div>
    </>
  );
};
export default LoggingView;
