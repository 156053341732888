import { Flex, Image } from "@mantine/core";
import { IconCheck } from "@tabler/icons-react";
import { forwardRef } from "react";
import styles from "../DMExportBorrowedGoods/PrintStyle.module.css";
interface PrintedInvoiceProps {
  id: string | number;
  dataPrint?: any;
}

const PrintImportBorrowedGoods = forwardRef<
  HTMLDivElement,
  PrintedInvoiceProps
>(({ id, dataPrint }, ref) => {
  return (
    <div ref={ref}>
      <div className={styles.containerPrint}>
        <div className={styles.contentMain}>
          {/* header */}
          <table className={styles.reportContainer}>
            <thead className={styles.reportHeader}>
              <tr>
                <th className={styles.reportHeaderCell}>
                  <div className={`${styles.header} ${styles.pageHeader}`}>
                    <Flex direction="row" justify="space-between" align="start">
                      <div className={styles.logo}>
                        <Image
                          radius="md"
                          src="https://hanoicomputercdn.com/media/lib/17-10-2024/logo-hacom-since-2001_new.png"
                          className={styles.logo}
                          style={{ width: "150px" }}
                        />
                      </div>
                      <div className={styles.headerRight}>
                        <h4
                          className={styles.text}
                          style={{
                            fontSize: "14.5px",
                            fontWeight: 900,
                          }}
                        >
                          CÔNG TY CỔ PHẦN ĐẦU TƯ CÔNG NGHỆ HACOM
                        </h4>
                        <Flex direction="column" gap="0">
                          <Flex direction="row">
                            <span
                              style={{
                                color: "rgb(230, 37, 43)",
                                fontWeight: 800,
                              }}
                            >
                              Trụ sở:
                            </span>
                            <span>
                              129+131 Lê Thanh nghị, Q.Hai Bà Trưng, TP.Hà Nội
                            </span>
                          </Flex>
                          <Flex direction="row" gap="lg">
                            <Flex direction="row">
                              <span
                                style={{
                                  color: "rgb(230, 37, 43)",
                                  fontWeight: 800,
                                }}
                              >
                                Tel:
                              </span>
                              <span>19001903</span>
                            </Flex>
                            <Flex direction="row">
                              <span
                                style={{
                                  color: "rgb(230, 37, 43)",
                                  fontWeight: 800,
                                }}
                              >
                                Email:
                              </span>
                              <span>info@hacom.vn</span>
                            </Flex>
                          </Flex>
                          <Flex direction="row">
                            <span
                              style={{
                                color: "rgb(230, 37, 43)",
                                fontWeight: 800,
                              }}
                            >
                              Website:
                            </span>{" "}
                            <span>www.hacom.vn</span>
                          </Flex>
                        </Flex>
                      </div>
                    </Flex>
                  </div>
                </th>
              </tr>
            </thead>
            {/* <tfoot className={styles.reportFooter}>
              <tr>
                <td className={styles.reportFooterCell}>
                  <Flex
                    direction="row"
                    style={{ width: "100%" }}
                    className={styles.footerInfo}
                    justify="space-between"
                    align={"center"}
                  >
                    <div>
                      <span style={{ fontSize: "10px" }}>
                        BM.HCKT.HACOM_0130{" "}
                      </span>
                    </div>
                    <div style={{ fontSize: "10px" }}>Trang</div>
                  </Flex>
                </td>
              </tr>
            </tfoot> */}
            <tbody className={styles.reportContent}>
              <tr>
                <td className={styles.reportContentCell}>
                  <div className={styles.contentMain}>
                    <div>
                      <h3
                        className={styles.text}
                        style={{
                          textAlign: "center",
                          fontWeight: 600,
                          fontSize: "18px",
                        }}
                      >
                        BIÊN BẢN HOÀN TRẢ SẢN PHẨM CHO MƯỢN
                      </h3>
                    </div>
                    <Flex
                      direction="column"
                      style={{ padding: "0 30px", margin: "0 auto" }}
                    >
                      <span
                        style={{
                          fontStyle: "italic",
                          fontSize: "14.5px",
                          lineHeight: "1.6",
                          color: "#000",
                          fontWeight: "normal",
                        }}
                      >
                        Căn cứ Phiếu tiếp nhận – Bàn giao sản phẩm bảo hành số:
                        ………………. ngày …… tháng …… năm 20……;
                      </span>
                      <span
                        style={{
                          fontStyle: "italic",
                          fontSize: "14.5px",
                          lineHeight: "1.6",
                          fontWeight: "normal",
                          color: "#000",
                        }}
                      >
                        Căn cứ Biên bản bàn giao sản phẩm cho mượn ngày …...
                        tháng …… năm ………;
                      </span>
                      <span
                        style={{
                          fontStyle: "italic",
                          fontSize: "14.5px",
                          lineHeight: "1.6",
                          fontWeight: "normal",
                          color: "#000",
                        }}
                      >
                        Căn cứ tình hình thực tế hai bên.
                      </span>
                      <span
                        style={{
                          fontSize: "14.5px",
                          lineHeight: "1.6",
                          fontWeight: "normal",
                          color: "#000",
                        }}
                      >
                        Hôm nay, ngày ….. tháng ….. năm 20….., tại ………………….,
                        chúng tôi gồm:
                      </span>
                    </Flex>
                    {/* ----- */}
                    <div>
                      <div>
                        <Flex direction="row">
                          <span style={{ fontWeight: 600 }}>
                            A. BÊN A (Bên mượn): Ông/Bà{" "}
                          </span>
                          <span className={styles.dottedLine}></span>
                        </Flex>
                      </div>
                      <div className={styles.infoTop}>
                        <Flex direction="row" align="center">
                          <Flex
                            direction="row"
                            style={{ width: "75%" }}
                            align="center"
                          >
                            <IconCheck
                              stroke={1}
                              style={{
                                marginRight: "5px",
                                marginLeft: "-2px",
                                width: "15px",
                                height: "25px",
                              }}
                            />
                            <span>Địa chỉ</span>
                            <span
                              className={styles.dottedLine}
                              style={{ flexGrow: 1 }}
                            ></span>
                          </Flex>
                          <Flex direction="row" style={{ width: "25%" }}>
                            <span>Điện thoại</span>
                            <span
                              className={styles.dottedLine}
                              style={{ flexGrow: 1 }}
                            ></span>
                          </Flex>
                        </Flex>
                        <Flex direction="row" align="center">
                          <Flex
                            direction="row"
                            align="center"
                            style={{ width: "50%" }}
                          >
                            <IconCheck
                              stroke={1}
                              style={{
                                marginRight: "5px",
                                marginLeft: "-2px",
                                width: "15px",
                                height: "25px",
                              }}
                            />
                            <span>Số CCCD:</span>
                            <span
                              className={styles.dottedLine}
                              style={{ flexGrow: 1 }}
                            ></span>
                          </Flex>
                          <Flex direction="row" style={{ width: "25%" }}>
                            <span>Ngày cấp:</span>
                            <span
                              className={styles.dottedLine}
                              style={{ flexGrow: 1 }}
                            ></span>
                          </Flex>
                          <Flex direction="row" style={{ width: "25%" }}>
                            <span>Nơi cấp</span>
                            <span
                              className={styles.dottedLine}
                              style={{ flexGrow: 1 }}
                            ></span>
                          </Flex>
                        </Flex>
                      </div>
                    </div>
                    {/* ---- */}
                    <div>
                      <div>
                        <Flex direction="row">
                          <span style={{ fontWeight: 700 }}>
                            B. BÊN B (Bên cho mượn): CÔNG TY CỔ PHẦN ĐẦU TƯ CÔNG
                            NGHỆ HACOM
                          </span>
                          {/* <span className={styles.dottedLine}></span> */}
                        </Flex>
                      </div>
                      <div className={styles.infoTop}>
                        <Flex direction="row" align="center">
                          <Flex
                            direction="row"
                            style={{ width: "75%" }}
                            align="center"
                          >
                            <IconCheck
                              stroke={1}
                              style={{
                                marginRight: "5px",
                                marginLeft: "-2px",
                                width: "15px",
                                height: "25px",
                              }}
                            />
                            <span>Địa chỉ</span>
                            <span
                              className={styles.dottedLine}
                              style={{ flexGrow: 1 }}
                            ></span>
                          </Flex>
                          <Flex direction="row" style={{ width: "25%" }}>
                            <span>Điện thoại</span>
                            <span
                              className={styles.dottedLine}
                              style={{ flexGrow: 1 }}
                            ></span>
                          </Flex>
                        </Flex>
                        <Flex direction="row" align="center">
                          <Flex
                            direction="row"
                            align="center"
                            style={{ width: "50%" }}
                          >
                            <IconCheck
                              stroke={1}
                              style={{
                                marginRight: "5px",
                                marginLeft: "-2px",
                                width: "15px",
                                height: "25px",
                              }}
                            />
                            <span>Đại diện:</span>
                            <span
                              className={styles.dottedLine}
                              style={{ flexGrow: 1 }}
                            ></span>
                          </Flex>
                          <Flex direction="row" style={{ width: "50%" }}>
                            <span>Chức vụ:</span>
                            <span
                              className={styles.dottedLine}
                              style={{ flexGrow: 1 }}
                            ></span>
                          </Flex>
                        </Flex>
                      </div>
                    </div>
                    {/* ------ */}
                    <div>
                      <Flex direction="column" className={styles.term}>
                        <span style={{ paddingLeft: "20px" }}>
                          <strong>
                            Hai bên tiến hành bàn giao sản phẩm với nội dung
                            sau:
                          </strong>
                        </span>
                        <span>
                          <strong>1. Sản phẩm cần bàn giao hoàn trả: </strong>
                        </span>
                        <span style={{ paddingLeft: "20px" }}>
                          Bên A tiến hành bàn giao hoàn trả cho bên B các sản
                          phẩm (máy móc, thiết bị, vật tư) sau:
                        </span>
                        {/* --- */}
                      </Flex>
                      <div className={styles.tableProduct}>
                        <table>
                          <thead>
                            <tr>
                              <th style={{ width: "5%" }}>STT</th>
                              <th style={{ width: "60%" }}>Tên sản phẩm</th>
                              <th style={{ width: "5%" }}>SL</th>
                              <th style={{ width: "15%" }}>Tình trạng</th>
                              <th style={{ width: "15%" }}>Ghi chú</th>
                            </tr>
                          </thead>
                          <tbody>
                            {dataPrint?.tblWarrantyExItemBorrowTicketAddDetailModels.map(
                              (item: any, index: number) => {
                                return (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.itemName}</td>
                                    <td>{item.amount}</td>
                                    <td>{item.condition}</td>
                                    <td>{item.note}</td>
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        </table>
                      </div>
                      {/* -- */}
                      <Flex direction="column" className={styles.term}>
                        <span>
                          <strong>
                            2. Tình trạng sản phẩm khi tiếp nhận hoàn trả:
                          </strong>
                        </span>
                        <div>
                          <span>
                            <strong>2.1. Tình trạng vật lý:</strong>
                          </span>
                          <Flex direction="row">
                            <Flex direction="row" style={{ width: "50%" }}>
                              <input type="checkbox" />
                              <span> Đủ điều kiện tiếp nhận lại sản phẩm </span>
                            </Flex>
                            <Flex direction="row" style={{ width: "50%" }}>
                              <input type="checkbox" />
                              <span>
                                {" "}
                                Không đủ điều kiện tiếp nhận lại sản phẩm{" "}
                              </span>
                            </Flex>
                          </Flex>
                          <Flex
                            direction="column"
                            style={{ paddingBottom: "10px" }}
                          >
                            <Flex
                              direction="row"
                              align="center"
                              style={{ width: "100%" }}
                            >
                              <span style={{ fontWeight: 700 }}>
                                Diễn giải chi tiết:
                              </span>
                              <span
                                className={styles.dottedLine}
                                style={{ flexGrow: 1 }}
                              ></span>{" "}
                            </Flex>
                            <div>
                              <div
                                className={styles.dottedLine}
                                style={{ width: "100%", height: "1.5em" }}
                              ></div>{" "}
                            </div>{" "}
                            <div>
                              <div
                                className={styles.dottedLine}
                                style={{ width: "100%", height: "1.5em" }}
                              ></div>{" "}
                            </div>{" "}
                            <div>
                              <div
                                className={styles.dottedLine}
                                style={{ width: "100%", height: "1.5em" }}
                              ></div>{" "}
                            </div>
                          </Flex>
                        </div>
                        <div>
                          <span>
                            <strong>2.2. Tính năng sử dụng:</strong>
                          </span>
                          <Flex direction="row">
                            <Flex direction="row" style={{ width: "50%" }}>
                              <input type="checkbox" />
                              <span> Đủ điều kiện tiếp nhận lại sản phẩm </span>
                            </Flex>
                            <Flex direction="row" style={{ width: "50%" }}>
                              <input type="checkbox" />
                              <span>
                                {" "}
                                Không đủ điều kiện tiếp nhận lại sản phẩm{" "}
                              </span>
                            </Flex>
                          </Flex>
                          <Flex direction="column">
                            <Flex
                              direction="row"
                              align="center"
                              style={{ width: "100%" }}
                            >
                              <span style={{ fontWeight: 700 }}>
                                Diễn giải chi tiết:
                              </span>
                              <span
                                className={styles.dottedLine}
                                style={{ flexGrow: 1 }}
                              ></span>{" "}
                            </Flex>
                            <div>
                              <div
                                className={styles.dottedLine}
                                style={{ width: "100%", height: "1.5em" }}
                              ></div>{" "}
                            </div>{" "}
                            <div>
                              <div
                                className={styles.dottedLine}
                                style={{ width: "100%", height: "1.5em" }}
                              ></div>{" "}
                            </div>{" "}
                            <div>
                              <div
                                className={styles.dottedLine}
                                style={{ width: "100%", height: "1.5em" }}
                              ></div>{" "}
                            </div>
                          </Flex>
                        </div>
                      </Flex>
                    </div>
                    {/* ----- */}
                    <div className={styles.infoBottom}>
                      <Flex direction="column">
                        <div>
                          <span>
                            <strong>
                              3. Phương án xử lý đề xuất trong trường hợp sản
                              phẩm không đảm bảo tình trạng vật lý, công năng sử
                              dụng như tại thời điểm bên B bàn giao cho bên A
                              mượn:
                            </strong>
                          </span>
                          <Flex direction="column">
                            <Flex direction="row">
                              <input type="checkbox" />
                              <span>
                                Bên A bồi thường tổn thất cho bên B bằng hiện
                                vật có giá trị tương đương.{" "}
                              </span>
                            </Flex>
                            <Flex direction="row">
                              <input type="checkbox" />
                              <span>
                                Bên A bồi thường tổn thất cho bên B bằng tiền.
                              </span>
                            </Flex>
                            <Flex direction="row">
                              <input type="checkbox" />
                              <span>
                                Bên B tự tiến hành sửa chữa, khắc phục sản phẩm
                                và bàn giao lại cho bên A theo tình trạng ban
                                đầu.
                              </span>
                            </Flex>
                          </Flex>
                          <Flex direction="column">
                            <Flex
                              direction="row"
                              align="center"
                              style={{ width: "100%" }}
                            >
                              <span style={{ fontWeight: "700" }}>
                                Diễn giải chi tiết:
                              </span>
                              <span
                                className={styles.dottedLine}
                                style={{ flexGrow: 1 }}
                              ></span>{" "}
                            </Flex>
                            <div>
                              <div
                                className={styles.dottedLine}
                                style={{ width: "100%", height: "1.5em" }}
                              ></div>{" "}
                            </div>{" "}
                            <div>
                              <div
                                className={styles.dottedLine}
                                style={{ width: "100%", height: "1.5em" }}
                              ></div>{" "}
                            </div>{" "}
                            <div>
                              <div
                                className={styles.dottedLine}
                                style={{ width: "100%", height: "1.5em" }}
                              ></div>{" "}
                            </div>
                          </Flex>
                        </div>
                        <div>
                          <span>
                            <strong>
                              4. Số tiền đặt cọc bên B hoàn trả cho bên A:
                            </strong>
                          </span>
                          <Flex direction="row" style={{ width: "100%" }}>
                            <span>Số tiền:</span>
                            <span
                              className={styles.dottedLine}
                              style={{ flexGrow: 1 }}
                            ></span>
                          </Flex>
                          <Flex direction="row" style={{ width: "100%" }}>
                            <span>Viết bằng chữ:</span>
                            <span
                              className={styles.dottedLine}
                              style={{ flexGrow: 1 }}
                            ></span>
                          </Flex>
                          <Flex direction="row" gap="lg">
                            <span>Hình thức trả:</span>
                            <Flex direction="row">
                              <input type="checkbox" />
                              <span>Tiền mặt</span>
                            </Flex>
                            <Flex direction="row">
                              <input type="checkbox" />
                              <span>Chuyển khoản</span>
                            </Flex>
                          </Flex>
                          <Flex direction="row">
                            <span>Ngày thực hiện đổi trả:</span>
                            <span>……… ngày……. tháng ….. năm…… </span>
                          </Flex>
                        </div>
                        <div>
                          <span>
                            <strong>
                              5. Số tiền bên A bồi thường cho bên B do bên A làm
                              hỏng/mất trong quá trình mượn sản phẩm (nếu có):
                            </strong>
                          </span>
                          <Flex direction="row" style={{ width: "100%" }}>
                            <span>Số tiền:</span>
                            <span
                              className={styles.dottedLine}
                              style={{ flexGrow: 1 }}
                            ></span>
                          </Flex>
                          <Flex direction="row" style={{ width: "100%" }}>
                            <span>Viết bằng chữ:</span>
                            <span
                              className={styles.dottedLine}
                              style={{ flexGrow: 1 }}
                            ></span>
                          </Flex>
                          <Flex direction="row" gap="lg">
                            <span>Hình thức bồi thường:</span>
                            <Flex direction="row">
                              <input type="checkbox" />
                              <span>Tiền mặt</span>
                            </Flex>
                            <Flex direction="row">
                              <input type="checkbox" />
                              <span>Chuyển khoản</span>
                            </Flex>
                          </Flex>
                          <Flex direction="row">
                            <span>Ngày thực hiện bồi thường:</span>
                            <span>……… ngày……. tháng ….. năm…… </span>
                          </Flex>
                        </div>
                        <div>
                          <span>
                            <strong>6. Điều khoản chung </strong>
                          </span>
                          <span>
                            - Hai Bên cam kết và bảo đảm rằng khi một Bên đã
                            thực hiện đầy đủ trách nhiệm theo Biên bản này, Bên
                            còn lại sẽ không có bất kì khiếu nại hoặc tiến hành
                            bất cứ hành động pháp lý nào khác nhằm chống lại
                            hoặc gây ảnh hưởng/gây thiệt hại đến uy tín, danh
                            dự, hình ảnh, tài sản… của Bên kia. <br /> - Biên
                            bản có hiệu lực ngay sau khi có đầy đủ chữ ký của cả
                            Hai Bên, xác nhận Hai Bên đã hoàn tất trách nhiệm,
                            nghĩa vụ phát sinh từ việc mượn – trả sản phẩm trong
                            quá trình Bên A bảo hành sản phẩm tại Bên B. <br />{" "}
                            - Biên bản này sẽ mặc nhiên hết hiệu lực sau khi các
                            Bên thực hiện xong các nội dung ghi nhận trong Biên
                            bản. <br /> - Biên bản này được lập thành 02 bản,
                            Bên A giữ 01 bản, Bên B giữ 01 bản có giá trị như
                            nhau làm căn cứ thực hiện.
                          </span>
                        </div>
                      </Flex>
                      <Flex
                        direction="row"
                        justify="space-around"
                        style={{ marginTop: "10px" }}
                      >
                        <Flex direction="column">
                          <span style={{ fontWeight: 700 }}>
                            ĐẠI DIỆN BÊN A
                          </span>
                          <span>(Ký, ghi rõ họ tên)</span>
                        </Flex>
                        <Flex direction="column">
                          <span style={{ fontWeight: 700 }}>
                            ĐẠI DIỆN BÊN B
                          </span>
                          <span>(Ký, ghi rõ họ tên)</span>
                        </Flex>
                      </Flex>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
});

export default PrintImportBorrowedGoods;
