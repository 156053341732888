import {
  ActionIcon,
  Box,
  Button,
  Flex,
  Menu,
  NumberFormatter,
  rem,
  Text,
  TextInput,
  Title,
  Tooltip,
} from "@mantine/core";
import {
  IconCaretDown,
  IconCheck,
  IconEdit,
  IconEye,
  IconFileUpload,
  IconPlus,
  IconSearch,
  IconTrash,
} from "@tabler/icons-react";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_Row,
  MRT_RowSelectionState,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useState } from "react";
import { paginationBase } from "../../../../../../_base/model/_base/BaseTable";
import { modals } from "@mantine/modals";
import ModalSearchItems from "../../Modals/SearchItems";
import { repositoryPos } from "../../../../../../_base/_const/_constVar";
import { MessageResponse } from "../../../../../../model/MessageResponse";
import { _localization } from "../../../../../../config/location";
import {
  tblPromotion,
  tblPromotionItemAddOnCommand,
  tblPromotionItemGiftCommand,
  tblPromotionItemPrimaryCommand,
} from "../../../../../../model/TblPromotion";
import ModalDiscountToAll from "../../Modals/ModalItemPrimaryDiscountToAll";
import { UseFormReturnType } from "@mantine/form";
import ModalItemGiftEditDiscount from "../../Modals/ModalItemGiftEditDiscount";

const TableItemGiftPromotion = ({
  dataPromotionItemGiftModels,
}: {
  dataPromotionItemGiftModels: tblPromotionItemGiftCommand[];
}) => {
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 50 });
  const [keySearch, setKeySearch] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [selectIds, setSelectIds] = useState<string[]>([]);
  const [data, setData] = useState<tblPromotionItemGiftCommand[]>([]);
  const [dataTemp, setDataTemp] = useState<tblPromotionItemGiftCommand[]>([]);

  const entity: tblPromotionItemGiftCommand = {
    id: 0,
    promoteId: null,
    promoteDetailId: null,
    idItemPrimary: null,
    idItemGift: null,
    quantityApply: null,
    quantityMin: null,
    quantityMax: null,
    discountPercent: null,
    discountAmount: null,
    price: null,
    priceMin: null,
    priceMax: null,
    // attribute1: null,
    // attribute2: null,
    // attribute3: null,
    // attribute4: null,
    // attribute5: null,
    // attribute6: null,
    // attribute7: null,
    // attribute8: null,
    // attribute9: null,
    // attribute10: null,
    // attribute11: null,
    // attribute12: null,
    // attribute13: null,
    // attribute14: null,
    // attribute15: null,
    itemGiftCode: null,
    itemGiftName: null,
  };

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      //   {
      //     accessorKey: "apply",
      //     header: "Áp dụng cho",
      //     size: 20,
      //   },
      {
        accessorKey: "itemGiftCode",
        header: "Mã hàng",
        size: 20,
      },
      {
        accessorKey: "itemGiftName",
        header: "Tên hàng",
        Cell: ({ renderedCellValue, cell }) => (
          <Tooltip label={renderedCellValue as string}>
            <Text truncate="end" w={300}>
              {renderedCellValue}
            </Text>
          </Tooltip>
        ),
      },
      //   {
      //     accessorKey: "startingPrice",
      //     header: "Giá bán từ",
      //   },
      //   {
      //     accessorKey: "finalPrice",
      //     header: "Đến",
      //   },
      //   {
      //     accessorKey: "pointAccumulationRate",
      //     header: "Tỷ lệ tích điểm thưởng",
      //   },
      //   {
      //     accessorKey: "bonusPoints",
      //     header: "Số điểm thưởng",
      //   },
      // {
      //   accessorKey: "discountPercent",
      //   header: "% chiết khấu",
      // },
      // {
      //   accessorKey: "discountAmount",
      //   header: "Số tiền chiết khấu",
      //   Cell: ({ renderedCellValue, cell }) => (
      //     <NumberFormatter
      //       value={(renderedCellValue as string | number) || 0}
      //       suffix="₫"
      //       thousandSeparator="."
      //       decimalSeparator=","
      //     />
      //   ),
      // },
      //   {
      //     accessorKey: "appliesQuantity",
      //     header: "Áp dụng với số lượng Đ.H",
      //   },
      //   {
      //     accessorKey: "minimumValue",
      //     header: "Giá trị/Đ.Hàng tối thiểu",
      //   },
      //   {
      //     accessorKey: "total",
      //     header: "Tổng SL được mua cả C.Trình/1 Người",
      //   },
      {
        accessorKey: "quantityApply",
        header: "SL cho phép",
      },
      {
        accessorKey: "quantityMin",
        header: "SL tối thiểu 1 lần phải mua/1 Đ.Hàng",
      },
      {
        accessorKey: "quantityMax",
        header: "SLượng tối đa 1 lần được mua/1 Đ.Hàng",
      },

      //   {
      //     accessorKey: "purchasedItems",
      //     header: "Mặt hàng đã mua",
      //   },
      //   {
      //     accessorKey: "bundledItems",
      //     header: "Mặt hàng mua kèm",
      //   },
      //   {
      //     accessorKey: "promotion",
      //     header: "Khuyến mại",
      //   },
      //   {
      //     accessorKey: "websitePromotion",
      //     header: "Khuyến mại Website",
      //   },
      //   {
      //     accessorKey: "applicableItems",
      //     header: "Mặt hàng được áp dụng",
      //   },
      //   {
      //     accessorKey: "allocationRate",
      //     header: "Tỷ lệ phân bổ",
      //     size: 200,
      //   },
      // {
      //   accessorKey: "action",
      //   header: "Thao tác",
      //   size: 50,
      //   Cell: ({ renderedCellValue, row }) => (
      //     <Box
      //       style={{
      //         display: "flex",
      //         alignItems: "center",
      //         gap: "4px",
      //       }}
      //     >
      //       {processTaskActionMenu(row)}
      //     </Box>
      //   ),
      // },
    ],
    []
  );

  function processTaskActionMenu(row: MRT_Row<any>): any {
    return (
      <>
        <Tooltip label="Sửa báo giá">
          <ActionIcon variant="light" aria-label="Settings" color="yellow">
            <IconEdit
              size={14}
              stroke={1.5}
              onClick={() => openModalItemGiftEditDiscount(row.original)}
            />
          </ActionIcon>
        </Tooltip>
        {/* <Tooltip label="Xem chi tiết báo giá">
          <ActionIcon variant="light" aria-label="Settings" color="cyan">
            <IconEye
              size={14}
              stroke={1.5}
              //   onClick={}
            />
          </ActionIcon>
        </Tooltip> */}
        <Tooltip label="Xóa báo giá">
          <ActionIcon variant="light" aria-label="Settings" color="red">
            <IconTrash
              size={14}
              stroke={1.5}
              onClick={() => deletesData([row.id])}
            />
          </ActionIcon>
        </Tooltip>
      </>
    );
  }

  const openSearchItems = () => {
    modals.openConfirmModal({
      size: "700",
      centered: true,
      title: <Title order={5}>Tìm kiếm mặt hàng</Title>,
      children: <ModalSearchItems handleAddItem={handleAddItem} />,
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };

  const openModalDiscountToAll = () => {
    modals.openConfirmModal({
      size: "450",
      title: <Title order={5}>Nhập chiết khấu</Title>,
      children: <ModalDiscountToAll appleDiscountToAll={appleDiscountToAll} />,
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };

  const openModalItemGiftEditDiscount = (
    dataEdit: tblPromotionItemGiftCommand
  ) => {
    modals.openConfirmModal({
      size: "450",
      title: <Title order={5}>Nhập chiết khấu</Title>,
      children: (
        <ModalItemGiftEditDiscount
          dataEdit={dataEdit}
          editItemDiscount={editItemDiscount}
        />
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };

  const handleAddItem = async (
    selectedCatSegment: (string | null)[],
    selectedManuFacTurer: string[],
    selectCat: string | null
  ) => {
    let url = `/api/v1/TblItem/get-list-by-cat?Skip=0&Take=1000`;

    if (selectedCatSegment.length > 0 && selectCat) {
      url += `&Key=${
        {
          "1a": "Cat1",
          "2b": "Cat2",
          "3c": "Cat3",
          "4d": "Cat4",
          "5e": "Cat5",
          "6f": "Cat6",
        }[selectCat] || ""
      }`;

      selectedCatSegment.forEach((cat) => {
        if (cat !== null && cat !== undefined) {
          url += `&ids=${cat}`;
        }
      });
    }

    const dataApi = await repositoryPos.get<MessageResponse<any[]>>(url);
    // console.log(33, dataApi);
    if (dataApi?.success && dataApi.data) {
      const newData: tblPromotionItemGiftCommand[] = dataApi?.data.map(
        (item) => ({
          ...entity,
          idItemGift: item.itemId,
          itemGiftCode: item.itemCode,
          itemGiftName: item.itemName,
        })
      );
      setData(newData);
      setDataTemp(newData);
      setRowCount(dataApi?.totalCount || 0);
      setSelectIds([]);
      table.resetRowSelection();
    } else {
      setData([]);
      setDataTemp([]);
      setSelectIds([]);
      table.resetRowSelection();
    }

    modals.closeAll();
  };

  const handleChangeSearch = (e: any) => {
    const value = e.target.value.toLowerCase() ?? "";
    setKeySearch(value);
    if (value) {
      const searchResult = dataTemp.filter(
        (product) =>
          product.itemGiftCode?.toLowerCase().includes(value) ||
          product.itemGiftName?.toLowerCase().includes(value)
      );
      setRowCount(searchResult.length);
      setSelectIds([]);
      table.resetRowSelection();
      setData(searchResult);
    } else {
      setRowCount(dataTemp.length);
      setData(dataTemp);
      setSelectIds([]);
      table.resetRowSelection();
    }
  };

  const appleDiscountToAll = (dataSubmit: {
    discountPercent: null;
    discountAmount: null;
    quantityMin: null;
    quantityMax: null;
  }) => {
    setDataTemp((prevData) =>
      prevData.map((item) => ({ ...item, ...dataSubmit }))
    );
    setData((prevData) => prevData.map((item) => ({ ...item, ...dataSubmit })));

    modals.closeAll();
  };

  const editItemDiscount = (dataSubmit: tblPromotionItemGiftCommand) => {
    setDataTemp((prevData) =>
      prevData.map((item) =>
        item.idItemGift === dataSubmit.idItemGift ? dataSubmit : item
      )
    );
    setData((prevData) =>
      prevData.map((item) =>
        item.idItemGift === dataSubmit.idItemGift ? dataSubmit : item
      )
    );

    modals.closeAll();
  };

  const deletesData = (ids: string[]) => {
    setDataTemp((prevData) =>
      prevData.filter(
        (item) => !ids.includes(item?.idItemGift?.toString() || "")
      )
    );
    setData((prevData) =>
      prevData.filter(
        (item) => !ids.includes(item?.idItemGift?.toString() || "")
      )
    );

    setSelectIds([]);
    table.resetRowSelection();
  };

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      setHeight(window.innerHeight - (490 + headerHeight));
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setDataTemp(dataPromotionItemGiftModels);
    setData(dataPromotionItemGiftModels);
  }, [dataPromotionItemGiftModels]);

  const table = useMantineReactTable({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    renderTopToolbarCustomActions: ({ table }) => (
      <div ref={headerRef}>
        <Flex
          gap="md"
          justify="flex-start"
          align="center"
          direction="row"
          wrap="wrap"
        >
          <Box>
            <Flex gap="md">
              <TextInput
                placeholder="Nhập từ khoá"
                value={keySearch}
                onChange={handleChangeSearch}
              />

              {/* <TextInput placeholder="Hãng SX" /> */}
            </Flex>
          </Box>
          {/* <Button
            leftSection={<IconSearch size={14} />}
            color="blue"
            variant="outline"
            onClick={async () => {
              // await fetchData();
            }}
          >
            Lọc bảng giá
          </Button> */}
        </Flex>
      </div>
    ),
    renderToolbarInternalActions: ({ table }) => (
      <>
        <Flex
          gap="md"
          justify={"flex-end"}
          align="center"
          direction="row"
          wrap="wrap"
        >
          {/* <Button
            leftSection={<IconPlus size={14} />}
            color="blue"
            variant="outline"
            onClick={openSearchItems}
          >
            Thêm mới (F11)
          </Button>

          <Button
            leftSection={<IconTrash size={14} />}
            color="red"
            variant="outline"
            onClick={() => {
              deletesData(selectIds);
            }}
            disabled={selectIds.length < 1}
          >
            Xóa (đã chọn)
          </Button>

          <Button
            leftSection={<IconEdit size={14} />}
            color="green"
            variant="outline"
            onClick={() => openModalDiscountToAll()}
            disabled={dataTemp.length < 1}
          >
            Thiết lập chiếu khấu
          </Button>

          <Menu shadow="md">
            <Menu.Target>
              <Button
                rightSection={
                  <IconCaretDown style={{ width: rem(14), height: rem(14) }} />
                }
              >
                Chức năng
              </Button>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Label>Dữ liệu</Menu.Label>
              <Menu.Item
                leftSection={
                  <IconFileUpload style={{ width: rem(14), height: rem(14) }} />
                }
              >
                Import Excel
              </Menu.Item>
            </Menu.Dropdown>
          </Menu> */}
        </Flex>
      </>
    ),
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    enableRowSelection: true,
    getRowId: (row) => row?.idItemGift?.toString(),
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: [
          "mrt-row-select",
          "apply",
          "itemGiftCode",
          "namePurchase",
          "nameManufacturer",
        ],
        right: ["allocationRate", "action"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    // manualFiltering: true,
    // manualPagination: true,
    // manualSorting: true,
    rowCount,
    // onColumnFiltersChange: setColumnFilters,
    // onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    // onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        // fontWeight: "bold",
        fontSize: "11px",
        padding: "4px 12px",
      },
    }),
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      // showProgressBars: isRefetching,
      // showSkeletons: isLoading, //loading for the first time with no data
      //   sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: false
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["10", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
  });

  return <MantineReactTable table={table} />;
};

export default TableItemGiftPromotion;
