import {
  ActionIcon,
  Box,
  Button,
  ComboboxItem,
  Flex,
  NumberInput,
  ScrollArea,
  Select,
  Table,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core";
import cx from "clsx";
import classes from "../../../Styles/TableScrollArea.module.css";
import { useEffect, useState } from "react";
import { IconCheck, IconDeviceFloppy, IconEdit } from "@tabler/icons-react";
import { getTblDmUomCodeSelect } from "../../../service/getSelectApi";
import { DatePickerInput } from "@mantine/dates";
import dayjs from "dayjs";

const ProductWarrantyList = ({
  dataRequestFormSelectedDetail,
  warrantyReceiveTicketDetail,
  editAndSave,
  setEditAndSave,
  isEdit,
  setIsEdit,
  handleChangeValue,
  actionView,
  typeProduct,
}: {
  dataRequestFormSelectedDetail: any;
  warrantyReceiveTicketDetail: any;
  editAndSave: any;
  setEditAndSave: any;
  isEdit: any;
  setIsEdit: any;
  handleChangeValue: any;
  actionView?: any;
  typeProduct: any;
}) => {
  const [dataUomCodeSelect, setDataUomCodeSelect] = useState<ComboboxItem[]>(
    []
  );
  const [scrolled, setScrolled] = useState(false);
  const [editIndex, setEditIndex] = useState<number | null>(null);

  const getDataUomCodeSelect = async () => {
    const getData = await getTblDmUomCodeSelect();
    setDataUomCodeSelect(
      getData
        ?.filter((item: any) => item.value != null && item.text != null)
        ?.map((item: any) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  useEffect(() => {
    if (dataRequestFormSelectedDetail !== undefined) {
      getDataUomCodeSelect();
    }
  }, [dataRequestFormSelectedDetail]);

  return (
    <>
      <Flex justify={"end"} gap={"md"}>
        <TextInput placeholder="Nhập mã barcode hoặc dùng máy quét" w={250} />
        <Button>Quét mã</Button>
      </Flex>
      <ScrollArea
        h={275}
        onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
        scrollbars="x"
        mt={10}
      >
        <Table
          striped
          highlightOnHover
          withTableBorder
          withColumnBorders
          w={2100}
        >
          <Table.Thead
            className={cx(classes.header, {
              [classes.scrolled]: scrolled,
            })}
          >
            <Table.Tr>
              {actionView === "DETAIL" ? <></> : <Table.Th>Thao tác</Table.Th>}
              <Table.Th>Hóa đơn</Table.Th>
              <Table.Th>Serial</Table.Th>
              <Table.Th>Sản phẩm</Table.Th>
              <Table.Th>Đơn vị tính</Table.Th>
              <Table.Th>Số lượng</Table.Th>
              <Table.Th>Thời hạn bảo hành</Table.Th>
              <Table.Th>Phụ kiện kèm theo</Table.Th>
              <Table.Th>Ghi nhận lỗi ban đầu</Table.Th>
              <Table.Th>Loại dịch vụ</Table.Th>
              <Table.Th>Hẹn trả hàng</Table.Th>
              <Table.Th>Số ngày hẹn trả hàng</Table.Th>
              <Table.Th>Ghi chú</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {Array.isArray(dataRequestFormSelectedDetail) &&
              dataRequestFormSelectedDetail.length > 0 &&
              dataRequestFormSelectedDetail?.map((item: any, index: any) => (
                <Table.Tr key={index}>
                  {actionView === "DETAIL" ? (
                    <></>
                  ) : (
                    <Table.Td w={70} ta={"center"}>
                      <Flex gap={"xs"}>
                        {editAndSave && editIndex === Number(index) ? (
                          <Tooltip label="Lưu">
                            <ActionIcon
                              size={"24px"}
                              onClick={() => {
                                setIsEdit({
                                  uomId: false,
                                  acessory: false,
                                  testResolution: false,
                                  serviceType: false,
                                  scheduleTime: false,
                                  daysReturn: false,
                                  note: false,
                                });
                                setEditAndSave(false);
                              }}
                            >
                              <IconDeviceFloppy size={"20px"} />
                            </ActionIcon>
                          </Tooltip>
                        ) : (
                          <Tooltip label="Sửa">
                            <ActionIcon
                              size={"24px"}
                              onClick={() => {
                                setIsEdit({
                                  uomId: true,
                                  acessory: true,
                                  testResolution: true,
                                  serviceType: true,
                                  scheduleTime: true,
                                  daysReturn: true,
                                  note: true,
                                });
                                setEditIndex(Number(index));
                                setEditAndSave(true);
                              }}
                            >
                              <IconEdit size={"20px"} />
                            </ActionIcon>
                          </Tooltip>
                        )}
                        <Tooltip label="Nhập hàng lỗi">
                          <ActionIcon
                            size={"24px"}
                            color={
                              warrantyReceiveTicketDetail[index]?.isError
                                ? "gray"
                                : "green"
                            }
                            onClick={() =>
                              handleChangeValue(
                                "isError",
                                warrantyReceiveTicketDetail[index]?.isError ===
                                  null ||
                                  warrantyReceiveTicketDetail[index]
                                    ?.isError === undefined ||
                                  !warrantyReceiveTicketDetail[index]?.isError
                                  ? true
                                  : false,
                                Number(index)
                              )
                            }
                          >
                            <IconCheck size={"20px"} />
                          </ActionIcon>
                        </Tooltip>
                      </Flex>
                    </Table.Td>
                  )}
                  <Table.Td w={125}>
                    {typeProduct === "HANG_HACOM"
                      ? item.orderCode
                      : "Không có hóa đơn"}
                  </Table.Td>
                  <Table.Td w={120}>{item.serial}</Table.Td>
                  <Table.Td w={300}>
                    <Box w={275}>
                      <Tooltip
                        label={
                          typeProduct === "HANG_HACOM"
                            ? item.itemName
                            : item.attribute2
                        }
                      >
                        <Text truncate="end" size="12.5px" h={15}>
                          {typeProduct === "HANG_HACOM"
                            ? item.itemName
                            : item.attribute2}
                        </Text>
                      </Tooltip>
                    </Box>
                  </Table.Td>
                  <Table.Td w={150}>
                    {typeProduct === "HANG_HACOM"
                      ? item.uomName
                      : item.attribute3}
                  </Table.Td>
                  <Table.Td w={150}>1</Table.Td>
                  <Table.Td w={125}>{item.warrantyTerm} tháng</Table.Td>
                  {actionView === "DETAIL" ? (
                    <Table.Td w={150}>{item.acessory}</Table.Td>
                  ) : (
                    <Table.Td
                      onMouseDown={(event: React.MouseEvent) =>
                        event.stopPropagation()
                      }
                      w={150}
                    >
                      {editIndex === Number(index) && isEdit.acessory ? (
                        <TextInput
                          placeholder="Nhập phụ kiện kèm theo"
                          value={warrantyReceiveTicketDetail[index]?.acessory}
                          onChange={(e: any) =>
                            handleChangeValue(
                              "acessory",
                              e ? e.currentTarget.value : null,
                              Number(index)
                            )
                          }
                        />
                      ) : (
                        <>{warrantyReceiveTicketDetail[index]?.acessory}</>
                      )}
                    </Table.Td>
                  )}
                  <Table.Td w={200}>{item.conclusionError}</Table.Td>
                  <Table.Td
                    onMouseDown={(event: React.MouseEvent) =>
                      event.stopPropagation()
                    }
                    w={150}
                  >
                    {editIndex === Number(index) && isEdit.serviceType ? (
                      <Select
                        placeholder="Chọn loại dịch vụ"
                        data={[
                          { label: "Bảo hành", value: "Bảo hành" },
                          { label: "Hỗ trợ", value: "Hỗ trợ" },
                          { label: "Dịch vụ", value: "Dịch vụ" },
                        ]}
                        value={
                          warrantyReceiveTicketDetail[index]?.serviceType
                            ? warrantyReceiveTicketDetail[index]?.serviceType
                            : dataRequestFormSelectedDetail[
                                index
                              ]?.serviceType.toString()
                        }
                        onChange={(e: any) =>
                          handleChangeValue(
                            "serviceType",
                            e
                              ? e?.toString()
                              : dataRequestFormSelectedDetail[
                                  index
                                ]?.serviceType.toString(),
                            Number(index)
                          )
                        }
                      />
                    ) : (
                      <>
                        {warrantyReceiveTicketDetail[index]?.serviceType
                          ? warrantyReceiveTicketDetail[index]?.serviceType
                          : dataRequestFormSelectedDetail[
                              index
                            ]?.serviceType.toString()}
                      </>
                    )}
                  </Table.Td>
                  {actionView === "DETAIL" ? (
                    <Table.Td w={150}>
                      <DatePickerInput
                        valueFormat="DD-MM-YYYY"
                        value={
                          item.scheduleTime ? new Date(item.scheduleTime) : null
                        }
                        variant="unstyled"
                      />
                    </Table.Td>
                  ) : (
                    <Table.Td
                      onMouseDown={(event: React.MouseEvent) =>
                        event.stopPropagation()
                      }
                      w={150}
                    >
                      {editIndex === Number(index) && isEdit.scheduleTime ? (
                        <DatePickerInput
                          placeholder="Nhập ngày hẹn trả"
                          valueFormat="DD-MM-YYYY"
                          locale="vi"
                          value={
                            warrantyReceiveTicketDetail[index]?.scheduleTime
                              ? new Date(
                                  warrantyReceiveTicketDetail[
                                    index
                                  ]?.scheduleTime
                                )
                              : null
                          }
                          minDate={new Date()}
                          onChange={(e: any) => {
                            const selectedDate = dayjs(e);
                            const today = dayjs();
                            const daysDifference = selectedDate.diff(
                              today,
                              "day"
                            );
                            handleChangeValue(
                              "scheduleTime",
                              e ? new Date(e ?? "").toDateString() : null,
                              Number(index)
                            );
                            handleChangeValue(
                              "daysReturn",
                              daysDifference,
                              Number(index)
                            );
                          }}
                        />
                      ) : (
                        <DatePickerInput
                          valueFormat="DD-MM-YYYY"
                          variant="unstyled"
                          value={
                            warrantyReceiveTicketDetail[index]?.scheduleTime
                              ? new Date(
                                  warrantyReceiveTicketDetail[
                                    index
                                  ]?.scheduleTime
                                )
                              : null
                          }
                        />
                      )}
                    </Table.Td>
                  )}
                  {actionView === "DETAIL" ? (
                    <Table.Td w={150}>{item.daysReturn}</Table.Td>
                  ) : (
                    <Table.Td
                      onMouseDown={(event: React.MouseEvent) =>
                        event.stopPropagation()
                      }
                      w={150}
                    >
                      {editIndex === Number(index) && isEdit.daysReturn ? (
                        <NumberInput
                          placeholder="Nhập số ngày hẹn trả hàng"
                          hideControls
                          value={
                            warrantyReceiveTicketDetail[index]?.daysReturn
                              ? warrantyReceiveTicketDetail[index]?.daysReturn
                              : null
                          }
                          onChange={(e) => {
                            handleChangeValue(
                              "daysReturn",
                              e ? Number(e) : null,
                              Number(index)
                            );
                            handleChangeValue(
                              "scheduleTime",
                              e
                                ? new Date(
                                    dayjs(new Date())
                                      .add(Number(e), "day")
                                      .toDate()
                                  )
                                : null,
                              Number(index)
                            );
                          }}
                        />
                      ) : (
                        <>{warrantyReceiveTicketDetail[index]?.daysReturn}</>
                      )}
                    </Table.Td>
                  )}
                  {actionView === "DETAIL" ? (
                    <Table.Td w={200}>{item.note}</Table.Td>
                  ) : (
                    <Table.Td
                      onMouseDown={(event: React.MouseEvent) =>
                        event.stopPropagation()
                      }
                      w={200}
                    >
                      {editIndex === Number(index) && isEdit.note ? (
                        <TextInput
                          placeholder="Nhập ghi chú"
                          value={
                            warrantyReceiveTicketDetail[index]?.note
                              ? warrantyReceiveTicketDetail[index]?.note
                              : null
                          }
                          onChange={(e) => {
                            handleChangeValue(
                              "note",
                              e ? e.currentTarget.value : null,
                              Number(index)
                            );
                          }}
                        />
                      ) : (
                        <>{warrantyReceiveTicketDetail[index]?.note}</>
                      )}
                    </Table.Td>
                  )}
                </Table.Tr>
              ))}
          </Table.Tbody>
        </Table>
      </ScrollArea>
    </>
  );
};

export default ProductWarrantyList;
