import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Checkbox,
  Collapse,
  Flex,
  Group,
  Input,
  JsonInput,
  Title,
  Text,
  Tooltip,
  TypographyStylesProvider,
} from "@mantine/core";
import { modals } from "@mantine/modals";
import {
  IconActivity,
  IconEdit,
  IconFunction,
  IconHandStop,
  IconManualGearbox,
  IconPlus,
  IconSearch,
  IconShoppingBag,
  IconTrash,
  IconZoomReset,
} from "@tabler/icons-react";
import {
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_ExpandedState,
  MRT_PaginationState,
  MRT_RowSelectionState,
  MRT_SortingState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import { _localization } from "../../config/location";
import {
  _timerDelayClick,
  _timerDelaySearch,
  repositoryLog,
  repositoryMaster,
} from "../../_base/_const/_constVar";
import { Historyuseraction } from "../../model/HistoryuseractionModel";
import moment from "moment";
import { HistoryuseractionTopic } from "../../model/HistoryuseractionTopic";
import { IconRefresh } from "@tabler/icons-react";
import { formatDateTransfer } from "../../common/FormatDate/FormatDate";
import _breadcrumb from "../../_base/component/_layout/_breadcrumb";
import { MessageResponse } from "../../model/MessageResponse";
import { HistoryHandlerMessage } from "../../model/HistoryHandlerMessage";
import { useDisclosure } from "@mantine/hooks";
import { NotificationExtension } from "../../_base/extension/NotificationExtension";

const TopicErrorView = () => {
  //#region  table
  const headerRef = React.useRef<HTMLDivElement>(null);
  //data and fetching state

  const [data, setData] = useState<HistoryHandlerMessage[]>([]);

  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  //
  const [keySearch, setKeySearch] = useState<string>("");
  const [selectIds, setSelectIds] = useState<string[]>([]);

  //
  const [height, setHeight] = useState(0);

  useEffect(() => {
    fetchData();
  }, [sorting, pagination.pageIndex, pagination.pageSize]);

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    try {
      let urlGet =
        "/api/v1/logging/get-list-retry?skip=" +
        pagination.pageIndex * pagination.pageSize +
        "&take=" +
        pagination.pageSize;

      if (keySearch && keySearch.length > 0)
        urlGet = urlGet + "&keySearch=" + keySearch;
      let _softing = "";
      let isOrder = false;
      if (sorting && sorting.length > 0) {
        let _q = sorting[0];
        if (_q) {
          _softing = _q.id;
          isOrder = _q.desc;
        }
      }
      urlGet = urlGet + "&sort=" + _softing + "&isOrder=" + isOrder;

      const response = await repositoryLog.get<
        MessageResponse<HistoryHandlerMessage[]>
      >(urlGet);
      if (response && response.success) {
        setData(response.data ?? []);
        setRowCount(response.totalCount);
        setSelectIds([]);
        table.resetRowSelection();
      }
    } catch (error) {
      setIsError(true);
      console.error(error);
      return;
    }
    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
  };
  const [opened, { toggle }] = useDisclosure(false);
  const columns = React.useMemo<MRT_ColumnDef<HistoryHandlerMessage>[]>(
    () => [
      {
        accessorKey: "id",
        header: "Id",
      },
      {
        accessorKey: "username",
        header: "Người dùng",
      },
      {
        accessorKey: "createdate",
        header: "Thời gian xảy ra",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue,7)}</>
        ),
      },
      {
        accessorKey: "topic",
        header: "Tên topic",
      },
      {
        accessorKey: "app",
        header: "App",
      },
      {
        accessorKey: "eventname",
        header: "Event",
      },
      {
        accessorKey: "message",
        header: "Tin nhắn",
        size: 600,
        Cell: ({ renderedCellValue }: any) => (
          <JsonInput
            formatOnBlur
            autosize={true}
            minRows={5}
            value={renderedCellValue}
          />
        ),
      },
      {
        accessorKey: "exception",
        header: "Ngoại lệ",
        Cell: ({ renderedCellValue }: any) => (
          <Button
            onClick={() => {
              modals.openConfirmModal({
                title: "Chi tiết lỗi",
                children: (
                  <Box mx="auto">
                    <Text component="div">{renderedCellValue}</Text>
                  </Box>
                ),
                size: "80%",
                confirmProps: { display: "none" },
                cancelProps: { display: "none" },
                //    size: "500px",
                // labels: { confirm: "Confirm", cancel: "Cancel" },
                // onCancel: () => console.log("Cancel"),
                //onConfirm: () => console.log("Confirmed"),
              });
            }}
          >
            Xem lỗi
          </Button>
        ),
      },
      {
        accessorKey: "retry",
        header: "Tái thực hiện",
        Cell: (row) => {
          return (
            <Checkbox size="md" checked={row.row.original.retry ?? false} />
          );
        },
      },
      {
        accessorKey: "numberretry",
        header: "số lần tái thử",
      },

      // {
      //   accessorKey: "status",
      //   header: "Trạng thái",
      // },
      {
        accessorKey: "bypush",
        header: "Id Nhân viên thao tác",
      },

      {
        accessorKey: "numberretrypushtopic",
        header: "Chủ đề đẩy số lần thử lại",
      },
      {
        accessorKey: "action",
        header: "Thao tác",
        size: 50,
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
        Cell: ({ renderedCellValue, row, table }) => (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Tooltip label="Thử lại">
              <ActionIcon
                onClick={async () => {
                  const response = await repositoryLog.get<
                    MessageResponse<boolean>
                  >("/api/v1/logging/retry?id=" + row.original.id);
                  if (response && response.httpStatusCode === 200) {
                    if (response.data) {
                      await fetchData();
                      NotificationExtension.Success("Thành công !");
                    } else
                      NotificationExtension.Fails(
                        response.message ?? "Thất bại !"
                      );
                  }
                }}
                variant="light"
                aria-label="Settings"
                color="violet"
              >
                <IconRefresh size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
            <Tooltip label="Trạng thái">
              <ActionIcon variant="light" aria-label="Settings" color="green">
                <IconActivity size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
            <Tooltip label="Xóa">
              <ActionIcon variant="light" aria-label="Settings" color="red">
                <IconTrash size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
          </Box>
        ),
      },
    ],
    []
  );

  const table = useMantineReactTable({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    //add custom action buttons to top-left of top toolbar
    renderTopToolbarCustomActions: ({ table }) => (
      <div ref={headerRef}>
        <_breadcrumb></_breadcrumb>
        <Flex
          gap="md"
          justify="flex-start"
          align="center"
          direction="row"
          wrap="wrap"
        >
          <Input
            placeholder="Nhập từ khóa ..."
            onChange={(e) => {
              setKeySearch(e.target.value ?? "");
            }}
          />

          <Button
            leftSection={<IconSearch size={14} />}
            color="blue"
            variant="outline"
            //   disabled={isCounting}
            onClick={async () => {
              await fetchData();
            }}
          >
            Tìm kiếm
          </Button>
        </Flex>
      </div>
    ),
    renderToolbarInternalActions: ({ table }) => <></>,
    localization: _localization,
    getRowId: (row) => row?.id?.toString(),
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select", "username"],
        right: ["action"],
      },
      columnVisibility: { id: false },
      density: "xs",
      pagination: {
        pageIndex: 0,
        pageSize: 20,
      },
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    manualPagination: true,
    manualSorting: true,
    rowCount,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    state: {
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no data
      sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["20", "50", "70"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
  });

  //#endregion

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 160 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (200 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  return (
    <>
      <div className="mt-5">
        <MantineReactTable table={table} />
      </div>
    </>
  );
};
export default TopicErrorView;
