"use strict";

import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
  StrictMode,
  useEffect,
} from "react";
import { createRoot } from "react-dom/client";
import {
  AgGridReact,
  CustomCellRendererProps,
  CustomFilterProps,
  useGridFilter,
} from "@ag-grid-community/react";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";
import {
  ColDef,
  ColGroupDef,
  GetContextMenuItemsParams,
  GetRowIdParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  IRowNode,
  IServerSideDatasource,
  IServerSideGetRowsRequest,
  MenuItemDef,
  ModuleRegistry,
  RowClassParams,
  RowStyle,
} from "@ag-grid-community/core";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { ServerSideRowModelModule } from "@ag-grid-enterprise/server-side-row-model";
import { RichSelectModule } from "@ag-grid-enterprise/rich-select";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import { AG_GRID_LOCALE_VN } from "@ag-grid-community/locale";
import { AdvancedFilterModule } from "@ag-grid-enterprise/advanced-filter";
import { FiltersToolPanelModule } from "@ag-grid-enterprise/filter-tool-panel";
import { MultiFilterModule } from "@ag-grid-enterprise/multi-filter";
import { ClipboardModule } from "@ag-grid-enterprise/clipboard";
import {
  paginationPageSize,
  paginationPageSizeSelector,
  repositoryPos,
} from "../../_base/_const/_constVar";
import { MessageResponse } from "../../model/MessageResponse";
import { MasterDetailModule } from "@ag-grid-enterprise/master-detail";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { GridChartsModule } from "@ag-grid-enterprise/charts-enterprise";
import { ExcelExportModule } from "@ag-grid-enterprise/excel-export";
import { RangeSelectionModule } from "@ag-grid-enterprise/range-selection";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import { SparklinesModule } from "@ag-grid-enterprise/sparklines";
import { StatusBarModule } from "@ag-grid-enterprise/status-bar";
import { randomId, useHotkeys } from "@mantine/hooks";
import { formatDateTransfer } from "../../common/FormatDate/FormatDate";
import { ReportInventorySerialOnhandModel } from "../../model/Report/ReportInventorySerialOnhandModel";
import {
  PaymentReceiptDetail,
  PaymentReceiptSummary,
  ReportPaymentReceiptDetail,
} from "../../model/Report/ReportPaymentReceiptDetail";
import {
  Button,
  Flex,
  Grid,
  Input,
  NumberFormatter,
  Select,
} from "@mantine/core";
import {
  IconBrandBootstrap,
  IconDownload,
  IconMenuOrder,
  IconPlus,
  IconSearch,
  IconStatusChange,
  IconTimeDuration15,
  IconUserCode,
} from "@tabler/icons-react";
import _breadcrumb from "../../_base/component/_layout/_breadcrumb";
import { DatePickerInput } from "@mantine/dates";
import { setValue } from "@syncfusion/ej2-base";
import { SelectListItem } from "../../model/SelectListItem";
import { notifications } from "@mantine/notifications";

ModuleRegistry.registerModules([
  ColumnsToolPanelModule,
  MenuModule,
  ServerSideRowModelModule,
  RichSelectModule,
  SetFilterModule,
  AdvancedFilterModule,
  FiltersToolPanelModule,
  MultiFilterModule,
  MasterDetailModule,
  ClientSideRowModelModule,
  ExcelExportModule,
  GridChartsModule,
  RangeSelectionModule,
  RowGroupingModule,
  StatusBarModule,
  SparklinesModule,
  ClipboardModule,
]);
const getDefaultRange = (): [Date, Date] => {
  const now = new Date();
  const firstDay = new Date(now.getFullYear(), now.getMonth(), 1); // Ngày đầu tiên của tháng hiện tại
  return [firstDay, now]; // Ngày đầu tiên của tháng và ngày hiện tại
};
export const ReportPaymentReceiptView = () => {
  const reqModel: IServerSideGetRowsRequest = {
    startRow: undefined,
    endRow: undefined,
    rowGroupCols: [],
    valueCols: [],
    pivotCols: [],
    pivotMode: false,
    groupKeys: [],
    filterModel: null,
    sortModel: [],
  };

  const [loading, setLoading] = useState(true);
  const gridRef = useRef<AgGridReact<ReportPaymentReceiptDetail>>(null);
  const [height, setHeight] = useState(0);
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [value, setValue] = useState<[Date | null, Date | null]>(
    getDefaultRange()
  );
  const [valueBrandchId, setValueBrandchId] = useState<string>("");
  const [valueDepId, setValueDepId] = useState<string>("");
  const [keySearch, setKeySearch] = useState<string | undefined>(undefined);
  const [sumData, setSumData] = useState<PaymentReceiptSummary>({
    duDau: 0,
    duCuoi: 0,
    sumChi: 0,
    sumThu: 0,
  });
  const [dataBranch, setDataBranch] = useState<SelectListItem[]>([]);
  const [dataDep, setDataDep] = useState<SelectListItem[]>([]);
  const [typeRe, setTypeRe] = useState<string | null>(null);
  const [typeMul, setTypeMul] = useState<string | null>(null);
  const sizeGrid = { base: 6, sm: 4, md: 3, lg: 3, xl: 2 };
  useEffect(() => {
    fetchDataBranch();

    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      setHeight(window.innerHeight - (85 + headerHeight));
    };
    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize
    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  const containerStyle = useMemo(
    () => ({ width: "100%", height: height }),
    [height]
  );
  const gridStyle = useMemo(
    () => ({ height: "100%", width: "100%" }),
    [height]
  );

  const fetchData = async (req?: IServerSideGetRowsRequest) => {
    let request: IServerSideGetRowsRequest = {
      startRow: 0,
      endRow: 0,
      rowGroupCols: [],
      valueCols: [],
      pivotCols: [],
      pivotMode: false,
      groupKeys: [],
      filterModel: null,
      sortModel: [],
    };
    let _sort = "";
    if (req) {
      request = req;
      if (req.sortModel) {
        const get = req.sortModel[0];
        if (get && get.colId && get.sort) _sort = get?.colId + " " + get?.sort;
      }
    } else {
      request.startRow =
        (gridRef.current?.api?.paginationGetCurrentPage() ?? 0) *
        (gridRef.current?.api?.paginationGetPageSize() ?? 0);
      request.endRow =
        request.startRow + (gridRef.current?.api.paginationGetPageSize() ?? 0);
    }
    try {
      //date
      let startDateISO = "";
      let endDateISO = "";
      if (value && value.length > 0) {
        if (value[0]) {
          const startDate = value[0];
          startDateISO = startDate
            ? new Date(
                startDate.getTime() - startDate.getTimezoneOffset() * 60000
              ).toISOString()
            : "";
        }
        if (value[1]) {
          const endDate = value[1];
          endDateISO = endDate
            ? new Date(
                endDate.getTime() - endDate.getTimezoneOffset() * 60000
              ).toISOString()
            : "";
        }
      }
      const queryParams = new URLSearchParams({
        StartDate: startDateISO,
        EndDate: endDateISO,
        KeySearch: keySearch ?? "",
        Skip: request.startRow?.toString() ?? "0",
        Type: typeMul ?? "0",
        TypeReceipt: typeRe ?? "0",
        BrandIdCreate: valueBrandchId ?? "",
        DepIdCreate: valueDepId,
        Take: (request.endRow
          ? request.endRow - (request.startRow ?? 0)
          : 50
        ).toString(),
        Sort: _sort,
        //   IsExport: exportExcel.toString(),
      });

      var response = await repositoryPos.get<
        MessageResponse<ReportPaymentReceiptDetail>
      >(`/api/v1/report/payment-receipt?${queryParams.toString()}`);
      if (response && response.data?.paymentReceiptSummary)
        setSumData(response.data.paymentReceiptSummary);
      return response;
    } catch (error) {
      console.error(error);
    } finally {
    }
  };
  const getServerSideDatasource: (
    value: [Date | null, Date | null]
  ) => IServerSideDatasource = () => {
    return {
      getRows: async (params) => {
        var response = await fetchData(params.request);
        if (response && response.success) {
          params.success({
            rowData: response.data.paymentReceiptDetail,
            rowCount: response.totalCount,
          });
          setLoading(false);
        } else {
          params.fail();
          setLoading(false);
        }
      },
    };
  };

  const filterParamsDefault = {
    buttons: ["clear", "reset", "apply"],
    debounceMs: 1000,
    maxNumConditions: 5,
  };

  const [columnDefs, setColumnDefs] = useState<(ColDef | ColGroupDef)[]>([
    {
      headerName: "Thời gian",
      //  pinned: true,
      children: [
        {
          field: "createdDate",
          headerName: "Lập chứng từ",
          //  pinned: true,

          cellRenderer: (params: CustomCellRendererProps) => {
            if (
              typeof params.value === "string" ||
              params.value instanceof Date
            ) {
              return <> {params.value && formatDateTransfer(params.value)}</>;
            }
            return <></>;
          },
        },
        {
          field: "completionTime",
          headerName: "Ghi sổ",
          //  pinned: true,
          cellRenderer: (params: CustomCellRendererProps) => {
            if (
              typeof params.value === "string" ||
              params.value instanceof Date
            ) {
              return <> {params.value && formatDateTransfer(params.value)}</>;
            }
            return <></>;
          },
        },
      ],
    },
    {
      field: "receiptNumber",
      headerName: "Số chứng từ",
    },
    {
      field: "payerDescription",
      headerName: "Nội dung",
    },
    {
      field: "thu",
      headerName: "Thu",
      cellStyle: (params) => {
        return { textAlign: "right" };
      },
      cellRenderer: (params: CustomCellRendererProps) => {
        return (
          <NumberFormatter
            prefix=""
            value={params.value ?? ""}
            thousandSeparator
          />
        );
      },
    },
    {
      field: "chi",
      headerName: "Chi",
      cellStyle: (params) => {
        return { textAlign: "right" };
      },
      cellRenderer: (params: CustomCellRendererProps) => {
        return (
          <NumberFormatter
            prefix=""
            value={params.value ?? ""}
            thousandSeparator
          />
        );
      },
    },
    {
      field: "ton",
      headerName: "Tồn",
      cellStyle: (params) => {
        return { textAlign: "right" };
      },
      cellRenderer: (params: CustomCellRendererProps) => {
        return (
          <NumberFormatter
            prefix=""
            value={params.value ?? ""}
            thousandSeparator
          />
        );
      },
    },
    {
      field: "creatorBranch",
      headerName: "Mã chi nhánh",
    },
    {
      field: "createBy",
      headerName: "Tài khoản",
    },
    {
      field: "codeCustomer",
      headerName: "Mã KH",
    },
    {
      field: "",
      headerName: "Nhóm công nợ",
    },
  ]);
  const defaultColDef = useMemo<ColDef>(() => {
    return {
      autoHeaderHeight: true,
      wrapHeaderText: true,
      allowedAggFuncs: ["sum", "min", "max", "avg"],
      filter: false,
      enableRowGroup: false,
      enableValue: true,
      floatingFilter: false,
      floatingFilterComponentParams: {
        showDisabledCheckboxes: true,
      },
      filterParams: filterParamsDefault,
      autoHeight: true,
    };
  }, []);

  const onGridReady = useCallback(async (params: GridReadyEvent) => {
    var datasource = getServerSideDatasource(value);
    params.api!.setGridOption("serverSideDatasource", datasource);
  }, []);
  const statusBar = useMemo(
    () => ({
      statusPanels: [
        { statusPanel: "agTotalAndFilteredRowCountComponent" },
        { statusPanel: "agTotalRowCountComponent" },
        { statusPanel: "agFilteredRowCountComponent" },
        { statusPanel: "agAggregationComponent" },
      ],
    }),
    []
  );
  const getContextMenuItems = useCallback(
    (params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
      var result: (string | MenuItemDef)[] = [
        "cut",
        "copy",
        "copyWithHeaders",
        "copyWithGroupHeaders",
        "paste",
        "separator",
        "chartRange",
        "export",
      ];
      return result;
    },
    [window]
  );
  const pinnedTopRowData = useMemo<any[]>(() => {
    return [
      {
        payerDescription: "Dư đầu",
        ton: sumData.duDau === 0 ? "" : sumData.duDau,
      },
    ];
  }, [sumData.duCuoi, sumData.duDau, sumData.sumChi, sumData.sumThu]);

  const pinnedBottomRowData = useMemo<any[]>(() => {
    return [
      {
        payerDescription: "Tổng phát sinh",
        thu: sumData.sumThu,
        chi: sumData.sumChi,
      },
      {
        payerDescription: "Dư cuối",
        ton: sumData.duCuoi + sumData.duDau,
      },
    ];
  }, [sumData.duCuoi, sumData.duDau, sumData.sumChi, sumData.sumThu]);

  const getRowStyle = useCallback(
    (params: RowClassParams): RowStyle | undefined => {
      if (params.node.rowPinned) {
        return { fontWeight: "bold" };
      }
    },
    []
  );
  // useHotkeys([
  //   ["enter", () => console.log("Rick roll")],
  // ]);

  const loadData = async (exportExcel: boolean = false) => {
    const data = await fetchData();
    gridRef.current?.api.setGridOption("serverSideDatasource", {
      getRows: (params) => {
        if (data) {
          params.success({
            rowData: data.data.paymentReceiptDetail,
            rowCount: data.totalCount,
          });
        } else {
          params.fail();
          return false;
        }
      },
    });
    return true;
  };

  const fetchDataBranch = async () => {
    const dataApi = await repositoryPos.get<MessageResponse<any>>(
      "/api/v1/TblDmInventory/get-select-branch"
    );

    if (dataApi && dataApi.success) {
      const filteredData = dataApi.data.slice(1);
      setDataBranch(filteredData);
      // if(dataBranch)
      // {
      //   const jsonData = localStorage.getItem("userLogin");
      //   if (jsonData) {
      //     const dataObject = JSON.parse(jsonData);
      //     setValueBrandchId(dataObject.branchId);
      //   }
      // }

    }
  };

  const getDataDepartment = async () => {
    const res = await repositoryPos.get<MessageResponse<any>>(
      "/api/v1/TblDepartment/get-all"
    );
    if (res && res.success) {
      const _d = res.data.map((x: { name: string; departmentId: string }) => ({
        text: x.name,
        value: x.departmentId,
      }));

      setDataDep(_d); // Cập nhật trạng thái với dữ liệu đã xử lý
    }
  };
  return (
    <>
      <div
        style={{ border: "1px solid #dee2e6", padding: "5px" }}
        ref={headerRef}
      >
        <Flex
          gap="md"
          justify={"space-between"}
          align="center"
          direction="row"
          wrap="wrap"
          w={"100%"}
        >
          <_breadcrumb></_breadcrumb>
          <div>
            {/* <Button
              leftSection={<IconPlus size={14} />}
              variant="outline"
              onClick={() => {
                //  navigate("/sell/retail-order");
              }}
              mr={"xs"}
            >
              Thêm mới (F11)
            </Button> */}

            <Button
              leftSection={<IconDownload size={14} />}
              color="violet"
              variant="outline"
              onClick={async () => {
                {
                  //   const get = await loadData(true);
                  //   if (get) gridRef.current!.api.exportDataAsExcel();
                }
              }}
            >
              Export Excel
            </Button>
          </div>
        </Flex>

        <Grid mt={"10px"}>
          <Grid.Col span={sizeGrid}>
            <Input
              placeholder="Từ khóa"
              type="text"
              leftSection={<IconSearch color="#15aabf" />}
              min={0}
              onChange={(e) => {
                setKeySearch(e.target.value);
              }}
            />
          </Grid.Col>
          <Grid.Col span={sizeGrid}>
            <DatePickerInput
              id="select2"
              key={"select2"}
              type="range"
              size="sm"
              leftSection={<IconTimeDuration15 color="#15aabf" />}
              placeholder="Chọn ngày"
              locale="vi"
              value={value}
              valueFormat="DD/MM/YYYY"
              onChange={setValue}
              clearable
            />
          </Grid.Col>
          {/* <Grid.Col span={sizeGrid}>
            <Select
              id="status"
              key={"status"}
              placeholder="Kiểu phiếu"
              leftSection={<IconMenuOrder color="#15aabf" />}
              comboboxProps={{
                transitionProps: { transition: "pop", duration: 200 },
              }}
              data={[
                {
                  label: "Phiếu thu",
                  value: "1",
                },
                {
                  label: "Phiếu chi",
                  value: "2",
                },
                {
                  label: "Phiếu điều chỉnh",
                  value: "3",
                },
              ]}
              onChange={setTypeRe}
              searchable
              clearable
            />
          </Grid.Col> */}
          <Grid.Col span={sizeGrid}>
            <Select
              id="status"
              key={"status"}
              placeholder="Loại phiếu"
              leftSection={<IconStatusChange color="#15aabf" />}
              comboboxProps={{
                transitionProps: { transition: "pop", duration: 200 },
              }}
              data={[
                {
                  label: "Đơn hàng",
                  value: "1",
                },
                {
                  label: "Nội bộ",
                  value: "2",
                },
                {
                  label: "Báo có",
                  value: "4",
                },
                {
                  label: "Báo nợ",
                  value: "3",
                },
              ]}
              onChange={setTypeMul}
              searchable
              clearable
            />
          </Grid.Col>
          <Grid.Col span={sizeGrid}>
            {" "}
            <Select
              flex={1}
              searchable
              clearable
              placeholder="Chi nhánh người lập phiếu"
              comboboxProps={{
                transitionProps: { transition: "pop", duration: 200 },
              }}
              defaultValue={valueBrandchId}
              value={valueBrandchId}
              leftSection={<IconBrandBootstrap color="#15aabf" />}
              data={dataBranch.map((item) => {
                return {
                  label: item.text ?? "",
                  value: String(item.value ?? ""),
                };
              })}
              onClick={async () => {
                if (!dataBranch || dataBranch.length < 1) {
                  await fetchDataBranch();
                }
                return;
              }}
              onChange={(value) => {
                setValueBrandchId(value ?? "");
              }}
              nothingFoundMessage="Dữ liệu không có !"
            />
          </Grid.Col>
          <Grid.Col span={sizeGrid}>
            {" "}
            <Select
              flex={1}
              searchable
              clearable
              placeholder="Phòng ban người lập phiếu"
              comboboxProps={{
                transitionProps: { transition: "pop", duration: 200 },
              }}
              leftSection={<IconBrandBootstrap color="#15aabf" />}
              data={dataDep.map((item) => {
                return {
                  label: item.text ?? "",
                  value: String(item.value ?? ""),
                };
              })}
              onClick={async () => {
                if (!dataDep || dataDep.length < 1) {
                  await getDataDepartment();
                }
                return;
              }}
              onChange={(value) => {
                setValueDepId(value ?? "");
              }}
              nothingFoundMessage="Dữ liệu không có !"
            />
          </Grid.Col>

          <Grid.Col span={sizeGrid}>
            <Select
              placeholder="Khách hàng"
              id="select1"
              key={"select1"}
              searchable
              clearable
              //  value={selectedCustomer}
              leftSection={<IconUserCode color="#15aabf" />}
              comboboxProps={{
                transitionProps: { transition: "pop", duration: 200 },
              }}
              //   data={dataListCustomer.map((item) => {
              //     return {
              //       label: item.text ?? "",
              //       value: String(item.value ?? ""),
              //     };
              //   })}
              //   onChange={(value) => {
              //     handleChangeSearchValue(value ?? "", "customerId");
              //   }}
              //   searchValue={searchTerm}
              //   onSearchChange={handleSearchChange}
            />
          </Grid.Col>

          <Grid.Col span={sizeGrid}>
            <Button
              leftSection={<IconSearch size={14} />}
              color="blue"
              variant="outline"
              onClick={() => {
                loadData();
              }}
            >
              Tìm kiếm
            </Button>
          </Grid.Col>
        </Grid>
      </div>

      <div style={containerStyle}>
        <div style={gridStyle} className={"ag-theme-quartz"}>
          <AgGridReact<ReportPaymentReceiptDetail>
            ref={gridRef}
            loading={loading}
            suppressNoRowsOverlay
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowModelType={"serverSide"}
            pagination={true}
            paginationPageSize={paginationPageSize}
            paginationPageSizeSelector={paginationPageSizeSelector}
            cacheBlockSize={paginationPageSize}
            maxBlocksInCache={10}
            onGridReady={onGridReady}
            localeText={AG_GRID_LOCALE_VN}
            sideBar={false}
            enableAdvancedFilter={false}
            suppressAggFuncInHeader={true}
            groupDefaultExpanded={-1}
            suppressRowClickSelection={true}
            blockLoadDebounceMillis={200}
            suppressServerSideFullWidthLoadingRow={false}
            maxConcurrentDatasourceRequests={1}
            animateRows={true}
            rowBuffer={paginationPageSize}
            purgeClosedRowNodes={true}
            statusBar={statusBar}
            enableRangeSelection
            enableCharts
            allowContextMenuWithControlKey={true}
            getContextMenuItems={getContextMenuItems}
            suppressColumnVirtualisation
            paginateChildRows={true}
            //  paginationAutoPageSize={true}
            maintainColumnOrder={true}
            pinnedTopRowData={pinnedTopRowData}
            pinnedBottomRowData={pinnedBottomRowData}
            getRowStyle={getRowStyle}
          />
        </div>
      </div>
    </>
  );
};
