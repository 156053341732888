import {
  Button,
  Checkbox,
  Fieldset,
  Flex,
  ScrollArea,
  Select,
  Table,
} from "@mantine/core";
import { useState, useEffect } from "react";
import classes from "../Styles/ScrollTable.module.css";
import cx from "clsx";
import { modals } from "@mantine/modals";
import { IconCheck, IconWindow } from "@tabler/icons-react";
import { TblDmCustomerCategory } from "../../../../../model/TblDmCustomerCategory";
import { repositoryPos } from "../../../../../_base/_const/_constVar";
import { MessageResponse } from "../../../../../model/MessageResponse";
import { TblDmCustomer } from "../../../../../model/TblDmCustomer";
import { listCustGroup } from "../../../../../model/TblPromotion";
import { sky_blue } from "../../../../../const/variables";

const ModalCustomers = ({
  data,
  dataListCustGroup,
  handleAddCustomer,
}: {
  data: TblDmCustomerCategory[];
  dataListCustGroup: listCustGroup[];
  handleAddCustomer: (selectedCus: TblDmCustomer[]) => void;
}) => {
  const [selectedCus, setSelectedCus] = useState<TblDmCustomer[]>([]);
  const [scrolled, setScrolled] = useState(false);
  const [selectedCustomerCat, setSelectedCustomerCat] = useState("");
  const [dataOption, setDataOption] = useState<any[]>([]);
  const [dataCustomer, setDataCustomer] = useState<TblDmCustomer[]>([]);

  // const selectedDetailGroupCustomers =
  //   selectedCustomers === "Tất cả"
  //     ? []
  //     : data.find((item: any) => item.nameGroupCustomers === selectedCustomers)
  //         ?.detailGroupCustomers || [];

  // const handleCheckboxChange = (item: any, checked: boolean) => {
  //   const customers = data.find((customers: any) =>
  //     customers.detailGroupCustomers.some(
  //       (detail: any) => detail.code === item.code
  //     )
  //   );

  //   if (!customers) return;

  //   if (checked) {
  //     setSelectedRow((prev) => [...prev, item.code]);
  //     setSelectedData((prev) => {
  //       const existing = prev.find((data) => data.code === customers.code);
  //       if (existing) {
  //         return prev.map((data) =>
  //           data.code === customers.code
  //             ? {
  //                 ...data,
  //                 detailGroupCustomers: [...data.detailGroupCustomers, item],
  //               }
  //             : data
  //         );
  //       }
  //       return [
  //         ...prev,
  //         {
  //           id: customers.id,
  //           code: customers.code,
  //           nameGroupCustomers: customers.nameGroupCustomers,
  //           detailGroupCustomers: [item],
  //         },
  //       ];
  //     });
  //   } else {
  //     setSelectedRow((prev) => prev.filter((code) => code !== item.code));
  //     setSelectedData((prev) => {
  //       const existing = prev.find((data) => data.code === customers.code);
  //       if (existing) {
  //         const updatedDetails = existing.detailGroupCustomers.filter(
  //           (detail: any) => detail.code !== item.code
  //         );
  //         if (updatedDetails.length > 0) {
  //           return prev.map((data) =>
  //             data.code === customers.code
  //               ? {
  //                   ...data,
  //                   detailGroupCustomers: updatedDetails,
  //                 }
  //               : data
  //           );
  //         } else {
  //           return prev.filter((data) => data.code !== customers.code);
  //         }
  //       }
  //       return prev;
  //     });
  //   }
  // };

  const handleCheckboxChange = (cus: TblDmCustomer, checked: boolean) => {
    const updatedSelectedRows = checked
      ? [...selectedCus, cus]
      : selectedCus.filter((item) => item.id !== cus.id);

    setSelectedCus(updatedSelectedRows);
  };

  // useEffect(() => {
  //   setSelectedData(existingDataTable);
  //   const selectedCodes = existingDataTable
  //     .flatMap((customers) => customers.detailGroupCustomers)
  //     .map((detail) => detail.code);
  //   setSelectedRow(selectedCodes);
  // }, [existingDataTable]);

  useEffect(() => {
    setDataOption(
      data.map((cat) => ({
        value: cat.id.toString() || "",
        label: cat.name || "",
      }))
    );

    setSelectedCus(
      dataListCustGroup.map((cust) => ({
        id: cust.cusId,
        code: cust.cusCode,
        name: cust.cusName,
      }))
    );
  }, []);

  useEffect(() => {
    const fetchDataCustomer = async () => {
      if (selectedCustomerCat) {
        const url = `/api/v1/TblDmCustomer/get-customer-by-group?groupId=${selectedCustomerCat}`;

        const dataApi = await repositoryPos.get<
          MessageResponse<TblDmCustomer[]>
        >(url);

        if (dataApi && dataApi.success) {
          setDataCustomer(dataApi.data);
        } else setDataCustomer([]);
      } else setDataCustomer([]);
    };

    fetchDataCustomer();
  }, [selectedCustomerCat]);

  return (
    <>
      <Fieldset legend="Danh sách nhóm khách hàng" m={"10px 0px"}>
        <Select
          label="Nhóm khách hàng"
          checkIconPosition="left"
          data={dataOption}
          defaultValue={"Tất cả"}
          onChange={(value) => setSelectedCustomerCat(value || "")}
        />
        <ScrollArea
          h={175}
          m={"10px 0px"}
          onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
        >
          <Table
            striped
            highlightOnHover
            withColumnBorders
            withTableBorder
            style={{ overflowY: "auto", height: "100px" }}
          >
            <Table.Thead
              className={cx(classes.header, { [classes.scrolled]: scrolled })}
            >
              <Table.Tr>
                <Table.Th w={70}>Áp dụng</Table.Th>
                <Table.Th w={80}>Mã</Table.Th>
                <Table.Th>Nhóm khách hàng</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {dataCustomer.map((item) => (
                <Table.Tr
                  key={item.id}
                  bg={
                    selectedCus.some((cus) => cus.id === item.id)
                      ? "var(--mantine-color-blue-light)"
                      : undefined
                  }
                >
                  <Table.Td>
                    <Checkbox
                      checked={selectedCus.some((cus) => cus.id === item.id)}
                      onChange={(event) =>
                        handleCheckboxChange(item, event.currentTarget.checked)
                      }
                    />
                  </Table.Td>
                  <Table.Td>{item.code}</Table.Td>
                  <Table.Td>{item.name}</Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table>
        </ScrollArea>
      </Fieldset>
      <Flex align={"center"} justify={"center"} gap={10}>
        <Button
          color="gray"
          onClick={() => {
            modals.closeAll();
          }}
          leftSection={<IconWindow size={18} />}
        >
          Đóng
        </Button>
        <Button
          color={sky_blue.base}
          leftSection={<IconCheck size={18} />}
          disabled={selectedCus.length < 1}
          onClick={() => {
            handleAddCustomer(selectedCus);
          }}
        >
          Xác nhận
        </Button>
      </Flex>
    </>
  );
};

export default ModalCustomers;
