import {
  ActionIcon,
  Badge,
  Box,
  Button,
  ComboboxItem,
  Flex,
  Grid,
  Group,
  Select,
  SelectProps,
  Text,
  TextInput,
  Title,
  Tooltip,
} from "@mantine/core";
import {
  IconSearch,
  IconCalendar,
  IconStatusChange,
  IconAdjustmentsCog,
  IconCheck,
  IconListCheck,
  IconEdit,
  IconClipboardX,
} from "@tabler/icons-react";
import {
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import * as React from "react";
import { useEffect, useState } from "react";
import {
  _timerDelayClick,
  repositoryMdm,
  repositoryPos,
} from "../../../_base/_const/_constVar";
import { _localization } from "../../../config/location";
import { MessageResponse } from "../../../model/MessageResponse";
import "../../../Styles/tab.component.css";
import { TblWarrantyServiceRequestFormDetail } from "../../../model/TblWarrantyServiceRequestForm";
import { paginationBase } from "../../../_base/model/_base/BaseTable";
import { getValueById } from "../../../_base/helper/FunctionHelper";
import { DatePickerInput } from "@mantine/dates";
import { modals } from "@mantine/modals";
import {
  formatDateNotTimeZone,
  formatDateTransfer,
} from "../../../common/FormatDate/FormatDate";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import RemoteProcessing from "./RemoteProcessing";
import { useNavigate } from "react-router-dom";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import AssignmentAtHome from "./AssignmentAtHome";
import { AvatarUtils } from "../../../common/ColorByName/AvatarUtils";
import DetailProductWarranty from "./DetailProductWarranty";
import WarrantyAssignment from "./WarrantyAssignment";
import UpdateWarrantyAssignment from "./UpdateWarrantyAssignment";
import { getBranchSelectMdm } from "../../../service/getSelectApi";
import { green } from "../../../const/variables";

const ProcessingAssignment = () => {
  //data and fetching state
  const navigate = useNavigate();
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<any[]>([]);
  const [, setDataTemp] = useState<any[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);
  const [pagination, setPagination] = useState(paginationBase);
  const [customerId, setCustomerId] = useState<string | null>(null);
  const [listCustId, setListCustId] = useState<any[]>([]);
  const [listCustomer, setListCustomer] = useState<any[]>([]);
  const [dataCustomerSelect, setDataCustomerSelect] = useState<ComboboxItem[]>(
    []
  );
  const [dataTblBranch, setDataTblBranch] = useState<ComboboxItem[]>([]);
  // const [listItemId, setListItemId] = useState<any[]>([]);
  const [listItem, setListItem] = useState<any[]>([]);
  const [dataEmployee, setDataEmployee] = useState<any[]>([]);
  // const [dataEmployeeSelect, setDataEmployeeSelect] = useState<ComboboxItem[]>(
  //   []
  // );
  const [dataProcessingMethod, setDataProcessingMethod] = useState<any[]>([]);
  // const [dataRequestStatus, setDataRequestStatus] = useState<any[]>([]);
  const [dataRequestFormSelect, setDataRequestFormSelect] = useState<any[]>([]);
  //table state
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [selectIds, setSelectIds] = useState<string[]>([]);
  const [selectProcessingMethod, setSelectProcessingMethod] = useState<
    string | null
  >(null);
  const [selectItemStatus, setSelectItemStatus] = useState<string | null>(null);
  const [selectEmpNameInCharge, setSelectEmpNameInCharge] = useState<
    string | null
  >(null);
  const [deleteViewStatus, setDeleteViewStatus] = useState(false);
  //count
  const [timeLeft, setTimeLeft] = useState(60);
  const [isCounting, setIsCounting] = useState(false);
  //search
  const [keySearch, setKeySearch] = useState<any>({
    startDate: "",
    endDate: "",
    status: "",
    processingMethod: "",
    employeeIncharge: "",
    custId: "",
    searchAll: "",
  });

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "requestNum",
        header: "Số phiếu yêu cầu",
        Cell: ({ renderedCellValue, cell }) => (
          <Badge
            radius="sm"
            variant="dot"
            size="lg"
            color={renderedCellValue === null ? "red" : green.base}
          >
            {renderedCellValue === null ? null : renderedCellValue}
          </Badge>
        ),
      },
      {
        accessorKey: "errorNumber",
        header: "Số phiếu nhận hàng lỗi",
        Cell: ({ renderedCellValue, cell }) => (
          <Badge
            radius="sm"
            variant="dot"
            size="lg"
            color={renderedCellValue === null ? "red" : green.base}
          >
            {renderedCellValue === null ? "Chưa lập phiếu" : renderedCellValue}
          </Badge>
        ),
        size: 225,
      },
      {
        accessorKey: "requestStatusName",
        header: "Trạng thái",
        Cell: ({ row }) => (
          <Badge
            radius={"sm"}
            color={getColorStatus(row.original.requestStatusName?.toString())}
            variant="light"
            bd={`1px solid ${getColorStatus(
              row.original.requestStatusName?.toString()
            )}`}
          >
            {row.original.requestStatusName}
          </Badge>
        ),
        filterFn: "equals",
        mantineFilterSelectProps: {
          data: [
            { label: "Chờ phân công", value: "Chờ phân công" },
            { label: "Hủy phân công", value: "Hủy phân công" },
            { label: "Đã phân công", value: "Đã phân công" },
            { label: "Kỹ thuật hủy", value: "Kỹ thuật hủy" },
            { label: "Đã xử lý", value: "Đã xử lý" },
            { label: "Xử lý lại", value: "Xử lý lại" },
          ],
        },
        filterVariant: "select",
      },
      {
        accessorKey: "priority",
        header: "Độ ưu tiên",
        Cell: ({ row }) => (
          <Badge color={getColorPriority(row.original.priority)} radius={"sm"}>
            {row.original.priority}
          </Badge>
        ),
      },
      // {
      //   accessorKey: "documentNumber",
      //   header: "Số chứng từ mua",
      // },
      {
        accessorKey: "custMeeting",
        header: "Khách hàng hẹn gặp",
        Cell: ({ row }) => <AvatarUtils value={row.original.custMeeting} />,
      },
      {
        accessorKey: "custPhone",
        header: "SĐT",
      },
      {
        accessorKey: "meetingAddress",
        header: "Địa chỉ hẹn gặp",
        Cell: ({ renderedCellValue, row }: any) => (
          <Text fw={"500"} size="12.5px">
            {row.original.additionalAddress !== null
              ? `${row.original.additionalAddress} - ${renderedCellValue}`
                  .replace(/\s*-\s*-\s*/g, " - ")
                  .replace(/^\s*-\s*/, "")
              : `${renderedCellValue}`.replace(/^\s*-\s*/, "")}{" "}
          </Text>
        ),
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      // {
      //   header: "Ngành hàng",
      //   enableColumnActions: false,
      //   Cell: ({ row }) => (
      //     <Text size="12.5px" fw={500}>
      //       {getValueById(
      //         row.original.employeeIncharge ?? 0,
      //         dataEmployee,
      //         "departmentName"
      //       )}
      //     </Text>
      //   ),
      // },
      {
        accessorKey: "processingMethodName",
        header: "Hình thức xử lý",
        enableColumnActions: false,
        enableSorting: false,
        Cell: ({ row }) => (
          <Badge
            color={getColorProcessingMethod(row.original.processingMethodName)}
            radius={"sm"}
          >
            {row.original.processingMethodName}
          </Badge>
        ),
        filterFn: "equals",
        mantineFilterSelectProps: {
          data: [
            { label: "Xử lý tại nhà", value: "Xử lý tại nhà" },
            { label: "Xử lý tại CH/CN", value: "Xử lý tại CH/CN" },
            { label: "Hãng xử lý", value: "Hãng xử lý" },
            { label: "Xử lý trực tiếp", value: "Xử lý trực tiếp" },
            { label: "Xử lý từ xa", value: "Xử lý từ xa" },
          ],
        },
        filterVariant: "select",
        size: 175,
      },
      {
        accessorKey: "empnameincharge",
        header: "Người nhận phân công",
        Cell: ({ row }) => <AvatarUtils value={row.original.empnameincharge} />,
        size: 225,
      },
      // {
      //   accessorKey: "Đánh giá mức độ",
      //   header: "Đánh giá mức độ",
      // },
      // {
      //   accessorKey: "Điểm",
      //   header: "Điểm",
      // },
      {
        accessorKey: "branchId",
        header: "Nơi nhận yêu cầu",
        Cell: ({ row }: any) => (
          <Text size="12.5px" fw={"500"}>
            {getValueById(
              row.original.branchId?.toString() ?? "",
              dataTblBranch,
              "label"
            )}
          </Text>
        ),
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "meetingDate",
        header: "Ngày hẹn",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
        enableColumnFilter: false,
      },
      {
        accessorKey: "createDate",
        header: "Ngày tiếp nhận",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
        enableColumnFilter: false,
      },
      {
        accessorKey: "content",
        header: "Nội dung",
      },
      {
        accessorKey: "action",
        header: "Thao tác",
        size: 50,
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
        Cell: ({ row }) => (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Tooltip label="Chỉnh sửa phân công">
              <ActionIcon
                variant="light"
                color="orange"
                onClick={() =>
                  modalUpdateWarrantyAssign(row.original.id, row.original)
                }
                disabled={
                  row.original.processingMethodName === "Hãng xử lý" ||
                  row.original.requestStatusName !== "Đã phân công"
                }
              >
                <IconEdit size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
            <Tooltip label="Cập nhật bảo hành">
              <ActionIcon
                variant="light"
                color="teal"
                onClick={() =>
                  modalRemoteProcessing(
                    row.original.id,
                    row.original.requestStatusName
                  )
                }
                disabled={
                  row.original.requestStatusName !== "Đã phân công" &&
                  row.original.requestStatusName !== "Hãng đang xử lý" &&
                  row.original.requestStatusName !== "Đang xử lý"
                }
              >
                <IconListCheck size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
            <Tooltip label="Hủy phân công">
              <ActionIcon
                variant="light"
                color="red"
                onClick={() => handleCancelAssign([row.original.id])}
                disabled={
                  row.original.requestStatusName === "Hủy phân công" ||
                  row.original.requestStatusName === "Chờ phân công" ||
                  row.original.processingMethodName === "Hãng xử lý" ||
                  row.original.requestStatusName === "Đang xử lý" ||
                  row.original.requestStatusName === "Đã xử lý"
                }
              >
                <IconClipboardX size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
          </Box>
        ),
      },
    ],
    [listItem, listCustomer, selectProcessingMethod, dataTblBranch]
  );

  const startCountdown = () => {
    if (!isCounting) {
      setTimeLeft(_timerDelayClick);
      setIsCounting(true);
    }
  };

  function getColorProcessingMethod(value: string) {
    switch (value) {
      case "Xử lý tại nhà":
        return "lime";
      case "Hãng xử lý":
        return "green";
      case "Xử lý trực tiếp":
        return "teal";
      case "Xử lý tại CH/CN":
        return "cyan";
      case "Xử lý từ xa":
        return "orange";
      case "":
      case null:
        return "rgba(255, 255, 255, 0)";
    }
  }

  function getColorStatus(status: string) {
    switch (status) {
      case "Đã phân công":
      case "Đã xử lý":
      case "Hãng đã xử lý":
        return "#4CAF50";

      case "Chờ phân công":
      case "Xử lý lại":
      case "Hãng đang xử lý":
      case "Chưa xử lý":
        return "#d9d02f";

      case "Hủy phân công":
      case "Kỹ thuật hủy":
        return "#F44336";

      case "Hãng xử lý":
        return "#2196F3";

      case "Đang xử lý":
      case "Hãng đang xử lý":
        return "#FF9800";

      case "":
      case null:
        return "rgba(255, 255, 255, 0)";

      default:
        return "#424242";
    }
  }

  function getColorPriority(value: string | null) {
    switch (value) {
      case "Cao":
        return "#1E5287";
      case "Thấp":
        return "#D0F66A";
      case null:
      case "":
        return "rgba(255, 255, 255, 0)";
    }
  }

  const handleAssignmentProcessing = () => {
    if (selectProcessingMethod === "Xử lý tại nhà") {
      modalAssignAtHome();
    } else if (
      selectProcessingMethod === "Xử lý từ xa" ||
      selectProcessingMethod === "Xử lý tại CH/CN" ||
      selectProcessingMethod === "Xử lý trực tiếp"
    ) {
      modalWarrantyAssignment();
    }
  };

  const optionColorProcessingMethod: Record<
    | "Xử lý tại nhà"
    | "Xử lý tại CH/CN"
    | "Hãng xử lý"
    | "Xử lý trực tiếp"
    | "Xử lý từ xa",
    string
  > = {
    "Xử lý tại nhà": "lime",
    "Xử lý tại CH/CN": "cyan",
    "Hãng xử lý": "green",
    "Xử lý trực tiếp": "teal",
    "Xử lý từ xa": "orange",
  };

  const renderSelectOptionProcessingMethod: SelectProps["renderOption"] = ({
    option,
    checked,
  }) => (
    <Group flex="1" gap="xs">
      <Badge
        color={
          optionColorProcessingMethod[
            option.label as
              | "Xử lý tại nhà"
              | "Xử lý tại CH/CN"
              | "Hãng xử lý"
              | "Xử lý trực tiếp"
              | "Xử lý từ xa"
          ]
        }
        radius="sm"
        variant="filled"
      >
        {option.label}
      </Badge>
      {checked && (
        <IconCheck size={"14px"} style={{ marginInlineStart: "auto" }} />
      )}
    </Group>
  );

  const optionColorsStatus: Record<
    | "Chờ phân công"
    | "Hủy phân công"
    | "Đã phân công"
    | "Hãng đang xử lý"
    | "Kỹ thuật hủy"
    | "Đã xử lý"
    | "Xử lý lại"
    | "Hãng xử lý"
    | "Đang xử lý",
    string
  > = {
    "Chờ phân công": "#d9d02f",
    "Hủy phân công": "#F44336",
    "Đã phân công": "#4CAF50",
    "Hãng đang xử lý": "#FF9800",
    "Kỹ thuật hủy": "#F44336",
    "Đã xử lý": "#4CAF50",
    "Xử lý lại": "#d9d02f",
    "Hãng xử lý": "#2196F3",
    "Đang xử lý": "#FF9800",
  };

  const renderSelectOptionStatus: SelectProps["renderOption"] = ({
    option,
    checked,
  }) => (
    <Group flex="1" gap="xs">
      <Badge
        color={
          optionColorsStatus[
            option.label as
              | "Chờ phân công"
              | "Hủy phân công"
              | "Đã phân công"
              | "Hãng đang xử lý"
              | "Kỹ thuật hủy"
              | "Đã xử lý"
              | "Xử lý lại"
          ]
        }
        radius="sm"
        variant="light"
        bd={`1px solid ${
          optionColorsStatus[
            option.label as
              | "Chờ phân công"
              | "Hủy phân công"
              | "Đã phân công"
              | "Hãng đang xử lý"
              | "Kỹ thuật hủy"
              | "Đã xử lý"
              | "Xử lý lại"
          ]
        }`}
      >
        {option.label}
      </Badge>
      {checked && (
        <IconCheck size={"14px"} style={{ marginInlineStart: "auto" }} />
      )}
    </Group>
  );

  const handleChangeSearchValue = (value: string, key: string) => {
    if (value !== "Invalid date")
      setKeySearch((prevData: any) => ({ ...prevData, [key]: value }));
    else {
      setKeySearch((prevData: any) => ({ ...prevData, [key]: "" }));
    }
  };

  const contactCompany = () => {
    navigate("/warranty/warranty-company", {
      state: { selectIds },
    });
  };

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);

    let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }`;

    if (keySearch.startDate) {
      url += `&StartDate=${keySearch.startDate}`;
    }

    if (keySearch.endDate) {
      url += `&EndDate=${keySearch.endDate}`;
    }

    if (keySearch.processingMethod) {
      url += `&ProcessingMethod=${keySearch.processingMethod}`;
    }

    if (keySearch.status) {
      url += `&RequestStatus=${keySearch.status}`;
    }

    if (keySearch.employeeIncharge) {
      url += `&EmployeeIncharge=${keySearch.employeeIncharge}`;
    }

    if (keySearch.custId) {
      url += `&CustId=${keySearch.custId}`;
    }

    if (keySearch.searchAll) {
      url += `&KeySearch=${keySearch.searchAll?.trim()}`;
    }

    try {
      const response = await repositoryMdm.get<
        MessageResponse<TblWarrantyServiceRequestFormDetail[]>
      >(`api/v1/TblWarrantyServiceRequestForm/get-list${url}`);
      if (response && response.success) {
        let result = response.data;
        setData(result);
        setDataTemp(response.data);
        setRowCount(response.totalCount ?? 0);
        setSelectIds([]);
        table.resetRowSelection();
        await Promise.all([dataBranchSelect()]);
      }
    } catch (error) {
      setData([]);
      setIsError(true);
      console.error(error);
      return;
    }
    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
    startCountdown();
  };

  const handleCancelAssign = async (ids: string[] | number[]) => {
    const res = await repositoryMdm.post<MessageResponse<any>>(
      "/api/v1/TblWarrantyServiceRequestForm/cancel-assignment",
      ids
    );

    if (res && res?.success) {
      NotificationExtension.Success("Hoàn tất hủy phân công !");
      setTimeout(() => {
        fetchData();
      }, 500);
    }
  };

  const getListCustomer = async () => {
    if (listCustId.length > 0) {
      let url = "";
      for (var i = 0; i < listCustId.length; i++) {
        url += `ids=${listCustId[i]}&`;
      }
      const res = await repositoryPos.get<MessageResponse<any[]>>(
        `/api/v1/TblDmCustomer/get-customer-by-list-id?${url}`
      );

      if (res && res.success) {
        const data = res.data;
        setListCustomer(data);
      } else {
        setListCustomer([]);
      }
    }
  };

  const fetchDataCustomer = async () => {
    let url = `?Skip=0&Take=${100}`;

    if (customerId) {
      url += `&KeySearch=${customerId.trim()}`;
    }

    try {
      const response = await repositoryPos.get(
        `api/v1/TblDmCustomer/get-customer-for-delivery${url}`
      );
      if (response && response.success) {
        const filteredData = response.data.lists.filter(
          (customer: any) =>
            customer.customerCode !== null || customer.customerName !== null
        );
        const mappedData = filteredData.map((item: any) => ({
          value: item.customerId.toString(),
          label: item.customerName,
        }));
        setDataCustomerSelect(mappedData);
      }
    } catch (error) {
      setDataCustomerSelect([]);
      return;
    }
  };

  const updateWarrantyStatus = async (status: string) => {
    const res = await repositoryMdm.post<MessageResponse<any>>(
      `/api/v1/TblWarrantyServiceRequestForm/update-request-form-status?status=${status}`,
      selectIds
    );

    if (res) {
      if (res.success) {
        NotificationExtension.Success("Đổi trạng thái thành công !");
        fetchData();
      }
    }
  };

  const dataBranchSelect = async () => {
    const getData = await getBranchSelectMdm();
    setDataTblBranch(
      getData
        ?.filter(
          (item) =>
            item.value != null && item.text != null && item.value != "-1"
        )
        ?.map((item) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  // const getListItem = async () => {
  //   if (listItemId.length > 0) {
  //     let url = "";
  //     for (var i = 0; i < listItemId.length; i++) {
  //       url += `Ids=${listItemId[i]}&`;
  //     }
  //     const res = await repositoryPos.get<MessageResponse<any[]>>(
  //       `/api/v1/TblItem/get-list-for-warranty-service-request?${url}`
  //     );

  //     if (res && res.success) {
  //       const data = res.data;
  //       setListItem(data);
  //     } else {
  //       setListItem([]);
  //     }
  //   }
  // };

  const getEmployee = async () => {
    const res = await repositoryPos.get<MessageResponse<any[]>>(
      "/api/v1/TblDMEmployee/get-all"
    );

    if (res && res.success) {
      const data = res.data;
      setDataEmployee(data);
      // setDataEmployeeSelect(
      //   data
      //     ?.filter((item: any) => item.fullname != null)
      //     .map((item: any) => ({
      //       value: item.id.toString(),
      //       label: item.fullname,
      //     }))
      // );
    } else {
      setDataEmployee([]);
    }
  };

  const getProcessingMethod = async () => {
    const res = await repositoryMdm.get<MessageResponse<any[]>>(
      "/api/v1/TblDmProcessingMethod/get-all"
    );

    if (res && res.success) {
      const data = res.data;
      setDataProcessingMethod(data);
    } else {
      setDataProcessingMethod([]);
    }
  };

  // const getRequestStatus = async () => {
  //   const res = await repositoryMdm.get<MessageResponse<any[]>>(
  //     "/api/v1/TblDMRequestStatus/get-all"
  //   );

  //   if (res && res.success) {
  //     const data = res.data;
  //     setDataRequestStatus(data);
  //   } else {
  //     setDataRequestStatus([]);
  //   }
  // };

  function modalRemoteProcessing(id: number | string, status: string | null) {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Cập nhật thông tin bảo hành sản phẩm</Title>
        </>
      ),
      size: "auto",
      children: (
        <RemoteProcessing
          id={id}
          onClose={setDeleteViewStatus}
          status={status}
        />
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  }

  function modalAssignAtHome() {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Phân công xử lý tại nhà</Title>
        </>
      ),
      size: "auto",
      children: (
        <AssignmentAtHome
          selectIds={selectIds}
          onClose={setDeleteViewStatus}
          dataRequestFormSelect={dataRequestFormSelect}
        />
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  }

  function modalWarrantyAssignment() {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Phân công nhân viên bảo hành</Title>
        </>
      ),
      size: "auto",
      children: (
        <WarrantyAssignment
          dataRequestFormSelect={dataRequestFormSelect}
          selectIds={selectIds}
          onClose={setDeleteViewStatus}
        />
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  }

  function modalUpdateWarrantyAssign(
    id: number | string,
    dataRequestForm: any
  ) {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Chỉnh sửa phân công nhân viên bảo hành</Title>
        </>
      ),
      size: "auto",
      children: (
        <UpdateWarrantyAssignment
          id={id}
          onClose={setDeleteViewStatus}
          dataRequestFormSelect={dataRequestForm}
        />
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  }

  useEffect(() => {
    let timer: ReturnType<typeof setInterval>;
    if (isCounting && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsCounting(false);
    }
    return () => clearInterval(timer);
  }, [isCounting, timeLeft]);

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);

  useEffect(() => {
    fetchData();
  }, [pagination, deleteViewStatus]);

  useEffect(() => {
    const updateListCustId = () => {
      const uniqueItemId = Array.from(
        new Set(
          data.map((item) => item.custId).filter((custId) => custId !== null)
        )
      ).map(Number);
      setListCustId(uniqueItemId);
    };

    // const updateListItemId = () => {
    //   const uniqueItemId = Array.from(
    //     new Set(
    //       data.map((item) => item.itemId).filter((itemId) => itemId !== null)
    //     )
    //   ).map(Number);
    //   setListItemId(uniqueItemId);
    // };

    updateListCustId();
    // updateListItemId();
  }, [data]);

  // useEffect(() => {
  //   getListCustomer();
  // }, [listCustId]);

  // useEffect(() => {
  //   getListItem();
  // }, [listItemId]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      setHeight(window.innerHeight - (170 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  useEffect(() => {
    if (selectIds.length > 0) {
      const lastId = selectIds[selectIds.length - 1];
      const foundData = data.find((p) => p.id.toString() === lastId);

      if (foundData) {
        setSelectProcessingMethod(foundData.processingMethodName);
        setSelectItemStatus(foundData.requestStatusName);
        setSelectEmpNameInCharge(foundData.empnameincharge);
      } else {
        setSelectProcessingMethod(null);
        setSelectItemStatus(null);
        setSelectEmpNameInCharge(null);
      }
    } else {
      setSelectProcessingMethod(null);
      setSelectItemStatus(null);
      setSelectEmpNameInCharge(null);
    }
  }, [selectIds]);

  useEffect(() => {
    const numericIds = selectIds.map((id) => Number(id));

    const filtered = data.filter((item) =>
      numericIds.includes(Number(item.id))
    );

    setDataRequestFormSelect(filtered);
  }, [selectIds, data]);

  // useEffect(() => {
  //   if (customerId && customerId.trim().length > 2) {
  //     const delayDebounce = setTimeout(() => {
  //       fetchDataCustomer();
  //     }, 300);

  //     return () => clearTimeout(delayDebounce);
  //   } else {
  //     setDataCustomerSelect([]);
  //   }
  // }, [customerId]);

  const table = useMantineReactTable({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    columnFilterDisplayMode: "popover",
    enableRowSelection: (row) => {
      if (selectProcessingMethod === null || selectProcessingMethod === "") {
        return true;
      } else {
        return row.original.processingMethodName === selectProcessingMethod;
      }
    },
    //add custom action buttons to top-left of top toolbar
    renderTopToolbarCustomActions: ({ row }: any) => (
      <div ref={headerRef} style={{ width: "100%" }}>
        <Flex justify={"space-between"} wrap={"wrap"}>
          <_breadcrumb></_breadcrumb>
          <Flex
            gap="md"
            justify="flex-end"
            align="center"
            direction="row"
            wrap="wrap"
          >
            <Button
              color="violet"
              disabled={
                selectProcessingMethod === "Hãng xử lý" ||
                selectIds.length === 0 ||
                (selectItemStatus !== "Chờ phân công" &&
                  selectItemStatus !== "Hủy phân công" &&
                  selectEmpNameInCharge !== null) ||
                (selectProcessingMethod === "Xử lý tại nhà" &&
                  selectIds.length > 1)
              }
              onClick={() => handleAssignmentProcessing()}
              w={115}
            >
              Phân công
            </Button>
            <Button
              color="red"
              disabled={
                selectItemStatus === "Chờ phân công" ||
                selectProcessingMethod === "Hãng xử lý" ||
                selectItemStatus === "Hủy phân công" ||
                selectIds.length === 0
              }
              leftSection={<IconClipboardX size={20} />}
              onClick={() => handleCancelAssign(selectIds)}
            >
              Hủy phân công
            </Button>
          </Flex>
        </Flex>
        <Grid mt={10}>
          <Grid.Col span={{ base: 12, md: 6, lg: 2.25 }}>
            <DatePickerInput
              type="range"
              size="sm"
              placeholder="Từ ngày - Đến ngày"
              leftSection={<IconCalendar color="#15aabf" />}
              locale="vi"
              valueFormat="DD/MM/YYYY"
              onChange={(e) => {
                handleChangeSearchValue(
                  formatDateNotTimeZone(e[0]) ?? "",
                  "startDate"
                );
                handleChangeSearchValue(
                  formatDateNotTimeZone(e[1]) ?? "",
                  "endDate"
                );
              }}
              clearable
            />
          </Grid.Col>
          {/* {/* <Grid.Col span={{ base: 12, md: 6, lg: 1.75 }}>
            <Select
              placeholder="Người nhận phân công"
              data={dataEmployeeSelect}
              leftSection={<IconUserCode color="#15aabf" />}
              searchable
              clearable
              onChange={(e) =>
                handleChangeSearchValue(e?.toString() ?? "", "employeeIncharge")
              }
              nothingFoundMessage="Không tìm thấy dữ liệu !"
            />
          </Grid.Col> */}
          {/* <Grid.Col span={{ base: 6, md: 6, lg: 1.75 }}>
            <Select
              placeholder="Khách hàng"
              data={dataCustomerSelect}
              leftSection={<IconUsers color="#15aabf" />}
              type="text"
              searchable
              clearable
              searchValue={customerId ?? ""}
              onSearchChange={setCustomerId}
              onChange={(e) =>
                handleChangeSearchValue(e?.toString() ?? "", "custId")
              }
              comboboxProps={{ width: 325 }}
              nothingFoundMessage={
                customerId && customerId?.length > 2
                  ? "Không tìm thấy dự liệu !"
                  : "Vui lòng nhập tối thiểu 3 kí tự !"
              }
            />
          </Grid.Col> */}
          <Grid.Col span={{ base: 6, md: 6, lg: 1.75 }}>
            <Select
              placeholder="Trạng thái"
              data={[
                { label: "Chờ phân công", value: "Chờ phân công" },
                { label: "Hủy phân công", value: "Hủy phân công" },
                { label: "Đã phân công", value: "Đã phân công" },
                { label: "Xử lý lại", value: "Xử lý lại" },
                { label: "Kỹ thuật hủy", value: "Kỹ thuật hủy" },
                { label: "Hãng xử lý", value: "Hãng xử lý" },
                { label: "Hãng đang xử lý", value: "Hãng đang xử lý" },
                { label: "Đang xử lý", value: "Đang xử lý" },
                { label: "Đã xử lý", value: "Đã xử lý" },
              ]}
              leftSection={<IconStatusChange color="#15aabf" />}
              clearable
              comboboxProps={{
                transitionProps: { transition: "pop", duration: 200 },
              }}
              onChange={(e) =>
                handleChangeSearchValue(e?.toString() ?? "", "status")
              }
              renderOption={renderSelectOptionStatus}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 6, md: 6, lg: 1.75 }}>
            <Select
              placeholder="Hình thức xử lý"
              data={[
                { label: "Xử lý tại nhà", value: "Xử lý tại nhà" },
                { label: "Xử lý tại CH/CN", value: "Xử lý tại CH/CN" },
                { label: "Hãng xử lý", value: "Hãng xử lý" },
                { label: "Xử lý trực tiếp", value: "Xử lý trực tiếp" },
                { label: "Xử lý từ xa", value: "Xử lý từ xa" },
              ]}
              leftSection={<IconAdjustmentsCog color="#15aabf" />}
              comboboxProps={{
                transitionProps: { transition: "pop", duration: 200 },
              }}
              onChange={(e) =>
                handleChangeSearchValue(e?.toString() ?? "", "processingMethod")
              }
              renderOption={renderSelectOptionProcessingMethod}
              clearable
            />
          </Grid.Col>
          <Grid.Col span={{ base: 6, md: 6, lg: 1.75 }}>
            <TextInput
              placeholder="Nhập từ khóa tìm kiếm"
              leftSection={<IconSearch color="#15aabf" />}
              onChange={(e) =>
                handleChangeSearchValue(
                  e.currentTarget.value ?? "",
                  "searchAll"
                )
              }
              onKeyDown={async (e) => {
                if (e.key === "Enter") {
                  await fetchData();
                }
              }}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 6, md: 6, lg: 1 }}>
            <Button
              variant="outline"
              color="blue"
              leftSection={<IconSearch size={14} />}
              onClick={async () => {
                await fetchData();
              }}
            >
              Tìm kiếm
            </Button>
          </Grid.Col>
        </Grid>
      </div>
    ),
    renderToolbarInternalActions: ({ table }) => <></>,
    renderDetailPanel: ({ row }) => (
      <Flex justify={"start"} w={"100%"}>
        <DetailProductWarranty
          processingMethod={row.original.processingMethodName}
          dataDetail={row.original.tblWarrantyServiceRequestFormDetailModels}
          typeProduct={row.original.attribute6}
        />
      </Flex>
    ),
    mantineSelectAllCheckboxProps: {
      style: {
        display: "none",
      },
    },
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select", "requestNum"],
        right: ["processingMethodName", "action"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    manualFiltering: false,
    manualPagination: true,
    manualSorting: false,
    onPaginationChange: setPagination,
    onRowSelectionChange: setRowSelection,
    rowCount,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontWeight: "500",
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["10", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });

  return (
    <>
      <MantineReactTable table={table} />
    </>
  );
};

export default ProcessingAssignment;
