import {
  ActionIcon,
  Badge,
  Box,
  Button,
  ComboboxItem,
  Flex,
  Grid,
  GridCol,
  Input,
  Menu,
  rem,
  Select,
  Text,
  Tooltip,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { useHotkeys } from "@mantine/hooks";
import {
  IconArrowNarrowLeft,
  IconBrandBootstrap,
  IconCaretDown,
  IconFileExport,
  IconFileTypography,
  IconFileUpload,
  IconPlus,
  IconSearch,
  IconStatusChange,
  IconTimeDuration15,
  IconUserCode,
} from "@tabler/icons-react";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MRT_SortingState,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import * as xlsx from "xlsx";
import { repositoryPos } from "../../../_base/_const/_constVar";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import { paginationBase } from "../../../_base/model/_base/BaseTable";
import {
  formatDateNotTimeZone,
  formatDateTransfer,
} from "../../../common/FormatDate/FormatDate";
import { _localization } from "../../../config/location";
import { cobalt_blue, green, teal } from "../../../const/variables";
import { TblOutwardGetList } from "../../../model/TblOutWard";
import {
  getInventorySelectPos,
  getTblDmEmployeeSelect,
  getTblInventoryTransactionTypeInWard,
  getTblInventoryTransactionTypeOutWard,
} from "../../../service/getSelectApi";

interface InwardRequestInterface {
  type: number;
  createDate: string | null;
  sourceCodeParent: string;
  sourceType: string;
  sourceTypeName: string;
  sourceTranCode: string;
  depName: string;
  workAddress: string;
  invName: string;
  roleName: string;
  empName: string;
  note: string | null;
}

const InwardRequest = () => {
  const navigate = useNavigate();
  // Reference data from another table

  //data and fetching state
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<InwardRequestInterface[]>([]);
  const [dataInv, setDataInv] = useState<ComboboxItem[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);
  const [dataInventoryTransactionType, setDataInventoryTransactionType] =
    useState<ComboboxItem[]>([]);
  //table state
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

  const [pagination, setPagination] = useState(paginationBase);
  const [sorting, setSorting] = useState<MRT_SortingState>([]);

  //count
  const [deleteViewStatus, setDeleteViewStatus] = useState(false);

  const search = {
    keySearch: "",
    fromTransationDate: "",
    toTransationDate: "",
    sourceType: "",
    invId: "",
    status: "",
    empId: "",
  };

  const columns = React.useMemo<MRT_ColumnDef<InwardRequestInterface>[]>(
    () => [
      {
        accessorKey: "index",
        header: "STT",
        size: 1,
        enableSorting: false,
        Cell: ({ row: { index } }: any) => index + 1,
      },
      {
        accessorKey: "createDate",
        header: "Thời gian tạo",
        size: 100,

        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "finishDate",
        header: "Thời gian hoàn thành",
        size: 180,
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "sourceTranCode",
        header: "Phiếu xuất",
        Cell: ({ renderedCellValue }: any) => (
          <Badge
            radius="sm"
            variant="dot"
            size="lg"
            style={{ cursor: "pointer" }}
            color={renderedCellValue === null ? "red" : green.base}
            onDoubleClick={() => {}}
          >
            {renderedCellValue === null ? null : renderedCellValue}
          </Badge>
        ),
      },

      {
        accessorKey: "depName",
        header: "Phòng ban",
        Cell: ({ renderedCellValue }: any) => (
          <Text fw={700} size="sm">
            {renderedCellValue}
          </Text>
        ),
      },

      {
        accessorKey: "workAddress",
        header: "Địa điểm làm việc",
        Cell: ({ renderedCellValue }: any) => (
          <Text fw={700} size="sm">
            {renderedCellValue}
          </Text>
        ),
      },

      {
        accessorKey: "invName",
        header: "Kho",
        Cell: ({ renderedCellValue, row }) => (
          <Text size="sm">{renderedCellValue}</Text>
        ),
      },

      {
        accessorKey: "roleName",
        header: "Chức danh",
        Cell: ({ renderedCellValue }) => (
          <Text size="11.5px">{renderedCellValue}</Text>
        ),
      },
      {
        accessorKey: "empName",
        header: "Họ & Tên",
        Cell: ({ renderedCellValue }) => (
          <Text size="11.5px">{renderedCellValue}</Text>
        ),
      },

      {
        accessorKey: "note",
        header: "Ghi chú",
        Cell: ({ renderedCellValue, row }) => (
          <Tooltip label={renderedCellValue}>
            <Text size="sm" maw={300}>
              {renderedCellValue}
            </Text>
          </Tooltip>
        ),
      },

      {
        accessorKey: "a",
        header: "GHNN",
        Cell: ({ renderedCellValue, row }) => (
          <Tooltip label={renderedCellValue}>
            <Text size="sm" maw={300}>
              {renderedCellValue}
            </Text>
          </Tooltip>
        ),
      },

      {
        accessorKey: "action",
        header: "Thao tác",
        size: 50,
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
        Cell: ({ renderedCellValue, row, table }) => (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Tooltip label="Nhập kho">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="blue"
                onClick={async () => {
                  inward(
                    row?.original?.sourceTranCode,
                    row?.original?.sourceType
                  );
                }}
              >
                <IconArrowNarrowLeft size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
          </Box>
        ),
      },
    ],
    [dataInventoryTransactionType, dataInv]
  );

  const form = useForm({
    mode: "uncontrolled",
    initialValues: search,
  });

  const handleExport = () => {
    try {
      const worksheet = xlsx.utils.json_to_sheet(data);
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, worksheet, "Data");
      xlsx.writeFile(workbook, "danh-sach-phieu-xuat-kho.xlsx");
      toast.success("Export excel thành công", { autoClose: 1500 });
    } catch (error) {
      toast.error("Export excel thất bại", { autoClose: 1500 });
    }
  };

  const inward = (sourceTranCode: string, sourceType: string) => {
    navigate("/warehouse-config/create-warehouse-in-ward", {
      state: {
        sourceCode: sourceTranCode,
        sourceType: sourceType,
      },
    });
  };

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setIsError(false);

    let _softing = "";
    let isOrder = false;
    if (sorting && sorting.length > 0) {
      let _q = sorting[0];
      if (_q) {
        _softing = _q.id;
        isOrder = _q.desc;
      }
    }

    try {
      const queryParams = {
        Skip: pagination?.pageIndex * pagination?.pageSize,
        Take: pagination?.pageSize,
        KeySearch: form.getValues()?.keySearch,
        FromDate: form.getValues()?.fromTransationDate,
        ToDate: form.getValues()?.toTransationDate,
        SourceType: form.getValues()?.sourceType,
        sort: _softing,
        isOrder: isOrder,
      };
      const baseUrl =
        "/api/v1/RequestInventoryTransaction/get-list-request-transaction-in-ward?";
      const url = Object.entries(queryParams)
        .reduce((acc, [key, value]) => {
          if (value !== undefined && value !== "" && value !== null) {
            return `${acc}&${key}=${encodeURIComponent(value)}`;
          }
          return acc;
        }, baseUrl)
        .replace("&", "");
      const response = await repositoryPos.get(url);
      if (response && response.success) {
        let result = response.data;
        setIsLoading(false);
        setData(result || []);
        setRowCount(response.totalCount);
        table.resetRowSelection();
      } else setData([]);
    } catch (error) {
      setData([]);
      setIsError(true);
      console.error(error);
      return;
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
  };

  const fetchdataInventoryTransactionType = async () => {
    const response = await getTblInventoryTransactionTypeInWard();

    setDataInventoryTransactionType(
      response.map((type) => ({ value: type.value, label: type.text }))
    );
  };

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 190 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (150 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  useEffect(() => {
    fetchData();
  }, [sorting, pagination, deleteViewStatus]);

  const table = useMantineReactTable({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    enableTopToolbar: false,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    enableRowSelection: false,
    getRowId: (row) => row.sourceTranCode?.toString(),
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: [
          "index",
          "createDate",
          "finishDate",
          "sourceTranCode",
          "mrt-row-select",
        ],
        right: ["action"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    manualFiltering: false,
    manualPagination: true,
    manualSorting: true,
    enableFilters: false,
    enableColumnActions: false,
    rowCount,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    mantineTableBodyCellProps: () => ({
      style: {
        fontSize: "11.5px",
        padding: "4px 12px",
      },
    }),
    state: {
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
      sorting,
    },
    mantineToolbarAlertBannerProps: false
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["20", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
  });

  return (
    <>
      <div
        style={{ border: "1px solid #dee2e6", padding: "5px" }}
        ref={headerRef}
      >
        <Flex
          gap="md"
          justify={"space-between"}
          align="center"
          direction="row"
          wrap="wrap"
          w={"100%"}
        >
          <_breadcrumb></_breadcrumb>
          <Flex gap="md" align="center" direction="row" wrap="wrap">
            <Menu shadow="md" width={200}>
              <Menu.Target>
                <Button
                  // size="xs"
                  rightSection={
                    <IconCaretDown
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                >
                  Chức năng
                </Button>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Label>Application</Menu.Label>
                <Menu.Item
                  leftSection={
                    <IconFileExport
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                  onClick={handleExport}
                >
                  Export Excel
                </Menu.Item>
                <Tooltip label={"Chức năng đang update"}>
                  <Menu.Item
                    leftSection={
                      <IconFileUpload
                        style={{ width: rem(14), height: rem(14) }}
                      />
                    }
                  >
                    Import Excel
                  </Menu.Item>
                </Tooltip>
              </Menu.Dropdown>
            </Menu>
          </Flex>
        </Flex>

        <form>
          <Grid mt={"10px"}>
            <GridCol span={{ base: 6, sm: 4, md: 2, lg: 1.25 }}>
              <Input
                w={"100%"}
                placeholder="Từ khoá"
                type="text"
                leftSection={<IconSearch color="#15aabf" />}
                key={form.key("keySearch")}
                onKeyDown={async (e) => {
                  if (e.key === "Enter") {
                    await fetchData();
                  }
                }}
                {...form.getInputProps("keySearch")}
              />
            </GridCol>

            <GridCol span={{ base: 6, sm: 4, md: 2, lg: 1.25 }}>
              <Select
                placeholder={"Loại chứng từ gốc"}
                searchable
                clearable
                leftSection={<IconFileTypography color="#15aabf" />}
                comboboxProps={{
                  transitionProps: { transition: "pop", duration: 200 },
                }}
                data={dataInventoryTransactionType}
                nothingFoundMessage={"Loading..."}
                key={form.key("sourceType")}
                onClick={() => {
                  if (dataInventoryTransactionType.length === 0) {
                    fetchdataInventoryTransactionType();
                  }
                }}
                {...form.getInputProps("sourceType")}
              />
            </GridCol>

            <GridCol span={{ base: 8, sm: 6, md: 3.35, lg: 2.5 }}>
              <DatePickerInput
                // type="multiple"
                type="range"
                size="sm"
                allowSingleDateInRange
                placeholder="Chọn khoảng ngày"
                leftSection={<IconTimeDuration15 color="#15aabf" />}
                locale="vi"
                // value={value}
                valueFormat="DD/MM/YYYY"
                onChange={(e) => {
                  form.setValues((prev) => ({
                    ...prev,
                    fromTransationDate: formatDateNotTimeZone(e[0]) ?? "",
                    toTransationDate: formatDateNotTimeZone(e[1]) ?? "",
                  }));
                }}
                clearable
              />
            </GridCol>

            <GridCol span={{ base: 4, sm: 4, md: 1.5, lg: 1.25 }}>
              <Button
                leftSection={<IconSearch size={14} />}
                color="blue"
                variant="outline"
                onClick={async () => {
                  await fetchData();
                }}
              >
                Tìm kiếm
              </Button>
            </GridCol>
          </Grid>
        </form>
      </div>

      <div className="mt-5" style={{ marginTop: "10px" }}>
        <MantineReactTable table={table} />
      </div>
    </>
  );
};

export default InwardRequest;
