import { useEffect, useRef, useState } from "react";
import {
  repositoryDelivery,
  repositoryPos,
} from "../../_base/_const/_constVar";
import { MessageResponse } from "../../model/MessageResponse";
import { TblAssignTestingTechniqueModel } from "../../model/TblTestingTechniqueHeader";
import {
  ActionIcon,
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  Group,
  Menu,
  ScrollArea,
  Select,
  Table,
  TextInput,
  Text,
  Badge,
  Fieldset,
} from "@mantine/core";
import { sky_blue } from "../../const/variables";
import { modals } from "@mantine/modals";
import {
  useDebouncedCallback,
  useDebouncedState,
  useDisclosure,
} from "@mantine/hooks";
import {
  IconArrowBack,
  IconCheck,
  IconTrash,
  IconWindow,
} from "@tabler/icons-react";
import { ComboboxItem } from "../../model/ComboboxItem";
import { NotificationExtension } from "../../_base/extension/NotificationExtension";
import { getTechnicalTestingDifficultySelect } from "../../service/getSelectApi";
import { useForm } from "@mantine/form";
import cx from "clsx";
import classes from "../../Styles/TableScrollArea.module.css";
import ListProductDetail from "./ListProductDetail";
import { SocketExtension } from "../../_base/socket/socket";
import { getValueById } from "../../_base/helper/FunctionHelper";
import { useLocation, useNavigate } from "react-router-dom";

const ModalAssignTestingTechnique = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const id = location?.state && location.state.id;
  const dataStatusOption = location?.state && location.state.dataStatusOption;
  const dataSourceStatus = location?.state && location.state.dataSourceStatus;
  const entity = {
    testingTechniqueHeaderId: Number(id),
    processingDate: null,
    priorityId: null,
    difficultyLevelId: null,
    note: null,
    responsibleEmployeeId: null,
    assignToTblTestingTechniqueHeaderCommandModels: null,
  };

  const form = useForm<TblAssignTestingTechniqueModel>({
    mode: "uncontrolled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },

    transformValues: (values) => ({
      ...values,
      priorityId: Number(values.priorityId),
      difficultyLevelId: Number(values.difficultyLevelId),
    }),
  });

  const [visible, { toggle, close, open }] = useDisclosure(false);
  const [scrolled, setScrolled] = useState(false);

  const [searchEmployee, setSearchEmployee] = useDebouncedState<string>(
    "",
    300
  );
  const [dataEmployeeOption, setDataEmployeeOption] = useState<any[]>([]);
  const [dataDeliverPriority] = useState<ComboboxItem[]>([
    { label: "Cao", value: "1" },
    { label: "Thấp", value: "2" },
  ]);
  const [dataDetail, setDataDetail] = useState<any>({});
  const [
    dataTechnicalTestingDifficultySelect,
    setDataTechnicalTestingDifficultySelect,
  ] = useState<ComboboxItem[]>([]);
  const [dataSelectEmployee, setDataSelectEmployee] = useState<any[]>([]);

  const [empCodeSelect, setEmpCodeSelect] = useState<Set<any>>(new Set());
  const initialScreenHeightRef = useRef<number>(window.innerHeight);
  const boxTopRef = useRef<number>(0);
  const [bottomBoxHeight, setBottomBoxHeight] = useState(0);

  const handleChangeValue = useDebouncedCallback(
    (key: string, value: any, index: number) => {
      form.setValues((prevData: any) => {
        const prevEmployee = Array.isArray(
          prevData.assignToTblTestingTechniqueHeaderCommandModels
        )
          ? prevData.assignToTblTestingTechniqueHeaderCommandModels
          : [];

        if (prevEmployee[index]?.[key] === value) {
          return prevData;
        }

        const updatedModels = [...prevEmployee];
        updatedModels[index] = {
          ...updatedModels[index],
          [key]: value,
        };

        const filteredModels = updatedModels.filter((model) => model !== null);

        return {
          ...prevData,
          assignToTblTestingTechniqueHeaderCommandModels: filteredModels,
        };
      });
    },
    300
  );

  const handleDeleteEmployee = (index: number) => {
    setDataSelectEmployee(dataSelectEmployee.filter((_, i) => i !== index));
    setEmpCodeSelect((prev) => {
      const updatedSet = new Set(prev);
      updatedSet.delete(dataSelectEmployee[index].id);
      return updatedSet;
    });
    if (
      dataSelectEmployee[index].id === form.getValues().responsibleEmployeeId
    ) {
      form.setValues((prev) => ({
        ...prev,
        responsibleEmployeeId: null,
      }));
    }
    form.setValues((prev) => ({
      ...prev,
      assignToTblTestingTechniqueHeaderCommandModels:
        prev.assignToTblTestingTechniqueHeaderCommandModels
          ? prev.assignToTblTestingTechniqueHeaderCommandModels.filter(
              (emp: any) => emp.employeeId !== dataSelectEmployee[index].id
            )
          : [],
    }));
  };

  const getDetailProduct = async () => {
    const res = await repositoryDelivery.get<MessageResponse<any>>(
      `/api/v1/TblTestingTechniqueHeader/get-detail?id=${id}`
    );

    if (res && res?.success) {
      const dataApi = res.data;
      setDataDetail(dataApi);
    }
  };

  const handleAssignEmployee = async (dataSubmit: any) => {
    const url = `/api/v1/TblTestingTechniqueHeader/assign`;
    open();
    if (
      form.getValues().assignToTblTestingTechniqueHeaderCommandModels
        ?.length === 0 ||
      !form.getValues().assignToTblTestingTechniqueHeaderCommandModels
    ) {
      NotificationExtension.Fails(
        "Vui lòng chọn nhân viên kỹ thuật để phân công !"
      );
      close();
      return;
    } else if (!form.getValues().responsibleEmployeeId) {
      NotificationExtension.Fails(
        "Vui lòng tích chọn người chịu trách nhiệm !"
      );
      close();
      return;
    } else {
      try {
        const response = await repositoryDelivery.post<
          MessageResponse<TblAssignTestingTechniqueModel>
        >(url, dataSubmit);
        if (response?.success) {
          NotificationExtension.Success("Phân công kiểm tra thành công");
          navigate("/testing/testing-technique");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    close();
  };

  // const getDataDeliveryPriotitySelect = async () => {
  //   const res = await getTblDmDeliverPrioritySelect();
  //   setDataDeliveryPriority(
  //     res
  //       .filter(
  //         (item) =>
  //           item.value != null && item.text != null && item.value !== "-1"
  //       )
  //       .map((item) => ({
  //         value: item.value,
  //         label: item.text,
  //       }))
  //   );
  // };

  const getDataTechnicalTestingDifficultySelect = async () => {
    const res = await getTechnicalTestingDifficultySelect();
    setDataTechnicalTestingDifficultySelect(
      res
        .filter(
          (item) =>
            item.value != null && item.text != null && item.value !== "-1"
        )
        .map((item) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const getDataEmployee = async () => {
    const res = await repositoryPos.get<MessageResponse<any>>(
      `/api/v1/TblDmEmployee/get-list?Take=100&KeySearch=${searchEmployee}`
    );

    if (res && res?.success) {
      const result = res.data;
      setDataEmployeeOption(
        result?.filter((item: any) =>
          item.roleName.toLowerCase().includes("kỹ thuật")
        )
      );
    } else {
      setDataEmployeeOption([]);
    }
  };

  useEffect(() => {
    Promise.all([
      // getDataDeliveryPriotitySelect(),
      getDataTechnicalTestingDifficultySelect(),
    ]);
  }, []);

  useEffect(() => {
    if (searchEmployee && searchEmployee.length > 2) {
      getDataEmployee();
    } else {
      setDataEmployeeOption([]);
    }
  }, [searchEmployee]);

  useEffect(() => {
    getDetailProduct();
  }, [id]);

  useEffect(() => {
    const updateHeight = () => {
      if (boxTopRef.current) {
        const boxTopHeight = boxTopRef.current;
        const totalHeight = window.innerHeight;
        const calculatedBottomHeight = totalHeight - boxTopHeight;
        setBottomBoxHeight(calculatedBottomHeight);
      }
    };

    updateHeight();

    window.addEventListener("resize", updateHeight);

    return () => window.removeEventListener("resize", updateHeight);
  }, []);

  return (
    <Box
      component="form"
      mx="auto"
      onSubmit={form.onSubmit((e) => {
        handleAssignEmployee(e);
        SocketExtension.SendMessageToEmp<any>(
          Number(form.getValues().responsibleEmployeeId),
          {
            message: "Có nhiệm vụ",
          }
        );
      })}
    >
      <Box
        ref={(el) => {
          if (el) boxTopRef.current = el.offsetHeight;
        }}
      >
        <Group
          justify="end"
          style={{
            position: "sticky",
            bottom: 0,
            backgroundColor: "white",
          }}
        >
          <Button
            type="button"
            color="red"
            loading={visible}
            onClick={() => {
              navigate("/testing/testing-technique");
            }}
            leftSection={!visible ? <IconArrowBack size={18} /> : undefined}
          >
            Quay lại
          </Button>
          <Button
            type="submit"
            color={sky_blue.base}
            loading={visible}
            leftSection={!visible ? <IconCheck size={18} /> : undefined}
          >
            Phân công
          </Button>
          <></>
        </Group>
        <Grid>
          <Grid.Col span={{ base: 12, md: 12, lg: 5 }} mt={0} pt={0} mb={10}>
            <Fieldset legend={<Badge>Thông tin đơn hàng</Badge>} h={"100%"}>
              <Grid>
                <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                  <TextInput
                    label="Mã kiểm tra"
                    size="xs"
                    readOnly
                    value={dataDetail?.code || ""}
                  />
                </Grid.Col>

                <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                  <TextInput
                    label="Tên khách hàng"
                    size="xs"
                    readOnly
                    value={dataDetail?.customerName || ""}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                  <TextInput
                    label="Số điện thoại"
                    size="xs"
                    readOnly
                    value={dataDetail?.customerTelephone || ""}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                  <TextInput
                    label="Trạng thái đơn hàng"
                    size="xs"
                    readOnly
                    value={getValueById(
                      dataDetail?.sourceStatus?.toString(),
                      dataSourceStatus,
                      "label"
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                  <TextInput
                    label="Trạng thái kiểm tra"
                    size="xs"
                    readOnly
                    value={getValueById(
                      dataDetail?.testingStatus?.toString(),
                      dataStatusOption,
                      "label"
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                  <TextInput
                    label="Ghi chú"
                    size="xs"
                    readOnly
                    value={dataDetail?.description || ""}
                  />
                </Grid.Col>
              </Grid>
            </Fieldset>
          </Grid.Col>

          <Grid.Col span={{ base: 12, md: 12, lg: 7 }} mb={10}>
            <Fieldset
              legend={
                <Badge color="orange">Thông tin phân công kiểm tra</Badge>
              }
              h={"100%"}
            >
              <Menu
                trigger="hover"
                openDelay={100}
                closeDelay={400}
                width={700}
              >
                <Menu.Target>
                  <TextInput
                    label="Tìm kiếm nhân viên"
                    placeholder="Nhập tên hoặc mã nhân viên"
                    defaultValue={searchEmployee ?? ""}
                    onChange={(e) => setSearchEmployee(e.currentTarget.value)}
                    w={300}
                  />
                </Menu.Target>
                <Menu.Dropdown>
                  <Table.ScrollContainer
                    minWidth={650}
                    mah={"100%"}
                    type="native"
                  >
                    <Table striped withTableBorder>
                      <Table.Thead>
                        <Table.Tr>
                          <Table.Th>Chọn</Table.Th>
                          <Table.Th>Mã nhân viên</Table.Th>
                          <Table.Th>Tên nhân viên</Table.Th>
                          <Table.Th>Chức dahh</Table.Th>
                        </Table.Tr>
                      </Table.Thead>
                      <Table.Tbody>
                        {dataEmployeeOption && dataEmployeeOption.length > 0 ? (
                          dataEmployeeOption.map((item, index) => (
                            <Table.Tr key={index}>
                              <Table.Td>
                                <Button
                                  color="teal"
                                  onClick={() => {
                                    setDataSelectEmployee((prev) => [
                                      ...prev,
                                      item,
                                    ]);
                                    setEmpCodeSelect((prev) => {
                                      const newSet = new Set(prev);
                                      newSet.add(Number(item.id));
                                      return newSet;
                                    });
                                    handleChangeValue(
                                      "employeeId",
                                      item.id,
                                      Number(index)
                                    );
                                  }}
                                  disabled={empCodeSelect.has(item.id)}
                                >
                                  {empCodeSelect.has(item.id)
                                    ? "Đã chọn"
                                    : "Chọn"}
                                </Button>
                              </Table.Td>
                              <Table.Th>{item.code}</Table.Th>
                              <Table.Th>{item.fullname}</Table.Th>
                              <Table.Th>{item.roleName}</Table.Th>
                            </Table.Tr>
                          ))
                        ) : (
                          <Table.Tr ta={"center"}>
                            <Table.Td colSpan={4} c={"gray"}>
                              {searchEmployee.length === 0
                                ? "Nhập mã hoặc tên nhân viên tối thiểu 3 kí tự !"
                                : "Không tìm thấy dữ kiệu !"}
                            </Table.Td>
                          </Table.Tr>
                        )}
                      </Table.Tbody>
                    </Table>
                  </Table.ScrollContainer>
                </Menu.Dropdown>
              </Menu>
              <ScrollArea
                h={167.5}
                onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
                scrollbars="y"
                mt={7.5}
              >
                <Table
                  striped
                  highlightOnHover
                  withTableBorder
                  withColumnBorders
                >
                  <Table.Thead
                    className={cx(classes.header, {
                      [classes.scrolled]: scrolled,
                    })}
                  >
                    <Table.Tr>
                      <Table.Th>Người chịu trách nhiệm</Table.Th>
                      <Table.Th>Mã nhân viên</Table.Th>
                      <Table.Th>Tên nhân viên</Table.Th>
                      <Table.Th>Chức danh</Table.Th>
                      <Table.Th>Thao tác</Table.Th>
                    </Table.Tr>
                  </Table.Thead>
                  <Table.Tbody>
                    {dataSelectEmployee.map((emp, index) => (
                      <Table.Tr key={emp.id}>
                        <Table.Td>
                          <Checkbox
                            checked={
                              form.getValues().responsibleEmployeeId === emp.id
                            }
                            onChange={() =>
                              form.setValues((prev) => ({
                                ...prev,
                                responsibleEmployeeId: emp.id,
                              }))
                            }
                          />
                        </Table.Td>
                        <Table.Td>{emp.code}</Table.Td>
                        <Table.Td>{emp.fullname}</Table.Td>
                        <Table.Td>{emp.roleName}</Table.Td>
                        <Table.Td>
                          <ActionIcon
                            aria-label="Settings"
                            color="red"
                            onClick={() => handleDeleteEmployee(index)}
                          >
                            <IconTrash size={20} stroke={1.5} />
                          </ActionIcon>
                        </Table.Td>
                      </Table.Tr>
                    ))}
                  </Table.Tbody>
                </Table>
              </ScrollArea>
            </Fieldset>
          </Grid.Col>
        </Grid>
        <Divider
          mb={5}
          label={
            <Badge color="green" size="md">
              Thông tin chi tiết đơn hàng
            </Badge>
          }
          labelPosition="center"
        />
      </Box>

      <ListProductDetail
        dataDetail={dataDetail?.tblTestingTechniqueDetailModels}
        isDetail={true}
        height={bottomBoxHeight - 190}
      />
    </Box>
  );
};

export default ModalAssignTestingTechnique;
