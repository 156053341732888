import {
  Box,
  Checkbox,
  ComboboxItem,
  Grid,
  Select,
  TextInput,
  Text,
  Fieldset,
} from "@mantine/core";
import { DateTimePicker } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { useEffect, useState } from "react";
import { repositoryDelivery } from "../../../_base/_const/_constVar";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { MessageResponse } from "../../../model/MessageResponse";
import { TblDeliveryAssignment } from "../../../model/TblDeliveryAssignment";
import {
  getDeliveryWorkSelect,
  getTblDmEmployeeSelect,
} from "../../../service/getSelectApi";
import DeliveryList from "./DeliveryList";
import DeliveryWarranty from "./DeliveryWarranty";

const DetailDeliveryAssignment = ({
  id,
  onClose,
}: DetailDeliveryAssignmentProps) => {
  const entity = {
    id: "0",
    code: null,
    assignmentDate: null,
    fromExpectedDate: null,
    toExpectedDate: null,
    deliveryWorkId: null,
    employee1: null,
    employee2: null,
    mainResponsibility1: false,
    mainResponsibility2: false,
    payroll1: false,
    payroll2: false,
    vehicleCode: null,
    driverId: null,
    driverName: null,
    driverMobileNumber: null,
    fromLocationId: null,
    toLocationId: null,
    distance: null,
    distanceLevel: null,
    distanceTotal: null,
    partnerId: null,
    partnerEmployeeName: null,
    description: null,
    deliveryIds: null,
    tblDeliveryModels: [],
  };

  const [visible, { toggle, close, open }] = useDisclosure(false);

  const [dataEmployeedSelect, setDataEmployeedSelect] = useState<
    ComboboxItem[]
  >([]);

  const [dataWorkSelect, setDataWorkSelect] = useState<ComboboxItem[]>([]);

  const form = useForm<any>({
    mode: "controlled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },
    validate: {},
  });

  const callApiGetData = async () => {
    open();
    const callapi = await repositoryDelivery.get<
      MessageResponse<TblDeliveryAssignment>
    >(`/api/v1/TblDeliveryAssignment/detail?id=${id}`);
    if (!callapi?.success) {
      modals.closeAll();
      return;
    }
    if (callapi) {
      const dataApi = callapi?.data;
      if (dataApi != null) {
        form.setValues(dataApi);
        form.resetDirty(dataApi);
        Promise.all([dataEmployeeSelect(), dataDeliveryWorkSelect()]);
      }
      close();
    } else {
      NotificationExtension.Fails("Dữ liệu không tồn tại");
      modals.closeAll();
    }
  };

  const dataEmployeeSelect = async () => {
    const get = await getTblDmEmployeeSelect();
    setDataEmployeedSelect(
      get
        ?.filter((item) => item.value != null && item.text != null)
        ?.map((item) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const dataDeliveryWorkSelect = async () => {
    const get = await getDeliveryWorkSelect();
    setDataWorkSelect(
      get
        ?.filter((item) => item.value != null && item.text != null)
        ?.map((item) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  useEffect(() => {
    if (id) callApiGetData();
  }, [id]);

  return (
    <Box
      component="form"
      maw={1350}
      w={"80vw"}
      style={{ position: "relative" }}
    >
      <Grid mt={10}>
        <Grid.Col span={{ base: 12, md: 12, lg: 7 }}>
          <Fieldset legend="Thông tin giao nhận" p={"0px 15px 7.5px 15px"}>
            <Grid>
              <Grid.Col span={{ base: 12, md: 12, lg: 4 }}>
                <TextInput
                  label="Mã phân công giao vận"
                  placeholder="Nhập mã phân công giao vận"
                  value={form.getValues().code ?? ""}
                  variant="filled"
                  readOnly
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 12, lg: 4 }}>
                <DateTimePicker
                  label="Dự kiến giao từ"
                  placeholder="Ngày dự kiến giao"
                  value={
                    form.getValues().fromExpectedDate
                      ? new Date(form.getValues().fromExpectedDate ?? "")
                      : null
                  }
                  valueFormat="DD/MM/YYYY HH:mm A"
                  variant="filled"
                  readOnly
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 12, lg: 4 }}>
                <DateTimePicker
                  label="Dự kiến giao đến"
                  placeholder="Ngày dự kiến đến"
                  value={
                    form.getValues().toExpectedDate
                      ? new Date(form.getValues().toExpectedDate ?? "")
                      : null
                  }
                  valueFormat="DD/MM/YYYY HH:mm A"
                  variant="filled"
                  readOnly
                />
              </Grid.Col>
            </Grid>
            <Grid>
              <Grid.Col span={{ base: 12, md: 12, lg: 4 }}>
                <DateTimePicker
                  label="Ngày phân công"
                  placeholder="Nhập ngày phân công"
                  value={
                    form.getValues().assignmentDate
                      ? new Date(form.getValues().assignmentDate ?? "")
                      : null
                  }
                  valueFormat="DD/MM/YYYY HH:mm A"
                  variant="filled"
                  readOnly
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 12, lg: 4 }}>
                <Select
                  label="Phương tiện giao hàng"
                  placeholder="Chọn phương tiện giao hàng"
                  value={
                    form.getValues().vehicleCode
                      ? form.getValues().vehicleCode?.toString()
                      : null
                  }
                  data={[
                    { label: "Xe máy", value: "XE_MAY" },
                    { label: "Ô tô", value: "O_TO" },
                  ]}
                  variant="filled"
                  readOnly
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 12, lg: 4 }}>
                <Select
                  label="Công việc"
                  placeholder="Chọn loại công việc"
                  value={
                    form.getValues().deliveryWorkId
                      ? form.getValues().deliveryWorkId?.toString()
                      : null
                  }
                  data={dataWorkSelect}
                  variant="filled"
                  readOnly
                />
              </Grid.Col>
            </Grid>
            <Grid>
              <Grid.Col span={{ base: 12, md: 12, lg: 4 }}>
                <TextInput
                  label="Tổng khoảng cách"
                  placeholder="Bấm nút tính khoảng cách để hiển thị"
                  value={`${Number(form.getValues().distanceTotal) / 1000} Km`}
                  variant="filled"
                  readOnly
                />
              </Grid.Col>
              {/* <Grid.Col span={{ base: 12, md: 12, lg: 4 }}>
                <TextInput
                  label="Thời gian giao ước tính"
                  value={`${Math.floor(
                    Number(form.getValues()?.estimatedTime) / 3600
                  )} giờ - ${Math.floor(
                    (Number(form.getValues()?.estimatedTime) % 3600) / 60
                  )} phút`}
                  variant="filled"
                  readOnly
                />
              </Grid.Col> */}
              <Grid.Col span={{ base: 12, md: 12, lg: 8 }}>
                <TextInput
                  label="Ghi chú"
                  placeholder="Ghi chú"
                  variant="filled"
                  readOnly
                  {...form.getInputProps("description")}
                />
              </Grid.Col>
            </Grid>
          </Fieldset>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 12, lg: 5 }}>
          <Fieldset legend="Nhân viên phân công" p={"0px 15px 7.5px 15px"}>
            <Grid>
              <Grid.Col span={{ base: 12, md: 6, lg: 7.5 }}>
                <TextInput
                  label="Lái xe"
                  placeholder="Nhập tên nhân viên lái xe"
                  value={
                    form.getValues().empDriverName
                      ? form.getValues().empDriverName
                      : null
                  }
                  variant="filled"
                  readOnly
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6, lg: 4.5 }}>
                <TextInput
                  label="Số điện thoại người lái"
                  placeholder="Nhập số điện thoại người lái"
                  {...form.getInputProps("driverMobileNumber")}
                  variant="filled"
                  readOnly
                />
              </Grid.Col>
            </Grid>
            <Grid>
              <Grid.Col span={{ base: 12, md: 6, lg: 7.5 }}>
                <TextInput
                  label="Nhân viên giao hàng"
                  placeholder="Nhập tên nhân viên giao hàng"
                  value={
                    form.getValues().employee1Name
                      ? form.getValues().employee1Name
                      : null
                  }
                  variant="filled"
                  readOnly
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6, lg: 4.5 }}>
                <Checkbox
                  mt={30}
                  label="Tính lương"
                  checked={form.getValues().payroll1}
                  disabled
                  {...form.getInputProps("payroll1")}
                />
              </Grid.Col>
            </Grid>
            <Grid>
              <Grid.Col span={{ base: 12, md: 6, lg: 7.5 }}>
                <TextInput
                  label="Nhân viên hỗ trợ"
                  placeholder="Nhập tên nhân viên hỗ trợ"
                  value={
                    form.getValues().employee2Name
                      ? form.getValues().employee2Name
                      : null
                  }
                  variant="filled"
                  readOnly
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6, lg: 4.5 }}>
                <Checkbox
                  mt={30}
                  label="Tính lương"
                  checked={form.getValues().payroll2}
                  disabled
                  {...form.getInputProps("payroll2")}
                />
              </Grid.Col>
            </Grid>
          </Fieldset>
        </Grid.Col>
      </Grid>
      <Grid align="center">
        <Grid.Col span={12}>
          <Text size="12.5px" fw={"bold"} my={6}>
            Danh sách đơn giao hàng
          </Text>
          {form.getValues().warrantyServiceRequestFormModels === null ? (
            <DeliveryList
              dataDetail={form.getValues().tblDeliveryModels}
              isSetHeight={true}
            />
          ) : form.getValues().warrantyServiceRequestFormModels ? (
            <DeliveryWarranty
              dataDetail={form.getValues().warrantyServiceRequestFormModels}
            />
          ) : (
            <></>
          )}
        </Grid.Col>
      </Grid>
    </Box>
  );
};

type DetailDeliveryAssignmentProps = {
  id: string | number;
  onClose: any;
};

export default DetailDeliveryAssignment;
