import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Flex,
  Grid,
  Menu,
  rem,
  TextInput,
  Title,
  Tooltip,
} from "@mantine/core";
import {
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import * as React from "react";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as xlsx from "xlsx";
import {
  _timerDelayClick,
  repositoryPos,
} from "../../../_base/_const/_constVar";
import { paginationBase } from "../../../_base/model/_base/BaseTable";
import { _localization } from "../../../config/location";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import {
  IconCalendar,
  IconCaretDown,
  IconEye,
  IconFileExport,
  IconFileUpload,
  IconSearch,
} from "@tabler/icons-react";
import { useDebouncedState } from "@mantine/hooks";
import { DatePickerInput } from "@mantine/dates";
import { formatDateNotTimeZone } from "../../../common/FormatDate/FormatDate";
import { useNavigate } from "react-router-dom";
import { modals } from "@mantine/modals";
import AssignReturnCustomers from "./AssignReturnCustomers";

const ReturnCustomers = () => {
  //#region State
  //data and fetching state
  const navigate = useNavigate();
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<any[]>([]);
  const [dataTemp, setDataTemp] = useState<any[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);
  //table state
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  // const [keySearch, setKeySearch] = useState<string>("");
  const [selectIds, setSelectIds] = useState<string[]>([]);
  const [deleteViewStatus, setDeleteViewStatus] = useState(false);
  const [pagination, setPagination] = useState(paginationBase);
  //count
  const [timeLeft, setTimeLeft] = useState(60);
  const [isCounting, setIsCounting] = useState(false);

  // Search
  const [search, setSearch] = useState({
    startDate: "",
    endDate: "",
  });

  const [keySearch, setKeySearch] = useDebouncedState("", 300);

  //#region  table
  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "sourceCode",
        header: "Số phiếu xuất trả",
        Cell: ({ renderedCellValue }) =>
          renderedCellValue && renderedCellValue !== null ? (
            <Tooltip label="Xem chi tiết">
              <Badge
                radius="sm"
                variant="dot"
                size="lg"
                color={renderedCellValue === null ? "red" : "green"}
              >
                {renderedCellValue}
              </Badge>
            </Tooltip>
          ) : (
            <></>
          ),
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "Ngày",
        header: "Ngày",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "Nhân viên trả",
        header: "Nhân viên trả",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "branchName",
        header: "Chi nhánh trả",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "createName",
        header: "Người lập phiếu",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "Địa điểm",
        header: "Địa điểm",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "Trạng thái",
        header: "Trạng thái",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "Ghi chú",
        header: "Ghi chú",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "action",
        header: "Thao tác",
        size: 50,
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
        Cell: ({ row }) => (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            <Tooltip label="Xem chi tiết">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="blue"
                onClick={() =>
                  detailData(
                    row.original.transactionId,
                    row.original.sourceType,
                    true
                  )
                }
              >
                <IconEye size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
            {/* <Tooltip label="Xuất trả">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="green"
                onClick={() => handleAssignReturnCustomer()}
              >
                <IconTransferOut size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip> */}
          </Box>
        ),
      },
    ],
    []
  );

  const startCountdown = () => {
    if (!isCounting) {
      setTimeLeft(_timerDelayClick);
      setIsCounting(true);
    }
  };

  const handleExport = () => {
    try {
      const worksheet = xlsx.utils.json_to_sheet(data);
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, worksheet, "Data");
      xlsx.writeFile(workbook, "danh-sach-xuat-tra-khach.xlsx");
      toast.success("Export excel thành công", { autoClose: 1500 });
    } catch (error) {
      toast.error("Export excel thất bại", { autoClose: 1500 });
    }
  };

  const handleChangeSearchValue = (value: string, key: string) => {
    setSearch((prevData) => ({ ...prevData, [key]: value }));
  };

  const detailData = (
    id: string | number,
    type: string | number | null,
    isLocked: boolean
  ) => {
    navigate("/warehouse-config/detail-warehouse-out-ward", {
      state: { id: id, type: type, isLocked: isLocked },
    });
  };

  const handleAssignReturnCustomer = () => {
    modals.openConfirmModal({
      title: <Title order={5}>Phân công trả khách</Title>,
      size: "auto",
      children: <AssignReturnCustomers />,
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setIsError(false);

    let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }&SourceTypeCode=XUAT_KHO_BAO_HANH`;

    try {
      const response = await repositoryPos.get(
        `/api/v1/TblItemTransaction/get-list${url}`
      );
      if (response && response.success) {
        let result = response.data;
        setData(result);
        setDataTemp(result);
        setRowCount(response.totalCount);
        setSelectIds([]);
        table.resetRowSelection();
      }
    } catch (error) {
      setData([]);
      setIsError(true);
      console.error(error);
      return;
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
    startCountdown();
  };

  useEffect(() => {
    let timer: ReturnType<typeof setInterval>;
    if (isCounting && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsCounting(false);
    }
    return () => clearInterval(timer);
  }, [isCounting, timeLeft]);

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);

  useEffect(() => {
    fetchData();
  }, [deleteViewStatus]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 160 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (165 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, [height]);

  const table = useMantineReactTable({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    //add custom action buttons to top-left of top toolbar
    renderTopToolbarCustomActions: ({ table }) => (
      <div ref={headerRef} style={{ width: "100%" }}>
        <Flex wrap={"wrap"} justify={"space-between"}>
          <_breadcrumb></_breadcrumb>
          <Flex
            gap="md"
            justify={{ sm: "flex-end", lg: "flex-end" }}
            align="center"
            direction="row"
            wrap="wrap"
          >
            {/* <Button
              leftSection={<IconCubeSend size={20} />}
              color="blue"
              variant="outline"
              // disabled={selectIds.length === 0}
              onClick={() => handleAssignReturnCustomer()}
            >
              Xuất trả khách
            </Button> */}
            <Menu shadow="md" width={200}>
              <Menu.Target>
                <Button
                  // size="xs"
                  rightSection={
                    <IconCaretDown
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                >
                  Chức năng
                </Button>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Label>Application</Menu.Label>
                <Menu.Item
                  leftSection={
                    <IconFileExport
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                  onClick={handleExport}
                >
                  Export Excel
                </Menu.Item>
                <Tooltip label={"Chức năng đang update"}>
                  <Menu.Item
                    leftSection={
                      <IconFileUpload
                        style={{ width: rem(14), height: rem(14) }}
                      />
                    }
                  >
                    Import Excel
                  </Menu.Item>
                </Tooltip>
              </Menu.Dropdown>
            </Menu>
          </Flex>
        </Flex>
        <Flex gap={"md"} align={"center"} wrap={"wrap"} mt={10}>
          <Grid gutter={"xs"} w={"100%"}>
            <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
              <DatePickerInput
                type="range"
                size="sm"
                placeholder="Từ ngày - Đến ngày"
                locale="vi"
                valueFormat="DD/MM/YYYY"
                leftSection={<IconCalendar size={"22px"} color="#15aabf" />}
                onChange={(e) => {
                  handleChangeSearchValue(
                    formatDateNotTimeZone(e[0]) ?? "",
                    "startDate"
                  );
                  handleChangeSearchValue(
                    formatDateNotTimeZone(e[1]) ?? "",
                    "endDate"
                  );
                }}
                clearable
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
              <TextInput
                placeholder="Nhập từ khóa"
                defaultValue={keySearch}
                leftSection={<IconSearch color="#15aabf" />}
                onChange={(e) => {
                  setKeySearch(e.currentTarget.value);
                }}
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6, lg: 1 }}>
              <Button
                leftSection={<IconSearch size={14} />}
                color="blue"
                variant="outline"
                onClick={async () => {
                  await fetchData();
                }}
                w={"100%"}
              >
                Tìm kiếm
              </Button>
            </Grid.Col>
          </Grid>
        </Flex>
      </div>
    ),
    renderToolbarInternalActions: ({ table }) => <></>,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },

    localization: _localization,
    enableRowSelection: true,
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select", "sourceCode"],
        right: ["action"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    manualFiltering: false,
    manualPagination: true,
    manualSorting: false,
    rowCount,
    // onColumnFiltersChange: setColumnFilters,
    // onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    // onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        fontWeight: "500", //conditional styling
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no data
      //sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["10", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    columnFilterDisplayMode: "popover",
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });
  //#endregion
  return (
    <>
      <MantineReactTable table={table} />
    </>
  );
};
export default ReturnCustomers;
