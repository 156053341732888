import { Badge, Button, ComboboxItem, Text, Flex } from "@mantine/core";
import {
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import * as React from "react";
import { useEffect, useState } from "react";
import { formatDateTransfer } from "../../../common/FormatDate/FormatDate";
import { _localization } from "../../../config/location";
import { AvatarUtils } from "../../../common/ColorByName/AvatarUtils";
import DetailProductWarranty from "../RequestProcessing/DetailProductWarranty";
import { repositoryDelivery } from "../../../_base/_const/_constVar";
import { getValueById } from "../../../_base/helper/FunctionHelper";
import { green } from "../../../const/variables";

const ReceiveWarrantyList = ({
  phoneNumber,
  dataRequestForm,
  setDataRequestFormSelected,
}: {
  phoneNumber: any;
  dataRequestForm: any;
  setDataRequestFormSelected: any;
}) => {
  //data and fetching state
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<any[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [dataBranchSelect, setDataBranchSelect] = useState<ComboboxItem[]>([]);
  //table state
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [selectIds, setSelectIds] = useState<string[]>([]);
  //count
  const [timeLeft, setTimeLeft] = useState(60);
  const [isCounting, setIsCounting] = useState(false);

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "requestNum",
        header: "Số phiếu yêu cầu",
        Cell: ({ renderedCellValue, cell }) => (
          <Badge
            radius="sm"
            variant="dot"
            size="lg"
            color={renderedCellValue === null ? "red" : green.base}
          >
            {renderedCellValue === null ? null : renderedCellValue}
          </Badge>
        ),
      },
      {
        accessorKey: "requestStatusName",
        header: "Trạng thái",
        Cell: ({ row }) => (
          <Badge
            radius={"sm"}
            color={getColorStatus(row.original.requestStatusName?.toString())}
            variant="light"
            bd={`1px solid ${getColorStatus(
              row.original.requestStatusName?.toString()
            )}`}
          >
            {row.original.requestStatusName}
          </Badge>
        ),
        filterFn: "equals",
        mantineFilterSelectProps: {
          data: [
            { label: "Chờ phân công", value: "Chờ phân công" },
            { label: "Hủy phân công", value: "Hủy phân công" },
            { label: "Đã phân công", value: "Đã phân công" },
            { label: "Kỹ thuật hủy", value: "Kỹ thuật hủy" },
            { label: "Đã xử lý", value: "Đã xử lý" },
            { label: "Xử lý lại", value: "Xử lý lại" },
          ],
        },
        filterVariant: "select",
      },
      {
        accessorKey: "processingMethodName",
        header: "Hình thức xử lý",
        enableColumnActions: false,
        enableSorting: false,
        Cell: ({ row }) => (
          <Badge
            color={getColorProcessingMethod(row.original.processingMethodName)}
            radius={"sm"}
          >
            {row.original.processingMethodName}
          </Badge>
        ),
        filterFn: "equals",
        mantineFilterSelectProps: {
          data: [
            { label: "Xử lý tại nhà", value: "Xử lý tại nhà" },
            { label: "Xử lý tại CH/CN", value: "Xử lý tại CH/CN" },
            { label: "Hãng xử lý", value: "Hãng xử lý" },
            { label: "Xử lý trực tiếp", value: "Xử lý trực tiếp" },
            { label: "Xử lý từ xa", value: "Xử lý từ xa" },
          ],
        },
        filterVariant: "select",
        size: 175,
      },
      {
        accessorKey: "custMeeting",
        header: "Khách hàng hẹn gặp",
        Cell: ({ row }) => <AvatarUtils value={row.original.custMeeting} />,
      },
      {
        accessorKey: "custPhone",
        header: "SĐT",
      },
      {
        accessorKey: "meetingAddress",
        header: "Địa chỉ hẹn gặp",
      },
      {
        accessorKey: "branchId",
        header: "Nơi nhận yêu cầu",
        Cell: ({ row }) => (
          <Text fw={500} size="12.5px">
            {getValueById(
              row.original.branchId?.toString(),
              dataBranchSelect,
              "label"
            )}
          </Text>
        ),
      },
      {
        accessorKey: "meetingDate",
        header: "Ngày hẹn",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
        enableColumnFilter: false,
      },
      {
        accessorKey: "createDate",
        header: "Ngày tiếp nhận",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
        enableColumnFilter: false,
      },
      {
        accessorKey: "content",
        header: "Nội dung",
      },
      {
        accessorKey: "action",
        header: "Chọn phiếu",
        Cell: ({ row }) => (
          <Flex justify={"center"}>
            <Button
              onClick={() => setDataRequestFormSelected(row.original)}
              variant="filled"
              color="teal"
              disabled={
                row.original.requestStatusName !== "Đã xử lý" ||
                (row.original.processingMethodName !== "Xử lý tại nhà" &&
                  row.original.processingMethodName !== "Xử lý tại CH/CN")
              }
            >
              Chọn
            </Button>
          </Flex>
        ),
        enableColumnActions: false,
        enableSorting: false,
        size: 50,
      },
    ],
    [dataBranchSelect]
  );

  function getColorProcessingMethod(value: string) {
    switch (value) {
      case "Xử lý tại nhà":
        return "lime";
      case "Hãng xử lý":
        return "green";
      case "Xử lý trực tiếp":
        return "teal";
      case "Xử lý tại CH/CN":
        return "cyan";
      case "Xử lý từ xa":
        return "orange";
      case "":
      case null:
        return "rgba(255, 255, 255, 0)";
    }
  }

  function getColorStatus(status: string) {
    switch (status) {
      case "Đã phân công":
      case "Đã xử lý":
      case "Hãng đã xử lý":
        return "#4CAF50";

      case "Chờ phân công":
      case "Xử lý lại":
      case "Hãng đang xử lý":
      case "Chưa xử lý":
        return "#d9d02f";

      case "Hủy phân công":
      case "Kỹ thuật hủy":
        return "#F44336";

      case "Hãng xử lý":
        return "#2196F3";

      case "Đang xử lý":
        return "#FF9800";

      case "":
      case null:
        return "rgba(255, 255, 255, 0)";

      default:
        return "#424242";
    }
  }

  const getDataBranch = async () => {
    const res = await repositoryDelivery.get("/api/v1/TblDmMasterOrg/get-all");

    if (res && res.success) {
      const filteredData = res.data.filter(
        (item: any) => item.code !== null || item.name !== null
      );
      const mappedData = filteredData.map((item: any) => ({
        value: item.id.toString(),
        label: item.name,
      }));
      setDataBranchSelect(mappedData);
    }
  };

  useEffect(() => {
    let timer: ReturnType<typeof setInterval>;
    if (isCounting && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsCounting(false);
    }
    return () => clearInterval(timer);
  }, [isCounting, timeLeft]);

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList?.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);

  useEffect(() => {
    setData(dataRequestForm);
  }, [phoneNumber]);

  useEffect(() => {
    getDataBranch();
  }, []);

  const table = useMantineReactTable({
    columns,
    data: dataRequestForm,
    positionToolbarAlertBanner: "bottom",
    //add custom action buttons to top-left of top toolbar
    renderDetailPanel: ({ row }) => (
      <DetailProductWarranty
        processingMethod={row.original.processingMethodName}
        dataDetail={row.original.tblWarrantyServiceRequestFormDetailModels}
        typeProduct={row.original.attribute6}
      />
    ),
    enableTopToolbar: false,
    renderBottomToolbar: false,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    enableRowSelection: false,
    getRowId: (row) => row.itemId?.toString(),
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["action"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: 500, minHeight: 100 },
    },
    enableStickyHeader: true,
    enableMultiRowSelection: false,
    onRowSelectionChange: setRowSelection,
    rowCount,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        fontWeight: "500", //conditional styling
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      isLoading,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["10", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
  });

  //#endregion
  return (
    <>
      <MantineReactTable table={table} />
    </>
  );
};

export default ReceiveWarrantyList;
