import {
  ActionIcon,
  Box,
  Button,
  ComboboxItem,
  Fieldset,
  Flex,
  Grid,
  Group,
  Menu,
  Select,
  Table,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core";
import { DateTimePicker } from "@mantine/dates";
import { useDebouncedState } from "@mantine/hooks";
import { IconArrowLeft, IconPlus, IconTrash } from "@tabler/icons-react";
import dayjs from "dayjs";
import {
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import moment from "moment";
import * as React from "react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  repositoryDelivery,
  repositoryMdm,
  repositoryPos,
} from "../../../_base/_const/_constVar";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { _localization } from "../../../config/location";
import { MessageResponse } from "../../../model/MessageResponse";
import { OrderDetailOutWard } from "../../../model/SaleOrderModel";
import {
  TblDMInventory,
  TblDmInventorySelectByBranch,
} from "../../../model/TblDMInventory";
import {
  TblInventorySerialItemDetailOnHand,
  TblInventorySerialOrderDetailOnHand,
} from "../../../model/TblInventorySerialOnHand";
import { TblInWard, TblInwardDetail } from "../../../model/TblInWard";
import "./tab.component.css";
import {
  getBranchSelect,
  getTblInventoryTransactionTypeInWard,
  getTblInventoryTransactionTypeOutWard,
} from "../../../service/getSelectApi";
import useScanDetection from "use-scan-detection";
import { sky_blue } from "../../../const/variables";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import { anyAsciiCode } from "../../../_base/helper/AnyAscii";

interface TblInventorySerialOrderDetailOnHandEdit
  extends TblInventorySerialOrderDetailOnHand {
  type?: string;
}

const EditWarehouseInWard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.state && location.state.id;

  const formOrderHeader = () => {
    return (
      // <ScrollArea offsetScrollbars scrollbars="y" h={height + 77}>
      <Grid pl={10}>
        <Grid.Col span={{ base: 6, xs: 4, md: 2, lg: 1 }}>
          <TextInput
            label="Mã phiếu"
            placeholder="Nhập mã"
            size="xs"
            disabled
            value={
              dataInWard.tblItemTransactionHeaderCommand.transactionCode || ""
            }
            onChange={(e) =>
              handleChangeValueInput(e.currentTarget.value, "transactionCode")
            }
          />
        </Grid.Col>
        <Grid.Col span={{ base: 6, xs: 4, md: 2, lg: 1 }}>
          <DateTimePicker
            label="Ngày lập"
            size="xs"
            disabled
            value={
              dataInWard.tblItemTransactionHeaderCommand.createDate
                ? new Date(
                    dataInWard.tblItemTransactionHeaderCommand.createDate
                  )
                : null
            }
            onChange={(value) => {
              handleChangeValueInput(
                moment(value).format("YYYY-MM-DD[T]HH:mm:ss") || "",
                "requestDate"
              );
            }}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 6, xs: 4, md: 2, lg: 1 }}>
          <TextInput
            label="Người lập"
            size="xs"
            disabled
            value={dataInWard.tblItemTransactionHeaderCommand.createName || ""}
          />
        </Grid.Col>

        <Grid.Col span={{ base: 6, xs: 4, md: 2, lg: 1 }}>
          <TextInput label="Bộ phận" size="xs" disabled />
        </Grid.Col>

        <Grid.Col span={{ base: 6, xs: 4, md: 2, lg: 1.3 }}>
          <Select
            searchable
            clearable
            size="xs"
            label="Loại phiếu"
            placeholder="Chọn loại phiếu"
            nothingFoundMessage={"Không có dữ liệu"}
            disabled
            data={dataInventoryTransactionType}
            value={
              dataInWard.tblItemTransactionHeaderCommand.sourceType?.toString() ||
              null
            }
            onChange={(value, option) =>
              handleChangeValueInput(value || "", "sourceType")
            }
          />
        </Grid.Col>

        <Grid.Col span={{ base: 6, xs: 4, md: 2, lg: 1.3 }}>
          <TextInput
            label="Chứng từ gốc/Số phiếu YC"
            size="xs"
            disabled
            value={dataInWard?.tblItemTransactionHeaderCommand.sourceCode || ""}
            onChange={(e) =>
              handleChangeValueInput(e.currentTarget.value, "sourceCode")
            }
          />
        </Grid.Col>

        <Grid.Col span={{ base: 6, xs: 4, md: 2, lg: 1.3 }}>
          <Select
            searchable
            clearable
            size="xs"
            label="Chi nhánh"
            placeholder="Chọn chi nhánh"
            nothingFoundMessage={"Không có dữ liệu"}
            disabled={
              dataInWard.tblItemTransactionHeaderCommand.status === "LOCK"
            }
            data={dataBranch}
            value={
              dataInWard.tblItemTransactionHeaderCommand.inventoryId?.toString() ||
              null
            }
            onChange={(value, option) => {
              handleChangeValueInput(value || "", "inventoryId");
              handleChangeValueInput("", "subInvId");
            }}
          />
        </Grid.Col>

        <Grid.Col span={{ base: 6, xs: 4, md: 2, lg: 1.4 }}>
          <Select
            searchable
            clearable
            size="xs"
            label="Kho"
            placeholder="Chọn kho"
            nothingFoundMessage={"Không có dữ liệu"}
            withAsterisk
            disabled={
              !dataInWard.tblItemTransactionHeaderCommand.sourceCode ||
              dataInWard.tblItemTransactionHeaderCommand.status === "LOCK"
            }
            data={dataInventory}
            value={
              dataInWard.tblItemTransactionHeaderCommand.subInvId?.toString() ||
              null
            }
            onChange={(value, option) =>
              handleChangeValueInput(value || "", "subInvId")
            }
          />
        </Grid.Col>

        <Grid.Col span={{ base: 6, xs: 4, md: 2, lg: 1.3 }}>
          <Select
            searchable
            clearable
            size="xs"
            label="Trạng thái"
            placeholder="Chọn trạng thái"
            disabled
            data={statusOption}
            value={
              dataInWard.tblItemTransactionHeaderCommand.status?.toString() ||
              null
            }
            onChange={(value, option) =>
              handleChangeValueInput(value || "", "status")
            }
          />
        </Grid.Col>
        <Grid.Col span={{ base: 6, xs: 4, md: 2, lg: 1.3 }}>
          <TextInput
            label="Người nhận"
            size="xs"
            disabled
            // value={getEmployeeName(dataInWard.createdBy || 0)}
          />
        </Grid.Col>

        <Grid.Col span={{ base: 10, xs: 8, md: 6, lg: 6 }}>
          <TextInput
            label="Diễn giải"
            size="xs"
            disabled={
              dataInWard.tblItemTransactionHeaderCommand.status === "LOCK"
            }
            value={
              dataInWard?.tblItemTransactionHeaderCommand.description || ""
            }
            onChange={(e) =>
              handleChangeValueInput(e.currentTarget.value, "description")
            }
          />
        </Grid.Col>
      </Grid>
      // </ScrollArea>
    );
  };

  /////////////////////////////////////////////////////////////////////////////////////////

  const [dataInWard, setDataInWard] = useState<TblInWard>({
    sourceId: 39,
    tblItemTransactionHeaderCommand: {
      transactionId: 0,
      transactionCode: "",
      transactionDate: "",
      sourceCode: "",
      sourceType: "",
      inventoryId: null,
      subInvId: null,
      departmentId: 0,
      status: "1",
      description: "",
      // atribute1: "",
      // atribute2: "",
      // atribute3: "",
      // atribute4: "",
      // atribute5: "",
      // atribute6: "",
      // atribute7: "",
      // atribute8: "",
      // atribute9: "",
      // atribute10: "",
      createBy: 0,
      createDate: "",
      createName: "",
      lastUpdateDate: "",
      lastUpdateBy: 0,
    },
    serials: [],
  });
  const [height, setHeight] = useState(0);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [userInfo, setUserInfo] = useState<any>();

  const statusOption = [
    { value: "NEW", label: "Tạo mới" },
    { value: "OPEN", label: "Lưu tạm" },
    { value: "LOCK", label: "Khoá" },
  ];
  //table state

  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [dataBranch, setDataBranch] = useState<ComboboxItem[]>([]);
  const [dataAllInventory, setDataAllInventory] = useState<ComboboxItem[]>([]);
  const [dataInventory, setDataInventory] = useState<ComboboxItem[]>([]);
  const entityOrderOutWard = {
    requestInventoryTransactionDetailModels: [],
    invCode: null,
    invId: null,
    invName: null,
    outWardCode: null,
    receiver: null,
    sourceCode: null,
    sourceId: null,
    subInvId: null,
    toAddess: null,
  };
  const [dataOrderDetail, setDataOrderDetail] =
    useState<OrderDetailOutWard>(entityOrderOutWard);
  const [serial, setSerial] = useState<string>("");
  const [dataSerialOnHand, setDataSerialOnHand] = useState<
    TblInventorySerialOrderDetailOnHandEdit[]
  >([]);
  const [checkDuplicateCode, setCheckDuplicateCode] = useState<any[]>([]);
  const [dataInventoryTransactionType, setDataInventoryTransactionType] =
    useState<ComboboxItem[]>([]);
  const [keySearch, setKeySearch] = useDebouncedState("", 500);
  const [dataItem, setDataItem] = useState<
    TblInventorySerialItemDetailOnHand[]
  >([]);
  const [isExceedQuantity, setIsExceedQuantity] = useState(false);

  //#region  table
  const headerRef = React.useRef<HTMLDivElement>(null);
  const contentRef = React.useRef<HTMLDivElement>(null);

  // useScanDetection({
  //   onComplete: (code) => {
  //     setSerial(code.toString());
  //     fetchDataSerialOnHand(serial);
  //   },
  //   minLength: 5,
  // });

  const titleSell = () => {
    return (
      <>
        <Group mt={-10} align="center" justify="space-between">
          <Flex
            gap="md"
            justify="flex-start"
            align="center"
            direction="row"
            wrap="wrap"
          >
            <_breadcrumb></_breadcrumb>
          </Flex>
          <Flex
            gap="md"
            justify={{ sm: "flex-start", lg: "flex-end" }}
            wrap="wrap"
            align="center"
            direction="row"
          >
            <Button
              leftSection={<IconArrowLeft size={14} />}
              color="red"
              size="xs"
              onClick={() => handleReturnPage()}
            >
              Quay lại
            </Button>
            <Button
              leftSection={<IconPlus size={14} />}
              variant="outline"
              color="blue"
              size="xs"
              onClick={() => {
                handleCreateInWard();
              }}
            >
              Chỉnh sửa phiếu nhập kho
            </Button>
          </Flex>
        </Group>
      </>
    );
  };

  const TableSelect = () => (
    <Table.ScrollContainer minWidth={500} h={300} type="native">
      <Table striped highlightOnHover stickyHeader>
        <Table.Thead>
          <Table.Tr>
            <Table.Th />
            <Table.Th>Mã sản phẩm</Table.Th>
            <Table.Th>Tên sản phẩm</Table.Th>
            <Table.Th>Số serial</Table.Th>
            <Table.Th>Tuổi tồn</Table.Th>
            <Table.Th>Kho</Table.Th>
            <Table.Th>Đơn vị tính</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {dataItem.map((item, index) => (
            <Table.Tr
              key={item.serial}
              bg={
                checkDuplicateCode.some((select) => select === item.serial)
                  ? "var(--mantine-color-blue-light)"
                  : undefined
              }
            >
              <Table.Td>
                <Button
                  size="xs"
                  variant="filled"
                  onClick={() => {
                    fetchDataSerialOnHand(item.serial);
                  }}
                  disabled={checkDuplicateCode.includes(item.serial)}
                >
                  {checkDuplicateCode.includes(item.serial)
                    ? "Đã chọn"
                    : "Chọn"}
                </Button>
              </Table.Td>
              <Table.Td>{item.itemCode}</Table.Td>
              <Table.Td>
                <Tooltip label={item.itemName}>
                  <Text size="sm" w={250} lineClamp={2} truncate="end">
                    {item.itemName}
                  </Text>
                </Tooltip>
              </Table.Td>
              <Table.Td>{item.serial}</Table.Td>
              {/* <Table.Td>{item.itemAge}</Table.Td>
              <Table.Td>
                {" "}
                <Tooltip label={item.subInvName}>
                  <Text size="sm" w={125} truncate="end">
                    {item.subInvName}
                  </Text>
                </Tooltip>
              </Table.Td>
              <Table.Td>{item.unit}</Table.Td> */}
            </Table.Tr>
          ))}
        </Table.Tbody>
        {dataItem.length < 1 && (
          <Table.Caption>Không tìm thấy sản phẩm!</Table.Caption>
        )}
      </Table>
    </Table.ScrollContainer>
  );

  const handleReturnPage = () => {
    navigate(-1);
  };

  const handleChangeValueInput = (value: string | boolean, key: string) => {
    setDataInWard((prevData) => ({
      ...prevData,
      tblItemTransactionHeaderCommand: {
        ...prevData.tblItemTransactionHeaderCommand,
        [key]: value,
      },
    }));
  };

  const getTypeName = (type: string) => {
    return dataInventoryTransactionType.find(
      (tradType) => tradType.value === type
    )?.label;
  };

  const getInventoryName = (id: number | null) => {
    return dataAllInventory.find(
      (inventory) => inventory.value === id?.toString()
    )?.label;
  };

  const fetchDataSerialOnHand = async (serialCode?: string) => {
    setIsLoading(true);
    setIsRefetching(true);
    let url = `?SourceCode=${
      dataInWard.tblItemTransactionHeaderCommand.sourceCode
    }&SourceType=${
      dataInWard.tblItemTransactionHeaderCommand.sourceType
    }&serial=${serialCode || serial}`;

    try {
      const response = await repositoryPos.get<
        MessageResponse<TblInventorySerialOrderDetailOnHand>
      >(`api/v1/TblInventorySerialOnHand/detail-on-hand-by-serial${url}`);

      if (response && response.success) {
        const responseData = response.data;

        if (responseData.warning) {
          NotificationExtension.Fails(responseData.warning);
        } else {
          setDataSerialOnHand((prevData) => [
            { ...responseData, quantity: 1 },
            ...prevData,
          ]);
          setCheckDuplicateCode((prev) => [...prev, serialCode || serial]);
          NotificationExtension.Success("Quét mã serial thành công");
        }
      } else {
        NotificationExtension.Fails(response?.message || "");
      }
    } catch (error) {
      setDataSerialOnHand([]);
      setIsError(true);
      console.error(error);
      return;
    }

    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
  };

  const fetchdataInventoryTransactionType = async () => {
    const response = await getTblInventoryTransactionTypeInWard();

    setDataInventoryTransactionType(
      response.map((type) => ({ value: type.value, label: type.text }))
    );
  };

  const handleAddProduct = () => {
    if (dataInWard.tblItemTransactionHeaderCommand.sourceCode) {
      if (checkDuplicateCode.length === 0) {
        fetchDataSerialOnHand();
        setSerial("");
      } else if (checkDuplicateCode.length > 0) {
        if (checkDuplicateCode.includes(serial)) {
          NotificationExtension.Fails("Mã Serial đã được quét !");
        } else {
          setCheckDuplicateCode((prev) => [...prev, serial]);
          fetchDataSerialOnHand();
          setSerial("");
        }
      }
    } else {
      NotificationExtension.Fails("Chưa nhập số chứng từ");
    }
  };

  const deleteItemSerial = (serial: string) => {
    setDataSerialOnHand((prevData) =>
      prevData.filter((item) => item.serial !== serial)
    );
    setCheckDuplicateCode((prevData) =>
      prevData.filter((item) => item !== serial)
    );
  };

  const handleCreateInWard = async (status?: string) => {
    if (dataInWard.tblItemTransactionHeaderCommand.subInvId) {
      if (!dataSerialOnHand.some((item) => item.warning)) {
        if (!isExceedQuantity) {
          let dataCreate;
          if (status) {
            handleChangeValueInput(status, "status");
            dataCreate = {
              ...dataInWard,
              tblItemTransactionHeaderCommand: {
                ...dataInWard.tblItemTransactionHeaderCommand,
                status: status,
              },
              serials: dataSerialOnHand
                .filter((item) => !item.type)
                .map((item) => ({
                  quantity: item.primaryQuantity,
                  serial: item.serial,
                  invId: item.invId,
                })),
            };
          } else {
            dataCreate = {
              ...dataInWard,
              serials: dataSerialOnHand
                .filter((item) => !item.type)
                .map((item) => ({
                  quantity: item.primaryQuantity,
                  serial: item.serial,
                  invId: item.invId,
                })),
            };
          }

          try {
            const response = await repositoryPos.post<MessageResponse<any>>(
              "/api/v1/Inward/in-ward",
              dataCreate
            );
            if (response?.httpStatusCode === 200) {
              if (response?.success) {
                if (!status) {
                  NotificationExtension.Success(
                    "Tạo phiếu nhập kho thành công"
                  );
                  navigate("/warehouse-config/warehouse-in-ward");
                } else if (status === "OPEN") {
                  NotificationExtension.Success(
                    "Lưu phiếu nhập kho thành công"
                  );
                  // navigate("/warehouse-config/warehouse-transaction");

                  // setDataSerialOnHand((prevData) =>
                  //   prevData.map((item) => ({ ...item, type: "scanned" }))
                  // );

                  fetchDataEdit();
                } else if (status === "LOCK") {
                  handleLockInWard(response.data);
                  // setDataSerialOnHand((prevData) =>
                  //   prevData.map((item) => ({ ...item, type: "scanned" }))
                  // );
                  fetchDataEdit();
                }
              } else {
                NotificationExtension.Fails("Tạo thất bại");
              }
            }
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        } else {
          NotificationExtension.Fails("Có sản phẩm vượt quá số lượng cần nhập");
        }
      } else {
        NotificationExtension.Fails("Có sản phẩm không hợp lệ");
      }
    } else {
      NotificationExtension.Fails("Vui lòng chọn kho");
    }
  };

  const handleLockInWard = async (invTransactionId?: string) => {
    try {
      const response = await repositoryPos.post<MessageResponse<any>>(
        `/api/v1/Inward/lock?InvTransactionId=${
          invTransactionId ||
          dataInWard.tblItemTransactionHeaderCommand.transactionId
        }`
      );
      if (response?.success) {
        handleChangeValueInput("LOCK", "status");
        NotificationExtension.Success("Khoá phiếu nhập kho thành công");

        // var res = SocketExtension.SendMessageToUserName<any>("kieuhv", {
        //   message: "Phiếu nhập kho",

        //   //  type: TypePushSocket.NotSelf,
        // });
        // console.log(res);
      }
    } catch (error) {}
  };

  const fetchDataItem = async () => {
    if (keySearch) {
      try {
        const response = await repositoryPos.get<
          TblInventorySerialItemDetailOnHand[]
        >(
          `api/v1/TblInventorySerialOnHand/detail-on-hand-by-item?Key=${keySearch}&Page=30`
        );

        setDataItem(response || []);
      } catch (error) {
        setDataItem([]);
      }
    }
  };

  const fetchDataBranch = async () => {
    const getDataBranch = await getBranchSelect();

    const mappedData = getDataBranch
      .filter(
        (item) => item.value != null && item.text != null && item.value !== "-1"
      )
      .map((item) => ({
        value: item.value,
        label: `${item.text}`,
      }));
    setDataBranch(mappedData);
  };

  const fetchdataAllInventory = async () => {
    const url = `/api/v1/TblDmInventory/get-all`;
    try {
      const getData = await repositoryDelivery.get<
        MessageResponse<TblDMInventory[]>
      >(url);
      if (getData?.data) {
        const filteredData = getData?.data;
        const mappedData = filteredData?.map((item) => ({
          value: item.id.toString(),
          label: `(${item.code}) ${item.name}`,
        }));
        setDataAllInventory(mappedData);
      } else {
        setDataAllInventory([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setDataAllInventory([]);
    }
  };

  const fetchdataInventory = async () => {
    const url = `/api/v1/TblDmInventory/get-select-by-branch?branchId=${dataInWard.tblItemTransactionHeaderCommand.inventoryId}`;
    try {
      const getDataInventory = await repositoryPos.get<
        MessageResponse<TblDmInventorySelectByBranch[]>
      >(url);
      if (getDataInventory?.data) {
        const filteredData = getDataInventory?.data;
        const mappedData = filteredData?.map((item) => ({
          value: item.inventoryId.toString(),
          label: `(${item.inventoryCode}) ${item.inventoryName}`,
        }));
        setDataInventory(mappedData);
      } else {
        setDataInventory([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setDataInventory([]);
    }
  };

  const fetchDataInWard = async () => {
    const url = `/api/v1/Inward/edit-in-ward?id=${id}`;
    try {
      const response = await repositoryPos.get<MessageResponse<any>>(url);
      if (response?.success) {
        setDataInWard((prevData) => ({
          ...prevData,
          tblItemTransactionHeaderCommand: {
            ...prevData.tblItemTransactionHeaderCommand,
            ...response.data,
          },
        }));
      } else {
        NotificationExtension.Fails("Bạn không có quyền !");
        navigate(-1);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchDataEdit = async () => {
    const url = `/api/v1/Inward/detail?TransactionId=${id}`;
    try {
      const response = await repositoryPos.get<
        MessageResponse<TblInwardDetail>
      >(url);
      if (response?.success && response?.data) {
        setDataInWard((prevData) => ({
          ...prevData,
          tblItemTransactionHeaderCommand:
            response?.data.tblInventoryTransactionModel,
        }));
        setDataOrderDetail((prevData) => ({
          ...prevData,
          requestInventoryTransactionDetailModels:
            response?.data.requestInventoryTransactionDetailModels.map(
              (order) => ({
                ...order,
                scannedQuantity: 0,
                remainQuantity:
                  (order.primaryQuantity || 0) -
                  (order.transactionQuantity || 0),
              })
            ),
        }));
        setDataSerialOnHand(
          response.data.serialOnHandForOutWard.map((item) => ({
            ...item,
            type: "scanned",
          })) || []
        );
        setCheckDuplicateCode((prev) => [
          ...prev,
          ...response.data.serialOnHandForOutWard
            .map((item) => item.serial)
            .filter((serial): serial is string => serial !== null),
        ]);
      } else {
        setDataOrderDetail(entityOrderOutWard);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setDataOrderDetail(entityOrderOutWard);
    }
  };

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        await Promise.all([
          fetchDataInWard(),
          fetchDataBranch(),
          fetchdataAllInventory(),
          fetchdataInventoryTransactionType(),
        ]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    const userLogin = localStorage.getItem("userLogin");
    if (userLogin) setUserInfo(JSON.parse(userLogin));

    fetchAllData();
  }, []);

  useEffect(() => {
    if (dataInWard.tblItemTransactionHeaderCommand.inventoryId) {
      fetchdataInventory();
    } else {
      setDataInventory([]);
    }
  }, [dataInWard.tblItemTransactionHeaderCommand.inventoryId]);

  // useEffect(() => {
  //   if (
  //     dataInWard.tblItemTransactionHeaderCommand.sourceCode &&
  //     dataInWard.tblItemTransactionHeaderCommand.sourceCode?.length > 15
  //   ) {
  //     fetchDataOrderDetail();
  //   }
  // }, [dataInWard.tblItemTransactionHeaderCommand.sourceCode]);

  useEffect(() => {
    if (keySearch && keySearch.length > 2) {
      fetchDataItem();
    } else {
      setDataItem([]);
    }
  }, [keySearch]);

  useEffect(() => {
    fetchDataEdit();
  }, [id]);

  useEffect(() => {
    const exceedQuantityFound =
      dataOrderDetail?.requestInventoryTransactionDetailModels.some((order) => {
        const matchingOrder = dataSerialOnHand
          .filter((item) => item.itemId === order.itemId && !item.type)
          .map((item) => item.primaryQuantity || 0); // Lấy số lượng và mặc định là 0 nếu undefined

        if (matchingOrder) {
          const totalQuantity = matchingOrder.reduce(
            (acc, curr) => acc + curr,
            0
          ); // Tính tổng số lượng

          const orderQuantity = order.primaryQuantity ?? 0;
          const orderTransferQuantity = order.transactionQuantity ?? 0;
          const remainQuantity =
            orderQuantity - (orderTransferQuantity + totalQuantity) || 0;

          return remainQuantity < 0;
        }

        return false;
      });

    setDataOrderDetail((prevData) => ({
      ...prevData,
      requestInventoryTransactionDetailModels:
        prevData.requestInventoryTransactionDetailModels.map((order) => {
          const quantities = dataSerialOnHand
            .filter((item) => item.itemId === order.itemId && !item.type)
            .map((item) => item.primaryQuantity || 0); // Lấy số lượng và mặc định là 0 nếu undefined

          const totalQuantity = quantities.reduce((acc, curr) => acc + curr, 0); // Tính tổng số lượng

          const orderQuantity = order.primaryQuantity ?? 0;
          const orderTransferQuantity = order.transactionQuantity ?? 0;
          const remainQuantity =
            orderQuantity - (orderTransferQuantity + totalQuantity) || 0;

          return {
            ...order,
            scannedQuantity: totalQuantity,
            remainQuantity: remainQuantity,
          };
        }),
    }));

    // Ensure the state is set after the mapping is complete
    if (exceedQuantityFound) {
      NotificationExtension.Fails("Sản phẩm vượt quá số lượng yêu cầu nhập");
    }

    setIsExceedQuantity(exceedQuantityFound || false);
  }, [dataSerialOnHand]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const contentHeight = contentRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 160 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (310 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "itemCode",
        header: "Mã hàng",
        size: 100,
      },
      {
        accessorKey: "itemName",
        header: "Tên hàng",
        Cell: ({ row }) => (
          <Tooltip label={row.original.itemName}>
            <Text size="sm" w={300}>
              {row.original.itemName}
            </Text>
          </Tooltip>
        ),
      },
      {
        accessorKey: "serial",
        header: "Số serial",
        size: 100,
      },
      {
        accessorKey: "uom",
        header: "Đơn vị tính",
        size: 100,
      },
      {
        accessorKey: "quantity",
        header: "Số lượng",
        size: 100,
      },
      {
        accessorKey: "inventory",
        header: "Kho",
        size: 100,
      },
      {
        // accessorKey: "",
        header: "Kho đến",
        size: 100,
      },
      {
        accessorKey: "serialStatus",
        header: "Tình trạng",
        size: 100,
      },
      {
        accessorKey: "warning",
        header: "Cảnh báo",
        Cell: ({ row }) =>
          !row.original.isMapping ? (
            <Tooltip label={row.original.warning}>
              <Text size="sm" w={300} c={"red"}>
                {row.original.warning}
              </Text>
            </Tooltip>
          ) : null,
      },

      {
        accessorKey: "action",
        header: "Thao tác",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
        size: 30,
        Cell: ({ renderedCellValue, row, table }) => (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            {!row.original.type && (
              <Tooltip label="Xóa">
                <ActionIcon
                  variant="light"
                  aria-label="Settings"
                  color="red"
                  onClick={async () => {
                    deleteItemSerial(row.original.serial);
                  }}
                >
                  <IconTrash size={20} stroke={1.5} />
                </ActionIcon>
              </Tooltip>
            )}
          </Box>
        ),
      },
    ],
    []
  );

  const columnsOrderDetail = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "sourceCode",
        header: "Mã số",
      },
      {
        accessorKey: "createDate",
        header: "Ngày lập",
        Cell: ({ renderedCellValue, row }) =>
          renderedCellValue
            ? moment(renderedCellValue as string).format("HH:mm DD-MM-YYYY")
            : "",
      },
      {
        accessorKey: "employeeName",
        header: "Người lập",
      },
      {
        accessorKey: "type",
        header: "Loại chứng từ",
        Cell: ({ renderedCellValue, row }) => (
          <Text size="sm">{getTypeName(row.original.type)}</Text>
        ),
      },
      {
        accessorKey: "itemCode",
        header: "Mã hàng",
      },

      {
        accessorKey: "fromSubInvId",
        header: "Kho đi",
        Cell: ({ renderedCellValue, row }) => (
          <Text size="sm">{getInventoryName(row.original.fromSubInvId)}</Text>
        ),
      },
      {
        accessorKey: "toSubInvId",
        header: "Kho đến",
        Cell: ({ renderedCellValue, row }) => (
          <Text size="sm">{getInventoryName(row.original.toSubInvId)}</Text>
        ),
      },

      {
        accessorKey: "uom",
        header: "Đơn vị tính",
      },
      {
        accessorKey: "quantity",
        header: "Số lượng",
      },
      {
        accessorKey: "scannedQuantity",
        header: "Số lượng đang quét",
        Cell: ({ renderedCellValue, row }) => (
          <Text size="sm">{renderedCellValue || 0}</Text>
        ),
      },
      {
        accessorKey: "transferQuantity",
        header: "Số lượng đã nhập",
      },
      {
        accessorKey: "remainQuantity",
        header: "Số lượng còn lại",
        Cell: ({ renderedCellValue, row }) => (
          <Text size="sm">
            {renderedCellValue ??
              (row.original.quantity || 0) -
                (row.original.transferQuantity || 0)}{" "}
            {renderedCellValue && Number(renderedCellValue) < 0 ? (
              <Text span c={"red"}>
                (Vượt quá số lượng cần nhập)
              </Text>
            ) : null}{" "}
          </Text>
        ),
      },
    ],
    []
  );

  const table = useMantineReactTable({
    columns,
    data: dataSerialOnHand,
    positionToolbarAlertBanner: "bottom",
    enableColumnActions: false,
    //add custom action buttons to top-left of top toolbar
    renderTopToolbarCustomActions: ({ table }) => (
      <div ref={headerRef} style={{ width: "70%" }}>
        <Grid>
          <Grid.Col span={{ base: 6, md: 4, lg: 3 }}>
            <TextInput
              flex={3}
              placeholder="Nhập mã vạch"
              size="xs"
              disabled={
                dataInWard.tblItemTransactionHeaderCommand.status === "LOCK"
              }
              value={anyAsciiCode(serial)}
              onChange={(e) => setSerial(e.currentTarget.value)}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 6, md: 2, lg: 1.5 }}>
            <Button
              flex={1.5}
              leftSection={<IconPlus size={14} />}
              size="xs"
              w={"fit-content"}
              onClick={handleAddProduct}
              disabled={
                serial?.length < 10 ||
                dataInWard.tblItemTransactionHeaderCommand.status === "LOCK"
              }
            >
              Thêm barcode
            </Button>
          </Grid.Col>
          <Grid.Col span={{ base: 6, md: 4, lg: 2 }}>
            <Menu
              trapFocus={false}
              trigger="hover"
              shadow="md"
              width={"800"}
              position="bottom-start"
            >
              <Menu.Target>
                <TextInput
                  placeholder="Hoặc tìm kiếm sản phẩm theo mã"
                  size="xs"
                  disabled={
                    dataInWard.tblItemTransactionHeaderCommand.status === "LOCK"
                  }
                  onChange={async (e) => {
                    const _key = e.target.value ?? "";
                    setKeySearch(_key);
                  }}
                />
              </Menu.Target>
              <Menu.Dropdown>
                <TableSelect />
              </Menu.Dropdown>
            </Menu>
          </Grid.Col>
        </Grid>
      </div>
    ),
    enableToolbarInternalActions: false,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    // enableRowSelection: true,
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select", "itemCode"],
        right: ["action"],
      },
      columnVisibility: { id: false, phone: false, fax: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height * 0.55 - 20, minHeight: height * 0.55 - 20 },
    },
    enableStickyHeader: true,
    enablePagination: false,
    enableBottomToolbar: false,
    onRowSelectionChange: setRowSelection,
    // manualFiltering: true,
    // manualPagination: true,
    // manualSorting: true,
    rowCount,
    // onColumnFiltersChange: setColumnFilters,
    // onGlobalFilterChange: setGlobalFilter,
    // onPaginationChange: setPagination,
    // onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        fontWeight: "normal", //conditional styling
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      //  pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no data
      //sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    // mantinePaginationProps: {
    //   showRowsPerPage: true,
    //   withEdges: true,
    //   rowsPerPageOptions: ["10", "50", "100"],
    // },
    // paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    // columnFilterDisplayMode: "popover",
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });

  const tableOrderDetail = useMantineReactTable({
    columns: columnsOrderDetail,
    data: dataOrderDetail?.requestInventoryTransactionDetailModels || [],
    positionToolbarAlertBanner: "bottom",
    //add custom action buttons to top-left of top toolbar
    enableTopToolbar: false,
    enableToolbarInternalActions: false,
    enableColumnActions: false,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    // enableRowSelection: true,
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select"],
        right: ["action"],
      },
      columnVisibility: { id: false, phone: false, fax: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: {
        maxHeight: height * 0.35,
        minHeight: height * 0.35,
      },
    },
    enableStickyHeader: true,
    enablePagination: false,
    enableBottomToolbar: false,
    onRowSelectionChange: setRowSelection,
    // manualFiltering: true,
    // manualPagination: true,
    // manualSorting: true,
    rowCount,
    // onColumnFiltersChange: setColumnFilters,
    // onGlobalFilterChange: setGlobalFilter,
    // onPaginationChange: setPagination,
    // onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        fontWeight: "normal", //conditional styling
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      //  pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no data
      //sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    // mantinePaginationProps: {
    //   showRowsPerPage: true,
    //   withEdges: true,
    //   rowsPerPageOptions: ["10", "50", "100"],
    // },
    // paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    // columnFilterDisplayMode: "popover",
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });

  //#endregion
  return (
    <Box>
      <div ref={headerRef}>{titleSell()}</div>
      <Fieldset legend={"Thông tin chung"} p={5}>
        {formOrderHeader()}
      </Fieldset>
      <Fieldset legend={"Thông tin chi tiết"} pb={10}>
        <MantineReactTable table={table} />
        <Group justify="end" mt="xs">
          <Button
            type="submit"
            color={sky_blue.base}
            disabled={
              dataInWard.tblItemTransactionHeaderCommand.status === "LOCK"
            }
            onClick={() => {
              // handleChangeValueInput("OPEN", "status");
              handleCreateInWard("OPEN");
            }}
          >
            Lưu tạm
          </Button>
          <Button
            type="button"
            color="red"
            disabled={
              dataInWard.tblItemTransactionHeaderCommand.status === "LOCK"
            }
            onClick={() => {
              // handleChangeValueInput("LOCK", "status");
              if (dataSerialOnHand.some((item) => !item.type)) {
                handleCreateInWard("LOCK");
              } else handleLockInWard();
            }}
          >
            Khoá
          </Button>
        </Group>
      </Fieldset>
      <Fieldset legend={"Thông tin chứng từ gốc"} pb={10}>
        <MantineReactTable table={tableOrderDetail} />
      </Fieldset>
    </Box>
  );
};
export default EditWarehouseInWard;
