import {
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  Grid,
  Group,
  LoadingOverlay,
  NumberFormatter,
  Radio,
  ScrollArea,
  Table,
  Tabs,
  Text,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { IconCheck, IconDevicesPc, IconWindow } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { repositoryPos } from "../../../../../_base/_const/_constVar";
import { NotificationExtension } from "../../../../../_base/extension/NotificationExtension";
import { orange, peach, sky_blue } from "../../../../../const/variables";
import { MessageResponse } from "../../../../../model/MessageResponse";
import {
  itemAddOnPromotionModel,
  itemGiftPromotionModel,
  itemPrimaryPromotionModel,
  TblItemPromotionModel,
} from "../../../../../model/TblItem";
import "./voucher.css";

interface VoucherProps {
  branchId: number;
  idItem: string;
  price: number;
  handleSelectProductAddOn: (product: itemAddOnPromotionModel) => Promise<void>;
}

const Voucher = ({
  idItem,
  branchId,
  price,
  handleSelectProductAddOn,
}: VoucherProps) => {
  const [visible, { toggle, open, close }] = useDisclosure(false);
  const [selectedPromotion, setSelectedPromotion] =
    useState<TblItemPromotionModel | null>(null);
  const [selectedItemPrimaryPromotion, setSelectedItemPrimaryPromotion] =
    useState<itemPrimaryPromotionModel | null>(null);
  const [selectedItemGiftPromotion, setSelectedItemGiftPromotion] = useState<
    itemGiftPromotionModel[]
  >([]);
  const [selectedItemAddOnPromotion, setSelectedItemAddOnPromotion] =
    useState<itemAddOnPromotionModel | null>(null);

  const [dataVoucher, setDataVoucher] = useState<TblItemPromotionModel[]>([]);

  const handleOptionChange = (option: TblItemPromotionModel) => {
    setSelectedPromotion(option);

    setSelectedItemPrimaryPromotion(option.tblPromotionItemPrimary[0]);
    setSelectedItemGiftPromotion(option.tblPromotionItemGift);
    // setSelectedItemAddOnPromotion(option.tblPromotionItemAddOn[0]);
  };

  const handleChangeItemGiftPromotion = (
    promotionItem: itemGiftPromotionModel
  ) => {
    setSelectedItemGiftPromotion((prevData) => {
      return prevData.includes(promotionItem)
        ? prevData.filter((item) => item !== promotionItem)
        : [...prevData, promotionItem];
    });
  };

  const handleSaveVoucher = () => {
    if (selectedItemAddOnPromotion) {
      handleSelectProductAddOn(selectedItemAddOnPromotion);
    }
    modals.closeAll();
  };

  useEffect(() => {
    const handleFetchDataVoucher = async () => {
      open();
      const response = await repositoryPos.post<MessageResponse<any>>(
        "/api/v1/TblItem/item-promotion",
        {
          id: idItem,
          price: price,
          branch: branchId,
        }
      );

      if (response && response.success) {
        let result = response.data;
        setDataVoucher(result || []);
        if (result?.length > 0) {
          setSelectedPromotion(result[0]);
          setSelectedItemPrimaryPromotion(result[0].tblPromotionItemPrimary[0]);
          setSelectedItemGiftPromotion(result[0].tblPromotionItemGift);
          // setSelectedItemAddOnPromotion(result[0].tblPromotionItemAddOn[0]);
        }

        // NotificationExtension.Success("Lấy thông tin khuyến mại thành công !");
      } else {
        NotificationExtension.Fails("Lấy thông tin khuyến mại thất bại !");
      }
      close();
    };

    if (idItem) {
      handleFetchDataVoucher();
    }
  }, [idItem, price, close, open]);

  console.log("selectedItemGiftPromotion", selectedItemGiftPromotion);

  return (
    <Box mih={550}>
      <LoadingOverlay
        visible={visible}
        zIndex={1000}
        overlayProps={{ radius: "sm", blur: 2 }}
      />

      <Grid>
        <Grid.Col span={{ base: 5, md: 4, xs: 4 }}>
          <ScrollArea h={530}>
            <Text size="md" fw={700} mb="xs">
              Chọn chính sách khuyến mãi:
            </Text>

            <Flex gap={10} direction={"column"} pb={15}>
              {/* dùng slice để chỉ lấy 1 chương trình đầu không cho chọn lại  */}
              {dataVoucher?.slice(0, 1)?.map((voucher) => (
                <Flex
                  align={"center"}
                  onClick={() => handleOptionChange(voucher)}
                  className="voucher-card"
                >
                  <Flex
                    justify={"center"}
                    align={"center"}
                    w={"30%"}
                    bg={peach.base}
                    direction={"column"}
                    py={10}
                  >
                    <Box
                      style={{
                        borderRadius: "50%",
                        backgroundColor: orange.vivid,
                        padding: 5,
                        color: "white",
                      }}
                    >
                      <IconDevicesPc size={50} />
                    </Box>
                    <Text c={"white"}> Điện tử</Text>
                  </Flex>

                  <Box w={"60%"} p={10}>
                    <Text fw={700} style={{ fontSize: 15 }}>
                      {voucher.tblPromotion.code}
                    </Text>
                    <Text c={"#AAAAAA"}>{voucher.tblPromotion.note}</Text>
                    <Text>
                      Giảm{" "}
                      {voucher.tblPromotionItemPrimary[0].discountPercent ? (
                        `${voucher.tblPromotionItemPrimary[0].discountPercent}%`
                      ) : (
                        <NumberFormatter
                          value={
                            voucher.tblPromotionItemPrimary[0].discountAmount ||
                            0
                          }
                          thousandSeparator="."
                          decimalSeparator=","
                          suffix="₫"
                        />
                      )}
                    </Text>
                  </Box>

                  <Box w={"10%"}>
                    <Radio checked={selectedPromotion === voucher} />
                  </Box>
                </Flex>
              ))}

              {dataVoucher.length < 1 && (
                <Text>Không có chính sách khuyến mãi</Text>
              )}
            </Flex>
          </ScrollArea>
        </Grid.Col>

        <Grid.Col span={{ base: 7, md: 8, xs: 8 }}>
          {selectedPromotion ? (
            <Tabs defaultValue="primary">
              <Tabs.List>
                <Tabs.Tab value="primary">Mặt hàng chính</Tabs.Tab>
                <Tabs.Tab value="gift">Mặt hàng quà tặng</Tabs.Tab>
                <Tabs.Tab value="addOn">Mặt hàng mua kèm</Tabs.Tab>
              </Tabs.List>

              <Tabs.Panel value="primary">
                <ScrollArea h={500}>
                  {/* <Divider my="sm" />
                <Text size="md" fw={700} mb="xs">
                  Chọn mặt hàng khuyến mãi:
                </Text> */}
                  <Table striped highlightOnHover stickyHeader>
                    <Table.Thead>
                      <Table.Tr>
                        {/* <Table.Th>Mã sản phẩm</Table.Th>
                <Table.Th>Tên sản phẩm</Table.Th> */}
                        <Table.Th>Id sản phẩm</Table.Th>
                        <Table.Th>% Chiết khấu</Table.Th>
                        <Table.Th>Tiền chiết khấu</Table.Th>
                        <Table.Th>Tiền sau chiết khấu</Table.Th>
                        <Table.Th></Table.Th>
                      </Table.Tr>
                    </Table.Thead>

                    <Table.Tbody>
                      {selectedPromotion?.tblPromotionItemPrimary.map(
                        (promotionItem) => (
                          <Table.Tr key={promotionItem.idItemPrimary}>
                            <Table.Td>{promotionItem.idItemPrimary}</Table.Td>
                            <Table.Td>
                              {promotionItem.discountPercent}%
                            </Table.Td>
                            <Table.Td>
                              <NumberFormatter
                                value={promotionItem.discountAmount || 0}
                                thousandSeparator="."
                                decimalSeparator=","
                                suffix="₫"
                              />
                            </Table.Td>
                            <Table.Td>
                              <Text span c={"red"}>
                                <NumberFormatter
                                  value={promotionItem.priceDiscount || 0}
                                  thousandSeparator="."
                                  decimalSeparator=","
                                  suffix="₫"
                                />
                              </Text>
                            </Table.Td>
                            <Table.Td>
                              <Radio
                                checked={
                                  selectedItemPrimaryPromotion === promotionItem
                                }
                                onChange={() =>
                                  setSelectedItemPrimaryPromotion(promotionItem)
                                }
                              />
                            </Table.Td>
                          </Table.Tr>
                        )
                      )}
                    </Table.Tbody>
                  </Table>
                </ScrollArea>
              </Tabs.Panel>

              <Tabs.Panel value="gift">
                <ScrollArea h={500}>
                  {/* <Divider my="sm" />
                <Text size="md" fw={700} mb="xs">
                  Chọn mặt hàng khuyến mãi:
                </Text> */}
                  <Table striped highlightOnHover stickyHeader>
                    <Table.Thead>
                      <Table.Tr>
                        <Table.Th>Mã sản phẩm</Table.Th>
                        <Table.Th>Tên sản phẩm</Table.Th>
                        <Table.Th>Đơn vị tính</Table.Th>
                        <Table.Th>Tồn kho</Table.Th>
                        <Table.Th>Kho</Table.Th>
                        <Table.Th></Table.Th>
                      </Table.Tr>
                    </Table.Thead>

                    <Table.Tbody>
                      {/* đang mặc định lấy sản phẩm tặng đầu tiên trong mảng */}
                      {selectedPromotion?.tblPromotionItemGift
                        .slice(0, 1)
                        .map((promotionItem) => (
                          <Table.Tr key={promotionItem?.idItemGift}>
                            <Table.Td>
                              {promotionItem?.itemForSellModel?.productCode}
                            </Table.Td>
                            <Table.Td>
                              {promotionItem?.itemForSellModel?.productName}
                            </Table.Td>
                            <Table.Td>
                              {promotionItem?.itemForSellModel?.unit}
                            </Table.Td>
                            <Table.Td>
                              {promotionItem?.itemForSellModel?.tonao}
                            </Table.Td>

                            <Table.Td>
                              {promotionItem?.itemForSellModel?.warehouse}
                            </Table.Td>
                            <Table.Td>
                              <Checkbox
                                checked={
                                  selectedItemGiftPromotion?.includes(
                                    promotionItem
                                  ) ?? false
                                }
                                onChange={
                                  () =>
                                    handleChangeItemGiftPromotion(promotionItem)
                                  // setSelectedItemGiftPromotion(promotionItem)
                                }
                              />
                            </Table.Td>
                          </Table.Tr>
                        ))}
                    </Table.Tbody>
                  </Table>
                </ScrollArea>
              </Tabs.Panel>

              <Tabs.Panel value="addOn">
                <ScrollArea h={500}>
                  {/* <Divider my="sm" />
            <Text size="md" fw={700} mb="xs">
              Chọn mặt hàng khuyến mãi:
            </Text> */}
                  <Table striped highlightOnHover stickyHeader>
                    <Table.Thead>
                      <Table.Tr>
                        <Table.Th>Mã sản phẩm</Table.Th>
                        <Table.Th>Tên sản phẩm</Table.Th>
                        <Table.Th>Đơn vị tính</Table.Th>
                        <Table.Th>% Chiết khấu</Table.Th>
                        <Table.Th>Tiền chiết khấu</Table.Th>
                        <Table.Th>Tồn kho</Table.Th>
                        <Table.Th></Table.Th>
                      </Table.Tr>
                    </Table.Thead>

                    <Table.Tbody>
                      {selectedPromotion?.tblPromotionItemAddOn
                        .filter(
                          (promotionItem) => promotionItem?.itemForSellModel
                        ) // Thêm điều kiện kiểm tra
                        .map((promotionItem) => (
                          <Table.Tr key={promotionItem?.idItemAddOn}>
                            <Table.Td>
                              {promotionItem?.itemForSellModel?.productCode}
                            </Table.Td>
                            <Table.Td>
                              {promotionItem?.itemForSellModel?.productName}
                            </Table.Td>
                            <Table.Td>
                              {promotionItem?.itemForSellModel?.unit}
                            </Table.Td>
                            <Table.Td>
                              {promotionItem?.discountPercent}%
                            </Table.Td>
                            <Table.Td>
                              <NumberFormatter
                                value={promotionItem.discountAmount || 0}
                                thousandSeparator="."
                                decimalSeparator=","
                                suffix="₫"
                              />
                            </Table.Td>
                            <Table.Td>
                              {promotionItem?.itemForSellModel?.tonao}
                            </Table.Td>
                            <Table.Td>
                              <Radio
                                checked={
                                  selectedItemAddOnPromotion?.id ===
                                  promotionItem.id
                                }
                                onChange={() =>
                                  setSelectedItemAddOnPromotion(promotionItem)
                                }
                              />
                            </Table.Td>
                          </Table.Tr>
                        ))}
                    </Table.Tbody>
                  </Table>
                </ScrollArea>
              </Tabs.Panel>
            </Tabs>
          ) : (
            <Box>
              <Center mt={"lg"}>Vui lòng chọn 1 chính sách khuyến mãi</Center>
            </Box>
          )}
        </Grid.Col>
      </Grid>

      {/* {selectedPromotion && selectedItemGiftPromotion && (
        <Text mt="md">
          You selected: {selectedPromotion.tblPromotion.code} -{" "}
        </Text>
      )} */}

      <Flex align={"center"} justify={"space-between"}>
        <Group mt="xs">
          <Text fw={700} mb="5" size="md">
            Tổng tiền được giảm:{" "}
            <Text span c={"red"} fw={700}>
              <NumberFormatter
                value={
                  selectedItemPrimaryPromotion
                    ? price -
                        (selectedItemPrimaryPromotion?.priceDiscount || 0) || 0
                    : 0
                }
                thousandSeparator="."
                decimalSeparator=","
                suffix="₫"
              />
            </Text>
          </Text>
        </Group>

        <Group
          justify="end"
          style={{
            position: "sticky",
            bottom: 0,
            backgroundColor: "white",
          }}
        >
          <Button
            type="button"
            color="gray"
            onClick={() => {
              modals.closeAll();
            }}
            leftSection={<IconWindow size={18} />}
          >
            Đóng
          </Button>
          <Button
            color={sky_blue.base}
            leftSection={<IconCheck size={18} />}
            onClick={() => handleSaveVoucher()}
          >
            Tiếp tục
          </Button>
          <></>
        </Group>
      </Flex>
    </Box>
  );
};

export default Voucher;
