import { BarChart, LineChart } from "@mantine/charts";
import { Grid } from "@mantine/core";
import { Text } from "@mantine/core";
import { MessageResponse } from "../../model/MessageResponse";
import { repositoryPos } from "../../_base/_const/_constVar";
import { useEffect, useState } from "react";
import { ChartModelPosModel } from "../../model/ChartModelPosModel";
import { AgCharts } from "ag-charts-react";
import { AgChartOptions, AgMapShapeSeriesOptions } from "ag-charts-enterprise";
import { AG_CHARTS_LOCALE_VI_VN } from "ag-charts-locale";
import "ag-charts-enterprise";
// import { topology } from "./_toplogy";
// import { quanhuyen } from "./quanhuyen";
interface RevenueData {
  CITY: string;
  TOTAL_REVENUE: number;
  TOTAL_REMAINING: number;
}

export interface Topology {
  type: string;
  features: Feature[];
}

export interface Feature {
  type: string;
  properties: Properties;
  geometry: Geometry;
}

export interface Properties {
  // [x: string]: any;
  gid: number;
  code: string;
  name: string;
  Ten_Tinh?: string;
  f_code?: string;
  Dan_So?: string;
  Nam_TK?: string;
}

export interface Geometry {
  type: string;
  coordinates: any;
}
export type Coordinate = [number, number];

const HomeView = () => {
  const [dataSale, setDataSale] = useState<ChartModelPosModel>();

  const getDataSaleOrder = async () => {
    try {
      const res = await repositoryPos.get<MessageResponse<ChartModelPosModel>>(
        "/api/v1/CreateSellItem/get-chart-sale"
      );
      if (res && res.success && res.data) {
        setDataSale(res.data);
      }
    } catch (error) {
      console.error(error);
      return;
    }
  };
  useEffect(() => {
    const initialize = async () => {
      await initMap();
      getDataSaleOrder();
      getDataProvice();
    };

    initialize();
  }, []);
  const [dataGdp, setDataGdp] = useState<RevenueData[]>([]);
  const [topology, settopology] = useState<Topology>();
  const [quanhuyen, setQuanHuyen] = useState<Topology>();

  async function initMap() {
    const _top = await fetch("/diaphantinh.geojson");
    const _to = await _top.json();
    if (_to) settopology(_to);

    const _quanhuyen = await fetch("/diaphanhuyen.geojson");
    const _quanhuyens = await _quanhuyen.json();
    if (_quanhuyens) setQuanHuyen(_quanhuyens);
  }

  async function getDataProvice() {
    try {
      const res = await repositoryPos.get<RevenueData[]>(
        "/api/v1/home/test-gdp"
      );
      setDataGdp(res ?? []);
      return res;
    } catch (error) {
      console.error(error);
      return;
    }
  }

  useEffect(() => {
    if (dataGdp && topology && quanhuyen) {
      // const _leg: AgMapShapeSeriesOptions<any>[] = dataGdp.map((d) => ({
      //   type: "map-shape",
      //   idKey: "name",
      //   colorKey: "gdp",
      //   title: d.CITY,
      //   tooltip: {
      //     renderer: ({ datum }) => ({
      //       title: datum.name,
      //       content: `Doanh thu: ${numberFormatter.format(datum.gdp)}`,
      //     }),
      //   },
      // }));
      setOptions5({
        title: {
          text: "Doanh thu theo tỉnh thành Việt Nam",
        },
        data: topology.features.map((d) => ({
          name: d.properties.name,
          code: d.properties.code,
          gdp:
            dataGdp?.find(
              (xxx) =>
                xxx.CITY?.toUpperCase() === d.properties.name?.toUpperCase()
            )?.TOTAL_REVENUE ?? Math.random() * 4e9,
        })),
        topology,
        series: [
          {
            type: "map-shape",
            //   legendItemName: "name",
            idKey: "name",
            colorKey: "gdp",
            //  title: "Pacific",
            //  labelKey: "name",
            tooltip: {
              renderer: ({ datum }) => ({
                title: datum.name,
                content: `Doanh thu: ${numberFormatter.format(datum.gdp)}`,
              }),
            },
          },
        ],
        gradientLegend: {
          enabled: true,
          position: "right",
          gradient: {
            preferredLength: 200,
            thickness: 20,
          },
          scale: {
            // interval: {
            //   minSpacing: 10,
            //   values: [0, 1e6, 2e6, 3e6, 4e6, 5e6],
            // },
            label: {
              fontSize: 10,
              formatter: ({ value }) => `${value}Đ`,
            },
          },
        },
        listeners: {
          // click: (e) => {
          //   console.log(e);
          // },
          seriesNodeClick(event) {
            const tinh = event.datum.name;
            const _dataClick = quanhuyen?.features.filter(
              (d) => d.properties.Ten_Tinh === tinh
            );

            if (tinh) {
              const _dataaa: Topology = {
                type: "FeatureCollection",
                features: _dataClick ?? [],
              };
              console.log(_dataClick);

              setOptions6({
                title: {
                  text: "Doanh thu theo quận huyện " + tinh + "",
                },
                data: _dataaa?.features.map((d) => ({
                  name: d.properties.name,
                  code: d.properties.code,
                  gdp: Math.random() * 4e6,
                })),
                topology: _dataaa,
                series: [
                  {
                    type: "map-shape",
                    idKey: "name",
                    colorKey: "gdp",
                    //  labelKey: "name",
                    tooltip: {
                      renderer: ({ datum }) => ({
                        title: datum.name,
                        content: `Doanh thu: ${numberFormatter.format(
                          datum.gdp
                        )}`,
                      }),
                    },
                  },
                ],
                gradientLegend: {
                  enabled: true,
                  position: "right",
                  gradient: {
                    preferredLength: 200,
                    thickness: 20,
                  },
                  scale: {
                    interval: {
                      minSpacing: 10,
                      values: [0, 1e6, 2e6, 3e6, 4e6, 5e6],
                    },
                    label: {
                      fontSize: 10,
                      formatter: ({ value }) => `${value}Đ`,
                    },
                  },
                },
                listeners: {
                  // click: (e) => {
                  //   console.log(e);
                  // },
                  seriesNodeClick(event) {
                    console.log(event);
                  },
                },
              });
            }
          },
        },
        contextMenu: {
          enabled: true,
        },
        zoom: {
          enabled: true,
          enableAxisDragging: true,
        },
        legend: {
          enabled: false,
          // item: {
          //   label: {
          //     fontSize: 14,
          //     fontFamily: "Papyrus",
          //     color: "red",
          //     maxLength: 12,
          //     formatter: ({ value }) =>
          //       value == "Coal" ? value + " *" : value,
          //   },
          // },
        },
        locale: {
          localeText: AG_CHARTS_LOCALE_VI_VN,
        },
      });

      setOptions6({
        title: {
          text: "Doanh thu theo quận huyện Hà Nội ",
        },
        data: {
          type: "FeatureCollection",
          features:
            quanhuyen?.features.filter(
              (d) => d.properties.Ten_Tinh === "Hà Nội"
            ) ?? [],
        }?.features.map((d) => ({
          name: d.properties.name,
          code: d.properties.code,
          gdp: Math.random() * 4e6,
        })),
        topology: {
          type: "FeatureCollection",
          features:
            quanhuyen?.features.filter(
              (d) => d.properties.Ten_Tinh === "Hà Nội"
            ) ?? [],
        },
        series: [
          {
            type: "map-shape",
            idKey: "name",
            colorKey: "gdp",
            //  labelKey: "name",
            tooltip: {
              renderer: ({ datum }) => ({
                title: datum.name,
                content: `Doanh thu: ${numberFormatter.format(datum.gdp)}`,
              }),
            },
          },
        ],
        gradientLegend: {
          enabled: true,
          position: "right",
          gradient: {
            preferredLength: 200,
            thickness: 20,
          },
          scale: {
            // interval: {
            //   minSpacing: 10,
            //   values: [0, 1e6, 2e6, 3e6, 4e6, 5e6],
            // },
            label: {
              fontSize: 10,
              formatter: ({ value }) =>
                new Intl.NumberFormat("vi-VN", {
                  style: "currency",
                  currency: "VND",
                }).format(value),
            },
          },
        },
        listeners: {
          seriesNodeClick(event) {
            console.log(event);
          },
        },
      });
    }
  }, [dataGdp]);

  const numberFormatter = new Intl.NumberFormat("vi-VN", {
    style: "currency",
    currency: "VND",
    useGrouping: true,
  });

  const [options5, setOptions5] = useState<AgChartOptions>({
    data: [],
  });

  const [dataDep, setDataDep] = useState<any>([]);

  const [options6, setOptions6] = useState<AgChartOptions>({
    data: [],
  });

  return (
    <>
      <Grid h={"100vh"} mt={"xs"}>
        <Grid.Col span={{ base: 12, md: 6 }}>
          {dataGdp.length > 0 && (
            <AgCharts
              style={{ height: "850px", width: "100%" }}
              options={options5}
            />
          )}
        </Grid.Col>

        <Grid.Col span={{ base: 12, md: 6 }}>
          <AgCharts
            style={{ height: "850px", width: "100%" }}
            options={options6}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6 }}>
          <Text fw={"bold"} mb="md" pl="md">
            Danh sách đơn hàng theo tháng
          </Text>
          <BarChart
            h={300}
            yAxisProps={{ tickMargin: 15, orientation: "right" }}
            data={dataSale?.saleOrder ?? []}
            dataKey="month"
            valueFormatter={(value: any) =>
              new Intl.NumberFormat("en-US").format(value)
            }
            withBarValueLabel
            series={[
              { name: "onlineOrders", color: "violet.6", label: "Đơn Online" },
              //  { name: "officeOrders", color: "blue.6" },
              { name: "officeOrders", color: "teal.6", label: "Đơn Office" },
              { name: "returnOrders", color: "cyan.6", label: "Đơn đổi trả" },
            ]}
            tickLine="x"
            legendProps={{ verticalAlign: "top", height: 50 }}
            withLegend
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 1 }}></Grid.Col>
        <Grid.Col span={{ base: 12, md: 5 }}>
          <Text fw={"bold"} mb="md" pl="md">
            Danh sách tổng tiền
          </Text>
          <LineChart
            h={300}
            data={dataSale?.saleOrderAmount ?? []}
            dataKey="month"
            series={[
              {
                name: "amountTotal",
                color: "indigo.6",
                label: "Tổng tiền thanh toán",
              },
              {
                name: "amountPaid",
                color: "blue.6",
                label: "Tổng tiền đã trả",
              },
              {
                name: "amountDiscount",
                color: "teal.6",
                label: "Tổng tiền chiết khấu",
              },
            ]}
            valueFormatter={(value: any) =>
              new Intl.NumberFormat("en-US").format(value)
            }
            // type="gradient"
            // gradientStops={[
            //   { offset: 0, color: "red.6" },
            //   { offset: 20, color: "orange.6" },
            //   { offset: 40, color: "yellow.5" },
            //   { offset: 70, color: "lime.5" },
            //   { offset: 80, color: "cyan.5" },
            //   { offset: 100, color: "blue.5" },
            // ]}
            // strokeWidth={5}
            curveType="linear"
            legendProps={{ verticalAlign: "top", height: 50 }}
            withLegend
          />
        </Grid.Col>

        <Grid.Col span={{ base: 12, md: 6 }}>
          <Text fw={"bold"} mb="md" pl="md">
            Danh sách nhân viên xuất sắc
          </Text>
          <BarChart
            h={300}
            data={dataSale?.employeeList ?? []}
            dataKey="employeeName"
            valueFormatter={(value: any) =>
              new Intl.NumberFormat("en-US").format(value)
            }
            yAxisProps={{ tickMargin: 15, orientation: "right" }}
            withBarValueLabel
            series={[
              {
                name: "totalRevenue",
                color: "violet.6",
                label: "Tổng tiền bán được",
              },
              //  { name: "officeOrders", color: "blue.6" },
              //  { name: "officeOrders", color: "teal.6", label: "Đơn Office" },
            ]}
            tickLine="x"
            legendProps={{ verticalAlign: "top", height: 50 }}
            withLegend
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 1 }}></Grid.Col>
        <Grid.Col span={{ base: 12, md: 5 }}>
          <Text fw={"bold"} mb="md" pl="md">
            Danh sách kho bán nhiều nhất
          </Text>
          <BarChart
            h={300}
            data={dataSale?.inventoryList ?? []}
            dataKey="warehouseName"
            valueFormatter={(value: any) =>
              new Intl.NumberFormat("en-US").format(value)
            }
            // yAxisProps={{ tickMargin: 15, orientation: "right" }}
            withBarValueLabel
            series={[
              {
                name: "totalOrders",
                color: "teal.6",
                label: "Tổng tiền bán được",
              },
              //  { name: "officeOrders", color: "blue.6" },
              //  { name: "officeOrders", color: "teal.6", label: "Đơn Office" },
            ]}
            tickLine="x"
            legendProps={{ verticalAlign: "top", height: 50 }}
            withLegend
          />
        </Grid.Col>
      </Grid>
    </>
  );
};

export default HomeView;
