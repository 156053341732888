import {
  Box,
  Button,
  Checkbox,
  Flex,
  Grid,
  Group,
  Image,
  NumberInput,
  Select,
  Text,
  TextInput,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { hasLength, useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { IconCheck, IconWindow } from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import {
  repositoryDelivery,
  repositoryMdm,
} from "../../../../_base/_const/_constVar";
import { sky_blue } from "../../../../const/variables";
import { ComboboxItem } from "../../../../model/ComboboxItem";
import { MessageResponse } from "../../../../model/MessageResponse";
import { TblDeliveryInformation } from "../../../../model/TblDmDelivery";
import { TblDMDeliveryDepartment } from "../../../../model/TblDMDeliveryDepartment";
import {
  getCommuneSelect,
  getDistrictSelect,
  getProvinceSelect,
} from "../../../../service/getSelectApi";
import {
  takeDeliveryfeeSCA,
  takeDeliveryfeeViettel,
  takeDistrictId,
} from "../../../../api/apiDeliveryPartner";
import style from "./Delivery.module.css";
import VTP from "../../../../assets/images/VTP.png";
import SCA from "../../../../assets/images/SCAlogo.png";

const ModalDeliveryInformation = ({
  customerCheck,
  dataDeliveryInformation,
  setDataDeliveryInformation,
  shipping,
  readOnly,
}: {
  dataDeliveryInformation: any;
  setDataDeliveryInformation: React.Dispatch<React.SetStateAction<any>>;
  customerCheck?: any;
  shipping?: string;
  shipper?: string;
  readOnly?: boolean;
}) => {
  const [visible] = useDisclosure(false);
  const [dataTblProvinceSelect, setDataTblProvinceSelect] = useState<
    ComboboxItem[]
  >([]);
  const [dataTblDistrictSelect, setDataTblDistrictSelect] = useState<
    ComboboxItem[]
  >([]);
  const [dataTblCommuneSelect, setDataTblCommuneSelect] = useState<
    ComboboxItem[]
  >([]);
  const [dataDeliveryDepartment, setDataDeliveryDepartment] = useState<
    ComboboxItem[]
  >([]);
  const [dataCommuneName, setDataCommuneName] = useState<ComboboxItem[]>([]);
  const [idCommune, setIdCommune] = useState("");

  console.log("dataDeliveryInformation", dataDeliveryInformation);

  const entity: TblDeliveryInformation = {
    customerId: dataDeliveryInformation?.customerId
      ? dataDeliveryInformation?.customerId
      : customerCheck?.id,
    customerName: dataDeliveryInformation?.customerName
      ? dataDeliveryInformation?.customerName
      : customerCheck?.name,
    telephoneNumber: dataDeliveryInformation?.telephoneNumber
      ? dataDeliveryInformation?.telephoneNumber
      : customerCheck?.telephoneNumber,
    customerAddress: dataDeliveryInformation?.customerAddress
      ? dataDeliveryInformation?.customerAddress
      : customerCheck?.address,
    customerProvinceId: dataDeliveryInformation?.customerProvinceId
      ? dataDeliveryInformation?.customerProvinceId
      : customerCheck?.province?.toString(),
    customerDistrictId: dataDeliveryInformation?.customerDistrictId
      ? dataDeliveryInformation?.customerDistrictId
      : customerCheck?.district?.toString(),
    customerCommuneId: dataDeliveryInformation?.customerCommuneId
      ? dataDeliveryInformation?.customerCommuneId
      : customerCheck?.commune?.toString(),
    depId: dataDeliveryInformation?.depId,
    deliveryAppointmentTime:
      dataDeliveryInformation?.deliveryAppointmentTime || new Date(),

    description: dataDeliveryInformation?.description,
    timeDelivery: dataDeliveryInformation?.timeDelivery,
    sourceType: 1,
    sourceCode: dataDeliveryInformation?.sourceCode,
    status: dataDeliveryInformation?.status,
    communeName: dataDeliveryInformation?.roadId,
    weight: dataDeliveryInformation?.weight,
    width: dataDeliveryInformation?.width,
    length: dataDeliveryInformation?.length,
    height: dataDeliveryInformation?.height,
    feeShip: dataDeliveryInformation?.feeShip,
  };

  const form = useForm<TblDeliveryInformation>({
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },

    validate: {
      customerName: (value: string | null) => {
        const validChars = /^[a-zA-Z\s\u00C0-\u024F\u1E00-\u1EFF]+$/;
        if (!value) {
          return "Vui lòng nhập tên người nhận !";
        }
        if (!validChars.test(value)) {
          return "Tên người nhận không được chứa các ký tự đặc biệt, và số !";
        }
        if (value.length > 50) {
          return "Tên người nhận không được dài hơn 50 ký tự !";
        }
        return null;
      },
      telephoneNumber: (value: string | null) => {
        if (value && !/^0\d{9,10}$/.test(value)) {
          return "Số điện thoại không hợp lệ!";
        }

        if (!value) {
          return "Vui lòng nhập số điện thoại!";
        }
        return null;
      },
      customerProvinceId: (value: number | null) => {
        if (!value) {
          return "Vui lòng chọn tỉnh/thành phố !";
        }
      },
      customerDistrictId: (value: number | null) => {
        if (!value) {
          return "Vui lòng chọn quận/huyện !";
        }
      },
      customerCommuneId: (value: number | null) => {
        if (!value) {
          return "Vui lòng chọn phường/xã !";
        }
      },
      customerAddress: (value: string | null) => {
        if (!value) {
          return "Vui lòng nhập địa chỉ cụ thể !";
        }
      },
      depId: (value: number | null) => {
        if (!value && shipping !== "Đối tác vận chuyển") {
          return "Vui lòng nhập bộ phận giao vận !";
        }
      },
      deliveryAppointmentTime: (value: string | null) => {
        if (!value) {
          return "Vui lòng nhập thời gian giao hàng !";
        }
      },
    },
  });

  const handleKeyDown = (event: any) => {
    if (event.key === "-") {
      event.preventDefault();
    }
  };

  const handleChangeSelectedProvince = (id: string | null) => {
    if (id) {
      form.getInputProps("customerProvinceId").onChange(id);
      form.getInputProps("customerDistrictId").onChange(null);
      form.getInputProps("customerCommuneId").onChange(null);
      form.getInputProps("roadId").onChange(null);
    }
  };

  const handleChangeSelectedDistrict = (id: string | null) => {
    if (id) {
      form.getInputProps("customerDistrictId").onChange(id);
      form.getInputProps("customerCommuneId").onChange(null);
      form.getInputProps("roadId").onChange(null);
    }
  };

  const handleChangeSelectedCommune = (id: string | null) => {
    if (id) {
      setIdCommune(id);
      form.getInputProps("customerCommuneId").onChange(id);
      form.getInputProps("roadId").onChange(null);
    }
  };

  const handleSaveForm = (dataSubmit: any) => {
    if (shipping === "Đối tác vận chuyển" && idActive === 2) {
      fetchDataDeliverySCA();
    }
    if (shipping === "Đối tác vận chuyển" && idActive === 1) {
      fetchDataDeliveryViettel();
    }

    setDataDeliveryInformation({
      ...dataSubmit,
      idActive,
      customerId: customerCheck?.id,
    });
    modals.closeAll();
  };

  const fetchDataProvinceSelect = async () => {
    const getData = await getProvinceSelect();
    setDataTblProvinceSelect(
      getData
        ?.filter((item) => item.value != null && item.text != null)
        ?.map((item) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const fetchDataDistrictSelect = async () => {
    const getData = await getDistrictSelect(
      form.values.customerProvinceId?.toString() ?? ""
    );
    setDataTblDistrictSelect(
      getData
        ?.filter((item) => item.value != null && item.text != null)
        ?.map((item) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const fetchDataCommuneSelect = async () => {
    const getData = await getCommuneSelect(
      form.values.customerDistrictId?.toString() ?? ""
    );
    setDataTblCommuneSelect(
      getData
        ?.filter((item) => item.value != null && item.text != null)
        ?.map((item) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const fetchDataDeliveryDepartment = async () => {
    const url = `/api/v1/TblDmDeliveryDepartment/get-all`;
    try {
      const getData = await repositoryMdm.get<
        MessageResponse<TblDMDeliveryDepartment[]>
      >(url);
      if (getData?.data) {
        const filteredData = getData?.data;
        const mappedData = filteredData?.map((item) => ({
          value: item.id.toString(),
          label: item.ddName || "",
        }));
        setDataDeliveryDepartment(mappedData);
      } else {
        setDataDeliveryDepartment([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setDataDeliveryDepartment([]);
    }
  };

  const fetchDataTransactionNumber = async () => {
    const url = `/api/v1/TblDelivery/create`;
    try {
      const getData = await repositoryDelivery.get<MessageResponse<any>>(url);
      if (getData?.data) {
      } else {
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchDataProvinceSelect();
    fetchDataDeliveryDepartment();
    fetchDataTransactionNumber();
  }, []);

  useEffect(() => {
    if (form.values.customerProvinceId) {
      fetchDataDistrictSelect();
    }
  }, [form.values.customerProvinceId, customerCheck]);

  useEffect(() => {
    if (form.values.customerDistrictId) {
      fetchDataCommuneSelect();
    }
  }, [form.values.customerDistrictId, customerCheck]);

  const handleConvertData = (data: any) => {
    const array = data?.map((item: any) => ({
      value: item?.value,
      label: item?.text,
    }));
    return array;
  };

  useEffect(() => {
    if (idCommune) {
      const handleFetch = async () => {
        const url = `/api/v1/Street/get-select?id=${idCommune}`;
        const searchResult = await repositoryMdm.get<MessageResponse<any>>(url);
        setDataCommuneName(handleConvertData(searchResult?.data));
      };

      handleFetch();
    }
  }, [idCommune]);

  // logic lấy thông tin giao vận bên thứ 3

  const [idActive, setIdActive] = useState(
    dataDeliveryInformation?.idActive ?? 1
  );
  const [dataAllDistrictSCA, setDataAllDistrictSCA] = useState<any[]>([]);
  const [dataSCA, setDataSCA] = useState<any[]>([]);

  const dataDelivery = [
    { icon: VTP, title: "Viettel Post", disable: true, id: 1 },
    {
      icon: SCA,
      title: "SCA Express",
      disable: true,
      id: 2,
    },
  ];

  const fetchDataDeliverySCA = async () => {
    const nameDistrict = dataTblDistrictSelect.find((item) => {
      return item.value === form.values.customerDistrictId?.toString();
    })?.label;
    const receiver_district_id = dataAllDistrictSCA.find((item) => {
      return item.district_name === nameDistrict;
    })?.district_id;
    const dataDeliveryFee = {
      money_collection: 0,
      product_price: 1000000,
      product_type: "HH",
      quantity: 1,
      receiver_district_id: receiver_district_id,
      receiver_province_id: 1,
      sender_district_id: 7,
      sender_province_id: 1,
      weight: 5000,
    };
    try {
      const data = await takeDeliveryfeeSCA(dataDeliveryFee);
      setDataSCA(data?.data);
      if (data?.data) {
        setDataDeliveryInformation((prev: any) => ({
          ...prev,
          deliveryFee: data?.data[0]?.fee,
        }));
      }
    } catch (error) {}
  };

  const fetchDataDeliveryViettel = async () => {
    let receviverAddress = "";

    const nameDistrict = dataTblDistrictSelect.find((item) => {
      return item.value === form.values.customerDistrictId?.toString();
    })?.label;

    const nameProvince = dataTblProvinceSelect.find((item) => {
      return item.value === form.values.customerProvinceId?.toString();
    })?.label;

    const nameCommune = dataTblCommuneSelect.find((item) => {
      return item.value === form.values.customerCommuneId?.toString();
    })?.label;

    receviverAddress = `${nameCommune}, ${nameDistrict}, ${nameProvince}`;

    const dataDeliveryFee = {
      token:
        "eyJhbGciOiJFUzI1NiJ9.eyJVc2VySWQiOjE0NTAyMzIzLCJGcm9tU291cmNlIjo1LCJUb2tlbiI6IlZDVUlHRjBOM0lTV1czUTExQlZSIiwiZXhwIjoxNzE3MjA4NzQyLCJQYXJ0bmVyIjotMX0.YxN6N0ZPpQzvbjvSwnEvPsCiloqQofwapYtaa1KJTNU4D2Krp7uGs7W4BJBHhci73A0SN0Cx3kgl0mv0DBw2-",
      model: {
        producT_WEIGHT: 1000,
        producT_PRICE: 1000000,
        moneY_COLLECTION: 0,
        ordeR_SERVICE_ADD: "",
        ordeR_SERVICE: "VCBO",
        sendeR_ADDRESS: "Hai Bà Trưng, Hà Nội",
        receiveR_ADDRESS: receviverAddress,
        producT_LENGTH: 0,
        producT_WIDTH: 0,
        producT_HEIGHT: 0,
        producT_TYPE: "HH",
        nationaL_TYPE: 1,
      },
    };
    try {
      const data = await takeDeliveryfeeViettel(dataDeliveryFee);
      setDataDeliveryInformation((prev: any) => ({
        ...prev,
        deliveryFee: data?.data?.MONEY_TOTAL,
      }));
    } catch (error) {}
  };

  const handleGetProvinceSCA = async () => {
    const data = await takeDistrictId("1");
    setDataAllDistrictSCA(data?.data);
  };

  useEffect(() => {
    handleGetProvinceSCA();
  }, [shipping]);

  return (
    <Box
      component="form"
      onSubmit={form.onSubmit((values) => handleSaveForm(values))}
      style={{
        position: "relative",
        pointerEvents: readOnly ? "none" : "auto",
      }}
    >
      <Grid mt={10}>
        {shipping === "Đối tác vận chuyển" && (
          <div className={style.typeDeliveryBox}>
            {dataDelivery.map((item, index) => (
              <Grid.Col span={5}>
                <Flex
                  justify={"center"}
                  align={"center"}
                  key={index}
                  onClick={() => setIdActive(item?.id)}
                  className={`${style.delivery} 
            ${idActive === item?.id && style.deliveryActive} 
            ${item.disable === true && style.disable}`}
                >
                  <Flex
                    justify={"center"}
                    align={"center"}
                    className={style.deliveryIcon}
                  >
                    {item.icon && (
                      <Image
                        className={style.iconstyle}
                        src={item.icon}
                        alt="iconStandardDelivery"
                      />
                    )}
                  </Flex>
                  <p className={style.deliveryTitle}>{item.title}</p>

                  <IconCheck
                    className={style.checkMark}
                    color="#FFFFFF"
                    size={14}
                  />
                </Flex>
              </Grid.Col>
            ))}
          </div>
        )}

        <Grid.Col span={4}>
          <TextInput
            withAsterisk
            label={"Người nhận hàng"}
            placeholder={"Nhập tên"}
            defaultValue={customerCheck?.name}
            type="text"
            {...form.getInputProps("customerName")}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <TextInput
            withAsterisk
            label={"Số điện thoại"}
            placeholder={"Nhập sđt"}
            defaultValue={customerCheck?.telephoneNumber}
            type="number"
            {...form.getInputProps("telephoneNumber")}
          />
        </Grid.Col>

        <Grid.Col span={4}>
          <Select
            withAsterisk
            label={"Tỉnh/Thành phố"}
            placeholder={"Chọn tỉnh/thành phố"}
            searchable
            data={dataTblProvinceSelect}
            nothingFoundMessage={"Không có dữ liệu"}
            {...form.getInputProps("customerProvinceId")}
            onChange={(e) => handleChangeSelectedProvince(e)}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <Select
            withAsterisk
            disabled={
              !form.values.customerProvinceId ||
              dataTblDistrictSelect.length === 0
            }
            label={"Quận/Huyện"}
            placeholder={"Chọn Quận/Huyện"}
            searchable
            data={dataTblDistrictSelect}
            nothingFoundMessage={"Không có dữ liệu"}
            {...form.getInputProps("customerDistrictId")}
            onChange={(e) => handleChangeSelectedDistrict(e?.toString() ?? "")}
          />
        </Grid.Col>

        <Grid.Col span={4}>
          <Select
            withAsterisk
            disabled={
              !form.values.customerDistrictId ||
              dataTblCommuneSelect.length === 0
            }
            label={"Phường/Xã"}
            placeholder={"Chọn Phường/Xã"}
            searchable
            data={dataTblCommuneSelect}
            nothingFoundMessage={"Không có dữ liệu"}
            {...form.getInputProps("customerCommuneId")}
            onChange={(e) => handleChangeSelectedCommune(e)}
          />
        </Grid.Col>
        {/* <Grid.Col span={4}>
          <Select
            searchable
            clearable
            disabled={dataCommuneName.length === 0}
            label="Chọn tên đường"
            placeholder="Chọn tên đường"
            data={dataCommuneName}
            {...form.getInputProps("roadId")}
          />
        </Grid.Col> */}

        <Grid.Col span={4}>
          <TextInput
            withAsterisk
            label={"Địa chỉ cụ thể"}
            placeholder={"Nhập địa chỉ"}
            type="text"
            {...form.getInputProps("customerAddress")}
          />
        </Grid.Col>

        {shipping === "Đối tác vận chuyển" ? (
          // <Grid.Col span={4}>
          //   <Select
          //     withAsterisk
          //     searchable
          //     clearable
          //     label="Đơn vị giao hàng"
          //     placeholder="Chọn đơn vị"
          //     data={["Viettel post", "SCA"]}
          //     {...form.getInputProps("depId")}
          //   />
          // </Grid.Col>
          <></>
        ) : (
          <Grid.Col span={4}>
            <Select
              withAsterisk
              searchable
              clearable
              label="Bộ phận giao hàng"
              placeholder="Chọn bộ phận"
              data={dataDeliveryDepartment}
              {...form.getInputProps("depId")}
            />
          </Grid.Col>
        )}
        <Grid.Col span={4}>
          <DatePickerInput
            label={"Thời gian giao hàng"}
            withAsterisk
            placeholder={"Nhập thời gian giao hàng"}
            valueFormat="DD/MM/YYYY"
            minDate={new Date()}
            {...form.getInputProps("deliveryAppointmentTime")}
            clearable
          />
          {/* <Select
            withAsterisk
            searchable
            clearable
            label="Thời gian giao hàng"
            placeholder="Chọn thời gian"
            data={["Trong giờ hành chính", "Ngoài giờ hành chính"]}
            {...form.getInputProps("deliveryAppointmentTime")}
          /> */}
        </Grid.Col>
        <Grid.Col span={4}>
          <Select
            searchable
            clearable
            label="Chọn khung giờ giao hàng"
            placeholder="Chọn khung giờ"
            data={[
              "Giờ hành chính",
              "Ngoài giờ hành chính",
              "Tất cả các ngày trong tuần",
            ]}
            {...form.getInputProps("timeDelivery")}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <NumberInput
            min={0}
            label="Trọng lượng (gr)"
            placeholder="Nhập trọng lượng"
            thousandSeparator=","
            hideControls
            onKeyDown={handleKeyDown}
            {...form.getInputProps("weight")}
          />
        </Grid.Col>
        <Grid.Col span={8}>
          <Flex align={"end"} direction={"row"}>
            <NumberInput
              min={0}
              label="Kích thước (cm)"
              placeholder="Chiều dài"
              thousandSeparator=","
              hideControls
              onKeyDown={handleKeyDown}
              {...form.getInputProps("length")}
            />
            <NumberInput
              mx={"xs"}
              min={0}
              placeholder="Chiều rộng"
              thousandSeparator=","
              hideControls
              onKeyDown={handleKeyDown}
              {...form.getInputProps("width")}
            />
            <NumberInput
              min={0}
              placeholder="Chiều cao"
              thousandSeparator=","
              hideControls
              onKeyDown={handleKeyDown}
              {...form.getInputProps("height")}
            />
          </Flex>
        </Grid.Col>
        <Grid.Col span={4}>
          <NumberInput
            disabled
            min={0}
            value={0}
            label="Phí vận chuyển"
            // placeholder="Nhập phí vận chuyển"
            thousandSeparator=","
            hideControls
            onKeyDown={handleKeyDown}
            {...form.getInputProps("feeShip")}
          />
        </Grid.Col>
        <Grid.Col span={8}>
          <TextInput
            label={"Ghi chú vận chuyển"}
            placeholder={"Nhập ghi chú vận chuyển"}
            type="text"
            {...form.getInputProps("description")}
          />
        </Grid.Col>

        <Grid.Col span={4} mt={20}>
          <Checkbox
            label={"Hàng dễ vỡ"}
            checked={form.getValues()?.status}
            key={"status"}
            {...form.getInputProps("status")}
          />
        </Grid.Col>
      </Grid>
      <Group
        justify="end"
        mt="xs"
        style={{
          position: "sticky",
          bottom: 0,
          backgroundColor: "white",
        }}
      >
        <Button
          type="button"
          color="gray"
          loading={visible}
          onClick={() => {
            modals.closeAll();
          }}
          leftSection={!visible ? <IconWindow size={18} /> : undefined}
        >
          Đóng
        </Button>
        <Button
          type="submit"
          color={sky_blue.base}
          loading={visible}
          leftSection={!visible ? <IconCheck size={18} /> : undefined}
        >
          Lưu
        </Button>
        <></>
      </Group>
    </Box>
  );
};

export default ModalDeliveryInformation;
