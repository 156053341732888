import { Box, Flex, Input, ScrollArea, Table, Text } from "@mantine/core";
import cx from "clsx";
import { useEffect, useState } from "react";
import { repositoryPos } from "../../../../../../_base/_const/_constVar";
import { MessageResponse } from "../../../../../../model/MessageResponse";
import {
  listBranch,
  tblPromotionDetailModel,
} from "../../../../../../model/TblPromotion";
import classes from "../../Styles/ScrollTable.module.css";

const SuperMarket = ({
  dataPromotionDetail,
}: {
  dataPromotionDetail: tblPromotionDetailModel | undefined;
}) => {
  const [dataTable, setDataTable] = useState<listBranch[]>([]);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [dataBranch, setDataBranch] = useState<any[]>([]);
  const [scrolled, setScrolled] = useState(false);
  const [selectedRows, setSelectedRows] = useState<(string | null)[]>([]);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const fetchDataBranch = async () => {
      const dataApi = await repositoryPos.get<MessageResponse<any>>(
        "/api/v1/TblDmInventory/get-select-branch"
      );

      if (dataApi && dataApi.success) {
        setDataBranch(dataApi.data);
      }
    };

    fetchDataBranch();
  }, []);

  useEffect(() => {
    if (
      dataPromotionDetail?.listBranch &&
      dataPromotionDetail?.listBranch?.length > 0
    ) {
      setDataTable(dataPromotionDetail?.listBranch as listBranch[]);
      setTotalQuantity(
        (dataPromotionDetail?.listBranch as listBranch[]).reduce(
          (total, branch) => (total += branch.orderQuantity || 0),
          0
        )
      );
    } else setDataTable([]);
  }, [dataPromotionDetail]);

  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight - 510);
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  return (
    <Box mt={10}>
      <ScrollArea
        h={height}
        onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
      >
        <Table
          striped
          highlightOnHover
          withColumnBorders
          withTableBorder
          style={{ overflowY: "auto", height: "30px" }}
        >
          <Table.Thead
            className={cx(classes.header, { [classes.scrolled]: scrolled })}
          >
            <Table.Tr>
              <Table.Th>Mã trung tâm</Table.Th>
              <Table.Th>Tên trung tâm</Table.Th>
              <Table.Th>SL đơn hàng</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody mih={100}>
            {dataTable?.map((branch, index) => (
              <Table.Tr
                key={index}
                style={{
                  backgroundColor: selectedRows.includes(branch.code)
                    ? "var(--mantine-color-blue-light)"
                    : "",
                }}
              >
                <Table.Td>{branch.code}</Table.Td>
                <Table.Td>{branch.branchName}</Table.Td>

                <Table.Td>{branch.orderQuantity}</Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
          {dataTable.length < 1 && (
            <Table.Caption>Không có dữ liệu</Table.Caption>
          )}
        </Table>
      </ScrollArea>

      <Flex align={"center"} gap={10} mt={10} justify={"end"}>
        <Text size="xs">Tổng số đơn hàng áp dụng</Text>
        <Input readOnly size="xs" w={80} value={totalQuantity} />
      </Flex>
    </Box>
  );
};

export default SuperMarket;
