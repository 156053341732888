import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Flex,
  Input,
  Title,
  Tooltip,
} from "@mantine/core";
import { modals } from "@mantine/modals";
import {
  IconActivity,
  IconBuildingWarehouse,
  IconEdit,
  IconFunction,
  IconHandStop,
  IconManualGearbox,
  IconPlus,
  IconShoppingBag,
  IconTrash,
  IconUser,
  IconWorldCheck,
  IconZoomReset,
} from "@tabler/icons-react";
import {
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_ExpandedState,
  MRT_PaginationState,
  MRT_RowSelectionState,
  MRT_SortingState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { NotificationExtension } from "../../_base/extension/NotificationExtension";
import Repository from "../../_base/helper/HttpHelper";
import { _localization } from "../../config/location";
import { getProcessDefSelect, getUsersSelect } from "../../const/getSelectApi";
import { TblUserGroupModel } from "../../model/TblUserGroupModel";
import { MessageResponse } from "../../model/MessageResponse";
import { SelectListItem } from "../../model/SelectListItem";
import GroupAddRole from "./GroupAddRoleAuthozire";
import GroupRoleEdit from "./GroupRoleEdit";
import GroupsRoleAdd from "./GroupsRoleAdd";
import "../../Styles/tab.component.css";
import {
  _timerDelayClick,
  repositoryMaster,
  repositoryPos,
} from "../../_base/_const/_constVar";
import GroupAddManufacturerAuth from "./GroupAddManufacturerAuth";
import GroupAddInventoryAuth from "./GroupAddInventoryAuth";
import { formatDateTransfer } from "../../common/FormatDate/FormatDate";
import _breadcrumb from "../../_base/component/_layout/_breadcrumb";
import GroupsUserAdd from "./GroupsUserAdd";
import GroupsUserBrandAdd from "./GroupsUserBrandAdd";
import GroupsUserInventoryAdd from "./GroupsUserInventoryAdd";
import GroupsUserEmployAdd from "./GroupsUserEmployAdd ";
import UserEditGroup from "./GroupUserEdit";
import GroupUserEdit from "./GroupUserEdit";

const GroupUserView = () => {
  //#region  table
  const headerRef = React.useRef<HTMLDivElement>(null);
  //data and fetching state
  const [data, setData] = useState<TblUserGroupModel[]>([]);
  const [dataTemp, setDataTemp] = useState<TblUserGroupModel[]>([]);
  const [dataNguyenThuy, setDataNT] = useState<TblUserGroupModel[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  //

  const [keySearch, setKeySearch] = useState<string>("");
  const [selectIds, setSelectIds] = useState<string[]>([]); //count
  const [timeLeft, setTimeLeft] = useState(60);
  const [isCounting, setIsCounting] = useState(false);

  useEffect(() => {
    let timer: ReturnType<typeof setInterval>;
    if (isCounting && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsCounting(false);
    }
    return () => clearInterval(timer);
  }, [isCounting, timeLeft]);

  const startCountdown = () => {
    if (!isCounting) {
      setTimeLeft(_timerDelayClick);
      setIsCounting(true);
    }
  };

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setOpenedMenu(false);
    try {
      const response = await repositoryPos.get<
        MessageResponse<TblUserGroupModel[]>
      >("/api/v1/auth/get-list");
      if (response && response.success) {
        setDataNT(response.data);
        const treeData = buildTree(response.data) ?? [];
        setData(treeData);
        setRowCount(treeData.length);
        setDataTemp(treeData);
        setSelectIds([]);
        table.resetRowSelection();
      }
    } catch (error) {
      setIsError(true);
      console.error(error);
      return;
    }
    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
    startCountdown();
  };

  function buildTree(
    data: TblUserGroupModel[],
    parentId: number | null = null
  ): TblUserGroupModel[] {
    const _d = data
      .filter((item) => item.parentid === parentId)
      .map((item) => ({
        ...item,
        subRows: buildTree(data, item.id),
      }));
    return _d;
  }
  useEffect(() => {
    setRowCount(data.length);
  }, [data]);
  const activeData = async (status: boolean) => {
    if (selectIds.length < 1)
      NotificationExtension.Success("Bạn chưa chọn dữ liệu !");
    else
      try {
        const res = await repositoryPos.post<MessageResponse<boolean>>(
          "/api/v1/auth/actives",
          {
            ids: selectIds,
            status: status,
          }
        );
        console.log(res);
        if (res && res.success) {
          NotificationExtension.Success("Cập nhật thành công !");
          await fetchData();
        } else NotificationExtension.Fails("Cập nhật thất bại !");
      } catch (error) {
        console.error(error);
        return;
      }
  };
  const activesData = async (status: boolean, id: string) => {
    if (!id || id.length < 1)
      NotificationExtension.Success("Bạn chưa chọn dữ liệu !");
    else
      try {
        const res = await repositoryPos.post<MessageResponse<boolean>>(
          "/api/v1/auth/actives",
          {
            ids: [id],
            status: status,
          }
        );
        console.log(res);
        if (res && res.success) {
          await fetchData();
          NotificationExtension.Success("Cập nhật thành công !");
        } else NotificationExtension.Fails("Cập nhật thất bại !");
      } catch (error) {
        console.error(error);
        return;
      }
  };
  const deletesData = async (ids: string[]) => {
    if (ids.length < 1)
      NotificationExtension.Success("Bạn chưa chọn dữ liệu !");
    else
      try {
        modals.openConfirmModal({
          title: (
            <>
              <Title order={5}>Xóa !</Title>
            </>
          ),

          size: "550px",
          children: <>Bạn có thực sự muốn xóa ?</>,
          labels: { confirm: "Lưu lại", cancel: "Hủy bỏ" },
          onConfirm: async () => {
            const res = await repositoryPos.post<MessageResponse<boolean>>(
              "/api/v1/auth/delete",
              ids
            );
            if (res)
              if (res.success) {
                await fetchData();
                NotificationExtension.Success("Xóa thành công !");
              } else NotificationExtension.Fails("Xóa thất bại !");
          },
        });
      } catch (error) {
        console.error(error);
        return;
      }
  };
  function createItem() {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Thêm mới !</Title>
        </>
      ),

      size: "550px",
      children: <GroupsUserAdd onLoad={fetchData} />,
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  }

  function editItem(id: string) {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Chỉnh sửa !</Title>
        </>
      ),
      size: "550px",
      children: <GroupUserEdit id={Number.parseInt(id)} onLoad={fetchData} />,
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  }

  const groupAddRoleModal = (idGroup: string, idsBrand: Array<string>) => {
    modals.closeAll();
    modals.openConfirmModal({
      title: "Danh sách phòng ban",
      size: "100%",
      fullScreen: false,
      children: (
        <GroupsUserBrandAdd
          onLoad={fetchData}
          key={idGroup}
          idsBrand={idsBrand}
          idGroup={idGroup}
        ></GroupsUserBrandAdd>
      ),
      labels: { confirm: "Confirm", cancel: "Hủy bỏ" },
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };

  const groupAddInventoryModal = (
    idGroup: string,
    idsInventory: Array<string>
  ) => {
    modals.closeAll();

    modals.openConfirmModal({
      title: "Danh sách kho theo chi nhánh",

      size: "80%",
      fullScreen: false,
      children: (
        <GroupsUserInventoryAdd
          onLoad={fetchData}
          key={idGroup}
          idsInventory={idsInventory}
          idGroup={idGroup}
        ></GroupsUserInventoryAdd>
      ),
      labels: { confirm: "Confirm", cancel: "Hủy bỏ" },
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };
  const groupAddEmpModal = async (idGroup: string) => {
    modals.closeAll();
    const idsEmp: Array<number> = [];

    const url = "/api/v1/auth/auth-group-user?id=" + idGroup;
    const res = await repositoryPos.get<MessageResponse<number[]>>(url);
    if (res && res.httpStatusCode === 200)
      if (res.success) {
        for (let index = 0; index < res.data.length; index++) {
          const element = res.data[index];
          idsEmp.push(element);
        }
      }

    modals.openConfirmModal({
      title: "Danh sách nhân viên",
      size: "80%",
      fullScreen: false,
      children: (
        <GroupsUserEmployAdd
          key={idGroup}
          idGroup={idGroup}
          idsEmp={idsEmp}
        ></GroupsUserEmployAdd>
      ),
      labels: { confirm: "Confirm", cancel: "Hủy bỏ" },
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };
  function getColorStatus(text: boolean) {
    switch (text) {
      case true:
        return "teal";
      default:
        return "red";
    }
  }

  const columns = React.useMemo<MRT_ColumnDef<TblUserGroupModel>[]>(
    () => [
      {
        accessorKey: "id",
        header: "Id",
      },
      {
        accessorKey: "name",
        header: "Tên chức năng",
        Cell: ({ renderedCellValue, row }) => (
          <>
            {row && row.original?.parentid && row.original?.parentid > 0
              ? "----- " + renderedCellValue
              : renderedCellValue}
          </>
        ),
      },
      {
        accessorKey: "description",
        header: "Mô tả",
      },
      {
        accessorKey: "inActive",
        header: "Trạng thái",
        Cell: ({ renderedCellValue, row }) => (
          <>
            <Badge color={getColorStatus(row.original.active ?? false)}>
              {row.original.active ? "Đang hoạt động" : "Dừng hoạt động"}
            </Badge>
          </>
        ),
      },
      {
        accessorKey: "createBy",
        header: "Người tạo",
      },
      {
        accessorKey: "createDate",
        header: "Ngày tạo (UTC)",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
      },

      {
        accessorKey: "action",
        header: "Phân quyền và thao tác",
        Cell: ({ renderedCellValue, row, table }) => (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "16px",
            }}
          >
            <Tooltip label="Phòng ban">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="cyan"
                onClick={() => {
                  groupAddRoleModal(
                    row.original.id.toString(),
                    row.original.listBrand?.split(",")
                  );
                }}
              >
                <IconFunction size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
            <Tooltip label="Kho">
              <ActionIcon
                onClick={() => {
                  groupAddInventoryModal(
                    row.original.id.toString(),
                    row.original.listInventory.split(",")
                  );
                }}
                variant="light"
                aria-label="Settings"
                color="grape"
              >
                <IconBuildingWarehouse size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>

            <Tooltip label="Nhân viên">
              <ActionIcon
                onClick={() => {
                  groupAddEmpModal(row.original.id.toString());
                }}
                variant="light"
                aria-label="Settings"
                color="blue"
              >
                <IconUser size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>

            <Tooltip label="Chỉnh sửa">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="orange"
                onClick={async () => {
                  editItem(row.original.id.toString());
                }}
              >
                <IconEdit size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
            <Tooltip
              label={
                row.original.active === true ? "Dừng hoạt động" : "Hoạt động"
              }
            >
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color={row.original.active === true ? "violet" : "green"}
                onClick={async () => {
                  await activesData(
                    !row.original.active,
                    row.original.id.toString()
                  );
                }}
              >
                {row.original.active === true ? (
                  <IconHandStop size={20} />
                ) : (
                  <IconActivity size={20} />
                )}
              </ActionIcon>
            </Tooltip>

            <Tooltip label="Xóa">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="red"
                onClick={async () => {
                  await deletesData([row.original.id.toString()]);
                }}
              >
                <IconTrash size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
          </Box>
        ),
      },
    ],
    []
  );
  const [openedMenu, setOpenedMenu] = useState(false);

  const initialExpandedRootRows = useMemo<MRT_ExpandedState>(
    () =>
      data
        .map((originalRow) => originalRow.id) //get all the root row ids, use recursion for additional levels
        .reduce((a, v) => ({ ...a, [v]: true }), {}), //convert to an object with all the ids as keys and `true` as values
    []
  );
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 160 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (200 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);
  const table = useMantineReactTable({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    //add custom action buttons to top-left of top toolbar
    renderTopToolbarCustomActions: ({ table }) => (
      <div ref={headerRef}>
        <_breadcrumb></_breadcrumb>
        <Flex
          gap="md"
          justify="flex-start"
          align="center"
          direction="row"
          wrap="wrap"
        >
          <Input
            placeholder="Nhập từ khóa ..."
            onChange={(e) => {
              setKeySearch(e.target.value ?? "");
              if (e && e?.target?.value) {
                const keySearch = e.target.value.toLowerCase(); // Chuyển đổi từ khóa tìm kiếm thành chữ thường

                const searchResult = dataTemp.filter(
                  (product) =>
                    product.name?.toLowerCase().includes(keySearch) ||
                    product.description?.toLowerCase().includes(keySearch)
                );
                setData(searchResult);
              } else {
                setData(dataTemp);
              }
            }}
          />
        </Flex>
      </div>
    ),
    renderToolbarInternalActions: ({ table }) => (
      <>
        <Flex
          gap="md"
          justify={{ sm: "flex-start", lg: "flex-end" }}
          align="center"
          direction="row"
          wrap="wrap"
        >
          <Button
            leftSection={<IconPlus size={14} />}
            color="blue"
            variant="outline"
            onClick={async () => {
              createItem();
            }}
          >
            Thêm mới
          </Button>

          <Button
            leftSection={<IconActivity size={14} />}
            color="green"
            variant="outline"
            onClick={async () => {
              await activeData(true);
            }}
            disabled={selectIds.length < 1}
          >
            Hoạt động
          </Button>
          <Button
            leftSection={<IconHandStop size={14} />}
            color="red"
            variant="outline"
            onClick={async () => {
              await activeData(false);
            }}
            disabled={selectIds.length < 1}
          >
            Dừng hoạt động
          </Button>
          <Button
            leftSection={<IconTrash size={14} />}
            color="red"
            variant="outline"
            onClick={async () => {
              await deletesData(selectIds);
            }}
            disabled={selectIds.length < 1}
          >
            Xóa nhóm (đã chọn)
          </Button>
          <Button
            leftSection={<IconZoomReset size={14} />}
            color="violet"
            variant="outline"
            onClick={async () => {
              await fetchData();
            }}
            disabled={isCounting}
          >
            {timeLeft > 0 && timeLeft} Load data
          </Button>
        </Flex>
      </>
    ),

    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    enableRowSelection: true,
    enableExpanding: true,
    getRowId: (row) => row.id?.toString(),
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select"],
        right: ["action"],
      },
      columnVisibility: { id: false },
      density: "xs",
      expanded: initialExpandedRootRows,
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    rowCount,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        fontWeight: "bold", //conditional styling
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      isLoading,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no data
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["50", "100", "150"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
  });
  //#endregion
  return (
    <>
      <div className="mt-5">
        <MantineReactTable table={table} />
      </div>
    </>
  );
};
export default GroupUserView;
