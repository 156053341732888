import { Badge, Box, Button, Fieldset, Flex, Group, Text } from "@mantine/core";
import { IconArrowLeft } from "@tabler/icons-react";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_RowSelectionState,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useState } from "react";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { useLocation, useNavigate } from "react-router-dom";
import { repositoryMdm } from "../../../_base/_const/_constVar";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import { _localization } from "../../../config/location";
import ProductionOrderDetail from "./ProductionOrderDetail/ProductionOrderDetail";
import "./tab.component.css";
import { green } from "../../../const/variables";
const DetailWipView = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const idProductionOrder = location.state && location.state.id;
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState<any>([]);
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  // Get Data Production Order Detail
  const fetchData = async () => {
    setIsLoading(true);
    setIsError(false);
    try {
      const response = await repositoryMdm.get(
        `/api/v1/TblProductionOrder/detail?id=${idProductionOrder}`
      );
      if (response && response.success) {
        let result = response?.data;
        setData(result || []);
      } else {
        setIsError(true);
      }
    } catch (error) {
      setIsError(true);
      setData([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [idProductionOrder]);
  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 160 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (310 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "maSpct",
        header: "Mã sản phẩm công trình",
        size: 200,
        Cell: ({ renderedCellValue }) => (
          <Text size="12.5px" fw={500}>
            {renderedCellValue}
          </Text>
        ),
      },
      {
        accessorKey: "maDm",
        header: "Mã định mức",
        size: 100,
        Cell: ({ renderedCellValue }) => (
          <Text size="12.5px" fw={500}>
            {renderedCellValue}
          </Text>
        ),
      },
      {
        accessorKey: "maCd",
        header: "Mã Công đoạn",
        size: 100,
        Cell: ({ renderedCellValue }) => (
          <Text size="12.5px" fw={500}>
            {renderedCellValue}
          </Text>
        ),
      },
      {
        accessorKey: "maKv",
        header: "Mã khu vực",
        size: 100,
        Cell: ({ renderedCellValue }) => (
          <Text size="12.5px" fw={500}>
            {renderedCellValue}
          </Text>
        ),
      },
      {
        accessorKey: "tgSx",
        header: "Thời gian sản xuất",
        size: 100,
        Cell: ({ renderedCellValue }) => (
          <Text size="12.5px" fw={500}>
            {renderedCellValue}
          </Text>
        ),
      },
    ],
    []
  );

  const table = useMantineReactTable({
    columns,
    data: data?.tblCongDoanModels || [],
    positionToolbarAlertBanner: "bottom",
    enableColumnActions: false,
    enableToolbarInternalActions: false,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    mantineTableContainerProps: {
      style: { height: 180, minHeight: 100 },
    },
    enableStickyHeader: true,
    enablePagination: false,
    enableBottomToolbar: false,
    enableTopToolbar: false,
    onRowSelectionChange: setRowSelection,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        fontWeight: "normal", //conditional styling
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      //  pagination,
      showAlertBanner: isError,

      showSkeletons: isLoading, //loading for the first time with no data
      //sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["10", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    // columnFilterDisplayMode: "popover",
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });
  const columnsOrderDetail = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "id",
        header: "Mã linh kiện",
        Cell: ({ renderedCellValue }) => (
          <Badge
            radius="sm"
            variant="dot"
            size="lg"
            color={renderedCellValue === null ? "red" : green.base}
          >
            {renderedCellValue === null ? null : renderedCellValue}
          </Badge>
        ),
        size: 200,
      },
      {
        accessorKey: "tenVt",
        header: "Tên linh kiện",
        Cell: ({ renderedCellValue }) => (
          <Text size="12.5px" fw={500} truncate="end" h={15}>
            {renderedCellValue}
          </Text>
        ),
        maxSize: 100,
      },
      {
        accessorKey: "soLuong",
        header: "Số lượng",
        Cell: ({ renderedCellValue }: any) => <>{renderedCellValue}</>,
        size: 200,
      },
      {
        accessorKey: "dvt",
        header: "Đơn vị",
        Cell: ({ renderedCellValue }: any) => <>{renderedCellValue}</>,
        size: 200,
      },
    ],
    []
  );

  const tableOrderDetail = useMantineReactTable({
    columns: columnsOrderDetail,
    data: data?.tblChitietVatTuModels || [],
    positionToolbarAlertBanner: "bottom",
    //add custom action buttons to top-left of top toolbar
    enableTopToolbar: false,
    enableToolbarInternalActions: false,
    enableColumnActions: false,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    // enableRowSelection: true,
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select"],
        right: ["action"],
      },
      //   columnVisibility: getColumnVisibility(),
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { height: 180, minHeight: 100 },
    },
    enableStickyHeader: true,
    enablePagination: false,
    enableBottomToolbar: false,
    onRowSelectionChange: setRowSelection,
    // manualFiltering: true,
    // manualPagination: true,
    // manualSorting: true,

    // onColumnFiltersChange: setColumnFilters,
    // onGlobalFilterChange: setGlobalFilter,
    // onPaginationChange: setPagination,
    // onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        fontWeight: "normal", //conditional styling
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      //  pagination,
      showAlertBanner: isError,
      showSkeletons: isLoading, //loading for the first time with no data
      //sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    // mantinePaginationProps: {
    //   showRowsPerPage: true,
    //   withEdges: true,
    //   rowsPerPageOptions: ["10", "50", "100"],
    // },
    // paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    // columnFilterDisplayMode: "popover",
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });
  const handleReturnPage = () => {
    navigate(-1);
  };
  const titleSell = () => {
    return (
      <>
        <Group mt={-10} align="center" justify="space-between">
          <Flex
            gap="md"
            justify="flex-start"
            align="center"
            direction="row"
            wrap="wrap"
          >
            <Group gap={20}>
              {/* <Text fw={700}>Loại phiếu:</Text>
              <Badge variant="light" size="xs" color="blue" radius="md">
                Xuất linh kiện sản xuất
              </Badge> */}
            </Group>
          </Flex>
          <Flex
            gap="md"
            justify={{ sm: "flex-start", lg: "flex-end" }}
            wrap="wrap"
            align="center"
            direction="row"
          >
            <Button
              leftSection={<IconArrowLeft size={14} />}
              color="red"
              size="xs"
              onClick={() => handleReturnPage()}
            >
              Quay lại
            </Button>
          </Flex>
        </Group>
      </>
    );
  };
  return (
    <Box>
      <div ref={headerRef}>
        <_breadcrumb></_breadcrumb>
        <Box my={"10px"}>{titleSell()}</Box>
      </div>
      <Box
        style={{
          maxHeight: `${height}px`,
          minHeight: `${height}px`,
        }}
      >
        <PanelGroup direction="horizontal" id="group">
          <Panel defaultSize={35} minSize={28} maxSize={40}>
            <Fieldset legend={"Thông tin mã lệnh"} p={5}>
              <ProductionOrderDetail data={data} />
            </Fieldset>
          </Panel>
          <PanelResizeHandle id="resize-handle" />
          <Panel defaultSize={65} minSize={35} maxSize={85}>
            <Box>
              <Fieldset p={10} mt={10} legend={"Linh kiện sản xuất"}>
                <MantineReactTable table={tableOrderDetail} />
              </Fieldset>
              <Fieldset p={10} mt={10} legend={"Công đoạn sản xuất"}>
                <MantineReactTable table={table} />
              </Fieldset>
            </Box>
          </Panel>
        </PanelGroup>
      </Box>
    </Box>
  );
};

export default DetailWipView;
