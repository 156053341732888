import React, { useState } from "react";
import { ComboboxItem, Select, Text, TextInput, Tooltip } from "@mantine/core";
import RenderTable from "./RenderTable";
import { formatDateTransfer } from "../../../../common/FormatDate/FormatDate";
import { tblInventoryTransaction } from "../../../../model/TblOutWard"; // Assuming RenderTable is in the same directory
import { anyAsciiCode } from "../../../../_base/helper/AnyAscii";
import { repositoryPos } from "../../../../_base/_const/_constVar";
import { MessageResponse } from "../../../../model/MessageResponse";
import { TblDmInventorySelectByBranch } from "../../../../model/TblDMInventory";

const RequestProductionOutWard = ({
  dataSearchSourceCodeOption,
  dataOutWard,
  sourceCode,
  setSourceCode,
  handleKeyDownSourceCode,
  dataOrderDetail,
  handleChangeValueInput,
  userInfo,
  isSave,
  searchSourceCode,
  setSearchSourceCode,
  isEdit,
  isLocked,
  haveStatus,
  isInternal,
  haveSourceCode,
}: RequestProductionOutWardProps) => {
  const [dataInvSelect, setDataInvSelect] = useState<ComboboxItem[]>([]);
  const headers = [
    { label: "", style: { width: "25%" } },
    { label: "Bên xuất", style: { width: "25%" } },
    { label: "", style: { width: "25%" } },
    { label: "Bên nhận", style: { width: "25%" } },
  ];

  const getDeliveryText = (delivery: string) => {
    switch (delivery) {
      case "KHONG":
        return "Nhận tại cửa hàng";
      case "NOI_BO":
      case "HA_COM":
        return "Nội bộ";
      default:
        return delivery;
    }
  };

  const isValidSerial = (input: string): boolean => {
    if (input) {
      const pattern = /^[A-Z0-9]+$/;
      return pattern.test(input);
    }
    return true;
  };

  const fetchdataInventory = async (idInvOrg: string | number) => {
    const url = `/api/v1/TblDmInventory/get-select-by-branch?branchId=${idInvOrg}`;
    try {
      const getDataInventory = await repositoryPos.get<
        MessageResponse<TblDmInventorySelectByBranch[]>
      >(url);
      if (getDataInventory?.data) {
        const filteredData = getDataInventory?.data;
        const mappedData = filteredData?.map((item) => ({
          value: item.inventoryId.toString(),
          label: `(${item.inventoryCode}) ${item.inventoryName}`,
        }));
        setDataInvSelect(mappedData);
      } else {
        setDataInvSelect([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setDataInvSelect([]);
    }
  };

  const rows = [
    [
      {
        content: (
          <Text
            fw={700}
            w={"100px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Thời gian tạo
          </Text>
        ),
      },
      {
        content: (
          <Tooltip label={dataOrderDetail?.createDate}>
            <Text
              size="sm"
              w={"150px"}
              style={{ whiteSpace: "normal" }}
              lineClamp={2}
              truncate
            >
              {formatDateTransfer(dataOrderDetail?.createDate ?? "")}
            </Text>
          </Tooltip>
        ),
      },
      {
        content: (
          <Text
            fw={700}
            w={"100px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Mã khách hàng
          </Text>
        ),
      },
      {
        content: (
          <Tooltip label={dataOrderDetail?.cusCode}>
            <Text
              size="sm"
              w={"150px"}
              style={{ whiteSpace: "normal" }}
              lineClamp={2}
              truncate
            >
              {dataOrderDetail?.cusCode ?? ""}
            </Text>
          </Tooltip>
        ),
      },
    ],
    [
      {
        content: (
          <Text
            fw={700}
            w={"100px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Thời gian hoàn thành
          </Text>
        ),
      },
      {
        content: (
          <Tooltip label={dataOutWard?.finishDate}>
            <Text
              w={"150px"}
              size="sm"
              style={{ whiteSpace: "normal" }}
              lineClamp={2}
              truncate
            >
              {formatDateTransfer(dataOutWard?.finishDate)}
            </Text>
          </Tooltip>
        ),
      },
      {
        content: (
          <Text
            w={"100px"}
            fw={700}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Tên khách hàng
          </Text>
        ),
      },
      {
        content: (
          <Tooltip label={dataOrderDetail?.cusName}>
            <Text
              w={"150px"}
              size="sm"
              style={{ whiteSpace: "normal" }}
              lineClamp={2}
              truncate
            >
              {dataOrderDetail?.cusName ?? ""}
            </Text>
          </Tooltip>
        ),
      },
    ],
    [
      {
        content: (
          <Text
            w={"100px"}
            fw={700}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Mã phiếu xuất kho
          </Text>
        ),
      },
      {
        content: (
          <Tooltip label={dataOutWard?.transactionCode}>
            <Text
              size="sm"
              style={{ whiteSpace: "normal" }}
              lineClamp={2}
              truncate
            >
              {dataOutWard?.transactionCode}
            </Text>
          </Tooltip>
        ),
      },
      { content: null },
      { content: null },
    ],
    [
      {
        content: (
          <Text
            w={"100px"}
            fw={700}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Mã phiếu yêu cầu xuất kho
          </Text>
        ),
      },
      {
        content:
          !isEdit && !haveSourceCode ? (
            <Select
              placeholder="Mã phiếu yêu cầu xuất kho"
              data={dataSearchSourceCodeOption}
              size="sm"
              disabled={
                !dataOutWard.sourceType ||
                dataOutWard.status === "LOCK" ||
                sourceCode ||
                isSave
              }
              searchable
              clearable
              onSearchChange={(e) => {
                if (setSearchSourceCode) {
                  setSearchSourceCode(e);
                }
              }}
              onKeyDown={handleKeyDownSourceCode}
              value={sourceCode || dataOutWard.sourceCode}
              onChange={(value) => {
                if (handleChangeValueInput) {
                  handleChangeValueInput(value || "", "sourceCode");
                }
                if (setSourceCode) {
                  setSourceCode(value);
                }
              }}
              nothingFoundMessage={"Không tìm thấy dữ liệu !"}
            />
          ) : (
            <Tooltip label={dataOutWard?.sourceCode}>
              <Text
                w={"150px"}
                size="sm"
                style={{ whiteSpace: "normal" }}
                lineClamp={2}
                truncate
              >
                {dataOutWard.sourceCode}
              </Text>
            </Tooltip>
          ),
      },
      { content: null },
      { content: null },
    ],
    haveStatus
      ? [
          {
            content: (
              <Text
                w={"100px"}
                fw={700}
                size="sm"
                style={{ whiteSpace: "normal" }}
                lineClamp={2}
                truncate
              >
                Tình trạng
              </Text>
            ),
          },
          { content: null },
          { content: null },
          { content: null },
        ]
      : [],
    [
      {
        content: (
          <Text
            w={"100px"}
            fw={700}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Chi nhánh
          </Text>
        ),
      },
      {
        content: (
          <Tooltip label={dataOrderDetail?.fromBranchName}>
            <Text
              w={"150px"}
              size="sm"
              style={{ whiteSpace: "normal" }}
              lineClamp={2}
              truncate
            >
              {dataOrderDetail.fromBranchName}
            </Text>
          </Tooltip>
        ),
      },
      { content: null },
      {
        content: (
          <Tooltip label={dataOrderDetail?.toBranchName}>
            <Text
              w={"150px"}
              size="sm"
              style={{ whiteSpace: "normal" }}
              lineClamp={2}
              truncate
            >
              {dataOrderDetail.toBranchName}
            </Text>
          </Tooltip>
        ),
      },
    ],
    [
      {
        content: (
          <Text
            w={"100px"}
            fw={700}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Địa điểm làm việc
          </Text>
        ),
      },
      {
        content: (
          <Tooltip label={dataOrderDetail?.fromBranchAddress}>
            <Text
              w={"150px"}
              size="sm"
              style={{ whiteSpace: "normal" }}
              lineClamp={2}
              truncate
            >
              {dataOrderDetail.fromBranchAddress}
            </Text>
          </Tooltip>
        ),
      },
      { content: null },
      {
        content: (
          <Tooltip label={dataOrderDetail?.toBranchAddress}>
            <Text
              w={"150px"}
              size="sm"
              style={{ whiteSpace: "normal" }}
              lineClamp={2}
              truncate
            >
              {dataOrderDetail.toBranchAddress}
            </Text>
          </Tooltip>
        ),
      },
    ],
    [
      {
        content: (
          <Text
            fw={700}
            w={"100px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Kho
          </Text>
        ),
      },
      {
        content: !isEdit ? (
          <Tooltip
            label={
              dataInvSelect?.find(
                (i) => i.value === dataOutWard?.subInvId?.toString()
              )?.label
            }
          >
            <Select
              placeholder="Chọn kho"
              data={dataInvSelect}
              size="sm"
              disabled={
                !dataOutWard.sourceType ||
                dataOutWard.status === "LOCK" ||
                isSave
              }
              searchable
              clearable
              onKeyDown={handleKeyDownSourceCode}
              value={dataOutWard?.subInvId?.toString() ?? ""}
              onChange={(value) => {
                if (handleChangeValueInput) {
                  handleChangeValueInput(value || "", "subInvId");
                }
              }}
              onClick={() => {
                if (dataInvSelect.length === 0) {
                  fetchdataInventory(dataOrderDetail?.fromBranchId ?? "");
                }
              }}
              nothingFoundMessage={"Không tìm thấy dữ liệu !"}
            />
          </Tooltip>
        ) : (
          <Tooltip label={dataOutWard?.fromInvName}>
            <Text
              w={"150px"}
              size="sm"
              style={{ whiteSpace: "normal" }}
              lineClamp={2}
              truncate
            >
              {dataOutWard?.fromInvName}
            </Text>
          </Tooltip>
        ),
      },
      { content: null },
      {
        content: (
          <Tooltip label={dataOrderDetail?.toInvName}>
            <Text
              w={"150px"}
              size="sm"
              style={{ whiteSpace: "normal" }}
              lineClamp={2}
              truncate
            >
              {dataOrderDetail.toInvName}
            </Text>
          </Tooltip>
        ),
      },
    ],

    [
      {
        content: (
          <Text
            fw={700}
            w={"100px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Họ & Tên
          </Text>
        ),
      },
      {
        content: (
          <Tooltip label={dataOrderDetail?.createName}>
            <Text
              w={"150px"}
              size="sm"
              style={{ whiteSpace: "normal" }}
              lineClamp={2}
              truncate
            >
              {dataOrderDetail?.createName || ""}
            </Text>
          </Tooltip>
        ),
      },
      { content: null },
      { content: null },
    ],
    [
      {
        content: (
          <Text
            w={"100px"}
            fw={700}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Chức danh
          </Text>
        ),
      },
      {
        content: (
          <Tooltip label={dataOrderDetail?.createRoleName}>
            <Text
              w={"150px"}
              size="sm"
              style={{ whiteSpace: "normal" }}
              lineClamp={2}
              truncate
            >
              {dataOrderDetail?.createRoleName || ""}
            </Text>
          </Tooltip>
        ),
      },
      { content: null },
      { content: null },
    ],
    [
      {
        content: (
          <Text
            w={"100px"}
            fw={700}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Giao hàng
          </Text>
        ),
      },
      {
        content: (
          <Tooltip label={dataOrderDetail?.delivery}>
            <Text
              w={"150px"}
              size="sm"
              style={{ whiteSpace: "normal" }}
              lineClamp={2}
              truncate
            >
              {getDeliveryText(dataOrderDetail?.delivery || "")}
            </Text>
          </Tooltip>
        ),
      },
      { content: null },
      { content: null },
    ],
    [
      {
        content: (
          <Text
            w={"100px"}
            fw={700}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Mã bộ
          </Text>
        ),
      },
      {
        content: (
          <TextInput
            error={
              !!dataOutWard?.parentSerial
                ? !isValidSerial(dataOutWard?.parentSerial ?? "")
                  ? "Mã bộ không hợp lệ"
                  : null
                : "Vui lòng nhập mã bộ"
            }
            value={dataOutWard?.parentSerial ?? ""}
            onChange={(e) => {
              const newValue = anyAsciiCode(e.target.value.toLocaleUpperCase());
              handleChangeValueInput(newValue, "parentSerial");
            }}
            disabled={isLocked}
            placeholder={isLocked ? "" : "Vui lòng nhập mã bộ"}
          ></TextInput>
        ),
        colSpan: 3,
      },
    ],
    [
      {
        content: (
          <Text
            w={"100px"}
            fw={700}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Ghi chú
          </Text>
        ),
      },
      {
        content: (
          <TextInput
            error={
              dataOutWard?.description && dataOutWard.description.length > 300
                ? "Ghi chú không được vượt quá 300 kí tự!"
                : null
            }
            value={dataOutWard?.description ?? ""}
            onChange={(e) => {
              handleChangeValueInput(e.target.value, "description");
            }}
            disabled={isLocked}
            placeholder={isLocked ? "" : "Vui lòng nhập"}
          />
        ),
        colSpan: 3,
      },
    ],
  ];

  return (
    <RenderTable
      headers={headers}
      rows={rows.filter((item) => item.length !== 0)}
    />
  );
};

type RequestProductionOutWardProps = {
  dataSearchSourceCodeOption?: any[];
  dataOutWard: tblInventoryTransaction;
  dataOrderDetail: any;
  userInfo: any;
  handleChangeValueInput: (value: string | boolean | null, key: string) => void;
  setSourceCode?: (value: any) => void;
  handleKeyDownSourceCode?: (e: any) => void;
  sourceCode?: any;
  isSave?: boolean;
  searchSourceCode?: string;
  setSearchSourceCode?: (newValue: React.SetStateAction<string>) => void;
  isEdit?: boolean;
  isLocked?: boolean;
  haveStatus?: boolean;
  isInternal?: boolean;
  haveSourceCode?: boolean;
};

export default RequestProductionOutWard;
