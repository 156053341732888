import {
  Box,
  Button,
  Checkbox,
  ComboboxItem,
  Fieldset,
  Flex,
  Grid,
  Group,
  LoadingOverlay,
  Select,
  Table,
  TextInput,
} from "@mantine/core";
import { DateTimePicker } from "@mantine/dates";
import { useDisclosure } from "@mantine/hooks";
import { useEffect, useState } from "react";
import {
  getTblDmEmployeeSelect,
  getTblLocationSelect,
} from "../../../service/getSelectApi";
import { IconArrowLeft, IconCheck } from "@tabler/icons-react";
import { modals } from "@mantine/modals";
import { sky_blue } from "../../../const/variables";
import { repositoryMdm, repositoryPos } from "../../../_base/_const/_constVar";
import { MessageResponse } from "../../../model/MessageResponse";
import { useForm } from "@mantine/form";
import { ProcessAssignAtHome } from "../../../model/AssignAtHome";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { SocketExtension } from "../../../_base/socket/socket";

const AssignmentAtHome = ({
  selectIds,
  onClose,
  dataRequestFormSelect,
}: {
  selectIds: any;
  onClose: any;
  dataRequestFormSelect: any;
}) => {
  const entity = {
    requestFormIds: selectIds,
    code: null,
    empInchargeId: null,
    empSupportId: null,
    note: null,
    levelId: null,
    points: null,
    assignDate: new Date().toLocaleString(),
    processingMethodId: 206,
    payroll1: false,
    payroll2: false,
    fromBranchId: dataRequestFormSelect.branchId,
    deliverPriorityId: null,
    distanceTotal: null,
    estimatedTime: null,
  };

  const [dataEmployeeSelect, setDataEmployeeSelect] = useState<ComboboxItem[]>(
    []
  );
  const [dataEmployeeSelectForDep, setDataEmployeeSelectForDep] = useState<
    ComboboxItem[]
  >([]);
  const [dataLocationSelect, setDataLocationSelect] = useState<ComboboxItem[]>(
    []
  );
  const [dataDeliveryPriority, setDataDeliveryPriority] = useState<
    ComboboxItem[]
  >([
    { label: "Cao", value: "1" },
    { label: "Thấp", value: "2" },
  ]);

  const [dataToAddress, setDataToAddress] = useState<string[]>([]);
  const [dataCalculateDistance, setDataCalculateDistance] = useState<any[]>([]);

  const [visible, { close, open }] = useDisclosure(false);

  const form = useForm<ProcessAssignAtHome>({
    mode: "uncontrolled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },

    transformValues: (values) => ({
      ...values,
      empInchargeId: Number(values.empInchargeId),
      empSupportId: Number(values.empSupportId),
    }),

    validate: {
      empInchargeId: (value: number | null) => {
        if (!value) {
          return "Vui lòng nhập tên người nhận phân công !";
        }
      },
    },
  });

  const handleAssignment = async (dataSubmit: ProcessAssignAtHome) => {
    open();
    const req = await repositoryMdm.post<MessageResponse<ProcessAssignAtHome>>(
      "/api/v1/TblWarrantyServiceRequestForm/create-assignment",
      dataSubmit
    );
    if (req?.success) {
      NotificationExtension.Success("Phân công thành công !");
      setTimeout(() => {
        onClose((prev: any) => !prev);
        modals.closeAll();
      }, 1500);
    }
    close();
  };

  const handleCalculateDistance = async () => {
    const res = await repositoryPos.post<MessageResponse<any>>(
      "/api/v1/Location/matric",
      {
        origins: [dataRequestFormSelect[0].branchAddress?.toString()],
        destinations: dataToAddress,
      }
    );

    if (res && res?.success) {
      const dataApi = res.data.rows;
      setDataCalculateDistance(dataApi);
    }
  };

  const getRequestNum = async () => {
    const res = await repositoryMdm.get<MessageResponse<any>>(
      "/api/v1/TblWarrantyServiceRequestForm/assign?prefix=SPC-"
    );

    if (res && res.success) {
      const data = res.data;
      form.setValues((prev) => ({ ...prev, code: data.requestNum }));
    }
  };

  const getDataEmployeeSelect = async () => {
    const get = await getTblDmEmployeeSelect();
    setDataEmployeeSelect(
      get
        ?.filter((item) => item.value != null && item.text != null)
        ?.map((item) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const getDataLocationSelect = async () => {
    const get = await getTblLocationSelect();
    setDataLocationSelect(
      get
        ?.filter((item) => item.value != null && item.text != null)
        ?.map((item) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  // const getDataDeliveryPriotitySelect = async () => {
  //   const get = await getTblDmDeliverPrioritySelect();
  //   setDataDeliveryPriority(
  //     get
  //       ?.filter((item) => item.value != null && item.text != null)
  //       ?.map((item) => ({
  //         value: item.value,
  //         label: item.text,
  //       }))
  //   );
  // };

  const getDataEmployeeForDep = async () => {
    const res = await repositoryPos.get<MessageResponse<any>>(
      `/api/v1/TblDmEmployee/get-list?BranchId=${dataRequestFormSelect[0]?.branchId}&Take=200`
    );

    if (res && res?.success) {
      setDataEmployeeSelectForDep(
        res.data
          ?.filter(
            (item: any) =>
              item.id != null &&
              item.fullname != null &&
              (item.roleName.toLowerCase().includes("bảo hành") ||
                item.roleName.toLowerCase().includes("giao vận"))
          )
          ?.map((item: any) => ({
            value: item.id?.toString(),
            label: item.fullname,
          }))
      );
    }
  };

  const callApi = async () => {
    try {
      open();
      await Promise.all([
        getDataEmployeeSelect(),
        getDataLocationSelect(),
        // getDataDeliveryPriotitySelect(),
        getRequestNum(),
        getDataEmployeeForDep(),
      ]);
    } catch (error) {
      console.error(error);
    } finally {
      close();
    }
  };

  useEffect(() => {
    callApi();
  }, []);

  useEffect(() => {
    const updateToAddress = () => {
      const uniqueToAddress = Array.from(
        new Set(
          dataRequestFormSelect
            .map((item: any) => item.meetingAddress)
            .filter((item: any) => item !== null)
        )
      ).map(String);
      setDataToAddress(uniqueToAddress);
    };

    updateToAddress();
  }, [dataRequestFormSelect]);

  useEffect(() => {
    if (
      dataCalculateDistance &&
      dataCalculateDistance.length > 0 &&
      dataToAddress.length === 1
    ) {
      form.setValues((prev) => ({
        ...prev,
        distanceTotal: dataCalculateDistance[0]?.elements[0].distance.value * 2,
        estimatedTime: dataCalculateDistance[0]?.elements[0].duration.value * 2,
      }));
    }
  }, [dataCalculateDistance]);

  useEffect(() => {
    handleCalculateDistance();
  }, [dataToAddress]);

  return (
    <Box
      component="form"
      mx="auto"
      w={"70vw"}
      maw={1000}
      onSubmit={form.onSubmit((e: ProcessAssignAtHome) => {
        handleAssignment(e);
        SocketExtension.SendMessageToEmp<any>(
          Number(form.getValues().empInchargeId),
          {
            message: "Có nhiệm vụ",
          }
        );
      })}
    >
      <LoadingOverlay
        visible={visible}
        zIndex={1000}
        overlayProps={{ radius: "sm", blur: 2 }}
      />
      <Grid mt={10}>
        <Grid.Col span={{ base: 12, md: 12, lg: 7 }}>
          <Fieldset legend="Thông tin phân công" p={"5px 15px 10px 15px"}>
            <Grid>
              <Grid.Col span={{ base: 12, md: 12, lg: 4 }}>
                <TextInput
                  label="Số phân công"
                  {...form.getInputProps("code")}
                  variant="filled"
                  readOnly
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
                <DateTimePicker
                  label="Ngày phân công"
                  placeholder="Chọn ngày phân công"
                  value={new Date()}
                  valueFormat="DD/MM/YYYY hh:mm A"
                  clearable
                  variant="filled"
                  readOnly
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
                <TextInput
                  label="Người phân công"
                  value={
                    JSON.parse(localStorage.getItem("userLogin") || "{}")
                      ?.fullName || ""
                  }
                  variant="filled"
                  readOnly
                />
              </Grid.Col>
            </Grid>
            <Grid>
              <Grid.Col span={{ base: 12, md: 4, lg: 4 }}>
                <Select
                  label="Mức độ ưu tiên"
                  placeholder="Chọn mức độ ưu tiên"
                  data={dataDeliveryPriority}
                  clearable
                  {...form.getInputProps("deliverPriorityId")}
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 8, lg: 8 }}>
                <TextInput
                  label="Nội dung phân công"
                  placeholder="Nhập nội dung phân công"
                  {...form.getInputProps("note")}
                />
              </Grid.Col>
            </Grid>
          </Fieldset>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 12, lg: 5 }}>
          <Fieldset legend="Thông tin nhân viên" p={"5px 15px 10px 15px"}>
            <Grid>
              <Grid.Col span={{ base: 12, md: 8, lg: 8 }}>
                <Select
                  label="Người nhận phân công"
                  placeholder="Nhập tên người nhận phân công"
                  data={dataEmployeeSelectForDep?.filter(
                    (item: any) =>
                      item.value !== form.getValues().empSupportId?.toString()
                  )}
                  value={
                    form.getValues().empInchargeId
                      ? form.getValues().empInchargeId?.toString()
                      : null
                  }
                  searchable
                  clearable
                  {...form.getInputProps("empInchargeId")}
                  onChange={(e) =>
                    form.setValues((prev) => ({
                      ...prev,
                      empInchargeId: e ? Number(e) : null,
                      payroll1: true,
                    }))
                  }
                  nothingFoundMessage="Không tìm thấy dữ liệu !"
                  withAsterisk
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 4, lg: 4 }}>
                <Checkbox
                  label="Tính lương"
                  mt={28}
                  checked={form.getValues().payroll1}
                  onClick={() =>
                    form.setValues((prev) => ({
                      ...prev,
                      payroll1: !form.getValues().payroll1,
                    }))
                  }
                />
              </Grid.Col>
            </Grid>
            <Grid>
              <Grid.Col span={{ base: 12, md: 8, lg: 8 }}>
                <Select
                  label="Người hỗ trợ"
                  placeholder="Nhập tên người hỗ trợ"
                  data={dataEmployeeSelectForDep?.filter(
                    (item: any) =>
                      item.value !== form.getValues().empInchargeId?.toString()
                  )}
                  value={
                    form.getValues().empSupportId
                      ? form.getValues().empSupportId?.toString()
                      : null
                  }
                  searchable
                  clearable
                  nothingFoundMessage="Không tìm thấy dữ liệu !"
                  {...form.getInputProps("empSupportId")}
                  onChange={(e) =>
                    form.setValues((prev) => ({
                      ...prev,
                      empSupportId: e ? Number(e) : null,
                      payroll2: true,
                    }))
                  }
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 4, lg: 4 }}>
                <Checkbox
                  label="Tính lương"
                  mt={28}
                  checked={form.getValues().payroll2}
                  onClick={() =>
                    form.setValues((prev) => ({
                      ...prev,
                      payroll2: !form.getValues().payroll2,
                    }))
                  }
                />
              </Grid.Col>
            </Grid>
          </Fieldset>
        </Grid.Col>
      </Grid>
      <Table striped highlightOnHover withTableBorder withColumnBorders mt={10}>
        <Table.Thead>
          <Table.Th>Danh sách điểm đi</Table.Th>
          <Table.Th>Danh sách điểm đến</Table.Th>
          {dataRequestFormSelect.length > 1 ? (
            <Table.Th>Thứ tự đi giao</Table.Th>
          ) : (
            <></>
          )}
        </Table.Thead>
        <Table.Tbody>
          {dataToAddress.length === 1 ? (
            <Table.Tr>
              <Table.Td>{dataRequestFormSelect[0].branchAddress}</Table.Td>
              <Table.Td>{dataToAddress[0]}</Table.Td>
            </Table.Tr>
          ) : (
            dataToAddress?.map((item: any, index: any) => (
              <Table.Tr key={index}>
                {index === 0 ? (
                  <Table.Td rowSpan={dataToAddress.length}>Điểm đi</Table.Td>
                ) : (
                  <></>
                )}
                {index > 0 && (
                  <>
                    <Table.Td>{dataToAddress[index]}</Table.Td>
                  </>
                )}
              </Table.Tr>
            ))
          )}
        </Table.Tbody>
      </Table>
      <TextInput
        label="Quãng đường"
        placeholder="Bấm tính khoảng cách để hiển thị quãng đường"
        value={
          dataToAddress.length === 1
            ? `${dataRequestFormSelect[0].branchAddress} <------> ${dataToAddress[0]}`
            : ""
        }
        mt={10}
        variant="filled"
        readOnly
      />
      <Flex justify="end" gap={"md"} mt={10} wrap={"wrap"}>
        <TextInput
          label="Khoảng cách ước tính"
          placeholder="Khoảng cách ước tính"
          value={
            dataCalculateDistance !== undefined
              ? `${(
                  (dataCalculateDistance[0]?.elements[0].distance.value * 2) /
                  1000
                )?.toFixed(1)} Km`
              : ""
          }
          variant="filled"
          readOnly
        />
        <TextInput
          label="Thời gian đi ước tính"
          placeholder="Thời gian đi ước tính"
          value={
            dataCalculateDistance !== undefined
              ? `${Math.floor(
                  (dataCalculateDistance[0]?.elements[0].duration.value * 2) /
                    3600
                )} giờ - ${Math.floor(
                  ((dataCalculateDistance[0]?.elements[0].duration.value * 2) %
                    3600) /
                    60
                )} phút`
              : ""
          }
          variant="filled"
          readOnly
        />
      </Flex>
      <Group
        justify="end"
        mt="xs"
        style={{
          position: "sticky",
          bottom: 0,
          backgroundColor: "white",
        }}
      >
        <Button
          type="button"
          color="gray"
          loading={visible}
          onClick={() => {
            modals.closeAll();
          }}
          leftSection={!visible ? <IconArrowLeft size={18} /> : undefined}
        >
          Quay lại
        </Button>
        <Button
          type="submit"
          color={sky_blue.base}
          loading={visible}
          leftSection={!visible ? <IconCheck size={18} /> : undefined}
          disabled={
            !form.getValues().distanceTotal ||
            form.getValues().distanceTotal === 0
          }
        >
          Lưu
        </Button>
      </Group>
    </Box>
  );
};

export default AssignmentAtHome;
