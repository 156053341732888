//#region import
import {
  ActionIcon,
  Box,
  Button,
  CSSProperties,
  Card,
  Collapse,
  Divider,
  Flex,
  Grid,
  Group,
  Input,
  Menu,
  NumberFormatter,
  NumberInput,
  Switch,
  Table,
  Text,
  TextInput,
  Textarea,
  Title,
  Tooltip,
} from "@mantine/core";
import { useDebouncedState, useDisclosure, useHotkeys } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import {
  IconCheck,
  IconMaximize,
  IconMaximizeOff,
  IconReceiptRefund,
  IconTrash,
  IconWindow,
} from "@tabler/icons-react";
import {
  MRT_ColumnDef,
  MRT_Row,
  MRT_RowSelectionState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import * as React from "react";
import { useEffect, useState } from "react";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { useLocation } from "react-router-dom";
import { repositoryMdm, repositoryPos } from "../../../_base/_const/_constVar";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { styleCellTable } from "../../../_setup/navdata/baseStyleCellTable";
import EditableTextWithOptions from "../../../common/selectValue";
import { _localizationSales } from "../../../config/location";
import { DepartmentModel } from "../../../model/DepartmentModel";
import { EmployeeModel } from "../../../model/Employee";
import { MessageResponse } from "../../../model/MessageResponse";
import {
  OrderHeader,
  dataOrderDetailTable,
} from "../../../model/SaleOrderModel";
import { CreateSellItem } from "../../../model/TblCreateSellItem";
import { ProductModel } from "../../../model/TblDMItem";
import { TblDMPaymentMethod } from "../../../model/TblDMPaymentMethod";
import {
  ItemDetailPriceModel,
  TblItemPromotionModel,
} from "../../../model/TblItem";
import "../../../Styles/TableBodyCell.module.css";
import { LineType, OrderStatus } from "../orderStatus";
import {
  calculateTotalAttribute5,
  formatDateString,
  formatOrderDate,
  handleCheckChooseProduct,
  handleGetTechnicialRequireId,
  handleGetTechnicialRequireName,
  handlerRenderOptionSelect,
  handlerRenderOptionSelectAccountant,
  removeSuffix,
} from "./HelperFunctionReturn";
import { TableSelectProps, customerCheck } from "./model/ModelReturn";
import { returnReasons } from "./ReasonOption";

const RetailOrder = () => {
  //#region   khai báo state
  const location = useLocation();
  // state lưu tất cả các giá trị tiền

  const [listSerial, setListSerial] = useState<any[]>([]);
  const [isHaveT9, setIsHaveT9] = useState(false);
  const [orderNumberRef, setOrderNumberRef] = useState<string>();
  const [payableAmount, setPayableAmount] = useState(0); // Số tiền cần thanh toán
  const [payableAmountReturn, setPayableAmountReturn] = useState(0); // Số tiền hoàn cho khách khi trả
  const [contractcode, setContractcode] = useState("");
  const [listEmployeeSales, setListEmployeeSales] = useState<any[]>([]);
  const [listBranch, setListBranch] = useState<any[]>([]);
  const [branchId, setBranchId] = useState<number>();
  const [branchName, setBranchName] = useState<string>("");
  const [listDepartment, setListDepartment] = useState<DepartmentModel[]>([]);
  const [departmentId, setDepartmentId] = useState<Number>();
  const [employeeSalesName, setEmployeeSalesName] = useState("");
  const [customerType, setcustomerType] = useState("Khách hàng lẻ");
  const [department, setDepartment] = useState("");
  const [saleChanelList, setSaleChanelList] = useState<any[]>();
  const [saleChanel, setSaleChanel] = useState("Khách đến showroom");
  const [height, setHeight] = useState(0);
  const [listAdd, setListAdd] = useState<dataOrderDetailTable[]>([]);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [dataProducts, setDataProducts] = useState<ProductModel[]>([]);
  const [valueDebounced, setValueDebounced] = useDebouncedState("", 500);
  const [isCollapse, { toggle: toggleCollapse }] = useDisclosure(false);

  //#endregion

  //#region  state object

  const [orderHeader, setOrderHeader] = useState<OrderHeader>({
    id: 0, // ID của đơn hàng - OK
    orderNumber: "", // Số đơn hàng - đã ghép vào field "Mã đơn hàng"
    orderTypeId: 2, // Loại đơn hàng (2 là đơn hàng lẻ)
    orderDate: "2024-01-01T01:00:00.000Z", // Ngày đặt hàng - đã ghép vào field "Thời gian"
    orderStatus: "DON_HANG_CHUA_LUU", // Trạng thái đơn hàng (pending: đang chờ) -đã ghép vào trạng thái góc trên phải
    note: "", // Ghi chú đơn hàng - đã ghép vào field "Ghi chú"
    orgId: 0, // ID tổ chức
    invOrgId: 0, // ID tổ chức liên quan đến kho hàng
    deptId: 0, // ID phòng ban
    divisionId: 0, // ID bộ phận
    saleChanelId: 0, // ID kênh bán hàng
    sourceOrderNumber: "", // Số đơn hàng nguồn
    sourceDocument: "", // Tài liệu nguồn
    sourceInvoices: "", // Hóa đơn nguồn
    custId: 0, // ID khách hàng
    custGroupId: 0, // ID nhóm khách hàng
    contactPersonId: 0, // ID người liên hệ
    billToLocationId: 0, // ID địa điểm hóa đơn
    shipToLocationId: 0, // ID địa điểm giao hàng
    invoiceSerial: "", // Số serial hóa đơn
    invoiceNumber: "", // Số hóa đơn
    invoiceDate: "2024-07-16T04:10:09.167Z", // Ngày hóa đơn
    taxCode: "", // Mã số thuế
    taxAddr: "", // Địa chỉ thuế
    amountTotal: 0, // Tổng số tiền
    amountDiscount: 0, // Số tiền giảm giá
    amountVoucher: 0, // Số tiền voucher
    amountVat: 0, // Số tiền thuế VAT
    currentcyCode: "", // Mã tiền tệ
    currentcyRate: 0, // Tỷ giá tiền tệ
    amountPaid: 0, // Số tiền đã thanh toán
    amountRemaining: 0, // Số tiền còn lại
    paymentType: "", // Loại thanh toán
    paymentStatus: "", // Trạng thái thanh toán
    paymentMethod: "", // Phương thức thanh toán
    paymentDue: 0, // Hạn thanh toán
    saleId: 0, // ID người bán hàng
    managerId: 0, // ID quản lý
    approveId: 0, // ID người duyệt
    approveNote: "", // Ghi chú người duyệt
    rejectNote: "", // Ghi chú từ chối
    shipngType: "", // Loại vận chuyển
    shipingVendor: "", // Nhà cung cấp vận chuyển
    shippingCode: "", // Mã vận chuyển
    shippingStatus: "", // Trạng thái vận chuyển
    shippingPriority: "", // Ưu tiên vận chuyển
    shippingDate: "2024-07-16T04:10:09.167Z", // Ngày vận chuyển
    shippingNote: "", // Ghi chú vận chuyển
    technicalChecked: 0, // Kiểm tra kỹ thuật
    technicalNote: "", // Ghi chú kỹ thuật
    clientId: 0, // ID khách hàng
    createBy: 0, // ID người tạo
    createDate: "2024-07-16T04:10:09.167Z", // Ngày tạo
    lastUpdateBy: 0, // ID người cập nhật cuối cùng
    lastUpdateDate: "2024-07-16T04:10:09.167Z", // Ngày cập nhật cuối cùng
    salesShift: "", // Ca bán hàng
    printInvoiceCheck: 0, // Kiểm tra in hóa đơn
    technicalRequired: 0,
    contractcode: "",
    discountAdded: 0,
    discountAddedTotal: 0,
  });

  const [customerCheck, setCustomerCheck] = useState<customerCheck>({
    code: "", // Mã khách hàng,
    taxCode: "", // mã số thuế
    name: "", // họ tên khách hàng
    telephoneNumber: "", // số Đt
    email: "", // email
    sex: null, // 0 nữ 1 nam
    birthOfDate: null, // ngày sinh
    address: "", // địa chỉ
    id: 0,
    contact: "",
    fax: "",
    type: 0,
    active: false,
    description: "",
    groupId: 0,
  });
  //#endregion

  //#region handle FUNC

  const handlePaymentReturn = async () => {
    const callapi = await repositoryPos.post<
      MessageResponse<TblItemPromotionModel[]>
    >(`/api/v1/CreateSellItem/payment-return`, {
      orderNumber: orderHeader?.orderNumber,
    });

    if (callapi?.success) {
      setOrderHeader({
        ...orderHeader,
        orderStatus: "DON_HANG_HOAN_THANH",
      });
    }
  };

  const fetchDetailEmployeeSales = async (SaleId: number) => {
    if (SaleId) {
      const response = await repositoryPos.get<MessageResponse<any>>(
        `/api/v1/TblDmEmployee/detail-employee?id=${SaleId}`
      );
      if (response && response.success) {
        let result = response.data;
        setEmployeeSalesName(result?.fullName);
      }
    }
  };

  const calculateTotalAttribute4 = (items: any): number => {
    return items.reduce((total: number, item: any) => {
      const discountRate = item.attribute4
        ? parseFloat(item.attribute4.replace("%", ""))
        : 0;
      const discountAmount = item.priceFinal * (discountRate / 100);
      return total + discountAmount;
    }, 0);
  };

  const handleGetNameAccountantById = async (
    accountantId: number | undefined
  ) => {
    try {
      if (accountantId) {
        const response = await repositoryPos.get<MessageResponse<any>>(
          `/api/v1/TblDmEmployee/detail-employee?id=${accountantId}`
        );
        if (response && response.success) {
          setAccountantName(response?.data?.fullName);
        }
      }
    } catch (error) {
      console.error("Error fetching accountant name:", error);
      return ""; // Trả về chuỗi rỗng nếu có lỗi
    }
  };

  const handleCheckAddProduct = (value: dataOrderDetailTable, type: string) => {
    setListAdd((prevList) => {
      return [
        ...prevList,
        {
          ...value,
          lineTyle: type,
          linkToItemId: value?.itemId,
          quality: -1,
        },
      ];
    });
  };

  const handleChangeQuantity = async (e: any, row: any) => {
    setListAdd((prevData) => {
      return prevData.map((item, i) =>
        i === row.index
          ? {
              ...item,
              quality: e || null,
            }
          : item
      );
    });
  };

  const headerRef = React.useRef<HTMLDivElement>(null);
  const contentRef = React.useRef<HTMLDivElement>(null);

  const handleSelectProduct = async (product: ProductModel, index: number) => {
    try {
      if (branchId) {
        const callapi = await repositoryPos.post<
          MessageResponse<ItemDetailPriceModel>
        >(
          `/api/v1/TblPriceListDetail/detail-price?id=${product?.id}&BranchId=${branchId}`
        );
        if (callapi?.success) {
          const callapiPromotion = await repositoryPos.post<
            MessageResponse<TblItemPromotionModel[]>
          >(`/api/v1/TblItem/item-promotion`, {
            id: product.id,
            price: callapi?.data?.listPrice || 0,
            branch: branchId,
          });

          const discountRate =
            (callapiPromotion?.data &&
              callapiPromotion?.data.length > 0 &&
              callapiPromotion?.data[0]?.tblPromotionItemPrimary[0]
                ?.discountPercent) ||
            0;
          const discountAmount =
            (callapiPromotion?.data &&
              callapiPromotion?.data.length > 0 &&
              callapiPromotion?.data[0]?.tblPromotionItemPrimary[0]
                ?.discountAmount) ||
            0;

          let idPromote = 0;
          let idPromoteHT = 0;

          if (callapiPromotion?.data) {
            idPromote =
              callapiPromotion?.data[0]?.tblPromotionItemPrimary[0]?.id || 0;
            idPromoteHT =
              callapiPromotion?.data[0]?.tblPromotionItemGift[0]?.id || 0;
          }

          const addData: dataOrderDetailTable = {
            id: product?.id,
            itemCode: product?.productCode,
            itemName: product?.productName,
            primaryUomCode: product?.unit || "",
            orgId: product?.warehouseId || 0,
            warehouse: product?.warehouse || "",
            priceType: "Giá niêm yết",
            quality: product?.quantity || 1,
            discountRate: discountRate,
            discountAmount: discountAmount,
            discountAdded: 0,
            priceFinal: discountRate
              ? callapi.data?.listPrice * 1 * (1 - discountRate / 100)
              : callapi.data?.listPrice * 1 - (discountAmount || 0) || 0,
            vatRate: callapi.data?.vatRate || 0,
            vatAmount: callapi.data?.vatAmount || 0,
            totalLineAmount: 0,
            note: product?.notes ?? "",
            lineTyle: "HD",
            orderHeaderId: 0,
            deptId: 0,
            itemId: product?.id,
            linkToItemId: 0,
            uomCode: "",
            priceListId: callapi.data?.idPriceLis || 0,
            priceOnPriceList: callapi?.data?.listPrice || 0,
            idPromote: idPromote || 0,
            idPromoteList: "",
            linkToLineId: 0,
            printInvoiceCheck: 0,
            promotePostPaid: "",
            promoteClearCheck: 0,
            promoteClearDate: "",
            prometeClearPersionId: 0,
            prometeClearClientId: "",
            clientId: 0,
            workShiftId: 0,
            createBy: 0,
            createDate: "",
            lastUpdateBy: 0,
            lastUpdateDate: "",
            subInvId: product?.warehouseId || 0,
            listIdPromotion: [
              callapiPromotion?.data &&
              callapiPromotion.data.length > 0 &&
              typeof callapiPromotion.data[0].tblPromotion.id === "number"
                ? callapiPromotion.data[0].tblPromotion.id
                : null,
            ].filter((id): id is number => id !== null),
            listIdAddOn: [],
            listIdGift:
              (callapiPromotion?.data &&
                callapiPromotion?.data.length > 0 &&
                callapiPromotion?.data[0]?.tblPromotionItemGift &&
                callapiPromotion?.data[0]?.tblPromotionItemGift.map(
                  (item) => item.idItemGift
                )) ||
              [],
            promotionDiscountPercent:
              (callapiPromotion?.data &&
                callapiPromotion?.data.length > 0 &&
                callapiPromotion?.data[0]?.tblPromotionItemPrimary[0]
                  ?.discountPercent) ||
              0,
            promotionDiscountAmount:
              (callapiPromotion?.data &&
                callapiPromotion?.data.length > 0 &&
                callapiPromotion?.data[0]?.tblPromotionItemPrimary[0]
                  ?.discountAmount) ||
              0,
            promotionItemGift:
              (callapiPromotion?.data &&
                callapiPromotion?.data.length > 0 &&
                callapiPromotion?.data[0]?.tblPromotionItemGift.map((item) => ({
                  itemCode: item?.itemForSellModel.productCode,
                  itemName: item?.itemForSellModel.productName,
                  primaryUomCode: item?.itemForSellModel.unit || "",
                  quality: 1,
                  warehouse: item?.itemForSellModel.warehouse,
                }))) ||
              [],
          };

          if (
            callapiPromotion?.data &&
            callapiPromotion?.data[0]?.tblPromotionItemGift?.length > 0
          ) {
            const itemGif =
              callapiPromotion?.data[0]?.tblPromotionItemGift[0]
                ?.itemForSellModel;

            const dataItemGif: dataOrderDetailTable = {
              id: itemGif?.id || 0,
              itemCode: itemGif?.productCode || "",
              itemName: itemGif?.productName || "",
              primaryUomCode: itemGif?.unit || "",
              orgId: itemGif?.warehouseId || 0,
              warehouse: itemGif?.warehouse || "",
              priceType: "Giá niêm yết",
              quality: 1,
              discountRate: 0,
              discountAmount: 0,
              discountAdded: 0,
              priceFinal: 0,
              vatRate: 0,
              vatAmount: 0,
              totalLineAmount: 0,
              note: "",
              // HH hàng hoá, KM khuyến mai, DV dịch vụ
              lineTyle: "KM",
              orderHeaderId: 0,
              deptId: 0,
              itemId: itemGif?.id || 0,
              linkToItemId: 0,
              uomCode: "",
              priceListId: 0,
              priceOnPriceList: 0,
              idPromote: idPromoteHT || 0,
              idPromoteList: "",
              linkToLineId: 0,
              printInvoiceCheck: 0,
              promotePostPaid: "",
              promoteClearCheck: 0,
              promoteClearDate: "",
              prometeClearPersionId: 0,
              prometeClearClientId: "",
              clientId: 0,
              workShiftId: 0,
              createBy: 0,
              createDate: "",
              lastUpdateBy: 0,
              lastUpdateDate: "",
              subInvId: itemGif?.warehouseId || 0,
              listIdPromotion: [],
              listIdAddOn: [],
              listIdGift: [],
              promotionDiscountPercent: 0,
              promotionDiscountAmount: 0,
            };
            setListAdd((prevList) => {
              // Nếu index === -1, thêm addData vào đầu mảng
              if (index === -1) {
                return [addData, ...prevList, dataItemGif]; // Thêm addData vào đầu, và dataItemGif vào cuối
              } else {
                // Nếu index khác -1, cập nhật phần tử tại vị trí index và thêm dataItemGif
                const newList = [...prevList];
                newList[index] = {
                  ...addData,
                  linkToItemId: newList[index]?.linkToItemId || 0,
                  quality: newList[index]?.quality || 0,
                };
                newList.push(dataItemGif); // Thêm dataItemGif vào cuối mảng
                return newList;
              }
            });
            return;
          }

          setListAdd((prevList) => {
            if (index === -1) {
              return [addData, ...prevList];
            } else {
              const newList = [...prevList];
              newList[index] = {
                ...addData,
                linkToItemId: newList[index]?.linkToItemId || 0,
                quality: newList[index]?.quality || 0,
              };
              return newList;
            }
          });
        }
        table.setEditingRow(null);
      }
    } catch (error) {
      console.error("Error in handleSelectProduct:", error);
    }
  };

  const handleGetNameEmployee = (Employee: EmployeeModel[]) => {
    const dataName = Employee?.map((item: any) => {
      return item?.fullName;
    });
    return dataName;
  };

  const handleGetNameDepartment = (Department: DepartmentModel[]) => {
    const dataName = Department?.map((item: any) => {
      return item?.name;
    });
    return dataName;
  };

  const handleGetNameBranch = (Branch: any[]) => {
    const dataName = Branch?.map((item: any) => {
      return item?.text;
    });
    return dataName;
  };

  const calculateTotalLineAmount = (item: any) => {
    const baseAmount = item.quality * item.priceFinal;

    // Trừ theo `attribute4` (tỷ lệ phần trăm)
    const discountPercent = item.attribute4 ? Number(item.attribute4) / 100 : 0;
    const amountAfterPercentDiscount = baseAmount * (1 - discountPercent);

    // Trừ theo `attribute5` (số tiền trực tiếp)
    const discountAmount = item.attribute5 ? Number(item.attribute5) : 0;
    const totalLineAmount = amountAfterPercentDiscount + discountAmount;

    return totalLineAmount;
  };

  const handleValidateField = (dataFilter: any[]): boolean => {
    const fieldsToValidate = [
      "attribute2",
      "attribute4",
      "attribute6",
      "attribute7",
      "attribute8",
      "attribute13",
    ];

    return dataFilter.every((item) =>
      fieldsToValidate.every((field) => item[field as keyof any] != null)
    );
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "-") {
      event.preventDefault();
    }
  };

  const handleCreateReturn = async (isLock: boolean) => {
    const dataFilter = listAdd
      .filter(
        (item) =>
          item.lineTyle !== "HH" &&
          item.lineTyle !== "HK" &&
          item.lineTyle !== "KM"
      )
      .map((item) => ({
        ...item,
        totalLineAmount: calculateTotalLineAmount(item),
      }));
    const dataCreateReturn = {
      // bỏ sp là hàng hóa ra khỏi đơn tạo
      createSellItem: {
        orderDetail: dataFilter,
        orderHeader: {
          ...orderHeader,
          attribute2: accountantId,
          attribute4: isHaveT9
            ? 0
            : Math.abs(payableAmountReturn)
            ? payableAmountReturn < 0
              ? Math.abs(payableAmountReturn)
              : 0
            : 0,
          attributes6: orderHeader?.attribute6
            ? orderHeader?.attribute6
            : "Tiền mặt",
        },
        orderNumberRef: orderNumberRef,
        orderStatus: "DON_HANG_CHOT",
        orderTypeId: 3,
        islock: isLock,
      },
    };

    const response = await repositoryPos.post<MessageResponse<CreateSellItem>>(
      `/api/v1/CreateSellItem/return-sale-order`,
      dataCreateReturn
    );

    if (response && response.success) {
      NotificationExtension.Success("Tạo đơn trả hàng thành công");
      setTimeout(
        () =>
          setOrderHeader({
            ...orderHeader,
            orderStatus: isLock ? "DON_HANG_CHOT" : "DON_HANG_TAM",
          }),
        1000
      );
    }
  };

  //#region render ui

  const TableSelect: React.FC<TableSelectProps> = ({
    dataProducts,
    onSelectProduct,
    rowIndex,
  }: any) => {
    return (
      <Table.ScrollContainer mah={300} minWidth={300} type="native">
        <Table striped withTableBorder>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Chọn</Table.Th>
              <Table.Th>ID</Table.Th>
              <Table.Th>Mã sản phẩm</Table.Th>
              <Table.Th>Tên sản phẩm</Table.Th>
              <Table.Th>Đơn vị</Table.Th>
              <Table.Th>Tồn ảo</Table.Th>
              <Table.Th>Tồn serial</Table.Th>
              <Table.Th>Tuổi tồn</Table.Th>
              <Table.Th>Mã kho</Table.Th>
              <Table.Th>Kho</Table.Th>
            </Table.Tr>
          </Table.Thead>
          {dataProducts?.length > 0 ? (
            <Table.Tbody>
              {dataProducts?.map((product: any, index: any) => (
                <Table.Tr key={index}>
                  <Table.Td>
                    <Button
                      size="xs"
                      variant="filled"
                      onClick={() => {
                        onSelectProduct(product, rowIndex);
                        table.setCreatingRow(null);
                      }}
                      disabled={
                        handleCheckChooseProduct(product, listAdd) ||
                        product.tonao <= 0
                      }
                    >
                      {handleCheckChooseProduct(product, listAdd)
                        ? "Đã chọn"
                        : "Chọn"}
                    </Button>
                  </Table.Td>
                  <Table.Td>{product.id}</Table.Td>
                  <Table.Td>{product.productCode}</Table.Td>
                  <Table.Td>{product.productName}</Table.Td>
                  <Table.Td>{product.unit}</Table.Td>
                  <Table.Td>{product.tonao}</Table.Td>
                  <Table.Td>{product.tons}</Table.Td>
                  <Table.Td>{product.oldton}</Table.Td>
                  <Table.Td>{product.wareHouseCode}</Table.Td>
                  <Table.Td>{product.warehouse}</Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
          ) : (
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Text>Hãy nhập ID hoặc mã sản phẩm!</Text>
            </Box>
          )}
        </Table>
      </Table.ScrollContainer>
    );
  };

  const titleSell = () => {
    return (
      <>
        <_breadcrumb></_breadcrumb>
        <Group
          align="center"
          p={"0 10 10 10"}
          justify="center"
          pos={"relative"}
        >
          <Flex pos={"absolute"} left={0}>
            <Tooltip
              label={isCollapse ? "Mở rộng" : "Thu gọn"}
              position="bottom"
            >
              <Group align="center">
                <Switch
                  size="sm"
                  color={isCollapse ? "red" : "green"}
                  checked={!isCollapse}
                  onChange={toggleCollapse}
                  label={isCollapse ? "Thu gọn (Ctrl+M)" : "Mở rộng (Ctrl+M)"}
                  thumbIcon={
                    !isCollapse ? (
                      <IconMaximize
                        style={{ width: 16, height: 16 }}
                        stroke={2.5}
                        color="white"
                      />
                    ) : (
                      <IconMaximizeOff
                        style={{ width: 16, height: 16 }}
                        stroke={2.5}
                        color="white"
                      />
                    )
                  }
                />
              </Group>
            </Tooltip>
          </Flex>
          <Flex
            gap="md"
            justify="center"
            align="center"
            direction="row"
            wrap="wrap"
          >
            <OrderStatus status={orderHeader?.orderStatus} />
          </Flex>
        </Group>
      </>
    );
  };

  const formOrderHeader = () => {
    return (
      <Box
        style={{
          padding: 5,
          height: "100%",
          position: "relative",
          ...getStyle(),
        }}
      >
        <Grid>
          <Grid.Col span={6}>
            <Flex align="center" gap="xs">
              <Text style={{ flex: "0 0 100px" }}>
                Mã đơn hàng:<span style={{ color: "red" }}>*</span>
              </Text>
              <Text fw={500}>{orderHeader?.orderNumber}</Text>
            </Flex>
            <Flex align="center" gap="xs">
              <EditableTextWithOptions
                required
                label="Bộ phận:"
                value={department}
                options={handleGetNameDepartment(listDepartment)}
                onChange={(value) => {
                  setDepartment(value);
                  const departmentId = listDepartment?.find((item) => {
                    return item?.name === value;
                  });
                  setDepartmentId(departmentId?.departmentId);
                }}
                width="280px"
              />
            </Flex>
            <Flex align="center" gap="xs">
              <EditableTextWithOptions
                required
                label="Chi nhánh:"
                value={branchName}
                options={handleGetNameBranch(listBranch) || []}
                onChange={(value: any) => {
                  setBranchName(value);
                  const BranchId = listBranch.find((item) => {
                    return item.name === value;
                  });
                  setBranchId(Number(BranchId?.value));
                  setListAdd([]);
                  setDataProducts([]);
                }}
                width="250px"
              />
            </Flex>

            <Flex align="center" gap="xs">
              <EditableTextWithOptions
                required
                label="NV bán hàng:"
                value={employeeSalesName}
                options={handleGetNameEmployee(listEmployeeSales) || []}
                onChange={(value: any) => {
                  setEmployeeSalesName(value);
                  const saleId = listEmployeeSales.find((item) => {
                    return item.fullName === value;
                  });
                }}
                width="250px"
              />
            </Flex>
            <Flex align="center" gap="xs">
              <Text style={{ flex: "0 0 100px" }}>
                Khách hàng:<span style={{ color: "red" }}>*</span>
              </Text>
              <Text fw={500}>{customerCheck?.name}</Text>
            </Flex>
            <Flex align="center" gap="xs">
              <Text style={{ flex: "0 0 100px" }}>
                Mã số KH:<span style={{ color: "red" }}>*</span>
              </Text>
              <Flex
                align="center"
                style={{ flex: "1 1 auto", position: "relative" }}
              >
                <Text style={{ flex: "0 0 100px" }}>{customerCheck?.code}</Text>
              </Flex>
            </Flex>
            <Flex align="center" gap="xs">
              <Text style={{ flex: "0 0 100px" }}>
                SĐT:<span style={{ color: "red" }}>*</span>
              </Text>
              <Text style={{ flex: "0 0 100px" }}>
                {customerCheck?.telephoneNumber}
              </Text>
            </Flex>
          </Grid.Col>

          <Grid.Col span={6}>
            <Flex align="center" gap="xs">
              <Text style={{ flex: "0 0 100px" }}>Thời gian tạo:</Text>
              <Text fw={500}>{formatDateString(orderHeader?.createDate)}</Text>
            </Flex>
            <Flex align="center" gap="xs">
              <Text style={{ flex: "0 0 100px" }}>Thời gian HT:</Text>
              <Text fw={500}>
                {formatDateString(formatOrderDate(orderHeader?.orderDate))}
              </Text>
            </Flex>
            <Flex align="center" gap="xs">
              <EditableTextWithOptions
                required
                label="Kênh bán:"
                value={saleChanel}
                options={handlerRenderOptionSelect(saleChanelList)}
                onChange={(value) => {
                  setSaleChanel(value);
                  const id = saleChanelList?.find((item) => {
                    return item.name === value;
                  });
                }}
                width="280px"
              />
            </Flex>

            <Flex align="center" gap="xs">
              <EditableTextWithOptions
                required
                label="Kế toán BH:"
                value={accountantName}
                options={handlerRenderOptionSelectAccountant(dataAccountant)}
                onChange={(value) => {
                  setAccountantName(value);
                  const id = dataAccountant?.find((item) => {
                    return item.fullname === value;
                  });
                  setAccountantId(id.id);
                }}
                width="280px"
                // readOnly
              />
            </Flex>
            <Flex align="center" gap="xs">
              <EditableTextWithOptions
                label="Loại KH:"
                readOnly
                value={customerType}
                options={[
                  "Khách hàng lẻ",
                  "Khách doanh nghiệp",
                  "Khách Bán buôn",
                ]}
                onChange={setcustomerType}
                width="280px"
              />
            </Flex>
            <Flex align="center" gap="xs">
              <EditableTextWithOptions
                required
                label="Xuất VAT:"
                value={
                  (orderHeader?.printInvoiceCheck ?? 0) === 0 ? "Không" : "Có"
                }
                options={["Có", "Không"]}
                onChange={(e) =>
                  setOrderHeader((prev) => ({
                    ...prev,
                    printInvoiceCheck: e === "Không" ? 0 : 1,
                  }))
                }
              />
            </Flex>
            <Flex align="center" gap="xs">
              <EditableTextWithOptions
                required
                label="Yêu cầu DV:"
                value={handleGetTechnicialRequireName(
                  orderHeader?.technicalRequired ?? 0
                )}
                options={[
                  "Không",
                  "Lắp ráp(mới)",
                  "Kiểm tra(mới)",
                  "Lắp ráp(mới) + Kiểm tra(mới)",
                ]}
                onChange={(e) =>
                  setOrderHeader((prev) => ({
                    ...prev,
                    technicalRequired: handleGetTechnicialRequireId(e),
                  }))
                }
              />
            </Flex>
            <Flex align="center" gap="xs" mt={-8}>
              <Text style={{ flex: "0 0 100px" }}>Mã hợp đồng:</Text>
              <TextInput
                value={contractcode}
                onChange={(e) => setContractcode(e.target.value)}
                variant="unstyled"
                size="md"
                fs={"14px"}
                fw={"500"}
                placeholder="Nhập mã đợp đồng"
              />
            </Flex>
          </Grid.Col>
        </Grid>

        <Divider my={"sm"} />

        {!isHaveT9 && (
          <Grid mt={20} mb={20}>
            {orderHeader?.discountAddedTotal && (
              <Grid.Col span={12} pt={0} pb={0}>
                <Flex align="center" gap="xs">
                  <Text style={{ flex: "0 0 180px" }}>
                    Xin giảm trên tổng đơn :
                  </Text>
                  <Text fw={500} style={{ width: "25%" }}></Text>
                  <Text fw={"500"}>
                    <NumberFormatter
                      style={{ fontSize: "17px" }}
                      value={orderHeader?.discountAddedTotal}
                      thousandSeparator="."
                      decimalSeparator=","
                    />
                  </Text>
                </Flex>
              </Grid.Col>
            )}
            <Grid.Col span={12} pt={0} pb={0}>
              <Flex align="center" gap="xs">
                <Text style={{ flex: "0 0 180px" }}>
                  Tổng tiền phí trả hàng :
                </Text>
                <Text fw={500} style={{ width: "25%" }}></Text>
                <Text fw={"500"}>
                  <NumberFormatter
                    style={{ fontSize: "17px" }}
                    value={calculateTotalAttribute4(listAdd)}
                    thousandSeparator="."
                    decimalSeparator=","
                  />
                </Text>
              </Flex>
            </Grid.Col>
            <Grid.Col span={12} pt={0} pb={0}>
              <Flex align="center" gap="xs">
                <Text style={{ flex: "0 0 180px" }}>
                  Tổng tiền trừ theo lỗi vật lý :
                </Text>
                <Text fw={500} style={{ width: "25%" }}></Text>
                <Text fw={"500"}>
                  <NumberFormatter
                    style={{ fontSize: "17px" }}
                    value={Number(calculateTotalAttribute5(listAdd))}
                    thousandSeparator="."
                    decimalSeparator=","
                  />
                </Text>
              </Flex>
            </Grid.Col>
            <Grid.Col span={12} pt={0} pb={0}>
              <Flex align="center" gap="xs">
                <Text style={{ flex: "0 0 180px" }}>
                  Tổng tiền trừ KM(chưa tính) :
                </Text>
                <Text fw={500} style={{ width: "25%" }}></Text>
                <Text fw={"500"}>
                  <NumberFormatter
                    style={{ fontSize: "17px" }}
                    value={0}
                    thousandSeparator="."
                    decimalSeparator=","
                  />
                </Text>
              </Flex>
            </Grid.Col>
            <Grid.Col span={12} pt={0} pb={0}>
              <Flex align="center" gap="xs">
                <Text style={{ flex: "0 0 180px" }}>
                  Tổng tiền hoàn cho khách :
                </Text>
                <Text fw={500} style={{ width: "25%" }}></Text>
                <Text fw={"500"} style={{ color: "red" }}>
                  <NumberFormatter
                    style={{ fontSize: "17px" }}
                    value={
                      Math.abs(payableAmountReturn)
                        ? payableAmountReturn < 0
                          ? Math.abs(payableAmountReturn)
                          : 0
                        : 0
                    }
                    thousandSeparator="."
                    decimalSeparator=","
                  />
                </Text>
              </Flex>
              <Flex align="center" gap="xs">
                <Text style={{ flex: "0 0 180px" }}>
                  Phương thức thanh toán :
                </Text>
                <Text fw={500} style={{ width: "25%" }}></Text>
                <EditableTextWithOptions
                  required
                  label=""
                  value={orderHeader?.attribute6 || "Tiền mặt"}
                  options={["Tiền mặt"]}
                  onChange={(value) => {
                    setOrderHeader({ ...orderHeader, attribute6: value });
                  }}
                  width="280px"
                />
              </Flex>
            </Grid.Col>
          </Grid>
        )}

        <Box>
          <Grid mt={2}>
            <Grid.Col span={12}>
              <Flex gap="xs" align="center">
                <Text>Ghi chú đơn hàng:</Text>
                <Textarea
                  style={{ flex: 1 }}
                  value={orderHeader?.note ? orderHeader?.note : ""}
                  onChange={(e) =>
                    setOrderHeader({ ...orderHeader, note: e.target.value })
                  }
                />
              </Flex>
            </Grid.Col>
          </Grid>
        </Box>

        <Flex pos={"absolute"} left={10} bottom={10} mt={10} gap={"xs"}>
          <>
            {/* <Button
              color="blue"
              size="sm"
              disabled={payableAmount === 0}
              onClick={() => openModalConfirmReturn(false)}
            >
              Lưu
            </Button> */}
            {location?.state?.actionType == "payment" ? (
              <Button
                color="green"
                size="sm"
                disabled={
                  (payableAmount === 0 ||
                    orderHeader?.orderStatus !== "DON_HANG_CHOT") &&
                  orderHeader?.orderStatus === "DON_HANG_HOAN_THANH"
                }
                onClick={() => openModalConfirmReturn(true)}
                style={{ pointerEvents: "auto" }}
              >
                Hoàn tất thanh toán
              </Button>
            ) : (
              <Button
                color="green"
                size="sm"
                disabled={
                  payableAmount === 0 ||
                  orderHeader?.orderStatus === "DON_HANG_CHOT"
                }
                onClick={() => openModalConfirmCreate(true)}
              >
                Chốt đơn
              </Button>
            )}
          </>
        </Flex>
      </Box>
    );
  };

  //#endregion

  //#region openConfirmModal

  const openDeleteConfirmModal = (row: MRT_Row<dataOrderDetailTable>) => {
    return modals.openConfirmModal({
      title: "Bạn có chắc chắn xóa sản phẩm này?",
      children: (
        <Text size="lg" mt={15}>
          Hành động này có thể được hoàn tác khi được thêm mới.
        </Text>
      ),
      labels: { confirm: "Xóa", cancel: "Thoát" },
      confirmProps: { color: "red" },
      onConfirm: () => {
        const data = listAdd.filter(
          (value, index) =>
            value.id !== row.original.id ||
            !(value.lineTyle === "HT" || value.lineTyle === "HD") ||
            index !== row.index
        );
        setListAdd(data);
      },
    });
  };

  const openModalConfirmCreate = (isLock: boolean) => {
    // const dataFilter = listAdd
    //   .filter((item) => item.lineTyle !== "HH")
    //   .map((item) => ({
    //     ...item,
    //     totalLineAmount: calculateTotalLineAmount(item),
    //   }));
    // if (!handleValidateField(dataFilter) || dataFilter.length === 0) {
    //   NotificationExtension.Warn(
    //     "Chưa nhập đầy đủ : serial , trường hợp đổi trả , tình trạng , phụ kiện , trạng thái , khấu trừ"
    //   );
    //   return;
    // }
    modals.openConfirmModal({
      title: <Title order={5}>Xác nhận tạo đơn trả hàng</Title>,
      children: (
        <>
          <Flex justify={"end"} gap={20} mt={"sm"}>
            <Button
              color="gray"
              leftSection={<IconWindow size={18} />}
              onClick={() => {
                modals.closeAll();
              }}
            >
              Huỷ
            </Button>
            <Button
              leftSection={<IconCheck size={18} />}
              onClick={() => {
                handleCreateReturn(isLock);
                modals.closeAll();
              }}
            >
              Xác nhận
            </Button>
          </Flex>
        </>
      ),
      labels: { confirm: "Confirm", cancel: "Cancel" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => console.log("Confirmed"),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
      size: "550px",
    });
  };

  const openModalConfirmReturn = (isLock: boolean) => {
    modals.openConfirmModal({
      title: <Title order={5}>Xác nhận hoàn tất thanh toán</Title>,
      children: (
        <>
          <Flex justify={"end"} gap={20} mt={"sm"}>
            <Button
              color="gray"
              leftSection={<IconWindow size={18} />}
              onClick={() => {
                modals.closeAll();
              }}
            >
              Huỷ
            </Button>
            <Button
              leftSection={<IconCheck size={18} />}
              onClick={() => {
                handlePaymentReturn();
                modals.closeAll();
              }}
            >
              Xác nhận
            </Button>
          </Flex>
        </>
      ),
      labels: { confirm: "Confirm", cancel: "Cancel" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => console.log("Confirmed"),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
      size: "550px",
    });
  };

  //#endregion openConfirmModal

  //#region Table sản phẩm

  const columns = React.useMemo<MRT_ColumnDef<dataOrderDetailTable>[]>(
    () => [
      {
        header: "STT",
        enableEditing: false,
        size: 30,
        Cell: ({ renderedCellValue, row }) => (
          <Text>{row.index === -1 ? "" : row.index + 1}</Text>
        ),
      },
      {
        accessorKey: "lineTyle",
        header: "Loại",
        enableEditing: false,
        Cell: ({ renderedCellValue, row }) => (
          <Text>
            <LineType status={row?.original?.lineTyle} />
          </Text>
        ),
        size: 70,
      },
      {
        accessorKey: "itemName",
        header: "Tên sản phẩm",
        enableEditing: false,
        Cell: ({ renderedCellValue, row }) => (
          <Tooltip label={renderedCellValue}>
            <Text w={200} style={{ whiteSpace: "normal" }} lineClamp={2}>
              {renderedCellValue}
            </Text>
          </Tooltip>
        ),
      },
      {
        accessorKey: "attribute13",
        header: "Serial",
        enableEditing: false,
        size: 200,
        Cell: ({ renderedCellValue, row }) => (
          // <Input
          //   value={row.original.attribute13}
          //   disabled={
          //     row?.original?.lineTyle === "HH" ||
          //     row?.original?.lineTyle === "KM" ||
          //     row?.original?.lineTyle === "HK"
          //   }
          //   onChange={(e) => handleChangeSerial(e.target.value, row)}
          // ></Input>

          <select
            value={row?.original?.attribute13}
            disabled={
              row?.original?.lineTyle === "HH" ||
              row?.original?.lineTyle === "KM" ||
              row?.original?.lineTyle === "HK"
            }
            onChange={(event) => {
              setListAdd((prevData) => {
                return prevData.map((item, i) =>
                  i === row.index
                    ? { ...item, attribute13: event?.currentTarget?.value }
                    : item
                );
              });
            }}
            style={selectStyle}
          >
            <option value="" style={optionStyle}>
              Chọn
            </option>

            {listSerial?.map((serial, index) => (
              <option key={index} value={serial} style={optionStyle}>
                {serial}
              </option>
            ))}
          </select>
        ),
      },
      {
        accessorKey: "itemCode",
        header: "Mã hàng",
        enableSorting: false,
        enableEditing: false,
        size: 100,
        Edit: ({ cell, row, table, column }) => {
          return (
            <Menu
              trapFocus={false}
              trigger="hover"
              shadow="md"
              width={"800"}
              position="bottom-start"
            >
              <Menu.Target>
                <TextInput
                  autoFocus
                  onChange={async (e) => {
                    const _key = e.target.value ?? "";
                    setValueDebounced(_key);
                  }}
                />
              </Menu.Target>
              <Menu.Dropdown>
                <TableSelect
                  dataProducts={dataProducts}
                  onSelectProduct={(product) =>
                    handleSelectProduct(product, row?.index)
                  }
                  rowIndex={row.index}
                />
              </Menu.Dropdown>
            </Menu>
          );
        },
      },

      {
        accessorKey: "primaryUomCode",
        header: "ĐVT",
        enableEditing: false,
        enableSorting: false,
        size: 50,
      },

      {
        accessorKey: "quality",
        header: "Số lượng",
        enableEditing: false,
        enableSorting: false,
        size: 80,
        Cell: ({ renderedCellValue, row }) => (
          <NumberInput
            disabled={
              row?.original?.lineTyle === "HH" ||
              row?.original?.lineTyle === "KM" ||
              row?.original?.lineTyle === "HK"
            }
            max={-1}
            min={-10000}
            clampBehavior="strict"
            hideControls
            value={row.original.quality}
            onChange={(e) => {
              handleChangeQuantity(e, row);
            }}
            onBlur={(e) => {
              table.setEditingCell(null);
            }}
          />
        ),
      },
      {
        accessorKey: "price",
        header: "Giá bán lẻ",
        enableEditing: false,
        enableSorting: false,
        size: 0,
        Cell: ({ renderedCellValue, row }) => (
          <Flex direction={"column"}>
            <NumberFormatter
              value={row.original.priceOnPriceList}
              thousandSeparator="."
              decimalSeparator=","
            />
          </Flex>
        ),
      },
      {
        header: "KM",
        enableEditing: false,
        enableSorting: false,
        size: 50,
        Cell: ({ row }) => (
          <span style={{ color: "red" }}>
            {row.original.discountRate ? (
              `-${row.original.discountRate}%`
            ) : row.original?.discountAmount ? (
              <NumberFormatter
                value={-row.original?.discountAmount || 0}
                thousandSeparator=","
                // suffix="₫"
              />
            ) : null}
          </span>
        ),
      },
      {
        accessorKey: "discount",
        header: "Xin giảm",
        enableEditing: false,
        enableSorting: false,
        size: 120,
        Cell: ({ renderedCellValue, row }) => (
          <>
            <NumberInput
              disabled
              style={{ height: 35 }}
              size="sm"
              fw={"500"}
              hideControls
              thousandSeparator="."
              decimalSeparator=","
              value={row.original?.discountAdded}
              min={1}
              max={10000000}
              clampBehavior="strict"
              onKeyDown={handleKeyDown}
            />
          </>
        ),
      },
      {
        accessorKey: "priceFinal",
        header: "Thành tiền",
        size: 80,
        enableEditing: false,
        enableSorting: false,
        Cell: ({ renderedCellValue, row }) => {
          const { priceFinal, quality } = row.original;
          // Đảm bảo không có giá âm
          const displayedPrice = priceFinal * quality;

          return (
            <>
              <NumberFormatter
                value={calculateTotalLineAmount(row.original)}
                thousandSeparator="."
                decimalSeparator=","
              />
            </>
          );
        },
      },

      // {
      //   accessorKey: "note",
      //   header: "Ghi chú",
      //   enableSorting: false,
      //   // enableEditing: false,
      //   size: 100,
      //   Cell: ({ renderedCellValue, row, column }) => renderedCellValue,
      //   mantineEditTextInputProps: ({ cell, row }) => ({
      //     disabled: row.original.lineTyle === "HH",
      //     onBlur: (event) => {
      //       setListAdd((prevData) => {
      //         return prevData.map((item, i) =>
      //           i === row.index
      //             ? { ...item, note: event.currentTarget.value }
      //             : item
      //         );
      //       });
      //     },
      //   }),
      // },
      {
        accessorKey: "exchangeReturn",
        header: "Trường hợp Đổi/Trả",
        enableSorting: false,
        enableEditing: false,
        size: 220,
        Cell: ({ row }) =>
          !(
            row?.original?.lineTyle === "HH" ||
            row?.original?.lineTyle === "KM" ||
            row?.original?.lineTyle === "HK"
          ) ? (
            <select
              value={row.original.attribute2}
              onChange={(event) => {
                setListAdd((prevData) => {
                  return prevData.map((item, i) =>
                    i === row.index
                      ? { ...item, attribute2: event.currentTarget.value }
                      : item
                  );
                });
              }}
              style={selectStyle}
            >
              <option value="" style={optionStyle}>
                Chọn
              </option>

              {/* Render các tùy chọn từ mảng returnReasons */}
              {returnReasons.map((reason, index) => (
                <option key={index} value={reason.value} style={optionStyle}>
                  {reason.label}
                </option>
              ))}
            </select>
          ) : (
            <Input disabled></Input>
          ),
      },
      {
        accessorKey: "attribute6",
        header: "Tình trạng",
        enableSorting: false,
        enableEditing: false,
        size: 150,
        Cell: ({ row }) =>
          !(
            row?.original?.lineTyle === "HH" ||
            row?.original?.lineTyle === "KM" ||
            row?.original?.lineTyle === "HK"
          ) ? (
            <select
              value={row.original.attribute6}
              onChange={(event) => {
                setListAdd((prevData) => {
                  return prevData.map((item, i) =>
                    i === row.index
                      ? { ...item, attribute6: event.currentTarget.value }
                      : item
                  );
                });
              }}
              style={selectStyle}
            >
              <option value="" style={optionStyle}>
                Chọn
              </option>
              <option value="new" style={optionStyle}>
                Mới 100%
              </option>
              <option value="used" style={optionStyle}>
                Đã sử dụng
              </option>
            </select>
          ) : (
            <Input disabled></Input>
          ),
      },
      {
        accessorKey: "attribute7",
        header: "Phụ kiện",
        enableSorting: false,
        enableEditing: false,
        size: 110,
        Cell: ({ row }) =>
          !(
            row?.original?.lineTyle === "HH" ||
            row?.original?.lineTyle === "KM" ||
            row?.original?.lineTyle === "HK"
          ) ? (
            <select
              value={row.original.attribute7}
              onChange={(event) => {
                setListAdd((prevData) => {
                  return prevData.map((item, i) =>
                    i === row.index
                      ? { ...item, attribute7: event.currentTarget.value }
                      : item
                  );
                });
              }}
              style={selectStyle}
            >
              <option value="" style={optionStyle}>
                Chọn
              </option>
              <option value="du" style={optionStyle}>
                Đủ
              </option>
              <option value="thieu" style={optionStyle}>
                Thiếu
              </option>
            </select>
          ) : (
            <Input disabled></Input>
          ),
      },
      {
        accessorKey: "attribute8",
        header: "Trạng thái",
        enableSorting: false,
        enableEditing: false,
        size: 180,
        Cell: ({ row }) =>
          !(
            row?.original?.lineTyle === "HH" ||
            row?.original?.lineTyle === "KM" ||
            row?.original?.lineTyle === "HK"
          ) ? (
            <select
              value={row.original.attribute8}
              onChange={(event) => {
                setListAdd((prevData) => {
                  return prevData.map((item, i) =>
                    i === row.index
                      ? { ...item, attribute8: event.currentTarget.value }
                      : item
                  );
                });
              }}
              style={selectStyle}
            >
              <option value="" style={optionStyle}>
                Chọn
              </option>
              <option value="loi" style={optionStyle}>
                Lỗi
              </option>
              <option value="hoat_dong_tot" style={optionStyle}>
                Hoạt động tốt
              </option>
            </select>
          ) : (
            <Input disabled></Input>
          ),
      },
      {
        accessorKey: "attribute4",
        header: "Trả hàng tính phí",
        enableSorting: false,
        enableEditing: false,
        size: 110,
        Cell: ({ row }) =>
          !(
            row?.original?.lineTyle === "HH" ||
            row?.original?.lineTyle === "KM" ||
            row?.original?.lineTyle === "HK"
          ) ? (
            <select
              value={row.original.attribute4}
              onChange={(event) => {
                setListAdd((prevData) => {
                  return prevData.map((item, i) =>
                    i === row.index
                      ? { ...item, attribute4: event.currentTarget.value }
                      : item
                  );
                });
              }}
              style={selectStyle}
            >
              <option value="" style={optionStyle}>
                Chọn
              </option>
              <option value="0" style={optionStyle}>
                0%
              </option>
              <option value="5" style={optionStyle}>
                5%
              </option>
              <option value="10" style={optionStyle}>
                10%
              </option>
              <option value="15" style={optionStyle}>
                15%
              </option>
              <option value="20" style={optionStyle}>
                20%
              </option>
            </select>
          ) : (
            <Input disabled></Input>
          ),
      },
      {
        accessorKey: "attribute5",
        header: "Trừ theo lỗi vật lý",
        enableSorting: false,
        enableEditing: false,
        size: 110,
        Cell: ({ row }) =>
          !(
            row?.original?.lineTyle === "HH" ||
            row?.original?.lineTyle === "KM" ||
            row?.original?.lineTyle === "HK"
          ) ? (
            <NumberInput
              min={0}
              max={10000000}
              clampBehavior="strict"
              hideControls
              thousandSeparator="."
              decimalSeparator=","
              value={row.original.attribute5 || ""}
              onChange={(value) => {
                setListAdd((prevData) => {
                  return prevData.map((item, i) =>
                    i === row.index
                      ? { ...item, attribute5: value?.toString() }
                      : item
                  );
                });
              }}
            />
          ) : (
            <Input disabled></Input>
          ),
      },
      {
        accessorKey: "attribute12",
        header: "Trừ KM",
        size: 110,
        Cell: ({ row }) =>
          !(
            row?.original?.lineTyle === "HH" ||
            row?.original?.lineTyle === "KM" ||
            row?.original?.lineTyle === "HK"
          ) ? (
            <NumberInput
              min={0}
              max={10000000}
              clampBehavior="strict"
              hideControls
              thousandSeparator="."
              decimalSeparator=","
              value={row.original.attribute12 || ""}
              onChange={(value) => {
                setListAdd((prevData) => {
                  return prevData.map((item, i) =>
                    i === row.index
                      ? { ...item, attribute12: value?.toString() }
                      : item
                  );
                });
              }}
            />
          ) : (
            <Input disabled></Input>
          ),
      },

      {
        accessorKey: "attribute9",
        header: "Ghi chú kiểm tra",
        enableSorting: false,
        // enableEditing: false,
        size: 10,
        Cell: ({ renderedCellValue, row, column }) => renderedCellValue,
        mantineEditTextInputProps: ({ cell, row }) => ({
          disabled: row.original.lineTyle === "HH",
          onBlur: (event) => {
            setListAdd((prevData) => {
              return prevData.map((item, i) =>
                i === row.index
                  ? { ...item, attribute9: event.currentTarget.value }
                  : item
              );
            });
          },
        }),
      },
    ],
    [listAdd, dataProducts]
  );

  const table = useMantineReactTable<dataOrderDetailTable>({
    columns,
    data: listAdd,
    displayColumnDefOptions: {
      "mrt-row-actions": {
        header: "Thao tác",
      },
    },
    positionToolbarAlertBanner: "bottom",
    createDisplayMode: "row",
    editDisplayMode: "cell",
    enableEditing: true,
    enableRowActions: true,
    enableColumnActions: false,
    enableColumnOrdering: false,
    enableTopToolbar: false,
    enableBatchRowSelection: true,
    positionActionsColumn: "last",
    enableExpandAll: false,

    renderRowActions: ({ row, table }) => (
      <>
        {row?.original?.lineTyle !== "HT" &&
        row?.original?.lineTyle !== "HD" ? (
          <Flex gap="4">
            <Tooltip label="Trả hàng">
              <ActionIcon variant="outline" color="red">
                <IconReceiptRefund
                  onClick={() => {
                    handleCheckAddProduct(row.original, "HT");
                  }}
                />
              </ActionIcon>
            </Tooltip>
          </Flex>
        ) : (
          <Flex gap="4">
            <Tooltip label="Xoá sản phẩm">
              <ActionIcon variant="outline" color="red">
                <IconTrash onClick={() => openDeleteConfirmModal(row)} />
              </ActionIcon>
            </Tooltip>
          </Flex>
        )}
      </>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <>
        <Flex
          gap="md"
          justify="flex-start"
          align="center"
          direction="row"
          wrap="wrap"
          mb={"xs"}
          style={{ opacity: 0 }}
        ></Flex>
      </>
    ),

    renderToolbarInternalActions: ({ table }) => <></>,

    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localizationSales,
    getRowId: (row) => row.id?.toString(),
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    initialState: {
      expanded: true,
      columnPinning: {
        left: ["mrt-row-select", "mrt-row-expand"],
        right: ["mrt-row-actions"],
      },
      columnVisibility: { id: true },
      density: "xs",
    },

    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    manualSorting: false,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        ...styleCellTable(row),
        ...getStyle(),
      },
    }),
    enablePagination: false,
    enableBottomToolbar: false,
    state: {
      rowSelection,
    },
    enableColumnPinning: true,
    mantineTableProps: {},
    mantineTableBodyRowProps: ({ row }) => ({
      style: {
        backgroundColor:
          row?.original?.lineTyle === "HH" ||
          row?.original?.lineTyle === "KM" ||
          row?.original?.lineTyle === "HK"
            ? "#D3D3D3"
            : "white",
      },
    }),
  });
  //#endregion Table sản phẩm

  //#region useEffect

  useHotkeys([
    ["mod+J", () => console.log("Toggle color scheme")],
    [
      "ctrl+F5",
      () => {
        //  openNew();
      },
    ],
    [
      "F5",
      () => {
        //  openNew();
      },
    ],
    ["alt+mod+shift+X", () => console.log("Rick roll")],
    [
      "F11",
      () => {
        // openNew(true);
      },
    ],
    ["ctrl+M", () => toggleCollapse()],
  ]);

  useEffect(() => {
    const userLogin = localStorage.getItem("userLogin");

    if (userLogin) {
      const userLoginObject = JSON.parse(userLogin);
      setEmployeeSalesName(userLoginObject?.fullName);
      setDepartment(userLoginObject?.depName);
      setDepartmentId(userLoginObject?.departmentId);
    }
  }, []);

  function checkAttribute2T9(listAdd: any[]) {
    setIsHaveT9(listAdd.some((item) => item.attribute2 === "T9"));
  }

  useEffect(() => {
    let priceTotal = 0;
    listAdd?.forEach((item) => {
      if (item.lineTyle !== "HD" && item.lineTyle !== "HT") {
        priceTotal += (item?.priceOnPriceList || 0) * (item?.quality || 0);
      }
    });

    const totalDiscount = listAdd.reduce((acc, item) => {
      return acc + (item.priceOnPriceList - item.priceFinal);
    }, 0);
    setPayableAmount(priceTotal - totalDiscount);

    // tính tổng tiền trả cho khách khi khách trả lại hàng
    function calculateTotalPriceDifference(
      items: dataOrderDetailTable[]
    ): number {
      const htItems = items.filter((item) => item.lineTyle === "HT");
      const totalAmount = htItems.reduce((total, item) => {
        return total + calculateTotalLineAmount(item);
      }, 0);
      // trừ thêm số tiền xin giảm tổng đơn , km trên tổng đơn (những thứ ảnh hướng đến giá)
      return totalAmount + orderHeader?.discountAddedTotal;
    }

    setPayableAmountReturn(calculateTotalPriceDifference(listAdd));
    checkAttribute2T9(listAdd);
  }, [listAdd, orderHeader?.discountAddedTotal]);

  function getStyle(): CSSProperties {
    const isDisabled =
      location?.state?.actionType === "view" ||
      orderHeader?.orderStatus === "DON_HANG_CHOT";

    return {
      opacity: isDisabled ? 0.9 : 1,
      pointerEvents: isDisabled ? "none" : "auto",
    };
  }

  // hàm lấy data ban đầu
  useEffect(() => {
    const fetchDataBranch = async () => {
      const response = await repositoryPos.get<
        MessageResponse<TblDMPaymentMethod[]>
      >("/api/v1/TblDmInventory/get-select-branch?auth=false");

      if (response && response.success) {
        let result = response.data;
        setListBranch(result);
      }
    };

    const fetchDataSaleChanelId = async () => {
      const response = await repositoryMdm.get<MessageResponse<any[]>>(
        "/api/v1/TblDmSaleChanel/get-all"
      );

      if (response && response.success) {
        let result = response.data;
        setSaleChanelList(result);
      }
    };

    const fetchAllDepartment = async () => {
      const response = await repositoryPos.get<
        MessageResponse<DepartmentModel[]>
      >(`/api/v1/TblDepartment/get-all`);

      if (response && response.success) {
        let result = response.data;
        setListDepartment(result);
      } else {
      }
    };

    Promise.all([
      fetchDataSaleChanelId(),
      fetchAllDepartment(),
      fetchDataBranch(),
    ]);
  }, []);

  useEffect(() => {
    const fetchEmployeeByDepartmentId = async () => {
      if (departmentId) {
        const response = await repositoryPos.get<MessageResponse<any>>(
          `/api/v1/TblDmEmployee/get-list-by-department?depId=${departmentId}`
        );
        if (response && response.success) {
          let result = response.data;
          setListEmployeeSales(result);
        }
      }
    };
    fetchEmployeeByDepartmentId();
  }, [departmentId]);

  useEffect(() => {
    const fetchItemSell = async () => {
      const keySearch = valueDebounced.toLowerCase();
      const url = `/api/v1/TblItem/search-sell?inv=${branchId}&key=${keySearch}`;
      try {
        const searchResult = await repositoryPos.get<
          MessageResponse<ProductModel[]>
        >(url);
        if (searchResult?.data) {
          setDataProducts(searchResult.data);
        } else {
          setDataProducts([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setDataProducts([]);
      }
    };
    if (valueDebounced && valueDebounced.length > 2) {
      fetchItemSell();
    } else {
      setDataProducts([]);
    }
  }, [valueDebounced, branchId]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 160 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (300 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  //#endregion

  // đơn trả lại

  // tạo đơn trả lại
  useEffect(() => {
    const handleChangeDataInittialReturnOrder = (result: any) => {
      setOrderHeader({
        ...result.orderHeader,
        attribute3: "",
        orderStatus:
          location?.state?.actionType === "view" ? "DON_HANG_CHOT" : "noCreate",
        orderNumber: result?.orderNumber,
      });
    };
    if (location?.state?.orderNumber) {
      setOrderNumberRef(location?.state?.orderNumber);
      const fetchDataReturn = async () => {
        const response = await repositoryPos.get<MessageResponse<any>>(
          `/api/v1/CreateSellItem/return-sale-order?orderNumber=${location?.state?.orderNumber}&type=2`
        );

        if (response && response.success) {
          let result = response.data;
          handleChangeDataInittialReturnOrder(result);
          if (!result?.orderDetail) return;

          // Tạo một bản sao của result.orderDetail để tránh sửa đổi trực tiếp
          if (branchId) {
            const updatedOrderDetail = await Promise.all(
              result.orderDetail.map(async (item: any) => {
                const callapi = await repositoryPos.post<
                  MessageResponse<ItemDetailPriceModel>
                >(
                  `/api/v1/TblPriceListDetail/detail-price?id=${item.itemId}&BranchId=${branchId}`
                );

                // Gán giá từ API vào item
                return {
                  ...item,
                  priceOnPriceList: callapi?.data?.listPrice, // giả sử giá trả về từ API được lưu trong `price`
                };
              })
            );
            setListAdd(updatedOrderDetail);
          }
        }
      };
      fetchDataReturn();
    }
  }, [location, branchId]);

  // // xem chi tiết đơn trả lại
  useEffect(() => {
    if (location?.state?.actionType === "view") {
      const fetchDataReturn = async () => {
        const response = await repositoryPos.get<MessageResponse<any>>(
          `/api/v1/CreateSellItem/details?orderNumber=${location?.state?.orderNumber}&returnOrder=1&ref=true`
        );

        if (response && response.success) {
          let result = response.data;
          setOrderNumberRef(result?.orderHeader?.orderNumber);
          setOrderHeader({
            ...result?.orderHeader,
            amountTotal: result?.orderHeaderRef?.amountTotal,
          });
          setBranchId(result?.orderHeader?.invOrgId);

          if (!result?.orderDetail) return;

          // Tạo một bản sao của result.orderDetail để tránh sửa đổi trực tiếp
          if (branchId) {
            const updatedOrderDetail = await Promise.all(
              result.orderDetail.map(async (item: any) => {
                const callapi = await repositoryPos.post<
                  MessageResponse<ItemDetailPriceModel>
                >(
                  `/api/v1/TblPriceListDetail/detail-price?id=${item.itemId}&BranchId=${branchId}`
                );

                // Gán giá từ API vào item
                return {
                  ...item,
                  priceOnPriceList: callapi?.data?.listPrice, // giả sử giá trả về từ API được lưu trong `price`
                };
              })
            );
            setListAdd(updatedOrderDetail);
          }
        }
      };
      fetchDataReturn();
    }
  }, [location, branchId]);

  useEffect(() => {
    // if (!location?.state || location?.state?.actionType === "view") return;
    const handleFetchDataCustomer = async () => {
      if (orderHeader?.custId) {
        const customerReturn = await repositoryPos.get<MessageResponse<any>>(
          `/api/v1/TblDmCustomer/get-detail?id=${orderHeader?.custId}`
        );
        setCustomerCheck((prev) => ({
          ...prev,
          active: customerReturn?.data?.active ?? false,
          address: customerReturn?.data?.address ?? "",
          birthOfDate: customerReturn?.data?.birthOfDate ?? null,
          code: customerReturn?.data?.code ?? "",
          contact: customerReturn?.data?.contact ?? "",
          description: customerReturn?.data?.description ?? "",
          email: customerReturn?.data?.email ?? "",
          fax: customerReturn?.data?.fax ?? "",
          groupId: customerReturn?.data?.groupId ?? 0,
          id: customerReturn?.data?.id ?? 0,
          name: customerReturn?.data?.name ?? "",
          sex: customerReturn?.data?.sex ?? null,
          taxCode: customerReturn?.data?.taxCode ?? "",
          telephoneNumber: customerReturn?.data?.telephoneNumber ?? "",
          type: customerReturn?.data?.type ?? 0,
        }));
      }
    };
    handleFetchDataCustomer();
  }, [orderHeader, location?.state]);

  //region logic chọn kế toán
  const [isAccountant, setIsAccountant] = useState(false);
  const [dataAccountant, setDataAccountant] = useState<any[]>([]);
  const [accountantId, setAccountantId] = useState(0);
  const [accountantName, setAccountantName] = useState("");

  useEffect(() => {
    const fetchDataAccountant = async () => {
      try {
        const response = await repositoryPos.get<MessageResponse<any>>(
          `/api/v1/TblDmEmployee/get-list-accountant-employee?Skip=0&Take=99`
        );
        if (response && response.success) {
          let result = response.data;

          setDataAccountant(result);
        }
      } catch (error) {
        setIsAccountant(false);
      }
    };
    fetchDataAccountant();
  }, [orderHeader?.orderNumber]);

  useEffect(() => {
    const handleFetchDataByBranchId = async () => {
      const response = await repositoryPos.get<MessageResponse<any>>(
        `/api/v1/TblDmEmployee/get-list-accountant-employee?BranchId=${branchId}`
      );
      if (response && response.success) {
        let result = response.data;

        setDataAccountant(result);
      }
    };

    if (branchId) {
      handleFetchDataByBranchId();
    }
  }, [branchId]);

  useEffect(() => {
    if (!location?.state) return;
    fetchDetailEmployeeSales(orderHeader?.saleId);
    setContractcode(orderHeader?.contractcode);
    handleGetNameAccountantById(orderHeader?.attribute2);
    setAccountantId(orderHeader?.attribute2 || 0);
    const fetchDataListSerial = async (orderNumber: string) => {
      if (orderNumber) {
        const response = await repositoryPos.get<MessageResponse<[]>>(
          `/api/v1/TblItemTransaction/get-serial-history-order?OrderNumber=${orderNumber}`
        );

        if (response && response.success) {
          let result = response?.data;
          const serialArray = result.map((item: any) => item.serial);
          setListSerial(serialArray);
        }
      }
    };
    if (location?.state?.orderNumber) {
      fetchDataListSerial(removeSuffix(orderHeader?.orderNumber));
    }
  }, [orderHeader, location]);

  useEffect(() => {
    if (branchId && branchName) return;

    // Xử lý branchId
    const newBranchId = orderHeader?.orgId || 0;
    if (branchId !== newBranchId) {
      setBranchId(newBranchId);
    }

    // Xử lý branchName
    const name = listBranch?.find(
      (item: any) => item?.value == orderHeader?.orgId
    )?.text;
    if (branchName !== name) {
      setBranchName(name || "");
    }
  }, [orderHeader, listBranch]);

  // logic thanh toan don hang tra
  useEffect(() => {
    if (location?.state?.actionType === "payment") {
      const fetchDataPaymentMethod = async () => {
        if (location.state?.orderNumber) {
          const url = `/api/v1/CreateSellItem/payment-return?orderNumber=${location.state?.orderNumber}`;

          const response = await repositoryPos.get<MessageResponse<any>>(url);

          if (response && response.success) {
            let result = response.data;
            setOrderHeader({ ...result.orderHeader });
            setBranchId(result?.orderHeader?.orgId);
            const branchName = listBranch?.find(
              (item: any) => item?.id == result?.orderHeader?.orgId
            );

            // Kiểm tra nếu branchName tồn tại trước khi set
            if (branchName) {
              setBranchName(branchName?.text);
            }
            if (!result?.orderDetail) return;
            setListAdd(result.orderDetail);
          }
        }
      };
      fetchDataPaymentMethod();
    }
  }, [location, listBranch]);

  return (
    <Box>
      <div
        style={{
          ...getStyle(),
        }}
      >
        {titleSell()}
      </div>
      <PanelGroup direction="horizontal" id="group" style={{ width: "100%" }}>
        <Panel
          defaultSize={isCollapse ? 0 : 40}
          minSize={isCollapse ? 0 : 35}
          maxSize={65}
          collapsible
          style={{
            transition: "0.3s ease",
            display: isCollapse ? "none" : "block",
          }}
        >
          <Collapse
            in={!isCollapse}
            transitionDuration={100}
            transitionTimingFunction="ease-in-out"
            h={"100%"}
          >
            <Card h="100%" p={0} shadow="sm" radius="md" withBorder>
              {formOrderHeader()}
            </Card>
          </Collapse>
        </Panel>

        {!isCollapse && <PanelResizeHandle id="resize-handle" />}

        <Panel
          defaultSize={isCollapse ? 100 : 60}
          minSize={35}
          maxSize={isCollapse ? 100 : 65}
          style={{
            width: isCollapse ? "100%" : "auto",
            height: "100%",
            transition: "width 0.3s ease",
          }}
        >
          <Flex direction={"column"}>
            <MantineReactTable table={table} />
            <Card
              shadow="sm"
              radius="md"
              withBorder
              p="md"
              style={{
                backgroundColor: "#f9f9f9",
                borderRadius: "10px",
                padding: "20px",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                ...getStyle(),
              }}
            >
              <Grid>
                {!isHaveT9 && (
                  <Grid.Col span={3} style={orderInfoStyle}>
                    <Text fw={500} style={infoLabelStyle}>
                      Tiền hoàn cho khách
                    </Text>
                    <NumberInput
                      variant="unstyled"
                      readOnly
                      value={
                        Math.abs(payableAmountReturn)
                          ? payableAmountReturn < 0
                            ? Math.abs(payableAmountReturn)
                            : 0
                          : 0
                      } // Giá trị hiện tại
                      min={0} // Không cho phép nhập số âm
                      step={1000} // Bước nhảy của giá trị khi thay đổi
                      hideControls
                      styles={{
                        input: {
                          fontWeight: "bold",
                          fontSize: "17px",
                          color: "red",
                        },
                      }}
                      thousandSeparator="."
                      decimalSeparator=","
                      onKeyDown={handleKeyDown}
                    />
                  </Grid.Col>
                )}
              </Grid>
            </Card>
          </Flex>
        </Panel>
      </PanelGroup>
    </Box>
  );
};

const orderInfoStyle = {
  display: "flex",
  flexDirection: "column" as "column",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center" as "center",
  padding: "10px",
  border: "1px solid #e0e0e0",
  borderRadius: "8px",
  margin: "5px",
};

const infoLabelStyle = {
  fontSize: "14px",
  color: "#555",
  marginBottom: "5px",
};

const infoValueStyle = {
  fontSize: "14px",
  fontWeight: "bold" as "bold",
  color: "#000",
};

const selectStyle = {
  padding: "8px",
  fontSize: "14px",
  borderRadius: "4px",
  border: "1px solid #ccc",
  backgroundColor: "#fff",
  width: "100%",
};

const optionStyle = {
  padding: "10px",
};

export default RetailOrder;
