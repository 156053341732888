import {
  ActionIcon,
  Badge,
  Box,
  Button,
  ComboboxItem,
  Flex,
  Grid,
  GridCol,
  Group,
  Input,
  Select,
  Title,
  Tooltip,
} from "@mantine/core";
import { modals } from "@mantine/modals";
import {
  IconActivity,
  IconBrandBootstrap,
  IconBuilding,
  IconBuildingStadium,
  IconBuildingStore,
  IconBuildingWarehouse,
  IconEdit,
  IconHandStop,
  IconPlus,
  IconSearch,
  IconTrash,
  IconUserCircle,
  IconUserCode,
  IconZoomReset,
} from "@tabler/icons-react";
import {
  MRT_ColumnDef,
  MRT_PaginationState,
  MRT_RowSelectionState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { NotificationExtension } from "../../_base/extension/NotificationExtension";
import Repository from "../../_base/helper/HttpHelper";
import { _localization } from "../../config/location";

import { MessageResponse } from "../../model/MessageResponse";
import "../../Styles/tab.component.css";
import {
  _timerDelayClick,
  repositoryMaster,
  repositoryPos,
} from "../../_base/_const/_constVar";
import { EmployeeModel } from "../../model/Employee";
import { useDebouncedState } from "@mantine/hooks";
import EmployeeEdit from "./EmployeeEdit";
import GroupAddInventoryAuth from "./GroupAddInventoryAuth";
import { formatDateTransfer } from "../../common/FormatDate/FormatDate";
import { AvatarUtils } from "../../common/ColorByName/AvatarUtils";
import _breadcrumb from "../../_base/component/_layout/_breadcrumb";
import {
  getBranchSelect,
  getTblDepartmentSelect,
  getTblDmEmployeeSelect,
  getTblRoleSelect,
} from "../../service/getSelectApi";
import EmployeeDetail from "./EmployeeDetail";
import { green } from "../../const/variables";

const EmployeeModelView = () => {
  //#region  table
  const headerRef = React.useRef<HTMLDivElement>(null);
  //data and fetching state
  const [data, setData] = useState<EmployeeModel[]>([]);
  const [isError, setIsError] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);
  // const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  //
  const [selectIds, setSelectIds] = useState<string[]>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  //count
  const [dataBranch, setDataBranch] = useState<ComboboxItem[]>([]);
  const [dataDepartment, setDataDepartment] = useState<ComboboxItem[]>([]);
  const [dataEmployee, setDataEmployee] = useState<ComboboxItem[]>([]);
  const [dataRole, setDataRole] = useState<ComboboxItem[]>([]);

  const [search, setSearch] = useDebouncedState(
    {
      roleId: "",
      departmentId: "",
      managerId: "",
      branchId: "",
      keySearch: "",
    },
    300
  );

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);

  useEffect(() => {
    fetchData();
  }, [pagination.pageIndex, pagination.pageSize]);

  const handleChangeSearchValue = (value: string, key: string) => {
    setSearch((prevData) => ({ ...prevData, [key]: value }));
  };

  const fetchDataEmployee = async () => {
    const response = await getTblDmEmployeeSelect();
    setDataEmployee(
      response.map((employee) => ({
        value: employee.value,
        label: employee.text,
      }))
    );
  };

  const fetchDataRole = async () => {
    const response = await getTblRoleSelect();
    const mappedData = response
      .filter(
        (item) => item.value != null && item.text != null && item.value !== "-1"
      )
      .map((item) => ({
        value: item.value,
        label: `${item.text}`,
      }));
    setDataRole(mappedData);
  };

  const fetchDataBranch = async () => {
    const getDataBranch = await getBranchSelect();

    const mappedData = getDataBranch
      .filter(
        (item) => item.value != null && item.text != null && item.value !== "-1"
      )
      .map((item) => ({
        value: item.value,
        label: `${item.text}`,
      }));
    setDataBranch(mappedData);
  };

  const fetchDataDepartment = async () => {
    const getDataDepartment = await getTblDepartmentSelect();

    const mappedData = getDataDepartment
      .filter(
        (item) => item.value != null && item.text != null && item.value !== "-1"
      )
      .map((item) => ({
        value: item.value,
        label: `${item.text}`,
      }));
    setDataDepartment(mappedData);
  };

  const fetchData = async () => {
    //  setIsLoading(true);
    // setIsRefetching(true);
    let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }`;

    if (search.roleId) {
      url += `&RoleId=${search.roleId}`;
    }
    if (search.departmentId) {
      url += `&DepartmentId=${search.departmentId}`;
    }
    if (search.managerId) {
      url += `&ManagerId=${search.managerId}`;
    }
    if (search.branchId) {
      url += `&BranchId=${search.branchId}`;
    }

    if (search.keySearch) {
      url += `&KeySearch=${search.keySearch}`;
    }
    try {
      const response = await repositoryPos.get<
        MessageResponse<EmployeeModel[]>
      >(`/api/v1/TblDmEmployee/get-list${url}`);
      if (response && response.success) {
        setData(response.data ?? []);
        setRowCount(response.totalCount);
        setSelectIds([]);
        table.resetRowSelection();
      }
    } catch (error) {
      setIsError(true);
      console.error(error);
      return;
    }
    //  setIsError(false);
    // setIsLoading(false);
    //  setIsRefetching(false);
  };

  const activeData = async (status: boolean) => {
    if (selectIds.length < 1)
      NotificationExtension.Success("Bạn chưa chọn dữ liệu !");
    else
      try {
        const res = await repositoryPos.post<MessageResponse<boolean>>(
          "/api/v1/TblDmEmployee/actives",
          {
            ids: selectIds,
            status: status,
          }
        );
        console.log(res);
        if (res && res.success) {
          NotificationExtension.Success("Cập nhật thành công !");
          await fetchData();
        } else NotificationExtension.Fails("Cập nhật thất bại !");
      } catch (error) {
        console.error(error);
        return;
      }
  };
  const activesData = async (status: boolean, id: string) => {
    if (!id || id.length < 1)
      NotificationExtension.Success("Bạn chưa chọn dữ liệu !");
    else
      try {
        const res = await repositoryPos.post<MessageResponse<boolean>>(
          "/api/v1/TblDmEmployee/actives",
          {
            ids: [id],
            status: status,
          }
        );
        console.log(res);
        if (res && res.success) {
          NotificationExtension.Success("Cập nhật thành công !");
          await fetchData();
        } else NotificationExtension.Fails("Cập nhật thất bại !");
      } catch (error) {
        console.error(error);
        return;
      }
  };
  const deletesData = async (ids: string[]) => {
    if (ids.length < 1)
      NotificationExtension.Success("Bạn chưa chọn dữ liệu !");
    else
      try {
        modals.openConfirmModal({
          title: (
            <>
              <Title order={5}>Xóa !</Title>
            </>
          ),

          size: "550px",
          children: <>Bạn có thực sự muốn xóa ?</>,
          labels: { confirm: "Lưu lại", cancel: "Hủy bỏ" },
          onConfirm: async () => {
            const res = await repositoryPos.post<MessageResponse<boolean>>(
              "/api/v1/TblDmEmployee/delete",
              ids
            );
            if (res)
              if (res.success) {
                NotificationExtension.Success("Xóa thành công !");
                await fetchData();
              } else NotificationExtension.Fails("Xóa thất bại !");
          },
        });
      } catch (error) {
        console.error(error);
        return;
      }
  };

  function editItem(id: number) {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Chỉnh sửa !</Title>
        </>
      ),
      size: "650px",
      children: <EmployeeEdit id={id} onLoad={fetchData} />,
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  }

  function detailItem(id: number) {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Chi tiết !</Title>
        </>
      ),
      size: "650px",
      children: <EmployeeDetail id={id} />,
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  }

  function getColorStatus(text: boolean) {
    switch (text) {
      case true:
        return "teal";
      default:
        return "red";
    }
  }

  function getGender(text: number) {
    switch (text) {
      case 0:
        return "";
      case 1:
        return "green";
      default:
        return "gray";
    }
  }
  function getGenderText(text: number) {
    switch (text) {
      case 0:
        return "Nam";
      case 1:
        return "Nữ";
      default:
        return "Không xác định";
    }
  }
  const groupAddInventoryModal = (idEmp: number) => {
    modals.closeAll();

    modals.openConfirmModal({
      title: "Danh sách kho theo chi nhánh",

      size: "80%",
      fullScreen: false,
      children: (
        <GroupAddInventoryAuth idAuthozire={idEmp}></GroupAddInventoryAuth>
      ),
      labels: { confirm: "Confirm", cancel: "Hủy bỏ" },
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };
  function formatDate(isoDateString: string): string {
    const date: Date = new Date(isoDateString);

    // Cộng thêm 7 tiếng
    date.setHours(date.getHours() + 7);
    // Lấy các thành phần ngày giờ sau khi cộng thêm số giờ
    const hours: string = date.getHours().toString().padStart(2, "0");
    const minutes: string = date.getMinutes().toString().padStart(2, "0");
    const seconds: string = date.getSeconds().toString().padStart(2, "0");
    const day: string = date.getDate().toString().padStart(2, "0");
    const month: string = (date.getMonth() + 1).toString().padStart(2, "0"); // Tháng bắt đầu từ 0
    const year: number = date.getFullYear();

    // Định dạng thành chuỗi "giờ:phút:giây ngày-tháng-năm"
    return `${day}-${month}-${year}`;
  }
  const columns = React.useMemo<MRT_ColumnDef<EmployeeModel>[]>(
    () => [
      {
        accessorKey: "id",
        header: "Id",
      },
      {
        accessorKey: "code",
        header: "Mã nhân viên",
        Cell: ({ renderedCellValue, row }) => (
          <Tooltip label="Xem chi tiết">
            <Badge
              radius="sm"
              variant="dot"
              size="lg"
              style={{ cursor: "pointer" }}
              color={renderedCellValue === null ? "red" : green.base}
              onDoubleClick={() => detailItem(row.original.id)}
            >
              {renderedCellValue === null ? null : renderedCellValue}
            </Badge>
          </Tooltip>
        ),
      },
      {
        accessorKey: "fullname",
        header: "Tên nhân viên",
        maxSize: 250,
        minSize: 250,
        Cell: ({ renderedCellValue, row }) => (
          <>
            <AvatarUtils
              value={row.original.fullname?.toString().split("-")[0]}
            />
          </>
        ),
      },
      {
        accessorKey: "email",
        header: "Email",
        //  enableGrouping: true,
        //   size: 50,
      },
      {
        accessorKey: "departmentName",
        header: "Phòng ban",
        //  enableGrouping: true,
        //   size: 50,
      },
      {
        accessorKey: "roleName",
        header: "Chức vụ",
        //  enableGrouping: true,
        //   size: 50,
      },
      {
        accessorKey: "gender",
        header: "Giới tính",
        Cell: ({ renderedCellValue, row }) => (
          <>
            <Badge color={getGender(row.original.gender)}>
              {getGenderText(row.original.gender)}
            </Badge>
          </>
        ),
      },
      {
        accessorKey: "phoneNumber",
        header: "Điện thoại",
        //  enableGrouping: true,
        //   size: 50,
      },
      {
        accessorKey: "dateOfBirth",
        header: "Ngày sinh",
        Cell: ({ renderedCellValue, row }) => (
          <>
            {renderedCellValue &&
              formatDate(renderedCellValue?.toLocaleString() ?? "")}
          </>
        ),
      },
      {
        accessorKey: "address",
        header: "Địa chỉ",
        //  enableGrouping: true,
        //   size: 50,
      },

      {
        accessorKey: "active",
        header: "Trạng thái",
        Cell: ({ renderedCellValue, row }) => (
          <>
            <Badge color={getColorStatus(row.original.active ?? false)}>
              {row.original.active ? "Đang hoạt động" : "Dừng hoạt động"}
            </Badge>
          </>
        ),
      },

      {
        accessorKey: "description",
        header: "Ghi chú",
        //  enableGrouping: true,
        //   size: 50,
      },
      {
        accessorKey: "accountingCenterId",
        header: "Id trung tâm hạch toán",
        //  enableGrouping: true,
        //   size: 50,
      },

      {
        accessorKey: "managerName",
        header: "Người quản lý trực tiếp",
        Cell: ({ renderedCellValue, row }) => (
          <>
            {renderedCellValue && renderedCellValue?.toString().split("-")[0]}
          </>
        ),
      },
      {
        accessorKey: "createDate",
        header: "Ngày tạo",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
      },

      {
        accessorKey: "action",
        header: "Thao tác",
        Cell: ({ renderedCellValue, row, table }) => (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "16px",
            }}
          >
            {/* <Tooltip label="Nhóm">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="cyan"
                onClick={() => {
                  groupAddRoleModal(row.original.id.toString());
                }}
              >
                <IconFunction size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip> */}
            {/* <Tooltip label="Kho theo chi nhánh">
              <ActionIcon
                onClick={() => {
                  groupAddInventoryModal(row.original.id);
                }}
                variant="light"
                aria-label="Settings"
                color="indigo"
              >
                <IconBuildingWarehouse size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip> */}
            <Tooltip label="Chỉnh sửa">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="orange"
                onClick={async () => {
                  editItem(row.original.id);
                }}
              >
                <IconEdit size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>

            <Tooltip
              label={
                row.original.active === true ? "Dừng hoạt động" : "Hoạt động"
              }
            >
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color={row.original.active === true ? "violet" : "green"}
                onClick={async () => {
                  await activesData(
                    !row.original.active,
                    row.original.id.toString()
                  );
                }}
              >
                {row.original.active === true ? (
                  <IconHandStop size={20} />
                ) : (
                  <IconActivity size={20} />
                )}
              </ActionIcon>
            </Tooltip>

            <Tooltip label="Xóa">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="red"
                onClick={async () => {
                  await deletesData([row.original.id.toString()]);
                }}
              >
                <IconTrash size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
          </Box>
        ),
      },
    ],
    []
  );

  const [height, setHeight] = useState(0);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 160 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (200 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  const table = useMantineReactTable({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    enablePagination: true,
    enableRowVirtualization: true,
    enableToolbarInternalActions: false,
    enableTopToolbar: false,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    enableRowSelection: true,
    getRowId: (row) => row.id?.toString(),
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select", "code", "fullname"],
        right: ["action"],
      },
      columnVisibility: { id: false },
      density: "xs",
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    // manualFiltering: true,
    manualPagination: true,
    rowCount,
    // onColumnFiltersChange: setColumnFilters,
    // onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    // onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        fontWeight: "bold", //conditional styling
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      // columnFilters,
      // globalFilter,
      // isLoading,
      pagination,
      // showAlertBanner: isError,
      // showProgressBars: isRefetching,
      // showSkeletons: isLoading, //loading for the first time with no data
      //sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["20", "50", "75"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
  });

  return (
    <>
      <div
        style={{ border: "1px solid #dee2e6", padding: "5px" }}
        ref={headerRef}
      >
        <Flex
          gap="md"
          justify={"space-between"}
          align="center"
          direction="row"
          wrap="wrap"
          w={"100%"}
        >
          <_breadcrumb></_breadcrumb>
          <Flex gap="md" align="center" direction="row" wrap="wrap">
            <Button
              leftSection={<IconActivity size={14} />}
              color="green"
              variant="outline"
              onClick={async () => {
                await activeData(true);
              }}
              disabled={selectIds.length < 1}
            >
              Hoạt động
            </Button>
            <Button
              leftSection={<IconHandStop size={14} />}
              color="red"
              variant="outline"
              onClick={async () => {
                await activeData(false);
              }}
              disabled={selectIds.length < 1}
            >
              Dừng hoạt động
            </Button>
            <Button
              leftSection={<IconTrash size={14} />}
              color="red"
              variant="outline"
              onClick={async () => {
                await deletesData(selectIds);
              }}
              disabled={selectIds.length < 1}
            >
              Xóa nhóm (đã chọn)
            </Button>
          </Flex>
        </Flex>

        <Grid mt={"10px"}>
          <GridCol span={{ base: 6, sm: 4, md: 2, lg: 1.5 }}>
            <Input
              w={"100%"}
              placeholder="Từ khoá"
              type="text"
              leftSection={<IconSearch color="#15aabf" />}
              onChange={(e) => {
                handleChangeSearchValue(e.target.value ?? "", "keySearch");
              }}
            />
          </GridCol>

          <GridCol span={{ base: 6, sm: 4, md: 2, lg: 1.5 }}>
            <Select
              searchable
              clearable
              placeholder="Chọn chi nhánh"
              comboboxProps={{
                transitionProps: { transition: "pop", duration: 200 },
              }}
              leftSection={<IconBuildingStore color="#15aabf" />}
              nothingFoundMessage={"Loading..."}
              data={dataBranch}
              onChange={(value) => {
                handleChangeSearchValue(value || "", "branchId");
              }}
              onClick={() => {
                if (dataBranch.length === 0) {
                  fetchDataBranch();
                }
              }}
            />
          </GridCol>

          <GridCol span={{ base: 6, sm: 4, md: 2, lg: 1.5 }}>
            <Select
              searchable
              clearable
              placeholder="Chọn phòng ban"
              comboboxProps={{
                transitionProps: { transition: "pop", duration: 200 },
              }}
              leftSection={<IconBuilding color="#15aabf" />}
              nothingFoundMessage={"Loading..."}
              data={dataDepartment}
              onChange={(value) => {
                handleChangeSearchValue(value || "", "departmentId");
              }}
              onClick={() => {
                if (dataDepartment.length === 0) {
                  fetchDataDepartment();
                }
              }}
            />
          </GridCol>

          <GridCol span={{ base: 6, sm: 4, md: 2, lg: 1.5 }}>
            <Select
              placeholder={"Chọn quản lý"}
              searchable
              clearable
              data={dataEmployee}
              leftSection={<IconUserCode color="#15aabf" />}
              comboboxProps={{
                transitionProps: { transition: "pop", duration: 200 },
              }}
              nothingFoundMessage={"Loading..."}
              onChange={(value) =>
                handleChangeSearchValue(value || "", "managerId")
              }
              onClick={() => {
                if (dataEmployee.length === 0) {
                  fetchDataEmployee();
                }
              }}
            />
          </GridCol>

          <GridCol span={{ base: 6, sm: 4, md: 2, lg: 1.5 }}>
            <Select
              placeholder={"Chọn chức vụ"}
              searchable
              clearable
              data={dataRole}
              leftSection={<IconUserCircle color="#15aabf" />}
              comboboxProps={{
                transitionProps: { transition: "pop", duration: 200 },
              }}
              nothingFoundMessage={"Loading..."}
              onChange={(value) =>
                handleChangeSearchValue(value || "", "roleId")
              }
              onClick={() => {
                if (dataRole.length === 0) {
                  fetchDataRole();
                }
              }}
            />
          </GridCol>

          <GridCol span={{ base: 4, sm: 4, md: 1.5, lg: 1.5 }}>
            <Button
              leftSection={<IconSearch size={14} />}
              color="blue"
              variant="outline"
              onClick={async () => {
                await fetchData();
              }}
            >
              Tìm kiếm
            </Button>
          </GridCol>
        </Grid>
      </div>
      <div className="mt-5">
        <MantineReactTable table={table} />
      </div>
    </>
  );
};

export default EmployeeModelView;
