import {
  ActionIcon,
  Box,
  Button,
  Input,
  Select,
  Title,
  Tooltip,
} from "@mantine/core";
import { modals } from "@mantine/modals";
import {
  IconEdit,
  IconSearch,
  IconSwitch,
  IconZoomReset,
} from "@tabler/icons-react";
import {
  MRT_ColumnDef,
  MRT_Row,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useState } from "react";
import { repositoryMdm } from "../../../_base/_const/_constVar";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import { paginationBase } from "../../../_base/model/_base/BaseTable";
import { _localization } from "../../../config/location";
import "../../../Styles/tab.component.css";
import { DataPickerView } from "./DataPickerView";
import EditWarrantyRenewal from "./EditWarrantyRenewal";

const WarrantyRenewalList = () => {
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [isRefetching, setIsRefetching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [pagination, setPagination] = useState(paginationBase);
  const [height, setHeight] = useState(0);
  const [data, setData] = useState<WarrantyRenewalListItem[]>([]);
  const [deleteViewStatus, setDeleteViewStatus] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [search, setSearch] = useState({
    status: "",
    keySearch: "",
  });
  const handleChangeSearchValue = (value: string, key: string) => {
    setSearch((prevData) => ({ ...prevData, [key]: value }));
  };

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "itemCode",
        header: "Mã sản phẩm",
        size: 50,
      },
      {
        accessorKey: "itemName",
        header: "Tên sản phẩm",
        size: 30,
      },
      {
        accessorKey: "serial",
        header: "Mã serial",
        size: 30,
      },

      {
        accessorKey: "itemType",
        header: "Loại hàng",
        size: 30,
      },
      {
        accessorKey: "sell",
        header: "Bán",
        size: 30,
        Cell: ({ cell }) => (
          <div
            style={{
              backgroundColor: cell.getValue() ? "#46C25C" : "#F44336",
              color: "white",
              borderRadius: "10px",
              padding: "5px",
              width: "80px",
              textAlign: "center",
            }}
          >
            {cell.getValue() ? "Bán" : "Không bán"}
          </div>
        ),
      },
      {
        accessorKey: "active",
        header: "Trạng thái",
        size: 30,
        Cell: ({ cell }) => (
          <div
            style={{
              backgroundColor: cell.getValue() ? "#298FE7" : "#F8A793",
              color: "white",
              borderRadius: "10px",
              padding: "5px",
              width: "100px",
              textAlign: "center",
            }}
          >
            {cell.getValue() ? "Mới 100%" : "Đã qua sử dụng"}
          </div>
        ),
      },
      {
        accessorKey: "display",
        header: "Hàng trưng bày",
        size: 30,
        Cell: ({ cell }) => (
          <div
            style={{
              backgroundColor: cell.getValue() ? "#46C25C" : "#F44336",
              color: "white",
              borderRadius: "10px",
              padding: "5px",
              width: "80px",
              textAlign: "center",
            }}
          >
            {cell.getValue() ? "Trưng bày" : "Không trưng bày"}
          </div>
        ),
      },
      {
        accessorKey: "experience",
        header: "Trải Nghiệm",
        size: 30,
      },
      {
        accessorKey: "promotion",
        header: "Khuyến mãi",
        size: 50,
        Cell: ({ cell }) => (
          <div
            style={{
              backgroundColor: cell.getValue() ? "#298FE7" : "#F8A793",
              color: "white",
              borderRadius: "10px",
              padding: "5px",
              width: "50px",
              textAlign: "center",
            }}
          >
            {cell.getValue() ? "Có" : "Không"}
          </div>
        ),
      },
      {
        accessorKey: "warrantyRenew",
        header: "Đổi mới 100%",
        size: 30,
        Cell: ({ cell }) => (
          <div
            style={{
              backgroundColor: cell.getValue() ? "#46C25C" : "#F44336",
              color: "white",
              borderRadius: "10px",
              padding: "5px",
              width: "80px",
              textAlign: "center",
            }}
          >
            {cell.getValue() ? "Áp dụng" : "Không áp dụng"}
          </div>
        ),
      },
      {
        accessorKey: "warrantyRepair",
        header: "Sửa chữa",
        size: 30,
        Cell: ({ cell }) => (
          <div
            style={{
              backgroundColor: cell.getValue() ? "#46C25C" : "#F44336",
              color: "white",
              borderRadius: "10px",
              padding: "5px",
              width: "80px",
              textAlign: "center",
            }}
          >
            {cell.getValue() ? "Áp dụng" : "Không áp dụng"}
          </div>
        ),
      },
      {
        accessorKey: "warrantyExchange",
        header: "Đổi hàng (sử dụng)",
        // width: 250,
        Cell: ({ cell }) => (
          <div
            style={{
              backgroundColor: cell.getValue() ? "#46C25C" : "#F44336",
              color: "white",
              borderRadius: "10px",
              padding: "5px",
              width: "80px",
              textAlign: "center",
            }}
          >
            {cell.getValue() ? "Áp dụng" : "Không áp dụng"}
          </div>
        ),
      },
      {
        accessorKey: "billing",
        header: "Trả tính phí",
        size: 30,
        Cell: ({ cell }) => (
          <div
            style={{
              backgroundColor: cell.getValue() ? "#46C25C" : "#F44336",
              color: "white",
              borderRadius: "10px",
              padding: "5px",
              width: "80px",
              textAlign: "center",
            }}
          >
            {cell.getValue() ? "Áp dụng" : "Không áp dụng"}
          </div>
        ),
      },
      {
        accessorKey: "period",
        header: "Thời hạn ",
        size: 20,
      },
      {
        accessorKey: "endDate",
        header: "Ngày hết hạn",
        size: 30,
      },
      {
        accessorKey: "oldPeriod",
        header: "Thời hạn ( cũ )",
        size: 20,
      },
      {
        accessorKey: "oldEndDate",
        header: "Ngày hết hạn ( cũ )",
        size: 30,
      },
      {
        accessorKey: "employeeName",
        header: "Tên người sửa",
        size: 30,
      },
      {
        accessorKey: "lastUpdateDate",
        header: "Ngày cập nhật",
        size: 30,
      },

      {
        accessorKey: "action",
        header: "Thao tác",
        size: 10,

        Cell: ({ row }) => (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "4px",
            }}
          >
            {processTaskActionMenu(row)}
          </Box>
        ),
      },
    ],
    [data]
  );

  function processTaskActionMenu(row: MRT_Row<any>): any {
    return (
      <>
        <Tooltip label="Sửa">
          <ActionIcon variant="light" aria-label="Settings" color="yellow">
            <IconEdit size={20} onClick={() => handleEdit(row?.original.id)} />
          </ActionIcon>
        </Tooltip>
      </>
    );
  }

  const handleEdit = (id: number | string) => {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Chỉnh sửa</Title>
        </>
      ),
      size: "auto",
      children: <EditWarrantyRenewal id={id} onClose={setDeleteViewStatus} />,
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };

  const handleSearch = async () => {
    let termData;
    let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }`;

    if (search.keySearch) {
      url += `&KeySearch=${search.keySearch.trim()}`;
    }

    if (search.status) {
      url += `&Active=${search.status}`;
    }

    try {
      const response = await repositoryMdm.get(
        `/api/v1/TblSerialStatus/get-list${url}`
      );
      if (response && response.success) {
        termData = response.data;
      }
    } catch (error) {
      console.error(error);
      return;
    }
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Chọn thông tin</Title>
        </>
      ),
      size: "xl",
      children: <DataPickerView data={termData} setData={setData} />,
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setIsError(false);

    let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }`;

    if (search.keySearch) {
      url += `&KeySearch=${search.keySearch.trim()}`;
    }

    if (search.status) {
      url += `&Active=${search.status}`;
    }

    try {
      const response = await repositoryMdm.get(
        `/api/v1/TblSerialStatus/get-list${url}`
      );
      if (response && response.success) {
        let result = response.data;
        setData(result);
        setRowCount(response.totalCount);
        table.resetRowSelection();
      }
    } catch (error) {
      setData([]);
      setIsError(true);
      console.error(error);
      return;
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, [deleteViewStatus]);

  const table = useMantineReactTable({
    columns,
    data,
    rowCount,
    enableColumnFilters: true,
    enableSorting: true,
    enableColumnActions: true,
    state: {
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      // rowSelection,
    },
    manualFiltering: false,
    manualSorting: false,
    manualPagination: true,
    onPaginationChange: setPagination,
    positionToolbarAlertBanner: "bottom",
    renderTopToolbarCustomActions: ({ table }) => (
      <div ref={headerRef}>
        <_breadcrumb></_breadcrumb>
        <Box style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Select
            size="sm"
            placeholder="Trạng thái"
            searchable
            clearable
            data={[
              { value: "true", label: "Mới 100%" },
              { value: "false", label: "Đã qua sử dụng" },
            ]}
            style={{ flex: 1, maxWidth: "180px" }}
            leftSection={<IconSwitch size={20} />}
            onChange={(value) => handleChangeSearchValue(value ?? "", "status")}
          />
          <Input
            style={{ flex: 1, maxWidth: "180px" }}
            placeholder="Nhập từ khóa tìm kiếm"
            type="text"
            leftSection={<IconSearch size={"20"} />}
            onChange={(e) => {
              handleChangeSearchValue(e.target.value ?? "", "keySearch");
            }}
            // onKeyDown={(e) => {
            //   if (e.key === "Enter") {
            //     fetchData();
            //   }
            // }}
          />
          <Button
            color="blue"
            variant="outline"
            onClick={() => {
              handleSearch();
            }}
          >
            Tìm kiếm
          </Button>
        </Box>
      </div>
    ),
    renderToolbarInternalActions: ({ table }) => (
      <>
        <Button
          leftSection={<IconZoomReset size={14} />}
          color="violet"
          variant="outline"
          onClick={async () => {
            await fetchData();
          }}
        >
          Load data
        </Button>
      </>
    ),

    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select", "code"],
        right: ["action"],
      },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontSize: "11.5px",
        padding: "4px 12px",
      },
    }),
    mantineToolbarAlertBannerProps: false
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["10", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      setHeight(window.innerHeight - (200 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    fetchData();
  }, [pagination]);
  return (
    <>
      <div className="mt-5">
        <MantineReactTable table={table} />
      </div>
    </>
  );
};

export default WarrantyRenewalList;

export type WarrantyRenewalListItem = {
  active: boolean;
  billing: boolean;
  createBy: string;
  createDate: Date;
  display: boolean;
  experience: string;
  id: string;
  itemCode: string;
  itemName: string;
  itemType: string;
  lastUpdateBy: string;
  lastUpdateDate: string;
  promotion: boolean;
  sell: boolean;
  serial: string;
  warrantyExchange: boolean;
  warrantyRenew: boolean;
  warrantyRepair: boolean;
};
