import moment from "moment";

export const removeSuffix = (input: string | null | undefined): string => {
  if (!input) {
    return ""; // Trả về chuỗi rỗng nếu input là null hoặc undefined
  }
  return input.split("-")[0];
};

export const calculateTotalAttribute5 = (items: any): number => {
  return items?.reduce((total: number, item: any) => {
    return total + Number(item?.attribute5 || 0);
  }, 0);
};

export const handleGetTechnicialRequireName = (id: number) => {
  const options = [
    "Không",
    "Lắp ráp(mới)",
    "Kiểm tra(mới)",
    "Lắp ráp(mới) + Kiểm tra(mới)",
  ];

  return options[id];
};

export const handleGetTechnicialRequireId = (name: string) => {
  const options = [
    "Không",
    "Lắp ráp(mới)",
    "Kiểm tra(mới)",
    "Lắp ráp(mới) + Kiểm tra(mới)",
  ];

  return options.findIndex((item) => item === name);
};

export const handlerRenderOptionSelectAccountant = (object: any) => {
  if (object) {
    const newObject = object?.map((item: any) => {
      return item?.fullname;
    });
    return newObject;
  }
};

// hàm check sản phẩm đã chọn hay chưa
export const handleCheckChooseProduct = (
  product: any,
  listAdd: any[]
): boolean => {
  const found = listAdd.some(
    (item) => item.id === product.id && item.warehouse === product.warehouse
  );
  return found;
};

export const handlerRenderOptionSelect = (object: any) => {
  if (object) {
    const newObject = object?.map((item: any) => {
      return item?.name;
    });
    return newObject;
  }
};

export const formatDateString = (dateStr: string) => {
  if (dateStr === "") return "";
  const date = new Date(dateStr);
  const adjustedDate = date.setHours(date.getHours());
  return moment(new Date(adjustedDate)).format("DD-MM-YYYY HH:mm:ss");
};

export const formatOrderDate = (date: string | undefined) => {
  if (date && date.startsWith("0001")) {
    return "";
  }
  return date || ""; // Trả về giá trị gốc nếu không bắt đầu bằng "0001"
};

// * ghi chú action đơn bán hàng
// xem chi tiết : view
// sửa đơn: edit
// duyệt giảm : approved
// thanh toán : payment

// * ghi chú đơn trả hàng
// tạo mới : return
// xem chi tiết : view
// thanh toán : payment
