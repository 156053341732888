import {
  Button,
  Checkbox,
  Combobox,
  ComboboxItem,
  ComboboxOptionProps,
  Fieldset,
  Flex,
  Grid,
  Group,
  Input,
  MultiSelect,
  NumberInput,
  Pill,
  PillsInput,
  ScrollArea,
  Select,
  useCombobox,
} from "@mantine/core";
import { modals } from "@mantine/modals";
import { IconCheck, IconWindow } from "@tabler/icons-react";
import { useState } from "react";
import { SelectListItemBase } from "../../../../../_base/model/_base/SelectListItemBase";
import { listBranch } from "../../../../../model/TblPromotion";
import { sky_blue } from "../../../../../const/variables";

const ModalSuperMarket = ({
  data,
  handleAddBranch,
  dataTable,
}: {
  data: SelectListItemBase[];
  handleAddBranch: (
    option: ComboboxItem | null,
    quantity: number | string
  ) => void;
  dataTable: listBranch[];
}) => {
  const [selectedRow, setSelectedRow] = useState<string[]>([]);
  const [scrolled, setScrolled] = useState(false);
  const [selectBranch, setSelectBranch] = useState<ComboboxItem | null>(null);
  const [quantity, setQuantity] = useState<string | number>(0);
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => combobox.updateSelectedOptionIndex("active"),
  });

  const dataOption: { value: string; label: string }[] = data
    .filter(
      (branch) =>
        !dataTable.some((table) => table.id.toString() === branch.value)
    )
    .map((branch) => ({
      value: branch.value || "",
      label: branch.text || "",
    }));

  // const handleValueSelect = (val: string, optionProps: ComboboxOptionProps) => {
  //   console.log(44, optionProps);
  //   setSelectBranch((current) =>
  //     current.includes(val)
  //       ? current.filter((v) => v !== val)
  //       : [...current, val]
  //   );
  // };

  // const handleValueRemove = (val: string) =>
  //   setSelectBranch((current) => current.filter((v) => v !== val));

  // const selectedDetailSuperMarket =
  //   selectedSuperMarket === "Tất cả"
  //     ? []
  //     : data.find((item: any) => item.nameSuperMarket === selectedSuperMarket)
  //         ?.detailSuperMarket || [];

  // const handleCheckboxChange = (item: any, checked: boolean) => {
  //   const supermarket = data.find((supermarket: any) =>
  //     supermarket.detailSuperMarket.some(
  //       (detail: any) => detail.code === item.code
  //     )
  //   );

  //   if (!supermarket) return;

  //   if (checked) {
  //     setSelectedRow((prev) => [...prev, item.code]);
  //     setSelectedData((prev) => {
  //       const existing = prev.find((data) => data.code === supermarket.code);
  //       if (existing) {
  //         return prev.map((data) =>
  //           data.code === supermarket.code
  //             ? {
  //                 ...data,
  //                 detailSuperMarket: [...data.detailSuperMarket, item],
  //               }
  //             : data
  //         );
  //       }
  //       return [
  //         ...prev,
  //         {
  //           id: supermarket.id,
  //           code: supermarket.code,
  //           nameSuperMarket: supermarket.nameSuperMarket,
  //           detailSuperMarket: [item],
  //           quantity,
  //         },
  //       ];
  //     });
  //   } else {
  //     setSelectedRow((prev) => prev.filter((code) => code !== item.code));
  //     setSelectedData((prev) => {
  //       const existing = prev.find((data) => data.code === supermarket.code);
  //       if (existing) {
  //         const updatedDetails = existing.detailSuperMarket.filter(
  //           (detail: any) => detail.code !== item.code
  //         );
  //         if (updatedDetails.length > 0) {
  //           return prev.map((data) =>
  //             data.code === supermarket.code
  //               ? {
  //                   ...data,
  //                   detailSuperMarket: updatedDetails,
  //                 }
  //               : data
  //           );
  //         } else {
  //           return prev.filter((data) => data.code !== supermarket.code);
  //         }
  //       }
  //       return prev;
  //     });
  //   }
  // };

  // useEffect(() => {
  //   setSelectedData(existingDataTable);
  //   const selectedCodes = existingDataTable
  //     .flatMap((supermarket) => supermarket.detailSuperMarket)
  //     .map((detail) => detail.code);
  //   setSelectedRow(selectedCodes);
  // }, [existingDataTable]);

  // const options = dataOption.map((item) => (
  //   <Combobox.Option
  //     value={item.value}
  //     key={item.value}
  //     active={selectBranch.includes(item.value)}
  //   >
  //     <Group gap="sm">
  //       <Checkbox
  //         checked={selectBranch.includes(item.value)}
  //         onChange={() => {}}
  //         aria-hidden
  //         tabIndex={-1}
  //         style={{ pointerEvents: "none" }}
  //       />
  //       <span>{item.label}</span>
  //     </Group>
  //   </Combobox.Option>
  // ));

  return (
    <>
      <Fieldset legend="Danh sách trung tâm, siêu thị" m={"10px 0px"}>
        <Grid>
          <Grid.Col span={{ base: 12, md: 6, lg: 9 }}>
            <Select
              label="Trung tâm, siêu thị"
              checkIconPosition="left"
              data={dataOption}
              onChange={(value, option) => setSelectBranch(option || "")}
            />

            {/* <Combobox
              store={combobox}
              onOptionSubmit={handleValueSelect}
              withinPortal={false}
            >
              <Combobox.DropdownTarget>
                <PillsInput pointer onClick={() => combobox.toggleDropdown()}>
                  <Pill.Group>
                    {selectBranch.length > 0 ? (
                      selectBranch
                    ) : (
                      <Input.Placeholder>
                        Chọn trung tâm, siêu thị
                      </Input.Placeholder>
                    )}

                    <Combobox.EventsTarget>
                      <PillsInput.Field
                        type="hidden"
                        onBlur={() => combobox.closeDropdown()}
                        onKeyDown={(event) => {
                          if (event.key === "Backspace") {
                            event.preventDefault();
                            handleValueRemove(
                              selectBranch[selectBranch.length - 1]
                            );
                          }
                        }}
                      />
                    </Combobox.EventsTarget>
                  </Pill.Group>
                </PillsInput>
              </Combobox.DropdownTarget>

              <Combobox.Dropdown>
                <Combobox.Options>
                  <ScrollArea.Autosize type="scroll" mah={300}>
                    {options}
                  </ScrollArea.Autosize>
                </Combobox.Options>
              </Combobox.Dropdown>
            </Combobox> */}
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
            <NumberInput
              label="SL đơn hàng"
              value={quantity}
              onChange={(value) => setQuantity(value)}
              hideControls
            />
          </Grid.Col>
        </Grid>
        {/* <ScrollArea
          h={175}
          m={"10px 0px"}
          onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
        >
          <Table
            striped
            highlightOnHover
            withColumnBorders
            withTableBorder
            style={{ overflowY: "auto", height: "100px" }}
          >
            <Table.Thead
              className={cx(classes.header, { [classes.scrolled]: scrolled })}
            >
              <Table.Tr>
                <Table.Th>Áp dụng</Table.Th>
                <Table.Th>Mã</Table.Th>
                <Table.Th>Tên kho</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {selectedDetailSuperMarket.map((item: any) => (
                <Table.Tr
                  key={item.code}
                  bg={
                    selectedRow.includes(item.code)
                      ? "var(--mantine-color-blue-light)"
                      : undefined
                  }
                >
                  <Table.Td>
                    <Checkbox
                      checked={selectedRow.includes(item.code)}
                      onChange={(event) =>
                        handleCheckboxChange(item, event.currentTarget.checked)
                      }
                    />
                  </Table.Td>
                  <Table.Td>{item.code}</Table.Td>
                  <Table.Td>{item.name}</Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table>
        </ScrollArea> */}
      </Fieldset>
      <Flex align={"center"} justify={"center"} gap={10}>
        <Button
          color="gray"
          onClick={() => {
            modals.closeAll();
          }}
          leftSection={<IconWindow size={18} />}
        >
          Đóng
        </Button>
        <Button
          color={sky_blue.base}
          leftSection={<IconCheck size={18} />}
          disabled={!selectBranch}
          onClick={() => {
            handleAddBranch(selectBranch, quantity);
          }}
        >
          Xác nhận
        </Button>
      </Flex>
    </>
  );
};

export default ModalSuperMarket;
