import {
  Badge,
  Box,
  Button,
  Checkbox,
  ComboboxItem,
  Divider,
  Flex,
  Grid,
  Input,
  Menu,
  NumberFormatter,
  Paper,
  rem,
  Select,
  Switch,
  Table,
  Tabs,
  Textarea,
  TextInput,
  Text,
  Group,
  Tooltip,
  ActionIcon,
  Title,
  SelectProps,
  Avatar,
} from "@mantine/core";
import {
  IconBrandBootstrap,
  IconBrandOkRu,
  IconBuildingSkyscraper,
  IconCalendar,
  IconCheck,
  IconCoinPound,
  IconCopy,
  IconCreditCardPay,
  IconDownload,
  IconDroplet,
  IconEdit,
  IconEye,
  IconFilter,
  IconList,
  IconMenuOrder,
  IconMinus,
  IconPhoto,
  IconPlus,
  IconPoint,
  IconPrinter,
  IconPrism,
  IconReceipt,
  IconSearch,
  IconShip,
  IconSquareXFilled,
  IconTicket,
  IconTimeDuration15,
  IconTrash,
  IconUser,
} from "@tabler/icons-react";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_RowSelectionState,
  MRT_SortingState,
  useMantineReactTable,
} from "mantine-react-table";
import * as React from "react";
import { useEffect, useState } from "react";

import { DateInput, DatePickerInput } from "@mantine/dates";
import { useDisclosure, useHotkeys } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { useNavigate } from "react-router-dom";
import { SelectListItem } from "../../../model/SelectListItem";
import { getOrderTypeSelect } from "../../../service/getSelectApi";
import { repositoryMdm, repositoryPos } from "../../../_base/_const/_constVar";
import { MessageResponse } from "../../../model/MessageResponse";
import { formatDateTransfer } from "../../../common/FormatDate/FormatDate";
import { _localization } from "../../../config/location";
import { SocketExtension } from "../../../_base/socket/socket";
import { CREATEORDERSUCCESS } from "../../../_base/socket/ToplicList";
import { OrderDetail } from "../../../model/SaleOrderModel";
import { TblDMPaymentMethod } from "../../../model/TblDMPaymentMethod";
import moment from "moment";
import DetaiReceipts from "./DetaiReceipts";
import { green, orange, purple } from "../../../const/variables";
import { AvatarUtils } from "../../../common/ColorByName/AvatarUtils";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import useWindowSize from "../../../common/WindownSize/WindownSize";
import { SelectListItemBase } from "../../../_base/model/_base/SelectListItemBase";

interface DataList {
  lists: any[];
  count: number;
}

interface InvoiceReceiptsPaymentModel {
  orderNumber: string;
  orderDate: string; // Use string if the date is in ISO format, or use Date if you parse it.
  totalAmount: number;
  amountPaid: number;
  amountRe: number;
  allocatedAmount: number;
  notes: string | null;
}

const CodeCustomerData = [
  "Khách chat Facebook",
  "Khách chat Website",
  "Khách chat Zalo",
  "Khách Facebook vệ tinh",
  "Khách gọi di động",
  "Khách gọi hotline",
  "Khách hàng Doanh nghiệp",
  "Khách lẻ chi nhánh Hai Bà Trung",
  "Khách lẻ CN Bắc Giang",
  "Khách lẻ CN Cầu Giấy",
  "Khách lẻ CN Hà Ðông 1",
  "Khách lẻ CN Hà Ðông 2",
  "Khách lẻ CN Hải Phòng",
  "Khách lẻ CN Long Biên",
  "Khách lẻ CN Phủ Lý",
  "Khách lẻ CN Q3",
  "Khách lẻ CN Thanh Trì",
  "Khách lẻ CN Thái Nguyên",
  "Khách lẻ CN Từ Son",
  "Khách lẻ CN Vinh",
  "Khách lẻ CN Ðông Anh",
  "Khách lẻ CN Ðống Ða",
  "Khách lẻ Thanh Hóa",
  "Khách đến showroom",
];

interface ExampleInterface {
  att1: string | null;
  att2: string | null;
  att3: number | null;
  att4: number | null;
  att5: string[] | null;
  disabled: boolean;
  group: string | null;
  selected: boolean;
  text: string | null;
  value: string | null;
}

const ListPaymentSlip = () => {
  const navigate = useNavigate();
  //#region  table

  //data and fetching state
  const [data, setData] = useState<any[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [dataBranch, setDataBranch] = useState<SelectListItem[]>([]);
  const [dataFetch, setDataFetch] = useState<SelectListItem[]>([]);
  const [dataWarehouse, setDataWarehouse] = useState<SelectListItem[]>([]);
  const [dataIndustry, setDataIndustry] = useState<SelectListItem[]>([]);
  const [dataOrderType, setOrderType] = useState<SelectListItem[]>([]);
  const [listDataFundAccout, setListDataFundAccout] = useState<any[]>([]);

  const [dataListCustomer, setDataListCustomer] = useState<SelectListItem[]>(
    []
  );
  const [paymentMethod, setPaymentMethod] = useState<TblDMPaymentMethod[]>();
  const [selectedValue, setSelectedValue] = useState<string | null>(null);
  const [selectedCustomer, setSelectedCustomer] = useState<string | null>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [skip, setSkip] = useState<number>(1);
  const [take, setTake] = useState<number>(30);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [deleteViewStatus, setDeleteViewStatus] = useState(false);

  //table state
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [height, setHeight] = useState(0);
  //
  const [dateUsers, setUsers] = useState<SelectListItem[]>([]);
  const [dataTblOrderTypeSelect, setDataTblOrderTypeSelect] = useState<
    ComboboxItem[]
  >([]);
  const debounceTimeoutRef = React.useRef<number | null>(null);
  const [value, setValue] = useState<[Date | null, Date | null]>([null, null]);
  const [dataCodeFund, setDataCodeFunch] = useState<SelectListItemBase[]>([]);
  const [dataGetDepartments, setDataGetDepartments] = useState<
    ExampleInterface[]
  >([]);

  const size = useWindowSize();

  const [search, setSearch] = useState({
    keySearch: "",
    customerId: "",
    startDate: "",
    endDate: "",
    codefund: "",
    accountfund: "",
    deartment: "",
    branch: "",
    type: 2,
    workplace: "",
    creator: "",
  });
  useEffect(() => {
    //  getUser();
  }, []);

  const [keySearch, setKeySearch] = useState<string>("");
  const [createdById, setcreatedById] = useState<number>(0);
  const [selectIds, setSelectIds] = useState<string[]>([]);
  const [status, setStatus] = useState<string>("");
  const sizeGrid = { base: 6, sm: 2, md: 2, lg: 2, xl: 2 };

  const dataOrderTypeSelect = async () => {
    const getData = await getOrderTypeSelect();
    setDataTblOrderTypeSelect(
      getData
        ?.filter((item) => item.value != null && item.text != null)
        ?.map((item) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };
  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setIsError(false);
    try {
      const queryParams = {
        Skip: pagination.pageIndex * pagination.pageSize,
        Take: pagination.pageSize,
        CustomerId: search.customerId,
        StartDate: search.startDate,
        EndDate: search.endDate,
        KeySearch: search.keySearch.toLowerCase() || keySearch.toLowerCase(),
        Codefund: search.codefund,
        Accountfund: search.accountfund,
        Creator: search.creator,
        Workplace: search.workplace,
        Branch: search.branch,
        Department: search.deartment,
        Type: search.type,
      };

      const url = Object.entries(queryParams)
        .reduce((acc, [key, value]) => {
          if (value !== undefined && value !== "") {
            return `${acc}&${key}=${encodeURIComponent(value)}`;
          }
          return acc;
        }, "/api/v1/CreateSellItem/list-receipt?")
        .replace("&", "");

      const response = await repositoryPos.get<MessageResponse<DataList>>(url);

      if (response && response.success) {
        const dataList = response?.data;
        setData(dataList?.lists);
        setRowCount(dataList?.count);
        // await dataOrderTypeSelect();
        setSelectIds([]);
        table.resetRowSelection();
      }
    } catch (error) {
      setIsError(true);
      console.error(error);
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
  };

  // Hàm gọi API phòng ban
  const fetchDataSelect = async () => {
    const dataApi = await repositoryPos.get<MessageResponse<any>>(
      "/api/v1/TblDepartment/get-select"
    );

    if (dataApi && dataApi.success) {
      setDataFetch(dataApi.data);
    }
  };

  // Hàm gọi API chi nhánh
  const fetchDataBranch = async () => {
    const dataApi = await repositoryPos.get<MessageResponse<any>>(
      "/api/v1/TblDmInventory/get-select-branch"
    );

    if (dataApi && dataApi.success) {
      const filteredData = dataApi.data.slice(1);
      setDataBranch(filteredData);
    }
  };

  const fetchDataWarehouse = async () => {
    const dataApi = await repositoryPos.get<MessageResponse<any>>(
      "/api/v1/TblDmEmployee/get-select"
    );

    if (dataApi && dataApi.success) {
      setDataWarehouse(dataApi.data);
    }
  };

  const handleChangeSearchValue = (value: string, key: string) => {
    if (key === "customerId") {
      setSelectedCustomer(value);
    }
    setSearch((prevData) => ({ ...prevData, [key]: value }));
    setSelectedValue(value);
  };

  const handleRangeChange = (dates: [Date | null, Date | null]) => {
    setValue(dates);
    const [startDate, endDate] = dates;
    const startDateISO = startDate ? startDate.toLocaleDateString() : null;
    const endDateISO = endDate ? endDate.toLocaleDateString() : null;

    handleChangeSearchValue(startDateISO ?? "", "startDate");

    handleChangeSearchValue(endDateISO ?? "", "endDate");
  };

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "receiptNumber",
        header: "Mã phiếu thu",
        Cell: ({ renderedCellValue, cell, row }) => (
          <Tooltip label="Chi tiết">
            <Badge
              radius="sm"
              variant="dot"
              size="lg"
              style={{ cursor: "pointer" }}
              color={renderedCellValue === null ? "red" : green.base}
              onClick={async () => {
                detailReceipts(row.original.id, row.original.type);
              }}
            >
              {renderedCellValue === null ? null : renderedCellValue}
            </Badge>
          </Tooltip>
        ),
      },
      {
        accessorKey: "test9",
        header: "Ngày lập",
      },
      {
        accessorKey: "createdDate",
        header: "Ngày tạo ",
        Cell: ({ renderedCellValue }: any) => (
          <Text fw={600}>{formatDateTransfer(renderedCellValue)}</Text>
        ),
      },
      {
        accessorKey: "typeReceipt",
        header: "Loại phiếu",
        Cell: ({ row }) => (
          <>
            {row.original.typeReceipt === 1 ? (
              <Badge variant="light" color="#0056D2">
                Phiếu báo nợ
              </Badge>
            ) : (
              <Badge variant="light" color="#FF8800">
                Phiếu chi
              </Badge>
            )}
          </>
        ),
      },
      {
        accessorKey: "creatordepartment",
        header: "Phòng ban người lập phiếu",
        size: 250,
        Cell: ({ renderedCellValue, cell }) => (
          <Text fw={600}>{renderedCellValue}</Text>
        ),
      },
      {
        accessorKey: "creatorworkplace",
        header: "Địa điểm người lập phiếu",
        size: 220,
        Cell: ({ renderedCellValue, cell }) => (
          <Text fw={600}>
            {renderedCellValue === null ? null : renderedCellValue}
          </Text>
        ),
      },
      {
        accessorKey: "creatorfullname",
        header: "Tên người lập phiếu",
        Cell: ({ renderedCellValue, cell, row }) => (
          <>
            <AvatarUtils
              value={
                row.original.creatorfullname ??
                row.original.createName.split("-")[0]
              }
            />
          </>
        ),
      },
      {
        accessorKey: "creatorbranch",
        header: "Chức danh người lập",
        Cell: ({ renderedCellValue, cell }) => (
          <Text fw={600}>{renderedCellValue}</Text>
        ),
      },

      {
        accessorKey: "funddepartment",
        header: "Phòng ban người nộp tiền",
        size: 250,
        Cell: ({ renderedCellValue }) => {
          return <Text fw={600}>{renderedCellValue}</Text>;
        },
      },
      {
        accessorKey: "fundworkplace",
        header: "Địa điểm người nộp tiền",
        size: 250,
        Cell: ({ renderedCellValue, cell }) => (
          <Text fw={600}>
            {renderedCellValue === null ? null : renderedCellValue}
          </Text>
        ),
      },
      {
        accessorKey: "funfullname",
        header: "Họ tên người nộp tiền",
        size: 250,
        Cell: ({ renderedCellValue, cell, row }) => (
          <AvatarUtils value={row.original.funfullname} />
        ),
      },
      {
        accessorKey: "fundbranch",
        header: "Chức danh người nộp tiền",
        size: 250,
        Cell: ({ renderedCellValue, cell }) => (
          <Text fw={600}>{renderedCellValue}</Text>
        ),
      },
      {
        accessorKey: "test1",
        size: 250,
        header: "Phòng ban người điều chỉnh",
      },
      {
        accessorKey: "test2",
        size: 270,
        header: "Địa điểm làm việc người điều chỉnh ",
      },
      {
        accessorKey: "test3",
        size: 250,
        header: "Họ tên người điều chỉnh",
      },
      {
        accessorKey: "test4",
        size: 250,
        header: "Chức danh người điều chỉnh ",
      },
      {
        accessorKey: "payertype",
        header: "Loại",
        Cell: ({ renderedCellValue, row }) => {
          const payerType = row.original.payertype;
          let badgeColor;

          switch (payerType) {
            case "Nhân viên":
              badgeColor = "#0056D2";
              break;
            case "Nội bộ":
              badgeColor = "#FF8800";
              break;
            case "Khách hàng":
              badgeColor = "#28A745";
              break;
            case "NCC":
              badgeColor = "#ffcc5c";
              break;
            case "Khác":
              badgeColor = "#6C757D";
              break;
            default:
              badgeColor = "red";
          }

          return <Badge color={badgeColor}>{renderedCellValue}</Badge>;
        },
      },
      {
        accessorKey: "null2",
        header: "Mã",
      },
      {
        accessorKey: "null3",
        header: "Tên ",
      },
      {
        accessorKey: "creatorsalesbranch",
        header: "Chi nhánh bán",
        Cell: ({ renderedCellValue, cell }) => (
          <Text fw={600}>
            {renderedCellValue === null ? null : renderedCellValue}
          </Text>
        ),
      },
      {
        accessorKey: "test5",
        size: 230,
        header: "Ngân hàng chuyển khoản",
      },
      {
        accessorKey: "test6",
        header: "STK chuyển khoản ",
      },
      // {
      //   accessorKey: "fundsalesbranch",
      //   header: "Chi nhánh NV nộp",
      //   Cell: ({ renderedCellValue, cell }) => (
      //     <Text fw={600}>
      //       {renderedCellValue === null ? null : renderedCellValue}
      //     </Text>
      //   ),
      // },
      {
        accessorKey: "accountfund",
        header: "Tài khoản quỹ",
        Cell: ({ row }) => {
          const codefund = row.original.accountfund;
          let badgeColor;
          let displayText;

          switch (codefund) {
            case 0:
              badgeColor = "cyan";
              displayText = "VND";
              break;
            case 1:
              badgeColor = "green";
              displayText = "USD";
              break;
            case 2:
              badgeColor = "blue";
              displayText = "Vàng SJC 9999";
              break;
            case 3:
              badgeColor = orange.medium;
              displayText = "Vàng PNJ 9999";
              break;
          }
          return (
            displayText && (
              <Badge color={badgeColor} fs={"12,5px"} fw={"bold"} radius="sm">
                {displayText}
              </Badge>
            )
          );
        },
      },

      {
        accessorKey: "codefund",
        header: "Mã phí",
        Cell: ({ renderedCellValue, row }) => {
          const toLocations =
            dataCodeFund?.find((item: any) => {
              return String(item.value) === String(renderedCellValue);
            })?.text || "";

          return (
            <Text tt="capitalize" fw={600}>
              {toLocations}
            </Text>
          );
        },
      },

      {
        accessorKey: "payerdescription",
        header: "Nội dung",
        Cell: ({ renderedCellValue, cell }) => (
          <Text fw={600}>
            {renderedCellValue === null ? null : renderedCellValue}
          </Text>
        ),
      },
      {
        accessorKey: "payernote",
        header: "Ghi chú",
        Cell: ({ renderedCellValue, cell }) => (
          <Text fw={600}>
            {renderedCellValue === null ? null : renderedCellValue}
          </Text>
        ),
      },

      {
        accessorKey: "totalAmount",
        header: "Tổng số tiền",
        Cell: ({ renderedCellValue, cell }) => (
          <Text fw={700}>
            <NumberFormatter
              value={(renderedCellValue as string | number) || 0}
              thousandSeparator=","
            />
          </Text>
        ),
      },
    ],
    [dataTblOrderTypeSelect, dataCodeFund]
  );

  const handleSearchChange = (value: string) => {
    setSearchTerm(value);
  };

  const table = useMantineReactTable<any>({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    //add custom action buttons to top-left of top toolbar
    renderTopToolbarCustomActions: ({ table }) => <></>,

    mantineTopToolbarProps: {
      style: {
        // borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        // marginTop: "10px",
        display: "none",
      },
    },
    localization: _localization,
    enableRowSelection: false,
    // getRowId: (row) => row.id?.toString(),
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["receiptNumber", "mrt-row-select"],
        right: ["totalAmount", "action"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    // enableExpanding: true,
    // getSubRows: (originalRow) => originalRow.orderDetail,

    onRowSelectionChange: setRowSelection,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    rowCount,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        // fontWeight: "bold", //conditional styling
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no data
      sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["20", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
  });

  useEffect(() => {
    // Hàm gọi API kho hàng

    // // Hàm gọi API ngành hàng
    // const fetchDataPaymentMethod = async () => {
    //   const response = await repositoryMdm.get<
    //     MessageResponse<TblDMPaymentMethod[]>
    //   >("/api/v1/TblDmPaymentMethod/get-all");

    //   if (response && response.success) {
    //     let result = response.data;
    //     setPaymentMethod(result);
    //   }
    // };

    // // Hàm gọi API loại đơn hàng
    // const orderType = async () => {
    //   const dataApi = await repositoryMdm.get<MessageResponse<any>>(
    //     "/api/v1/TblDmOrderType/get-select"
    //   );

    //   if (dataApi && dataApi.success) {
    //     setOrderType(dataApi.data);
    //   }
    // };

    // const dataFundAccout = async () => {
    //   const dataApi = await repositoryPos.get<MessageResponse<any>>(
    //     "/api/v1/TblDmBranchBankAccount/get-select"
    //   );

    //   if (dataApi && dataApi.success) {
    //     setListDataFundAccout(dataApi.data);
    //   }
    // };

    Promise.all([
      //  fetchDataPaymentMethod(),
      // orderType(),
      //  dataFundAccout(),
    ]);
  }, []);

  const fetchDataCustomer = async (searchTerm: string) => {
    setLoading(true);
    const query = searchTerm ? `KeySearch=${searchTerm}&` : "";
    const dataApi = await repositoryPos.get<MessageResponse<any>>(
      `/api/v1/TblDmCustomer/get-select?${query}Skip=${1}&Take=${30}`
    );

    if (dataApi && dataApi.success) {
      const filteredData = dataApi.data.filter(
        (item: any) => item.text !== null
      );
      setDataListCustomer(filteredData);
      setSkip((prevSkip) => prevSkip + 1);
    }
    setLoading(false);
  };

  const debouncedFetchDataCustomer = React.useCallback((value: string) => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }
    debounceTimeoutRef.current = window.setTimeout(() => {
      fetchDataCustomer(value);
    }, 300);
  }, []);

  const detailReceipts = (id: number, deltail: number) =>
    modals.openConfirmModal({
      title: <Title order={5}>Chi tiết phiếu thu</Title>,
      children: <DetaiReceipts receiptId={id} deltail={deltail} />,
      size: deltail === 1 ? "100%" : undefined,
      labels: { confirm: "Confirm", cancel: "Cancel" },
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });

  useEffect(() => {
    fetchDataCustomer("");
  }, []);

  const getTblDmExpense = async () => {
    const response = await repositoryMdm.get<MessageResponse<any>>(
      "/api/v1/TblDmExpense/get-select"
    );

    if (response && response.success) {
      let result = response.data;
      setDataCodeFunch(result);
    }
  };

  useEffect(() => {
    debouncedFetchDataCustomer(searchTerm);
  }, [searchTerm, debouncedFetchDataCustomer]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      setHeight(window.innerHeight - (150 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [height]);

  useEffect(() => {
    fetchData();
  }, [pagination.pageIndex, pagination.pageSize, sorting]);

  useEffect(() => {
    fetchData();
  }, [deleteViewStatus]);
  // Icon color mappings based on value
  const optionColors: Record<
    "Tiền dư" | "Khác" | "Thanh lý" | "Hoàn ứng công tác" | "Hoàn ứng thu mua",
    string
  > = {
    "Tiền dư": "cyan",
    Khác: "green",
    "Thanh lý": "blue",
    "Hoàn ứng công tác": orange.medium,
    "Hoàn ứng thu mua": purple.medium,
  };

  // Render function to customize the option appearance
  const renderSelectOption: SelectProps["renderOption"] = ({
    option,
    checked,
  }) => (
    <Group flex="1" gap="xs">
      <Badge
        color={
          optionColors[
            option.label as
              | "Tiền dư"
              | "Khác"
              | "Thanh lý"
              | "Hoàn ứng công tác"
              | "Hoàn ứng thu mua"
          ]
        }
        radius="sm"
        variant="filled"
      >
        {option.label}
      </Badge>
      {checked && <IconCheck style={{ marginInlineStart: "auto" }} />}
    </Group>
  );

  // account fund
  const optionColorsAccount: Record<
    "VND" | "USD" | "Vàng SJC 9999" | "Vàng PNJ 9999",
    string
  > = {
    VND: "cyan",
    USD: "green",
    "Vàng SJC 9999": "blue",
    "Vàng PNJ 9999": orange.medium,
  };

  const dataAccountFund = [
    { value: "0", label: "VND" },
    { value: "1", label: "USD" },
    { value: "2", label: "Vàng SJC 9999" },
    { value: "3", label: "Vàng PNJ 9999" },
  ];
  // Render function to customize the option appearance
  const renderSelectOptionAccount: SelectProps["renderOption"] = ({
    option,
    checked,
  }) => (
    <Group flex="1" gap="xs">
      <Badge
        color={
          optionColorsAccount[
            option.label as "VND" | "USD" | "Vàng SJC 9999" | "Vàng PNJ 9999"
          ]
        }
        radius="sm"
        variant="filled"
      >
        {option.label}
      </Badge>
      {checked && <IconCheck style={{ marginInlineStart: "auto" }} />}
    </Group>
  );
  const renderSelectOptionType: SelectProps["renderOption"] = ({
    option,
    checked,
  }) => (
    <Group flex="1" gap="xs">
      <Badge
        color={option.value === "1" ? "blue" : "green"}
        radius="sm"
        variant="filled"
      >
        {option.label}
      </Badge>
      {checked && <IconCheck style={{ marginInlineStart: "auto" }} />}
    </Group>
  );
  return (
    <>
      <div
        style={{ border: "1px solid #dee2e6", padding: "5px" }}
        ref={headerRef}
      >
        <_breadcrumb></_breadcrumb>
        <Grid>
          <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
            <Grid>
              <Grid.Col span={sizeGrid}>
                <Input
                  placeholder="Từ khóa"
                  type="text"
                  onChange={(e) => {
                    handleChangeSearchValue(e.target.value ?? "", "keySearch");
                  }}
                  leftSection={<IconSearch color="#15aabf" />}
                />
              </Grid.Col>
              <Grid.Col
                span={sizeGrid}
                // style={{ flexBasis: "100%", maxWidth: "320px", flex: "1" }}
              >
                <DatePickerInput
                  type="range"
                  leftSection={<IconTimeDuration15 color="#15aabf" />}
                  size="sm"
                  placeholder="Chọn ngày"
                  locale="vi"
                  value={value}
                  valueFormat="DD/MM/YYYY"
                  onChange={handleRangeChange}
                  clearable
                />
              </Grid.Col>
              <Grid.Col span={sizeGrid}>
                <Select
                  searchable
                  clearable
                  placeholder="Phòng ban"
                  limit={50}
                  leftSection={<IconBuildingSkyscraper color="#15aabf" />}
                  comboboxProps={{
                    transitionProps: { transition: "pop", duration: 200 },
                  }}
                  data={dataFetch
                    .filter((item) => item.text !== "Tất cả")
                    .map((item) => {
                      return {
                        label: item.text ?? "",
                        value: String(item.value ?? ""),
                      };
                    })}
                  onClick={async () => {
                    if (!dataFetch || dataFetch.length < 1) {
                      await fetchDataSelect();
                    }
                    return;
                  }}
                  onChange={(value) => {
                    handleChangeSearchValue(value ?? "", "department");
                  }}
                />
              </Grid.Col>
              <Grid.Col span={sizeGrid}>
                <Select
                  searchable
                  clearable
                  placeholder="Chi nhánh"
                  comboboxProps={{
                    transitionProps: { transition: "pop", duration: 200 },
                  }}
                  limit={50}
                  leftSection={<IconBrandBootstrap color="#15aabf" />}
                  data={dataBranch.map((item) => {
                    return {
                      label: item.text ?? "",
                      value: String(item.value ?? ""),
                    };
                  })}
                  onClick={async () => {
                    if (!dataBranch || dataBranch.length < 1) {
                      await fetchDataBranch();
                    }
                    return;
                  }}
                  onChange={(value) => {
                    handleChangeSearchValue(value ?? "", "branch");
                  }}
                />
              </Grid.Col>
              <Grid.Col span={sizeGrid}>
                <Select
                  searchable
                  clearable
                  placeholder="Nhân viên"
                  leftSection={<IconUser color="#15aabf" />}
                  comboboxProps={{
                    transitionProps: { transition: "pop", duration: 200 },
                  }}
                  limit={50}
                  data={dataWarehouse.map((item) => {
                    return {
                      label: item.text ?? "",
                      value: String(item.value ?? ""),
                    };
                  })}
                  onClick={async () => {
                    if (!dataWarehouse || dataWarehouse.length < 1) {
                      await fetchDataWarehouse();
                    }
                    return;
                  }}
                  onChange={(value) => {
                    handleChangeSearchValue(value ?? "", "creator");
                  }}
                />
              </Grid.Col>
              <Grid.Col span={sizeGrid}>
                <Select
                  searchable
                  clearable
                  placeholder="Mã phí"
                  leftSection={<IconReceipt color="#15aabf" />}
                  limit={50}
                  comboboxProps={{
                    transitionProps: { transition: "pop", duration: 0 },
                  }}
                  onClick={async () => {
                    if (!dataCodeFund || dataCodeFund.length < 1) {
                      await getTblDmExpense();
                    }
                    return;
                  }}
                  data={dataCodeFund.map((item) => {
                    return {
                      label: item.text ?? "",
                      value: String(item.value ?? ""),
                    };
                  })}
                  onChange={(value) => {
                    handleChangeSearchValue(value ?? "", "codefund");
                  }}
                  // renderOption={renderSelectOption}
                />
              </Grid.Col>
              <Grid.Col span={sizeGrid}>
                <Select
                  searchable
                  clearable
                  placeholder="Tài khoản quỹ"
                  leftSection={<IconCoinPound color="#15aabf" />}
                  limit={50}
                  comboboxProps={{
                    transitionProps: { transition: "pop", duration: 200 },
                  }}
                  data={dataAccountFund}
                  onChange={(value) => {
                    handleChangeSearchValue(value ?? "", "accountfund");
                  }}
                  renderOption={renderSelectOptionAccount}
                />
              </Grid.Col>
              <Grid.Col span={sizeGrid}>
                <Select
                  searchable
                  clearable
                  placeholder="Loại phiếu"
                  leftSection={<IconTicket color="#15aabf" />}
                  limit={50}
                  comboboxProps={{
                    transitionProps: { transition: "pop", duration: 200 },
                  }}
                  data={[
                    { value: "1", label: "Đơn hàng" },
                    { value: "2", label: "Nội bộ" },
                  ]}
                  onChange={(value) => {
                    handleChangeSearchValue(value ?? "", "type");
                  }}
                  renderOption={renderSelectOptionType}
                />
              </Grid.Col>

              <Grid.Col span={sizeGrid}>
                <Button
                  leftSection={<IconSearch size={14} />}
                  color="blue"
                  variant="outline"
                  onClick={async () => {
                    await fetchData();
                  }}
                >
                  Tìm kiếm
                </Button>
              </Grid.Col>
            </Grid>
          </Grid.Col>
        </Grid>
      </div>
      <div className="mt-5" style={{ marginTop: "10px" }}>
        <MantineReactTable table={table} />
      </div>
    </>
  );
};
export default ListPaymentSlip;
