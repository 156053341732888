import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Flex,
  Grid,
  Group,
  Input,
  Title,
  Tooltip,
} from "@mantine/core";
import { modals } from "@mantine/modals";
import {
  IconActivity,
  IconClearAll,
  IconEdit,
  IconFunction,
  IconHandStop,
  IconManualGearbox,
  IconPlus,
  IconSearch,
  IconShoppingBag,
  IconTrash,
  IconZoomReset,
} from "@tabler/icons-react";
import {
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_ExpandedState,
  MRT_PaginationState,
  MRT_RowSelectionState,
  MRT_SortingState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { NotificationExtension } from "../../_base/extension/NotificationExtension";
import Repository from "../../_base/helper/HttpHelper";
import { _localization } from "../../config/location";
import { getProcessDefSelect, getUsersSelect } from "../../const/getSelectApi";
import { MessageResponse } from "../../model/MessageResponse";
import { SelectListItem } from "../../model/SelectListItem";
import GroupAddRole from "./GroupAddRoleAuthozire";
import GroupRoleEdit from "./GroupRoleEdit";
import GroupsRoleAdd from "./GroupsRoleAdd";
import "../../Styles/tab.component.css";
import {
  _timerDelayClick,
  _timerDelaySearch,
  repositoryLog,
  repositoryMaster,
} from "../../_base/_const/_constVar";
import { Historyuseraction } from "../../model/HistoryuseractionModel";
import moment from "moment";
import { formatDateTransfer } from "../../common/FormatDate/FormatDate";
import _breadcrumb from "../../_base/component/_layout/_breadcrumb";
import { SocketExtension } from "../../_base/socket/socket";
import { Delay } from "../../_base/helper/FunctionHelper";

interface RequestHeader {
  host: string;
  "x-real-ip": string;
  "x-forwarded-for": string;
  "x-forwarded-host": string;
  "x-forwarded-port": string;
  "x-forwarded-proto": string;
  connection: string;
  accept: string;
  "user-agent": string;
  origin: string;
  referer: string;
  "accept-encoding": string;
  "accept-language": string;
}

interface Request {
  id: string;
  header: RequestHeader;
}

interface UserRequests {
  [username: string]: Request[];
}

interface RootObject {
  list: UserRequests;
}

interface OnlineModel {
  username: string;
  data: Request[];
}

interface OnlineDataModel {
  username: string;
  idDevice: string;
  host: string;
  "x-real-ip": string;
  "x-forwarded-for": string;
  "x-forwarded-host": string;
  "x-forwarded-port": string;
  "x-forwarded-proto": string;
  connection: string;
  accept: string;
  "user-agent": string;
  origin: string;
  referer: string;
  "accept-encoding": string;
  "accept-language": string;
}
const ListOnlineView = () => {
  //#region  table
  const headerRef = React.useRef<HTMLDivElement>(null);
  //data and fetching state
  const [data, setData] = useState<OnlineDataModel[]>([]);
  const [dataTemp, setDataTemp] = useState<Historyuseraction[]>([]);

  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  //table state
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 100,
  });
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  //

  const [keySearch, setKeySearch] = useState<string>("");
  const [selectIds, setSelectIds] = useState<string[]>([]);

  const [deleteViewStatus, setDeleteViewStatus] = useState(0);
  //count
  const [timeLeft, setTimeLeft] = useState(_timerDelaySearch);
  const [isCounting, setIsCounting] = useState(false);

  useEffect(() => {
    let timer: ReturnType<typeof setInterval>;
    if (isCounting && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsCounting(false);
    }
    return () => clearInterval(timer);
  }, [isCounting, timeLeft]);

  const startCountdown = () => {
    if (!isCounting) {
      setTimeLeft(_timerDelaySearch);
      setIsCounting(true);
    }
  };

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);

  useEffect(() => {
    // SocketExtension.On("user-office", (response: any) => {
    //   console.log(response); // ok
    // });
    getData();
  }, []);

  const getData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setOpenedMenu(false);
    await Delay(1000);
    SocketExtension.Emit("get-online", (response: RootObject) => {
      const transformedData: OnlineModel[] = Object.entries(response.list).map(
        ([username, requests]) => ({
          username: username,
          data: requests,
        })
      );
      let _data: OnlineDataModel[] = [];
      transformedData.forEach((x) => {
        x.data.forEach((request) => {
          _data.push({
            username: x.username,
            idDevice: request.id,
            host: request.header.host,
            "x-real-ip": request.header["x-real-ip"],
            "x-forwarded-for": request.header["x-forwarded-for"],
            "x-forwarded-host": request.header["x-forwarded-host"],
            "x-forwarded-port": request.header["x-forwarded-port"],
            "x-forwarded-proto": request.header["x-forwarded-proto"],
            connection: request.header.connection,
            accept: request.header.accept,
            "user-agent": request.header["user-agent"],
            origin: request.header.origin,
            referer: request.header.referer,
            "accept-encoding": request.header["accept-encoding"],
            "accept-language": request.header["accept-language"],
          });
        });
      });
      setData(_data);
    });
    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
    startCountdown();
  };

  const fetchDelete = async () => {
    try {
      let urlGet = "/api/v1/logging/delete-logs";
      const response = await repositoryLog.get<MessageResponse<any>>(urlGet);
      if (response && response.success && response.httpStatusCode === 200) {
        NotificationExtension.Success("Thao tác thành công !");
      } else NotificationExtension.Fails("Thao tác thất bại !");
    } catch (error) {
      setIsError(true);
      console.error(error);
      return;
    }
  };

  const columns = React.useMemo<MRT_ColumnDef<OnlineDataModel>[]>(
    () => [
      {
        accessorKey: "username",
        header: "Tài khoản",
        enableGrouping: true,
      },
      {
        accessorKey: "idDevice",
        header: "Mã thiết bị",
      },
      {
        accessorKey: "x-forwarded-for",
        header: "IP client",
        Cell: ({ renderedCellValue }) => (
          <>{renderedCellValue?.toString().split(",")[0]}</>
        ),
      },
      {
        accessorKey: "host",
        header: "Máy chủ",
      },
      {
        accessorKey: "origin",
        header: "Tên miền truy cập",
      },
      {
        accessorKey: "user-agent",
        header: "Trình duyệt",
      },
      {
        accessorKey: "x-real-ip",
        header: "IP nội bộ",
      },
      {
        accessorKey: "x-forwarded-proto",
        header: "Phương thức",
      },
      {
        accessorKey: "x-forwarded-port",
        header: "Cổng máy chủ",
      },

      {
        accessorKey: "x-forwarded-host",
        header: "Tên miền đích",
      },
      {
        accessorKey: "accept-language",
        header: "Ngôn ngữ client",
        Cell: ({ renderedCellValue }) => (
          <>{renderedCellValue?.toString().split(",")[0]}</>
        ),
      },
    ],
    []
  );
  const [openedMenu, setOpenedMenu] = useState(false);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 160 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (240 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);
  const table = useMantineReactTable({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    enableColumnResizing: false,
    enableGrouping: true,
    //add custom action buttons to top-left of top toolbar
    renderTopToolbarCustomActions: ({ table }) => (
      <Box>
        <_breadcrumb></_breadcrumb>
        <Group>
          <Input
            placeholder="Nhập từ khóa ..."
            onChange={(e) => {
              setKeySearch(e.target.value ?? "");
            }}
          />
          <Button
            ml={"xs"}
            leftSection={<IconSearch size={14} />}
            color="blue"
            variant="outline"
            disabled={isCounting}
            onClick={async () => {
              // await fetchData();
              getData();
            }}
          >
            Tìm kiếm
          </Button>

          {/* <Button
            ml={"xs"}
            leftSection={<IconClearAll size={14} />}
            color="red"
            variant="outline"
            disabled={isCounting}
            onClick={async () => {
              await fetchDelete();
            }}
          >
            Dọn dẹp
          </Button> */}
        </Group>
      </Box>
    ),
    renderToolbarInternalActions: ({ table }) => <></>,

    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    // enableRowSelection: true,
    getRowId: (row) => row?.idDevice,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select"],
        right: ["action"],
      },
      columnVisibility: { id: false },
      density: "xs",
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    rowCount,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        fontWeight: "bold", //conditional styling
        fontSize: "12.5px",
        padding: "5px 15px",
        //  backgroundColor: row.original.userName.length>0 ? "white" : "gray",
      },
    }),
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no data
      sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["100", "150", "200"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
  });
  //#endregion
  return (
    <>
      <div className="mt-5">
        <MantineReactTable table={table} />
      </div>
    </>
  );
};
export default ListOnlineView;
